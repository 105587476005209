<div class="ds-rows" dsDialogContent>
  <div [innerHTML]="'HarvestData.download.information' | translate"></div>
  <div
    class="ds-notice--warning"
    *ngIf="isExtended"
    [innerHTML]="'HarvestData.download.chargeWarning' | translate"
  ></div>
  <div class="ds-field">
    <label class="ds-label">
      {{ 'HarvestData.download.time' | translate }}
    </label>
    <ui-datetime-range-selector
      [value]="timeRange"
      [relativeTimeRangeOptions]="timeRangeOptions"
      classOption="inline"
      keyForHistory="harvest-data"
      (timeRangeChange)="setTimeRange($event)"
    ></ui-datetime-range-selector>
  </div>

  <div class="ds-field">
    <label class="ds-label">
      {{ 'HarvestData.download.target' | translate }}
    </label>
    <select [(ngModel)]="targetResource" class="ds-select" [disabled]="isDownloading()">
      <option *ngFor="let item of resources" [ngValue]="item" [selected]="item.resourceId === resourceId">
        {{ item.resourceId }}
      </option>
    </select>
  </div>
  <div class="ds-field">
    <label class="ds-label">
      {{ 'HarvestData.download.format' | translate }}
    </label>
    <select [(ngModel)]="downloadFormat" class="ds-select" [disabled]="isDownloading()">
      <option *ngFor="let item of formats" [ngValue]="item.identifier">
        {{ item.i18nKey | translate }}
      </option>
    </select>
  </div>
  <div class="ds-field">
    <div class="ds-notice--info--icon-refresh-cw --spin" *ngIf="state === 'downloading'">
      {{ 'HarvestData.download.downloading' | translate }}
      <span class="ds-span" *ngIf="state === 'downloading'"
        >{{ progress.totalDataSize | byte }}{{ 'HarvestData.download.downloaded_size' | translate }}</span
      >
    </div>
    <ui-button [content]="downloadBtn" *ngIf="state !== 'downloading'"></ui-button>
    <ui-button [content]="cancelBtn" *ngIf="state === 'downloading'"></ui-button>
  </div>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
</div>
<footer dsDialogFooter>
  <ui-button-bar [content]="footerBar"></ui-button-bar>
</footer>
