import { Component, Input } from '@angular/core';
import { HarvestDataIntelligenceUiConversation } from '../harvest-data-intelligence.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: '*[appHarvestDataConversationGroup]',
  templateUrl: './harvest-data-conversation-group.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class HarvestDataConversationGroupComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() conversation: HarvestDataIntelligenceUiConversation;

  lines(msg: string): string[] {
    return msg.split('\n').filter((v) => !!v);
  }
}
