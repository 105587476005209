import { LegacyAny } from '@soracom/shared/core';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActiveElement, Chart, ChartData, ChartDataset, ChartEvent, ChartOptions, ChartType } from 'chart.js';
import { merge } from 'lodash-es';
import { BaseChartDirective } from 'ng2-charts';
import { AxisControlState, DataSeriesVisibility, HarvestChartType } from '../harvest-data-viz-type';

// TODO: date format for x axis

const ChartTypeMap: Record<HarvestChartType, ChartType> = {
  line_chart: 'line',
  grouped_column_chart: 'bar',
  stacked_column_chart: 'bar',
} as const;

const ChartTypeOptionsMap: Record<HarvestChartType, ChartOptions> = {
  line_chart: {},
  grouped_column_chart: {},
  stacked_column_chart: {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  },
} as const;

const CommonChartOptions: ChartOptions = {
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  scales: {
    x: {
      type: 'time',
      ticks: {
        source: 'data',
        minRotation: 45,
        maxRotation: 45,
      },
      time: {
        displayFormats: {
          millisecond: 'MM-DD HH:mm:ss',
          second: 'MM-DD HH:mm:ss',
          minute: 'MM-DD HH:mm',
          hour: 'MM-DD HH:mm',
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  parsing: false,
};

const chartDataInputs = new Set(['datasets', 'labels', 'visibilities']);
const chartOptionsInputs = new Set(['type', 'yAxisCtrl']);

@Component({
  selector: 'app-harvest-data-chart',
  template: ` <app-chart-container>
    <canvas baseChart [type]="chartType" [data]="chartData" [options]="chartOptions"></canvas>
  </app-chart-container>`,
  styles: [
    `
      :host {
        display: block;
        position: relative;
        min-height: 400px;
        min-width: 400px;
      }
      app-chart-container {
        height: 100%;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HarvestDataChartComponent {
  @Input() type: HarvestChartType = 'line_chart';
  // @ts-expect-error (legacy code incremental fix)
  @Input() datasets: ChartDataset[];
  @Input() labels: string[] = [];
  // @ts-expect-error (legacy code incremental fix)
  @Input() visibilities: DataSeriesVisibility;
  // @ts-expect-error (legacy code incremental fix)
  @Input() yAxisCtrl: AxisControlState;

  @Output() visibilitiesChange = new EventEmitter<DataSeriesVisibility>();
  // emit the indexes of point clicked in datasets.data
  @Output() dataClick = new EventEmitter<ActiveElement[]>();

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  get chartType(): ChartType {
    return ChartTypeMap[this.type];
  }

  // @ts-expect-error (legacy code incremental fix)
  chartData: ChartData;
  // @ts-expect-error (legacy code incremental fix)
  chartOptions: ChartOptions;

  ngOnChanges(changes: SimpleChanges) {
    const keys = Object.keys(changes);
    if (keys.some((k) => chartDataInputs.has(k))) {
      this.chartData = this.getChartData();
    }
    if (keys.some((k) => chartOptionsInputs.has(k))) {
      this.chartOptions = this.getChartOptions();
    }
  }

  getChartData() {
    return {
      datasets: this.getChartDatasets(),
      labels: this.labels,
    } as ChartData;
  }

  getChartDatasets(): ChartDataset[] {
    const isHidden = (label: LegacyAny) => (this.visibilities || {})[label] === false; // isHidden = false if undefined
    return this.datasets.map((ds) => ({ ...ds, hidden: isHidden(ds.label) }));
  }

  getChartOptions(): ChartOptions {
    return merge({}, CommonChartOptions, this.typeSpecificOptions, this.yAxisOptions, {
      onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        // charts are run outside angular so we have to _run_  to detect changes in the angular world
        this.zone.run(() => {
          if (elements && elements.length > 0) {
            this.dataClick.emit(elements);
          }
        });
      },
    });
  }

  get typeSpecificOptions(): ChartOptions {
    return ChartTypeOptionsMap[this.type] || {};
  }

  get yAxisOptions(): ChartOptions {
    if (!this.yAxisCtrl || this.yAxisCtrl.autoAdjust) {
      return {};
    }
    return {
      scales: {
        y: {
          // @ts-expect-error (legacy code incremental fix)
          max: this.yAxisCtrl.maxValue,
          // @ts-expect-error (legacy code incremental fix)
          min: this.yAxisCtrl.minValue,
        },
      },
    };
  }

  constructor(private zone: NgZone) {}
}
