export const template = `<div class="ds-rows" translate-namespace="harvest_contract_component">
  <div class="ds-notice--info" translate=".description"></div>
  <div class="ds-field"><label class="ds-label" translate=".label"></label><select class="ds-select x-harvest-contract"
      ng-model="$ctrl.selectedContract"
      ng-options="s as ('harvest_contract.' + s.plan | translate:$ctrl.translationData(s)) for s in $ctrl.availableContracts track by s.id"
      ng-change="$ctrl.contractDidChanged(s)"></select></div><sc-alerts
    service="$ctrl.alertsServiceInstance"></sc-alerts>
  <div><button class="ds-button--primary" type="button" ng-click="$ctrl.updateContract()"
      ng-disabled="!$ctrl.canSubmit()"><span translate=".apply"></span></button></div>
</div>`;
