<section dsDialogContent>
  <dl class="ds-details">
    <ng-container  *ngFor="let target of targets">
      <dt translate="templates.change_group_modal.model_id"></dt>
      <dd><div>{{ target.id }}</div></dd>
    </ng-container>
  </dl>
  <app-group-chooser (groupChooserChange)="selectedGroup = ($event.newGroup || null)" customLabel="templates.change_group_modal.new_group" [disableUnset]="true"> </app-group-chooser>

</section>
<footer dsDialogFooter>
  <button
  class="ds-button --primary" (click)="submit()" [disabled]="!canSubmit()"
  translate="templates.change_group_modal.update" focusMe></button>
  <button class="ds-button"
  (click)="unset()" translate="templates.change_group_modal.unset"></button>
  <button class="ds-button" (click)="cancel()"
  translate="templates.change_group_modal.cancel"></button>

</footer>
