import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResourceOption, RESOURCE_TYPES, RESOURCE_TYPE_SIM } from '../resource-selector.type';
import { ExtendedSim as Sim } from '@soracom/shared/sim';

@Component({
  selector: 'app-resource-select-option',
  templateUrl: './resource-select-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceSelectOptionComponent {
  @Input() value: ResourceOption | null = null;

  get type() {
    return this.value?.resourceType;
  }

  get isSIM() {
    return this.type === RESOURCE_TYPE_SIM;
  }

  get isOtherResource() {
    // @ts-expect-error (legacy code incremental fix)
    return !this.isSIM && (RESOURCE_TYPES as string[]).includes(this.type);
  }

  get sim(): Sim {
    return this.value as unknown as Sim;
  }
}
