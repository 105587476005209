export const template = `<app-user-notifications></app-user-notifications>
<div class="x-gps-multiunit-root" id="page-wrapper" translate-namespace="gadgets.gps_multiunit_page">
  <div class="ds-rows">
    <div class="ds-span--12">
      <div class="alerts" style="float: none;"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
    </div>
    <div class="ds-span--12"><app-gps-multiunit [state]="$ctrl.state"
        (on-state-changed)="$ctrl.onUpdateState($event)"></app-gps-multiunit></div>
  </div>
</div>`;
