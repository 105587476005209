import { LegacyAny } from '@soracom/shared/core';

import { A11yModule } from '@angular/cdk/a11y';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DsLoadingIndicator, UiSdsModule } from '@soracom/shared-ng/ui-sds';
import {
  HarvestDataIntelligenceService,
  HarvestDataIntelligenceVizTypeCategory,
  whichIntelligenceVizTypeCategory,
} from './harvest-data-intelligence.service';
import { UiAppSidebarService } from '@soracom/shared-ng/ui-app-sidebar';
import { ApiException } from '@soracom/shared/soracom-api-typescript-client';
import { getRateLimitRefreshTimeMsFromHeaders } from '@soracom/shared/soracom-platform';
import { HarvestDataStore } from '../harvest-data.store';
import { debounceTime, merge, skip } from 'rxjs';
import { HarvestDataConversationGroupComponent } from './harvest-data-conversation-group/harvest-data-conversation-group.component';
import { HarvestDataVizType } from '../harvest-data-viz/harvest-data-viz-type';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// TODO: use i18n service
const freeInputEn = 'Free Input' as const;
const freeInputJa = '自由入力' as const;

const predefinedQuestions = {
  ja: [
    'データについて説明してください。',
    'データになにか特筆すべき箇所や傾向、異常値はありますか?',
    'このデータには欠損値が含まれていますか？',
    'このデータには外れ値が含まれていますか？',
    'このデータを監視する場合、しきい値のおすすめは？',
    'このデータで何ができますか？',
  ],
  en: [
    'Please describe the data.',
    'Are there any notable spots, trends or outliers in the data?',
    'Does this data contain missing values?',
    'Does this data contain outliers?',
    'What are the threshold recommendations for monitoring this data?',
    'What can we do with this data?',
  ],
} as const;

@Component({
  selector: 'app-harvest-data-intelligence',
  templateUrl: `./harvest-data-intelligence.component.html`,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TextFieldModule,
    UiSdsModule,
    A11yModule,
    HarvestDataConversationGroupComponent,
    TranslateModule,
  ],
  providers: [HarvestDataIntelligenceService, DsLoadingIndicator],
})
export class HarvestDataIntelligenceComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  private curVizTypeCategory: HarvestDataIntelligenceVizTypeCategory = undefined;
  protected svc = inject(HarvestDataIntelligenceService);
  protected t = inject(TranslateService);
  protected uiAppSidebarService = inject(UiAppSidebarService);
  protected store = inject(HarvestDataStore);

  protected loading = false;

  protected questions: string[] =
    this.t.currentLang === 'ja' ? [...predefinedQuestions.ja] : [...predefinedQuestions.en];

  constructor() {
    merge(this.store.visibilities$, this.store.displayItems$, this.store.mapDisplayData$) //any time visibilities or data changes, show reset dialog option
      .pipe(takeUntilDestroyed(), debounceTime(300), skip(1)) //skip the first emission
      .subscribe(() => {
        this.triggerResetConversationDialog();
      });

    this.store.vizType$.pipe(takeUntilDestroyed()).subscribe((vizType) => {
      this.handleVizTypeChange(vizType);
    });
  }

  @ViewChild('askAiDetails') askAiDetails!: ElementRef;
  @ViewChild('conversationEndMarker') conversationEndMarker!: ElementRef;

  question: string = this.questions[0];

  freeQuestion = '';
  private timeoutId: LegacyAny;
  awaitingRetry: boolean = false;
  private readonly defaultRateLimitRefreshTimeMs = 60 * 1000;
  private rateLimitRefreshTimeMs = this.defaultRateLimitRefreshTimeMs;
  showResetConversationDialog = false;

  ngOnInit(): void {
    this.store.setAutoRefresh(false);
    this.store.setAutoRefreshSwitchEnabled(false);
    this.store.setAiDialogOpen(true);
    // if (this.featureVisibilityService.isEnabled('harvestDataIntelligenceFreeInput')) { //FIXME TODO - Bring back free input for sorao account only
    //   this.questions.push(this.t.currentLang === 'ja' ? freeInputJa : freeInputEn);
    // }
    this.svc.initNewConversation();
  }

  ngOnDestroy(): void {
    this.store.setAutoRefreshSwitchEnabled(true);
    this.store.setAiDialogOpen(false);
    clearTimeout(this.timeoutId);
  }

  ask() {
    const q = this.question;
    this.svc.appendUserQuestion(q);
    this.askCurrentConversation();
  }

  reTry() {
    this.awaitingRetry = true;
    this.timeoutId = setTimeout(() => {
      this.askCurrentConversation();
    }, this.rateLimitRefreshTimeMs);
  }

  askCurrentConversation() {
    this.awaitingRetry = false;
    this.loading = true;
    let shouldRetry = false;
    this.scrollToEndOfConversation();
    return this.svc
      .askCurrentConversation()
      .then(() => {})
      .catch((e: LegacyAny) => {
        if (this.isRateLimitError(e)) {
          this.setRateLimitRefreshTimeFromResHeaders(e);
          shouldRetry = true;
        } else {
          this.svc.appendUiErrorMessage(e);
        }
      })
      .finally(() => {
        this.loading = false;
        this.awaitingRetry = false;
        this.scrollToEndOfConversation();
        if (shouldRetry) {
          this.reTry();
        }
      });
  }

  private isRateLimitError(e: any) {
    if (e?.data?.code === 'AGW0003') {
      return true;
    } else {
      return false;
    }
  }

  get isFreeInput() {
    return this.question === (this.t.currentLang === 'ja' ? freeInputJa : freeInputEn);
  }

  questionClicked(question: string) {
    this.question = question;
    this.askAiDetails.nativeElement.open = false;
    this.ask();
  }

  scrollToEndOfConversation() {
    setTimeout(() => {
      //need the timeout for smooth scrolling
      if (this.conversationEndMarker?.nativeElement) {
        this.conversationEndMarker.nativeElement.scrollIntoView();
      }
    }, 50);
  }

  close() {
    this.uiAppSidebarService.removeSidebar();
  }

  private setRateLimitRefreshTimeFromResHeaders(e: ApiException<any>) {
    const refreshTimeFromHeaders = getRateLimitRefreshTimeMsFromHeaders(e);
    this.rateLimitRefreshTimeMs = refreshTimeFromHeaders
      ? refreshTimeFromHeaders + 2000 //Add a 2s buffer
      : this.defaultRateLimitRefreshTimeMs;
  }

  dataSettingsUpdateYes() {
    this.showResetConversationDialog = false;
    this.svc.initNewConversation([
      {
        role: 'ui-confirmation',
        content: this.t.instant('harvestDataIntelligence.messages.usingNewData'),
      },
    ]);
    this.scrollToEndOfConversation();
  }

  dataSettingsUpdateNo() {
    this.showResetConversationDialog = false;
    this.svc.appendUiConfirmationMessage(this.t.instant('harvestDataIntelligence.messages.usingPreviousData'));
    this.scrollToEndOfConversation();
  }

  private triggerResetConversationDialog() {
    this.showResetConversationDialog = true;
    this.scrollToEndOfConversation();
  }

  private handleVizTypeChange(vizType: HarvestDataVizType) {
    if (!this.curVizTypeCategory) {
      this.curVizTypeCategory = whichIntelligenceVizTypeCategory(vizType); //don't need to do anything else but save the type for the first emit
    } else {
      const newWizTypeCategory = whichIntelligenceVizTypeCategory(vizType);
      if (this.curVizTypeCategory !== newWizTypeCategory) {
        this.curVizTypeCategory = newWizTypeCategory;
        this.triggerResetConversationDialog();
      }
    }
  }
}
