import { LegacyAny } from '@soracom/shared/core';

import { template } from './prefixed_event_handlers_by_imsi.component.html';

import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';
import { EventHandler, TargetType } from '../event_handlers/event_handler';
import { EventHandlersService } from '../event_handlers/event_handlers.service';
import { PrefixedEventHandlerViewModelCollection } from './prefixed_event_handler';
import { PrefixedEventHandlersService } from './prefixed_event_handlers.service';

export class PrefixedEventHandlerByImsiComponent implements ng.IComponentOptions {
  bindings = {
    imsi: '<',
    groupId: '<',
    closeModal: '<',
  };

  controller = PrefixedEventHandlerByImsiComponentController;
  template: any = template;
}

export class PrefixedEventHandlerByImsiComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$scope', 'AlertsService', 'EventHandler', 'PrefixedEventHandler'];

  alertsService: AlertsServiceInstance;
  nowLoading = true;
  submitting = false;
  positiveInteger = /^[0-9]*$/;
  // @ts-expect-error (legacy code incremental fix)
  groupTab: { disabled?: boolean };

  target = TargetType.imsi;
  // @ts-expect-error (legacy code incremental fix)
  imsi: string;
  // @ts-expect-error (legacy code incremental fix)
  groupId: string;

  settings = new PrefixedEventHandlerViewModelCollection(); // for imsi
  groupSettings = new PrefixedEventHandlerViewModelCollection();
  operatorSettings = new PrefixedEventHandlerViewModelCollection();

  prefixedEventHandlers: EventHandler[] = [];
  // @ts-expect-error (legacy code incremental fix)
  prefixedEventHandlersForm: ng.IFormController;
  // @ts-expect-error (legacy code incremental fix)
  closeModal: () => void;

  constructor(
    $log: ng.ILogService,
    private $scope: ng.IScope,
    alertsServiceGenerator: AlertsService,
    private eventHandlersService: EventHandlersService,
    private prefixedEventHandlersService: PrefixedEventHandlersService
  ) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    if (this.groupId) {
      this.groupTab = {};
    } else {
      this.groupTab = {
        disabled: true,
      };
    }

    // After sim details modal migration, this tab will always be initialized on open. no need of event
    this.fetchSettings();
  }

  fetchSettings() {
    this.nowLoading = true;
    return this.eventHandlersService
      .fetchByImsi(this.imsi)
      .then((data) => {
        this.prefixedEventHandlers = data;
        this.settings = this.prefixedEventHandlersService.parseEventHandlers(data, 'imsi', this.imsi);
        this.groupSettings = this.prefixedEventHandlersService.parseEventHandlers(data, 'group', this.groupId);
        this.operatorSettings = this.prefixedEventHandlersService.parseEventHandlers(data, 'operator', this.groupId);
        this.prefixedEventHandlersForm.$setPristine();
      })
      .catch((response: LegacyAny) => {
        this.alertsService.showError(response.data);
      })
      .finally(() => {
        this.nowLoading = false;
      });
  }

  updateSettings() {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    this.prefixedEventHandlersService
      .updateSettings(this.settings, this.prefixedEventHandlers, this.target, this.imsi)
      .then(() => {
        this.submitting = false;
        this.alertsService.showSuccess('prefixed_event_handlers.messages.saved');
        return this.fetchSettings();
      })
      .catch((response: LegacyAny) => {
        this.submitting = false;
        this.alertsService.showError(response.data);
      });
  }

  canSubmit(): boolean {
    return !this.submitting && this.prefixedEventHandlersForm.$valid && this.isDirty();
  }

  isDirty(): boolean {
    return this.prefixedEventHandlersForm.$dirty;
  }
}
