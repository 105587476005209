import { LegacyAny } from '@soracom/shared/core';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { template } from './gps_multiunit_page.component.html';

import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';

import { SubSink } from 'subsink';
import { DeleteGpsMultiunitConfigComponent } from '../../../src/app/gps-multiunit/delete-gps-multiunit-config/delete-gps-multiunit-config.component';
import { GpsMultiunitStateChangedEvent } from '../../../src/app/gps-multiunit/gps-multiunit/gps-multiunit.component';
import { GpsMultiunitStoreSelectors, RootStoreState } from '../../../src/app/root-store';
import * as Actions from '../../../src/app/root-store/gps-multiunit-store/actions';
import {
  GpsMultiunitPageRouteState,
  GpsMultiunitPageState,
} from '../../../src/app/root-store/gps-multiunit-store/state';
import { UserAction } from '../../../src/app/shared/UserAction';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { SubscribersContainer } from '../core/SubscribersContainer';

export class GpsMultiunitPageComponent implements ng.IComponentOptions {
  bindings = {
    path: '@',
  };
  controller = GpsMultiunitPageComponentController;
  template: any = template;
}

export class GpsMultiunitPageComponentController extends BaseController implements ng.IOnInit, ng.IOnDestroy {
  static $inject: InjectList = [
    '$log',
    '$location',
    '$routeParams',
    '$scope',
    '$translate',
    'AlertsService',
    'CoverageTypeService',
    'UiDsModalService',
    'ngrxStoreService',
  ];

  alertsService: AlertsServiceInstance;
  state: GpsMultiunitPageState = 'index';
  // @ts-expect-error (legacy code incremental fix)
  state$: Observable<GpsMultiunitPageState>;
  // @ts-expect-error (legacy code incremental fix)
  path: string;
  // @ts-expect-error (legacy code incremental fix)
  error$: Observable<Error | string | null>;
  // @ts-expect-error (legacy code incremental fix)
  routeState$: Observable<GpsMultiunitPageRouteState>;
  private subs = new SubSink();

  constructor(
    $log: ng.ILogService,
    private $location: ng.ILocationService,
    private $routeParams: LegacyAny,
    private $scope: ng.IScope,
    private $translate: LegacyAny,
    alertsServiceGenerator: AlertsService,
    private coverageTypeService: CoverageTypeService,
    private modalService: UiDsModalService,
    private store$: Store<RootStoreState.State>
  ) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    this.state$ = this.store$.pipe(select(GpsMultiunitStoreSelectors.selectGpsMultiunitStateValue));
    this.subs.sink = this.state$.subscribe((newState) => (this.state = newState));

    // This is workaround for that NgRx can work with AngularJS $location.
    // TODO: Find better way. yuta 2020/03/13
    this.routeState$ = this.store$.pipe(select(GpsMultiunitStoreSelectors.selectGpsMultiunitPageRouteState));
    this.subs.sink = this.routeState$.subscribe((routeState) => {
      this.updateUrl(routeState);
    });

    this.error$ = this.store$.select(GpsMultiunitStoreSelectors.selectGpsMultiunitError);
    this.subs.sink = this.error$.subscribe((error: LegacyAny) => {
      if (error) {
        this.alertsService.showError(error);
      }
    });

    // Apply $route parameters to application state. This is workaround for that NgRx can work with AngularJS $route.
    // TODO: Find better way. yuta 2020/03/13
    const imsiList = this.$routeParams.imsi ? this.$routeParams.imsi.split(',') : [];
    const groupId = this.$routeParams.groupId;
    const newGroupName = this.$routeParams.newGroupName;
    this.store$.dispatch(Actions.applyRouteParameters({ imsiList, groupId, newGroupName, path: this.path }));
  }

  $onDestroy(): void {
    this.subs.unsubscribe();
  }

  getPageTitle() {
    return this.$translate.instant(`gadgets.gps_multiunit_page.breadcrumb.${this.state}`);
  }

  onUpdateState($event: GpsMultiunitStateChangedEvent) {
    this.debug('onUpdateState is called', $event);
    const state = $event.newState;
    if (state === 'linkToHarvestData') {
      // @ts-expect-error (legacy code incremental fix)
      this.navigateToHarvestDataPage($event.attributes.selectedSubscribers);
      return;
    }
    if (state === 'editGroupConfig') {
      // @ts-expect-error (legacy code incremental fix)
      this.navigateToGroupPage($event.attributes.groupId);
      return;
    }
    if (state === 'removeGpsMultiunitConfig') {
      // @ts-expect-error (legacy code incremental fix)
      this.openDeleteConfigModal($event.attributes.selectedSubscribers);
      return;
    }
  }

  private navigateToHarvestDataPage(subscribers: ExtendedSubscriberInterface[]) {
    const resources = subscribers.map((s) => `Subscriber#${s.imsi}`).join('&');
    this.$location.path('/harvest_data').search({ resources });
    this.$scope.$apply(); // Callback event coming from Angular world doesn't affect anything somehow.
  }

  private navigateToGroupPage(groupId: string) {
    this.$location.path(`/groups/${groupId}`).search({});
    this.$scope.$apply(); // Callback event coming from Angular world doesn't affect anything somehow.
  }

  private openDeleteConfigModal(subscribers: ExtendedSubscriberInterface[]) {
    const modalInput: SubscribersContainer = { subscribers };
    this.modalService
      .openAndWaitForResult(DeleteGpsMultiunitConfigComponent, {
        data: modalInput,
        title:
          modalInput.subscribers.length > 1
            ? 'DeleteGpsMultiunitConfigComponent.heading.multiple'
            : 'DeleteGpsMultiunitConfigComponent.heading.single',
      })
      .then((result: LegacyAny) => {
        if (UserAction.isConfirm(result)) {
          setTimeout(() => {
            this.store$.dispatch(Actions.loadConfiguredSubscribers());
          }, 1000);
        }
      });
  }

  private getPath(state: GpsMultiunitPageState): string {
    switch (state) {
      case 'newConfig':
      case 'selectGroup':
      case 'selectSubscribers':
        return 'new';
      case 'editConfig':
        return 'edit';
      case 'index':
      default:
        return 'index';
    }
  }

  private updateUrl(routeState: GpsMultiunitPageRouteState) {
    this.debug(`[GpsMultiunitPageComponent.updateUrl] routeState: ${JSON.stringify(routeState)}`);
    const path = this.getPath(routeState.status);
    const imsi = routeState.imsiList.length > 0 ? routeState.imsiList.join(',') : undefined;
    if (path === 'index') {
      this.$location.path('/gadgets/gps_multiunit').search({
        imsi: undefined,
        groupId: undefined,
        coverage_type: this.coverageTypeService.getCoverageType(),
      });
    } else if (path === 'new') {
      this.$location.path('/gadgets/gps_multiunit/new').search({
        imsi,
        newGroupName: routeState.newGroupName,
        coverage_type: this.coverageTypeService.getCoverageType(),
      });
    } else if (path === 'edit') {
      this.$location.path(`/gadgets/gps_multiunit/${routeState.groupId}/edit`).search({
        imsi,
        coverage_type: this.coverageTypeService.getCoverageType(),
      });
    } else {
      this.error('No path found: ' + path);
    }
    this.$scope.$applyAsync(); // Callback event coming from Angular world doesn't affect anything somehow.
  }
}
