<div class="ds-rows">
  <ng-container *ngIf="hasSubscription">
    <h2>{{ 'LagoonPlanComponent.title' | translate }}</h2>
    <div class="ds-notice--info">{{ currentPlanDescription() }}</div>
  </ng-container>
  <ng-container>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </ng-container>
  <div class="ds-cols">
    <div class="lagoon-plan ds-span--4" [ngClass]="{ selected: isFree() }">
      <div class="ds-group content">
        <h2 class="plan-title" translate="LagoonPlanComponent.free.text"></h2>
        <p class="description" [innerHTML]="'LagoonPlanComponent.free.description' | translate"></p>
        <div class="button-wrapper">
          <button
            class="ds-button"
            *ngIf="!isFree()"
            (click)="setPlan(LagoonSubscription.free)"
            [disabled]="submitting || !canSelectFree()"
          >
            <span translate="LagoonPlanComponent.switch" *ngIf="canSelectFree()"></span>
            <span translate="LagoonPlanComponent.unavailable" *ngIf="!canSelectFree()"></span>
          </button>
          <i class="glyphicon glyphicon-ok" *ngIf="isFree()"></i>
        </div>
      </div>
    </div>
    <div class="lagoon-plan ds-span--4" [ngClass]="{ selected: isMaker() }">
      <div class="ds-group content">
        <h2 class="plan-title" translate="LagoonPlanComponent.maker.text"></h2>
        <p class="description" [innerHTML]="'LagoonPlanComponent.maker.description' | translate"></p>
        <div class="button-wrapper">
          <button
            class="ds-button"
            *ngIf="!isMaker()"
            (click)="setPlan(LagoonSubscription.maker)"
            [disabled]="submitting"
          >
            <span translate="LagoonPlanComponent.switch"></span>
          </button>
          <i class="glyphicon glyphicon-ok" *ngIf="isMaker()"></i>
        </div>
      </div>
    </div>
    <div class="lagoon-plan ds-span--4" [ngClass]="{ selected: isPro() }">
      <div class="ds-group content">
        <h2 class="plan-title" translate="LagoonPlanComponent.pro.text"></h2>
        <p class="description" [innerHTML]="'LagoonPlanComponent.pro.description' | translate"></p>
        <div class="button-wrapper">
          <button class="ds-button" *ngIf="!isPro()" (click)="setPlan(LagoonSubscription.pro)" [disabled]="submitting">
            <span translate="LagoonPlanComponent.switch"></span>
          </button>
          <i class="glyphicon glyphicon-ok" *ngIf="isPro()"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasSubscription">
    <button
      type="button"
      class="ds-button--primary"
      [disabled]="submitting || !isDirty()"
      appConfirm="confirm"
      confirmI18nPrefix="LagoonPlanComponent.confirmation"
      [confirmI18nParams]="translationValues"
      (onConfirm)="updateSubscription()"
    >
      <span translate="LagoonPlanComponent.upgrade"></span>
    </button>
    <button
      type="button"
      class="ds-button--alert"
      [disabled]="submitting"
      appConfirm="deletion"
      confirmI18nPrefix="LagoonPlanComponent.termination_modal"
      (onConfirm)="cancelSubscription()"
    >
      <span translate="LagoonPlanComponent.terminate"></span>
    </button>
  </div>
</div>
