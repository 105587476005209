export const template = `<app-user-notifications></app-user-notifications>
<div class="x-sigfox_devices-page-root" id="page-wrapper" translate-namespace="sigfox_devices">
  <div>
    <div class="modal-parent"></div>
  </div>
  <div class="alerts-wrapper"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  <div class="ds-rows">
    <div class="ds-cols --spaced">
      <div><button class="btn btn-primary" id="sigfox-devices-register-button"
          ng-disabled="!$ctrl.actionable(&quot;Register&quot;)" ng-click="$ctrl.doAction(&quot;Register&quot;)"><i
            class="glyphicon glyphicon-plus"></i><span class="button-text">{{
            &quot;sigfox_devices.actions.register&quot; | translate }}</span></button>
        <div class="btn-group" uib-dropdown="uib-dropdown"><button class="btn btn-default dropdown-toggle"
            id="sigfox-devices-actions-menu" type="button" uib-dropdown-toggle="uib-dropdown-toggle"><span
              class="button-text" translate="sigfox_devices.actions_menu.title"></span><span
              class="caret"></span></button>
          <ul class="dropdown-menu" id="sigfox-devices-actions-dropdown-menu" role="menu"
            uib-dropdown-menu="uib-dropdown-menu">
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeGroup')"
              id="sigfox-devices-actions-menu-change-group"
              ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeGroup&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;ChangeGroup&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;ChangeGroup&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;ChangeGroup&quot;)">{{
                &quot;sigfox_devices.actions.change_group&quot; | translate }}</a></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('EditTag')"
              id="sigfox-devices-actions-menu-edit-tag"
              ng-class="{ disabled: !$ctrl.actionable(&quot;EditTag&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;EditTag&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;EditTag&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;EditTag&quot;)">{{ &quot;sigfox_devices.actions.edit_tag&quot; |
                translate }}</a></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Downlink')"
              id="sigfox-devices-actions-menu-downlink"
              ng-class="{ disabled: !$ctrl.actionable(&quot;Downlink&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Downlink&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Downlink&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;Downlink&quot;)">{{ &quot;sigfox_devices.actions.downlink&quot;
                | translate }}</a></li>
            <li class="divider"></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Logs')" id="sigfox-devices-actions-menu-logs"
              ng-class="{ disabled: !$ctrl.actionable(&quot;Logs&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Logs&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Logs&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;Logs&quot;)">{{ &quot;sigfox_devices.actions.logs&quot; |
                translate }}</a></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Data')" id="sigfox-devices-actions-menu-data"
              ng-class="{ disabled: !$ctrl.actionable(&quot;Data&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Data&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Data&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;Data&quot;)">{{ &quot;sigfox_devices.actions.data&quot; |
                translate }}</a></li>
            <li class="divider"></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeTerminationProtection')"
              id="sigfox-devices-actions-menu-change-termination-protection"
              ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeTerminationProtection&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;ChangeTerminationProtection&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;ChangeTerminationProtection&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;ChangeTerminationProtection&quot;)">{{
                &quot;sigfox_devices.actions.change_termination_protection&quot; | translate }}</a></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Terminate')"
              id="sigfox-devices-actions-menu-terminate"
              ng-class="{ disabled: !$ctrl.actionable(&quot;Terminate&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Terminate&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Terminate&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;Terminate&quot;)">{{
                &quot;sigfox_devices.actions.terminate&quot; | translate }}</a></li>
            <li class="divider"></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ShowPac')"
              id="sigfox-devices-context-menu-show-pac" ng-class="{ disabled: !$ctrl.actionable(&quot;ShowPac&quot;) }">
              <a role="menuitem" href="" ng-click="$ctrl.doAction(&quot;ShowPac&quot;)">{{
                &quot;sigfox_devices.actions.show_pac&quot; | translate }}</a></li>
          </ul>
        </div><button class="btn btn-default" id="table-settings-button" data-testid="ng1-table-settings-button" ng-click="$ctrl.openTableSettingsModal()"><i
            class="glyphicon glyphicon-cog"></i></button>
      </div>
      <div><sc-pagination paginator="$ctrl.paginator" ctrl="$ctrl"></sc-pagination></div>
    </div>
    <div ng-if="!$ctrl.browserIsBroken"><ui-search [context]="$ctrl.searchContext" [parent-load-state]="$ctrl.loadState"
        (changed)="$ctrl.onSigfoxSearchQueryChanged($event)"></ui-search></div>
    <div>
      <ul class="dropdown-menu" id="sigfox-devices-context-menu" role="menu" uib-dropdown-menu="uib-dropdown-menu"
        ng-if="$ctrl.shouldShowContextMenu()">
        <li class="dropdown-header" translate="sigfox_devices.context_menu.header"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeGroup')"
          id="sigfox-devices-context-menu-change-group"
          ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeGroup&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;ChangeGroup&quot;)">{{ &quot;sigfox_devices.actions.change_group&quot; |
            translate }}</a></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('EditTag')"
          id="sigfox-devices-context-menu-edit-tag" ng-class="{ disabled: !$ctrl.actionable(&quot;EditTag&quot;) }"><a
            role="menuitem" href="" ng-click="$ctrl.doAction(&quot;EditTag&quot;)">{{
            &quot;sigfox_devices.actions.edit_tag&quot; | translate }}</a></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Downlink')"
          id="sigfox-devices-context-menu-downlink" ng-class="{ disabled: !$ctrl.actionable(&quot;Downlink&quot;) }"><a
            role="menuitem" href="" ng-click="$ctrl.doAction(&quot;Downlink&quot;)">{{
            &quot;sigfox_devices.actions.downlink&quot; | translate }}</a></li>
        <li class="divider"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Logs')" id="sigfox-devices-context-menu-logs"
          ng-class="{ disabled: !$ctrl.actionable(&quot;Logs&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;Logs&quot;)">{{ &quot;sigfox_devices.actions.logs&quot; | translate }}</a>
        </li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Data')" id="sigfox-devices-context-menu-data"
          ng-class="{ disabled: !$ctrl.actionable(&quot;Data&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;Data&quot;)">{{ &quot;sigfox_devices.actions.data&quot; | translate }}</a>
        </li>
        <li class="divider"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeTerminationProtection')"
          id="sigfox-devices-context-menu-change-termination-protection"
          ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeTerminationProtection&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;ChangeTerminationProtection&quot;)">{{
            &quot;sigfox_devices.actions.change_termination_protection&quot; | translate }}</a></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Terminate')"
          id="sigfox-devices-context-menu-terminate" ng-class="{ disabled: !$ctrl.actionable(&quot;Terminate&quot;) }">
          <a role="menuitem" href="" ng-click="$ctrl.doAction(&quot;Terminate&quot;)">{{
            &quot;sigfox_devices.actions.terminate&quot; | translate }}</a></li>
        <li class="divider"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ShowPac')"
          id="sigfox-devices-context-menu-show-pac" ng-class="{ disabled: !$ctrl.actionable(&quot;ShowPac&quot;) }"><a
            role="menuitem" href="" ng-click="$ctrl.doAction(&quot;ShowPac&quot;)">{{
            &quot;sigfox_devices.actions.show_pac&quot; | translate }}</a></li>
      </ul>
      <div class="table-responsive table-sigfox-devices">
        <table class="sc-table table ds-datatable--striped x-sigfox-devices-table" id="sigfox-devices-table">
          <thead>
            <tr>
              <th class="th-checkbox ds-datatable__col--min" id="sigfox-devices-th-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check"
                    id="sigfox-devices-checkbox-for-all" type="checkbox" ng-model="$ctrl.checkboxForAll"
                    ng-change="$ctrl.checkboxForAllChanged($ctrl.checkboxForAll);" />
                  <div class="ds-checkbox__label">Check All</div>
                </div>
              </th>
              <th id="sigfox-devices-th-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)">{{
                &quot;sigfox_devices.columns.id&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.id.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)">{{
                &quot;sigfox_devices.columns.name&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.name.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-group" ng-if="$ctrl.shouldShowColumn(&quot;group&quot;)">{{
                &quot;sigfox_devices.columns.group&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.group.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-status" ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)">{{
                &quot;sigfox_devices.columns.status&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.status.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-registration-status"
                ng-if="$ctrl.shouldShowColumn(&quot;registration_status&quot;)">{{
                &quot;sigfox_devices.columns.registration_status&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.registration_status.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-registered-time" ng-if="$ctrl.shouldShowColumn(&quot;registered_time&quot;)">{{
                &quot;sigfox_devices.columns.registered_time&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.registered_time.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-activated-time" ng-if="$ctrl.shouldShowColumn(&quot;activated_time&quot;)">{{
                &quot;sigfox_devices.columns.activated_time&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.activated_time.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-lqi" ng-if="$ctrl.shouldShowColumn(&quot;lqi&quot;)">{{
                &quot;sigfox_devices.columns.lqi&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.lqi.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-last-seen" ng-if="$ctrl.shouldShowColumn(&quot;last_seen&quot;)">{{
                &quot;sigfox_devices.columns.last_seen&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.last_seen.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-termination-protection"
                ng-if="$ctrl.shouldShowColumn(&quot;termination_protection&quot;)">{{
                &quot;sigfox_devices.columns.termination_protection&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.termination_protection.description' | translate"></a>
                      </li>
                    </ul>
                  </div>
                </span></th>
              <th id="sigfox-devices-th-product-certification-id"
                ng-if="$ctrl.shouldShowColumn(&quot;product_certification_id&quot;)">{{
                &quot;sigfox_devices.columns.product_certification_id&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'sigfox_devices.legends.product_certification_id.description' | translate"></a>
                      </li>
                    </ul>
                  </div>
                </span></th>
            </tr>
          </thead>
          <tbody class="x-sigfox-devices-list" ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()">
            <tr ng-repeat="row in $ctrl.tableData.rows" ng-click="$ctrl.setSelected(row)"
              sc-context-menu="sigfox-devices-context-menu">
              <td class="td-checkbox sigfox-devices-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check checkbox-for-each"
                    id="cb{{row.obj.id}}" type="checkbox" ng-model="row.selected"
                    ng-change="$ctrl.checkboxForEachChanged()" ng-click="$event.stopPropagation()" />
                  <div class="ds-checkbox__label">Check {{ row.obj.id }}</div>
                </div>
              </td>
              <td class="sigfox-devices-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)" ng-bind="row.obj.id"></td>
              <td class="sigfox-devices-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)"><sc-inplace-editable-text
                  ng-if="$ctrl.editable" ng-mouseover="row.hoverName = true" ng-mouseleave="row.hoverName = false"
                  key="name" cancel-on-blur="true" display-value="row.obj.name" edit-ctrl="row.nameEditCtrl"
                  padding-left="&quot;5&quot;" show-pencil-icon="row.hoverName" show-remove-button="false"
                  show-save-button="false" show-cancel-button="false"></sc-inplace-editable-text><span
                  ng-if="!$ctrl.editable" ng-bind="row.obj.name"></span></td>
              <td class="sigfox-devices-group feedback" ng-if="$ctrl.shouldShowColumn(&quot;group&quot;)"
                sc-update-feedback="sc-update-feedback"
                feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                feedback-type="row.feedback.updateGroup" feedback-duration="3000">
                <div class="overflow-auto"><a ng-href="/sigfox_device_groups/{{ row.obj.groupId }}"><span>{{
                      row.obj.groupName() }}</span></a></div>
              </td>
              <td class="sigfox-devices-status feedback" ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)"
                sc-update-feedback="sc-update-feedback"
                feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                feedback-type="row.feedback.updateStatus" feedback-duration="3000"><sc-sigfox-device-status-label
                  obj="row.obj"></sc-sigfox-device-status-label></td>
              <td ng-class="['sigfox-devices-registration-status', 'registration-status' + row.obj.registrationStatus]"
                ng-if="$ctrl.shouldShowColumn(&quot;registration_status&quot;)"><span
                  ng-if="row.obj.registrationStatus">{{ 'sigfox_devices.values.registration_status.' +
                  row.obj.registrationStatus | translate }}</span><span class="sigfox-registration-error-info"
                  ng-if="row.obj.registrationStatus === 'failed'">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a
                      class="sigfox-registration-error-info-button dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle" ng-click="$event.stopPropagation();"><i
                        class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="sigfox-device-error-info dropdown-menu-right" uib-dropdown-menu="uib-dropdown-menu"
                      sc-context-menu="default" role="menu">
                      <li class="text-muted" ng-click="$event.stopPropagation();">
                        <div class="error-details-title">{{ 'sigfox_devices.label_registration_error_details' |
                          translate }}</div>
                        <p>{{ row.obj.registrationError || ('sigfox_devices.errors.unspecified_registration_error' |
                          translate) }}</p>
                      </li>
                    </ul>
                  </div>
                </span></td>
              <td class="sigfox-devices-registered-time" ng-if="$ctrl.shouldShowColumn(&quot;registered_time&quot;)"
                sc-date-time="row.obj.registeredTime" alt-text=""></td>
              <td class="sigfox-devices-activated-time" ng-if="$ctrl.shouldShowColumn(&quot;activated_time&quot;)"
                sc-date-time="row.obj.activatedTime" alt-text=""></td>
              <td ng-class="['sigfox-devices-lqi', 'lqi' + row.obj.lqi]"
                ng-if="$ctrl.shouldShowColumn(&quot;lqi&quot;)"><span ng-if="row.obj.lqi">{{
                  'sigfox_devices.values.lqi.' + row.obj.lqi | translate }}</span></td>
              <td class="sigfox-device-last-seen" ng-if="$ctrl.shouldShowColumn(&quot;last_seen&quot;)"><span
                  sc-date-time="row.obj.lastSeen.time" alt-text=""
                  ng-if="row.obj.lastSeen &amp;&amp; row.obj.lastSeen.time"></span></td>
              <td class="sigfox-devices-termination-protection"
                ng-if="$ctrl.shouldShowColumn(&quot;termination_protection&quot;)"
                sc-update-feedback="sc-update-feedback"
                feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                feedback-type="row.feedback.changeTerminationProtection" feedback-duration="3000"><i
                  class="ds-icon --icon-unlock x-tp-disabled" ng-if="row.obj.terminationEnabled"
                  style="color: lightgray;"></i><i class="ds-icon --icon-lock x-tp-enabled"
                  ng-if="!row.obj.terminationEnabled"></i></td>
              <td class="sigfox-devices-product-certification-id"
                ng-if="$ctrl.shouldShowColumn(&quot;product_certification_id&quot;)"
                ng-bind="row.obj.productCertificationId"></td>
            </tr>
          </tbody>
          <tbody class="x-no-sigfox-devices-exist" ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()">
            <tr class="no-data">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.no_data' |
                translate }}</td>
            </tr>
          </tbody>
          <tbody class="x-is-loading" ng-if="$ctrl.isLoading()">
            <tr class="loading">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.loading' |
                translate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div><sc-tutorial-component></sc-tutorial-component>`;
