import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';

import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { SubscriberLteMButtonConfigDeleter } from './SubscriberLteMButtonConfigDeleter';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { Logger } from '@soracom/shared/logger';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { SubscribersContainer } from 'apps/user-console/app/shared/core/SubscribersContainer';

@Component({
  selector: 'app-delete-lte-m-button-config',
  templateUrl: './delete-lte-m-button-config.component.html',
})
export class DeleteLteMButtonConfigComponent
  extends DsModalContentBase<SubscribersContainer, ExtendedSubscriberInterface[]>
  implements OnInit
{
  className = 'DeleteLteMButtonConfigComponent';
  state: 'updating' | 'ready' = 'ready';
  // @ts-expect-error (legacy code incremental fix)
  updater: SubscriberLteMButtonConfigDeleter;
  alertsManager = new AlertsManager();

  constructor(
    private logger: Logger,
    private apiService: SoracomApiService,
  ) {
    super();
  }

  ngOnInit() {
    // do nothing
    this.updater = new SubscriberLteMButtonConfigDeleter(this.logger, this.apiService, this.inputSubscribers);
  }

  get inputSubscribers() {
    return this.getInput().subscribers;
  }

  get atLeastOneSubscriberWillBeChanged() {
    return !this.inputSubscribers.every((s) => s.groupId === null || s.groupId === undefined);
  }

  get canConfirm() {
    return this.state === 'ready' && this.atLeastOneSubscriberWillBeChanged && this.updater.state !== 'busy';
  }

  canClose() {
    return this.updater.state !== 'busy';
  }

  async performUpdate() {
    try {
      const updater = await this.updater.performUpdate();
      if (updater.state !== 'succeeded') {
        throw new Error(`${this.className}.error.updateFailed`);
      }
      this.close(this.output);
    } catch (error: LegacyAny) {
      this.alertsManager.add(Alert.danger(error.message || `${error}`));
    }
  }

  confirm() {
    this.performUpdate();
    return;
  }

  get output() {
    return this.updater.subscribers;
  }
}
