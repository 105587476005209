import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';

import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { AirConfiguration, Group } from '@soracom/shared/group';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscribersService } from '../../../../app/shared/subscribers/subscribers.service';

import { GpsMultiunitStoreSelectors, RootStoreState } from '../../root-store';
import * as Actions from '../../root-store/gps-multiunit-store/actions';
import { GMDate } from '../../shared/gps_multiunit/GMDate';
import { GMTime } from '../../shared/gps_multiunit/GMTime';
import { GpsMultiunitConfig } from '../../shared/gps_multiunit/GpsMultiunitConfig';
import {
  DefaultGpsMultiunitConfigData,
  GMMode,
  GMNull,
  GMWeekday,
} from '../../shared/gps_multiunit/GpsMultiunitConfigData';
import {
  DefaultEndTime,
  DefaultStartTime,
  isNoDateLimit,
  isNoTimeLimit,
  isNoWeekdaysLimit,
  MaximumEndDate,
  MinimumStartDate,
  Weekdays,
} from '../../shared/gps_multiunit/GpsMultiunitScenario';
import { SoracomUserConsole } from '../../shared/SoracomUserConsole';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import {
  SUBSCRIBER_TAG_NAME_GADGET,
  SUBSCRIBER_TAG_VALUE_GPS_MULTIUNIT_IN_USE,
} from '../gps-multiunit/gps-multiunit.component';
import { dateValidator, timeValidator } from './GpsMultiunitConfigValidators';
import { GroupOrGroupParam, isGroup } from '../../root-store/gps-multiunit-store/state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-gps-multiunit-config',
  templateUrl: './gps-multiunit-config.component.html',
  styleUrls: ['./gps-multiunit-config.component.scss'],
})
export class GpsMultiunitConfigComponent implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);

  // @ts-expect-error (legacy code incremental fix)
  group$: Observable<GroupOrGroupParam>;
  // @ts-expect-error (legacy code incremental fix)
  subscribers$: Observable<ExtendedSubscriberInterface[]>;

  // FIXME: Remove it
  // @ts-expect-error (legacy code incremental fix)
  group: GroupOrGroupParam;
  // FIXME: Remove it
  subscribers: ExtendedSubscriberInterface[] = [];

  // @ts-expect-error (legacy code incremental fix)
  config: GpsMultiunitConfig;
  weekdays = Weekdays;

  weekdayOptions = Weekdays.reduce<{ [id: string]: FormControl<boolean> }>((obj, curr) => {
    // @ts-expect-error (legacy code incremental fix)
    obj[curr.toString()] = this.fb.control({ value: true, disabled: true });
    return obj;
  }, {});
  form = this.fb.group({
    mode: this.fb.control('MANUAL', [Validators.required]),
    fetchConfigurationMode: this.fb.control('AUTO', [Validators.required]),
    accelerator: this.fb.control(false, [Validators.required]),
    battery: this.fb.control({ value: true, disabled: true }, [Validators.required]),
    humidity: this.fb.control(true, [Validators.required]),
    location: this.fb.control(true, [Validators.required]),
    temperature: this.fb.control(true, [Validators.required]),
    SoracomBeam: this.fb.control({ value: false, disabled: true }),
    SoracomFunnel: this.fb.control({ value: false, disabled: true }),
    SoracomFunk: this.fb.control({ value: false, disabled: true }),
    SoracomHarvest: this.fb.control({ value: false, disabled: false }, [Validators.required]),
    accelerationEventThreshold: this.fb.control({ value: 2048, disabled: true }, [
      Validators.min(62),
      Validators.max(7874),
    ]),
    isEnabledAccelerationEvent: this.fb.control(false, [Validators.required]),
    intervalScenario: this.fb.group(
      {
        duration: this.fb.control(60, [Validators.required, Validators.min(1), Validators.max(1440)]),
        noDateLimit: this.fb.control(true),
        startDate: this.fb.control({ value: MinimumStartDate.format('YYYY-MM-DD'), disabled: true }, [
          Validators.required,
        ]),
        endDate: this.fb.control({ value: MaximumEndDate.format('YYYY-MM-DD'), disabled: true }, [Validators.required]),
        noTimeLimit: this.fb.control(true),
        startTime: this.fb.control({ value: DefaultStartTime, disabled: true }, [Validators.required]),
        endTime: this.fb.control({ value: DefaultEndTime, disabled: true }, [Validators.required]),
        noWeekdayLimit: this.fb.control(true),
        weekdays: this.fb.group(this.weekdayOptions),
      },
      { validators: [dateValidator, timeValidator] }
    ),
    fetchConfigurationScenario: this.fb.group(
      {
        duration: this.fb.control(1440, [Validators.required, Validators.min(1), Validators.max(1440)]),
        noDateLimit: this.fb.control(true),
        startDate: this.fb.control({ value: MinimumStartDate.format('YYYY-MM-DD'), disabled: true }, [
          Validators.required,
        ]),
        endDate: this.fb.control({ value: MaximumEndDate.format('YYYY-MM-DD'), disabled: true }, [Validators.required]),
        noTimeLimit: this.fb.control(false),
        startTime: this.fb.control({ value: '02:00', disabled: false }, [Validators.required]),
        endTime: this.fb.control({ value: '03:00', disabled: false }, [Validators.required]),
        noWeekdayLimit: this.fb.control(true),
        weekdays: this.fb.group(this.weekdayOptions),
      },
      { validators: [dateValidator, timeValidator] }
    ),
  });
  private logger = SoracomUserConsole.shared.logger;

  constructor(
    private fb: FormBuilder,
    private store$: Store<RootStoreState.State>,
    private modalService: UiDsModalService,
    private subscribersService: SubscribersService
  ) {}

  // save = async () => {
  //   const newConfig: GpsMultiunitConfig = this.applyFormValue(this.config, this.form.getRawValue());
  //   const group = this.group.id ? this.group : await this.groupsService.create(this.group.name);
  //   const isSoracomHarvestEnabled = this.form.value.SoracomHarvest;
  //   this.store$.dispatch(Actions.saveConfiguration({ group, newConfig, isSoracomHarvestEnabled }));
  // }

  save = async () => {
    const newConfig: GpsMultiunitConfig = this.applyFormValue(this.config, this.form.getRawValue());
    // Enable metadata service
    const metadata: AirConfiguration['metadata'] = (isGroup(this.group) &&
      this.group.configuration.SoracomAir &&
      this.group.configuration.SoracomAir.metadata) || {
      enabled: false,
      minimizeResponseBody: false,
      allowOrigin: '',
      readonly: true,
    };
    const copiedMetadata = cloneDeep(metadata);
    copiedMetadata.enabled = true;
    const group = isGroup(this.group) ? this.group : await groupsService.create(this.group.name);
    await groupsService.updateConfiguration(group.id, 'SoracomAir', [
      {
        key: 'userdata',
        value: newConfig.toJSON(),
      },
      {
        key: 'metadata',
        value: copiedMetadata,
      },
    ]);

    if (!isGroup(this.group) || this.group.configuration.SoracomHarvest?.enabled !== this.form.value.SoracomHarvest) {
      await groupsService.updateConfiguration(group.id, 'SoracomHarvest', [
        {
          key: 'enabled',
          value: this.form.value.SoracomHarvest,
        },
      ]);
    }

    const promises: LegacyAny = [];
    this.logger.debug(this.subscribers);
    this.subscribers.forEach((subscriber) => {
      if (subscriber.groupId !== group.id) {
        promises.push(
          new Promise((resolve, reject) => {
            this.subscribersService
              .setGroup(subscriber.imsi, group.id)
              .then((s: LegacyAny) => {
                resolve(s);
              })
              .catch((e: LegacyAny) => reject(e));
          })
        );
      }
      if (subscriber.tags[SUBSCRIBER_TAG_NAME_GADGET] !== SUBSCRIBER_TAG_VALUE_GPS_MULTIUNIT_IN_USE) {
        promises.push(
          new Promise((resolve, reject) => {
            this.subscribersService
              .updateTags(subscriber.imsi, [
                { tagName: SUBSCRIBER_TAG_NAME_GADGET, tagValue: SUBSCRIBER_TAG_VALUE_GPS_MULTIUNIT_IN_USE },
              ])
              .then((s: LegacyAny) => {
                resolve(s);
              })
              .catch((e: LegacyAny) => reject(e));
          })
        );
      }
    });

    await Promise.allSettled(promises);

    await this.modalService.openGenericConfirmModal('GpsMultiunitConfigComponent.dialog.configurationSaved', {
      hideCancelButton: true,
    });

    this.store$.dispatch(Actions.navigateToIndexPage());
  };

  back = () => {
    this.logger.debug('back called');
    if (this.subscribers && this.subscribers.length > 0) {
      this.store$.dispatch(Actions.navigateToSelectGroupPage());
    } else {
      this.store$.dispatch(Actions.navigateToIndexPage());
    }
  };

  buttonBar = UiButtonBar.configure((bar) => {
    bar.leftButtons = [
      UiButton.configure((b) => {
        b.buttonStyle = 'primary';
        b.titleId = 'GpsMultiunitConfigComponent.actions.save.title';
        b.classes = ['x-save-config'];
        b.isDisabled_ƒ = () => {
          return this.form.invalid;
        };
        b.onClick = this.save;
      }),
      UiButton.configure((b) => {
        b.titleId = 'GpsMultiunitConfigComponent.actions.back.title';
        b.classes = ['x-back'];
        b.onClick = this.back;
      }),
    ];
  });

  ngOnInit() {
    this.group$ = this.store$.select(GpsMultiunitStoreSelectors.selectGpsMultiunitGroup);
    this.subscribers$ = this.store$.select(GpsMultiunitStoreSelectors.selectGpsMultiunitSelectedSubscribers);
    this.group$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((group) => {
      if (group) {
        this.group = group;
        this.loadGpsMultiunitConfig(group);
      }
    });
    this.subscribers$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subscribers) => {
      this.subscribers = subscribers;
    });

    const isFormGroup = this.form.get('intervalScenario');
    // @ts-expect-error (legacy code incremental fix)
    isFormGroup.get('noDateLimit').valueChanges.subscribe((val) => {
      // @ts-expect-error (legacy code incremental fix)
      this.setDateControls(isFormGroup, val);
    });
    // @ts-expect-error (legacy code incremental fix)
    isFormGroup.get('noTimeLimit').valueChanges.subscribe((val) => {
      // @ts-expect-error (legacy code incremental fix)
      this.setTimeControls(isFormGroup, val);
    });
    // @ts-expect-error (legacy code incremental fix)
    isFormGroup.get('noWeekdayLimit').valueChanges.subscribe((val) => {
      // @ts-expect-error (legacy code incremental fix)
      this.setWeekdayControls(isFormGroup, val);
    });
    const fcFormGroup = this.form.get('fetchConfigurationScenario');
    // @ts-expect-error (legacy code incremental fix)
    fcFormGroup.get('noDateLimit').valueChanges.subscribe((val) => {
      // @ts-expect-error (legacy code incremental fix)
      this.setDateControls(fcFormGroup, val);
    });
    // @ts-expect-error (legacy code incremental fix)
    fcFormGroup.get('noTimeLimit').valueChanges.subscribe((val) => {
      // @ts-expect-error (legacy code incremental fix)
      this.setTimeControls(fcFormGroup, val);
    });
    // @ts-expect-error (legacy code incremental fix)
    fcFormGroup.get('noWeekdayLimit').valueChanges.subscribe((val) => {
      // @ts-expect-error (legacy code incremental fix)
      this.setWeekdayControls(fcFormGroup, val);
    });
    // @ts-expect-error (legacy code incremental fix)
    this.form.get('isEnabledAccelerationEvent').valueChanges.subscribe((val) => {
      if (val) {
        // @ts-expect-error (legacy code incremental fix)
        this.form.get('accelerationEventThreshold').enable();
      } else {
        // @ts-expect-error (legacy code incremental fix)
        this.form.get('accelerationEventThreshold').disable();
      }
    });
    // @ts-expect-error (legacy code incremental fix)
    this.form.get('mode').valueChanges.subscribe((val) => {
      if (val === GMMode.MANUAL) {
        // @ts-expect-error (legacy code incremental fix)
        isFormGroup.enable();
        // @ts-expect-error (legacy code incremental fix)
        this.setDateControls(isFormGroup, this.form.value.intervalScenario.noDateLimit);
        // @ts-expect-error (legacy code incremental fix)
        this.setTimeControls(isFormGroup, this.form.value.intervalScenario.noTimeLimit);
        // @ts-expect-error (legacy code incremental fix)
        this.setWeekdayControls(isFormGroup, this.form.value.intervalScenario.noWeekdayLimit);
      } else {
        // @ts-expect-error (legacy code incremental fix)
        isFormGroup.disable();
      }
    });
    // @ts-expect-error (legacy code incremental fix)
    this.form.get('fetchConfigurationMode').valueChanges.subscribe((val) => {
      if (val === GMMode.AUTO) {
        // @ts-expect-error (legacy code incremental fix)
        fcFormGroup.enable();
        // @ts-expect-error (legacy code incremental fix)
        this.setDateControls(fcFormGroup, this.form.value.fetchConfigurationScenario.noDateLimit);
        // @ts-expect-error (legacy code incremental fix)
        this.setTimeControls(fcFormGroup, this.form.value.fetchConfigurationScenario.noTimeLimit);
        // @ts-expect-error (legacy code incremental fix)
        this.setWeekdayControls(fcFormGroup, this.form.value.fetchConfigurationScenario.noWeekdayLimit);
      } else {
        // @ts-expect-error (legacy code incremental fix)
        fcFormGroup.disable();
      }
    });
  }

  private setDateControls(fg: AbstractControl, val: boolean) {
    if (val) {
      // @ts-expect-error (legacy code incremental fix)
      fg.get('startDate').disable();
      // @ts-expect-error (legacy code incremental fix)
      fg.get('endDate').disable();
    } else {
      // @ts-expect-error (legacy code incremental fix)
      fg.get('startDate').enable();
      // @ts-expect-error (legacy code incremental fix)
      fg.get('endDate').enable();
    }
  }

  private setTimeControls(fg: AbstractControl, val: boolean) {
    const mode = this.form.value.mode;
    if (val) {
      // @ts-expect-error (legacy code incremental fix)
      fg.get('startTime').disable();
      // @ts-expect-error (legacy code incremental fix)
      fg.get('endTime').disable();
    } else {
      // @ts-expect-error (legacy code incremental fix)
      fg.get('startTime').enable();
      // @ts-expect-error (legacy code incremental fix)
      fg.get('endTime').enable();
    }
  }

  private setWeekdayControls(fg: AbstractControl, val: boolean) {
    if (val) {
      this.weekdays.forEach((weekday) => {
        // @ts-expect-error (legacy code incremental fix)
        fg.get('weekdays').get(weekday).disable();
      });
    } else {
      this.weekdays.forEach((weekday) => {
        // @ts-expect-error (legacy code incremental fix)
        fg.get('weekdays').get(weekday).enable();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group && changes.group.currentValue) {
      this.loadGpsMultiunitConfig(changes.group.currentValue);
    }
  }

  hasDateError() {
    return this.dateErrors.length > 0;
  }

  get dateErrors() {
    // @ts-expect-error (legacy code incremental fix)
    if (this.form.get('intervalScenario').invalid) {
      // WARN: This code is not smart to split errors about Date and Time
      // @ts-expect-error (legacy code incremental fix)
      return Object.keys(this.form.get('intervalScenario').errors || {}).filter((e) => /Date/.test(e));
    } else {
      return [];
    }
  }

  hasInvalidTimeRangeError() {
    // @ts-expect-error (legacy code incremental fix)
    if (this.form.get('intervalScenario').invalid) {
      // @ts-expect-error (legacy code incremental fix)
      const errors = this.form.get('intervalScenario').errors;
      return errors && errors.invalidTimeRange;
    }
  }

  private loadGpsMultiunitConfig(group: GroupOrGroupParam) {
    const userdata = isGroup(group) && group.configuration.SoracomAir && group.configuration.SoracomAir.userdata;
    if (userdata) {
      try {
        this.config = new GpsMultiunitConfig(userdata);
      } catch (e) {
        this.logger.error(e);
        this.config = new GpsMultiunitConfig(DefaultGpsMultiunitConfigData);
      }
    } else {
      this.config = new GpsMultiunitConfig(DefaultGpsMultiunitConfigData);
    }
    this.form.patchValue({
      mode: this.config.mode,
      accelerator: this.config.accelerator,
      battery: true, // it's always true. this.config.battery,
      humidity: this.config.humidity,
      temperature: this.config.temperature,
      location: this.config.location,
      accelerationEventThreshold: this.config.accelerationEventThreshold,
      isEnabledAccelerationEvent: this.config.isEnabledAccelerationEvent,
      intervalScenario: {
        duration: this.config.intervalScenario.duration,
        noDateLimit: isNoDateLimit(this.config.intervalScenario),
        startDate: this.config.intervalScenario.startDate.toString('-'),
        endDate: this.config.intervalScenario.endDate.toString('-'),
        noTimeLimit: isNoTimeLimit(this.config.intervalScenario),
        startTime: this.config.intervalScenario.startTime.toString(),
        endTime: this.config.intervalScenario.endTime.toString(),
        noWeekdayLimit: isNoWeekdaysLimit(this.config.intervalScenario),
        weekdays: this.generateWeekdayOptions(this.config.intervalScenario.weekdays),
      },
      fetchConfigurationMode: this.config.fetchConfigurationMode,
      fetchConfigurationScenario: {
        duration: this.config.fetchConfigurationScenario.duration,
        noDateLimit: isNoDateLimit(this.config.fetchConfigurationScenario),
        startDate: this.config.fetchConfigurationScenario.startDate.toString('-'),
        endDate: this.config.fetchConfigurationScenario.endDate.toString('-'),
        noTimeLimit: isNoTimeLimit(this.config.fetchConfigurationScenario),
        startTime: this.config.fetchConfigurationScenario.startTime.toString(),
        endTime: this.config.fetchConfigurationScenario.endTime.toString(),
        noWeekdayLimit: isNoWeekdaysLimit(this.config.fetchConfigurationScenario),
        weekdays: this.generateWeekdayOptions(this.config.fetchConfigurationScenario.weekdays),
      },
      SoracomBeam: isGroup(group) && this.isBeamEnabled(group),
      SoracomFunk: isGroup(group) && this.isFunkEnabled(group),
      SoracomFunnel: isGroup(group) && this.isFunnelEnabled(group),
      SoracomHarvest: isGroup(group) && this.isHarvestEnabled(group),
    });
  }

  private isBeamEnabled(group: Group) {
    if (!group.configuration.SoracomBeam) {
      return false;
    }

    if (!group.configuration.SoracomBeam['udp://beam.soracom.io:23080']) {
      return false;
    }
    return group.configuration.SoracomBeam['udp://beam.soracom.io:23080'].enabled;
  }

  private isFunkEnabled(group: Group) {
    if (!group.configuration.SoracomFunk) {
      return false;
    }

    return group.configuration.SoracomFunk.enabled;
  }

  private isFunnelEnabled(group: Group) {
    if (!group.configuration.SoracomFunnel) {
      return false;
    }

    return group.configuration.SoracomFunnel.enabled;
  }

  private isHarvestEnabled(group: Group) {
    if (!group.configuration.SoracomHarvest) {
      return false;
    }

    return group.configuration.SoracomHarvest.enabled;
  }

  private generateWeekdayOptions(values: GMNull | Array<GMWeekday | GMNull>) {
    if (values === 'null') {
      return this.weekdays.reduce<{ [weekday: string]: boolean }>((obj, curr) => {
        obj[curr] = false;
        return obj;
      }, {});
    } else {
      return this.weekdays.reduce<{ [weekday: string]: boolean }>((obj, curr, idx) => {
        obj[curr] = values[idx] === curr;
        return obj;
      }, {});
    }
  }

  private applyFormValue(config: GpsMultiunitConfig, value: { [key: string]: any }): GpsMultiunitConfig {
    config.accelerator = value.accelerator;
    config.battery = value.battery;
    config.humidity = value.humidity;
    config.temperature = value.temperature;
    config.location = value.location;
    config.accelerationEventThreshold = value.accelerationEventThreshold;
    config.isEnabledAccelerationEvent = value.isEnabledAccelerationEvent;

    config.mode = value.mode;
    config.intervalScenario.duration = value.intervalScenario.duration;
    if (value.intervalScenario.noDateLimit) {
      config.intervalScenario.startDate = GMDate.parse(MinimumStartDate.format('YYYY/MM/DD'));
      config.intervalScenario.endDate = GMDate.parse(MaximumEndDate.format('YYYY/MM/DD'));
    } else {
      config.intervalScenario.startDate = GMDate.parse(value.intervalScenario.startDate);
      config.intervalScenario.endDate = GMDate.parse(value.intervalScenario.endDate);
    }
    if (value.intervalScenario.noTimeLimit) {
      config.intervalScenario.startTime = GMTime.parse(DefaultStartTime);
      config.intervalScenario.endTime = GMTime.parse(DefaultEndTime);
    } else {
      config.intervalScenario.startTime = GMTime.parse(value.intervalScenario.startTime);
      config.intervalScenario.endTime = GMTime.parse(value.intervalScenario.endTime);
    }
    if (value.intervalScenario.noWeekdayLimit) {
      config.intervalScenario.weekdays = this.weekdays;
    } else {
      config.intervalScenario.weekdays = this.weekdays.map((w) =>
        value.intervalScenario.weekdays[w] === true ? w : 'null'
      );
    }

    config.fetchConfigurationScenario.duration = value.fetchConfigurationScenario.duration;
    if (value.fetchConfigurationScenario.noDateLimit) {
      config.fetchConfigurationScenario.startDate = GMDate.parse(MinimumStartDate.format('YYYY/MM/DD'));
      config.fetchConfigurationScenario.endDate = GMDate.parse(MaximumEndDate.format('YYYY/MM/DD'));
    } else {
      config.fetchConfigurationScenario.startDate = GMDate.parse(value.fetchConfigurationScenario.startDate);
      config.fetchConfigurationScenario.endDate = GMDate.parse(value.fetchConfigurationScenario.endDate);
    }
    if (value.fetchConfigurationScenario.noTimeLimit) {
      config.fetchConfigurationScenario.startTime = GMTime.parse(DefaultStartTime);
      config.fetchConfigurationScenario.endTime = GMTime.parse(DefaultEndTime);
    } else {
      config.fetchConfigurationScenario.startTime = GMTime.parse(value.fetchConfigurationScenario.startTime);
      config.fetchConfigurationScenario.endTime = GMTime.parse(value.fetchConfigurationScenario.endTime);
    }
    if (value.fetchConfigurationScenario.noWeekdayLimit) {
      config.fetchConfigurationScenario.weekdays = this.weekdays;
    } else {
      config.fetchConfigurationScenario.weekdays = this.weekdays.map((w) =>
        value.fetchConfigurationScenario.weekdays[w] === true ? w : 'null'
      );
    }
    // Update start time / end time to null by mode
    config.fetchConfigurationMode = value.fetchConfigurationMode;

    return config;
  }
}
