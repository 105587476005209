import { createSelector } from '@ngrx/store';
import { GpsMultiunitStoreSelectors } from './gps-multiunit-store';
import { LteMButtonStoreSelectors } from './lte-m-button-store';

// Note: Actually this variable is not used. The one of the reasons is we don't use NgRx with routing.
// @ts-expect-error (legacy code incremental fix)
export const selectError = createSelector(
  GpsMultiunitStoreSelectors.selectGpsMultiunitError,
  LteMButtonStoreSelectors.selectLteMButtonError,
  (gpsMultiunitError: string, lteMButtonError: string) => {
    return gpsMultiunitError || lteMButtonError;
  }
);

export const selectIsLoading = createSelector(
  GpsMultiunitStoreSelectors.selectGpsMultiunitIsLoading,
  LteMButtonStoreSelectors.selectLteMButtonIsLoading,
  (gpsMultiunit: boolean, lteMButton: boolean) => {
    return gpsMultiunit || lteMButton;
  }
);
