import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';
import { template } from './prefixed_event_handlers_page.component.html';

export class PrefixedEventHandlersPageComponent implements ng.IComponentOptions {
  bindings = {};

  controller = PrefixedEventHandlersPageComponentController;
  template: any = template;
}

export class PrefixedEventHandlersPageComponentController extends BaseController {
  static $inject: InjectList = ['$log', 'AlertsService'];

  alertsService: AlertsServiceInstance;

  constructor($log: ng.ILogService, alertsServiceGenerator: AlertsService) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }
}
