import { LegacyAny } from '@soracom/shared/core';

import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { ScRelation } from '../../components/paginator';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { GroupsService } from '../../groups/groups.service';
import { SubscribersService } from '../subscribers.service';
import { SubscriberSearchSessionStatus } from '../subscriber_search_session_status';
import { SubscriberEnumeratorBase } from './subscriber_enumerator_base';

export class SubscriberEnumeratorByGroupId extends SubscriberEnumeratorBase {
  constructor(
    $log: ng.ILogService,
    $q: ng.IQService,
    $translate: LegacyAny,
    protected service: SubscribersService,
    private groupsService: GroupsService, // Not using NEW groupsService because this code expects Subscriber[] and new groups service gives Sim[]
    private groupId: string,
    filters: LegacyAny,
    private coverageTypeService: CoverageTypeService,
    private sessionStatus: SubscriberSearchSessionStatus,
  ) {
    super($log, $q, $translate, service, filters);
  }

  searchSubscribers(): Promise<ScRelation<ExtendedSubscriberInterface>> {
    return this.listSubscribers();
  }

  listSubscribers(): Promise<ScRelation<ExtendedSubscriberInterface>> {
    const options = this.generatePaginateOptions();
    return this.groupsService.fetchSubscribers(this.groupId, options);
  }

  protected hasSearchMethodFallback() {
    return false;
  }
}
