export const template = `<div
  ng-show="!$ctrl.editing"
  ng-class='{ "wait-cursor": $ctrl.saving || $ctrl.removing }'
  style="padding-left: {{ $ctrl.paddingLeft }}px"
>
  <span data-testid="x-value">{{ $ctrl.displayValue || '—' }}</span>
  <button
    class="ds-button --icon-delete --in-place"
    ng-show="$ctrl.showRemoveButton"
    ng-click="$ctrl.removeThis($event)"
  >
    <span ng-show="!$ctrl.removing"
      >{{ 'inplace_editable_text.remove' | translate }}</span
    >
    <span ng-show="$ctrl.removing"
      >{{'inplace_editable_text.removing' | translate }}</span
    >
  </button>
  <button
    data-testid="begin-edit-button"
    class="ds-button --in-place --icon-edit"
    ng-show="!$ctrl.showRemoveButton && $ctrl.showPencilIcon"
    ng-click="$ctrl.beginEdit($event)"
  >
    <span>Edit</span>
  </button>
</div>
<div
  class="ds-cols --no-gap --no-wrap --middle --narrow"
  ng-show="$ctrl.editing"
  ng-class='{ "wait-cursor": $ctrl.saving || $ctrl.removing, "input-group": $ctrl.showSaveButton || $ctrl.showCancelButton || $ctrl.showRemoveButton }'
>
  <div class="ds-input --small">
    <input
      class="--6-wmin"
      data-testid="edit-value"
      ng-model="$ctrl.editValue"
      ng-change="$ctrl.onChange()"
      ng-blur="$ctrl.onBlur($event)"
    />
    <button
      data-testid="save-button"
      class="ds-button --plain --small --addon --hide-label --icon-confirm"
      ng-show="$ctrl.showSaveButton"
      ng-disabled="$ctrl.saving || $ctrl.removing || !$ctrl.dirty"
      ng-click="$ctrl.commit($event)"
    >
      <span>{{ 'inplace_editable_text.save' | translate }}</span>
    </button>
    <button
      data-testid="cancel-button"
      class="ds-button --plain --small --addon --hide-label --icon-cancel"
      ng-if="$ctrl.showCancelButton"
      ng-disabled="$ctrl.saving || $ctrl.removing"
      ng-click="$ctrl.cancelEdit($event)"
    >
      <span>{{ 'inplace_editable_text.cancel' | translate }}</span>
    </button>

    <button
      data-testid="delete-button"
      class="ds-button --plain --small --addon --icon-delete"
      ng-if="$ctrl.showRemoveButton"
      ng-disabled="$ctrl.saving || $ctrl.removing"
      ng-click="$ctrl.removeThis($event)"
    >
      <span ng-show="!$ctrl.removing">{{ 'inplace_editable_text.remove' | translate }}</span>
      <span ng-show="$ctrl.removing">{{ 'inplace_editable_text.removing' | translate }}</span>
    </button>
  </div>
</div>`;
