export const template = `<form class="x-register-sigfox-device-view" name="$ctrl.registerSigfoxDeviceForm"
  ng-submit="$ctrl.registerSigfoxDevice()" novalidate="novalidate" translate-namespace="sigfox_devices.modals.register">
  <div class="modal-header">
    <h4 translate=".title"></h4>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <p translate=".text"></p>
      <div class="ds-notice--warning"
        ng-bind-html="'sigfox_devices.modals.register.register_sigfox_jp_coverage_pricing_notice' | translate"
        ng-if="$ctrl.coverageTypeService.isJapan()"></div>
    </div>
    <div class="form-group" sc-form-group-validity-classes="sc-form-group-validity-classes" target="sigfox-device-id">
      <label class="control-label" for="sigfox-device-id"><span translate=".device_id"></span>&nbsp;<span
          class="text-danger">*</span></label><input class="form-control" id="sigfox-device-id" name="sigfox-device-id"
        translate-attr="{ placeholder: '.device_id_placeholder' }" type="text" ng-model="$ctrl.deviceId"
        ng-required="true" /></div>
    <div class="form-group" sc-form-group-validity-classes="sc-form-group-validity-classes"
      target="sigfox-device-registration-secret"><label class="control-label"
        for="sigfox-device-registration-secret"><span translate=".registration_code"></span>&nbsp;<span
          class="text-danger">*</span></label><input class="form-control" id="sigfox-device-registration-secret"
        name="sigfox-device-registration-secret" translate-attr="{ placeholder: '.registration_code_placeholder' }"
        type="text" ng-model="$ctrl.registrationSecret" ng-required="true" /></div>
    <div class="x-sigfox-byod" sc-feature="sigfoxBYOD">
      <div class="form-group" ng-if="!$ctrl.deviceIsPrototype"><label class="control-label"
          for="sigfox-device-product-certification-id"><span translate=".product_certification_id"></span></label><input
          class="form-control" id="sigfox-device-product-certification-id" name="sigfox-device-product-certification-id"
          translate-attr="{ placeholder: '.product_certification_id_placeholder' }" type="text"
          ng-model="$ctrl.productCertificationId" /></div>
      <div class="form-group" ng-if="$ctrl.deviceIsPrototype"><label class="control-label"
          for="sigfox-device-product-certification-id"><span translate=".product_certification_id"></span></label><input
          class="form-control" id="sigfox-device-product-certification-id" ng-disabled="true"
          name="sigfox-device-product-certification-id"
          translate-attr="{ placeholder: '.product_certification_not_required_for_prototypes' }" type="text" /></div>
      <div class="form-group"><label class="control-label" for="sigfox-device-is-prototype"><span
            translate=".prototype_status"></span></label><br /><label style="font-weight: normal;"><input
            id="sigfox-device-is-prototype" type="checkbox" ng-model="$ctrl.deviceIsPrototype" /><span>&nbsp;
            &nbsp;</span><span translate=".register_this_devices_as_a_prototype"></span></label></div>
    </div>
    <div class="form-group"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  </div>
  <div class="modal-footer">
    <div class="form-group"><button class="x-cancel-button btn btn-default" type="button" translate=".cancel"
        ng-disabled="$ctrl.submitting" ng-click="$ctrl.dismiss()"></button><button
        class="x-register-button btn btn-primary" type="submit" translate=".submit" ng-disabled="!$ctrl.canSubmit()"
        sc-modal-default-action="sc-modal-default-action"></button></div>
  </div>
</form>`;
