import { LegacyAny } from '@soracom/shared/core';

import { template } from './session_status_label.component.html';

import { BaseController } from '../../components/base_controller';
import { InjectList } from '../../core/injectable';

export class SessionStatusLabelComponent implements ng.IComponentOptions {
  bindings = {
    isOnline: '<',
    alignLeft: '<?',
    disabled: '<?',
  };
  controller = SessionStatusLabelComponentController;
  template: any = template;
}

export class SessionStatusLabelComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$translate'];

  // @ts-expect-error (legacy code incremental fix)
  isOnline: boolean;

  // @ts-expect-error (legacy code incremental fix)
  alignLeft: boolean;
  // @ts-expect-error (legacy code incremental fix)
  disabled: boolean;

  constructor($log: ng.ILogService, private $translate: LegacyAny) {
    super($log);
  }

  cssClass() {
    if (this.disabled) {
      return ['hidden'];
    }

    return this.isOnline ? ['ds-tag--success'] : ['ds-tag--outline'];
  }

  text() {
    const sessionStatus = this.isOnline ? 'online' : 'offline';
    return this.$translate.instant(`session_status_label.${sessionStatus}`);
  }
}
