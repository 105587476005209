<section dsDialogContent>
  <figure class="ds-codeblock --auto">
    <pre><code>{{ rawData }}</code>
  </pre>
  </figure>
</section>
<footer dsDialogFooter>
  <div>
    <button class="ds-button --secondary" (click)="close()">
      <span>{{ 'logs.modals.raw_data.close' | translate }}</span>
    </button>
  </div>
</footer>
