import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoggerService } from '@soracom/shared-ng/logger-service';
import { Logger } from '@soracom/shared/logger';
import { LocalStorageService } from '@soracom/forks/ngx-store';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SubscriberSearchType } from '../../../../app/shared/components/subscriber_search_type';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscribersService } from '../../../../app/shared/subscribers/subscribers.service';
import { SubscriberSearchSessionStatus } from '../../../../app/shared/subscribers/subscriber_search_session_status';
import { RootStoreState } from '../../root-store';
import * as Actions from '../../root-store/gps-multiunit-store/actions';
import { SearchQuery } from '@user-console/legacy-soracom-api-client';
import { DEFAULT_INPUT_DEBOUNCE_TIME } from '../../shared/user_interaction';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiPaginator } from '../../soracom-ui/ui-pagination/UiPaginator';
import { UiTableContent } from '../../soracom-ui/ui-table/ui-table-content';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-gps-multiunit-new-subscribers-select',
  templateUrl: './gps-multiunit-new-subscribers-select.component.html',
})
export class GpsMultiunitNewSubscribersSelectComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private logger: Logger;

  // UiTable
  columns: Array<UiTableColumn<ExtendedSubscriberInterface>> = [
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'imsi', (s) => s.imsi),
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'name', (s) => s.name),
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'group', (s) => s.groupName),
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'subscription', (s) => s.subscription),
  ];
  // @ts-expect-error (legacy code incremental fix)
  creator: new () => ExtendedSubscriberInterface;
  content: UiTableContent<ExtendedSubscriberInterface> = new UiTableContent<ExtendedSubscriberInterface>(
    [],
    this.columns,
    {
      multiSelect: true,
    },
  );
  paginationContent: UiPaginator<ExtendedSubscriberInterface>;
  searchTextControl = new FormControl();

  constructor(
    localStorageService: LocalStorageService,
    private store$: Store<RootStoreState.State>,
    subscribersService: SubscribersService,
  ) {
    this.logger = LoggerService.shared();
    this.paginationContent = new UiPaginator<ExtendedSubscriberInterface>(localStorageService, subscribersService);
    this.paginationContent.listApiOptions.assignGroup = true;
    this.paginationContent.searchQuery = new SearchQuery();
    // query API requires at least one query item.
    this.paginationContent.onDataLoaded.subscribe((relation) => {
      this.content.setData(relation.data);
    });
  }
  imsiList: string[] = [];

  searchSubscribers = (searchText = '') => {
    if (searchText && searchText.length >= 2) {
      this.paginationContent.listApiOptions.mode = 'query';
      this.paginationContent.searchQuery.setQueryItem('name', searchText);
      this.paginationContent.searchQuery.setQueryItem('group', searchText);
      this.paginationContent.searchQuery.setQueryItem('imsi', searchText);
      this.paginationContent.searchQuery.setQueryItem('session_status', SubscriberSearchSessionStatus.NA);
      this.paginationContent.searchQuery.setQueryItem('search_type', SubscriberSearchType.OR);
      this.paginationContent.searchQuery.deleteQueryItem('status_filter');
    } else if (searchText === '') {
      this.paginationContent.listApiOptions.mode = 'list';
      this.paginationContent.searchQuery.deleteQueryItem('name');
      this.paginationContent.searchQuery.deleteQueryItem('group');
      this.paginationContent.searchQuery.deleteQueryItem('imsi');
      this.paginationContent.searchQuery.deleteQueryItem('search_type');
      this.paginationContent.searchQuery.deleteQueryItem('session_status');
      this.paginationContent.searchQuery.deleteQueryItem('search_type');
      this.paginationContent.searchQuery.setQueryItem(
        'status_filter',
        'standby|ready|active|inactive|shipped|suspended',
      );
    } else {
      // skip if the text length is 1
      return;
    }
    this.paginationContent
      .reload()
      .then(() => {
        this.store$.dispatch(Actions.loadAvailableSubscribersSuccess());
      })
      .catch((e: LegacyAny) => {
        this.store$.dispatch(Actions.loadAvailableSubscribersError(e));
      });
  };

  clickNextButton = () => {
    this.logger.debug('subscriberSelected called');
    this.store$.dispatch(Actions.selectGroup({ imsiList: this.imsiList }));
  };

  backToIndex = () => {
    this.logger.debug('backToIndex called');
    this.store$.dispatch(Actions.navigateToIndexPage());
  };

  buttonBar = UiButtonBar.configure((bar) => {
    bar.leftButtons = [
      UiButton.configure((b) => {
        b.buttonStyle = 'primary';
        b.titleId = 'GpsMultiunitNewSubscribersSelectComponent.actions.next.title';
        b.classes = ['x-select-group'];
        b.isDisabled_ƒ = () => {
          return this.imsiList.length === 0;
        };
        b.onClick = this.clickNextButton;
      }),
      UiButton.configure((b) => {
        b.titleId = 'GpsMultiunitNewSubscribersSelectComponent.actions.back.title';
        b.classes = ['x-back'];
        b.onClick = this.backToIndex;
      }),
    ];
  });

  ngOnInit() {
    this.searchTextControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((input) => input.length !== 1),
        debounceTime(DEFAULT_INPUT_DEBOUNCE_TIME),
        distinctUntilChanged(),
      )
      .subscribe(this.searchSubscribers);
    this.searchSubscribers();
  }

  subscriberSelected(selectedSubscribers: ExtendedSubscriberInterface[]) {
    this.logger.debug('subscriberSelected', selectedSubscribers);
    this.imsiList = selectedSubscribers.map((s) => s.imsi);
  }
}
