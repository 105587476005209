import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { LagoonUser } from '../../../../app/shared/core/lagoon_user';
import { LagoonUsersService } from '../../../../app/shared/lagoon/lagoon_users.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';

@Component({
  selector: 'app-delete-lagoon-user-modal',
  templateUrl: './delete-lagoon-user-modal.component.html',
})
export class DeleteLagoonUserModalComponent
  extends DsModalContentBase<LagoonUser[], { success: boolean }>
  implements OnInit
{
  loading = false;
  get lagoonUsers(): LagoonUser[] {
    return this.getInput();
  }
  alertsManager = new AlertsManager();
  canClose(): boolean {
    return !this.loading;
  }

  constructor(private lagoonUsersService: LagoonUsersService) {
    super();
  }

  ngOnInit(): void {}

  confirm() {
    this.alertsManager.clear();
    this.loading = true;
    const promises = this.getInput().map((lagoonUser) => this.lagoonUsersService.delete(lagoonUser.id));
    Promise.all(promises)
      .then((_results) => {
        this.loading = false;
        this.close({ success: true });
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
        this.loading = false;
      });
  }
}
