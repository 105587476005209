import '@angular/common/locales/global/ja';
import { LocationUpgradeModule } from '@angular/common/upgrade';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { setUpLocationSync } from '@angular/router/upgrade';
import { UpgradeModule, setAngularJSGlobal } from '@angular/upgrade/static';
import * as angular from 'angular';
import { AppName } from '../../app/shared/main/app';
import { AppComponent } from './app.component';
import { appModuleImports } from './app.module.imports';
import { appModuleProvidersForHybridApp } from './app.module.providers';
import { AppRoutingModule } from './app.routing';
import { providePreloadService } from './app.preload.provider';

@NgModule({
  imports: appModuleImports.concat([
    UpgradeModule,
    AppRoutingModule,
    LocationUpgradeModule.config({
      useHash: false,
    }),
  ]),
  declarations: [AppComponent],
  providers: [
    ...providePreloadService(),
     ...appModuleProvidersForHybridApp,
  ]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule, private router: Router) {}

  ngDoBootstrap() {
    setAngularJSGlobal(angular);
    this.upgrade.bootstrap(document.body, [AppName]);
    setUpLocationSync(this.upgrade, 'path');
    this.router.initialNavigation();
  }
}

