import { LegacyAny } from '@soracom/shared/core';

import { Action } from '../core/actions/Action';
import { LoraDevice } from '../core/lora_device';
import { LoraDevicesComponentController } from './lora_devices.component';

export abstract class LoraDevicesAction<T> implements Action<T> {
  constructor(protected ctrl: LoraDevicesComponentController) {}

  run() {
    throw new Error('Please implement');
  }
  actionable() {
    return true;
  }
}

export class DownlinkLoraDeviceAction extends LoraDevicesAction<LoraDevice> {
  private $uibModal;

  constructor(ctrl: LoraDevicesComponentController) {
    super(ctrl);
    this.$uibModal = ctrl.$uibModal;
  }

  run() {
    this.$uibModal
      .open({
        component: 'scSendDownlinkMessageComponent',
        backdrop: 'static',
        resolve: {
          params: () => {
            return {
              loraDevices: this.ctrl.tableData.getSelectedData(),
            };
          },
        },
      })
      .result.then(this.okAction, this.cancelAction);
  }

  actionable() {
    const notTerminated =
      this.ctrl.tableData.getSelectedData().filter((r) => {
        return r.status === 'terminated';
      }).length === 0;
    return this.ctrl.tableData.countChecked() > 0 && notTerminated;
  }

  private okAction = (result: LegacyAny) => {
    this.ctrl.alertsService.showInfo('lora_devices.downlink_sent');
  };

  private cancelAction = () => {
    // noop
  };
}
