import { LegacyAny } from '@soracom/shared/core';

import { Terminatable } from '@soracom/shared/soracom-platform';
import { TerminatableService } from '@soracom/shared/soracom-platform';
import { InjectList } from '../../core/injectable';
import { AlertsService, AlertsServiceInstance } from '../alerts.service';
import { BaseModalController } from '../base_modal_controller';
import { template } from './change_termination_protection.component.html';

/**
 * A component for changing termination protection settings for one or
 * more resources (displayed in a modal).
 */
export class ChangeTerminationProtectionComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = ChangeTerminationProtectionComponentController;
  template: any = template;
}

export class ChangeTerminationProtectionResult {
  constructor(public enableTerminationProtection: boolean, private translations: { modelName: string }) {}

  changed: Terminatable[] = [];
  failed: Terminatable[] = [];
  errors: any[] = [];

  didSucceed() {
    return this.errors.length === 0;
  }

  /**
   * Returns an object like `{translate: 'foo.bar', values: {baz: 'hoge'}}` which
   * contains the i18n key, and translation values (if any) for a success or
   * error message, something like:
   *
   * "Enabled termination protection for 7 SIMs", or "Disabling termination
   * protection for the SIM failed: some err message".
   *
   * This method doesn't indicate whether the result is a failure message or
   * success message; use didSucceed() to determine that.
   */
  messageValues() {
    const changed = this.changed.length;
    const failed = this.failed.length;
    const total = changed + failed;
    const info = this.getUnderlyingErrorInfo();
    const values = { changed, failed, total, info, modelName: this.translations.modelName };

    const result = this.didSucceed() ? 'success' : 'failure';
    const action = this.enableTerminationProtection ? 'enabled' : 'disabled';
    const singular = this.numberOfResults() === 1 ? 'one' : 'multiple';
    const translate = `change_termination_protection.${result}_message_${action}_${singular}`;
    return { translate, values };
  }

  getUnderlyingErrorInfo() {
    const errorMessages: string[] = [];

    this.errors.forEach((err) => {
      const msg = (err && err.data && err.data.message) || 'error';
      errorMessages.push(msg);
    });

    // FIXME: was gonna do some fancy error coalescing but gave up. decide if we do that or not...
    return errorMessages.length > 0 ? errorMessages[0] : 'error';
  }

  numberOfResults() {
    return this.changed.length + this.failed.length;
  }

  // Mason 2017-10-23: someday we will have TS types for the above, but we don't yet...
}

export class ChangeTerminationProtectionComponentController extends BaseModalController {
  static $inject: InjectList = ['$log', '$translate', 'AlertsService'];

  // @ts-expect-error (legacy code incremental fix)
  alertsServiceInstance: AlertsServiceInstance;

  resources: Terminatable[] = [];

  // @ts-expect-error (legacy code incremental fix)
  resourceTranslations: {
    modelName: string;
    id: string;
  };

  enableTerminationProtection = true;

  submitting = false;

  // @ts-expect-error (legacy code incremental fix)
  service: TerminatableService;

  constructor(private $log: ng.ILogService, private $translate: any, private alertsService: AlertsService) {
    super($log);
    this.setTraceEnabled(true);
    this.trace('ChangeTerminationProtectionComponentController: constructor()...');
  }

  $onInit() {
    this.trace('HELLO from ChangeTerminationProtectionComponentController.$onInit()...');

    this.alertsServiceInstance = this.alertsService.generate();

    this.service = this.resolve.service;
    if (this.resolve && this.resolve.params) {
      this.resources = this.resolve.params.resources || [];
      this.enableTerminationProtection = this.protectedAny();
      this.initTranslations();
    }
    this.trace('resources:', this.resources);

    // if (! this.somethingWillChange()) {
    //   this.enableTerminationProtection = !this.enableTerminationProtection;
    // }
    // Mason 2017-10-23: I decided the above was a bad idea. Make the user explicitly
    // turn it off to avoid accidental disabling.
  }

  initTranslations() {
    const resourceType = this.resolve.params.resourceType;
    const prefix = `model.${resourceType}`;
    const translationKeys = [`${prefix}.modelName`, `${prefix}.id`];
    this.$translate(translationKeys)
      .then((translations: LegacyAny) => {
        this.resourceTranslations = {
          modelName: translations[translationKeys[0]],
          id: translations[translationKeys[1]],
        };
        this.debug(this.resourceTranslations);
      })
      .catch((error: LegacyAny) => {
        this.error(error);
      });
  }

  private protectedAny(): boolean {
    // Return false if a resource exists that termination is disabled.
    return this.resources.filter((r) => !r.terminationEnabled).length > 0;
  }

  terminationProtectionChanged() {
    this.trace('terminationProtectionChanged:', this.enableTerminationProtection);
  }

  somethingWillChange() {
    for (const resource of this.resources) {
      const isProtected = !resource.terminationEnabled;
      if (isProtected !== this.enableTerminationProtection) {
        return true;
      }
    }
    return false;
  }

  getHelpMessage() {
    const single = this.resources.length === 1;

    if (this.somethingWillChange()) {
      if (this.enableTerminationProtection) {
        return single ? 'help_message_singular_enable' : 'help_message_plural_enable';
      } else {
        return single ? 'help_message_singular_disable' : 'help_message_plural_disable';
      }
    } else {
      if (this.enableTerminationProtection) {
        return single ? 'help_message_singular_no_changes_enabled' : 'help_message_plural_no_changes_enabled';
      } else {
        return single ? 'help_message_singular_no_changes_disabled' : 'help_message_plural_no_changes_disabled';
      }
    }
  }

  canSubmit() {
    return this.somethingWillChange();
  }

  cancel() {
    this.debug('ChangeTerminationProtectionComponentController.cancel()...');
    this.dismiss('canceled');
  }

  confirm() {
    this.trace('ChangeTerminationProtectionComponentController.confirm()...');

    this.submitting = true;

    const result = new ChangeTerminationProtectionResult(this.enableTerminationProtection, this.resourceTranslations);

    this.resources.forEach((resource) => {
      const resourceId = String(resource.id);

      let promise;
      if (this.enableTerminationProtection) {
        promise = this.service.disableTermination(resourceId);
      } else {
        promise = this.service.enableTermination(resourceId);
      }

      promise
        .then((response: LegacyAny) => {
          this.debug('SUCCESSFULLY CHANGED TERMINATION PROTECTION:');
          this.debug(response);
          result.changed.push(response);
        })
        .catch((err: LegacyAny) => {
          this.debug('FAILED TO CHANGE TERMINATION PROTECTION:', err);
          result.errors.push(err);
          result.failed.push(resource);
        })
        .finally(() => {
          // We always close with a result object, pass or fail.
          if (result.numberOfResults() === this.resources.length) {
            this.close(result);
            this.submitting = false;
          }
        });
    });
  }
}
