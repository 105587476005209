import { GpsMultiunitStoreState } from './gps-multiunit-store';
import { LagoonStoreState } from './lagoon-store';
import { LteMButtonStoreState } from './lte-m-button-store';

export interface ModelCollectionState<T> {
  data: T[];
  error: Error | string | null;
  loading: boolean;
}

export interface ModelState<T> {
  data: T | null;
  error: Error | string | null;
  loading: boolean;
}

export const initialModelCollectionState: ModelCollectionState<any> = {
  data: [],
  error: null,
  loading: false,
};

export const initialModelState: ModelState<any> = {
  data: null,
  error: null,
  loading: false,
};

export interface State {
  gpsMultiunit: GpsMultiunitStoreState.State;
  lagoon: LagoonStoreState.State;
  lteMButton: LteMButtonStoreState.State;
}
