import { LegacyAny } from '@soracom/shared/core';
import { GMTime } from './GMTime';
import { GMBoolean, GMCommonAttributes, GMMode, GpsMultiunitConfigData } from './GpsMultiunitConfigData';
import { GpsMultiunitScenario } from './GpsMultiunitScenario';

const SCENARIO_ID_FOR_AUTO_MODE = '0';
const SCENARIO_ID_FOR_NONE_MODE = '4';
const SCENARIO_ID_FOR_MANUAL_MODE = '5';
// Scenario ID to fetch config from SORACOM Metadata service
const SCENARIO_ID_FOR_CONFIG_FETCH = '6';
const SCENARIO_ID_FOR_ACCELERATION_EVENT = '7';

type FetchConfigurationMode = GMMode.AUTO | GMMode.MANUAL;

export class GpsMultiunitConfig {
  private autoScenarioPhase0: GpsMultiunitScenario; // Scenario 0
  private autoScenarioPhase1: GpsMultiunitScenario; // Scenario 1
  private autoScenarioPhase2: GpsMultiunitScenario; // Scenario 2
  private autoScenarioPhase3: GpsMultiunitScenario; // Scenario 3
  private scenario4: GpsMultiunitScenario; // Scenario4, unused
  intervalScenario: GpsMultiunitScenario; // Scenario 5, executed by interval timer
  fetchConfigurationScenario: GpsMultiunitScenario; // Scenario6, scenario to fetch configuration from metadata service
  accelerationEventScenario: GpsMultiunitScenario; // Scenario7, executed by accelerator event
  private scenario8: GpsMultiunitScenario; // Scenario8, unused
  private scenario9: GpsMultiunitScenario; // Scenario9, unused

  isEnabledAccelerationEvent = false; // if true, scenario 7 is enabled
  // @ts-expect-error (legacy code incremental fix)
  accelerationEventThreshold: number; // Setting.itr.i2
  // @ts-expect-error (legacy code incremental fix)
  fetchConfigurationMode: FetchConfigurationMode;

  accelerator = false; // SensorData.acc.i1
  battery = false; // SensorData.bat.i1
  humidity = false; // SensorData.hum.i1
  location = false; // SensorData.loc.i1
  temperature = false; // SensorData.tem.i1

  mode = GMMode.MANUAL; // Common.C7

  get scenarios(): GpsMultiunitScenario[] {
    return [
      this.autoScenarioPhase0,
      this.autoScenarioPhase1,
      this.autoScenarioPhase2,
      this.autoScenarioPhase3,
      this.scenario4,
      this.intervalScenario,
      this.fetchConfigurationScenario,
      this.accelerationEventScenario,
      this.scenario8,
      this.scenario9,
    ];
  }

  constructor(params?: GpsMultiunitConfigData) {
    if (params && params.SensorData && params.SensorData.acc && params.SensorData.acc.i1) {
      this.accelerator = gMBoolToBool(params.SensorData.acc.i1);
    }
    if (params && params.SensorData && params.SensorData.bat && params.SensorData.bat.i1) {
      this.battery = gMBoolToBool(params.SensorData.bat.i1);
    }
    if (params && params.SensorData && params.SensorData.hum && params.SensorData.hum.i1) {
      this.humidity = gMBoolToBool(params.SensorData.hum.i1);
    }
    if (params && params.SensorData && params.SensorData.loc && params.SensorData.loc.i1) {
      this.location = gMBoolToBool(params.SensorData.loc.i1);
    }
    if (params && params.SensorData && params.SensorData.tem && params.SensorData.tem.i1) {
      this.temperature = gMBoolToBool(params.SensorData.tem.i1);
    }
    if (params && params.Setting && params.Setting.itr && params.Setting.itr.i2) {
      this.accelerationEventThreshold = params.Setting.itr.i2;
    }

    // @ts-expect-error (legacy code incremental fix)
    const scenarios = parseScenarios(params);
    this.autoScenarioPhase0 = scenarios[SCENARIO_ID_FOR_AUTO_MODE];
    this.autoScenarioPhase1 = scenarios[1];
    this.autoScenarioPhase2 = scenarios[2];
    this.autoScenarioPhase3 = scenarios[3];
    this.scenario4 = scenarios[4];
    this.intervalScenario = scenarios[SCENARIO_ID_FOR_MANUAL_MODE];
    this.fetchConfigurationScenario = scenarios[SCENARIO_ID_FOR_CONFIG_FETCH];
    this.accelerationEventScenario = scenarios[SCENARIO_ID_FOR_ACCELERATION_EVENT];
    this.scenario8 = scenarios[8];
    this.scenario9 = scenarios[9];

    if (params && params.Common && params.Common.C7) {
      const availableScenarios = params.Common.C7.split(',');
      if (availableScenarios.includes(SCENARIO_ID_FOR_ACCELERATION_EVENT)) {
        this.isEnabledAccelerationEvent = true;
      }
      if (availableScenarios.includes(SCENARIO_ID_FOR_AUTO_MODE)) {
        this.mode = GMMode.AUTO;
      } else if (availableScenarios.includes(SCENARIO_ID_FOR_MANUAL_MODE)) {
        this.mode = GMMode.MANUAL;
      } else if (availableScenarios.includes(SCENARIO_ID_FOR_NONE_MODE)) {
        this.mode = GMMode.NONE;
      }
      // check if config is wrong format
      if (
        availableScenarios.includes(SCENARIO_ID_FOR_CONFIG_FETCH) &&
        this.fetchConfigurationScenario.startTime === 'null' &&
        this.fetchConfigurationScenario.endTime === 'null'
      ) {
        // It's wrong format. Fix to the correct one.
        this.fetchConfigurationMode = GMMode.MANUAL;
        this.fetchConfigurationScenario.startTime = GMTime.parse('02:00');
        this.fetchConfigurationScenario.endTime = GMTime.parse('03:00');
      } else if (availableScenarios.includes(SCENARIO_ID_FOR_CONFIG_FETCH)) {
        // It's correct auto format
        this.fetchConfigurationMode = GMMode.AUTO;
      } else {
        // It's correct manual format
        this.fetchConfigurationMode = GMMode.MANUAL;
      }
    }
  }

  toJSON() {
    const json: GpsMultiunitConfigData = {
      SensorData: {
        acc: { i1: boolToGMBool(this.accelerator) },
        loc: { i1: boolToGMBool(this.location) },
        tem: { i1: boolToGMBool(this.temperature) },
        hum: { i1: boolToGMBool(this.humidity) },
        bat: { i1: boolToGMBool(this.battery) },
      },
      Common: this.generateCommonAttributes(),
    };
    if (this.accelerationEventThreshold) {
      json.Setting = {
        itr: { i2: this.accelerationEventThreshold },
      };
    }
    return json;
  }

  private generateCommonAttributes(): GMCommonAttributes {
    const scenarios = this.scenarios;
    const attributes: GMCommonAttributes = {
      C1: scenarios.map((s) => s.C1),
      C2: scenarios.map((s) => s.C2),
      C3: scenarios.map((s) => s.C3),
      C4: scenarios.map((s) => s.C4),
      C5: scenarios.map((s) => s.C5),
      C6: scenarios.map((s) => s.C6),
      C7: this.generateC7(),
    };

    return attributes;
  }

  private generateC7(): string {
    const scenarioIds: LegacyAny[] = [];
    if (this.mode === GMMode.AUTO) {
      scenarioIds.push(SCENARIO_ID_FOR_AUTO_MODE);
    } else if (this.mode === GMMode.MANUAL) {
      scenarioIds.push(SCENARIO_ID_FOR_MANUAL_MODE);
    } else {
      scenarioIds.push(SCENARIO_ID_FOR_NONE_MODE);
    }
    if (this.fetchConfigurationMode === GMMode.AUTO) {
      scenarioIds.push(SCENARIO_ID_FOR_CONFIG_FETCH);
    }
    if (this.isEnabledAccelerationEvent) {
      scenarioIds.push(SCENARIO_ID_FOR_ACCELERATION_EVENT);
    }
    return scenarioIds.join(',');
  }

  isValid(): boolean {
    return this.scenarios.map((s) => s.validate()).reduce((prev, curr, i) => prev && curr); // Return true if all scenarios is valid
  }
}

function parseScenarios(json: GpsMultiunitConfigData): GpsMultiunitScenario[] {
  const scenarios: GpsMultiunitScenario[] = [];
  for (let idx = 0; idx < 10; idx++) {
    scenarios.push(
      new GpsMultiunitScenario(
        json.Common.C1[idx],
        json.Common.C2[idx],
        json.Common.C3[idx],
        json.Common.C4[idx],
        json.Common.C5[idx],
        json.Common.C6[idx]
      )
    );
  }
  return scenarios;
}

export function boolToGMBool(val: boolean): GMBoolean {
  return val ? 'ON' : 'OFF';
}

export function gMBoolToBool(val: GMBoolean): boolean {
  return val === 'ON';
}
