import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { AlertsService, AlertsServiceInstance } from '../../components/alerts.service';
import { BaseModalController } from '../../components/base_modal_controller';
import { InjectList } from '../../core/injectable';
import { LoraNetworkSetsService } from '../lora_network_sets.service';
import { template } from './lora_network_set_add.modal.component.html';

export class AddLoraNetworkSetModalComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = AddLoraNetworkSetModalComponentController;
  template: any = template;
}

export class AddLoraNetworkSetModalComponentController extends BaseModalController {
  static $inject: InjectList = ['$log', '$timeout', 'AlertsService', 'LoraNetworkSetsService'];

  // @ts-expect-error (legacy code incremental fix)
  name: string;
  alertsService: AlertsServiceInstance;
  submitting = false;
  // @ts-expect-error (legacy code incremental fix)
  loraNetworkSetAddForm: angular.IFormController;

  constructor(
    $log: ng.ILogService,
    private $timeout: ng.ITimeoutService,
    AlertsService: AlertsService,
    private LoraNetworkSetsService: LoraNetworkSetsService
  ) {
    super($log);
    this.alertsService = AlertsService.generate();
  }

  $onInit() {}

  submit() {
    if (!this.canSubmit()) {
      return;
    }

    this.submitting = true;
    this.LoraNetworkSetsService.create(this.name)
      .then((loraNetworkSet) => {
        this.close(loraNetworkSet);
      })
      .catch((response: LegacyAny) => {
        this.alertsService.showError(response.data);
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  canSubmit() {
    return !this.submitting && this.loraNetworkSetAddForm.$valid;
  }

  cancel() {
    this.dismiss();
  }
}
