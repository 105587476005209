<section dsDialogContent class="ds-rows">
  <div class="ds-field">
    <label class="ds-label">
      {{ i18n.labels.deviceName | localize }}
      <abbr class="--required" title="required">*</abbr>
    </label>
    <input
      class="ds-input"
      id="deviceName"
      required
      aria-required="true"
      type="text"
      [(ngModel)]="deviceName"
    />
  </div>

  <div class="ds-field">
    <label class="ds-label">
      {{ i18n.labels.group | localize }}
      <abbr class="--required" title="required">*</abbr>
    </label>
    <app-group-chooser
      class="x-add-device-group-chooser"
      [hideLabel]="true"
      (groupChooserChange)="groupChooserChangeHandler($event)"
    ></app-group-chooser>
  </div>
  <ds-notices [notices]="addDevicerErrorNotices"></ds-notices>
</section>
<footer dsDialogFooter>
  <button class="ds-button --primary x-connect-web-console-cancel-button" (click)="submit()" [disabled]="isLoading">
    <span [innerHTML]="i18n.buttons.add| localize"></span>
  </button>
  <button class="ds-button x-connect-web-console-launch-button" (click)="close()">
    <span [innerHTML]="i18n.buttons.cancel | localize"></span>
  </button>
</footer>
