import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { BaseTableRow } from './BaseTableRow';

export const OrderObjectByFilter = ($log: ng.ILogService, $cacheFactory: ng.ICacheFactoryService) => {
  const cache = $cacheFactory('OrderObjectByFilterCache');

  // Please update if you want to skip updating order attributes
  const replacer = (key: string, value: any) => {
    if (key === 'location') return undefined;
    else if (key === 'cellInfo') return undefined;
    else if (key === 'group') return undefined;
    else return value;
  };

  const getCacheKey = (items: any[] | { [id: string]: any }, field: string, reverse: boolean): string => {
    if (Array.isArray(items) && items.length > 0 && items[0] instanceof BaseTableRow) {
      const data = items.map((item: BaseTableRow<any>) => item.obj);
      return `${JSON.stringify(data, replacer)}"&field=${field}&reverse=${reverse}`;
    } else {
      return `${JSON.stringify(items, replacer)}"&field=${field}&reverse=${reverse}`;
    }
  };

  return (items: any[] | { [id: string]: any }, field: string, reverse: boolean): any[] => {
    // const kicked = Date.now();
    const cacheKey = getCacheKey(items, field, reverse);
    // $log.debug(key);
    const cachedResult = cache.get<any[]>(cacheKey);
    if (!!cachedResult) {
      // $log.debug(`FILTERING IGNORED: ${Date.now() - kicked}`);
      return cachedResult;
    }

    const filtered: LegacyAny[] = [];
    angular.forEach(items, (item) => {
      filtered.push(item);
    });
    filtered.sort((a: LegacyAny, b: LegacyAny) => {
      // return a[field] > b[field] ? 1 : -1;
      // mason 2018-12-17: extending this to allow sorting based on a multi-level key path, because we want to sort on name which is really tags.name

      const keyPath = field.split('.');
      if (a instanceof BaseTableRow) {
        keyPath.unshift('obj');
      }

      let aValue = null;
      let bValue = null;
      for (let index = 0; index < keyPath.length; index++) {
        const key = keyPath[index];

        if (index === 0) {
          aValue = a[key];
          bValue = b[key];
        } else {
          aValue = aValue && aValue[key];
          bValue = bValue && bValue[key];
        }
      }
      aValue = aValue || '';
      bValue = bValue || '';
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.toLocaleUpperCase() > bValue.toLocaleUpperCase() ? 1 : -1; // Extend to be case insensitive, but otherwise preserve original behaviorel
      } else {
        return aValue > bValue ? 1 : -1; // Original implementation, for all types
      }
    });

    if (reverse) {
      filtered.reverse();
    }

    cache.removeAll();

    // $log.debug(`FILTERING EXECUTED: ${Date.now() - kicked}`);
    return cache.put<any[]>(cacheKey, filtered);
  };
};

OrderObjectByFilter.$inject = ['$log', '$cacheFactory'];
