import { LegacyAny } from '@soracom/shared/core';

import { SearchQuery } from '@user-console/legacy-soracom-api-client';
import { UCStorage } from '../../../../src/app/shared/UCStorage';

import { PaginationOptions, Paginator, ScRelation } from '../../components/paginator';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberFilter } from '../SubscriberFilter';
import { SubscribersService } from '../subscribers.service';

export interface SubscriberSearchOption extends PaginationOptions {
  status_filter?: string;
  serial_number_filter?: string;
  iccid_filter?: string;
  tag_name?: string;
  tag_value?: string;
  tag_value_match_mode?: string;
}

export interface SubscriberEnumerator extends Paginator<ExtendedSubscriberInterface> {
  searchSubscribers(): Promise<ScRelation<ExtendedSubscriberInterface>>;
  listSubscribers(): Promise<ScRelation<ExtendedSubscriberInterface>>;
}

export class SubscriberEnumeratorBase implements SubscriberEnumerator {
  itemsPerPage: number[] = [10, 25, 50, 100];

  // @ts-expect-error (legacy code incremental fix)
  protected currentPage: number; // Starts with 0
  // @ts-expect-error (legacy code incremental fix)
  protected pageKeys: string[];
  // @ts-expect-error (legacy code incremental fix)
  protected reachedLast: boolean;
  private defaultPerPage = 10;
  private resourcePath: string;
  // @ts-expect-error (legacy code incremental fix)
  _searchQuery: SearchQuery; // SubscriberEnumerator shouldn't use this SearchQuery for now.

  get searchQuery(): SearchQuery {
    return this._searchQuery;
  }

  set searchQuery(newOpts: SearchQuery) {
    this._searchQuery = newOpts;
  }

  protected filters: SubscriberFilter;

  constructor(
    protected $log: ng.ILogService,
    protected $q: ng.IQService,
    protected $translate: any,
    protected service: SubscribersService,
    filters?: SubscriberFilter,
  ) {
    this.resourcePath = service.resourcePath;
    this.clearPagination();
    // @ts-expect-error (legacy code incremental fix)
    this.filters = filters || { status: null };
  }

  get perPage(): number {
    return (UCStorage.itemsPerPage as LegacyAny)?.[this.resourcePath] || this.defaultPerPage;
  }

  set perPage(perPage: number) {
    const perPageSettings = UCStorage.itemsPerPage || ({} as LegacyAny);
    perPageSettings[this.resourcePath] = perPage;
    UCStorage.itemsPerPage = perPageSettings;
  }

  getCurrentPageNumber() {
    return this.currentPage + 1;
  }

  hasPrev() {
    return this.currentPage > 0;
  }

  hasNext() {
    return !this.reachedLast;
  }
  /**
   * @param pageNumber The page number you want to show. page number starts with 1.
   */
  getPage(pageNumber: number) {
    const pageIndex = pageNumber - 1;
    this.currentPage = pageIndex < 0 ? 0 : pageIndex;
    return this.getSubscribers().then((relation) => {
      if (relation.links.next) {
        this.reachedLast = false;
        this.pageKeys[this.currentPage + 1] = relation.links.next.lastEvaluatedKey;
      } else {
        this.reachedLast = true;
        // @ts-expect-error (legacy code incremental fix)
        this.pageKeys[this.currentPage + 1] = undefined;
      }
      return relation;
    });
  }

  async getPrevPage() {
    if (this.hasPrev()) {
      return this.getPage(this.getCurrentPageNumber() - 1);
    }
    // TODO: better error message
    throw Error('no prev link');
  }

  async getNextPage() {
    if (this.hasNext()) {
      return this.getPage(this.getCurrentPageNumber() + 1);
    }
    // TODO: better error message
    throw Error('no next link');
  }

  clearPagination() {
    this.currentPage = 0;
    // @ts-expect-error (legacy code incremental fix)
    this.pageKeys = [null];
  }

  searchSubscribers(): Promise<ScRelation<ExtendedSubscriberInterface>> {
    // This class doesn't use Searchlight.
    return this.listSubscribers();
  }

  listSubscribers(): Promise<ScRelation<ExtendedSubscriberInterface>> {
    const paginationOptions = this.generatePaginateOptions();
    const searchQuery: SearchQuery = new SearchQuery();
    searchQuery.setQueryItem('status_filter', this.filters.status);
    return this.service.list(paginationOptions, searchQuery);
  }

  private getSubscribers(): Promise<ScRelation<ExtendedSubscriberInterface>> {
    return this.searchSubscribers().catch((error: LegacyAny) => {
      // Mason 2020-10-02: special case error message rewriting was moved from here to BasePaginatableTableController, so just reject with the error as-is if we don't have a "search method fallback":
      if (this.hasSearchMethodFallback()) {
        return this.listSubscribers();
      } else {
        return this.$q.reject(error);
      }
    });
  }

  protected generateSearchQuery(): SearchQuery {
    // @ts-expect-error (legacy code incremental fix)
    return null;
  }

  protected generatePaginateOptions(): PaginationOptions {
    const lastEvaluatedKey = this.pageKeys[this.currentPage];
    return {
      last_evaluated_key: lastEvaluatedKey,
      limit: this.perPage,
    };
  }

  protected hasSearchMethodFallback() {
    return false;
  }
}
