<div class="ds-modal">
  <div class="ds-dialog">
    <header class="ds-banner">
      <i class="ds-icon --icon-download"></i>
      <div>
        <h3 [innerHTML]="'LogViewerRequestDownloadLinkComponent.header' | translate"></h3>
        <p [innerHTML]="'LogViewerRequestDownloadLinkComponent.description' | translate"></p>
      </div>
    </header>

    <section class="ds-rows">
      <ui-datetime-range-selector
        classOption="inline"
        [value]="queryTimeRange"
        [relativeTimeRangeOptions]="relativeTimeRangeOptions"
        (timeRangeChange)="onQueryTimeRangeChange($event)"
      ></ui-datetime-range-selector>

      <div [innerHTML]="'LogViewerRequestDownloadLinkComponent.helpText1' | translate"></div>

      <div class="ds-notice --info" [innerHTML]="'LogViewerRequestDownloadLinkComponent.notice1' | translate"></div>

      <ng-container>
        <app-alerts [alertsManager]="alertsManager"></app-alerts>
      </ng-container>
    </section>

    <footer>
      <button class="ds-button --primary" (click)="requestDownloadLink()">
        <span>{{ 'LogViewerRequestDownloadLinkComponent.button.request' | translate }}</span>
      </button>
      <button class="ds-button" (click)="close.emit()">
        <span>{{ 'generic.button_title.cancel' | translate }}</span>
      </button>
    </footer>
  </div>
</div>
