import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from '@soracom/shared-ng/ng-charts';
import { MapModule } from '../map/map.module';
import { ResourceSelectorModule } from '../resource-selector/resource-selector.module';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { HarvestDataDownloadButtonComponent } from './harvest-data-download-button/harvest-data-download-button.component';
import { HarvestDataDownloadModalComponent } from './harvest-data-download-modal/harvest-data-download-modal.component';
import { HarvestDataIntelligenceButtonComponent } from './harvest-data-intelligence/harvest-data-intelligence-button.component';
import { HarvestDataTableComponent } from './harvest-data-table/harvest-data-table.component';
import { HarvestDataChartDataSeriesControlComponent } from './harvest-data-viz/chart-dataseries-control/harvest-data-chart-dataseries-control.component';
import { HarvestDataChartYAxisControlComponent } from './harvest-data-viz/chart-yaxis-control/harvest-chart-yaxis-control.component';
import { HarvestDataChartComponent } from './harvest-data-viz/chart/harvest-data-chart.component';
import { HarvestDataMapContainerComponent } from './harvest-data-viz/map-container/harvest-data-map-container.component';
import { HarvestDataMapParserSettingControlComponent } from './harvest-data-viz/map-parser-setting-control/harvest-data-map-parser-setting-control.component';
import { HarvestDataChartPreviewPanelComponent } from './harvest-data-viz/preview-panel/harvest-data-chart-preview-panel.component';
import { HarvestDataChartContainerComponent } from './harvest-data-viz/viz-container/harvest-data-viz-container.component';
import { HarvestDataVizTypeSelectorComponent } from './harvest-data-viz/viz-type-selector/harvest-data-viz-type-selector.component';
import { HarvestDataComponent } from './harvest-data.component';

@NgModule({
  declarations: [
    HarvestDataVizTypeSelectorComponent,
    HarvestDataChartComponent,
    HarvestDataChartYAxisControlComponent,
    HarvestDataChartContainerComponent,
    HarvestDataChartDataSeriesControlComponent,
    HarvestDataChartPreviewPanelComponent,
    HarvestDataMapContainerComponent,
    HarvestDataMapParserSettingControlComponent,
    HarvestDataTableComponent,
    HarvestDataComponent,
    HarvestDataDownloadButtonComponent,
    HarvestDataDownloadModalComponent,
  ],
  exports: [HarvestDataChartContainerComponent, HarvestDataTableComponent, HarvestDataComponent],
  imports: [
    HarvestDataIntelligenceButtonComponent,
    ReactiveFormsModule,
    CommonModule,
    SoracomCommonModule,
    NgChartsModule,
    TranslateModule.forChild(),
    SoracomUiModule,
    ResourceSelectorModule,
    MapModule,
    FormsModule,
  ],
})
export class HarvestDataModule {}
