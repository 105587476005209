<div class="ds-rows">
  <h3 class="ds-heading--regular" translate="LagoonLogoComponent.title"></h3>
  <div class="ds-notice--info" translate="LagoonLogoComponent.text"></div>
  <div *ngIf="hasPermission">
    <div class="lagoon-logo-wrapper" *ngIf="loading.logo.length > 0">
      <ng-container *ngIf="loading.logo">
        <img [src]="loading.logo" crossorigin="anonymous" />
      </ng-container>
      <ng-container *ngIf="!loading.logo">
        <div class="image-placeholder" [innerHTML]="'LagoonLogoComponent.no_image' | translate"></div>
      </ng-container>
    </div>
    <ng-container *ngIf="loading.image">
      <div class="uploading-placeholder">
        <div class="loading-bar-text">
          {{ 'LagoonLogoComponent.uploading' | translate: { progress: barStyle.width } }}
        </div>
        <div class="loading-bar" [ngStyle]="barStyle"></div>
      </div>
    </ng-container>
    <div class="lagoon-logo-uploader" *ngIf="logoDetails">
      <div>
        <label class="ds-button--icon-cloud-upload" for="file-upload" [ngClass]="{ 'ds-button--loading': isLoading }">
          <span translate="LagoonLogoComponent.upload"></span>
        </label>
        <input #fileInput id="file-upload" type="file" accept="image/*" (change)="fileSelected($event)" />
      </div>
      <div class="ds-text--small" translate="LagoonLogoComponent.image_info"></div>
    </div>
  </div>
</div>
