import { LegacyAny } from '@soracom/shared/core';

import Clipboard from 'clipboard';
import { Component, OnInit } from '@angular/core';
import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  templateUrl: './sigfox_delete_immediately_success.component.html',
  selector: 'app-sigfox-delete-immediately-success',
  imports: [TranslateModule, CommonModule, FormsModule],
})
export class SigfoxDeleteImmediatelySuccessComponent
  extends LegacyUibModalAdapterModalBase<
    {
      params: () => {
        response: {
          deviceId: string;
          pac: string;
        };
      };
    },
    void
  >
  implements OnInit
{
  // resolve; // legacy code incremental fix note: commented out because the superclass declares this prop (and the dual declaration causes a TS error now, unless we use 'declare')
  afterDeleteResponse: LegacyAny;

  ngOnInit() {
    new Clipboard('.copy-pac-button');
    this.afterDeleteResponse = this.resolve?.params().response;
  }
}

export function openSigfoxDeleteImmediatelySuccessModal(
  uiDsModalservice: UiDsModalService,
  afterDeleteResponse: { deviceId: string; pac: string }
) {
  return uiDsModalservice.openAndWaitForResult(SigfoxDeleteImmediatelySuccessComponent, {
    title: 'terminate_resource.sigfoxDeletedImmediatelySuccessModal.title',
    data: {
      resolve: {
        params: () => {
          return {
            response: afterDeleteResponse,
          };
        },
      },
    },
  });
}
