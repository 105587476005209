import { LegacyAny } from '@soracom/shared/core';

import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { ItemChooserController, NullItemChooserController } from '../components/ItemChooserController';
import { InjectList } from '../core/injectable';
import { LoraGateway } from '../core/lora_gateway';
import { LoraNetworkSetsService } from '../lora_network_sets/lora_network_sets.service';
import { template } from './lora_gateway.component.html';
import { LoraGatewaysService } from './lora_gateways.service';
import { NetworkSetChooserController } from './NetworkSetChooserController';

export class LoraGatewayComponent implements ng.IComponentOptions {
  bindings = {};
  controller = LoraGatewayComponentController;
  template: any = template;
}

export class LoraGatewayComponentController extends BaseController {
  static $inject: InjectList = [
    '$log',
    '$routeParams',
    '$uibModal',
    'AlertsService',
    'LoraGatewaysService',
    'LoraNetworkSetsService',
  ];

  alertsService: AlertsServiceInstance;
  // @ts-expect-error (legacy code incremental fix)
  loraGateway: LoraGateway;
  itemChooserController: ItemChooserController<any> = new NullItemChooserController();
  editable = true;

  constructor(
    $log: ng.ILogService,
    private $routeParams: any,
    public $uibModal: any,
    alertsServiceGenerator: AlertsService,
    public loraGatewaysService: LoraGatewaysService,
    private loraNetworkSetsService: LoraNetworkSetsService
  ) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    this.reloadData();
  }

  reloadData() {
    this.loraGatewaysService
      .get(this.$routeParams.id)
      .then((loraGateway: LegacyAny) => {
        this.loraGateway = loraGateway;
        this.itemChooserController = new NetworkSetChooserController(
          this.alertsService,
          this.loraGatewaysService,
          this.loraNetworkSetsService,
          this.loraGateway
        );
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }
}
