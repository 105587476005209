import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WebStorageModule } from '@soracom/forks/ngx-store';
import { AutocompleteModule } from '@soracom/shared-ng/autocomplete';
import { HeaderComponent } from '@soracom/shared-ng/header';
import { LoggerServiceModule } from '@soracom/shared-ng/logger-service';
import { OperatorConfigurationModule } from '@soracom/shared-ng/operator-configuration';
import { SoracomApiNgClientModule } from '@soracom/shared-ng/soracom-api-ng-client';
import { DsGlobalLoadingIndicatorModule } from '@soracom/shared-ng/ui-sds';
import { environment } from '../environments/environment';
import { NgChartsModule } from '@soracom/shared-ng/ng-charts';
import { GpsMultiunitModule } from './gps-multiunit/gps-multiunit.module';
import { HarvestDataModule } from './harvest-data/harvest-data.module';
import { LagoonModule } from './lagoon/lagoon.module';
import { LegacySimsPageModule } from './legacy-sims-page.component';
import { LteMButtonModule } from './lte-m-button/lte-m-button.module';
import { PrefixedEventHandlersModule } from './prefixed_event_handlers/prefixed-event-handlers.module';
import { RootStoreModule } from './root-store/root-store.module';
import { SimDetailsModule } from './sim-details/sim-details.module';
import { SimsModule } from './sims/sims.module';
import { SoracomBeforeLoginModule } from './soracom-before-login/soracom-before-login.module';
import { SoracomCommonModule } from './soracom-common/soracom-common.module';
import { SoracomGroupsModule } from './soracom-groups/soracom-groups.module';
import { SoracomLogsModule } from './soracom-logs/soracom-logs.module';
import { SoracomLongTermDiscountsModule } from './soracom-long-term-discounts/soracom-long-term-discounts.module';
import { SoracomNavigationModule } from './soracom-navigation/soracom-navigation.module';
import { SoracomPaymentModule } from './soracom-payment/soracom-payment.module';
import { SoracomUiModule } from './soracom-ui/soracom-ui.module';
import { SubscribersModule } from './subscribers/subscribers.module';
import { TrafficVolumeRankingModule } from './traffic-volume-ranking/traffic-volume-ranking.module';
import { UiAskAiButtonComponent } from '@soracom/shared-ng/ui-ai-button';
import { FormErrorModule } from '@soracom/shared-ng/ui-form';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/locale-', '.json');
}

/**
 * These are declarations used in `@NgModule()` options. Normally they might be defined inline within the `@NgModule()` definition, but since we need to use this list in multiple places (hybrid app's module definition, pure angular test app's module definition, and Storybook test setup), it is separated out into a standalone exported array.
 */
export const appModuleImports: any[] = [
  CommonModule,
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  RootStoreModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
  StoreModule.forRoot({}),
  StoreDevtoolsModule.instrument({ logOnly: environment.production }),
  EffectsModule.forRoot([]),
  WebStorageModule,
  NgChartsModule,

  // Common modules
  SoracomBeforeLoginModule,
  SoracomCommonModule,
  SoracomNavigationModule,
  SoracomUiModule,
  AutocompleteModule,
  SoracomApiNgClientModule,

  HeaderComponent,

  // modules migrated from next-console back to user-console
  LoggerServiceModule,

  // modules defined in monorepo libs (could be used by other apps too)
  OperatorConfigurationModule.forRoot(),

  // Page Specific modules. DON'T import Lazy loaded modules as they are lazy loaded and they will conflict with other routes. MAKE SURE THAT LAZY LOADED MODULES IS NOT IMPORTED ANYWHERE, INCLUDING IN ANY OTHER MODULE.

  LagoonModule,
  SoracomLogsModule,
  SoracomGroupsModule,
  SoracomLongTermDiscountsModule,
  SoracomPaymentModule,
  SubscribersModule,
  GpsMultiunitModule,
  LteMButtonModule,
  HarvestDataModule,
  TrafficVolumeRankingModule,
  SimsModule,
  SimDetailsModule,

  FormErrorModule,

  // legacy, needs migrate to Angular.
  PrefixedEventHandlersModule,
  DsGlobalLoadingIndicatorModule,
  LegacySimsPageModule,

  UiAskAiButtonComponent,
];
