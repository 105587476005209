<section dsDialogContent>
  <div class="ds-rows" [formGroup]="form">
    <div class="ds-notice--info" [innerHTML]="'UpdateLagoonUserPermissionModalComponent.text' | translate"></div>
    <div class="ds-field" target="lagoon-user-role">
      <label class="ds-label" for="lagoon-user-role"
        ><span translate="UpdateLagoonUserPermissionModalComponent.role"></span>&nbsp;<span class="text-danger"
          >*</span
        ></label
      ><select
        class="ds-select"
        id="lagoon-user-role"
        name="lagoon-user-role"
        formControlName="role"
        required="required"
      >
        <option
          *ngFor="let role of roles"
          [attr.label]="'lagoon_users.values.role.' + role | translate"
          [ngValue]="role"
        ></option>
      </select>
    </div>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" [class.--loading]="loading" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'UpdateLagoonUserPermissionModalComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>
