export const template = `<div>
  <div class="modal-parent"></div>
</div>
<div class="row block">
  <div class="col-sm-12"><button class="btn btn-default" id="table-settings-button"
      ng-click="$ctrl.openTableSettingsModal()"><i class="glyphicon glyphicon-cog"></i></button><sc-pagination
      paginator="$ctrl.paginator" ctrl="$ctrl"></sc-pagination></div>
</div>
<div class="row block">
  <div class="col-sm-12">
    <div class="table-responsive table-subscribers">
      <table class="sc-table table ds-datatable--striped x-subscribers-table" id="subscribers-table">
        <thead>
          <th class="th-checkbox ds-datatable__col--min" id="subscribers-th-checkbox"
            ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
            <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check"
                id="subscribers-checkbox-for-all" type="checkbox" ng-model="$ctrl.checkboxForAll"
                ng-change="$ctrl.checkboxForAllChanged($ctrl.checkboxForAll);" />
              <div class="ds-checkbox__label">Check All</div>
            </div>
          </th>
          <th id="subscribers-th-sim-id" ng-if="$ctrl.shouldShowColumn(&quot;sim_id&quot;)">{{
            &quot;subscribers.columns.sim_id&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href="" ng-bind-html="'subscribers.legends.sim_id.description' | translate"></a>
                  </li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)">{{
            &quot;subscribers.columns.name&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href="" ng-bind-html="'subscribers.legends.name.description' | translate"></a>
                  </li>
                </ul>
              </div>
            </span><span class="inline-block pull-right x-current-page-sort-by-name" ng-if="$ctrl.canSortByName()"
              ng-click="$ctrl.nameSortToggled()"><i class="glyphicon glyphicon-sort-by-attributes-alt"
                title="{{ 'currentPageSortByName.helpText.reverseSortedByName' | translate }}"
                ng-if="$ctrl.sortPredicate === 'tags.name' &amp;&amp; $ctrl.sortReverse"></i><i
                class="glyphicon glyphicon-sort-by-attributes"
                title="{{ 'currentPageSortByName.helpText.sortedByName' | translate }}"
                ng-if="$ctrl.sortPredicate === 'tags.name' &amp;&amp; !$ctrl.sortReverse"></i><i
                class="glyphicon glyphicon-sort-by-attributes"
                title="{{ 'currentPageSortByName.helpText.notSortedByName' | translate }}" style="color: lightgray"
                ng-if="$ctrl.sortPredicate !== 'tags.name'"></i></span></th>
          <th id="subscribers-th-group" ng-if="$ctrl.shouldShowColumn(&quot;group&quot;)">{{
            &quot;subscribers.columns.group&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href="" ng-bind-html="'subscribers.legends.group.description' | translate"></a>
                  </li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-iccid" ng-if="$ctrl.shouldShowColumn(&quot;iccid&quot;)">{{
            &quot;subscribers.columns.iccid&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href="" ng-bind-html="'subscribers.legends.iccid.description' | translate"></a>
                  </li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-imsi" ng-if="$ctrl.shouldShowColumn(&quot;imsi&quot;)">{{
            &quot;subscribers.columns.imsi&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href="" ng-bind-html="'subscribers.legends.imsi.description' | translate"></a>
                  </li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-msisdn" ng-if="$ctrl.shouldShowColumn(&quot;msisdn&quot;)">{{
            &quot;subscribers.columns.msisdn&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href="" ng-bind-html="'subscribers.legends.msisdn.description' | translate"></a>
                  </li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-serial-number" ng-if="$ctrl.shouldShowColumn(&quot;serial_number&quot;)">{{
            &quot;subscribers.columns.serial_number&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href=""
                      ng-bind-html="'subscribers.legends.serial_number.description' | translate"></a></li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-ip-address" ng-if="$ctrl.shouldShowColumn(&quot;ip_address&quot;)">{{
            &quot;subscribers.columns.ip_address&quot; | translate }}&nbsp;</th>
          <th id="subscribers-th-status" ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)">{{
            &quot;subscribers.columns.status&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href="" ng-bind-html="'subscribers.legends.status.description' | translate"></a>
                  </li>
                </ul>
              </div>
            </span>
            <div class="inline-block"><sc-sort-filter-menu
                ng-if="$ctrl.editable &amp;&amp; $ctrl.apiMode !== &quot;sim&quot;"
                sort-order="$ctrl.sortFilterOptions.statusSortOrder" filter-items="$ctrl.statusList"
                filtered-items="$ctrl.sortFilterOptions.selectedStatusList"
                on-sort-order-change="$ctrl.statusSortOrderChanged(newOrder)"
                on-filter-change="$ctrl.statusFilterChanged(filteredItems)"></sc-sort-filter-menu>&nbsp;<i
                class="glyphicon glyphicon-sort-by-alphabet"
                ng-show="$ctrl.statusSortOrder === &quot;ascend&quot;"></i><i
                class="glyphicon glyphicon-sort-by-alphabet-alt"
                ng-show="$ctrl.statusSortOrder === &quot;descend&quot;"></i><i class="glyphicon glyphicon-filter"
                ng-show="$ctrl.isStatusFiltered()"></i></div>
          </th>
          <th id="subscribers-th-last-seen" ng-if="$ctrl.shouldShowColumn(&quot;last_seen&quot;)">{{
            &quot;subscribers.columns.last_seen&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href=""
                      ng-bind-html="'subscribers.legends.last_seen.description' | translate"></a></li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-country" ng-if="$ctrl.shouldShowColumn(&quot;country&quot;)">{{
            &quot;subscribers.columns.country&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href=""
                      ng-bind-html="'subscribers.legends.country.description' | translate"></a></li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-plan" ng-if="$ctrl.shouldShowColumn(&quot;plan&quot;)">{{
            &quot;subscribers.columns.plan&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu" role="menu">
                  <li><a class="notice" href="" ng-bind-html="'subscribers.legends.plan.description' | translate"></a>
                  </li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-subscription" ng-if="$ctrl.shouldShowColumn(&quot;subscription&quot;)">{{
            &quot;subscribers.columns.subscription&quot; | translate }}&nbsp;</th>
          <th id="subscribers-th-bundles" ng-if="$ctrl.shouldShowColumn(&quot;bundles&quot;)">{{
            &quot;subscribers.columns.bundles&quot; | translate }}&nbsp;</th>
          <th id="subscribers-th-module-type" ng-if="$ctrl.shouldShowColumn(&quot;module_type&quot;)">{{
            &quot;subscribers.columns.module_type&quot; | translate }}&nbsp;</th>
          <th id="subscribers-th-speed-class" ng-if="$ctrl.shouldShowColumn(&quot;speed_class&quot;)">{{
            &quot;subscribers.columns.speed_class&quot; | translate }}&nbsp;<app-speed-class-legend
              [subscribers]="$ctrl.subscribers"></app-speed-class-legend></th>
          <th id="subscribers-th-expiry" ng-if="$ctrl.shouldShowColumn(&quot;expiry&quot;)">{{
            &quot;subscribers.columns.expiry&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu dropdown-menu-right" role="menu">
                  <li><a class="notice" href="" ng-bind-html="'subscribers.legends.expiry.description' | translate"></a>
                  </li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-imei-lock" ng-if="$ctrl.shouldShowColumn(&quot;imei_lock&quot;)">{{
            &quot;subscribers.columns.imei_lock&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu dropdown-menu-right" role="menu">
                  <li><a class="notice" href=""
                      ng-bind-html="'subscribers.legends.imei_lock.description' | translate"></a></li>
                </ul>
              </div>
            </span></th>
          <th id="subscribers-th-termination-protection"
            ng-if="$ctrl.shouldShowColumn(&quot;termination_protection&quot;)">{{
            &quot;subscribers.columns.termination_protection&quot; | translate }}&nbsp;<span class="th-legend">
              <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                  uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                <ul class="dropdown-menu dropdown-menu-right" role="menu">
                  <li><a class="notice" href=""
                      ng-bind-html="'subscribers.legends.termination_protection.description' | translate"></a></li>
                </ul>
              </div>
            </span></th>
          <th ng-repeat="tag in $ctrl.customTagColumns" ng-attr-id="{{'subscribers-th-' + tag}}">{{ tag }}</th>
        </thead>
        <tbody class="x-subscribers-list" ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()">
          <tr ng-class="{ unselectable: !$ctrl.selectable() }"
            ng-repeat="row in $ctrl.tableData.rows|orderObjectBy:$ctrl.sortPredicate:$ctrl.sortReverse"
            ng-click="$ctrl.setSelected(row)" sc-context-menu="subscribers-context-menu">
            <td class="td-checkbox subscribers-checkbox"
              ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
              <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check checkbox-for-each"
                  id="cb{{row.obj.id}}" type="checkbox" ng-model="row.selected"
                  ng-change="$ctrl.checkboxForEachChanged()" ng-click="$event.stopPropagation()" />
                <div class="ds-checkbox__label">Check {{ row.obj.id }}</div>
              </div>
            </td>
            <td class="subscribers-sim-id" ng-if="$ctrl.shouldShowColumn(&quot;sim_id&quot;)" ng-bind="row.obj.simId">
            </td>
            <td class="subscribers-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)"><sc-inplace-editable-text
                ng-if="$ctrl.editable" ng-mouseover="row.hoverName = true" ng-mouseleave="row.hoverName = false"
                key="name" cancel-on-blur="true" display-value="row.obj.name" edit-ctrl="row.nameEditCtrl"
                padding-left="&quot;5&quot;" show-pencil-icon="row.hoverName" show-remove-button="false"
                show-save-button="false" show-cancel-button="false"></sc-inplace-editable-text><a ng-href="/sims/{{ row.obj.simId }}" target="_blank"><span
                ng-if="!$ctrl.editable" ng-bind="row.obj.name"></span><i class="ds-icon --icon-arrow-top-right --tiny"></i></a></td>
            <td class="subscribers-group feedback" ng-if="$ctrl.shouldShowColumn(&quot;group&quot;)"
              sc-update-feedback="sc-update-feedback"
              feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
              feedback-type="row.feedback.updateGroup" feedback-duration="3000">
              <div class="overflow-auto"><span>{{ row.obj.groupName
                    }}</span></div>
            </td>
            <td class="subscribers-iccid" ng-if="$ctrl.shouldShowColumn(&quot;iccid&quot;)" ng-bind="row.obj.iccid">
            </td>
            <td class="subscribers-imsi" ng-if="$ctrl.shouldShowColumn(&quot;imsi&quot;)">
              <div class="single" ng-if="!row.obj.hasMultipleImsis">{{ row.obj.imsi }}</div>
              <div class="multiple" ng-if="row.obj.hasMultipleImsis">
                <div ng-repeat="subscriber in row.obj.allSubscribersWithPrimaryFirstThenSortedByImsi">
                  <div style="white-space: nowrap;" ng-if="$index == 0">{{ subscriber.imsi }}</div>
                  <div class="text-muted" style="white-space: nowrap; font-size: smaller;" ng-if="$index &gt; 0"
                    ng-style="{'text-decoration': subscriber.status === 'terminated' ? 'line-through' : ''}">{{
                    subscriber.imsi }}</div>
                </div>
              </div>
            </td>
            <td class="subscribers-msisdn" ng-if="$ctrl.shouldShowColumn(&quot;msisdn&quot;)" ng-bind="row.obj.msisdn">
            </td>
            <td class="subscribers-serial-number" ng-if="$ctrl.shouldShowColumn(&quot;serial_number&quot;)"
              ng-bind="row.obj.serialNumber"></td>
            <td class="subscribers-ip-address" ng-if="$ctrl.shouldShowColumn(&quot;ip_address&quot;)">
              <div ng-if="row.obj.getIpAddress()">{{ row.obj.getIpAddress() }}</div>
              <div class="div"
                ng-if="!row.obj.getIpAddress() &amp;&amp; row.obj.previousSession &amp;&amp; row.obj.previousSession.vpgId &amp;&amp; row.obj.previousSession.ueIpAddress">
                <div class="ds-text--small"
                  data-ds-texttip="{{'subscribers.legends.ip_address.previous_ip_address' | translate}}"
                  style="font-style: italic">{{ row.obj.previousSession.ueIpAddress }}</div>
              </div>
            </td>
            <td class="subscribers-status feedback" ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)"
              sc-update-feedback="sc-update-feedback"
              feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
              feedback-type="row.feedback.updateStatus" feedback-duration="{{ $ctrl.getFeedbackDuration() }}">
              <sim-status-info ng-prop-is_online="row.obj.isOnline()" ng-prop-status="row.obj.status"
                ng-prop-is_transferring="row.obj.isTransferring()"></sim-status-info><sc-session-status-label
                is-online="row.obj.isOnline()" disabled="row.obj.status !== 'active'"></sc-session-status-label></td>
            <td class="subscribers-last-seen" ng-if="$ctrl.shouldShowColumn(&quot;last_seen&quot;)">
              <div ng-if="!row.obj.isOnline()" sc-date-time="row.obj.lastSeen"
                format="common.dayjs_format.iso_8601_friendly" alt-text="subscribers_table.last_seen.no_data"></div>
            </td>
            <td class="subscribers-country" ng-if="$ctrl.shouldShowColumn(&quot;country&quot;)">
              <sc-subscriber-session-country-info cell-info="row.obj.cellInfo"></sc-subscriber-session-country-info>
            </td>
            <td class="subscribers-plan" ng-if="$ctrl.shouldShowColumn(&quot;plan&quot;)"><i
                class="glyphicon glyphicon-sort"></i>&nbsp;<i class="glyphicon glyphicon-envelope"
                ng-show="row.obj.hasSms()"></i><i class="glyphicon glyphicon-blank" ng-show="!row.obj.hasSms()"></i>
            </td>
            <td class="subscribers-subscription" ng-if="$ctrl.shouldShowColumn(&quot;subscription&quot;)">
              <div class="single-subscription" ng-if="!row.obj.hasMultipleSubscriptions">{{ row.obj.subscription ||
                '--'}}</div>
              <div class="multiple-subscription" ng-if="row.obj.hasMultipleSubscriptions">
                <div ng-repeat="subscriber in row.obj.allSubscribersWithPrimaryFirstThenSortedByImsi">
                  <div style="white-space: nowrap;" ng-if="$index == 0">{{ subscriber.subscription }}</div>
                  <div class="text-muted" style="white-space: nowrap; font-size: smaller;" ng-if="$index &gt; 0"
                    ng-style="{'text-decoration': subscriber.status === 'terminated' ? 'line-through' : ''}">{{
                    subscriber.subscription }} {{
                    row.obj.secondarySubscriberStatusForDisplayInSubscriptionColumn(subscriber.imsi) }}</div>
                </div>
              </div>
            </td>
            <td class="subscribers-bundles feedback" ng-if="$ctrl.shouldShowColumn(&quot;bundles&quot;)"
              sc-update-feedback="sc-update-feedback"
              feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
              feedback-type="row.feedback.updateBundles" feedback-duration="{{ $ctrl.getFeedbackDuration() }}"><span
                ng-if="!row.obj.isEligibleForBundles">--</span><span
                ng-if="row.obj.isEligibleForBundles &amp;&amp; !$ctrl.editable">{{ row.obj.bundlesDescription ||
                '--'}}</span><a ng-if="row.obj.isEligibleForBundles &amp;&amp; $ctrl.editable" href=""
                ng-click="$ctrl.showBundleEditorForSubscribers([row.obj])">{{ row.obj.bundlesDescription || '--'}}</a>
            </td>
            <td class="subscribers-module-type" ng-if="$ctrl.shouldShowColumn(&quot;module_type&quot;)">{{
              row.obj.getPrettyModuleTypeNameId() | translate }}</td>
            <td class="subscribers-speed-class feedback"
              ng-if="$ctrl.shouldShowColumn(&quot;speed_class&quot;) &amp;&amp; $ctrl.editable &amp;&amp; !row.obj.isTransferring()"
              sc-update-feedback="sc-update-feedback"
              feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
              feedback-type="row.feedback.updateSpeedClass" feedback-duration="{{ $ctrl.getFeedbackDuration() }}">
              <select class="form-control" ng-init="row.state.nextSpeedClass = row.obj.speedClass"
                ng-model="row.state.nextSpeedClass"
                ng-options="speedClass as speedClass for speedClass in $ctrl.getSpeedClassesForSubscriber(row.obj)"
                ng-click="$event.preventDefault(); $event.stopPropagation();"
                ng-change="$ctrl.changeSpeedClassFromSubscriberTable(row.obj, row.state.nextSpeedClass)"
                required="required"></select></td>
            <td class="subscribers-speed-class"
              ng-if="$ctrl.shouldShowColumn(&quot;speed_class&quot;) &amp;&amp; (!$ctrl.editable || row.obj.isTransferring())">
              &nbsp; {{ $ctrl.getBps(row.obj.speedClass).label }}</td>
            <td class="subscribers-expiry feedback"
              ng-class="{ &quot;text-danger&quot;: $ctrl.isExpired(row.obj.expiredAt) }"
              ng-if="$ctrl.shouldShowColumn(&quot;expiry&quot;) &amp;&amp; $ctrl.editable"
              ng-mouseover="row.hoverExpiry = true" ng-mouseleave="row.hoverExpiry = false"
              sc-update-feedback="sc-update-feedback"
              feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
              feedback-type="row.feedback.updateExpiry" feedback-duration="{{ $ctrl.getFeedbackDuration() }}">{{
              $ctrl.formatExpiredAt(row.obj.expiredAt) }}<a class="pull-right" href=""
                ng-click="$ctrl.changeExpiredAt($event, row.obj)"><i class="glyphicon glyphicon-pencil"
                  ng-show="row.hoverExpiry"></i></a></td>
            <td class="subscribers-expiry" ng-if="$ctrl.shouldShowColumn(&quot;expiry&quot;) &amp;&amp; !$ctrl.editable"
              ng-class="{ &quot;text-danger&quot;: $ctrl.isExpired(row.obj.expiredAt) }">
              {{$ctrl.formatExpiredAt(row.obj.expiredAt)}}</td>
            <td class="imei-lock feedback" ng-if="$ctrl.shouldShowColumn(&quot;imei_lock&quot;)"
              sc-update-feedback="sc-update-feedback"
              feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
              feedback-type="row.feedback.updateImeiLock" feedback-duration="{{ $ctrl.getFeedbackDuration() }}"><i
                class="ds-icon --icon-unlock" ng-show="!row.obj.imeiLock" style="color: lightgray;"></i><i
                class="ds-icon --icon-lock" ng-show="row.obj.imeiLock"></i>&nbsp; {{row.obj.imeiLock &&
              row.obj.imeiLock.imei}}</td>
            <td class="subscribers-termination-protection"
              ng-if="$ctrl.shouldShowColumn(&quot;termination_protection&quot;)" sc-update-feedback="sc-update-feedback"
              feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
              feedback-type="row.feedback.changeTerminationProtection" feedback-duration="3000"><i
                class="ds-icon --icon-unlock x-tp-disabled" ng-if="row.obj.terminationEnabled"
                style="color: lightgray;"></i><i class="ds-icon --icon-lock x-tp-enabled"
                ng-if="!row.obj.terminationEnabled"></i></td>
            <td ng-repeat="tag in $ctrl.customTagColumns">{{ row.obj.tags[tag] }}</td>
          </tr>
        </tbody>
        <tbody class="x-no-subscribers-exist" ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()">
          <tr class="no-data">
            <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.no_data' | translate
              }}</td>
          </tr>
        </tbody>
        <tbody class="x-is-loading" ng-if="$ctrl.isLoading()">
          <tr class="loading">
            <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.loading' | translate
              }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>`;
