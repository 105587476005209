import { LegacyAny } from '@soracom/shared/core';

import { AlertsService, AlertsServiceInstance } from '../../components/alerts.service';
import { BaseModalController } from '../../components/base_modal_controller';
import { HarvestContract } from '../../core/harvest_contract';
import { InjectList } from '../../core/injectable';
import { template } from './update_contract.modal.component.html';

export class UpdateContractModalComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = UpdateContractModalComponentController;

  template: any = template;
}

export class UpdateContractModalComponentController extends BaseModalController {
  static $inject: InjectList = ['$log', 'AlertsService'];

  alertsService: AlertsServiceInstance;

  // @ts-expect-error (legacy code incremental fix)
  current: HarvestContract;
  // @ts-expect-error (legacy code incremental fix)
  selected: HarvestContract;

  submitting = true;

  constructor($log: LegacyAny, alertsServiceGenerator: AlertsService) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    this.current = this.resolve.current;
    this.selected = this.resolve.selected;
    this.submitting = false;
  }

  canSubmit(): boolean {
    return !this.submitting && !this.selected.equals(this.current);
  }

  willSubscribe(): boolean {
    return this.current.plan === HarvestContract.normal.plan && this.selected.plan !== HarvestContract.normal.plan;
  }

  willUnsubscribe(): boolean {
    return this.current.plan !== HarvestContract.normal.plan && this.selected.plan === HarvestContract.normal.plan;
  }

  translationData(contract: HarvestContract): { ttl: string } {
    return { ttl: contract.ttl.toString() };
  }

  submit() {
    this.close(this.selected);
  }

  cancel() {
    this.dismiss();
  }
}
