<div class="ds-modal">
  <div class="ds-dialog">
    <div class="ds-dialog__header">
      <div class="ds-dialog__title">{{ dialogTitle | translate }}</div>
    </div>
    <!-- FIXME: this div.dialog_main should not need to set display: block (he's hiding from scary GRID) -->
    <div class="ds-dialog__main" style="display: block" [innerHTML]="dialogBody | translate"></div>
    <div class="ds-dialog__footer">
      <button class="ds-button ds-button--primary" (click)="closeWithUserConfirmation()">
        <span>{{ dialogConfirmButtonTitle | translate }}</span>
      </button>
      <button class="ds-button" (click)="closeWithoutUserConfirmation()">
        <span>{{ 'generic.button_title.cancel' | translate }}</span>
      </button>
    </div>
  </div>
</div>
