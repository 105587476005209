import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { LagoonActivationRequest, LagoonService } from '../../../../app/shared/lagoon/lagoon.service';
import { LagoonPlan } from '../../../../app/shared/lagoon/lagoon_plans';
import { environment } from '../../../environments/environment';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-register-lagoon',
  templateUrl: './register-lagoon-modal.component.html',
})
export class RegisterLagoonModalComponent extends DsModalContentBase<void, { success: boolean }> implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  outputType: void;
  // @ts-expect-error (legacy code incremental fix)
  output: void;
  req: LagoonActivationRequest = {
    // @ts-expect-error (legacy code incremental fix)
    plan: null,
    // @ts-expect-error (legacy code incremental fix)
    userPassword: null,
  };
  className = 'RegisterLagoonModalComponent';
  // @ts-expect-error (legacy code incremental fix)
  planName: string;
  // @ts-expect-error (legacy code incremental fix)
  translationValues: { url: string } = { url: null };
  state: 'plan' | 'password' = 'plan';
  debugMode = environment.development;
  alertsManager = new AlertsManager();
  classic = false;
  isLoading = false;

  get canConfirm(): boolean {
    if (this.state === 'plan') {
      return Boolean(this.req.plan);
    } else if (this.state === 'password') {
      return Boolean(this.req.plan && this.req.userPassword);
    } else {
      return false;
    }
  }
  get headingId(): string {
    return 'RegisterLagoonModalComponent.title';
  }
  get confirmButtonTitle(): string {
    if (this.state === 'password') {
      return 'RegisterLagoonModalComponent.submit';
    } else {
      return 'RegisterLagoonModalComponent.next';
    }
  }

  constructor(
    private coverageTypeService: CoverageTypeService,
    private lagoonService: LagoonService,
    private logger: Logger,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const coverageType = this.coverageTypeService.getCoverageType();
    this.translateService.get(`LagoonComponent.pricing_url_${coverageType}`).subscribe((url: string) => {
      this.translationValues = { url };
    });
  }

  userPasswordChanged(password: string) {
    this.req.userPassword = password;
  }

  setPlan(plan: LagoonPlan) {
    this.req.plan = plan;
  }

  confirm() {
    if (this.state === 'plan') {
      this.planName = this.translateService.instant(`RegisterLagoonModalComponent.${this.req.plan}`);
      this.state = 'password';
    } else if (this.state === 'password') {
      this.isLoading = true;
      this.lagoonService
        .register(this.req, this.classic)
        .then(() => {
          this.isLoading = false;
          this.close({ success: true });
        })
        .catch((error: LegacyAny) => {
          this.isLoading = false;
          this.alertsManager.add(Alert.fromApiError(error));
        });
    } else {
      this.logger.error(`Invalid State: ${this.state}`);
    }
  }

  canClose(): boolean {
    return !this.isLoading;
  }
}
