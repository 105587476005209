export const template = `<div class="row block">
  <div class="col-sm-12"><a class="btn btn-primary" href="" ng-click="$ctrl.doAction(&quot;Create&quot;)"
      ng-disabled="!$ctrl.actionable(&quot;Create&quot;)"><i class="glyphicon glyphicon-plus"></i><span
        class="button-text" translate="device_keys.actions.create"></span></a></div>
</div>
<div class="row block">
  <div class="col-sm-12">
    <div class="table-responsive table-">
      <table class="sc-table table ds-datatable--striped x--table" id="-table">
        <thead>
          <tr>
            <th id="device-keys-th-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)">{{
              &quot;device_keys.columns.id&quot; | translate }}&nbsp;</th>
            <th id="device-keys-th-status" ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)">{{
              &quot;device_keys.columns.status&quot; | translate }}&nbsp;</th>
            <th id="device-keys-th-created-time" ng-if="$ctrl.shouldShowColumn(&quot;created_time&quot;)">{{
              &quot;device_keys.columns.created_time&quot; | translate }}&nbsp;</th>
            <th id="device-keys-th-actions" ng-if="$ctrl.shouldShowColumn(&quot;actions&quot;)">{{
              &quot;device_keys.columns.actions&quot; | translate }}&nbsp;</th>
          </tr>
        </thead>
        <tbody class="x--list" ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()">
          <tr ng-repeat="row in $ctrl.tableData.rows">
            <td class="device-keys-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)" ng-bind="row.obj.id"></td>
            <td ng-class="['device-keys-status', 'status' + row.obj.status]"
              ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)"><span ng-if="row.obj.status">{{
                'device_keys.values.status.' + row.obj.status | translate }}</span></td>
            <td class="device-keys-created-time" ng-if="$ctrl.shouldShowColumn(&quot;created_time&quot;)"
              sc-date-time="row.obj.createdTime"></td>
            <td><a class="device-api-keys-activate btn btn-default" sc-confirm="sc-confirm"
                key="device_keys.confirmation.activate" on-confirm="$ctrl.doAction(&quot;Activate&quot;, row.obj)"
                ng-disabled="!$ctrl.actionable(&quot;Activate&quot;, row.obj)">{{ 'device_keys.actions.activate' |
                translate }}</a><a class="device-api-keys-deactivate btn btn-default" sc-confirm="sc-confirm"
                key="device_keys.confirmation.deactivate" on-confirm="$ctrl.doAction(&quot;Deactivate&quot;, row.obj)"
                ng-disabled="!$ctrl.actionable(&quot;Deactivate&quot;, row.obj)">{{ 'device_keys.actions.deactivate' |
                translate }}</a><a class="device-api-keys-delete btn btn-danger" sc-confirm="sc-confirm" type="deletion"
                on-confirm="$ctrl.doAction(&quot;Delete&quot;, row.obj)"
                ng-disabled="!$ctrl.actionable(&quot;Delete&quot;, row.obj)">{{ 'device_keys.actions.delete' | translate
                }}</a></td>
          </tr>
        </tbody>
        <tbody class="x-no--exist" ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()">
          <tr class="no-data">
            <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.no_data' | translate
              }}</td>
          </tr>
        </tbody>
        <tbody class="x-is-loading" ng-if="$ctrl.isLoading()">
          <tr class="loading">
            <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.loading' | translate
              }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>`;
