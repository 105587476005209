import { LegacyAny } from '@soracom/shared/core';

import { UCStorage } from '../../../src/app/shared/UCStorage';
import { InjectList } from '../core/injectable';
import { template } from './subscribers.component.html';
import * as angular from 'angular';

export class SubscribersComponent implements ng.IComponentOptions {
  bindings = {
    apiMode: '@',
  };
  controller = SubscribersComponentController;
  template: any = template;
}

export class SubscribersComponentController {
  static $inject: InjectList = ['$location', '$scope'];
  constructor(private $location: ng.ILocationService, private $scope: ng.IScope) {}

  enableSimApiMode = ($event?: LegacyAny) => {
    if ($event) {
      $event.preventDefault();
    }
    // @ts-expect-error (legacy code incremental fix)
    UCStorage.optOutOfSimManagementV2 = null;
    this.$location.path('/sims');
    this.$scope.$applyAsync();
  };
}

export type SubscribersApiMode = 'subscriber' | 'sim';
