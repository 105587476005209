export const template = `<div class="ds-notice--info">{{ 'watch.ignore.description' | translate }}</div>
<form class="ignore-form" name="$ctrl.ignorePrefixedEventHandlersForm" ng-disabled="$ctrl.nowLoading">
  <div class="row block">
    <div class="col-sm-12">
      <h4>{{ 'watch.send_mail_to_operator_action.title' | translate }}</h4>
    </div>
  </div>
  <div class="row block" ng-if="$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.active">
    <div class="col-sm-9">{{ 'watch.send_mail_to_operator_action.daily_limit_view' | translate:{ value:
      $ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.value } }}</div>
    <div class="col-sm-3">
      <div class="checkbox"><label><input name="smaDailyLimit" type="checkbox"
            ng-model="$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.ignored" />{{ 'watch.ignore.checkbox' |
          translate }}</label></div>
    </div>
  </div>
  <div class="row block" ng-if="!$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.active">
    <div class="col-sm-12">
      <div class="form-control-static">{{ 'watch.send_mail_to_operator_action.daily_limit_view_not_exist' | translate }}
      </div>
    </div>
  </div>
  <div class="row block" ng-if="$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.active">
    <div class="col-sm-9">{{ 'watch.send_mail_to_operator_action.monthly_limit_view' | translate:{ value:
      $ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.value } }}</div>
    <div class="col-sm-3">
      <div class="checkbox"><label><input name="smaMonthlyLimit" type="checkbox"
            ng-model="$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.ignored" />{{ 'watch.ignore.checkbox' |
          translate }}</label></div>
    </div>
  </div>
  <div class="row block" ng-if="!$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.active">
    <div class="col-sm-12">
      <div class="form-control-static">{{ 'watch.send_mail_to_operator_action.monthly_limit_view_not_exist' | translate
        }}</div>
    </div>
  </div>
  <div class="row block" ng-if="$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.active">
    <div class="col-sm-9">{{ 'watch.send_mail_to_operator_action.cumulative_limit_view' | translate:{ value:
      $ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.value } }}</div>
    <div class="col-sm-3">
      <div class="checkbox"><label><input name="smaCumulativeLimit" type="checkbox"
            ng-model="$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.ignored" />{{
          'watch.ignore.checkbox' | translate }}</label></div>
    </div>
  </div>
  <div class="row block" ng-if="!$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.active">
    <div class="col-sm-12">
      <div class="form-control-static">{{ 'watch.send_mail_to_operator_action.cumulative_limit_view_not_exist' |
        translate }}</div>
    </div>
  </div>
  <div class="row block"
    ng-if="$ctrl.settings.SendMailToOperatorAction.MonthlyTotalTrafficRule.active &amp;&amp; $ctrl.shouldShowMonthlyTotalLimit()">
    <div class="col-sm-9">{{ 'watch.send_mail_to_operator_action.monthly_total_limit_view' | translate:{ value:
      $ctrl.settings.SendMailToOperatorAction.MonthlyTotalTrafficRule.value } }}</div>
    <div class="col-sm-3">
      <div class="checkbox"><label><input name="smaMonthlyTotalLimit" type="checkbox"
            ng-model="$ctrl.settings.SendMailToOperatorAction.MonthlyTotalTrafficRule.ignored" />{{
          'watch.ignore.checkbox' | translate }}</label></div>
    </div>
  </div>
  <div class="row block"
    ng-if="!$ctrl.settings.SendMailToOperatorAction.MonthlyTotalTrafficRule.active &amp;&amp; $ctrl.shouldShowMonthlyTotalLimit()">
    <div class="col-sm-12">
      <div class="form-control-static">{{ 'watch.send_mail_to_operator_action.monthly_total_limit_view_not_exist' |
        translate }}</div>
    </div>
  </div>
  <div class="row block">
    <div class="col-sm-12">
      <h4>{{ 'watch.change_speed_class_action.title' | translate }}</h4>
    </div>
  </div>
  <div class="row block" ng-if="$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.active">
    <div class="col-sm-9">{{ 'watch.change_speed_class_action.daily_limit_view' | translate:{ value:
      $ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.value } }}</div>
    <div class="col-sm-3">
      <div class="checkbox"><label><input name="cscaDailyLimit" type="checkbox"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.ignored" />{{ 'watch.ignore.checkbox' |
          translate }}</label></div>
    </div>
  </div>
  <div class="row block" ng-if="!$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.active">
    <div class="col-sm-12">
      <div class="form-control-static">{{ 'watch.change_speed_class_action.daily_limit_view_not_exist' | translate }}
      </div>
    </div>
  </div>
  <div class="row block" ng-if="$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.active">
    <div class="col-sm-9">{{ 'watch.change_speed_class_action.monthly_limit_view' | translate:{ value:
      $ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.value } }}</div>
    <div class="col-sm-3">
      <div class="checkbox"><label><input name="cscaMonthlyLimit" type="checkbox"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.ignored" />{{ 'watch.ignore.checkbox' |
          translate }}</label></div>
    </div>
  </div>
  <div class="row block" ng-if="!$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.active">
    <div class="col-sm-12">
      <div class="form-control-static">{{ 'watch.change_speed_class_action.monthly_limit_view_not_exist' | translate }}
      </div>
    </div>
  </div>
  <div class="row block" ng-if="$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.active">
    <div class="col-sm-9">{{ 'watch.change_speed_class_action.cumulative_limit_view' | translate:{ value:
      $ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.value } }}</div>
    <div class="col-sm-3">
      <div class="checkbox"><label><input name="cscaCumulativeLimit" type="checkbox"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.ignored" />{{ 'watch.ignore.checkbox'
          | translate }}</label></div>
    </div>
  </div>
  <div class="row block" ng-if="!$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.active">
    <div class="col-sm-12">
      <div class="form-control-static">{{ 'watch.change_speed_class_action.cumulative_limit_view_not_exist' | translate
        }}</div>
    </div>
  </div>
  <div class="row block"
    ng-if="$ctrl.settings.ChangeSpeedClassAction.MonthlyTotalTrafficRule.active &amp;&amp; $ctrl.shouldShowMonthlyTotalLimit()">
    <div class="col-sm-9">{{ 'watch.change_speed_class_action.monthly_total_limit_view' | translate:{ value:
      $ctrl.settings.ChangeSpeedClassAction.MonthlyTotalTrafficRule.value } }}</div>
    <div class="col-sm-3">
      <div class="checkbox"><label><input name="cscaMonthlyTotalLimit" type="checkbox"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.MonthlyTotalTrafficRule.ignored" />{{
          'watch.ignore.checkbox' | translate }}</label></div>
    </div>
  </div>
  <div class="row block"
    ng-if="!$ctrl.settings.ChangeSpeedClassAction.MonthlyTotalTrafficRule.active &amp;&amp; $ctrl.shouldShowMonthlyTotalLimit()">
    <div class="col-sm-12">
      <div class="form-control-static">{{ 'watch.change_speed_class_action.monthly_total_limit_view_not_exist' |
        translate }}</div>
    </div>
  </div>
  <div class="row block">
    <div class="col-sm-12"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  </div>
  <div class="row">
    <div class="col-sm-12"><button class="btn btn-primary" ng-disabled="$ctrl.ignorePrefixedEventHandlersForm.$pristine"
        ng-click="$ctrl.updateSettings()">{{ 'watch.update_button' | translate }}</button></div>
  </div>
</form>`;
