import { TableController } from '../../components/base_table_controller';
import { Action } from './Action';

/**
 * Generic action to reload table.
 */
export class ReloadTableAction<T> implements Action<T> {
  constructor(private ctrl: TableController<T>) {}
  run() {
    this.ctrl.reloadData();
  }
  actionable() {
    return true;
  }
}
