import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WebStorageModule } from '@soracom/forks/ngx-store';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { ConfirmLongTermDiscountComponent } from './confirm-long-term-discount/confirm-long-term-discount.component';
import { LongTermDiscountDetailsComponent } from './long-term-discount-details/long-term-discount-details.component';
import { LongTermDiscountListComponent } from './long-term-discount-list/long-term-discount-list.component';
import { LongTermDiscountsComponent } from './long-term-discounts/long-term-discounts.component';
import { NewLongTermDiscountComponent } from './new-long-term-discount/new-long-term-discount.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // FIXME: Remove unused modules
    ReactiveFormsModule,
    TranslateModule.forChild(),
    WebStorageModule,
    SoracomCommonModule,
    SoracomUiModule
  ],
  declarations: [
    ConfirmLongTermDiscountComponent,
    LongTermDiscountDetailsComponent,
    LongTermDiscountListComponent,
    LongTermDiscountsComponent,
    NewLongTermDiscountComponent,
  ],
  exports: [
    ConfirmLongTermDiscountComponent,
    LongTermDiscountDetailsComponent,
    LongTermDiscountListComponent,
    LongTermDiscountsComponent,
    NewLongTermDiscountComponent,
  ],
})
export class SoracomLongTermDiscountsModule {}
