import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { getOperatorData } from '@soracom/shared/data-access-auth';

@Injectable({
  providedIn: 'root',
})
export class CountryNotJapanGuard {
  private router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (getOperatorData().getHomeCountry() !== 'JP') {
      const urlTree = this.router.parseUrl('/');
      urlTree.queryParams = { return_to: state.url };
      return urlTree;
    }
    return true;
  }
}
