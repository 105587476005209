export const template = `<app-user-notifications></app-user-notifications>
<div id="page-wrapper" translate-namespace="device_object_models">
  <div class="alerts-wrapper"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  <div class="ds-rows">
    <div class="ds-cols --spaced">
      <div><button class="btn btn-primary" id="device-object-models-action-add-device-object-models"
          ng-disabled="!$ctrl.actionable(&quot;AddDeviceObjectModel&quot;)"
          ng-click="$ctrl.doAction(&quot;AddDeviceObjectModel&quot;)"><i class="glyphicon glyphicon-plus"></i><span
            class="button-text" translate="device_object_models.actions.add_device_object_model"></span></button><button
          class="btn btn-default" id="device-object-models-action-show-device-object-models"
          ng-disabled="!$ctrl.actionable(&quot;ShowDeviceObjectModel&quot;)"
          ng-click="$ctrl.doAction(&quot;ShowDeviceObjectModel&quot;)"><span class="button-text"
            translate="device_object_models.actions.show_device_object_model"></span></button>
        <div class="btn-group" uib-dropdown="uib-dropdown"><button class="btn btn-default dropdown-toggle"
            id="device-object-models-actions-menu" type="button" uib-dropdown-toggle="uib-dropdown-toggle"><span
              class="button-text" translate="device_object_models.actions_menu.title"></span><span
              class="caret"></span></button>
          <ul class="dropdown-menu" id="device-object-models-actions-dropdown-menu" role="menu"
            uib-dropdown-menu="uib-dropdown-menu">
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Delete')"
              id="device-object-models-actions-menu-delete"
              ng-class="{ disabled: !$ctrl.actionable(&quot;Delete&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Delete&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Delete&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;Delete&quot;)">{{
                &quot;device_object_models.actions.delete&quot; | translate }}</a></li>
          </ul>
        </div>
      </div><sc-pagination paginator="$ctrl.paginator" ctrl="$ctrl"></sc-pagination>
    </div>
  </div>
  <div class="row block">
    <div class="col-xs-12">
      <ul class="dropdown-menu" id="device-object-models-context-menu" role="menu" uib-dropdown-menu="uib-dropdown-menu"
        ng-if="$ctrl.shouldShowContextMenu()">
        <li class="dropdown-header" translate="device_object_models.context_menu.header"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ShowDeviceObjectModel')"
          id="device-object-models-context-menu-show-device-object-model"
          ng-class="{ disabled: !$ctrl.actionable(&quot;ShowDeviceObjectModel&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;ShowDeviceObjectModel&quot;)">{{
            &quot;device_object_models.actions.show_device_object_model&quot; | translate }}</a></li>
        <li class="divider"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Delete')"
          id="device-object-models-context-menu-delete" ng-class="{ disabled: !$ctrl.actionable(&quot;Delete&quot;) }">
          <a role="menuitem" href="" ng-click="$ctrl.doAction(&quot;Delete&quot;)">{{
            &quot;device_object_models.actions.delete&quot; | translate }}</a></li>
      </ul>
      <div class="table-responsive table-device-object-models">
        <table class="sc-table table ds-datatable--striped x-device-object-models-table"
          id="device-object-models-table">
          <thead>
            <tr>
              <th class="th-checkbox ds-datatable__col--min" id="device-object-models-th-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check"
                    id="device-object-models-checkbox-for-all" type="checkbox" ng-model="$ctrl.checkboxForAll"
                    ng-change="$ctrl.checkboxForAllChanged($ctrl.checkboxForAll);" />
                  <div class="ds-checkbox__label">Check All</div>
                </div>
              </th>
              <th id="device-object-models-th-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)">{{
                &quot;device_object_models.columns.id&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'device_object_models.legends.id.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="device-object-models-th-object-id" ng-if="$ctrl.shouldShowColumn(&quot;object_id&quot;)">{{
                &quot;device_object_models.columns.object_id&quot; | translate }}&nbsp;</th>
              <th id="device-object-models-th-object-name" ng-if="$ctrl.shouldShowColumn(&quot;object_name&quot;)">{{
                &quot;device_object_models.columns.object_name&quot; | translate }}&nbsp;</th>
              <th id="device-object-models-th-scope" ng-if="$ctrl.shouldShowColumn(&quot;scope&quot;)">{{
                &quot;device_object_models.columns.scope&quot; | translate }}&nbsp;</th>
            </tr>
          </thead>
          <tbody class="x-device-object-models-list" ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()">
            <tr ng-repeat="row in $ctrl.tableData.rows" ng-click="$ctrl.setSelected(row)"
              sc-context-menu="device-object-models-context-menu">
              <td class="td-checkbox device-object-models-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check checkbox-for-each"
                    id="cb{{row.obj.id}}" type="checkbox" ng-model="row.selected"
                    ng-change="$ctrl.checkboxForEachChanged()" ng-click="$event.stopPropagation()" />
                  <div class="ds-checkbox__label">Check {{ row.obj.id }}</div>
                </div>
              </td>
              <td class="device-object-models-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)" ng-bind="row.obj.id">
              </td>
              <td class="device-object-models-object-id" ng-if="$ctrl.shouldShowColumn(&quot;object_id&quot;)"
                ng-bind="row.obj.objectId"></td>
              <td class="device-object-models-object-name" ng-if="$ctrl.shouldShowColumn(&quot;object_name&quot;)"
                ng-bind="row.obj.objectName"></td>
              <td class="device-object-models-scope" ng-if="$ctrl.shouldShowColumn(&quot;scope&quot;)"
                ng-bind="row.obj.scope"></td>
            </tr>
          </tbody>
          <tbody class="x-no-device-object-models-exist" ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()">
            <tr class="no-data">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.no_data' |
                translate }}</td>
            </tr>
          </tbody>
          <tbody class="x-is-loading" ng-if="$ctrl.isLoading()">
            <tr class="loading">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.loading' |
                translate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div><sc-tutorial-component></sc-tutorial-component>`;
