import { ComponentType } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UiAppSidebarService {
  private curSideBar: UiAppSidebarData<any> | undefined = undefined;

  constructor(
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  openComponentInSideBar<T>(
    component: ComponentType<T>,
    injector: Injector,
    sideBarClasses: string[] = []
  ) {
    if (this.curSideBar) {
      this.removeSidebar();
    }
    this.appendSideBarContainer(sideBarClasses);
    this.generateComponent(component, injector);
  }

  private appendSideBarContainer(sideBarClasses: string[]) {
    const dsAppContainer = document.querySelector('.ds-app');
    if (dsAppContainer) {
      const uiAppSidebarContainer = document.createElement('div');
      uiAppSidebarContainer.classList.add('ds-app__sidebar');
      uiAppSidebarContainer.classList.add('ds-dialog');
      sideBarClasses.forEach((className) => {
        uiAppSidebarContainer.classList.add(className);
      });
      dsAppContainer.appendChild(uiAppSidebarContainer);
    }
  }

  removeSidebar() {
    this.curSideBar?.componentRef.destroy();
    const existingSidebar = document.querySelector('.ds-app__sidebar');
    if (existingSidebar) {
      existingSidebar.remove();
    }
    this.curSideBar = undefined;
  }

  private generateComponent<T>(
    component: ComponentType<T>,
    injector: Injector,
    attachToDOM = true
  ) {
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    this.curSideBar = {
      componentRef: factory.create(injector),
    };
    if (attachToDOM) {
      this.attachComponentToDOM(this.curSideBar.componentRef);
    }
  }

  private attachComponentToDOM<T>(componentRef: ComponentRef<T>) {
    this.appRef.attachView(componentRef.hostView);
    // Grab actual HTML element of component
    const componentElement = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    const componentContainerEl = document.querySelector('.ds-app__sidebar');
    if (componentContainerEl) {
      componentContainerEl.appendChild(componentElement);
    }
  }
}

export interface UiAppSidebarData<T> {
  componentRef: ComponentRef<T>;
}
