import { LegacyAny } from '@soracom/shared/core';

import { AlertsServiceInstance } from '../../components/alerts.service';
import { BaseModalController } from '../../components/base_modal_controller';
import { InjectList } from '../../core/injectable';
import { template } from './change_device_status_modal.component.html';

/**
 * Generic component to change group of model which impelments `Groupable`.
 */
export class ChangeDeviceStatusModalComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = ChangeDeviceStatusModalController;
  template: any = template;
}

class ChangeDeviceStatusModalController extends BaseModalController {
  static $inject: InjectList = ['$log', 'AlertsService', 'SoracomApi', '$uibModal'];

  /** After init, this is the **instance** of AlertsService that can be used to display errors. */
  // @ts-expect-error (legacy code incremental fix)
  alertsService: AlertsServiceInstance;

  /** flag that tracks whether submission is in progress (used e.g. to disable UI appropriately) */
  protected isSubmitting = false;

  constructor(private $log: ng.ILogService) {
    super($log);
    this.setTraceEnabled(true);
  }

  describeSelection() {
    if (this.resolve.targets) {
      return this.resolve.targets.map((element: LegacyAny) => element.deviceId).join('<br>');
    } else {
      return '';
    }
  }

  cancel() {
    this.dismiss();
  }

  submit() {
    this.close();
  }
}
