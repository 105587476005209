import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { CoverageTypeService, getCoverageType, getOperatorData } from '@soracom/shared/data-access-auth';
import { LagoonSubscription } from '../../../../app/shared/lagoon/lagoon_plans';
import { environment } from '../../../environments/environment';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { RootStoreState } from '../../root-store';
import { loadLagoonSubscriptionAndAvailableVersion } from '../../root-store/lagoon-store/actions';
import { selectAvailableVersions, selectSubscription } from '../../root-store/lagoon-store/selectors';
import { skipInitial } from '../../root-store/rxjs-custom-operators';
import { UCStorage } from '../../shared/UCStorage';
import { RegisterLagoonModalComponent } from '../register-lagoon-modal/register-lagoon-modal.component';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-lagoon',
  templateUrl: './lagoon.component.html',
  styleUrls: ['./lagoon.component.scss'],
})
export class LagoonComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  alertsManager = new AlertsManager();
  subscription$ = this.store$.pipe(select(selectSubscription), skipInitial());
  availableVersions$ = this.store$.pipe(select(selectAvailableVersions), skipInitial());

  enabledBothVersions = false;
  enabledLagoonV2 = false;
  enabledLagoonV3 = false;
  enabledLagoonV2Only = false;
  enabledLagoonV3Only = false;
  // @ts-expect-error (legacy code incremental fix)
  subscription: LagoonSubscription;
  hasSubscription = false;
  lagoonMigrationNoticeDismissed = false;

  constructor(
    private coverageTypeService: CoverageTypeService,
    private store$: Store<RootStoreState.State>,
    private uiDsModalService: UiDsModalService,
  ) {}

  ngOnInit(): void {
    this.availableVersions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((versions) => {
      // Remove classic from contract to detect conditions
      versions = versions.filter((v) => v !== 'classic');
      this.enabledLagoonV2 = versions.includes('v2');
      this.enabledLagoonV3 = versions.includes('v3');
      this.enabledLagoonV2Only = isEqual(versions, ['v2']);
      this.enabledLagoonV3Only = isEqual(versions, ['v3']);
      this.enabledBothVersions = isEqual(versions, ['v2', 'v3']);
    });
    this.subscription$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subscription) => {
      this.hasSubscription = subscription !== LagoonSubscription.none;
    });
    this.lagoonMigrationNoticeDismissed = UCStorage.lagoonMigrationNoticeDismissed;
  }

  dismissMigrationNotice() {
    this.lagoonMigrationNoticeDismissed = true;
    UCStorage.lagoonMigrationNoticeDismissed = true;
  }

  lagoonUrl() {
    // @ts-expect-error (legacy code incremental fix)
    const coverageType: string = this.coverageTypeService.getCoverageType();
    if (this.enabledLagoonV3) {
      // @ts-expect-error (legacy code incremental fix)
      return environment.lagoonV3ConsoleUrl[coverageType];
    } else {
      // @ts-expect-error (legacy code incremental fix)
      return environment.lagoonV2ConsoleUrl[coverageType];
    }
  }

  lagoon2Url() {
    // @ts-expect-error (legacy code incremental fix)
    const coverageType: string = this.coverageTypeService.getCoverageType();
    // @ts-expect-error (legacy code incremental fix)
    return environment.lagoonV2ConsoleUrl[coverageType];
  }

  lagoon3Url() {
    // @ts-expect-error (legacy code incremental fix)
    const coverageType: string = this.coverageTypeService.getCoverageType();
    // @ts-expect-error (legacy code incremental fix)
    return environment.lagoonV3ConsoleUrl[coverageType];
  }

  openRegisterLagoonModal() {
    this.uiDsModalService
      .openAndWaitForResult(RegisterLagoonModalComponent, {
        title: 'RegisterLagoonModalComponent.title',
      })
      .then((res: LegacyAny) => {
        if (res?.success) {
          this.store$.dispatch(loadLagoonSubscriptionAndAvailableVersion());
          this.alertsManager.add(Alert.success('LagoonComponent.activate_success_message'));
        }
      });
  }

  canTryLagoon() {
    const coverageType = getCoverageType();
    const status = getOperatorData().getPaymentStatus(coverageType);
    if (status === undefined || status === null || status === 'registered') {
      return true;
    }
    return false;
  }
}
