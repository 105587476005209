import { template } from './alerts.directive.html';

export class AlertsDirective implements ng.IDirective {
  template: any = template;
  restrict = 'E';
  scope: any = {
    service: '=',
  };

  static Factory(): ng.IDirectiveFactory {
    return () => new AlertsDirective();
  }
}
