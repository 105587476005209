import { InjectList } from '../core/injectable';
import { SubscribersComponentController } from './subscribers.component';
import { template } from './subscribers.component.html';

export class SimsComponent implements ng.IComponentOptions {
  bindings = {
    apiMode: '@',
  };
  controller = SimsComponentController;
  template: any = template;
}

/**
 * This is a minimal subclass of SubscribersComponentController. It exists because we need to do some configuration when the constructor executes, to initialize our underlying basic components (paginatable table, paginator, etc) with the right service.
 *
 * But we should avoid implementing too much here, since SubscribersComponentController is due to be converted to Angular as soon as we can do that... it is better to keep as much functionality as possible in the superclass rather than this subclass.
 */
export class SimsComponentController extends SubscribersComponentController {
  static $inject: InjectList = SubscribersComponentController.$inject;

  resourcePath = 'sims';

  /**
   * The only purpose of this constructor is to invoke the superclass constructor with `undefined` for `subscribersService` and a valid `simsService`, which will initialize the component in 'sim' mode instead of 'subscriber' mode.
   */
  constructor($location: ng.ILocationService, $scope: ng.IScope) {
    super($location, $scope);
    // [1]: I could not think of a better way to ensure that the superclass picks simsService instead of subscribersService... since we can't do any logic in constructors in TypeScript that have "initialized properties, parameter properties, or private identifiers" ts(2376)
  }

  // Mason 20202-05-30: I think we should avoid adding code here and keep it all in the superclass, using conditional logic to do different things in 'sim' mode vs. 'subscriber' mode where necessary.
}
