import { LegacyAny } from '@soracom/shared/core';

import { InjectList } from '../core/injectable';
import { BaseObject } from './base_object';

export class ContextMenuService extends BaseObject {
  static $inject: InjectList = ['$log', '$window'];

  /** A global reference to the active sc-context-menu menu element (so that it can be hidden when needed).  */
  // @ts-expect-error (legacy code incremental fix)
  activeContextMenu: ng.IAugmentedJQuery;

  /** A global reference to the element that "owns" the active context menu. Used to help workaround Firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=184051 */
  // @ts-expect-error (legacy code incremental fix)
  activeContextMenuOwner: ng.IAugmentedJQuery | JQuery<Document>;

  /** Cached timestamp helps us work around Firefox bug. */
  // @ts-expect-error (legacy code incremental fix)
  timestampOfLastContextMenuEvent: number;

  constructor(private $log: ng.ILogService, private $window: ng.IWindowService) {
    // This is an Angular service, so only one instance is instantiated and it is shared.
    super($log);
    this.trace('ContextMenuService initialized.');

    this.setUpDocumentClickEventHandler();
  }

  setUpDocumentClickEventHandler() {
    $(document).click((event) => {
      this.trace(`clickEvent: ${event.timeStamp}`);
      const target = $(event.target);

      // NOTE: We have to work around a bug in Firefox where right-click triggers the 'contextmenu' event on the clicked element (correct behavior) AND triggers a 'click' event on the document (incorrect behavior). There is some machine-dependent time drift between the contextmenu event and the click event, too (in my testing on 2018 iMac Pro). So we have to take a fuzzy approach. More info: https://stackoverflow.com/questions/16898330/firefox-strange-right-click-event-bubbling-behavior

      const sourceIsMenuOwner = target && this.activeContextMenuOwner && target[0] === this.activeContextMenuOwner[0];
      const isWithinOneSecond = event.timeStamp - this.timestampOfLastContextMenuEvent < 999;
      const isRightClick = event.which === 3; // 1 left, 2 middle, 3 right

      if (sourceIsMenuOwner && isWithinOneSecond && isRightClick) {
        // then it would seem to be the spurious click even triggered by the Firefox bug
        this.debug('Ignoring spurious click event caused by Firefox bug 184051');
        return;
      }

      // Separately, I noticed that firefox behaves differently than other browsers when some other element is right-clicked, because it (again) generates a click event on the document. This is not the same as the above case, since the spurious event is not generated by the same click that caused our context menu to be shown. However, we want the browser's context menu to be able to appear without disturbing our own context menu element. (This is already the behavior on Chrome/Safari, where the click event on the document is NOT triggered by a right click.)
      if (isRightClick) {
        this.debug("Ignoring click event caused by right click (most browsers don't emit this event)");
        return;
      }

      // if (!target.is('.popover') && !target.parents().is('.popover')) {
      //   // FIXME: what does this do? is it really necessary even? (mason 2018-04-06)
      //   if (event.timeStamp === ts) {
      //     return;
      //   }
      //   this.hideContextMenu(this.activeContextMenu);
      // }
      // MASON 2018-04-09: I THINK THE ABOVE OLD CODE IS NO LONGER NECESSARY?

      this.hideContextMenu(this.activeContextMenu);
    });
  }

  calcContextMenuPosition(event: LegacyAny, contextMenu: LegacyAny) {
    const bottomMargin = 30,
      rightMargin = 30,
      windowHeight = this.$window.innerHeight,
      windowWidth = this.$window.innerWidth,
      contextMenuHeight = contextMenu.innerHeight(),
      contextMenuWidth = contextMenu.innerWidth(),
      position = {
        x: event.clientX,
        y: event.clientY,
      };

    if (event.clientX + contextMenuWidth + rightMargin > windowWidth) {
      position.x = windowWidth - contextMenuWidth - rightMargin;
    }

    if (event.clientY + contextMenuHeight + bottomMargin > windowHeight) {
      position.y = windowHeight - contextMenuHeight - bottomMargin;
    }

    return position;
  }

  showContextMenu(cm: ng.IAugmentedJQuery, x: number, y: number, owner: ng.IAugmentedJQuery) {
    this.debug(`showContextMenu(${cm.attr('id')}, ${x}, ${y} — owner:`, owner);

    const previousContextMenu = this.activeContextMenu;
    if (previousContextMenu) {
      this.hideContextMenu(previousContextMenu);
    }
    cm.css({
      position: 'fixed',
      display: 'block',
      left: x + 'px',
      top: y + 'px',
    });
    this.activeContextMenu = cm;
    this.activeContextMenuOwner = owner;
  }

  hideContextMenu(cm: ng.IAugmentedJQuery) {
    if (!cm) {
      return;
    }
    cm.hide();
    // @ts-expect-error (legacy code incremental fix)
    this.activeContextMenu = null;
    // @ts-expect-error (legacy code incremental fix)
    this.activeContextMenuOwner = null;
  }
}
