<header class="ds-banner --simple --indent-small">
  <div>
    <h2>{{ 'SORACOM Harvest Data' }}</h2>
  </div>
</header>

<section class="ds-app__notice">
  <div *ngIf="contract" class="ds-notice --pinned">
    <span
      [translate]="'data.contract.notice.description.' + contract.plan"
      [translateParams]="{ retentionPeriod: contract.ttl }"
    ></span>
    &nbsp;
    <a href="" (click)="onContractNoticeLinkClick($event)">
      {{ 'data.contract.notice.action-link.' + contract.plan | translate }}
    </a>
  </div>
</section>

<!-- TODO: alerts should go overlay and stay sticked to top regardless of scroll-->
<app-alerts />

<section class="ds-controls">
  <div class="ds-button-bar">
    <app-multi-resource-selector
      *ngIf="svc.resources$ | async as resources"
      [availableTypes]="$any(resourceTypes)"
      [resources]="resources"
      (resourcesChange)="onResourcesChange($event)"
    >
    </app-multi-resource-selector>

    <ui-datetime-range-selector
      *ngIf="svc.timeRange$ | async as timeRange"
      [value]="timeRange"
      [relativeTimeRangeOptions]="timeRangeOptions"
      classOption="left"
      keyForHistory="harvest-data"
      (timeRangeChange)="svc.setTimeRange($event)"
    ></ui-datetime-range-selector>
  </div>

  <!-- TODO: use ui-pagination -->
  <div *ngIf="svc.paginationState$ | async as page">
    <label class="ds-switch" *ngIf="svc.autoRefresh$ | async as ar">
      <input type="checkbox" [checked]="ar.enabled" (change)="svc.setAutoRefresh(!ar.enabled)" [disabled]="!(svc.autoRefreshSwitchEnabled$ | async)" />
      <span
        *ngIf="svc.autoRefreshDisplayCountDown$ | async as num"
        translate="data.auto_refresh"
        [translateParams]="{ interval: num }"
      ></span>
    </label>

    <div class="ds-button-bar">
      <span>
        {{ 'sc_pagination.items_per_page' | translate }}
      </span>

      <select class="ds-select" (change)="svc.setItemsPerPage($any($event.target).value)">
        <option *ngFor="let n of itemsPerPageOptions" [value]="n" [selected]="page.itemsPerPage === n">
          {{ n }}
        </option>
      </select>

      <span>
        {{
          'UiPaginationComponent.items_count_and_position'
            | translate
              : {
                  head: page.firstItemDisplayIndex,
                  tail: page.lastItemDisplayIndex
                }
        }}
      </span>
    </div>

    <div class="ds-button-bar">
      <button type="button" class="ds-button --plain --hide-label --icon-refresh-cw" (click)="svc.search()">
        <span>Reload</span>
      </button>

      <button
        type="button"
        class="ds-button --plain --hide-label --icon-arrow-left"
        (click)="svc.prev()"
        [disabled]="!page.hasPrev"
      >
        <span translate="UiPaginationComponent.prev"></span>
      </button>

      <button
        type="button"
        class="ds-button --plain --hide-label --icon-arrow-right"
        (click)="svc.next()"
        [disabled]="!page.hasNext"
      >
        <span translate="UiPaginationComponent.next"></span>
      </button>
    </div>
  </div>
  <!--end of control row-->
</section>

<section class="ds-controls" *ngIf="svc.vizType$ | async as vizType">
  <div>
    <app-harvest-data-viz-type-selector [value]="vizType" (valueChange)="svc.setVizType($event)">
    </app-harvest-data-viz-type-selector>
  </div>

  <div>
    <button *appFeature="'harvestDataIntelligence'" appHarvestDataIntelligenceTrigger></button>
    <app-harvest-data-download-button *ngIf="svc.displayItems$ | async as data" [data]="data">
    </app-harvest-data-download-button>

    <ng-container *ngIf="svc.displayItemsInfo$ | async as info">
      <div class="ds-text" *ngIf="info.num > 0">
        <span>{{ info.num }}&nbsp;</span>
        <span>{{ 'data.record_count' | translate }} </span>
        <span>(</span>
        <span>{{ info.displayStartDate | datetime: 'datetime_sec' }}</span>
        <span>&nbsp;-&nbsp;</span>
        <span>{{ info.displayEndDate | datetime: 'datetime_sec' }}</span>
        <span>)</span>
      </div>
    </ng-container>
  </div>
</section>

<section class="ds-app__main-content ds-rows --indent-small" style="min-height: 600px">
  <ng-container *ngIf="svc.vizType$ | async as vizType">
    <ng-container *ngIf="svc.displayItems$ | async as data">
      <ng-container [ngSwitch]="vizType">
        <app-harvest-data-table *ngSwitchCase="'table'" [data]="data"> </app-harvest-data-table>

        <app-harvest-data-map-container *ngSwitchCase="'map'" [data]="data"> </app-harvest-data-map-container>

        <app-harvest-data-viz-container *ngSwitchDefault [data]="data"> </app-harvest-data-viz-container>
      </ng-container>
    </ng-container>
  </ng-container>
</section>
