import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { ApiErrorCode } from '@user-console/legacy-soracom-api-client';
import { Order } from '../../../../app/shared/core/order';
import { OrdersService } from '../../../../app/shared/orders/orders.service';

@Component({
  selector: 'app-order-notification',
  templateUrl: './order-notification.component.html',
  styleUrls: ['./order-notification.component.scss'],
})
export class OrderNotificationComponent implements OnInit {
  ordersToConfirm: Order[] = [];
  logger: Logger = LoggerService.shared();

  constructor(private ordersService: OrdersService) {}

  ngOnInit() {
    this.ordersService
      .getOrdersWaitingRegistration()
      .then((orders) => {
        this.ordersToConfirm = orders;
      })
      .catch((e: LegacyAny) => {
        if (e.code && (e.code === ApiErrorCode.COM0017 || e.code === ApiErrorCode.SEM0049)) {
          // Do nothing
        } else {
          this.logger.info(e);
        }
      });
  }

  // Order notification
  hasOrdersToConfirm(): boolean {
    return this.ordersToConfirm.length > 0;
  }
}
