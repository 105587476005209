<!-- This template is a direct port of the old .jade one from the AngularJS app... probably does not represent the right way to do this in Angular (e.g. we should use Material, or....) -->

<div class="ds-rows x-long-term-discount-list">
  <div class="ds-notice --loading --15-h" *ngIf="state === 'loading'" [attr.data-ds-message]="'long_term_discount.title_loading' | translate">
    <h3 translate="long_term_discount.title_loading"></h3>
  </div>

  <ng-container class="row" *ngIf="state === 'no data'">
    <h3 translate="long_term_discount.title_no_data"></h3>
    <p translate="long_term_discount.caption_no_data"></p>
  </ng-container>

  <ng-container class="has-data" *ngIf="state === 'has data'">
    <h3 translate="long_term_discount.title_has_data"></h3>
    <p
      translate="long_term_discount.caption_has_data"
      [translateParams]="{
        count: discounts.length,
        isOrAre: discounts.length == 1 ? 'is' : 'are',
        sIfPlural: discounts.length == 1 ? '' : 's'
      }"
    ></p>

    <ng-container *ngFor="let discount of discounts; let index = index">
      <app-long-term-discount-details class="ds-group --indent-xsmall" [discount]="discount" [listIndex]="index" [currency]="discount.currency"></app-long-term-discount-details>
    </ng-container>

  </ng-container>
</div>
