import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from '@soracom/shared-ng/logger-service';
import { Logger } from '@soracom/shared/logger';
import { Observable } from 'rxjs';

import { BaseTableData } from '../../../../app/shared/components/BaseTableData';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { GpsMultiunitStoreSelectors, RootStoreState } from '../../root-store';
import * as Actions from '../../root-store/gps-multiunit-store/actions';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

enum GroupSelectMode {
  create = 'create',
  select = 'select',
}

@Component({
  selector: 'app-gps-multiunit-new-group-select',
  templateUrl: './gps-multiunit-new-group-select.component.html',
})
export class GpsMultiunitNewGroupSelectComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  // @ts-expect-error (legacy code incremental fix)
  subscribers$: Observable<ExtendedSubscriberInterface[]>;

  // @ts-expect-error (legacy code incremental fix)
  tableData: BaseTableData<ExtendedSubscriberInterface>;
  // @ts-expect-error (legacy code incremental fix)
  columns: Array<UiTableColumn<ExtendedSubscriberInterface>>;
  // @ts-expect-error (legacy code incremental fix)
  groupId: string;
  // @ts-expect-error (legacy code incremental fix)
  mode: GroupSelectMode;
  // @ts-expect-error (legacy code incremental fix)
  newGroupName: string;
  groups: Group[] = [];

  clickNextButton = () => {
    this.logger.debug('clickNextButton called');
    if (this.mode === GroupSelectMode.create) {
      this.store$.dispatch(Actions.newConfiguration({ newGroupName: this.newGroupName }));
    } else if (this.mode === GroupSelectMode.select) {
      this.store$.dispatch(Actions.editGpsMultiunitConfiguration({ groupId: this.groupId }));
    } else {
      this.logger.error(`Invalid mode: ${this.mode}`);
    }
  };

  back = () => {
    this.logger.debug('backToSubscribersSelect called');
    this.store$.dispatch(Actions.navigateToSelectSubscribersPage());
  };

  buttonBar = UiButtonBar.configure((bar) => {
    bar.leftButtons = [
      UiButton.configure((b) => {
        b.buttonStyle = 'primary';
        b.titleId = 'GpsMultiunitNewGroupSelectComponent.actions.next.title';
        b.classes = ['x-new-config'];
        b.isDisabled_ƒ = () => {
          if (this.mode === GroupSelectMode.create) {
            return !this.newGroupName;
          } else {
            return !this.groupId;
          }
        };
        b.onClick = this.clickNextButton;
      }),
      UiButton.configure((b) => {
        b.titleId = 'GpsMultiunitNewGroupSelectComponent.actions.back.title';
        b.classes = ['x-back'];
        b.onClick = this.back;
      }),
    ];
  });

  private logger: Logger;

  constructor(private store$: Store<RootStoreState.State>) {
    this.logger = LoggerService.shared();
  }

  ngOnInit() {
    groupsService.getCachedAll().then((groupMap) => {
      this.groups = groupsService.sortGroupsByName(groupMap);
    });
    this.subscribers$ = this.store$.select(GpsMultiunitStoreSelectors.selectGpsMultiunitSelectedSubscribers);
    this.subscribers$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subscribers) => {
      const initialGroupId = this.getDefaultGroupId(subscribers);
      this.groupId = initialGroupId;
      this.mode = initialGroupId ? GroupSelectMode.select : GroupSelectMode.create;
    });
  }

  nameExists() {
    return this.newGroupName && this.newGroupName.length > 0 && groupsService.nameExists(this.newGroupName);
  }

  onGroupChooserChange(event: { newGroup: Group }) {
    this.groupId = event.newGroup.groupId;
  }

  private getDefaultGroupId(subscribers: ExtendedSubscriberInterface[]): string {
    // When all subscribers has only 1 group, choose it as default.
    const groupIds = subscribers
      .map((s) => s.groupId)
      .reduce<string[]>((_prev, curr, _idx, arr) => {
        if (curr && !arr.includes(curr)) {
          arr.push(curr);
        }
        return arr;
      }, []);
    if (groupIds.length === 1) {
      return groupIds[0];
    } else {
      // @ts-expect-error (legacy code incremental fix)
      return undefined;
    }
  }
}
