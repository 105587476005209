import { LegacyAny } from '@soracom/shared/core';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { LocalStorageService } from '@soracom/forks/ngx-store';

type Item<T> = { key: string; value: T };

export const KeyValueLocalStorageServiceConfigToken = new InjectionToken('');
export type KeyValueLocalStorageServiceConfig = {
  storageKey: string;
  size?: number;
};
/**
 * LocalStorage wrapper service for storing key-value object with _fixed size_.
 * Internally, key-value entries are stored in an array and old entries will be
 * deleted if number of items exceeds the specified limit
 *
 * @usage
 * ```
 * new LocalStorageService(localStorageService, {storageKey: 'hoge', size:10 })
 * ```
 *
 * or
 *
 * ```
 * @Component {
 *   ...
    providers: [
    {provide: KeyValueLocalStorageServiceConfigToken, useValue: {storageKey: 'hello'}},
    KeyValueLocalStorageService,
  ]
 * }
  export class ..
 * ```
 */
@Injectable()
export class KeyValueLocalStorageService<T> {
  constructor(
    private localStorage: LocalStorageService,
    @Inject(KeyValueLocalStorageService) private config: KeyValueLocalStorageServiceConfig
  ) {
    if (!(localStorage && config.storageKey)) {
      throw Error('insufficient arguments');
    }
    if (!config.size) {
      config.size = 100;
    }
  }

  private getData(): Item<T>[] {
    return (this.localStorage.get(this.config.storageKey) as Item<T>[]) || [];
  }

  save(key: LegacyAny, value: LegacyAny) {
    const data = this.getData();
    const idx = data.findIndex((item) => item.key === key);
    if (idx !== -1) {
      data.splice(idx, 1);
      // @ts-expect-error (legacy code incremental fix)
    } else if (data.length >= this.config.size) {
      data.splice(0, 1);
    }
    data.push({ key, value });
    this.localStorage.set(this.config.storageKey, data);
  }

  get(key: LegacyAny): T | null {
    return this.getData().find((item) => item.key === key)?.value || null;
  }
}
