import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { assertNever } from '@soracom/shared/core';
import { Translation } from '@soracom/shared-ng/soracom-ui-legacy';
import { LogViewerControllerMode } from '../log-viewer/log-viewer.component';
import { LogViewerSubscriptionModalService, NapterSubscriptionModals } from '../LogViewerSubscriptionModalService';

// TODO(someday/maybe): auto-generate translations.d.ts
type TranslationId = Translation['id'];

@Component({
  selector: 'app-log-viewer-subscription-modal',
  templateUrl: './log-viewer-subscription-modal.component.html',
  styles: ['.text.--alert { color: rgb(var(--color-alert)); font-weight: bold; }'],
  // To apply style to innnerHTML text. https://stackoverflow.com/questions/44210786/style-not-working-for-innerhtml-in-angular
  encapsulation: ViewEncapsulation.None,
})
export class LogViewerSubscriptionModalComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() mode: LogViewerControllerMode;

  @Output() close = new EventEmitter<boolean>();

  // @ts-expect-error (legacy code incremental fix)
  modalMode: NapterSubscriptionModals;
  // @ts-expect-error (legacy code incremental fix)
  dialogTitle: TranslationId;
  // @ts-expect-error (legacy code incremental fix)
  dialogBody: TranslationId;
  // @ts-expect-error (legacy code incremental fix)
  dialogConfirmButtonTitle: TranslationId;

  constructor(private service: LogViewerSubscriptionModalService) {}

  ngOnInit(): void {
    const modalNow = this.service.currentModal.getValue();

    if (modalNow === false) {
      throw Error('Modal component ngOnInit called when currentModal is false?');
    }
    this.modalMode = modalNow;

    if (modalNow === 'subscribeAuditLogsEnterprise') {
      this.dialogTitle = 'SoracomLogs.audit_logs_enterprise_modal.subscribe.title';
      this.dialogBody = 'SoracomLogs.audit_logs_enterprise_modal.subscribe.body';
      this.dialogConfirmButtonTitle = 'SoracomLogs.audit_logs_enterprise_sub_status.unsubscribed.transition'; // LOL wtf
    } else if (modalNow === 'unsubscribeAuditLogsEnterprise') {
      this.dialogTitle = 'SoracomLogs.audit_logs_enterprise_modal.unsubscribe.title';
      this.dialogBody = 'SoracomLogs.audit_logs_enterprise_modal.unsubscribe.body';
      this.dialogConfirmButtonTitle = 'SoracomLogs.audit_logs_enterprise_sub_status.subscribed.transition'; // LOL wtf
    } else if (modalNow === 'subscribeNapterAuditLogs') {
      this.dialogTitle = 'SoracomLogs.napter_modal.subscribe.title';
      this.dialogBody = 'SoracomLogs.napter_modal.subscribe.body';
      this.dialogConfirmButtonTitle = 'SoracomLogs.napter_sub_status.unsubscribed.transition'; // LOL wtf
    } else if (modalNow === 'unsubscribeNapterAuditLogs') {
      this.dialogTitle = 'SoracomLogs.napter_modal.unsubscribe.title';
      this.dialogBody = 'SoracomLogs.napter_modal.unsubscribe.body';
      this.dialogConfirmButtonTitle = 'SoracomLogs.napter_sub_status.subscribed.transition'; // LOL wtf
    } else {
      throw new Error('invalid modal ID');
    }

    // if (modalNow === false) {
    //   throw Error('Modal component ngOnInit called when currentModal is false?');
    // }

    // const stringIdsPrefix = 'SoracomLogs';
    // this.modalMode = modalNow;
    // const transition = this.modalMode === 'subscribeNapterAuditLogs'
    //   ? 'subscribe'
    //   : 'unsubscribe';

    // Mason I am just getting it to work the existing way before deleting it all:
    // Mason 2021-06-08 NOPE I didn't even do that

    // const topGroupForCurrentMode = this.mode === 'auditLogs' || this.mode === 'testAuditLogs'
    //   ? 'audit_logs_enterprise_modal'
    //   : 'napter_modal';

    // const _tid = (
    //   leaf: string | false,
    //   subGroup: string | false = transition,
    //   topGroup: string | false = topGroupForCurrentMode
    // ): TranslationId => {
    //   return [stringIdsPrefix, topGroup, subGroup, leaf].filter((i) => !!i).join('.');
    // };

    // this.dialogTitle = _tid('title');
    // this.dialogBody = _tid('body');
    // this.dialogConfirmButtonTitle = _tid(
    //   /* leaf= */ 'transition',
    //   /* this looks backwards, but: napter_sub_status.unsubscribed.transition: Subscribe */
    //   /* subGroup= */ transition === 'subscribe' ? 'unsubscribed' : 'subscribed',
    //   /* topGroup= */ 'napter_sub_status'
    // );
  }

  closeWithUserConfirmation() {
    const mode = this.modalMode;
    switch (mode) {
      case 'subscribeNapterAuditLogs':
        this.service.subscribeNapterAuditLogs();
        break;
      case 'unsubscribeNapterAuditLogs':
        this.service.unsubscribeNapterAuditLogs();
        break;
      case 'subscribeAuditLogsEnterprise':
        this.service.subscribeAuditLogsEnterprise();
        break;
      case 'unsubscribeAuditLogsEnterprise':
        this.service.unsubscribeAuditLogsEnterprise();
        break;
      default:
        assertNever(mode);
    }
    // An alert will be shown on the page when the backend operation completes,
    // so it's not necessary to keep the modal open just to watch a spinner.
    // In other words, the modal can close immediately.

    // FIXME: mason

    this.close.emit();
  }

  closeWithoutUserConfirmation() {
    this.close.emit();
  }
}
