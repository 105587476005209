import { LegacyAny } from '@soracom/shared/core';

import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { LocalStorageService } from '@soracom/forks/ngx-store';
import { HarvestContract } from '../../../app/shared/core/harvest_contract';
import { HarvestDataService } from '../../../app/shared/harvest_data/harvest_data.service';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { MultiResourceSelectorComponent } from '../resource-selector/multi-resource-selector/multi-resource-selector.component';
import { ResourcesChangeEvent } from '../resource-selector/resource-selector.type';
import { PageVisibilityService } from '../shared/page-visibility.service';
import { HarvestDataContractAction, HarvestDataContractActionType } from './harvest-data-contract.action';
import {
  decodeHarvestDataRouteParams,
  encodeHarvestDataRouteParams,
  HarvestDataParam,
  HarvestDataUrlSearchParam,
} from './harvest-data-route-param';
import { isHarvestDataVizType } from './harvest-data-viz/harvest-data-viz-type';
import { HarvestDataStore } from './harvest-data.store';
import { HarvestResource, HarvestResourceType, TIME_RANGE_OPTIONS } from './harvest-data.type';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
const ITEMS_PER_PAGE_OPTIONS = [50, 100, 150, 200, 250, 500, 1000];
const LOCAL_STORAGE_VIZ_TYPE_KEY = 'chartType';

@Component({
  selector: 'app-harvest-data',
  templateUrl: './harvest-data.component.html',
  providers: [AlertsManager, HarvestDataStore, HarvestDataContractAction],
  styleUrls: ['./harvest-data.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HarvestDataComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  @Input() set routeParams(urlParams: HarvestDataUrlSearchParam) {
    const params = decodeHarvestDataRouteParams(urlParams);
    if (!params.vizType) {
      const t = this.localStorage.get(LOCAL_STORAGE_VIZ_TYPE_KEY);
      if (isHarvestDataVizType(t)) {
        params.vizType = t;
      }
    }
    if (params.itemsPerPage !== undefined && !ITEMS_PER_PAGE_OPTIONS.includes(params.itemsPerPage)) {
      delete params.itemsPerPage;
    }
    this.init(params);
  }

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild(MultiResourceSelectorComponent) resourceSelector: MultiResourceSelectorComponent;

  @Output() routeParamsChange = new EventEmitter<HarvestDataUrlSearchParam>();

  resourceTypes: HarvestResourceType[] | undefined;
  contract: HarvestContract | undefined;

  init(params: HarvestDataParam) {
    this.svc.initialize(params);
    this.contract = this.harvestDataSvc.getContract();
    this.resourceTypes = this.harvestDataSvc.getResourceTypeOptions();
    if (this.resourceSelector) {
      this.resourceSelector.clearCache();
    }
  }

  constructor(
    public svc: HarvestDataStore,
    private visibility: PageVisibilityService,
    private localStorage: LocalStorageService,
    private contractAction: HarvestDataContractAction,
    private harvestDataSvc: HarvestDataService
  ) {}

  get itemsPerPageOptions() {
    return ITEMS_PER_PAGE_OPTIONS;
  }

  get timeRangeOptions() {
    return TIME_RANGE_OPTIONS;
  }

  onResourcesChange(event: ResourcesChangeEvent) {
    if (event.type === 'add') {
      this.svc.addResource(event.targetValue as HarvestResource);
    } else {
      this.svc.removeResource(event.targetValue.resourceId);
    }
  }

  ngOnInit() {
    this.svc.routeParams$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((routeParam) => {
      const param = encodeHarvestDataRouteParams(routeParam);
      this.routeParamsChange.emit(param);
    });

    this.visibility.visibilityChange$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((visible) => {
      this.svc.setPageVisible(visible);
    });

    this.svc.vizType$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((vizType) => {
      this.localStorage.set(LOCAL_STORAGE_VIZ_TYPE_KEY, vizType);
    });
  }

  onContractNoticeLinkClick(event: LegacyAny) {
    event?.preventDefault();

    let actionType: HarvestDataContractActionType | undefined;

    // @ts-expect-error (legacy code incremental fix)
    if (this.contract.equals(HarvestContract.normal)) {
      actionType = 'subscribe';
      // @ts-expect-error (legacy code incremental fix)
    } else if (this.contract.equals(HarvestContract.extended)) {
      actionType = 'unsubscribe';
    }

    if (actionType) {
      this.contractAction.run(actionType);
    }
  }
}
