export const template = `<sc-alerts service="$ctrl.alertsServiceInstance"></sc-alerts>
<table class="ds-datatable --embedded --compact" translate-namespace="base_tag_list">
  <thead>
    <th translate=".tag_name"></th>
    <th translate=".tag_value"></th>
  </thead>
  <tbody ng-show="$ctrl.hasTag()">
    <tr ng-repeat="(key, value) in $ctrl.obj.tags | filterReservedTag:{invert:true}">
      <td>{{ key }}</td>
      <td><sc-inplace-editable-text key="{{key}}" display-value="value" edit-ctrl="$ctrl.tagEditCtrl"
          show-remove-button="$ctrl.showRemoveTagButtons" show-save-button="true" show-cancel-button="true"
          button-place="right" show-pencil-icon="true"></sc-inplace-editable-text></td>
    </tr>
  </tbody>
  <tbody ng-show="!$ctrl.hasTag()">
    <tr>
      <td class="fullwidth-message" colspan="2" translate=".no_data"></td>
    </tr>
  </tbody>
</table>
<p></p><button class="btn btn-default" id="add-tag-modal" ng-click="$ctrl.showAddTagModal()"><i
    class="glyphicon glyphicon-plus"></i></button><button class="btn btn-default"
  ng-click="$ctrl.toggleRemoveTagButton()"
  ng-class="$ctrl.showRemoveTagButtons ? &quot;active btn-danger&quot; : &quot;btn-default&quot;"><i
    class="glyphicon glyphicon-minus"></i></button>`;
