<section dsDialogContent>
  <form #addGroupForm="ngForm">
    <div class="ds-field" >
      <label class="ds-text --label">{{ 'group_add.name' | translate }}</label>
      <div class="ds-input">
        <input id="group-name" name="groupName" type="text" [(ngModel)]="name" required="true" uiFocusMe />
      </div>
      <div class="ds-text --alert" *ngIf="nameExists()">
        {{ 'group_add.name_exists' | translate }}
      </div>
      <app-alerts [alertsManager]="alertsManager" *ngIf="alertsManager.alerts.length > 0"></app-alerts>
    </div>
  </form>
</section>
<footer dsDialogFooter>
  <button class="ds-button --primary"
    id="group-add-submit" type="button" (click)="submit()" [disabled]="!canSubmit()"
    >{{ 'group_add.submit' | translate }}</button>
  <button class="ds-button" id="group-add-cancel" type="button"
    (click)="cancel()" [disabled]="submitting">{{ 'group_add.cancel' | translate }}</button>
</footer>
