import { BaseModalController } from '../../components/base_modal_controller';
import { InjectList } from '../../core/injectable';
import { template } from './execute_device_resource_modal.component.html';

/**
 * Generic component to change group of model which impelments `Groupable`.
 */
export class ExecuteDeviceResourceModalComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = ChangeDeviceStatusModalController;
  template: any = template;
}

class ChangeDeviceStatusModalController extends BaseModalController {
  static $inject: InjectList = ['$log'];

  // @ts-expect-error (legacy code incremental fix)
  parameter: string;
  // @ts-expect-error (legacy code incremental fix)
  form: ng.IFormController;

  constructor(private $log: ng.ILogService) {
    super($log);
  }

  resource() {
    this.debug(this.resolve.resource);
    return this.resolve.resource;
  }

  cancel() {
    this.dismiss();
  }

  submit() {
    this.close(this.parameter);
  }
}
