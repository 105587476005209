import { template } from './generic_button_bar.component.html';

/**
 * A generic button bar component for simple button bars.
 *
 * BINDINGS:
 *
 *   buttons:          array of button identifiers. Currently, this just means a full localization key, like 'orders.some_button'
 *
 *   onButtonClicked:  function(buttonIdentifier) -> void (take whatever action is appropriate; return value not used)
 *
 *   getButtonClasses:  function(buttonIdentifier) -> "fooClass barClass" type string, indicating the extra CSS class(es) that should be applied (in addition to the ones all buttons get, e.g. 'btn')
 *
 *   getButtonDisabled: function(buttonIdentifier) -> true if button should be disabled, falsy value otherwise
 */
export class GenericButtonBarComponent implements ng.IComponentOptions {
  bindings = {
    buttons: '<?',
    onButtonClicked: '&',
    getButtonClasses: '&',
    getButtonDisabled: '&',
  };

  template: any = template;
}

// NO CONTROLLER: This component has no controller defined. This means Angular synthesizes a default
// controller, available in the template as $ctrl.
//
// This is cool; it is one less file to manage, one less object whose code needs to be maintained.
//
// However, we could one day add a controller to make setting up buttons more convenient for client code.
// E.g., we could let the buttons passed into the component be a simple array of string identifiers, OR
// it could be an array of " string OR object like {title: 'Foo Button', css: 'btn-danger', glyph: 'fa-warning', align: 'right'}".
//
// That would make it convenient for the client code using this button bar; but its too much logic to
// sanely put in the template, so we could defined a controller for this component similar to the below:

// export class GenericButtonBarController {
//   static $inject: InjectList= ['$log'];
//
//   constructor(
//     $log: ng.ILogService,
//   ) {
//     super($log);
//   }
//
//   $onInit() {
//     this.debug('GenericButtonBarController: $onInit() executing...');
//     // parse the buttons value here, and keep a local
//   };
//
//   // Then, create controller methods here to use in the template itself, like:
//
//   getButtonClasses(buttonIdentifier) {
//     // some kind of logic here...
//   };
//
//   isButtonDisabled(buttonIdentifier) {
//     return this.getButtonDisabled && this.getButtonDisabled({buttonIdentifier: buttonIdentifier});
//   };
// }
