import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { Action } from '../../core/actions/Action';
import { HarvestFilesPageController } from '../harvest_files_page.component';

export class UploadHarvestFileAction implements Action<any> {
  constructor(private ctrl: HarvestFilesPageController, private $uibModal: LegacyAny) {}

  run() {
    this.$uibModal
      .open({
        backdrop: 'static',
        component: 'scUploadHarvestFileModalComponent',
        // @ts-expect-error (legacy code incremental fix)
        appendTo: angular.element(document.querySelector('.modal-parent')),
        resolve: {
          currentDirectory: () => this.ctrl.currentDirectory,
        },
      })
      .result.then(this.okAction, this.cancelAction);
  }

  private okAction = (result: LegacyAny) => {
    this.ctrl.openDirectory(result.path);
  };

  private cancelAction = () => {};

  actionable() {
    return true;
  }
}
