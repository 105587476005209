import { LegacyAny } from '@soracom/shared/core';

import { Action } from '../../core/actions/Action';
import { HarvestFile } from '../../core/harvest_file';
import { HarvestFilesService } from './../harvest_files.service';
import { HarvestFilesPageController } from './../harvest_files_page.component';

export class DownloadHarvestFileAction implements Action<HarvestFile> {
  constructor(private ctrl: HarvestFilesPageController, private service: HarvestFilesService) {}

  run(target: HarvestFile[]) {
    this.service
      .get(target[0])
      .then(() => {})
      .catch((e: LegacyAny) => {
        this.ctrl.alertsService.showError(e);
      });
  }

  actionable(target: HarvestFile[]) {
    return target.length === 1;
  }
}
