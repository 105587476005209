import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@soracom/shared/data-access-auth';
import { Observable } from 'rxjs';

export interface LoginPageGuardData {
  ssoLoginUrl?: string;
}

/**
 * For a process before access to login page.
 * So far it redirects to top or a requested page if a user has already logged in.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginPageGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // redirect to / or a path in return_to if logged In
    if (this.authService.isLoggedIn()) {
      return this.router.parseUrl(route.queryParams.return_to || '/');
    }

    // if not logged in, redirect to sso endpoint
    const ssoLoginUrl = (route.data as LoginPageGuardData).ssoLoginUrl ?? 'https://auth.soracom.io/login';

    const returnToPath = route.queryParamMap?.get('return_to') ?? '';
    const operatorId = route.queryParamMap?.get('o') ?? null;

    // redirect to SSO login page
    const redirectFullUrl = `${window.location.origin}${returnToPath}`;

    const ssoUrl = new URL(ssoLoginUrl);
    ssoUrl.searchParams.append('redirect', redirectFullUrl);
    if (operatorId) {
      ssoUrl.searchParams.append('o', operatorId);
    }

    // navigate to sso
    window.location.href = ssoUrl.toString();

    // https://app.shortcut.com/soracom/story/109012/when-access-to-sam-login-url-the-opid-won-t-be-filled
    // if we return false here it cancels navigation causing the angular router to route to `/`
    // this causes the angular runtime to schedule an update to the dom. If this dom update starts before
    // the window.location.href update above had time to complete, the browser cancels the first redirect
    // and the user is redirected again to the SSO login page, but this time minus any parameters that were
    // appended to the URL.
    return true;
  }
}
