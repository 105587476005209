export const template = `<div class="alerts-wrapper"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
<div class="ds-rows">
  <div class="ds-controls --no-indent --plain">
    <div>
      <h2>{{ $ctrl.group.name }}</h2>
    </div>
    <div class="ds-bar"><button class="ds-button --icon-copy x-duplicate-group" type="button" sc-confirm="sc-confirm"
        key="group_details.modal.duplicate" on-confirm="$ctrl.duplicateThisGroup()"><span
          translate="group_details.copy_this"></span></button><button
        class="ds-button--alert --icon-delete x-delete-group" type="button" ng-click="$ctrl.deleteThisGroup()"><span
          translate="group_details.delete_this"></span></button></div>
  </div>
  <dl class="ds-details">
    <dt>{{ &quot;group_details.id&quot; | translate }}</dt>
    <dd>
      <div class="x-header-group-id">{{ $ctrl.editing.groupId }}</div>
    </dd>
    <dt style="display: flex; align-items: center;"><span>{{ &quot;group_details.name&quot; | translate }}</span></dt>
    <dd><sc-inplace-editable-text data-testid="selected-group-name" key="name" display-value="$ctrl.editing.tags.name" edit-ctrl="$ctrl.nameEditCtrl"
        show-remove-button="false" show-save-button="true" show-cancel-button="true"
        show-pencil-icon="true"></sc-inplace-editable-text></dd>
    <dt ng-if="$ctrl.shouldShowGroupLastModified">{{ &quot;group_details.last_modified_at&quot; | translate }}</dt>
    <dd ng-if="$ctrl.shouldShowGroupLastModified" class="--vertical">
      <div>{{ $ctrl.formatDateTime( $ctrl.editing.lastModifiedAt, 'datetime_sec_tz' ) }}</div>
      <div>({{ &quot;group_details.created_at&quot; | translate }}: {{ $ctrl.formatDateTime( $ctrl.editing.createdAt, 'datetime_sec_tz' ) }})</div>
    </dd>
  </dl><uib-tabset><uib-tab heading="{{ &quot;group_details.tab.basic_settings&quot; | translate }}">
      <div ng-if="!$ctrl.status.air.hidden"><uib-accordion id="accordion-air" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.air.isOpen">
            <uib-accordion-heading id="accordion-air-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.air.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.air.isOpen}"></i><span
                  ng-if="$ctrl.isCellular">{{ &quot;group_details.air.cellular&quot; | translate }}</span><span
                  ng-if="$ctrl.isLorawan">{{ &quot;group_details.air.lorawan&quot; | translate }}</span><span
                  ng-if="$ctrl.isSigfox">{{ &quot;group_details.air.sigfox&quot; | translate }}</span><span
                  ng-if="$ctrl.isSatellite">{{ &quot;group_details.air.satellite&quot; | translate }}</span><span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.air_summary&quot; |
                  translate }}</span></div>
            </uib-accordion-heading>
            <div ng-if="$ctrl.isCellular">
              <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                  class="ds-icon --color-celeste --xxlarge {{'service_descriptions.air_cellular'+&quot;.links.icon&quot; | translate }}"></i>
                <div>
                  <p>{{ 'service_descriptions.air_cellular' + '.summary' | translate }}</p>
                  <p><a ng-href="{{ 'service_descriptions.air_cellular' + '.links.overview' | translate }}"
                      target="_blank" translate="documentation_linking.learn_more"></a></p>
                </div>
              </div>
            </div>
            <div ng-if="$ctrl.isSigfox">
              <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                  class="ds-icon --color-celeste --xxlarge {{'service_descriptions.air_sigfox'+&quot;.links.icon&quot; | translate }}"></i>
                <div>
                  <p>{{ 'service_descriptions.air_sigfox' + '.summary' | translate }}</p>
                  <p><a ng-href="{{ 'service_descriptions.air_sigfox' + '.links.overview' | translate }}"
                      target="_blank" translate="documentation_linking.learn_more"></a></p>
                </div>
              </div>
            </div>
            <div ng-if="$ctrl.isLorawan">
              <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                  class="ds-icon --color-celeste --xxlarge {{'service_descriptions.air_lorawan'+&quot;.links.icon&quot; | translate }}"></i>
                <div>
                  <p>{{ 'service_descriptions.air_lorawan' + '.summary' | translate }}</p>
                  <p><a ng-href="{{ 'service_descriptions.air_lorawan' + '.links.overview' | translate }}"
                      target="_blank" translate="documentation_linking.learn_more"></a></p>
                </div>
              </div>
            </div><app-air-config [group]="$ctrl.editing" [air-type]="$ctrl.airType"></app-air-config>
          </div>
        </uib-accordion></div>
      <div ng-if="$ctrl.isCellular"><uib-accordion id="accordion-peh" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.peh.isOpen">
            <uib-accordion-heading id="accordion-peh-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.peh.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.peh.isOpen}"></i>{{
                &quot;group_details.peh&quot; | translate }}<span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.peh_summary&quot; |
                  translate }}</span></div>
            </uib-accordion-heading><sc-prefixed-event-handlers class="ds-rows" target="group" readonly="false"
              group-id="$ctrl.editing.groupId"></sc-prefixed-event-handlers></div>
        </uib-accordion></div>
      <div ng-if="$ctrl.isCellular"><uib-accordion id="accordion-endorse" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.endorse.isOpen">
            <uib-accordion-heading id="accordion-endorse-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.endorse.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.endorse.isOpen}"></i>{{
                &quot;group_details.endorse&quot; | translate }}<span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.endorse_summary&quot;
                  | translate }}</span></div>
            </uib-accordion-heading>
            <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                class="ds-icon --color-celeste --xxlarge {{'service_descriptions.endorse'+&quot;.links.icon&quot; | translate }}"></i>
              <div>
                <p>{{ 'service_descriptions.endorse' + '.summary' | translate }}</p>
                <p><a ng-href="{{ 'service_descriptions.endorse' + '.links.overview' | translate }}" target="_blank"
                    translate="documentation_linking.learn_more"></a></p>
              </div>
            </div><app-endorse-config [group]="$ctrl.editing"></app-endorse-config>
          </div>
        </uib-accordion></div>
      <div ng-if="!$ctrl.status.krypton.hidden"><uib-accordion id="accordion-krypton" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.krypton.isOpen">
            <uib-accordion-heading id="accordion-krypton-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.krypton.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.krypton.isOpen}"></i>{{
                &quot;group_details.krypton&quot; | translate }}<span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.krypton_summary&quot;
                  | translate }}</span></div>
            </uib-accordion-heading>
            <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                class="ds-icon --color-celeste --xxlarge {{'service_descriptions.krypton'+&quot;.links.icon&quot; | translate }}"></i>
              <div>
                <p>{{ 'service_descriptions.krypton' + '.summary' | translate }}</p>
                <p><a ng-href="{{ 'service_descriptions.krypton' + '.links.overview' | translate }}" target="_blank"
                    translate="documentation_linking.learn_more"></a></p>
              </div>
            </div><app-krypton-config [group]="$ctrl.editing"></app-krypton-config>
          </div>
        </uib-accordion></div>
      <div ng-if="!$ctrl.status.beam.hidden"><uib-accordion id="accordion-beam" data-testid="beam-config-button" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.beam.isOpen">
            <uib-accordion-heading id="accordion-beam-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.beam.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.beam.isOpen}"></i>{{
                &quot;group_details.beam&quot; | translate }}<small>&nbsp; - {{ $ctrl.soracomBeamConfigCount }}
                  &nbsp;{{ &quot;group_details.items&quot; | translate }}</small><span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.beam_summary&quot; |
                  translate }}</span></div>
            </uib-accordion-heading>
            <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                class="ds-icon --color-celeste --xxlarge {{'service_descriptions.beam'+&quot;.links.icon&quot; | translate }}"></i>
              <div>
                <p>{{ 'service_descriptions.beam' + '.summary' | translate }}</p>
                <p><a ng-href="{{ 'service_descriptions.beam' + '.links.overview' | translate }}" target="_blank"
                    translate="documentation_linking.learn_more"></a></p>
              </div>
            </div><app-beam-config [group]="$ctrl.editing" [air-type]="$ctrl.airType" (update-config-count)="$ctrl.setSoracomBeamConfigCount($event)"></app-beam-config>
          </div>
        </uib-accordion></div>
      <div><uib-accordion id="accordion-funnel" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.funk.isOpen">
            <uib-accordion-heading id="accordion-funk-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.funk.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.funk.isOpen}"></i>{{
                &quot;group_details.funk&quot; | translate }}<span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.funk_summary&quot; |
                  translate }}</span></div>
            </uib-accordion-heading>
            <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                class="ds-icon --color-celeste --xxlarge {{'service_descriptions.funk'+&quot;.links.icon&quot; | translate }}"></i>
              <div>
                <p>{{ 'service_descriptions.funk' + '.summary' | translate }}</p>
                <p><a ng-href="{{ 'service_descriptions.funk' + '.links.overview' | translate }}" target="_blank"
                    translate="documentation_linking.learn_more"></a></p>
              </div>
            </div>
            <div><app-funk-config-editor [group]="$ctrl.editing" [parent]="$ctrl"
                [air-type]="$ctrl.airType"></app-funk-config-editor></div>
          </div>
        </uib-accordion></div>
      <div><uib-accordion id="accordion-funnel" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.funnel.isOpen">
            <uib-accordion-heading id="accordion-funnel-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.funnel.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.funnel.isOpen}"></i>{{
                &quot;group_details.funnel&quot; | translate }}<span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.funnel_summary&quot; |
                  translate }}</span></div>
            </uib-accordion-heading>
            <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                class="ds-icon --color-celeste --xxlarge {{'service_descriptions.funnel'+&quot;.links.icon&quot; | translate }}"></i>
              <div>
                <p>{{ 'service_descriptions.funnel' + '.summary' | translate }}</p>
                <p><a ng-href="{{ 'service_descriptions.funnel' + '.links.overview' | translate }}" target="_blank"
                    translate="documentation_linking.learn_more"></a></p>
              </div>
            </div>
            <div><app-funnel-config [group]="$ctrl.editing" [parent]="$ctrl"
                [air-type]="$ctrl.airType"></app-funnel-config></div>
          </div>
        </uib-accordion></div>
      <div><uib-accordion id="accordion-harvest" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.harvest.isOpen">
            <uib-accordion-heading id="accordion-harvest-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.harvest.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.harvest.isOpen}"></i>{{
                &quot;group_details.harvest&quot; | translate }}<span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.harvest_summary&quot;
                  | translate }}</span></div>
            </uib-accordion-heading>
            <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                class="ds-icon --color-celeste --xxlarge {{'service_descriptions.harvest'+&quot;.links.icon&quot; | translate }}"></i>
              <div>
                <p>{{ 'service_descriptions.harvest' + '.summary' | translate }}</p>
                <p><a ng-href="{{ 'service_descriptions.harvest' + '.links.overview' | translate }}" target="_blank"
                    translate="documentation_linking.learn_more"></a></p>
              </div>
            </div><app-harvest-data-config [group]="$ctrl.editing" [air-type]="$ctrl.airType"></app-harvest-data-config>
          </div>
        </uib-accordion></div>
      <div ng-if="!$ctrl.status.harvestFiles.hidden"><uib-accordion id="accordion-harvest-files" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group"
            is-open="$ctrl.status.harvestFiles.isOpen"><uib-accordion-heading id="accordion-harvest-file-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.harvestFiles.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.harvestFiles.isOpen}"></i>{{
                &quot;group_details.harvest_files&quot; | translate }}<span
                  class="group-info pull-right text-muted small hidden-xs">{{
                  &quot;group_details.harvest_files_summary&quot; | translate }}</span></div>
            </uib-accordion-heading>
            <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                class="ds-icon --color-celeste --xxlarge {{'service_descriptions.harvest_files'+&quot;.links.icon&quot; | translate }}"></i>
              <div>
                <p>{{ 'service_descriptions.harvest_files' + '.summary' | translate }}</p>
                <p><a ng-href="{{ 'service_descriptions.harvest_files' + '.links.overview' | translate }}"
                    target="_blank" translate="documentation_linking.learn_more"></a></p>
              </div>
            </div><app-harvest-file-config [group]="$ctrl.editing"></app-harvest-file-config>
          </div>
        </uib-accordion></div>
      <div ng-if="!$ctrl.status.orbit.hidden"><uib-accordion id="accordion-orbit" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.orbit.isOpen">
            <uib-accordion-heading id="accordion-orbit-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.orbit.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.orbit.isOpen}"></i>{{
                &quot;group_details.orbit&quot; | translate }}<span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.orbit_summary&quot; |
                  translate }}</span></div>
            </uib-accordion-heading>
            <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                class="ds-icon --color-celeste --xxlarge {{'service_descriptions.orbit'+&quot;.links.icon&quot; | translate }}"></i>
              <div>
                <p>{{ 'service_descriptions.orbit' + '.summary' | translate }}</p>
                <p><a ng-href="{{ 'service_descriptions.orbit' + '.links.overview' | translate }}" target="_blank"
                    translate="documentation_linking.learn_more"></a></p>
              </div>
            </div><app-orbit-config [group]="$ctrl.editing"></app-orbit-config>
          </div>
        </uib-accordion></div>
      <div ng-if="!$ctrl.status.unified.hidden"><uib-accordion id="accordion-unified" close-others="false">
          <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.unified.isOpen">
            <uib-accordion-heading id="accordion-unified-heading">
              <div class="clickable-area"><i class="glyphicon"
                  ng-class="{&quot;glyphicon-chevron-down&quot;: $ctrl.status.unified.isOpen,&quot;glyphicon-chevron-right&quot;: !$ctrl.status.unified.isOpen}"></i>{{
                &quot;group_details.unified&quot; | translate }}<span
                  class="group-info pull-right text-muted small hidden-xs">{{ &quot;group_details.unified_summary&quot;
                  | translate }}</span></div>
            </uib-accordion-heading>
            <div class="ds-banner --strong --color-ink-bright --indent-small documentation-info-box"><i
                class="ds-icon --color-celeste --xxlarge {{'service_descriptions.unified_endpoint'+&quot;.links.icon&quot; | translate }}"></i>
              <div>
                <p>{{ 'service_descriptions.unified_endpoint' + '.summary' | translate }}</p>
                <p><a ng-href="{{ 'service_descriptions.unified_endpoint' + '.links.overview' | translate }}"
                    target="_blank" translate="documentation_linking.learn_more"></a></p>
              </div>
            </div><app-unified-endpoint-config [group]="$ctrl.editing"
              [air-type]="$ctrl.airType"></app-unified-endpoint-config>
          </div>
        </uib-accordion></div>
    </uib-tab><uib-tab heading="{{ &quot;group_details.tab.subscribers&quot; | translate }}"
      ng-if="$ctrl.isCellular"><sc-group-subscribers-table
        group-id="$ctrl.groupId"></sc-group-subscribers-table></uib-tab><uib-tab id="group-advanced-tab"
      heading="{{ &quot;group_details.tab.advanced_settings&quot; | translate }}"><uib-accordion close-others="false">
        <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.tags.isOpen">
          <uib-accordion-heading>
            <div class="clickable-area" id="edit-tag-accordion-header"><i class="glyphicon"
                ng-class="{ &quot;glyphicon-chevron-down&quot;: $ctrl.status.tags.isOpen, &quot;glyphicon-chevron-right&quot;: !$ctrl.status.tags.isOpen }"></i>{{
              &quot;group_details.edit_tag&quot; | translate }}</div>
          </uib-accordion-heading><sc-tag-list service-name="$ctrl.serviceName"
            obj-one-way="$ctrl.editing"></sc-tag-list></div>
      </uib-accordion></uib-tab></uib-tabset>
</div>`;
