import { LegacyAny } from '@soracom/shared/core';

import { Taggable } from '@soracom/shared/soracom-platform';
import { TaggableService, TagParams } from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';
import { LoraDevice } from '../core/lora_device';
import { LoraGateway } from '../core/lora_gateway';
import { SigfoxDevice } from '../core/sigfox_device';
import { ExtendedSim as Sim } from '@soracom/shared/sim';
import { ExtendedSubscriber as LegacySubscriber } from '@soracom/shared/subscriber';
import { AlertsService, AlertsServiceInstance } from './alerts.service';
import { BaseController } from './base_controller';
import { BaseEditTagController } from './base_edit_tag.controller';
import { template } from './base_tag_list.component.html';
import {
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { AddTagModalComponent } from '../../../src/app/ng-rewrites/legacy-tags/add_tag.modal.component';

export class BaseTagListComponent implements ng.IComponentOptions {
  bindings = {
    serviceName: '<',
    obj: '=?',
    objOneWay: '<?', // FIXME: for backward compatibility
  };
  controller = BaseTagListComponentController;
  template: any = template;
}

export class BaseTagListComponentController extends BaseController {
  static $inject: InjectList = [
    '$filter',
    '$injector',
    '$log',
    '$q',
    'UiDsModalService',
    'AlertsService',
    'TableColumnOptionsService',
  ];

  alertsServiceInstance: AlertsServiceInstance;
  // @ts-expect-error (legacy code incremental fix)
  serviceName: string;
  // @ts-expect-error (legacy code incremental fix)
  obj: Taggable;
  // @ts-expect-error (legacy code incremental fix)
  private service: TaggableService;
  // @ts-expect-error (legacy code incremental fix)
  showRemoveTagButtons: boolean;
  // @ts-expect-error (legacy code incremental fix)
  tagEditCtrl: BaseEditTagController;

  set objOneWay(newValue: Taggable) {
    this.obj = newValue;
  }

  get objOneWay(): Taggable {
    return this.obj;
  }

  constructor(
    private $filter: LegacyAny,
    private $injector: LegacyAny,
    $log: LegacyAny,
    private $q: LegacyAny,
    private uiDsModalService: UiDsModalService,
    alertsService: AlertsService,
    private tableColumnOptionsService: TableColumnOptionsService,
  ) {
    super($log);

    this.alertsServiceInstance = alertsService.generate();
  }

  $onInit() {
    if (this.$injector.has(this.serviceName)) {
      this.service = this.$injector.get(this.serviceName);
      this.tagEditCtrl = new BaseEditTagController(this.logService, this.$q);
      this.tagEditCtrl.service = this.service;
      this.tagEditCtrl.editing = this.obj;
      this.tagEditCtrl.alertsService = this.alertsServiceInstance;
    }
  }

  $onChanges(changesObj: LegacyAny) {
    if (changesObj.objOneWay) {
      if (changesObj.objOneWay.currentValue) {
        this.obj = changesObj.objOneWay.currentValue;
        if (this.tagEditCtrl) {
          this.tagEditCtrl.editing = this.obj;
        }
      }
    }
  }

  hasTag(): boolean {
    if (this.obj && this.obj.tags && Object.keys(this.obj.tags).length > 0) {
      const filter = this.$filter('filterReservedTag');
      const visibleTags = filter(this.obj.tags, { invert: true });
      return Object.keys(visibleTags).length > 0;
    } else {
      return false;
    }
  }

  showAddTagModal() {
    this.uiDsModalService
      .openAndWaitForResult(AddTagModalComponent, {
        title: 'add_tag_modal.title',
        data: {
          resolve: {
            params: () => {
              return {
                currentTags: this.obj.tags,
              };
            },
          },
        },
      })
      .then((newTag) => {
        if (newTag !== undefined && newTag !== null) {
          const idString = String(this.obj.id);
          this.service
            .updateTags(idString, [newTag])
            .then(() => {
              this.obj.tags[newTag.tagName] = newTag.tagValue;
            })
            .catch((e: LegacyAny) => {
              this.alertsServiceInstance.showError(e);
            });
        } else {
          this.updateColumnOptionsWithTag('cancel');
        }
      });
  }

  toggleRemoveTagButton() {
    this.showRemoveTagButtons = !this.showRemoveTagButtons;
  }

  updateColumnOptionsWithTag(tagName: LegacyAny) {
    let type: TableColumnOptionsType;
    if (this.obj instanceof LoraDevice) {
      type = TableColumnOptionsType.LoRa;
    } else if (this.obj instanceof LoraGateway) {
      type = TableColumnOptionsType.LoRaGateway;
    } else if (this.obj instanceof SigfoxDevice) {
      type = TableColumnOptionsType.SigFox;
    } else if (this.obj instanceof LegacySubscriber) {
      type = TableColumnOptionsType.Subscriber;
    } else if (this.obj instanceof Sim) {
      type = TableColumnOptionsType.Subscriber;
    }

    // @ts-expect-error (legacy code incremental fix)
    this.tableColumnOptionsService.addCustomTagColumn(tagName, null, type);
  }
}
