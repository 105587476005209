<section dsDialogContent>
  <form  #addCredForm="ngForm" class="ds-rows">
        <div class="ds-field">
          <label class="ds-text --label" for="cred-name"><span translate="security.credentials.modals.register.name"></span>&nbsp;<span
              class="--alert">*</span></label>
            <input class="ds-input" id="cred-name" type="text"
            [placeholder]="'security.credentials.modals.register.name' | translate" name="credName" [(ngModel)]="credName"
            required [pattern]="credNamePattern" uiFocusMe />
            <div class="ds-text"[innerHtml]="'security.credentials.modals.register.name_notice' | translate"></div>
        </div>
        <div class="ds-field"><label class="ds-text --label" for="cred-description"
            translate="security.credentials.modals.register.description"></label><input class="ds-input" id="cred-description" type="text"
            [placeholder]="'security.credentials.modals.register.description' | translate" name="credDescription"
            [(ngModel)]="credDescription" /></div>
        <div class="ds-field"><label class="ds-text --label" for="cred-type"><span translate="security.credentials.modals.register.type"></span>&nbsp;<span
              class="--alert">*</span></label>
          <select class="ds-select" id="cred-type"
            [(ngModel)]="selectedType" name="selectedType" [disabled]="hasDefaultType">
            <option *ngFor="let type of types" [value]="type.code">{{type.label}}</option>
          </select></div>
        <ng-container *ngIf="selectedType">
          <div class="ds-field" *ngIf="selectedType === 'aws-credentials'"><label class="ds-text --label"
              for="cred-aws-key"><span translate="security.credentials.modals.register.aws_key"></span>&nbsp;<span class="--alert">*</span></label><input
              class="ds-input" id="cred-aws-key" type="text" [placeholder]="'security.credentials.modals.register.aws_key' | translate"
              name="credAwsKey" [(ngModel)]="credAwsKey"
              [required]="selectedType === 'aws-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'aws-credentials'"><label class="ds-text --label"
              for="cred-aws-secret"><span translate="security.credentials.modals.register.aws_secret"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-aws-secret" type="text"
              [placeholder]="'security.credentials.modals.register.aws_secret' | translate" name="credAwsSecret" [(ngModel)]="credAwsSecret"
              [required]="selectedType === 'aws-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'aws-iam-role-credentials'"><label
              class="ds-text --label" for="cred-aws-key"><span translate="security.credentials.modals.register.roleArn"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-aws-key" type="text"
              [placeholder]="'security.credentials.modals.register.roleArn' | translate" name="credRoleArn" [(ngModel)]="credRoleArn"
              [required]="selectedType === 'aws-iam-role-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'aws-iam-role-credentials'"><label
              class="ds-text --label" for="cred-aws-secret"><span translate="security.credentials.modals.register.externalId"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-aws-secret" type="text"
              [placeholder]="'security.credentials.modals.register.externalId' | translate" name="credExternalId"
              [(ngModel)]="credExternalId" [required]="selectedType === 'aws-iam-role-credentials'" />
          </div>
          <div class="ds-field" *ngIf="selectedType === 'azure-credentials'"><label
              class="ds-text --label" for="cred-azure-key"><span translate="security.credentials.modals.register.azure_key"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-azure-key" type="text"
              [placeholder]="'security.credentials.modals.register.azure_key' | translate" name="credAzureKey" [(ngModel)]="credAzureKey"
              [required]="selectedType === 'azure-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'azure-credentials'"><label
              class="ds-text --label" for="cred-azure-secret"><span translate="security.credentials.modals.register.azure_secret"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-azure-secret" type="text"
              [placeholder]="'security.credentials.modals.register.azure_secret' | translate" name="credAzureSecret"
              [(ngModel)]="credAzureSecret" [required]="selectedType === 'azure-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'azureIoT-credentials'"><label
              class="ds-text --label" for="cred-shared-access-key-name"><span
                translate="security.credentials.modals.register.azureIoT-credentials.sharedAccessKeyName"></span>
                &nbsp;<i class="ds-icon --small --icon-info --no-case" [attr.data-ds-texttip]="'security.credentials.modals.register.azureIoT-credentials.tooltip.sharedAccessKeyName' | translate"></i></label><input
              class="ds-input" type="text" id="cred-shared-access-key-name" name="credSharedAccessKeyName"
              [(ngModel)]="cred['azureIoT-credentials'].sharedAccessKeyName" /></div>
          <div class="ds-field" *ngIf="selectedType === 'azureIoT-credentials'"><label
              class="ds-text --label" for="cred-shared-access-key"><span
                translate="security.credentials.modals.register.azureIoT-credentials.sharedAccessKey"></span>&nbsp;<span class="--alert">*</span><i class="ds-icon --small --icon-info --no-case" [attr.data-ds-texttip]="'security.credentials.modals.register.azureIoT-credentials.tooltip.sharedAccessKey' | translate"></i></label><input
              class="ds-input" type="text" id="cred-shared-access-key" name="credSharedAccessKey"
              [(ngModel)]="cred['azureIoT-credentials'].sharedAccessKey"
              [required]="selectedType === 'azureIoT-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'googleIoT-credentials'"><label
              class="ds-text --label" for="cred-googleIot-projectId"><span translate="security.credentials.modals.register.googleIot.projectId"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-googleIot-projectId"
              [placeholder]="'security.credentials.modals.register.googleIot.projectId' | translate" name="cred-googleIot-projectId"
              [(ngModel)]="cred['googleIoT-credentials'].projectId"
              [required]="selectedType === 'googleIoT-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'googleIoT-credentials'"><label
              class="ds-text --label" for="cred-googleIot-region"><span translate="security.credentials.modals.register.googleIot.region"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-googleIot-region"
              [placeholder]="'security.credentials.modals.register.googleIot.region' | translate" name="cred-googleIot-region"
              [(ngModel)]="cred['googleIoT-credentials'].region"
              [required]="selectedType === 'googleIoT-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'googleIoT-credentials'"><label
              class="ds-text --label" for="cred-googleIot-registryId"><span
                translate="security.credentials.modals.register.googleIot.registryId"></span>&nbsp;<span class="--alert">*</span></label><input
              class="ds-input" id="cred-googleIot-registryId"
              [placeholder]="'security.credentials.modals.register.googleIot.registryId' | translate" name="cred-googleIot-registryId"
              [(ngModel)]="cred['googleIoT-credentials'].registryId"
              [required]="selectedType === 'googleIoT-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'googleIoT-credentials'"><label
              class="ds-text --label" for="cred-googleIot-deviceId"><span translate="security.credentials.modals.register.googleIot.deviceId"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-googleIot-deviceId"
              [placeholder]="'security.credentials.modals.register.googleIot.deviceId' | translate" name="cred-googleIot-deviceId"
              [(ngModel)]="cred['googleIoT-credentials'].deviceId"
              [required]="selectedType === 'googleIoT-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'googleIoT-credentials'"><label
              class="ds-text --label" for="cred-googleIot-algorithm"><span translate="security.credentials.modals.register.googleIot.algorithm"></span>&nbsp;<span
                class="--alert">*</span></label><select class="ds-select" id="cred-googleIot-algorithm"
              [(ngModel)]="cred['googleIoT-credentials'].algorithm" name="cred-googleIot-algorithm"
              [required]="selectedType === 'googleIoT-credentials'">
              <option value="RS256" label="RS256"></option>
              <option value="ES256" label="ES256"></option>
            </select></div>
          <div class="ds-field" *ngIf="selectedType === 'googleIoT-credentials'"><label
              class="ds-text --label" for="cred-googleIot-privateKey"><span
                translate="security.credentials.modals.register.googleIot.privateKey"></span>&nbsp;<span class="--alert">*</span></label><textarea
              class="ds-input" id="cred-googleIot-privateKey"
              [placeholder]="'security.credentials.modals.register.googleIot.privateKey' | translate" name="cred-googleIot-privateKey"
              [(ngModel)]="cred['googleIoT-credentials'].privateKey"
              [required]="selectedType === 'googleIoT-credentials'"></textarea></div>
          <div class="ds-field" *ngIf="selectedType === 'google-service-account-json'"><label
              class="ds-text --label" for="cred-gsaj-credentials"><span translate="security.credentials.modals.register.gsaj.credentials"></span>&nbsp;<span
                class="--alert">*</span></label><textarea class="ds-input" id="cred-google-sa-json"
              [placeholder]="'security.credentials.modals.register.gsaj.credentials' | translate" name="cred-gsaj-credentials"
              [(ngModel)]="cred['google-service-account-json'].credentials"
              [required]="selectedType === 'google-service-account-json'"></textarea></div>
          <div class="ds-field" *ngIf="selectedType === 'api-token-credentials'"><label
              class="ds-text --label" for="cred-token"><span translate="security.credentials.modals.register.api_token_credentials_token"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-token" type="text" name="credToken"
              [(ngModel)]="cred['api-token-credentials'].token"
              [required]="selectedType === 'api-token-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'psk'"><label class="ds-text --label"
              for="cred-pre-shared-key"><span translate="security.credentials.modals.register.pre_shared_key"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-pre-shared-key" type="text"
              [placeholder]="'security.credentials.modals.register.pre_shared_key' | translate" name="credPreSharedKey"
              [(ngModel)]="credPreSharedKey" [required]="selectedType === 'psk'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'pubnub-credentials'"><label
              class="ds-text --label" for="cred-pub-key"><span translate="security.credentials.modals.register.pubnub-credentials.pubKey"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" type="text" id="cred-pub-key"
              name="credPubKey" [(ngModel)]="cred['pubnub-credentials'].pubKey"
              [required]="selectedType === 'pubnub-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'pubnub-credentials'"><label
              class="ds-text --label" for="cred-sub-key"><span translate="security.credentials.modals.register.pubnub-credentials.subKey"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" type="text" id="cred-sub-key"
              name="credSubKey" [(ngModel)]="cred['pubnub-credentials'].subKey"
              [required]="selectedType === 'pubnub-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'username-password-credentials'"><label
              class="ds-text --label" for="cred-username"><span
                translate="security.credentials.modals.register.username_password_credentials_username"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-username" type="text"
              name="credUsername" [(ngModel)]="cred['username-password-credentials'].username"
              [required]="selectedType === 'username-password-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'username-password-credentials'"><label
              class="ds-text --label" for="cred-password"><span
                translate="security.credentials.modals.register.username_password_credentials_password"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-password" type="password"
              name="credPassword" [(ngModel)]="cred['username-password-credentials'].password"
              [required]="selectedType === 'username-password-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'x509'"><label class="ds-text --label"
              for="cred-x509-key"><span translate="security.credentials.modals.register.x509-key"></span>&nbsp;<span
                class="--alert">*</span></label><textarea class="ds-input" id="cred-x509-key"
              [placeholder]="'security.credentials.modals.register.x509-key' | translate" name="credX509Key" [(ngModel)]="credX509Key"
              [required]="selectedType === 'x509'"></textarea></div>
          <div class="ds-field" *ngIf="selectedType === 'x509'"><label class="ds-text --label"
              for="cred-x509-cert"><span translate="security.credentials.modals.register.x509-cert"></span>&nbsp;<span
                class="--alert">*</span></label><textarea class="ds-input" id="cred-x509-cert" type="text"
              [placeholder]="'security.credentials.modals.register.x509-cert' | translate" name="credX509Cert" [(ngModel)]="credX509Cert"
              [required]="selectedType === 'x509'"></textarea></div>
          <div class="ds-field" *ngIf="selectedType === 'x509'"><label class="ds-text --label"
              for="cred-x509-ca"><span translate="security.credentials.modals.register.x509-ca"></span></label><textarea class="ds-input" id="cred-x509-ca"
              type="text" [placeholder]="'security.credentials.modals.register.x509-ca' | translate" name="credX509CA"
              [(ngModel)]="credX509CA"></textarea></div>
          <div class="ds-field" *ngIf="selectedType === 'esrij-credentials'"><label
              class="ds-text --label" for="cred-referer-url"><span
                translate="security.credentials.modals.register.esrij_credentials_referer_url"></span>&nbsp;<span class="--alert">*</span></label><input
              class="ds-input" id="cred-referer-url" type="text" name="credRefererUrl" placeholder="https://example.com"
              [(ngModel)]="cred['esrij-credentials'].refererUrl"
              [required]="selectedType === 'esrij-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'infoteria-platio-credentials'"><label
              class="ds-text --label" for="cred-token-id"><span
                translate="security.credentials.modals.register.infoteria_platio_credentials_token_id"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-token-id" type="text"
              name="credTokenId" placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
              [(ngModel)]="(cred['infoteria-platio-credentials']).tokenId"
              [required]="selectedType === 'infoteria-platio-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'infoteria-platio-credentials'"><label
              class="ds-text --label" for="cred-secret-token"><span
                translate="security.credentials.modals.register.infoteria_platio_credentials_secret_token"></span>&nbsp;<span
                class="--alert">*</span></label><textarea class="ds-input" id="cred-secret-token"
              name="credSecretToken"
              placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
              [(ngModel)]="cred['infoteria-platio-credentials'].secretToken"
              [required]="selectedType === 'infoteria-platio-credentials'"></textarea></div>
          <div class="ds-field" *ngIf="selectedType === 'kii-thingif-credentials'"><label
              class="ds-text --label" for="cred-app-id"><span translate="security.credentials.modals.register.kii_thingif_credentials_app_id"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-app-id" type="text"
              name="credAppId" [(ngModel)]="cred['kii-thingif-credentials'].app_id"
              [required]="selectedType === 'kii-thingif-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'kii-thingif-credentials'"><label
              class="ds-text --label" for="cred-app-key"><span translate="security.credentials.modals.register.kii_thingif_credentials_app_key"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-app-key" type="text"
              name="credAppKey" [(ngModel)]="cred['kii-thingif-credentials'].app_key"
              [required]="selectedType === 'kii-thingif-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'kii-thingif-credentials'"><label
              class="ds-text --label" for="cred-app-key"><span
                translate="security.credentials.modals.register.kii_thingif_credentials_client_id"></span>&nbsp;<span class="--alert">*</span></label><input
              class="ds-input" id="cred-client-id" type="text" name="credClientId"
              [(ngModel)]="cred['kii-thingif-credentials'].client_id"
              [required]="selectedType === 'kii-thingif-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'kii-thingif-credentials'"><label
              class="ds-text --label" for="cred-client-secret"><span
                translate="security.credentials.modals.register.kii_thingif_credentials_client_secret"></span>&nbsp;<span
                class="--alert">*</span></label><input class="ds-input" id="cred-client-secret" type="text"
              name="credClientSecret" [(ngModel)]="cred['kii-thingif-credentials'].client_secret"
              [required]="selectedType === 'kii-thingif-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'mockmock-datarecorder-credentials'"><label
              class="ds-text --label" for="cred-mockmock-key"><span translate="security.credentials.modals.register.mockmock.key"></span>&nbsp;<span
                class="--alert">*</span></label><textarea class="ds-input" id="cred-mockmock-key"
              name="cred-mockmock-key" [(ngModel)]="cred['mockmock-datarecorder-credentials'].key"
              [required]="selectedType === 'mockmock-datarecorder-credentials'"></textarea></div>
          <div class="ds-field" *ngIf="selectedType === 'mockmock-datarecorder-credentials'"><label
              class="ds-text --label" for="cred-mockmock-cert"><span translate="security.credentials.modals.register.mockmock.cert"></span>&nbsp;<span
                class="--alert">*</span></label><textarea class="ds-input" id="cred-mockmock-cert" type="text"
              name="cred-mockmock-cert" [(ngModel)]="cred['mockmock-datarecorder-credentials'].cert"
              [required]="selectedType === 'mockmock-datarecorder-credentials'"></textarea></div>
          <div class="ds-field" *ngIf="selectedType === 'mockmock-datarecorder-credentials'"><label
              class="ds-text --label" for="cred-mockmock-ca"><span translate="security.credentials.modals.register.mockmock.ca"></span></label><textarea
              class="ds-input" id="cred-mockmock-ca" type="text" name="cred-mockmock-ca"
              [(ngModel)]="cred['mockmock-datarecorder-credentials'].ca"></textarea></div>
          <div class="ds-field" *ngIf="selectedType === 'motionboard-credentials'">
            <label class="ds-text --label" for="cred-motionboard-tenant"><span
                translate="security.credentials.modals.register.motionboard_tenant"></span>&nbsp;<span class="--alert">*</span></label><input
              class="ds-input" id="cred-motionboard-tenant"
              [placeholder]="'security.credentials.modals.register.motionboard_tenant' | translate" name="credMotionboardTenant"
              [(ngModel)]="cred.motionboard.tenant"
              [required]="selectedType === 'motionboard-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'motionboard-credentials'">
            <label class="ds-text --label" for="cred-motionboard-template"><span
                translate="security.credentials.modals.register.motionboard_template"></span>&nbsp;<span class="--alert">*</span></label><input
              class="ds-input" id="cred-motionboard-template" type="text"
              [placeholder]="'security.credentials.modals.register.motionboard_template' | translate" name="credMotionboardTemplate"
              [(ngModel)]="cred.motionboard.template"
              [required]="selectedType === 'motionboard-credentials'" /></div>
          <div class="ds-field" *ngIf="selectedType === 'motionboard-credentials'">
            <label class="ds-text --label" for="cred-motionboard-authkey"><span
                translate="security.credentials.modals.register.motionboard_authkey"></span>&nbsp;<span class="--alert">*</span></label><input
              class="ds-input" id="cred-motionboard-authkey" type="text"
              [placeholder]="'security.credentials.modals.register.motionboard_authkey' | translate" name="credMotionboardAuthkey"
              [(ngModel)]="cred.motionboard.authkey"
              [required]="selectedType === 'motionboard-credentials'" /></div>
          <div class="ds-rows mmcloud-credentials-editor" *ngIf="selectedType === 'mmcloud-credentials'">
            <div class="ds-field"><label class="ds-text --label" for="cred-mmcloud-customerid"><span
                  translate="security.credentials.modals.register.mmcloud_customerid"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-mmcloud-customerid"
                [placeholder]="'security.credentials.modals.register.mmcloud_customerid' | translate" name="cred_mmcloud_customerid"
                [(ngModel)]="cred['mmcloud-credentials'].customerid" required /></div>
            <div class="ds-checkbox">
              <input class="x-input-randomize-partition-key" type="checkbox"
                [(ngModel)]="cred['mmcloud-credentials'].machineidtype_as_boolean" name="machineidtype_as_boolean" />
                <div translate="security.credentials.modals.register.mmcloud_machineidtype"></div>
              </div>
            <div class="ds-field" *ngIf="cred['mmcloud-credentials'].machineidtype_as_boolean">
              <label
                class="ds-text --label" for="cred-mmcloud-machineid"><span translate="security.credentials.modals.register.mmcloud_machineid"></span>&nbsp;<span
                  class="--alert"
                  [hidden]="!cred['mmcloud-credentials'].machineidtype_as_boolean">*</span></label>
                <input
                class="ds-input" id="cred-mmcloud-machineid"
                [placeholder]="'security.credentials.modals.register.mmcloud_machineid' | translate" name="cred_mmcloud_machineid"
                [(ngModel)]="cred['mmcloud-credentials'].machineid"
                [required]="cred['mmcloud-credentials'].machineidtype_as_boolean" /></div>
            <div class="ds-field"><label class="ds-text --label" for="cred-mmcloud-commandcode"><span
                  translate="security.credentials.modals.register.mmcloud_commandcode"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-mmcloud-commandcode"
                [placeholder]="'security.credentials.modals.register.mmcloud_commandcode' | translate" name="cred_mmcloud_commandcode"
                [(ngModel)]="cred['mmcloud-credentials'].commandcode" required
                [pattern]="exactlyFourCharactersPattern" /></div>
            </div>
          <div class="ds-rows landlog-credentials-editor" *ngIf="selectedType === 'landlog-credentials'">
            <div class="ds-field"><label class="ds-text --label" for="cred-landlog-client_id"><span
                  translate="security.credentials.modals.register.landlog.client_id"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-landlog-client_id" name="cred-landlog-client_id"
                [(ngModel)]="cred['landlog-credentials'].client_id" required /></div>
            <div class="ds-field"><label class="ds-text --label" for="cred-landlog-client_secret"><span
                  translate="security.credentials.modals.register.landlog.client_secret"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-landlog-client_secret" name="cred-landlog-client_secret"
                [(ngModel)]="cred['landlog-credentials'].client_secret" required /></div>
          </div>
          <div class="ds-rows optim-cloud-IoT-OS-credentials-credentials-editor" *ngIf="selectedType === 'optim-cloud-IoT-OS-credentials'">
            <div class="ds-field"><label class="ds-text --label" for="cred-cloudiotos-client_id"><span
                  translate="security.credentials.modals.register.cloudiotos.client_id"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-cloudiotos-client_id" name="cred-cloudiotos-client_id"
                [(ngModel)]="cred['optim-cloud-IoT-OS-credentials'].client_id" required /></div>
            <div class="ds-field"><label class="ds-text --label" for="cred-cloudiotos-client_secret"><span
                  translate="security.credentials.modals.register.cloudiotos.client_secret"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-cloudiotos-client_secret" name="cred-cloudiotos-client_secret"
                [(ngModel)]="cred['optim-cloud-IoT-OS-credentials'].client_secret" required /></div>
          </div>
          <div class="ds-rows sensorcorpus-credentials-credentials-editor"
            *ngIf="selectedType === 'sensorcorpus-credentials'">
            <div class="ds-field"><label class="ds-text --label" for="cred-sensorcorpus-session_code"><span
                  translate="security.credentials.modals.register.sensorcorpus.session_code"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-sensorcorpus-session_code" name="cred-sensorcorpus-session_code"
                [(ngModel)]="cred['sensorcorpus-credentials'].session_code" required /></div>
          </div>
          <div class="ds-rows x-watson-iot-credentials-editor" *ngIf="selectedType === 'watsonIoT-credentials'">
            <div class="ds-field"><label class="ds-text --label" for="cred-watson-iot-device-type"><span
                  translate="security.credentials.modals.register.watson_iot_device_type"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-watson-iot-device-type" type="text"
                [placeholder]="'security.credentials.modals.register.watson_iot_device_type' | translate" name="credWatsonIoTDeviceType"
                [(ngModel)]="cred['watsonIoT-credentials'].deviceType" required /></div>
            <div class="ds-field"><label class="ds-text --label" for="cred-watson-iot-device-id"><span
                  translate="security.credentials.modals.register.watson_iot_device_id"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-watson-iot-device-id" type="text"
                [placeholder]="'security.credentials.modals.register.watson_iot_device_id' | translate" name="credWatsonIoTDeviceId"
                [(ngModel)]="cred['watsonIoT-credentials'].deviceId" required /></div>
            <div class="ds-field"><label class="ds-text --label" for="cred-watson-iot-auth-token"><span
                  translate="security.credentials.modals.register.watson_iot_auth_token"></span>&nbsp;<span class="--alert">*</span></label><input
                class="ds-input" id="cred-watson-iot-auth-token" type="text"
                [placeholder]="'security.credentials.modals.register.watson_iot_auth_token' | translate" name="credWatsonIoTAuthToken"
                [(ngModel)]="cred['watsonIoT-credentials'].authToken" required /></div>
          </div>
      </ng-container>
      <div class="ds-text"><em><span class="--alert">* &nbsp;<span
                translate="security.credentials.modals.register.required"></span></span></em></div>
      <app-alerts [alertsManager]="alertsManager" *ngIf="alertsManager.alerts.length > 0"></app-alerts>
  </form>
</section>
<footer dsDialogFooter>
  <button class="ds-button --primary" id="cred-add-submit" (click)="submit()"
    type="submit" [disabled]="!canSubmit()" translate="security.credentials.modals.register.submit"></button>
  <button class="ds-button" id="cred-add-cancel" type="button"
    (click)="close()" translate="security.credentials.modals.register.cancel"></button>
</footer>