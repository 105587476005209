import { LagoonPlan } from '../lagoon/lagoon_plans';

export interface LagoonQuota {
  editorQuota: number;
  viewerQuota: number;
  dashboardQuota: number;
  alertQuota: number;
}

const QUOTA: { [plan: string]: LagoonQuota } = {};
QUOTA[LagoonPlan.free] = {
  editorQuota: 1,
  viewerQuota: 0,
  dashboardQuota: 1,
  alertQuota: 1,
};
QUOTA[LagoonPlan.maker] = {
  editorQuota: 1,
  viewerQuota: 2,
  dashboardQuota: 3,
  alertQuota: 10,
};
QUOTA[LagoonPlan.pro] = {
  editorQuota: 1,
  viewerQuota: 9,
  dashboardQuota: 5,
  alertQuota: 20,
};

const USER_PACK_ADDITIONAL_VIEWER_QUOTA = 4;
const USER_PACK_ADDITIONAL_EDITOR_QUOTA = 1;
const DASHBOARD_PACK_ADDITIONAL_DASHBOARD_QUOTA = 1;
const DASHBOARD_PACK_ADDITIONAL_ALERT_QUOTA = 4;

export class LagoonLicenseQuota {
  userPackCount: number;
  dashboardPackCount: number;

  constructor(public plan: LagoonPlan, licensePacks?: Array<{ licensePackName: string; quantity: number }>) {
    const payload: { [licensePackName: string]: number } = {};
    if (licensePacks) {
      for (const d of licensePacks) {
        payload[d.licensePackName] = d.quantity;
      }
    }
    this.userPackCount = payload.basicUser || 0;
    this.dashboardPackCount = payload.basicDashboard || 0;
  }

  get viewerQuota() {
    if (this.quota) {
      return this.quota.viewerQuota + this.userPackCount * USER_PACK_ADDITIONAL_VIEWER_QUOTA;
    } else {
      return 0;
    }
  }

  get editorQuota() {
    if (this.quota) {
      return this.quota.editorQuota + this.userPackCount * USER_PACK_ADDITIONAL_EDITOR_QUOTA;
    } else {
      return 0;
    }
  }

  get dashboardQuota() {
    if (this.quota) {
      return this.quota.dashboardQuota + this.dashboardPackCount * DASHBOARD_PACK_ADDITIONAL_DASHBOARD_QUOTA;
    } else {
      return 0;
    }
  }

  get alertQuota() {
    if (this.quota) {
      return this.quota.alertQuota + this.dashboardPackCount * DASHBOARD_PACK_ADDITIONAL_ALERT_QUOTA;
    } else {
      return 0;
    }
  }

  get quota(): LagoonQuota {
    return QUOTA[this.plan];
  }
}
