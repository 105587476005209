import { LagoonVersion } from '../../../../app/shared/lagoon/lagoon.service';
import { LagoonSubscription } from '../../../../app/shared/lagoon/lagoon_plans';

export interface State {
  subscription: LagoonSubscription;
  availableVersions: LagoonVersion[];
  error?: any;
}

export const initialState: State = {
  // @ts-expect-error (legacy code incremental fix)
  subscription: undefined,
  availableVersions: [],
  error: undefined,
};
