import { Action } from '../../core/actions/Action';
import { HarvestFile } from '../../core/harvest_file';

export class CopyHarvestFileUrlForAirAction implements Action<HarvestFile> {
  constructor() {}

  run(target: HarvestFile[]) {
    if (target.length !== 1) {
      return '';
    }
    navigator.clipboard.writeText(target[0].urlForAir);
  }

  actionable(target: HarvestFile[]): boolean {
    return target.length === 1;
  }
}
