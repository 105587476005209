<div class="ds-rows">
  <ng-container>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </ng-container>
  <div class="ds-notice --warning" *appFeature="'lagoon3'">
    <span [innerHTML]="'LagoonDashboardsComponent.v3_notice' | translate"></span>
  </div>
  <div class="ds-notice --info" *ngIf="canUseLagoon2">
    <span>{{ "LagoonDashboardsComponent.showing_lagoon_2_dashboards" | translate }}</span>
  </div>
  <div class="ds-notice --info" *ngIf="quota">
    <span
      [translate]="isProUser ? 'LagoonDashboardsComponent.quota_pro' : 'LagoonDashboardsComponent.quota'"
      [translateParams]="translateParams"
    ></span>
    <ng-container *ngIf="enabledBothVersions">
      <br />
      <span translate="LagoonDashboardsComponent.migration_info"></span>
    </ng-container>
  </div>

  <!-- Allow migration tool on User console -->
  <ng-container *ngIf="!isProUser">
    <div class="ds-notice --warning" translate="LagoonDashboardsComponent.pro_plan_is_required"></div>
    <div *ngIf="enabledBothVersions">
      <button class="ds-button" (click)="openMigrationModal()">
        <span translate="LagoonDashboardsComponent.migration_tool"></span>
      </button>
    </div>
  </ng-container>


  <!-- Allow dashboard permission control on User console-->
  <ng-container *ngIf="isProUser && canUseLagoon2">
    <div class="ds-cols --stretch">
      <div class="ds-cols --small">
        <ui-menu [content]="menu"></ui-menu>
        <button class="ds-button" (click)="openMigrationModal()" *ngIf="enabledBothVersions">
          <span translate="LagoonDashboardsComponent.migration_tool"></span>
        </button>
      </div>
      <div class="ds-cols --end">
        <ui-pagination [content]="paginationContent" [tableContent]="content" [pagination]="false"></ui-pagination>
      </div>
    </div>
    <ui-generic-table [content]="content" (selectionChanged)="onTableItemSelectionChanged($event)"></ui-generic-table>
  </ng-container>
</div>
