import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { Group } from '@soracom/shared/group';

export type GroupMap = { [groupId: string]: Group };

/**
 * Cache storage for each coverage type.
 */
export class GroupsCache {
  // @ts-expect-error (legacy code incremental fix)
  private data: GroupMap;
  private groupsCount = 0;
  initialized = false;

  constructor(groups: LegacyAny, public coverageType: LegacyAny) {
    this.reset(groups);
  }

  all() {
    return this.data;
  }

  find(groupId: string): Group {
    return this.data[groupId];
  }

  findByName(name: string): Group {
    // @ts-expect-error (legacy code incremental fix)
    let result: Group = null;
    angular.forEach(this.data, (group) => {
      if (group.name === name) {
        result = group;
      }
    });
    return result;
  }

  set(group: Group) {
    this.initialized = true;
    this.data[group.id] = group;
  }

  remove(groupId: string) {
    const group = this.data[groupId];
    if (group) {
      delete this.data[groupId];
    }
    return group;
  }

  reset(groups: Group[]) {
    this.data = {};
    if (groups) {
      groups.forEach((g) => {
        this.set(g);
      });
      this.initialized = true;
    } else {
      this.initialized = false;
    }
    return this;
  }

  count(): number {
    return Object.keys(this.data).length;
  }
}
