<div class="ds-modal">
  <div class="ds-dialog">
    <div class="ds-dialog__header">
      <div class="ds-dialog__title" [innerHTML]="'SoracomLogs.logDetail' | translate"></div>
    </div>

    <div class="ds-dialog__main">
      <code style="white-space: pre-wrap">{{ recordDetail }}</code>
    </div>

    <div class="ds-dialog__footer">
      <button class="ds-button ds-button--primary" (click)="close.emit()">
        <span>{{ 'generic.button_title.ok' | translate }}</span>
      </button>
    </div>
  </div>
</div>
