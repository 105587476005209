import { LegacyAny } from '@soracom/shared/core';

import { AlertsService } from '../components/alerts.service';
import { BaseEditTagController } from '../components/base_edit_tag.controller';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import { ScPaginator } from '../components/paginator';
import { DataAction } from '../core/actions/data_action';
import { EditTagsAction } from '../../../src/app/ng-rewrites/legacy-actions/edit_tags_action';
import { LogsAction } from '../core/actions/LogsAction';
import { ReloadTableAction } from '../core/actions/ReloadTableAction';
import { ShowResourceAction } from '../core/actions/ShowResourceAction';
import { Device, DeviceStatus } from '../core/device';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { InjectList } from '../core/injectable';
import { template } from './devices.component.html';
import { DevicesService } from './devices.service';
import { AddDeviceAction, ChangeDeviceGroupAction, UpdateDeviceStatusAction } from './devices_action';

import { enumValues } from '../../../src/app/core/util/enumValues';
import { DeviceSearchableField } from '../../../src/app/device-search/DeviceSearchableField';
import { DeviceSearchPredicate } from '../../../src/app/device-search/DeviceSearchPredicate';
import { SearchQuery } from '@user-console/legacy-soracom-api-client';
import { PredicateTest } from '../../../src/app/soracom-ui/ui-predicate-editor/PredicateTest';
import { SearchContext } from '../../../src/app/soracom-ui/ui-search/ui-search.component';
import {
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

/**
 * A component for display Devices page
 */
export class DevicesComponent implements ng.IComponentOptions {
  bindings = {};

  controller = DevicesComponentController;
  template: any = template;
}

export class DevicesComponentController extends BasePaginatableTableController<Device> {
  searchContext: SearchContext = {
    defaultPredicate: () => {
      return new DeviceSearchPredicate(DeviceSearchableField.any, PredicateTest.stringPartialMatchCaseInsensitive);
    },
    possibleSubjects: () => {
      return enumValues(DeviceSearchableField);
    },
  };

  static $inject: InjectList = [
    '$location',
    '$log',
    '$translate',
    '$q',
    '$uibModal',
    'AlertsService',
    'DevicesService',
    'TableColumnOptionsService',
    'UiDsModalService',
  ];

  submitting = false;

  paginator: ScPaginator<Device>;

  constructor(
    private $location: ng.ILocationService,
    $log: ng.ILogService,
    $translate: LegacyAny,
    $q: ng.IQService,
    public $uibModal: any,
    AlertsServiceManager: AlertsService,
    public devicesService: DevicesService,
    private tableColumnOptionsService: TableColumnOptionsService,
    public uiDsModalService: UiDsModalService,
  ) {
    super($log, $translate, $q, AlertsServiceManager, devicesService);

    this.modelName = 'Devices';
    this.paginator = new ScPaginator<Device>($q, devicesService);
  }

  $onInit() {
    this.setTraceEnabled(true);
    this.trace(this);

    this.alertsService = this.alertsServiceGenerator.generate();

    this.actionContainer.actions = {
      Activate: new UpdateDeviceStatusAction(this, ['inactive'], 'active'),
      AddDevice: new AddDeviceAction(this),
      ChangeGroup: new ChangeDeviceGroupAction(this, this.uiDsModalService),
      Data: new DataAction<Device>(this, this.$location),
      Deactivate: new UpdateDeviceStatusAction(this, ['active'], 'inactive'),
      Delete: new UpdateDeviceStatusAction(this, ['active', 'inactive'], 'deleted'),
      EditTag: new EditTagsAction<Device>(this.uiDsModalService, this.devicesService),
      Logs: new LogsAction<Device>(this, this.$location),
      Reload: new ReloadTableAction(this),
      ShowDevice: new ShowResourceAction(this, this.$location, '/devices/'),
    };

    this.setupColumnOptions(TableColumnOptionsType.Device, this.tableColumnOptionsService, this.uiDsModalService);
    this.reloadData();
  }

  onDeviceSearchQueryChanged(newQuery: LegacyAny) {
    this.debug('onDeviceSearchQueryChanged():', newQuery);

    this._deviceSearchQuery = newQuery;
    this.paginator.clearPagination();
    this.reloadData();
  }

  protected getErrorMessage(translationId: string) {
    return this.$translate.instant(`devices.errors.${translationId}`);
  }

  // @ts-expect-error (legacy code incremental fix)
  private _deviceSearchQuery: SearchQuery;

  get searchQuery() {
    return this._deviceSearchQuery;
  }

  updateGroup(group?: Group) {
    const targets = this.tableData.getSelectedData();
    const promises: LegacyAny = [];
    targets.forEach((target) => {
      if (group && group.groupId !== target.groupId) {
        target.group = group;
        promises.push(this.devicesService.setGroup(target.deviceId, group.groupId));
      } else if (!group && target.groupId) {
        // @ts-expect-error (legacy code incremental fix)
        target.group = null;
        promises.push(this.devicesService.unsetGroup(target.deviceId));
      }
    });

    this.$q
      .all(promises)
      .then(() => {
        this.alertsService.showSuccess('devices.change_group_success_message');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  updateStatus(fromStatus: DeviceStatus[], destinationStatus: string) {
    const promises: LegacyAny = [];
    const data: Device[] = this.tableData.getSelectedData();
    data
      .filter((device) => {
        return fromStatus.includes(device.status);
      })
      .forEach((device) => {
        switch (destinationStatus) {
          case 'active':
            promises.push(this.devicesService.activate(device.deviceId));
            break;
          case 'inactive':
            promises.push(this.devicesService.deactivate(device.id));
            break;
          case 'deleted':
            promises.push(this.devicesService.delete(device.id));
            break;
          default:
            this.debug(`Unknown destinationStatus: ${destinationStatus}`);
            break;
        }
      });

    this.$q
      .all(promises)
      .then(() => {
        this.reloadData();
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  assignGroup(data: Device[]): Promise<Device[]> {
    /*
     * TODO: This is a kind of dirty implementation to perform object relation.
     *
     * Problem 1: If the number of groups is too big, we cannot fetch all groups at once.
     */
    return groupsService
      .getCachedAll()
      .then((groups) => {
        data.forEach((device) => {
          if (device.groupId) {
            const group = groups.get(device.groupId);
            if (group) {
              device.group = new Group(group);
            }
          }
        });
        return data;
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
        return data;
      });
  }

  setData(data: Device[]) {
    return this.assignGroup(data)
      .then((dataWithGroup) => {
        super.setData(dataWithGroup);
        this.tableData.rows.forEach((row) => {
          // FIXME: Super dirty hack to work with nameEditCtrl....
          const nameEditCtrl = new BaseEditTagController(this.logService, this.$q);
          nameEditCtrl.service = this.devicesService;
          nameEditCtrl.editing = row.obj;
          nameEditCtrl.alertsService = this.alertsService;
          row.nameEditCtrl = nameEditCtrl;
        });
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this._isLoading = false;
      });
  }

  statusText(device: Device) {
    if (device.status) {
      return this.$translate.instant(`devices.values.status.${device.status}`);
    } else {
      return '';
    }
  }
}
