import { InjectList } from '../core/injectable';
import { BaseController } from './base_controller';
import { BasePaginatableTableController } from './base_paginatable_table_controller';
import { Paginator } from './paginator';
import { template } from './sc_pagination.component.html';

declare global {
  interface Window {
    soracom_user_console_debug_extra_items_per_page_option: number;
    // Mason 2019-05-29: FIXME: move this feature to the new SoracomUserConsole singleton, where we now want to consolidate all this kind of "debug functionality that is exposed to tests/developers via the Window object" stuff.
  }
}

export class ScPaginationComponent implements ng.IComponentOptions {
  bindings = {
    paginator: '<',
    ctrl: '<',
    pagination: '<?',

    /**
     * Added in 2023 as a workaround. Not making it better because this AngularJS code needs to die. Set this to `true` to show the settings button. If you set this to true the parent component must implement a method called `openTableSettingsModal` that will be called when the settings button is clicked. The base AngularJS table controller already does this. This should have no effect on old code that doesn't explicitly set `settings` to true.
     */
    settings: '<?',
  };

  controller = ScPaginationController;
  template: any = template;
}

export interface PaginationController {
  hasPrev(): boolean;
  hasNext(): boolean;
  prev(): void;
  next(): void;
}

export class ScPaginationController<T> extends BaseController implements PaginationController {
  static $inject: InjectList = ['$log'];

  // @ts-expect-error (legacy code incremental fix)
  private paginator: Paginator<T>;
  // @ts-expect-error (legacy code incremental fix)
  private ctrl: BasePaginatableTableController<T>;

  // If the value is true, show pagination controls
  // @ts-expect-error (legacy code incremental fix)
  private pagination: boolean;

  /**
   * Added in 2023 as a workaround, see the docs for the corresponding binding above.
   */
  private settings = false;

  executeParentSettingsFunction() {
    this.ctrl.openTableSettingsModal();
  }

  shouldShowSettingsButton(): boolean {
    return !!this.settings;
  }

  get itemsPerPageOptions(): number[] {
    return this.paginator.itemsPerPage;
  }

  get isLoading(): boolean {
    return this.ctrl?.isLoading();
  }

  constructor($log: ng.ILogService) {
    super($log);
    const win: any = window;

    if (win.soracom_user_console_debug_extra_items_per_page_option) {
      this.itemsPerPageOptions.unshift(win.soracom_user_console_debug_extra_items_per_page_option);
    }
  }

  $onInit() {
    if (this.pagination === undefined) {
      this.pagination = true;
    }
  }

  hasPrev(): boolean {
    return this.paginator.hasPrev();
  }

  updateItemsPerPage() {
    this.paginator.clearPagination();
    this.ctrl.reloadData();
  }

  hasNext(): boolean {
    return this.paginator.hasNext();
  }

  reload() {
    this.ctrl.reloadData();
  }

  reloadable(): boolean {
    return !this.ctrl.isLoading();
  }

  prev() {
    this.ctrl.showPrevPage();
  }

  next() {
    this.ctrl.showNextPage();
  }

  showPager() {
    return this.pagination;
  }

  showPerPage() {
    return this.pagination;
  }

  isVisibleItemCountAndPosition(): boolean {
    return this.ctrl && !this.ctrl.isLoading() && this.ctrl.hasData() && this.ctrl.getSelection().length === 0;
  }

  isVisibleItemCountAndPositionAndSelection(): boolean {
    return this.ctrl && !this.ctrl.isLoading() && this.ctrl.hasData() && this.ctrl.getSelection().length > 0;
  }

  translationDataForItemsCountAndPosition() {
    const currentPage = this.paginator.getCurrentPageNumber();
    const itemsPerPage = this.paginator.perPage;
    const dataCount = this.ctrl.tableData.rows.length;
    const selectionCount = this.ctrl.getSelection().length;

    return {
      head: (currentPage - 1) * itemsPerPage + 1,
      tail: (currentPage - 1) * itemsPerPage + dataCount,
      selected: selectionCount,
    };
  }
}
