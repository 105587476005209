import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';

import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';

import { GpsMultiunitPageState } from '../../root-store/gps-multiunit-store/state';

export const SUBSCRIBER_TAG_NAME_GADGET = 'soracom.gadgets';
export const SUBSCRIBER_TAG_VALUE_GPS_MULTIUNIT_IN_USE = 'GPS-Multiunit';

export interface GpsMultiunitStateChangedEvent {
  newState: GpsMultiunitPageState;
  attributes?: {
    imsiList?: string[];
    groupId?: string;
    newGroupName?: string;
    selectedSubscribers?: ExtendedSubscriberInterface[];
  };
}

@Component({
  selector: 'app-gps-multiunit',
  templateUrl: './gps-multiunit.component.html',
})
export class GpsMultiunitComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() state: GpsMultiunitPageState;
  @Output() onStateChanged = new EventEmitter<GpsMultiunitStateChangedEvent>();

  private logger: Logger;

  constructor() {
    this.logger = LoggerService.shared();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // this.isLoading$ = this.store$.select(
    //   GpsMultiunitStoreSelectors.selectGpsMultiunitIsLoading
    // );
    // this.error$ = this.store$.select(
    //   GpsMultiunitStoreSelectors.selectGpsMultiunitError
    // );
  }

  relayOnStateChanged(event: GpsMultiunitStateChangedEvent) {
    this.logger.debug('relayOnStateChanged(event: GpsMultiunitStateChangedEvent)');
    this.onStateChanged.emit(event);
  }
}
