import { LegacyAny } from '@soracom/shared/core';

import { BaseEditTagController } from '../components/base_edit_tag.controller';
import { BaseTableController } from '../components/base_table_controller';
import { InjectList } from '../core/injectable';
import { LoraGateway } from '../core/lora_gateway';
import { LoraGatewaysService } from './lora_gateways.service';
import { template } from './_lora_gateways_table.html';

export class LoraGatewaysTableComponent implements ng.IComponentOptions {
  bindings = {
    loraGateways: '<',
  };
  controller = LoraGatewaysTableComponentController;
  template: any = template;
}

export class LoraGatewaysTableComponentController extends BaseTableController<LoraGateway> {
  static $inject: InjectList = ['$log', '$q', '$translate', 'LoraGatewaysService'];

  loraGateways: any; // FIXME: typing

  constructor(
    $log: ng.ILogService,
    private $q: ng.IQService,
    private $translate: any,
    public LoraGatewaysService: LoraGatewaysService
  ) {
    super($log);
    this.modelName = 'LoraGateways';
  }

  shouldShowContextMenu() {
    return false;
  }

  selectable(): boolean {
    return false;
  }

  $onChanges(changesObj: LegacyAny) {
    /**
     * this.lora_gateways is initialized by parent component after $onInit().
     * I have to watch the update and apply to this.. It's weird.
     * I guess I shouldn't get object in the component..?
     *
     * Yuta
     */
    this.trace(changesObj);
    if (changesObj.loraGateways.currentValue) {
      this.setData(this.loraGateways.map((l: LegacyAny) => new LoraGateway(l)));
    } else {
      this.trace('current device id is not changed.');
    }
  }

  setData(data: LoraGateway[]) {
    super.setData(data);
    this.tableData.rows.forEach((row) => {
      const nameEditCtrl = new BaseEditTagController(this.logService, this.$q);
      nameEditCtrl.service = this.LoraGatewaysService;
      nameEditCtrl.editing = row.obj;
      row.nameEditCtrl = nameEditCtrl;
    });
  }

  statusText(gateway: LoraGateway) {
    if (gateway.status) {
      return this.$translate.instant(`lora_gateways.values.status.${gateway.status}`);
    } else {
      return '';
    }
  }
}
