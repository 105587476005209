import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { GroupOrGroupParam, LteMButtonPageRouteState, LteMButtonPageState, State } from './state';

import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';

export const selectLteMButtonState: MemoizedSelector<object, State> = createFeatureSelector<State>('lteMButton');
export const selectLteMButtonStateValue: MemoizedSelector<object, LteMButtonPageState> = createSelector(
  selectLteMButtonState,
  (state: State): LteMButtonPageState => state.routing.status
);
export const selectLteMButtonSelectedSubscribers: MemoizedSelector<object, ExtendedSubscriberInterface[]> = createSelector(
  selectLteMButtonState,
  (state: State): ExtendedSubscriberInterface[] => state.new.selectedSubscribers.data
);
export const selectLteMButtonGroup: MemoizedSelector<object, GroupOrGroupParam> = createSelector(
  selectLteMButtonState,
  // @ts-expect-error (legacy code incremental fix)
  (state: State): GroupOrGroupParam => state.edit.group.data
);
export const selectLteMButtonGroupId: MemoizedSelector<object, string> = createSelector(
  selectLteMButtonState,
  // @ts-expect-error (legacy code incremental fix)
  (state: State): string => state.routing.groupId
);
export const selectLteMButtonIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectLteMButtonState,
  // @ts-expect-error (legacy code incremental fix)
  (state: State): boolean => state.new.selectedSubscribers.loading || state.edit.group.loading
);
export const selectLteMButtonIndexPageLoadingRequired: MemoizedSelector<object, boolean> = createSelector(
  selectLteMButtonState,
  (state: State): boolean => state.index.loadingRequired
);
export const selectLteMButtonError: MemoizedSelector<object, Error | string | null> = createSelector(
  selectLteMButtonState,
  // @ts-expect-error (legacy code incremental fix)
  (state: State): Error | string | null => state.new.selectedSubscribers.error || state.edit.group.error
);

export const selectLteMButtonPageRouteState: MemoizedSelector<object, LteMButtonPageRouteState> = createSelector(
  selectLteMButtonState,
  (state: State): LteMButtonPageRouteState => state.routing
);
