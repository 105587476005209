import { SearchQuery, SearchQueryItem } from '@user-console/legacy-soracom-api-client';
import { Predicate } from '../soracom-ui/ui-predicate-editor/Predicate';
import { PredicateTest } from '../soracom-ui/ui-predicate-editor/PredicateTest';
import { DeviceSearchableField } from './DeviceSearchableField';

export class DeviceSearchPredicate extends Predicate {
  constructor(subject: DeviceSearchableField, test: PredicateTest, comparisonValue?: string /* | number */) {
    // @ts-expect-error (legacy code incremental fix)
    super(subject, test, comparisonValue);
  }

  get allowedValues(): string[] | undefined {
    return undefined;
  }

  /**
   * Overridden to handle "any" searching.
   */
  get searchQuery(): SearchQuery {
    const queryItems: SearchQueryItem[] = [];

    if (this.subject === DeviceSearchableField.any) {
      Object.keys(DeviceSearchableField).forEach((key) => {
        // @ts-expect-error (legacy code incremental fix)
        queryItems.push(new SearchQueryItem(key, this.comparisonValue));
      });
    } else {
      // @ts-expect-error (legacy code incremental fix)
      queryItems.push(new SearchQueryItem(this.subject, this.comparisonValue));
    }

    // Validate the query, by removing illegal values, to prevent Searchlight API from throwing error about invalid key or arg:

    const validatedQueryItems = queryItems.filter((item) => {
      if (item.key === DeviceSearchableField.any) {
        return false;
      }
      return true;
    });

    return new SearchQuery(validatedQueryItems);

    // FIXME: wanted to use the superclass implementation; but when targeting ES5 you can't access a superclass's get/set implementation from within an override. (https://github.com/Microsoft/TypeScript/issues/338)
  }
}
