import { LegacyAny } from '@soracom/shared/core';

export const ScCapitalizeFilter = () => {
  return (token: string | undefined) => {
    if (token && token.length > 2) {
      return token.charAt(0).toUpperCase() + token.slice(1);
    } else {
      return token;
    }
  };
};

ScCapitalizeFilter.$inject = [] as LegacyAny;
