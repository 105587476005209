import { BaseModel } from '@soracom/shared/soracom-platform';

export class HarvestContract extends BaseModel {
  static get normal(): HarvestContract {
    return normal;
  }
  static get extended(): HarvestContract {
    return extended;
  }

  constructor(public plan: string, public ttl: number) {
    super();
  }

  equals(another: HarvestContract) {
    return this.plan === another.plan && this.ttl === another.ttl;
  }

  get id(): string {
    return `${this.plan}-${this.ttl}`;
  }
}

// There is no contract "normal". this instance acts as "null object"
const normal = new HarvestContract('normal', 40);
const extended = new HarvestContract('extended', 731);
