import { LegacyAny } from '@soracom/shared/core';

import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { Action } from '../../core/actions/Action';
import { HarvestFile } from '../../core/harvest_file';
import { HarvestFilesService } from '../harvest_files.service';
import { HarvestFilesPageController } from '../harvest_files_page.component';

export class DeleteHarvestFileAction implements Action<HarvestFile> {
  constructor(
    private $q: ng.IQService,
    private ctrl: HarvestFilesPageController,
    private service: HarvestFilesService,
    private modalService: UiDsModalService
  ) {}

  async run(target: HarvestFile[]) {
    const confirmed = await this.modalService.openGenericConfirmModal(
      'harvest_files.delete_harvest_file_action_modal',
      { modalStyle: 'delete' }
    );
    if (!confirmed) {
      return;
    }
    const promises = target.map((t) =>
      this.service
        .delete(t)
        .then(() => ({ file: t, error: null }))
        .catch((e: LegacyAny) => ({ file: t, error: e }))
    );
    const totalRecords = promises.length;
    this.$q.all(promises).then((results) => {
      const succeededRecords = results.filter((r) => r.error === null).length;
      this.ctrl.reloadData();
      this.ctrl.alertsService.showSuccess(`harvest_files.delete_harvest_file_action_modal.succeed`, {
        all: totalRecords,
        success: succeededRecords,
      });
      const errorRecords = results.filter((r) => r.error).map((r) => r.error);
      errorRecords.forEach((error: LegacyAny) => {
        this.ctrl.alertsService.showError(error);
      });
    });
  }

  actionable(target: HarvestFile[]): boolean {
    return target.length > 0;
  }
}
