import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { HarvestDataService } from '../../../app/shared/harvest_data/harvest_data.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

export type HarvestDataContractActionType = 'subscribe' | 'unsubscribe';

const I18N_PREFIX = 'data.contract.action-modal';

@Injectable()
export class HarvestDataContractAction {
  constructor(
    private modalService: UiDsModalService,
    private api: HarvestDataService,
    private alertsManager: AlertsManager
  ) {}

  public async run(type: HarvestDataContractActionType) {
    const ok = await this.modalService.openGenericConfirmModal(`${I18N_PREFIX}.${type}`);

    if (ok) {
      this.doContractAction(type);
    }
  }

  private doContractAction = (type: HarvestDataContractActionType) => {
    const successMessageKey = `${I18N_PREFIX}.${type}.successMessage`;

    (type === 'subscribe' ? this.api.subscribe() : this.api.unsubscribe()).then(
      () => {
        this.alertsManager.add(Alert.success(LegacyTextContent.translation(successMessageKey)));
      },
      (error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
      }
    );
  };
}
