import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { LogViewerControllerMode } from '../log-viewer/log-viewer.component';
import { LogViewerSubscriptionModalService, SubscriptionModal } from '../LogViewerSubscriptionModalService';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface SubscriptionTextPair {
  /**
   * This should be `false` when we "don't know yet" so that the UI can behave accordingly.
   */
  hasLoaded: boolean;

  user_is: string; // "You don't have a subscription to Napter audit logs."
  user_may: string; // "Subscribe"
}

@Component({
  selector: 'app-log-viewer-subscription-status',
  templateUrl: './log-viewer-subscription-status.component.html',
  styleUrls: [],
})
export class LogViewerSubscriptionStatusComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  // 🌸

  @Input() mode?: LogViewerControllerMode;

  get shouldShowSubscriptionStatus() {
    const showModes: LogViewerControllerMode[] = [
      'auditLogs',
      'testAuditLogs',
      'napterAuditLogs',
      'testNapterAuditLogs',
    ];
    // @ts-expect-error (legacy code incremental fix)
    const featureIsAvailable = ['auditLogs' || 'testAuditLogs'].includes(this.mode);
    // @ts-expect-error (legacy code incremental fix)
    return featureIsAvailable && showModes.includes(this.mode);
  }

  // @ts-expect-error (legacy code incremental fix)
  currentModal: SubscriptionModal;

  hasNapterContract: boolean | undefined;

  hasAuditLogsEnterpriseContract: boolean | undefined;

  constructor(
    private subscriptionModalService: LogViewerSubscriptionModalService,
    private featureVisibilityService: FeatureVisibilityService
  ) {}

  ngOnInit(): void {
    this.subscriptionModalService.hasNapterContract.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((c) => {
      this.hasNapterContract = c;
    });

    this.subscriptionModalService.hasAuditLogsEnterpriseContract
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((c) => {
        this.hasAuditLogsEnterpriseContract = c;
      });
  }

  showModal($event: LegacyAny) {
    $event.preventDefault();
    $event.stopPropagation();

    const mode = this.mode;

    if (mode === 'auditLogs' || mode === 'testAuditLogs') {
      if (this.hasAuditLogsEnterpriseContract !== undefined) {
        const modal = this.hasAuditLogsEnterpriseContract
          ? 'unsubscribeAuditLogsEnterprise'
          : 'subscribeAuditLogsEnterprise';
        this.subscriptionModalService.showModal(modal);
      }
    } else if (mode === 'napterAuditLogs' || mode === 'testNapterAuditLogs') {
      if (this.hasNapterContract !== undefined) {
        const modal = this.hasNapterContract ? 'unsubscribeNapterAuditLogs' : 'subscribeNapterAuditLogs';
        this.subscriptionModalService.showModal(modal);
      }
    }
  }

  /**
   * FIXME: This is too complicated for what it is doing, just make it WAY dumber....
   */
  // @ts-expect-error (legacy code incremental fix)
  get subscriptionText(): SubscriptionTextPair {
    if (this.hasNapterContract === undefined) {
      return { hasLoaded: false, user_is: '', user_may: '' };
    } else {
      const mode = this.mode;
      if (mode === 'napterAuditLogs' || mode === 'testNapterAuditLogs') {
        return this.hasNapterContract
          ? LogViewerSubscriptionStatusComponent._subscribedStringsNapter
          : LogViewerSubscriptionStatusComponent._unsubscribedStringsNapter;
      } else if (mode === 'auditLogs' || mode === 'testAuditLogs') {
        return this.hasAuditLogsEnterpriseContract
          ? LogViewerSubscriptionStatusComponent._subscribedStringsAuditLogsEnterprise
          : LogViewerSubscriptionStatusComponent._unsubscribedStringsAuditLogsEnterprise;
      }
    }
  }

  // @mason: dumb enough for ya?
  // Mason 2021-06-08: NO, lol! Needs to get way dumber...
  private static _subscribedStringsNapter: SubscriptionTextPair = {
    hasLoaded: true,
    user_is: 'SoracomLogs.napter_sub_status.subscribed.description',
    user_may: 'SoracomLogs.napter_sub_status.subscribed.openModal',
  };

  private static _unsubscribedStringsNapter: SubscriptionTextPair = {
    hasLoaded: true,
    user_is: 'SoracomLogs.napter_sub_status.unsubscribed.description',
    user_may: 'SoracomLogs.napter_sub_status.unsubscribed.openModal',
  };

  private static _subscribedStringsAuditLogsEnterprise: SubscriptionTextPair = {
    hasLoaded: true,
    user_is: 'SoracomLogs.audit_logs_enterprise_sub_status.subscribed.description',
    user_may: 'SoracomLogs.audit_logs_enterprise_sub_status.subscribed.openModal',
  };

  private static _unsubscribedStringsAuditLogsEnterprise: SubscriptionTextPair = {
    hasLoaded: true,
    user_is: 'SoracomLogs.audit_logs_enterprise_sub_status.unsubscribed.description',
    user_may: 'SoracomLogs.audit_logs_enterprise_sub_status.unsubscribed.openModal',
  };
}
