export const USER_CANCEL = 'UserCancel' as const;
export const API_REQUEST_LIMIT = 'APIRequestLimit' as const;
export const API_ERROR = 'APIError' as const;
export const REACH_SIZE_LIMIT = 'ReachSizeLimit' as const;

export const HARVEST_DOWNLOAD_ERR_CODE = [USER_CANCEL, API_REQUEST_LIMIT, API_ERROR, REACH_SIZE_LIMIT] as const;

export type HarvestDownloadErrorCode = typeof HARVEST_DOWNLOAD_ERR_CODE[number];
export const isHarvestDownloadErrorCode = (type: string): type is HarvestDownloadErrorCode =>
  HARVEST_DOWNLOAD_ERR_CODE.some((t) => t === type);
