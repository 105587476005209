import { LegacyAny } from '@soracom/shared/core';

import { LteMButtonConfig } from '../../shared/lte_m_button/LteMButtonConfig';

import { Group } from '@soracom/shared/group';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { initialModelCollectionState, initialModelState, ModelCollectionState, ModelState } from '../root-state';

export type CreateGroupParam = Pick<Group, 'name'>;

export type GroupOrGroupParam = Group | CreateGroupParam;
export const isGroup = (v: GroupOrGroupParam): v is Group => !!(v as LegacyAny)['id'];

export type LteMButtonPageState =
  | 'index'
  | 'newConfig'
  | 'selectSubscribers'
  | 'selectGroup'
  | 'editConfig'
  | 'editGroupConfig'
  | 'linkToHarvestData'
  | 'removeLteMButtonConfig'
  | 'unknown';

export interface LteMButtonPageRouteState {
  status: LteMButtonPageState;
  imsiList: string[];
  groupId: string | null;
  newGroupName: string | null;
}

export interface LteMButtonIndexPageState {
  loadingRequired: boolean;
}

export interface LteMButtonNewPageState {
  availableSubscribers: {
    error: Error | string | null;
  };
  selectedSubscribers: ModelCollectionState<ExtendedSubscriberInterface>;
}

export interface LteMButtonEditPageState {
  config: LteMButtonConfig | null;
  group: ModelState<GroupOrGroupParam> | null;
}

export interface State {
  routing: LteMButtonPageRouteState;
  index: LteMButtonIndexPageState;
  new: LteMButtonNewPageState;
  edit: LteMButtonEditPageState;
}

export const initialIndexPageState: LteMButtonIndexPageState = {
  loadingRequired: true,
};

export const initialNewPageState: LteMButtonNewPageState = {
  availableSubscribers: {
    error: null,
  },
  selectedSubscribers: {
    ...initialModelCollectionState,
  },
};

export const initialEditPageState: LteMButtonEditPageState = {
  config: null,
  group: {
    ...initialModelState,
  },
};

export const initialRoutingState: LteMButtonPageRouteState = {
  status: 'index',
  groupId: null,
  imsiList: [],
  newGroupName: null,
};

export const initialState: State = {
  routing: initialRoutingState,
  index: initialIndexPageState,
  new: initialNewPageState,
  edit: initialEditPageState,
};
