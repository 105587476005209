import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { template } from './prefixed_event_handlers.component.html';

import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';
import { TargetType } from '../event_handlers/event_handler';
import { EventHandlersService } from '../event_handlers/event_handlers.service';
import { NavigationInterceptionService } from '../main/navigation_interception.service';
import { PrefixedEventHandlerViewModelCollection } from './prefixed_event_handler';
import { PrefixedEventHandlersService } from './prefixed_event_handlers.service';

export class PrefixedEventHandlersComponent implements ng.IComponentOptions {
  bindings = {
    groupId: '<?',
    target: '@',
    readonly: '<',
  };

  controller = PrefixedEventHandlersComponentController;
  template: any = template;
}

export class PrefixedEventHandlersComponentController extends BaseController {
  static $inject: InjectList = [
    '$log',
    '$q',
    '$scope',
    'AlertsService',
    'EventHandler',
    'NavigationInterceptionService',
    'PrefixedEventHandler',
  ];

  alertsService: AlertsServiceInstance;
  // @ts-expect-error (legacy code incremental fix)
  settings: PrefixedEventHandlerViewModelCollection;
  nowLoading = true;
  submitting = false;
  positiveInteger = /^[0-9]*$/;
  prefixedEventHandlers: any[] = [];
  // @ts-expect-error (legacy code incremental fix)
  groupId: string;
  // @ts-expect-error (legacy code incremental fix)
  readonly: boolean;
  // @ts-expect-error (legacy code incremental fix)
  target: TargetType;
  // @ts-expect-error (legacy code incremental fix)
  original: PrefixedEventHandlerViewModelCollection;
  // @ts-expect-error (legacy code incremental fix)
  prefixedEventHandlersForm: ng.IFormController;
  unsavedChangesCheck = () => {
    if (angular.equals(this.original, this.settings)) {
      return null;
    } else {
      return 'Unsaved changes to Event Handler settings exist.';
    }
  };

  constructor(
    $log: ng.ILogService,
    private $q: ng.IQService,
    private $scope: ng.IScope,
    alertsServiceGenerator: AlertsService,
    private eventHandlersService: EventHandlersService,
    private navigationInterceptionService: NavigationInterceptionService,
    private prefixedEventHandlersService: PrefixedEventHandlersService
  ) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    this.prefixedEventHandlers = [];
    this.fetchSettings();
  }

  $onChanges(onChangesObj: ng.IOnChangesObject) {
    if (onChangesObj.groupId && onChangesObj.groupId.currentValue) {
      this.fetchSettings();
    }
  }

  fetchSettings() {
    const d = this.$q.defer();
    this.nowLoading = true;
    this.eventHandlersService
      .fetch(this.target)
      .then((data) => {
        this.prefixedEventHandlers = data;
        this.settings = this.prefixedEventHandlersService.parseEventHandlers(data, this.target, this.groupId);

        this.original = angular.copy(this.settings);
        // Mason 2016-07-26: Added this for unsaved change detection; it's similar to what GroupAirParamsDirective does.
      })
      .catch((response: LegacyAny) => {
        this.alertsService.showError(response.data);
      })
      .finally(() => {
        this.nowLoading = false;
        d.resolve();
      });

    this.navigationInterceptionService.registerNavigationInterceptor(
      'PrefixedEventHandlersComponent',
      this.$scope,
      this.unsavedChangesCheck
    );

    return d.promise;
  }

  updateSettings() {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    const targetId = this.target === 'group' ? this.groupId : null;
    this.prefixedEventHandlersService
      // @ts-expect-error (legacy code incremental fix)
      .updateSettings(this.settings, this.prefixedEventHandlers, this.target, targetId)
      .then(() => {
        this.submitting = false;
        this.alertsService.showSuccess('prefixed_event_handlers.messages.saved');
        return this.fetchSettings();
      })
      .catch((response: LegacyAny) => {
        this.submitting = false;
        this.alertsService.showError(response);
      });
  }

  canSubmit(): boolean {
    return !this.submitting && this.prefixedEventHandlersForm.$valid && this.isDirty();
  }

  isDirty(): boolean {
    return this.unsavedChangesCheck() !== null;
  }
}
