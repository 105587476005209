<div class="ds-button-bar">
  <button
    (click)="copyText.emit()"
    class="ds-button--plain ds-button--hide-label ds-button--icon-clipboard-plain-text copy-as-text"
    [disabled]="copyButtonsShouldBeDisabled"
  >
    <span>{{ 'SoracomLogs.button.copyAsText' | translate }}</span>
  </button>

  <button
    (click)="copyCsv.emit()"
    class="ds-button--plain ds-button--hide-label ds-button--icon-clipboard-csv copy-as-csv"
    [disabled]="copyButtonsShouldBeDisabled"
  >
    <span>{{ 'SoracomLogs.button.copyAsCSV' | translate }}</span>
  </button>

  <button
    (click)="copyJson.emit()"
    class="ds-button--plain ds-button--hide-label ds-button--icon-clipboard-json copy-as-json"
    [disabled]="copyButtonsShouldBeDisabled"
  >
    <span>{{ 'SoracomLogs.button.copyAsJSON' | translate }}</span>
  </button>

  <button
    *ngIf="shouldShowRequestDownloadLink"
    class="ds-button--plain ds-button--icon-download x-download-logs"
    (click)="requestDownloadLink.emit()"
  >
    <span>{{ 'SoracomLogs.button.downloadLogs' | translate }}</span>
  </button>
</div>
