import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { GMNull } from './GpsMultiunitConfigData';

dayjs.extend(customParseFormat);

export class GMDate {
  static parse(val: string): GMDate | GMNull {
    if (!val || val === 'null') {
      return 'null';
    } else {
      const gmDate = new GMDate(val);
      if (gmDate.isValid()) {
        return gmDate;
      } else {
        return 'null';
      }
    }
  }

  private _dayjs: dayjs.Dayjs;

  get year(): number {
    return this._dayjs.year();
  }

  set year(val: number) {
    this._dayjs = this._dayjs.year(val);
  }

  get month(): number {
    return this._dayjs.month() + 1;
  }

  set month(val: number) {
    this._dayjs = this._dayjs.month(val - 1);
  }

  get date(): number {
    return this._dayjs.date();
  }

  set date(val: number) {
    this._dayjs = this._dayjs.date(val);
  }

  get dayjs(): dayjs.Dayjs {
    return dayjs(this._dayjs);
  }

  private constructor(val: string) {
    this._dayjs = dayjs(val, 'YYYY/MM/DD');
  }

  isValid() {
    return this._dayjs.isValid();
  }

  toString(delimiter = '/') {
    return this._dayjs.format(`YYYY${delimiter}MM${delimiter}DD`);
  }
}
