import { LegacyAny } from '@soracom/shared/core';

import { BaseModalController } from '../../components/base_modal_controller';
import { InjectList } from '../../core/injectable';
import { SigfoxDevice } from '../../core/sigfox_device';
import { template } from './sigfox-show-pac.component.html';

import Clipboard from 'clipboard';

interface SigfoxShowPacComponentModalParams {
  devices: SigfoxDevice[];
}

export class SigfoxShowPacComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = SigfoxShowPacComponentController;
  template: any = template;
}

class SigfoxShowPacComponentController extends BaseModalController {
  static $inject: InjectList = ['$log', '$rootScope'];

  // @ts-expect-error (legacy code incremental fix)
  devices: SigfoxDevice[];

  // @ts-expect-error (legacy code incremental fix)
  clipboard: Clipboard;

  // @ts-expect-error (legacy code incremental fix)
  copyDate: Date = null;

  constructor($log: ng.ILogService, private $rootScope: ng.IRootScopeService) {
    super($log);
  }

  $onInit() {
    this.setTraceEnabled(true);
    this.trace('$onInit()', self);

    this.clipboard = new Clipboard('button');
    this.clipboard.on('success', (e) => {
      this.trace('Action:', e.action);
      this.trace('Text:', e.text);
      this.trace('Trigger:', e.trigger);

      if (e.action === 'copy') {
        this.trace('Action: COPY');
        this.copyDate = new Date();
        this.$rootScope.$digest();
      }
    });

    this.devices = (this.params() as SigfoxShowPacComponentModalParams).devices;
    // Mason 2018-08-03: FIXME: I wanna do something like this but with actual type safety, not fake type safety (e.g. no "as")

    this.devices = this.devices.filter((d: LegacyAny) => !!d.pac || !!d.deviceId.startsWith('6'));
  }

  confirm() {
    this.trace('confirm()', self);
    this.close('close');
  }

  canConfirm() {
    return true;
  }

  cancel() {
    this.trace('cancel()', this);
    this.dismiss();
  }

  onReturn() {
    this.confirm();
  }

  clipboardText() {
    let result = 'Device ID: PAC\n';

    this.devices.forEach((device) => {
      result += `${device.deviceId}: ${device.pac || '(no PAC available)'}\n`;
    });

    return result;
  }

  get copyButtonTitleId() {
    return this.devices.length === 1 ? 'sigfox-show-pac.label.copyPAC' : 'sigfox-show-pac.label.copyPACs';
  }

  get showCopiedSuccessfully() {
    return this.copyDate !== null;
  }
}
