<div class="ds-cols--small">
  <button class="ds-button --primary --alert" [disabled]="!canDelete" appConfirm="deletion" (onConfirm)="onDelete()">
    {{ 'HarvestData.delete_action_button.title' | translate }}
  </button>
  <ui-table-setting-button [columns]="columnDefs"></ui-table-setting-button>
</div>
<ui-generic-table
  class="harvest-data-table"
  [content]="tableCtrl"
  (selectionChanged)="onTableItemSelectionChanged($event)"
></ui-generic-table>
