import { LegacyAny } from '@soracom/shared/core';

import { template } from './role_add.modal.component.html';

import { AlertsService, AlertsServiceInstance } from '../../components/alerts.service';
import { BaseModalController } from '../../components/base_modal_controller';
import { InjectList } from '../../core/injectable';
import { RolesService } from './roles.service';

export class RoleAddModalComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };

  controller = RoleAddModalComponentController;
  template: any = template;
}

export class RoleAddModalComponentController extends BaseModalController {
  static $inject: InjectList = ['$log', '$scope', 'AlertsService', 'RolesService'];

  alertsService: AlertsServiceInstance;
  roleNamePattern = /^[A-Za-z0-9_\-]{1,50}$/;
  // @ts-expect-error (legacy code incremental fix)
  roleAddForm: ng.IFormController;
  // @ts-expect-error (legacy code incremental fix)
  roleName: string;
  // @ts-expect-error (legacy code incremental fix)
  roleDescription: string;
  // @ts-expect-error (legacy code incremental fix)
  rolePermissions: string;
  submitting = false;

  constructor(
    $log: ng.ILogService,
    private $scope: LegacyAny,
    alertsServiceGenerator: AlertsService,
    private rolesService: RolesService
  ) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    this.roleName = '';
    this.roleDescription = '';
    this.rolePermissions = '';
    this.submitting = false;
  }

  onDataChanges = (data: string) => {
    // there wont be any data if json is invalid
    this.rolePermissions = data;
    this.$scope.$applyAsync();
  };

  submit() {
    if (!this.canSubmit()) {
      return;
    }
    this.alertsService.clearAll();

    const roleParams = {
      description: this.roleDescription,
      permission: this.rolePermissions,
    };
    this.submitting = true;
    this.rolesService
      .create(this.roleName, roleParams)
      .then(() => {
        this.close();
      })
      .catch((response: LegacyAny) => {
        this.alertsService.showError(response.data);
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  canSubmit() {
    return !this.submitting && this.roleAddForm.$valid && this.rolePermissions;
  }
}
