import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroupOf } from '@soracom/shared-ng/util-common';
import { LagoonUser } from '../../../../app/shared/core/lagoon_user';
import { LagoonUserEmailUpdatingRequest, LagoonUsersService } from '../../../../app/shared/lagoon/lagoon_users.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-update-lagoon-user-email-modal',
  templateUrl: './update-lagoon-user-email-modal.component.html',
})
export class UpdateLagoonUserEmailModalComponent
  extends DsModalContentBase<LagoonUser, { success: true }>
  implements OnInit
{
  // @ts-expect-error (legacy code incremental fix)
  output: LagoonUser;
  alertsManager = new AlertsManager();
  get canConfirm(): boolean {
    return this.form.valid && this.form.dirty;
  }
  // @ts-expect-error (legacy code incremental fix)
  form: FormGroupOf<LagoonUserEmailUpdatingRequest> = this.fb.group({
    userEmail: ['', [Validators.required, Validators.email]],
  });

  isLoading = false;

  constructor(private fb: FormBuilder, private lagoonUsersService: LagoonUsersService) {
    super();
  }

  ngOnInit(): void {
    this.form.patchValue({ userEmail: this.getInput().email });
  }

  confirm(): void {
    if (this.canConfirm) {
      const req = this.form.value as LagoonUserEmailUpdatingRequest;
      this.alertsManager.clear();
      this.isLoading = true;
      this.lagoonUsersService
        .updateEmail(this.getInput().id, req)
        .then(() => {
          this.isLoading = false;
          this.close({ success: true });
        })
        .catch((error: LegacyAny) => {
          this.alertsManager.add(Alert.fromApiError(error));
          this.isLoading = false;
        });
    }
  }
  canClose(): boolean {
    return !this.isLoading;
  }
}
