import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { Alert, AlertType } from '@soracom/shared-ng/soracom-ui-legacy';
import { ApiErrorCode } from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';

declare global {
  interface Window {
    soracom_user_console_debug_feedback_duration: any;
    // Mason 2019-05-29: FIXME: move this feature to the new SoracomUserConsole singleton, where we now want to consolidate all this kind of "debug functionality that is exposed to tests/developers via the Window object" stuff.
  }
}

// The error codes when the api request failed by SAM permission insufficiency
const PERMISSION_ERROR_CODES: string[] = [ApiErrorCode.SEM0049, ApiErrorCode.COM0017];

interface AlertMessage {
  /**
   * id will be added in AlertsService. You don't have to set a value when adding an alert message.
   */
  id?: number;
  /**
   * Possible values are 'success', 'info', 'warning' and 'danger'.
   * https://getbootstrap.com/docs/3.3/components/#alerts
   */
  type: AlertType;
  text?: string;
  translationId?: string;
  values?: any;
}

/**
 * Convert a more modern object to the legacy AlertMessage format used by the legacy AngularJS AlertsService.
 *
 *  This is added so that we can gradually migrate away from this old service, and eventually delete it. New code can use Alert, even if it still has to interact with this old service for the time being, and not have to deal with fragile old untyped whatever-it-is that `error` is used by showError().
 */
const convertToAlertMessage = (alert: Alert): AlertMessage => {
  const result: AlertMessage = {
    type: alert.type,
  };
  if (alert.textContent.translatable) {
    result.translationId = alert.textContent.translatable.id;
    result.values = alert.textContent.translatable.parameters;
  } else {
    result.text = alert.textContent.text;
  }
  return result;
};

export class AlertsService {
  static $inject: InjectList = ['$interval'];

  constructor(private $interval: ng.IIntervalService) {}

  // Utility method for compatibility
  generate(): AlertsServiceInstance {
    return new AlertsServiceInstance(this.$interval);
  }
}

export class AlertsServiceInstance {
  alerts: AlertMessage[] = [];
  private lastAlertId = 0;

  constructor(private $interval: ng.IIntervalService) {}

  addAlert(alert: AlertMessage) {
    const win: any = window;
    let showSuccessAlertDuration = win.soracom_user_console_debug_feedback_duration;

    if (showSuccessAlertDuration === undefined || showSuccessAlertDuration === null) {
      showSuccessAlertDuration = 5000;
    }

    alert.id = ++this.lastAlertId;
    this.alerts.push(alert);

    if (alert.type === 'success') {
      this.$interval(
        () => {
          // @ts-expect-error (legacy code incremental fix)
          this.closeAlertById(alert.id);
        },
        showSuccessAlertDuration,
        1,
      );
    }
    return alert.id;
  }

  showAlert(alertType: AlertType, text: string | { translationId: string; values?: any }): number {
    const message: AlertMessage = {
      type: alertType,
    };

    if (typeof text === 'string') {
      message.text = text;
    } else {
      message.translationId = text.translationId;
      message.values = text.values;
    }

    return this.addAlert(message);
  }

  closeAlertById(id: number): void {
    angular.forEach(this.alerts, (alert, index) => {
      if (alert.id === id) {
        this.closeAlertAt(index);
      }
    });
  }

  updateAlert(id: number, type: AlertType, text: string | { translationId: string; values?: any }): number {
    if (id === undefined || id === null) {
      return this.showAlert(type, text);
    }

    const alert = this.findAlert(id);
    if (alert === undefined || alert === null) {
      return this.showAlert(type, text);
    }

    alert.type = type;
    if (typeof text === 'string') {
      alert.text = text;
    } else {
      alert.translationId = text.translationId;
      alert.values = text.values;
    }
    // @ts-expect-error (legacy code incremental fix)
    return alert.id;
  }

  findAlert(id: number): AlertMessage {
    let result = null;
    angular.forEach(this.alerts, (alert) => {
      if (alert.id === id) {
        result = alert;
      }
    });
    // @ts-expect-error (legacy code incremental fix)
    return result;
  }

  closeAlertAt(index: number): void {
    this.alerts.splice(index, 1);
  }

  showSuccess(translationId: string, values?: any): number {
    return this.showAlert('success', { translationId, values });
  }

  /**
   * Show success message.
   *
   * @param translationId
   * @param values
   * @deprecated Use show Success method instead.
   */
  showInfo(translationId: string, values?: any): number {
    return this.showSuccess(translationId, values);
  }

  /**
   * Show an error message, with flexibility[1] about what type of error object is passed in.
   *
   * [1]: This old method predates `Alert`, and really `Alert` (or some new cleaned-up replacement for `Alert`) should be the one to accept various input types, and convert them to `Alert`). But, for historical reasons, this exists and is widely used, so we'll keep it around for now.
   * @param error
   * @returns
   */
  showError(error: Alert | string | Error | { data: Error; config: LegacyAny } | any): number {
    // If it is an Alert, then just convert it to the legacy format and show it.
    // (We don't have to do this for other methods in this legacy class, because they don't take `any` and so are unlikely to be passed an `Alert`.)
    if (error instanceof Alert) {
      const converted = convertToAlertMessage(error);
      return this.addAlert(converted);
    }

    const alert: AlertMessage = {
      type: 'danger',
    };
    if (error === null || error === undefined) {
      alert.translationId = 'common.error_messages.unknown_error';
    } else if (typeof error === 'string') {
      if (error.length > 0) {
        alert.text = error;
      } else {
        alert.translationId = 'common.error_messages.unknown_error';
      }
    } else if (error.status && error.status === -1) {
      alert.translationId = 'common.error_messages.blocked_by_client_filter';
    } else if (error.code && error.msg) {
      // This allows `myAlertsService.showError(response.data)` to work, since we do in fact do that in at least some places:
      return this.showError(`${error.msg} (${error.code})`);
    } else if (error.code && error.message) {
      // This allows `myAlertsService.showError(response.data)` to work, since we do in fact do that in at least some places:
      return this.showError(`${error.message} (${error.code})`);
    } else if (error instanceof Error || error.message) {
      if (error.message) {
        alert.text = error.message;
      } else {
        alert.translationId = 'common.error_messages.unknown_error';
      }
    } else if (error.data) {
      // This pattern is for HTTP response error.
      const errorObj = error.data;
      const specifiedUrl = error.config?.url || error.url;
      if (errorObj.code && PERMISSION_ERROR_CODES.includes(errorObj.code)) {
        const path = new URL(specifiedUrl).pathname;
        return this.showError(`${errorObj.message} ${path} (${errorObj.code})`);
      } else {
        return this.showError(errorObj);
      }
    } else {
      alert.translationId = 'common.error_messages.unknown_error';
    }

    return this.addAlert(alert);
  }

  clearAll(): void {
    this.alerts.splice(0, this.alerts.length);
  }
}
