let hasGoogleTagManagerCodeBeenInjected = false;

/**
 * Injects the Google Tag Manager code into the DOM. Because in Angular (and maybe other things), the index.html file is not directly editable, this function must be called during the app load process.
 *
 * Injecting this code will set up GTM to do analytics, per the `GTM_ID` that you provide.
 *
 * WARNING: Google does not provide any method to unload their code, so **this method may only be called once**. If you call it more than once, it will throw an error. (If it becomes needed, we could fix this...)
 *
 * @param GTM_ID The Google Tag Manager ID, e.g. `GTM-ABC123`. Normally, this comes from the runtime configuration (e.g., in Angular, the `environment.ts` file).
 */
export const injectGoogleTagManagerCode = (GTM_ID: string) => {
  if (!GTM_ID) {
    return false;
  }

  if (hasGoogleTagManagerCodeBeenInjected) {
    throw new Error(
      'injectGoogleTagManagerCode(): this function may only be called once, and it has already been called;'
    );
  }

  const body = document.body;

  // Add the noscript tag for non-JS browsers
  const noscriptElement = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscriptElement.appendChild(iframe);
  body.insertBefore(noscriptElement, body.lastChild);
  // Add the script tag with a 5-second delay
  const script = document.createElement('script');
  script.innerHTML = `
  function loadGTM(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  };
  setTimeout(loadGTM.bind(null, window, document, 'script', 'dataLayer', '${GTM_ID}'), 5000);
`;

  hasGoogleTagManagerCodeBeenInjected = true;
  // Add the script tag
  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = `
    function loadGTM(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    };
    setTimeout(loadGTM.bind(null,window,document,'script','dataLayer', '${GTM_ID}'), 5000);
  `;
  body.insertBefore(scriptElement, body.lastChild);

  return true;
};
