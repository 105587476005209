import { inject } from '@angular/core';
import {
  CanActivateFn,
  createUrlTreeFromSnapshot,
  Router
} from '@angular/router';
import { normalizeCoverageType } from '@foundation/coverage-type';
import {
  AuthService,
  CoverageTypeService
} from '@soracom/shared/data-access-auth';
import { COVERAGE_TYPE_QUERY_PARAM_KEY } from './coverage-type-query-param';

/**
 * A router guard which should put for routes depends on coverage type.
 * This guard determines the coverage_type to visit, based on query parameter, application state and history.
 * In addition, it also redirects to the /tos if user has not accepted the terms-of-service for the coverage type to use
 *
 * FIXME: add tos bouncer
 * TODO: implement this functionality for non-Angular apps
 * @returns
 */
export const CoverageTypeGuard: CanActivateFn = async (
  routeSnapshot,
  state
) => {
  const auth = inject(AuthService);
  const ctSvc = inject(CoverageTypeService);
  const router = inject(Router);

  // Router guards in a same "Route" will be invoked concurrently,
  // so we make sure that authentication is finished here
  // Otherwise, if auth is not yet done, coverageType is not set even if user successfully logs in
  await auth.init();

  const coverageTypeInQueryParam = normalizeCoverageType(
    routeSnapshot.queryParamMap.get(COVERAGE_TYPE_QUERY_PARAM_KEY)
  );

  // If coverage_type is specified in URL and it's different to current coverage_type, then change it accordingly
  // TODO: If the specified url is not available, show a "notice" to explain it
  if (
    coverageTypeInQueryParam &&
    coverageTypeInQueryParam !== ctSvc.getCoverageType() &&
    ctSvc.getAvailableCoverageTypes().includes(coverageTypeInQueryParam)
  ) {
    ctSvc.setCoverageType(coverageTypeInQueryParam);
  }

  // If coverage_type is not set in URL, set it
  if (
    !coverageTypeInQueryParam ||
    coverageTypeInQueryParam !== ctSvc.getCoverageType()
  ) {
    return createUrlTreeFromSnapshot(
      routeSnapshot,
      [],
      {
        ...routeSnapshot.queryParams,
        [COVERAGE_TYPE_QUERY_PARAM_KEY]: ctSvc.getCoverageType(),
      },
      routeSnapshot.fragment
    );
  }

  // Here, the coverageType to visit is determined
  // Now we check if user has already accepted the term-of-service for the coverage type
  const coverageType = ctSvc.getCoverageType();

  if (!coverageType) {
    return false; // unexpected case
  }

  if (!ctSvc.getToSAcceptedCoverageTypes().includes(coverageType)) {
    // if not, redirect to the terms-of-service page
    return router.createUrlTree(['/tos'], {
      queryParams: {
        [COVERAGE_TYPE_QUERY_PARAM_KEY]: coverageType,
        redirect: state.url,
      },
    });
  }

  return true;
};
