export const template = `<app-user-notifications></app-user-notifications>
<div id="page-wrapper" translate-namespace="feature_visibility">
  <div class="row block">
    <div class="col-sm-6 col-sm-offset-3">
      <h1 translate=".title"></h1>
      <p style="margin-top: 30px; margin-bottom: 30px;" translate=".caption"></p>
      <ul style="margin-top: 30px; margin-bottom: 30px;">
        <li translate=".reason.coverage_type"></li>
        <li translate=".reason.user_permission"></li>
      </ul><a class="btn btn-lg btn-primary" ng-href="{{ $ctrl.returnPath }}" translate=".back"></a>
    </div>
  </div>
</div>`;
