import { LegacyAny } from '@soracom/shared/core';

import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormGroupOf } from '@soracom/shared-ng/util-common';
import { LagoonUser } from '../../../../app/shared/core/lagoon_user';
import {
  LagoonUserPasswordUpdatingRequest,
  LagoonUsersService,
} from '../../../../app/shared/lagoon/lagoon_users.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiInput } from '../../soracom-ui/ui-input/UiInput';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-update-lagoon-user-password-modal',
  templateUrl: './update-lagoon-user-password-modal.component.html',
})
export class UpdateLagoonUserPasswordModalComponent extends DsModalContentBase<LagoonUser, { success: boolean }> {
  alertsManager = new AlertsManager();
  get canConfirm(): boolean {
    return this.form.valid && !this.isLoading;
  }
  // @ts-expect-error (legacy code incremental fix)
  form: FormGroupOf<Omit<LagoonUserPasswordUpdatingRequest, 'userEmail'>> = this.fb.group({
    oldPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required]],
  });

  currentPasswordInputContent: UiInput;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private lagoonUsersService: LagoonUsersService,
    private translateService: TranslateService
  ) {
    super();
    this.currentPasswordInputContent = UiInput.configure((content) => {
      content.autocomplete = 'new-password';
      content.label = LegacyTextContent.translation('UpdateLagoonUserPasswordModalComponent.old_password');
      content.id = 'lagoon-user-old-password';
      content.type = 'password';
      // @ts-expect-error (legacy code incremental fix)
      content.formControl = this.form.get('oldPassword');
      content.enableViewPasswordToggle = true;
      content.required = true;
      content.placeholder_ƒ = this.getTranslate_ƒ('old_password_placeholder');
    });
  }

  private getTranslate_ƒ(id: string) {
    return this.translateService.instant(`UpdateLagoonUserPasswordModalComponent.${id}`);
  }

  onPasswordChanged(newPassword: string) {
    this.form.patchValue({ newPassword });
  }

  confirm() {
    if (this.canConfirm) {
      const req = {
        ...this.form.value,
        userEmail: this.getInput().email, // TODO: It can be omitted after v2 release
      } as LagoonUserPasswordUpdatingRequest;
      this.alertsManager.clear();
      this.isLoading = true;
      this.lagoonUsersService
        .updatePassword(this.getInput().id, req)
        .then((lagoonUser: LegacyAny) => {
          this.isLoading = false;
          this.close({ success: true });
        })
        .catch((error: LegacyAny) => {
          this.alertsManager.add(Alert.fromApiError(error));
          this.isLoading = false;
        });
    }
  }

  canClose() {
    return !this.isLoading;
  }
}
