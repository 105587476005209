import { kebabCase, snakeCase } from 'lodash-es';
import { template } from './base.component.html';
import { LegacyOptional } from '@soracom/shared/core';

export class BaseComponent implements ng.IComponentOptions {
  bindings = {
    breadcrumb: '@?',
    name: '@',
    pageWrapperClassName: '@?',
    path: '@?',
  };

  controller = BaseComponentController;
  template: any = template;
  transclude = true;
}

export class BaseComponentController implements ng.IOnInit {
  breadcrumb: LegacyOptional<string>;
  name: LegacyOptional<string>;
  pageWrapperClassName: LegacyOptional<string>;
  path: LegacyOptional<string>;
  wrapDsRows = false;
  pageWrapperStyle = {};

  $onInit() {
    const kebabCased = kebabCase(this.name);
    const snakeCased = snakeCase(this.name);
    this.pageWrapperClassName = this.pageWrapperClassName || `x-${kebabCased}-page-root`;
    if (this.breadcrumb) {
      this.wrapDsRows = true;
      this.path = this.path || `/${snakeCased}`;
    } else {
      this.pageWrapperStyle = { padding: 0 };
    }
  }
}
