<div class="ds-cols --6 --top">
  <ul class="ds-list --lined">
    @if (discount.contractId) {
      <li>
        <strong>{{ 'long_term_discount.labels.id' | translate }}: </strong>
        <span data-testid="ltd-contract-id">{{ discount.contractId }}</span>
      </li>
    }
  
    <li>
      <strong>{{ 'long_term_discount.labels.volume_discount_type' | translate }}: </strong>
      <span data-testid="ltd-type">{{ 'long_term_discount.discount_type.' + discount.volumeDiscountType | translate }}</span>
    </li>
    
    <li>
      <strong>{{ 'long_term_discount.labels.payment_type' | translate }}: </strong>
      <span data-testid="ltd-payment-type">{{ 'long_term_discount.payment_type.' + discount.volumeDiscountPaymentType | translate }}</span>
    </li>
    
    @if (discount.taxIncludedInitialCost && discount.taxIncludedInitialCost !== 0) {
      <li>
        <strong>{{ 'long_term_discount.labels.initial_cost' | translate }}: </strong>
        <span data-testid="ltd-initial-cost">{{ discount.taxIncludedInitialCost | currency: currency:'symbol':'.0-2' }}</span>
      </li>
    }

    @if (totalAmount) {
      <li>
        <strong>{{ 'long_term_discount.labels.total_amount' | translate }}: </strong>
        <span data-testid="ltd-total-amount">{{ totalAmount | currency: currency }}</span>
      </li>
    }
  </ul>

  <ul class="ds-list --lined">
    @if (discount.status) {
      <li>
        <strong>{{ 'long_term_discount.labels.status' | translate }}: </strong>
        <span data-testid="ltd-status">{{ 'long_term_discount.status.' + discount.status | translate }}</span>
      </li>
    }

    <li>
      <strong>{{ 'long_term_discount.labels.duration' | translate }}: </strong>
      <span data-testid="ltd-duration-summary">{{ humanReadableDuration }} ({{ discount.contractTermMonth }}{{ 'long_term_discount.duration_in_months_suffix' | translate }})</span>
    </li>

    @if (discount.taxIncludedUnitPrice) {
      <li>
        <strong>{{ 'long_term_discount.labels.unit_price' | translate }}: </strong>
        <span data-testid="ltd-unit-price">{{ discount.taxIncludedUnitPrice | currency: currency:'symbol':'.0-2' }}</span>
      </li>
    }

    <li>
      <strong>{{ 'long_term_discount.labels.number_of_sims' | translate }}: </strong>
      <span data-testid="ltd-number-of-sims">{{ discount.quantity }}</span>
    </li>
  </ul>
</div>
