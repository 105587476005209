export const template = `<app-user-notifications></app-user-notifications>
<div id="page-wrapper" translate-namespace="devices">
  <div class="ds-rows block"></div>
  <div class="row block alerts-wrapper">
    <div class="col-sm-12"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  </div>
  <div class="row block" translate-namespace="devices">
    <div class="col-md-6 col-xs-12">
      <div class="form-horizontal">
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.device_id"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static" ng-bind="$ctrl.device.deviceId"></div>
          </div>
        </div>
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.name"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static">{{ $ctrl.device.tags.name }}</div>
          </div>
        </div>
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.group"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static"><a ng-href="/inventory_device_groups/{{ $ctrl.device.groupId }}">{{ $ctrl.groupName()
                }}</a></div>
          </div>
        </div>
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.status"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static">{{ $ctrl.statusText() }}</div>
          </div>
        </div>
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.endpoint"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static" ng-bind="$ctrl.device.endpoint"></div>
          </div>
        </div>
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.online"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static"><sc-session-status-label is-online="$ctrl.device.online"
                align-left="true"></sc-session-status-label></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="form-horizontal">
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.manufacturer"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static" ng-bind="$ctrl.device.manufacturer"></div>
          </div>
        </div>
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.model_number"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static" ng-bind="$ctrl.device.modelNumber"></div>
          </div>
        </div>
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.serial_number"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static" ng-bind="$ctrl.device.serialNumber"></div>
          </div>
        </div>
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.last_modified_time"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static"><span sc-date-time="$ctrl.device.lastModifiedTime"></span></div>
          </div>
        </div>
        <div class="form-group"><label
            class="col-sm-3 col-sm-offset-3 col-sm-offset-0 col-xs-11 col-xs-offset-1 control-label"
            translate=".columns.last_registration_update"></label>
          <div class="col-sm-6 col-sm-offset-0 col-xs-11 col-xs-offset-1">
            <div class="form-control-static"><span sc-date-time="$ctrl.device.lastRegistrationUpdate"></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12"><uib-tabset active="$ctrl.activeTab"><uib-tab index="0"
          heading="{{ 'devices.basic_settings' | translate }}">
          <div class="row device-page-detail">
            <div class="col-sm-12 object" ng-repeat="(objectKey, object) in $ctrl.device.objects">
              <div class="object-info row" ng-if="$ctrl.hasInstances(objectKey)">
                <div class="object-key col-sm-3 col-xs-9"><span ng-bind="object.name"></span></div>
                <div class="lwm2m-path col-sm-2 col-xs-3" ng-bind="$ctrl.getResourcePath(objectKey)"></div>
                <div class="controls col-sm-2 col-sm-offset-5 col-xs-12 col-xs-offset-0">
                  <div class="btn-group btn-group-sm" role="group"><button class="btn btn-default"
                      sc-update-feedback="sc-update-feedback" title="{{ device.actions.read | translate }}"
                      feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                      feedback-type="$ctrl.device.feedbackRefresh" ng-click="$ctrl.refresh()"
                      ng-disabled="$ctrl.isLoading"><i class="glyphicon glyphicon-refresh"></i></button></div>
                </div>
              </div>
              <div class="row">
                <div class="instance col-sm-12" ng-repeat="(instanceKey, instance) in $ctrl.getInstances(objectKey)">
                  <div class="instance-info row">
                    <div class="instance-key col-sm-3 col-xs-9">
                      <div class="device-label" translate=".instance_key"></div>
                    </div>
                    <div class="lwm2m-path col-sm-2 col-xs-3" ng-bind="$ctrl.getResourcePath(objectKey, instanceKey)">
                    </div>
                    <div class="controls col-sm-2 col-sm-offset-5 col-xs-12 col-xs-offset-0">
                      <div class="btn-group btn-group-sm" role="group"><button class="btn btn-default"
                          sc-update-feedback="sc-update-feedback" title="{{ device.actions.read | translate }}"
                          feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                          feedback-type="instance.feedbackUpdateInstance"
                          ng-click="$ctrl.readInstance(objectKey, instanceKey)"
                          ng-disabled="!$ctrl.device.online || $ctrl.isLoading" ng-if="$ctrl.device"><i
                            class="glyphicon glyphicon-refresh"></i></button><button class="btn btn-default"
                          sc-update-feedback="sc-update-feedback" title="{{ device.actions.observe | translate }}"
                          ng-click="$ctrl.observeInstance(objectKey, instanceKey)"
                          ng-disabled="!$ctrl.device.online || $ctrl.isLoading" ng-if="!instance.observed"><i
                            class="glyphicon glyphicon-eye-open"></i></button><button class="btn btn-default"
                          sc-update-feedback="sc-update-feedback" title="{{ device.actions.unobserve | translate }}"
                          ng-click="$ctrl.unobserveInstance(objectKey, instanceKey)"
                          ng-disabled="!$ctrl.device.online || $ctrl.isLoading" ng-if="instance.observed"><i
                            class="glyphicon glyphicon-eye-close"></i></button></div>
                    </div>
                  </div>
                  <div class="resource row"
                    ng-repeat="(resourceKey, resource) in $ctrl.getResources(objectKey, instanceKey)">
                    <div class="resource-key col-sm-3 col-xs-9"><span ng-bind="resource.name"></span><sc-tooltip
                        description="resource.description"></sc-tooltip></div>
                    <div class="lwm2m-path col-sm-2 col-xs-3"
                      ng-bind="$ctrl.getResourcePath(objectKey, instanceKey, resourceKey)"></div>
                    <div class="resource-value col-sm-5 col-xs-12" sc-update-feedback="sc-update-feedback"
                      feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                      feedback-type="resource.feedbackUpdateResource"><span
                        ng-bind="$ctrl.showResourceValue(resource)"></span></div>
                    <div class="controls col-sm-2 col-xs-12">
                      <div class="btn-group btn-group-sm" role="group"><button class="btn btn-default"
                          title="{{ device.actions.read | translate }}"
                          ng-click="$ctrl.read(objectKey, instanceKey, resourceKey)"
                          ng-disabled="!$ctrl.device.online || $ctrl.isLoading" ng-if="$ctrl.readable(resource)"><i
                            class="glyphicon glyphicon-refresh"></i></button><button class="btn btn-default"
                          sc-update-feedback="sc-update-feedback" title="{{ device.actions.observe | translate }}"
                          ng-click="$ctrl.observe(objectKey, instanceKey, resourceKey)"
                          ng-disabled="!$ctrl.device.online || $ctrl.isLoading" ng-if="$ctrl.observable(resource)"><i
                            class="glyphicon glyphicon-eye-open"></i></button><button class="btn btn-default"
                          sc-update-feedback="sc-update-feedback" title="{{ device.actions.unobserve | translate }}"
                          ng-click="$ctrl.unobserve(objectKey, instanceKey, resourceKey)"
                          ng-disabled="!$ctrl.device.online || $ctrl.isLoading" ng-if="$ctrl.unobservable(resource)"><i
                            class="glyphicon glyphicon-eye-close"></i></button><button class="btn btn-default"
                          title="{{ device.actions.write | translate }}"
                          ng-click="$ctrl.openEditDeviceResourceDialog(objectKey, instanceKey, resourceKey)"
                          ng-disabled="!$ctrl.device.online || $ctrl.isLoading" ng-if="$ctrl.writable(resource)"><i
                            class="glyphicon glyphicon-pencil"></i></button><button class="btn btn-default"
                          key="devices.modals.execute" title="{{ device.actions.execute | translate }}"
                          ng-click="$ctrl.execute(objectKey, instanceKey, resourceKey)"
                          ng-disabled="!$ctrl.device.online || $ctrl.isLoading" ng-if="$ctrl.executable(resource)"><i
                            class="glyphicon glyphicon-play" sc-update-feedback="sc-update-feedback"
                            feedback-classes="{&quot;success&quot;: &quot;feedback-wait&quot;, &quot;failure&quot;: &quot;feedback-wait&quot;}"
                            feedback-type="resource.feedbackExecuteResource"></i><i
                            class="glyphicon glyphicon-ok feedback-wait" sc-update-feedback="sc-update-feedback"
                            feedback-classes="{&quot;success&quot;: &quot;feedback-show&quot;}"
                            feedback-type="resource.feedbackExecuteResource"></i><i
                            class="glyphicon glyphicon-remove feedback-wait" sc-update-feedback="sc-update-feedback"
                            feedback-classes="{&quot;failure&quot;: &quot;feedback-show-error&quot;}"
                            feedback-type="resource.feedbackExecuteResource"></i></button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </uib-tab><uib-tab index="1" heading="{{ 'devices.keys_settings' | translate }}"><sc-device-keys
            device="$ctrl.device" alerts-service="$ctrl.alertsService"></sc-device-keys></uib-tab><uib-tab index="2"
          heading="{{ 'devices.harvest_usage_alert' | translate }}"
          sc-feature="eventHandlerStats"><sc-device-harvest-usage-alert-component
            device="$ctrl.device"></sc-device-harvest-usage-alert-component></uib-tab><uib-tab index="3"
          heading="{{ 'devices.advanced_settings' | translate }}"><uib-accordion close-others="false">
            <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="true"><uib-accordion-heading>
                <div class="clickable-area" id="edit-tag-accordion-header"><i class="glyphicon"
                    ng-class="{ &quot;glyphicon-chevron-down&quot;: $ctrl.panelStatus.tags, &quot;glyphicon-chevron-right&quot;: !$ctrl.panelStatus.tags }"></i><span
                    translate=".actions.edit_tag"></span></div>
              </uib-accordion-heading><sc-tag-list service-name="$ctrl.serviceName"
                obj-one-way="$ctrl.device"></sc-tag-list></div>
          </uib-accordion><uib-accordion close-others="false">
            <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="true"><uib-accordion-heading>
                <div class="clickable-area"><i class="glyphicon"
                    ng-class="{ &quot;glyphicon-chevron-down&quot;: $ctrl.panelStatus.advanced, &quot;glyphicon-chevron-right&quot;: !$ctrl.panelStatus.advanced }"></i><span
                    translate=".delete_header"></span></div>
              </uib-accordion-heading><button class="btn btn-danger" sc-confirm="deletion" type="button"
                on-confirm="$ctrl.deleteDevice()"><span translate=".delete"></span></button></div>
          </uib-accordion></uib-tab></uib-tabset></div>
  </div>
</div>`;
