export const template = `<app-user-notifications></app-user-notifications>
<div class="ds-modal">
  <div class="ds-dialog">
    <header class="ds-banner"><i class="ds-icon --icon-comment-question"></i>
      <div>
        <h2 ng-bind-html="'subscribers.abolished.title' | translate"></h2>
      </div>
    </header>
    <section>
      <p ng-bind-html="'subscribers.abolished.text' | translate"></p>
    </section>
    <footer><button class="ds-button x-close-subscribers-abolished" id="start-using-new-ui"
        ng-click="$ctrl.enableSimApiMode()"><span
          ng-bind-html="'subscribers.abolished.button' | translate"></span></button></footer>
  </div>
</div>`;
