import { BaseController } from './base_controller';

/**
 * A base class for Angular JS 1.x directive controllers, implementing
 * some common behaviors.
 */
export class BaseDirectiveController extends BaseController {
  // @ts-expect-error (legacy code incremental fix)
  protected scope: ng.IScope;

  // @ts-expect-error (legacy code incremental fix)
  protected attributes: ng.IAttributes;

  // @ts-expect-error (legacy code incremental fix)
  protected element: ng.IAugmentedJQuery;

  /**
   * Invoked from an Angular directive's link() function, this
   * captures the initial scope, element, and attributes.
   */
  activate(scope: ng.IScope, element: ng.IAugmentedJQuery, attributes?: ng.IAttributes) {
    this.scope = scope;
    // @ts-expect-error (legacy code incremental fix)
    this.attributes = attributes;
    this.element = element;
  }

  /**
   * Evaluates an angular expression, with special handling for a null value
   * (it gets ignored) and also for an expression that evaluates to a
   * function (it gets executed). This is a convenience routine for directive
   * controllers to evaluate the values of their attributes.
   *
   * Example: <sc-foo ng-disabled="$ctrl.isLoading()" hoge-attr="whatever()">
   *
   * The controller might use this to evaluate the "$ctrl.isLoading()" or
   * "whatever()" attribute values.
   *
   * The string is evaluated by the receiver's scope (this.scope).
   *
   * If the expression null, the result is null and nothing happens.
   *
   * If the expression evaluates to a function (e.g. "$ctrl.fooFunc"), then
   * that function is invoked.
   *
   * If the expression is a string that does not evaluate to a function
   * (e.g. "$ctrl.doSomething()"), then the result is evaluated and no further
   * action is taken.
   *
   * @param angularExpression - an angular expression, e.g. "$ctrl.isLoading()"
   */
  eval(angularExpression?: string) {
    let result = null;

    if (!angularExpression) {
      this.trace('eval: there is no value to evaluate:', angularExpression);
      return result;
    }
    const evaluatedExpression = this.scope.$eval(angularExpression);
    this.trace('eval: evaluated expression:', angularExpression, evaluatedExpression);

    if (typeof evaluatedExpression === 'function') {
      this.trace('eval: the expression evaluates to a function, so that function will now be executed:');
      result = evaluatedExpression();
    } else {
      this.trace('eval: the expression does not evaluate to a function, so the result will be returned as-is.');
      result = evaluatedExpression;
    }
    this.trace('eval: expression evaluation is returning this result:', result);
    return result;
  }

  /**
   * Returns true if directive's element has a value bound to the 'ng-disabled' attribute, and
   * that result of evaluating that value (with this.eval()) is truthy.
   */
  ngDisabled() {
    const ngDisabledValue = this.attributes.ngDisabled;
    return ngDisabledValue ? !!this.eval(ngDisabledValue) : false;
  }
}
