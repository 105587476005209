import { Component, Directive, ElementRef, Injector, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// allow $scope to be provided to ng1
const ScopeProvider = {
  deps: ['$injector'],
  provide: '$scope',
  useFactory: (injector: Injector) => injector.get('$rootScope').$new(),
};

@Directive({
  selector: 'legacy-sims-page',
  providers: [ScopeProvider],
})
export class LegacySimsPageDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('scSims', elementRef, injector);
  }
}

@Component({ template: `<legacy-sims-page apiMode="sim"></legacy-sims-page>` })
export class LegacySimsPageComponent {}

@NgModule({
  declarations: [LegacySimsPageDirective, LegacySimsPageComponent],
  exports: [LegacySimsPageDirective, LegacySimsPageComponent],
})
export class LegacySimsPageModule {}
