/* Resource Type */
export const RESOURCE_TYPE_SIM = 'Subscriber' as const;
export const RESOURCE_TYPE_LORA = 'LoraDevice' as const;
export const RESOURCE_TYPE_SIGFOX = 'SigfoxDevice' as const;
export const RESOURCE_TYPE_DEVICE = 'Device' as const;
export const RESOURCE_TYPE_SORACAM = 'SoraCam' as const;
export const RESOURCE_TYPE_SATELLITE = 'SatelliteDevice' as const;
/**
 * This "resource" is unlike the others, and it kind of violates the design of the resource selector. OTOH, though, some APIs explicitly only accept IMSI, such as Napter Audit Logs searching. I needed this resource selector for that UI, in order to get rid of Angular Material, so I added this resource type. I'm a little skeptical of this design, though, so we should maybe revisit this to extend RESOURCE_TYPE_SIM to be able to search by IMSI or simId (and to generally work better with SIMs that have multiple IMSIs via subscription containers). In other words, the resource design is a little too simplistic for SIMs in particular, so maybe we should extend that design rather than making IMSI a separate resource type. But... I don't have the bandwidth to do it right now, but removing Material is a big priority.
 */
export const RESOURCE_TYPE_IMSI = 'IMSI' as const;

export type ResourceType =
  | typeof RESOURCE_TYPE_SIM
  | typeof RESOURCE_TYPE_LORA
  | typeof RESOURCE_TYPE_SIGFOX
  | typeof RESOURCE_TYPE_DEVICE
  | typeof RESOURCE_TYPE_SORACAM
  | typeof RESOURCE_TYPE_SATELLITE
  | typeof RESOURCE_TYPE_IMSI;

export const RESOURCE_TYPES: ReadonlyArray<ResourceType> = [
  RESOURCE_TYPE_SIM,
  RESOURCE_TYPE_LORA,
  RESOURCE_TYPE_SIGFOX,
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_SORACAM,
  RESOURCE_TYPE_SATELLITE,
  RESOURCE_TYPE_IMSI,
];

export type ResourcesChangeEvent = {
  type: 'add' | 'remove';
  targetValue: Resource;
  resources: Resource[];
};

export interface Resource {
  resourceId: string;
  resourceType: ResourceType;
}

export interface ResourceSelectionState extends Pick<Resource, 'resourceType'> {
  resourceId?: string | null;
}

export const EmptyState = () => ({ resourceType: RESOURCE_TYPE_SIM, resourceId: null } as ResourceSelectionState);

// option
export interface ResourceOption {
  resourceId: string;
  resourceType: ResourceType;
  name?: string;
}
