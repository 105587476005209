import { LegacyAny } from '@soracom/shared/core';

import { PaginationOptions, ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  SearchQuery,
  SoracomApiParams,
  TaggableService,
  TagParams,
} from '@user-console/legacy-soracom-api-client';
import { GroupableService } from '@soracom/shared/soracom-platform';
import { TerminatableService } from '@soracom/shared/soracom-platform';
import { InjectList } from '../core/injectable';
import { SigfoxDevice } from '../core/sigfox_device';

export interface SigfoxDeviceRegistrationRequest {
  deviceId: string;
  registrationSecret: string;
  tags: { name: string };
  groupId?: string;

  /** Added for sigfoxBYOD feature. Null means "register with prototype status, no product certfication ID is required", whereas "" means "user didn't enter anything for this field". (https://soracom.atlassian.net/browse/SS-2879) */
  productCertificationId?: string | null;

  registrationStatus?: string;
  registrationError?: string;
}

export class SigfoxDevicesService
  implements TaggableService, GroupableService, PaginatableService<SigfoxDevice>, TerminatableService
{
  static $inject: InjectList = ['$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'sigfox_devices';

  constructor(
    private $q: ng.IQService,
    private SoracomApi: LegacyBaseSoracomApiService,
    private PaginationService: PaginationService,
  ) {}

  list(paginationOptions: PaginationOptions, searchQuery: SearchQuery): Promise<ScRelation<SigfoxDevice>> {
    const hasSearchQuery = !!searchQuery;

    const apiParams = {
      method: 'get',
      path: hasSearchQuery ? '/v1/query/sigfox_devices' : '/v1/sigfox_devices',
      query: { ...paginationOptions },
      searchQuery,
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const data = response.data.map((element: LegacyAny) => new SigfoxDevice(element));
      const relation: ScRelation<SigfoxDevice> = {
        data,
        links: this.PaginationService.getPaginationLinks(response.headers.link),
      };

      return this.$q.resolve(relation);
    });
  }

  register(sigfoxDevice: SigfoxDeviceRegistrationRequest): Promise<SigfoxDevice> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/sigfox_devices/${sigfoxDevice.deviceId}/register`,
      contentType: 'application/json',
      body: sigfoxDevice,
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return new SigfoxDevice(response.data);
    });
  }

  setGroup(id: string, groupId: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/sigfox_devices/${id}/set_group`,
      body: { groupId },
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  unsetGroup(id: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/sigfox_devices/${id}/unset_group`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  updateTags(id: string, tags: TagParams[]) {
    const apiParams = {
      method: 'put',
      path: `/v1/sigfox_devices/${id}/tags`,
      contentType: 'application/json',
      body: tags,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  deleteTag(id: string, tagName: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/sigfox_devices/${id}/tags/${encodeURIComponent(tagName)}`,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  downlink(id: string, data: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/sigfox_devices/${id}/data`,
      contentType: 'application/json',
      body: { data },
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  terminate(id: string, deleteImmediately?: boolean): Promise<SigfoxDevice> {
    const query =
      deleteImmediately !== undefined && deleteImmediately !== null ? `?delete_immediately=${deleteImmediately}` : '';
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/terminate${query}`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => new SigfoxDevice(response.data));
  }

  disableTermination(id: string): Promise<SigfoxDevice> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/disable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => new SigfoxDevice(response.data));
  }

  enableTermination(id: string): Promise<SigfoxDevice> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/enable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => new SigfoxDevice(response.data));
  }
}
