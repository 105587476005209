import { LegacyAny } from '@soracom/shared/core';
import {
  ColumnOption,
  TableColumnOptionsService,
  TableColumnOptionsType,
  TranslationPrefix,
} from './table_column_options.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';
import { Logger } from '@soracom/shared-ng/logger-service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-legacy-table-settings-modal-component',
  templateUrl: './table_settings.modal.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
})
export class TableSettingsModalComponent
  extends LegacyUibModalAdapterModalBase<any, { columnOptions: ColumnOption[] }>
  implements OnInit, OnDestroy
{
  // @ts-expect-error (legacy code incremental fix)
  columnOptions: ColumnOption[];
  disableSave = false;
  tableType = TableColumnOptionsType.Subscriber;
  // @ts-expect-error (legacy code incremental fix)
  enableCustomTags: boolean;
  // @ts-expect-error (legacy code incremental fix)
  translationPrefix: TranslationPrefix;

  /**
   * This property can be supplied, and if it is supplied, then it will be used to process the column options after they are loaded
   */
  // @ts-expect-error (legacy code incremental fix)
  columnOptionsTransformer: (opts: ColumnOption[]) => ColumnOption[];

  constructor(
    private $log: Logger,
    private tableColumnOptionsService: TableColumnOptionsService,
    private translateService: TranslateService
  ) {
    super();
  }

  params() {
    return this.resolve.params();
  }

  ngOnInit(): void {
    // Mason 2020-07-02: marker for investigation of:
    // https://soracom.atlassian.net/browse/SS-8337
    // "Subscribers table column changes sometimes don't take effect"
    //
    // window['TESTEE'] = this;
    //

    this.$log.debug('TrafficVolumeRankingController: $onInit()...');

    const tt = this.params().tableType;
    if (!!tt) {
      this.tableType = tt;
    }

    const transformFunc = this.params().columnOptionsTransformer;
    if (!!transformFunc) {
      this.columnOptionsTransformer = transformFunc;
    }

    // only enable custom tags on the subscriber table
    this.enableCustomTags = this.tableType === TableColumnOptionsType.Subscriber;
    this.configureForTableType(this.tableType);
  }

  ngOnDestroy() {
    // Mason 2022-11-28: This is a specific workaround for https://app.shortcut.com/soracom/story/74245/scroll-function-fails-on-various-pages-after-adding-custom-tag-column-in-sim-management-ui
    // It's kind of a lame hack — because we have no idea when or why this issue started occurring, and this is just working around it primitively. But, I already debugged it for an hour and we are going to replace all these legacy components with the new SIM Management UI very soon (within 2-4 weeks), so if this hack works then fine. It will be deleted soon.
    // NOTE: We cannot do this when the actual columns modal causing the problem is dismissed, because there is a timing issue and $uibModal puts the class back on the body while it is animating the modal out. That is why we don't do it in the close handler of that modal below, but here with the parent modal is being destroyed.
    document?.body.classList.remove('modal-open');
  }

  configureForTableType(tableType: TableColumnOptionsType) {
    this.tableType = tableType;
    this.columnOptions = structuredClone(this.tableColumnOptionsService.get(tableType));

    if (this.columnOptionsTransformer) {
      this.columnOptions = this.columnOptionsTransformer(this.columnOptions);
    }

    this.translationPrefix = this.tableColumnOptionsService.translationPrefix(tableType);
  }

  getColumnName(key: LegacyAny) {
    if (this.isTag(key)) {
      return key.substring('tag_'.length);
    }
    return this.translateService.instant(this.translationPrefix + key);
  }

  save() {
    // debugger;
    this.tableColumnOptionsService.set(this.columnOptions, this.tableType);
    this.close({
      columnOptions: this.columnOptions,
    });
  }

  cancel() {
    this.dismiss();
  }

  isTag(key: LegacyAny) {
    if (this.enableCustomTags) {
      if (typeof key === 'string' && key.lastIndexOf('tag_', 0) === 0) {
        return true;
      }
    }
    return false;
  }

  removeTag(key: LegacyAny) {
    const newOptions = this.tableColumnOptionsService.removeCustomTagColumn(key, this.columnOptions);
    if (newOptions == null) {
      return;
    }
    this.columnOptions = structuredClone(newOptions);
  }

  addTag(tagName: LegacyAny) {
    const newOptions = this.tableColumnOptionsService.addCustomTagColumn(tagName, this.columnOptions);
    if (newOptions == null) {
      return;
    }
    this.columnOptions = structuredClone(newOptions);
  }
}
