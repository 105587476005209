import { LegacyAny } from '@soracom/shared/core';

import { Harvestable } from '@soracom/shared/soracom-platform';
import * as angular from 'angular';
import { TableController } from '../components/base_table_controller';
import { Action } from '../core/actions/Action';
import { Device, DeviceStatus } from '../core/device';
import { DevicesComponentController } from './devices.component';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { ChangeGroupModalComponent } from 'apps/user-console/src/app/soracom-groups/change_group_modal/change_group_modal.component';
import { FeatureAddDeviceModalComponent } from '../../../src/app/inventory-devices/feature-add-device-modal/feature-add-device-modal.component';

export abstract class DevicesAction<T> implements Action<T> {
  constructor(protected ctrl: DevicesComponentController) {}

  run() {
    throw new Error('Please implement');
  }
  actionable() {
    return true;
  }
}
export class ChangeDeviceGroupAction extends DevicesAction<Device> {
  private $uibModal;

  constructor(
    ctrl: DevicesComponentController,
    private uiDsModalService: UiDsModalService,
  ) {
    super(ctrl);
    this.$uibModal = ctrl.$uibModal;
  }

  run() {
    this.uiDsModalService
      .openAndWaitForResult(ChangeGroupModalComponent, {
        title: 'devices.actions.change_group',
        data: {
          resolve: {
            targets: () => {
              return this.ctrl.tableData.getSelectedData();
            },
            model: () => {
              return 'Device';
            },
          },
        },
      })
      .then((result) => {
        if (result) {
          this.okAction(result);
        }
      });
  }

  actionable() {
    return this.ctrl.tableData.countChecked() > 0;
  }

  private okAction = (result: LegacyAny) => {
    if (result) {
      this.ctrl.updateGroup(result.group);
    }
  };
}

export class AddDeviceAction extends DevicesAction<any> {
  private $uibModal;

  constructor(ctrl: DevicesComponentController) {
    super(ctrl);
    this.$uibModal = ctrl.$uibModal;
  }

  run() {
    this.ctrl.uiDsModalService.openAndWaitForResult(FeatureAddDeviceModalComponent, {}).then((result) => {
      this.okAction(result);
    });
  }

  actionable() {
    return true;
  }

  private okAction = (result: LegacyAny) => {
    const device = result.device;
    this.$uibModal
      .open({
        backdrop: 'static',
        component: 'scAddDeviceKeyModalComponent',
        resolve: {
          deviceId: () => device.deviceId,
          deviceKey: () => device.keys[0],
        },
      })
      .result.then(
        () => {
          this.ctrl.tableData.appendData(device);
        },
        () => {
          this.ctrl.tableData.appendData(device);
        },
      );
  };
}

const MAX_DEVICES_FOR_DATA = 5;
export class ShowDeviceDataAction<T extends Harvestable> implements Action<T> {
  constructor(
    private ctrl: TableController<T>,
    private $location: ng.ILocationService,
  ) {}

  run() {
    if (this.ctrl.tableData.getSelectedData().length > MAX_DEVICES_FOR_DATA) {
      this.ctrl.alertsService.showAlert('danger', {
        translationId: 'show_devices_data_action.too_many_devices',
        values: { max: MAX_DEVICES_FOR_DATA },
      });
      return;
    }
    const resources = this.ctrl.tableData
      .getSelectedData()
      .map((resource) => {
        return `${resource.resourceType}#${resource.resourceId}`;
      })
      .join('&');
    this.$location.path('/harvest_data').search({ resources });
  }

  actionable() {
    return this.ctrl.tableData.countChecked() > 0;
  }
}

export class UpdateDeviceStatusAction extends DevicesAction<Device> {
  private $uibModal;

  constructor(
    ctrl: DevicesComponentController,
    private fromStatus: DeviceStatus[],
    private destinationStatus: string,
  ) {
    super(ctrl);
    this.$uibModal = ctrl.$uibModal;
  }

  run() {
    this.$uibModal
      .open({
        backdrop: 'static',
        component: 'scChangeDeviceStatusModalComponent',
        resolve: {
          targets: () => {
            return this.targetDevices();
          },
          fromStatus: () => {
            return this.fromStatus;
          },
          destinationStatus: () => {
            return this.destinationStatus;
          },
        },
      })
      .result.then((result: LegacyAny) => {
        this.ctrl.updateStatus(this.fromStatus, this.destinationStatus);
      });
  }

  targetDevices(): Device[] {
    const data: Device[] = this.ctrl.tableData.getSelectedData();
    return data.filter((device) => this.fromStatus.includes(device.status));
  }

  actionable() {
    return this.targetDevices().length > 0;
  }
}
