import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { InjectList } from '../core/injectable';
import { LoraDevice } from '../core/lora_device';
import { SigfoxDevice } from '../core/sigfox_device';
import { LoraDevicesService } from '../lora_devices/lora_devices.service';
import { SigfoxDevicesService } from '../sigfox_devices/sigfox_devices.service';
import { AlertsService, AlertsServiceInstance } from './alerts.service';
import { BaseModalController } from './base_modal_controller';
import { template } from './send_downlink_message.component.html';

/**
 * A component for sending a downlink message to a device such as a LoRa or Sigfox device.
 */
export class SendDownlinkMessageComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<', // see $onInit() for what resolve.params canshould contain
  };
  controller = SendDownlinkMessageComponentController;
  template: any = template;
}

enum DownlinkMessageType {
  lora = 'lora',
  sigfox = 'sigfox',
}

// FIXME: This new generic downlink message send UI component should replace
// the old LoRa-specific LoraDevicesDownlinkModalController.

class SendDownlinkMessageComponentController extends BaseModalController {
  // @ts-expect-error (legacy code incremental fix)
  alertsService: AlertsServiceInstance; // FIXME: move to BaseController

  submitting = false; // FIXME: move to BaseController

  // @ts-expect-error (legacy code incremental fix)
  loraDevices: LoraDevice[];

  // @ts-expect-error (legacy code incremental fix)
  sigfoxDevices: SigfoxDevice[];

  dataRegexForLora = '^([0-9a-fA-F]{2}){1,242}$';

  dataRegexForEightOctets = '^([0-9a-fA-F]{2}){8}$';

  /** The type is set in $onInit, and is determined by the devices passed in resolve.params. */
  // @ts-expect-error (legacy code incremental fix)
  downlinkMessageType: DownlinkMessageType;

  /** the content for the downlink message (data-bound to UI) */
  // @ts-expect-error (legacy code incremental fix)
  downlinkMessageData: string;

  // only for lora downlink
  // @ts-expect-error (legacy code incremental fix)
  fPort: number;

  /**
   * internal list of just the device IDs (for now; once I have real model
   * objects we will probably delete this and have the template iterate over
   * the devices directly)
   */
  deviceIds = [];

  static $inject: InjectList = ['$log', '$q', 'AlertsService', 'LoraDevicesService', 'SigfoxDevicesService'];

  constructor(
    private $log: ng.ILogService,
    private $q: ng.IQService,
    private alertsServiceManager: AlertsService,
    private loraDevicesService: LoraDevicesService,
    private sigfoxDevicesService: SigfoxDevicesService
  ) {
    super($log);
    this.setTraceEnabled(true);
    this.trace('SendDownlinkMessageComponentController: constructor()...');
  }

  $onInit() {
    this.trace('SendDownlinkMessageComponentController.$onInit()...');

    this.alertsService = this.alertsServiceManager.generate();

    this.loraDevices = this.params().loraDevices;
    this.sigfoxDevices = this.params().sigfoxDevices;

    if (this.loraDevices) {
      this.downlinkMessageType = DownlinkMessageType.lora;
      // @ts-expect-error (legacy code incremental fix)
      this.deviceIds = this.loraDevices.map((element: LegacyAny) => element.id);
      this.fPort = LoraDevice.defaultFport;
    } else if (this.sigfoxDevices) {
      this.downlinkMessageType = DownlinkMessageType.sigfox;
      // @ts-expect-error (legacy code incremental fix)
      this.deviceIds = this.sigfoxDevices.map((element: LegacyAny) => element.deviceId);
    } else {
      this.error("sc-send-downlink-message-component initialized with no devices of any kind; that's not supported");
    }
  }

  cancel() {
    super.dismiss();
  }

  confirm() {
    if (this.loraDevices) {
      this.sendLoraDownlinkMessages();
    } else if (this.sigfoxDevices) {
      this.sendSigfoxDownlinkMessages();
    } else {
      this.alertsService.showError('no devices found');
    }
  }

  dataRegex(): string {
    if (this.downlinkMessageType === DownlinkMessageType.lora) {
      return this.dataRegexForLora;
    } else if (this.downlinkMessageType === DownlinkMessageType.sigfox) {
      return this.dataRegexForEightOctets;
    } else {
      // @ts-expect-error (legacy code incremental fix)
      return null;
    }
  }

  dataMinimumLength(): number {
    if (this.downlinkMessageType === DownlinkMessageType.lora) {
      return 2;
    } else if (this.downlinkMessageType === DownlinkMessageType.sigfox) {
      return 16;
    } else {
      // @ts-expect-error (legacy code incremental fix)
      return null;
    }
  }

  private sendLoraDownlinkMessages() {
    const promises: LegacyAny = [];
    angular.forEach(this.loraDevices, (loraDevice) => {
      promises.push(this.loraDevicesService.downlink(loraDevice.deviceId, this.downlinkMessageData, this.fPort));
    });
    this.executePromises(promises);
  }

  private sendSigfoxDownlinkMessages() {
    const promises: LegacyAny = [];
    angular.forEach(this.sigfoxDevices, (sigfoxDevice) => {
      const data = this.downlinkMessageData;
      const apiCall = this.sigfoxDevicesService.downlink(sigfoxDevice.deviceId, data);
      promises.push(apiCall);
    });
    this.executePromises(promises);
  }

  private executePromises(promises: any[]) {
    this.$q
      .all(promises)
      .then(() => {
        this.close();
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
        // FIXME: any need to trigger reload in the caller UI here?
      });
  }
}
