import { LegacyAny } from '@soracom/shared/core';

import { Harvestable, Taggable } from '@soracom/shared/soracom-platform';
import * as angular from 'angular';
import { BaseModel } from '@soracom/shared/soracom-platform';
import { DeviceKey } from './device_key';
import { Group, Groupable } from '@soracom/shared/group';

export interface DeviceObject {
  id: number;
  name: string;
  description: string;
  multiple: boolean;
  mandatory: boolean;
  instances: { [id: string]: DeviceInstance };
}

export type DeviceStatus = 'active' | 'inactive' | 'deleted';

export interface DeviceInstance {
  id: number;
  resources: { [id: string]: DeviceResource };
  observed: boolean;

  feedbackUpdateInstance: string;
}

export interface DeviceResource {
  id: number;
  name: string;
  operations: string;
  multiple: boolean;
  mandatory: boolean;
  type: string;
  rangeEnumeration: string;
  units: string;
  description: string;
  observed: boolean;
  value: string | object;
  imsi?: string;
  imei?: string;

  feedbackExecuteResource: string;
  feedbackUpdateResource: string;
}

export class Device extends BaseModel implements Taggable, Groupable, Harvestable {
  static resourcePath = 'devices';

  // @ts-expect-error (legacy code incremental fix)
  deviceId: string;
  // @ts-expect-error (legacy code incremental fix)
  endpoint: string;
  // @ts-expect-error (legacy code incremental fix)
  firmwareVersion: string;
  // @ts-expect-error (legacy code incremental fix)
  groupId: string;
  // @ts-expect-error (legacy code incremental fix)
  imei: string;
  // @ts-expect-error (legacy code incremental fix)
  imsi: string;
  // @ts-expect-error (legacy code incremental fix)
  ipAddress: string;
  // @ts-expect-error (legacy code incremental fix)
  lastModifiedTime: number;
  // @ts-expect-error (legacy code incremental fix)
  lastRegistrationUpdate: number;
  // @ts-expect-error (legacy code incremental fix)
  manufacturer: string;
  // @ts-expect-error (legacy code incremental fix)
  modelNumber: string;
  // @ts-expect-error (legacy code incremental fix)
  objects: { [id: string]: DeviceObject };
  /** The pathes to be observed */
  // @ts-expect-error (legacy code incremental fix)
  observed: string[];
  // @ts-expect-error (legacy code incremental fix)
  online: boolean;
  // @ts-expect-error (legacy code incremental fix)
  operatorId: string;
  // @ts-expect-error (legacy code incremental fix)
  registrationId: string;
  // @ts-expect-error (legacy code incremental fix)
  registrationLifeTime: number;
  // @ts-expect-error (legacy code incremental fix)
  serialNumber: string;
  // @ts-expect-error (legacy code incremental fix)
  status: DeviceStatus;
  tags: { [id: string]: string };

  // @ts-expect-error (legacy code incremental fix)
  keys: DeviceKey[];

  // @ts-expect-error (legacy code incremental fix)
  feedbackRefresh: string;

  // @ts-expect-error (legacy code incremental fix)
  private _group: Group;

  constructor(params?: LegacyAny) {
    super();
    this.tags = {};
    if (params) {
      this.deviceId = params.deviceId;
      this.endpoint = params.endpoint;
      this.firmwareVersion = params.firmwareVersion;
      this.imei = params.imei;
      this.imsi = params.imsi;
      this.ipAddress = params.ipAddress;
      this.lastModifiedTime = params.lastModifiedTime;
      this.lastRegistrationUpdate = params.lastRegistrationUpdate;
      this.manufacturer = params.manufacturer;
      this.modelNumber = params.modelNumber;
      this.objects = params.objects;
      this.observed = params.observed;
      this.online = params.online;
      this.operatorId = params.operatorId;
      this.registrationId = params.registrationId;
      this.registrationLifeTime = params.registrationLifeTime;
      this.serialNumber = params.serialNumber;
      this.status = params.status;

      this.groupId = params.groupId;
      this.tags = params.tags || {};

      if (params.keys) {
        this.keys = [];
        angular.forEach(params.keys, (key) => {
          this.keys.push(new DeviceKey(key));
        });
      }
    }
  }

  get id(): string {
    return this.deviceId;
  }

  get resourceId(): string {
    return this.deviceId;
  }

  readonly resourceType = 'Device';

  get name() {
    return this.tags?.name;
  }

  groupName() {
    if (this._group) {
      return this._group.name;
    } else {
      return null;
    }
  }

  get group(): Group {
    return this._group;
  }

  set group(group: Group) {
    this._group = group;
    if (group) {
      this.groupId = group.groupId;
    } else {
      // @ts-expect-error (legacy code incremental fix)
      this.groupId = null;
    }
  }

  isActivated() {
    return this.status === 'active';
  }

  isDeactivated() {
    return this.status === 'inactive';
  }
}
