export const template = `<app-user-notifications></app-user-notifications>
<main class="ds-app__main-container x-lora_network_sets-page-root" translate-namespace="lora_network_sets">
  <div class="alerts-wrapper"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  <div class="ds-controls">
    <div class="ds-bar"><button class="btn btn-primary" id="lora-network-sets-add-button"
        ng-disabled="!$ctrl.actionable(&quot;Add&quot;)" ng-click="$ctrl.doAction(&quot;Add&quot;)"><span
          class="button-text">{{ &quot;lora_network_sets.actions.add&quot; | translate }}</span></button><button
        class="btn btn-default" id="lora-network-sets-show-button" ng-disabled="!$ctrl.actionable(&quot;Show&quot;)"
        ng-click="$ctrl.doAction(&quot;Show&quot;)"><span class="button-text">{{
          &quot;lora_network_sets.actions.show&quot; | translate }}</span></button>
      <div class="btn-group" uib-dropdown="uib-dropdown"><button class="btn btn-default dropdown-toggle"
          id="lora-network-sets-actions-menu" type="button" uib-dropdown-toggle="uib-dropdown-toggle"><span
            class="button-text" translate="lora_network_sets.actions_menu.title"></span><span
            class="caret"></span></button>
        <ul class="dropdown-menu" id="lora-network-sets-actions-dropdown-menu" role="menu"
          uib-dropdown-menu="uib-dropdown-menu">
          <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('EditTag')"
            id="lora-network-sets-actions-menu-edit-tag"
            ng-class="{ disabled: !$ctrl.actionable(&quot;EditTag&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;EditTag&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;EditTag&quot;)}">
            <a href="" ng-click="$ctrl.doAction(&quot;EditTag&quot;)">{{ &quot;lora_network_sets.actions.edit_tag&quot;
              | translate }}</a></li>
        </ul>
      </div>
    </div>
    <div><sc-pagination paginator="$ctrl.paginator" ctrl="$ctrl"></sc-pagination></div>
  </div>
  <section class="ds-app__main-content ds-rows">
    <ul class="dropdown-menu" id="lora-network-sets-context-menu" role="menu" uib-dropdown-menu="uib-dropdown-menu"
      ng-if="$ctrl.shouldShowContextMenu()">
      <li class="dropdown-header" translate="lora_network_sets.context_menu.header"></li>
      <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Show')" id="lora-network-sets-context-menu-show"
        ng-class="{ disabled: !$ctrl.actionable(&quot;Show&quot;) }"><a role="menuitem" href=""
          ng-click="$ctrl.doAction(&quot;Show&quot;)">{{ &quot;lora_network_sets.actions.show&quot; | translate }}</a>
      </li>
      <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('EditTag')"
        id="lora-network-sets-context-menu-edit-tag" ng-class="{ disabled: !$ctrl.actionable(&quot;EditTag&quot;) }"><a
          role="menuitem" href="" ng-click="$ctrl.doAction(&quot;EditTag&quot;)">{{
          &quot;lora_network_sets.actions.edit_tag&quot; | translate }}</a></li>
    </ul>
    <div class="table-responsive table-lora-network-sets">
      <table class="sc-table table ds-datatable--striped x-lora-network-sets-table" id="lora-network-sets-table">
        <thead>
          <tr>
            <th class="th-checkbox ds-datatable__col--min" id="lora-network-sets-th-checkbox"
              ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
              <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check"
                  id="lora-network-sets-checkbox-for-all" type="checkbox" ng-model="$ctrl.checkboxForAll"
                  ng-change="$ctrl.checkboxForAllChanged($ctrl.checkboxForAll);" />
                <div class="ds-checkbox__label">Check All</div>
              </div>
            </th>
            <th id="lora-network-sets-th-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)">{{
              &quot;lora_network_sets.columns.name&quot; | translate }}&nbsp;</th>
            <th id="lora-network-sets-th-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)">{{
              &quot;lora_network_sets.columns.id&quot; | translate }}&nbsp;</th>
          </tr>
        </thead>
        <tbody class="x-lora-network-sets-list" ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()">
          <tr ng-repeat="row in $ctrl.tableData.rows" ng-click="$ctrl.setSelected(row)"
            sc-context-menu="lora-network-sets-context-menu">
            <td class="td-checkbox lora-network-sets-checkbox"
              ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
              <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check checkbox-for-each"
                  id="cb{{row.obj.id}}" type="checkbox" ng-model="row.selected"
                  ng-change="$ctrl.checkboxForEachChanged()" ng-click="$event.stopPropagation()" />
                <div class="ds-checkbox__label">Check {{ row.obj.id }}</div>
              </div>
            </td>
            <td class="lora-network-sets-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)">
              <sc-inplace-editable-text ng-if="$ctrl.editable" ng-mouseover="row.hoverName = true"
                ng-mouseleave="row.hoverName = false" key="name" cancel-on-blur="true" display-value="row.obj.name"
                edit-ctrl="row.nameEditCtrl" padding-left="&quot;5&quot;" show-pencil-icon="row.hoverName"
                show-remove-button="false" show-save-button="false"
                show-cancel-button="false"></sc-inplace-editable-text><span ng-if="!$ctrl.editable"
                ng-bind="row.obj.name"></span></td>
            <td class="lora-network-sets-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)" ng-bind="row.obj.id"></td>
          </tr>
        </tbody>
        <tbody class="x-no-lora-network-sets-exist" ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()">
          <tr class="no-data">
            <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.no_data' | translate
              }}</td>
          </tr>
        </tbody>
        <tbody class="x-is-loading" ng-if="$ctrl.isLoading()">
          <tr class="loading">
            <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.loading' | translate
              }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section><sc-tutorial-component></sc-tutorial-component>
</main>`;
