<section dsDialogContent>
  <div class="ds-rows">
    <div class="ds-notice--info" translate="UpdateLagoonUserEmailModalComponent.text"></div>
    <div class="ds-field" [formGroup]="form">
      <label class="ds-label" for="lagoon-user-email"
        ><span translate="UpdateLagoonUserEmailModalComponent.email"></span>&nbsp;<span class="text-danger"
          >*</span
        ></label
      >
      <input
        type="text"
        class="ds-input"
        formControlName="userEmail"
        [attr.translate]="'UpdateLagoonUserEmailModalComponent.email_placeholder' | translate"
        required="required"
        uiFocusMe
      />
    </div>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm" [ngClass]="{'--loading': isLoading}">
    <span [innerHTML]="'UpdateLagoonUserEmailModalComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()">
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>

