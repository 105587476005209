<div class="ds-cols--center">
  <div class="ds-span--12">
    <div class="x-lte-m-button-index" *ngIf="state === 'index'">
      <app-lte-m-button-index (onStateChanged)="relayOnStateChanged($event)"></app-lte-m-button-index>
    </div>
    <div class="x-lte-m-button-new-wizard-1-select-subscribers" *ngIf="state === 'selectSubscribers'">
      <app-lte-m-button-new-subscribers-select></app-lte-m-button-new-subscribers-select>
    </div>
    <div class="x-lte-m-button-new-wizard-2-select-group" *ngIf="state === 'selectGroup'">
      <app-lte-m-button-new-group-select></app-lte-m-button-new-group-select>
    </div>
    <div class="x-lte-m-button-new-wizard-3-edit-config" *ngIf="state === 'newConfig'">
      <app-lte-m-button-config></app-lte-m-button-config>
    </div>
    <div class="x-lte-m-button-edit" *ngIf="state === 'editConfig'">
      <app-lte-m-button-config></app-lte-m-button-config>
    </div>
  </div>
</div>
