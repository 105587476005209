import { LegacyAny } from '@soracom/shared/core';

import { InjectList } from '../core/injectable';

/**
 * KeyEventService implements a master event handler for the "keypress" event
 * at the window level. This event handler, in turn, can dispatch other event
 * handlers that may be registered/unregistered.
 *
 * The concrete motivation for this is to allow modals to react to the Return
 * key being pressed, even when the "focused" element is not within the modal.
 *
 * Since it would be messy and error-prone to have a bunch of modal adding
 * global event handlers at the window level, it seemed easier and more
 * testable to create KeyEventService to coordinate that.
 */
export class KeyEventService {
  static $inject: InjectList = ['$log'];

  /** Register a named function to be invoked every single time the Return key is pressed. (Note: should not be an anonymous function. You can pass a method of your TypeScript class as the handler.) */
  addReturnKeyHandler(handler: (e: Event) => void) {
    this.registerKeyEventHandler(this.kReturnKey, handler);
  }

  /** Remove a named function previously registered using `addReturnKeyHandler()`. After it is removed, the function will no longer be invoked when the Return key is pressed. */
  removeReturnKeyHandler(handler: (e: Event) => void) {
    this.unregisterKeyEventHandler(this.kReturnKey, handler);
  }

  constructor(private $log: ng.ILogService) {
    // do nothing
  }

  /**
   * Print summary of the service's internal data.
   *
   * @param haltWithDebugger - if truthy, a "debugger;" statement will be executed,
   * which should pause execution if browser dev tools are open
   */
  debugPrintKeyEventService(haltWithDebugger: LegacyAny) {
    if (haltWithDebugger) {
      debugger;
    }

    const p = this.$log.debug;

    p('--- BEGIN KeyEventService ---');
    p(`hasRegisteredMasterEventHandler: ${this.hasRegisteredMasterEventHandler}`);
    p('eventHandlers:');
    for (const k in this.eventHandlers) {
      const handlers = this.eventHandlers[k] || [];
      p(`  →  key code ${k}: ${handlers.length} keypress handlers registered`);
    }
    p('--- END KeyEventService ---');
  }

  // --------------------------------------------------
  // Private:
  // --------------------------------------------------

  private kReturnKey = '13';

  private eventHandlers: LegacyAny = {};

  private hasRegisteredMasterEventHandler = false;

  /**
   * Note: This is declared this way because event handlers have to be named functions,
   * not anonymous functions, for JS function equality tests via === to work.
   * Even though this handler is never actually removed, currently, it's still
   * implemented as a property with a function value, rather than as a normal method.
   * See this for the difference: https://github.com/soracom/user-console/pull/763/files#r134923231
   */
  private masterEventHandler = (e: any) => {
    const key = e.which || e.keyCode || 0;
    const registeredHandlers = this.eventHandlers['' + key];
    if (!registeredHandlers || registeredHandlers.length < 1) {
      return;
    }
    for (const handler of registeredHandlers) {
      handler(e);
    }
  };

  private registerMasterEventHandler() {
    this.$log.debug('registerMasterEventHandler()');
    window.addEventListener('keypress', this.masterEventHandler, false);
    this.hasRegisteredMasterEventHandler = true;
  }

  private registerKeyEventHandler(keyIdentifier: string, handler: (e: Event) => void) {
    this.$log.debug('registerKeyEventHandler:');

    if (!this.hasRegisteredMasterEventHandler) {
      this.registerMasterEventHandler();
    }
    let handlers = this.eventHandlers[keyIdentifier];
    if (!handlers) {
      handlers = [];
      this.eventHandlers[keyIdentifier] = handlers;
    }
    handlers.push(handler);
  }

  private unregisterKeyEventHandler = (keyIdentifier: string, handler: (e: Event) => void) => {
    this.$log.debug('unregisterKeyEventHandler:');

    const handlers = this.eventHandlers[keyIdentifier] || [];
    const lastIndex = handlers.length - 1;

    for (let i = lastIndex; i > -1; i--) {
      const candidate = handlers[i];

      if (candidate === handler) {
        handlers.splice(i, 1);
      }
    }
  };
}
