<form [formGroup]="form">
  <div>
    <label class="ds-checkbox">
      <input type="checkbox" formControlName="autoAdjust" />
      <div class="ds-text--label">{{ 'data.chart.y_range_automatic_adjustment' | translate }}</div>
    </label>
  </div>
  <div class="ds-cols">
    <div class="ds-field">
      <div class="ds-label">{{ 'data.chart.y_max_label' | translate }}</div>
      <input type="number" formControlName="maxValue" style="width: 100%" />
    </div>
    <div class="ds-field">
      <label class="ds-label">{{ 'data.chart.y_min_label' | translate }}</label>
      <input type="number" formControlName="minValue" style="width: 100%" />
    </div>
  </div>
</form>
