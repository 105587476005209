import { LegacyAny } from '@soracom/shared/core';

import {
  CoverageTypeService,
  getCoverageType,
  getOperatorData,
  LoginUserDataService,
} from '@soracom/shared/data-access-auth';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { Contract } from '@soracom/shared/soracom-platform';
import {
  DEVICE,
  HarvestResourceType,
  LORA,
  SATELLITE,
  SIGFOX,
  SIM,
  SORACAM,
} from '../../../src/app/harvest-data/harvest-data.type';
import { PaginationService } from '@user-console/legacy-soracom-api-client';
import { ScRelation } from '../components/paginator';
import { LegacyBaseSoracomApiService, SoracomApiParams } from '@user-console/legacy-soracom-api-client';
import { HarvestContract } from '../core/harvest_contract';
import { HarvestData, HarvestDataInstance } from '../core/harvest_data';
import { InjectList } from '../core/injectable';
import { getHarvestDummyData } from './dummy_harvest_data';

export type SortOrder = 'desc' | 'asc';

export interface HarvestDataQuery {
  resourceType: HarvestResourceType;
  resourceId: string;
  from?: number;
  to?: number;
  sort?: SortOrder;
  limit?: number;
  lastEvaluatedKey?: string;
}

export type HarvestDataPrevQuery = {
  resourceType: HarvestResourceType;
  resourceId: string;
  url: string;
  sort?: SortOrder;
};

export class HarvestDataService {
  static $inject: InjectList = [
    '$log',
    '$q',
    'BaseSoracomApiService',
    'CoverageTypeService',
    'FeatureVisibilityService',
    'PaginationService',
    'LoginUserDataService',
  ];

  resourcePath = 'data';

  constructor(
    private $log: ng.ILogService,
    private $q: ng.IQService,
    private soracomApi: LegacyBaseSoracomApiService,
    private coverageTypeService: CoverageTypeService,
    private featureVisibilityService: FeatureVisibilityService,
    private paginationService: PaginationService,
    private loginUserDataService: LoginUserDataService,
  ) {}

  getResourceTypeOptions() {
    const resourceTypes: HarvestResourceType[] = [SIM];
    if (this.featureVisibilityService.isEnabled('lora')) {
      resourceTypes.push(LORA);
    }
    if (this.featureVisibilityService.isEnabled('sigfox')) {
      resourceTypes.push(SIGFOX);
    }
    if (this.featureVisibilityService.isEnabled('harvestDataForSoraCam')) {
      resourceTypes.push(SORACAM);
    }
    if (this.featureVisibilityService.isEnabled('satellite')) {
      resourceTypes.push(SATELLITE);
    }
    resourceTypes.push(DEVICE);
    return resourceTypes;
  }

  listPrevious(q: HarvestDataPrevQuery): Promise<ScRelation<HarvestData>> {
    const promise = this.getHarvestDataByUrl(q.url);

    const sortOrder = q.sort ? q.sort : /sort=asc/.test(q.url) ? 'asc' : 'desc'; // default: desc

    return promise.then((res: LegacyAny) => {
      return this.handleApiResponse(q.resourceType, q.resourceId, sortOrder, res);
    });
  }

  list(q: HarvestDataQuery): Promise<ScRelation<HarvestData>> {
    const resourceId = q.resourceId;
    const resourceType = q.resourceType;
    const _query = JSON.parse(
      JSON.stringify(q, (k, v) => {
        if (v === undefined || v === null || v === '') {
          return undefined;
        } else if (k === 'resourceType' || k === 'resourceId') {
          return undefined;
        } else {
          return v;
        }
      }),
    );

    const promise = this.getHarvestData(resourceType, resourceId, _query);

    return promise.then((res: LegacyAny) => {
      // @ts-expect-error (legacy code incremental fix)
      return this.handleApiResponse(q.resourceType, q.resourceId, q.sort, res);
    });
  }

  delete(harvestData: HarvestData) {
    const apiParams = {
      method: 'delete',
      path: `/v1/data/${harvestData.resourceType}/${encodeURIComponent(harvestData.resourceId)}/${harvestData.time}`,
    };

    return this.soracomApi.callApiWithToken(apiParams);
  }

  private handleApiResponse(
    resourceType: HarvestResourceType,
    resourceId: string,
    sortOrder: SortOrder,
    res: any,
  ): ScRelation<HarvestData> {
    const data = res.data.map((record: LegacyAny) => {
      return new HarvestDataInstance(resourceType, resourceId, record);
    });
    const links = this.paginationService.getPaginationLinks(res.headers.link);
    // swap next/prev to align time series (Always 'next' means future)
    if (sortOrder === 'desc') {
      const swap = links.prev;
      links.prev = links.next;
      links.next = swap;
    }
    return { data, links };
  }

  private getHarvestData(resourceType: LegacyAny, resourceId: LegacyAny, queryObj: LegacyAny) {
    const dummyResponse = getHarvestDummyData(resourceType, resourceId, queryObj);
    if (dummyResponse) {
      return Promise.resolve(dummyResponse);
    }

    const apiParams = {
      path: `/v1/data/${encodeURIComponent(resourceType)}/${encodeURIComponent(resourceId)}`,
      query: queryObj,
    };

    return this.soracomApi.callApiWithToken(apiParams);
  }

  private getHarvestDataByUrl(url: string) {
    const apiParams = {
      path: url,
    };

    return this.soracomApi.callApiWithToken(apiParams);
  }

  // TODO: Extract contract codes into a dedicated service (maybe on service migration)
  /**
   * Harvest Data subscription format like this in JWT Token
   *
   * {
   *   "operator": {
   *     "coverageTypeAttributes": {
   *       "jp": {
   *         "contracts": [
   *           "harvest"
   *         ],
   *         "contractDetail": {
   *           "harvest": {
   *             "ttl": "731",
   *           }
   *         }
   *       }
   *     }
   *   }
   * }
   */
  getContract(): HarvestContract {
    const harvest: Contract = 'harvest';
    const coverageType = getCoverageType();
    const contracts = getOperatorData().getContracts(coverageType) ?? [];
    const contractDetail = getOperatorData().getContractDetail(coverageType, harvest);
    if (contracts && contracts.includes(harvest)) {
      if (contractDetail.ttl === HarvestContract.extended.ttl) {
        return HarvestContract.extended;
      } else {
        return new HarvestContract('custom', contractDetail.ttl);
      }
    } else {
      return HarvestContract.normal;
    }
  }

  subscribe() {
    const operatorId = this.loginUserDataService.getLoginUser()?.operatorId;
    const apiParams: SoracomApiParams = {
      method: 'post',
      contentType: 'application/json',
      path: `/v1/operators/${operatorId}/contracts`,
      body: {
        contractName: 'harvest',
        contractDetail: {
          plan: 'extended',
          ttl: 731,
        },
      },
    };

    return this.soracomApi.callApiWithToken(apiParams).then(() => {
      return this.soracomApi.renewToken();
    });
  }

  unsubscribe() {
    const operatorId = this.loginUserDataService.getLoginUser()?.operatorId;
    const apiParams: SoracomApiParams = {
      method: 'delete',
      contentType: 'application/json',
      path: `/v1/operators/${operatorId}/contracts/harvest`,
    };

    return this.soracomApi.callApiWithToken(apiParams).then(() => {
      return this.soracomApi.renewToken();
    });
  }
}
