import { LegacyAny } from '@soracom/shared/core';

import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { AlertsService, AlertsServiceInstance } from '../../components/alerts.service';
import { BaseModalController } from '../../components/base_modal_controller';
import { InjectList } from '../../core/injectable';
import { SigfoxDeviceRegistrationRequest, SigfoxDevicesService } from '../sigfox_devices.service';
import { template } from './register_sigfox_device.component.html';

export class RegisterSigfoxDeviceComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    service: '<',
    resolve: '<',
  };
  controller = RegisterSigfoxDeviceModalController;
  template: any = template;
}

export class RegisterSigfoxDeviceModalController extends BaseModalController {
  static $inject: InjectList = ['$log', 'AlertsService', 'SigfoxDevicesService', 'CoverageTypeService'];

  agreedToTerms = false;
  // @ts-expect-error (legacy code incremental fix)
  alertsService: AlertsServiceInstance;
  submitting = false;

  // @ts-expect-error (legacy code incremental fix)
  deviceId: string;
  // @ts-expect-error (legacy code incremental fix)
  registrationSecret: string;

  /** Only used for BYOD devices (added 2018-04-04). This value is required unless deviceIsPrototype is true (Sigfox allows a limited number of prototype devices, which may lack a product certification ID). */
  productCertificationId?: string;

  /** Only used for BYOD devices (added 2018-04-04). True means the device is a "prototype", so it does not need a product certification ID. */
  deviceIsPrototype = false;

  // @ts-expect-error (legacy code incremental fix)
  name: string;
  // @ts-expect-error (legacy code incremental fix)
  groupName: string;
  // @ts-expect-error (legacy code incremental fix)
  groupId: string;
  // @ts-expect-error (legacy code incremental fix)
  registerSigfoxDeviceForm: ng.IFormController;

  constructor(
    $log: ng.ILogService,
    private AlertsService: AlertsService,
    private SigfoxDevicesService: SigfoxDevicesService,
    public coverageTypeService: CoverageTypeService
  ) {
    super($log);
    this.setTraceEnabled(true);
  }

  $onInit() {
    this.alertsService = this.AlertsService.generate();
  }

  canSubmit() {
    if (this.submitting) {
      return false;
    }

    return !this.registerSigfoxDeviceForm.$invalid;
  }

  registerSigfoxDevice() {
    this.submitting = true;
    const alerts = [];

    let sanitizedProductCertificationId: string | null;

    if (this.deviceIsPrototype) {
      sanitizedProductCertificationId = null;
    } else {
      sanitizedProductCertificationId = this.productCertificationId || '';
    }
    // Mason 2018-04-03: The difference between null and "" is significant; null means "this is a prototype with no productCertificationId" while "" means "this is not a prototype, but the user didn't enter productCertificationId".
    // https://soracom.atlassian.net/browse/SS-2879

    const sigfoxDevice: SigfoxDeviceRegistrationRequest = {
      deviceId: this.deviceId,
      registrationSecret: this.registrationSecret,
      tags: {
        name: this.name,
      },
      productCertificationId: sanitizedProductCertificationId,
    };

    if (this.groupId) {
      sigfoxDevice.groupId = this.groupId;
    }

    this.SigfoxDevicesService.register(sigfoxDevice)
      .then((sigfoxDevice) => {
        this.close(sigfoxDevice);
      })
      .catch((response: LegacyAny) => {
        // Mason 2017-06-09: Sigfox errors.
        // Here we will see not-helpful errors from Sigfox, like this:
        // "{"code":"SEM0112","message":"Failed to create sigfox device FEEDBEEF: <html>\n<head>\n<meta http-equiv=\"Content-Type\" content=\"text/html;charset=utf-8\"/>\n<title>Error 400 Error parsing the JSON body</title>\n</head>\n<body><h2>HTTP ERROR 400</h2>\n<p>Problem accessing /api/devicetypes/592432433c87895f9b466c5c/devices/bulk/create/async. Reason:\n<pre>    Error parsing the JSON body</pre></p>\n</body>\n</html>\n"}"
        //
        // That's not helpful to end user, so let's substitute:
        if (response.data.message.indexOf('<html>') !== -1) {
          const translationId = 'sigfox_devices.errors.unable_to_register_sigfox_device';
          this.alertsService.showAlert('danger', { translationId });
        } else {
          this.alertsService.showError(response);
        }
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
