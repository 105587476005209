import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { Device } from '../core/device';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { InjectList } from '../core/injectable';
import { template } from './device.component.html';
import { DevicesService } from './devices.service';

export class DeviceComponent implements ng.IComponentOptions {
  bindings = {};

  controller = DeviceComponentController;
  template: any = template;
}

export class DeviceComponentController extends BaseController {
  static $inject: InjectList = [
    '$location',
    '$log',
    '$uibModal',
    '$routeParams',
    '$timeout',
    '$translate',
    'AlertsService',
    'DevicesService',
    'FeatureVisibilityService',
    'VisibilityChange',
  ];

  // @ts-expect-error (legacy code incremental fix)
  isWindowVisible: boolean;
  nextTick: LegacyAny;
  isLoading = false;
  alertsService: AlertsServiceInstance;
  // @ts-expect-error (legacy code incremental fix)
  device: Device = null;
  // @ts-expect-error (legacy code incremental fix)
  group: Group = null;
  // @ts-expect-error (legacy code incremental fix)
  activeTab: number;
  autoRefreshInterval = 60000;
  autoRefreshEnabled: boolean;
  serviceName = 'DevicesService';
  panelStatus = {
    tags: true,
    advanced: true,
  };

  constructor(
    private $location: ng.ILocationService,
    $log: ng.ILogService,
    private $uibModal: LegacyAny,
    private $routeParams: LegacyAny,
    private $timeout: ng.ITimeoutService,
    private $translate: LegacyAny,
    alertsServiceGenerator: AlertsService,
    private devicesService: DevicesService,
    private featureVisibilityService: FeatureVisibilityService,
    private visibilityChange: LegacyAny
  ) {
    super($log);
    this.autoRefreshEnabled = false;
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    this.visibilityChange.onVisible(() => {
      this.isWindowVisible = true;
    });

    this.visibilityChange.onHidden(() => {
      this.isWindowVisible = false;
    });

    this.activeTab = 0; // Basic
    this.devicesService
      .get(this.$routeParams.id)
      .then((device) => {
        this.device = device;
        if (this.device.groupId) {
          groupsService
            .getCachedById(this.device.groupId)
            .then((group: LegacyAny) => {
              this.group = group;
            })
            .catch((error: LegacyAny) => {
              this.alertsService.showError(error);
              this.autoRefresh();
            });
        } else {
          this.autoRefresh();
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  readable = this.canOperate('R');
  writable = this.canOperate('W');
  executable = this.canOperate('E');

  onInit() {
    this.activeTab = 0; // Basic
    this.isLoading = true;
    this.devicesService
      .get(this.$routeParams.id)
      .then((device) => {
        this.device = device;
        if (this.device.groupId) {
          groupsService
            .getCachedById(this.device.groupId)
            .then((group: LegacyAny) => {
              this.group = group;
            })
            .catch((error: LegacyAny) => {
              this.alertsService.showError(error);
              this.autoRefresh();
            });
        } else {
          this.autoRefresh();
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onDestroy() {
    if (this.nextTick) {
      this.$timeout.cancel(this.nextTick);
    }
  }

  refresh() {
    this.isLoading = true;
    this.devicesService
      .get(this.$routeParams.id)
      .then((device) => {
        this.device = device;
        this.device.feedbackRefresh = 'success';
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  groupName() {
    if (this.group) {
      return this.group.tags.name ? this.group.tags.name + ' (' + this.group.groupId + ')' : this.group.groupId;
    } else {
      return '';
    }
  }

  statusText() {
    if (this.device && this.device.status) {
      return this.$translate.instant('devices.values.status.' + this.device.status);
    } else {
      return '';
    }
  }

  observable(resource: LegacyAny) {
    return this.readable(resource) && !resource.observed;
  }

  unobservable(resource: LegacyAny): LegacyAny {
    return this.readable(resource) && resource.observed;
  }

  private canOperate(operation: LegacyAny) {
    return (resource: LegacyAny) => {
      return resource.operations.indexOf(operation) >= 0;
    };
  }

  getInstances(objectKey: LegacyAny) {
    return this.device.objects[objectKey].instances;
  }

  getInstance(objectKey: LegacyAny, instanceKey: LegacyAny) {
    return this.getInstances(objectKey)[instanceKey];
  }

  setInstance(objectKey: LegacyAny, instanceKey: LegacyAny, instance: LegacyAny) {
    instance.feedbackUpdateInstance = 'success';
    this.getInstances(objectKey)[instanceKey] = instance;
  }

  hasInstances(objectKey: LegacyAny) {
    const device = this.device;
    if (device && device.objects) {
      const object = device.objects[objectKey];
      if (object) {
        return Object.keys(object).length > 0;
      }
    }
    return false;
  }

  getResource(objectKey: LegacyAny, instanceKey: LegacyAny, resourceKey: LegacyAny) {
    return this.getInstance(objectKey, instanceKey).resources[resourceKey];
  }

  getResources(objectKey: LegacyAny, instanceKey: LegacyAny) {
    return this.getInstance(objectKey, instanceKey).resources;
  }

  setResource(objectKey: LegacyAny, instanceKey: LegacyAny, resourceKey: LegacyAny, attributes: LegacyAny) {
    const resource = this.getResource(objectKey, instanceKey, resourceKey);
    if (attributes.hasOwnProperty('value')) {
      attributes.feedbackUpdateResource = 'success';
    }
    if (resource) {
      Object.assign(resource, attributes);
    } else {
      this.getInstance(objectKey, instanceKey).resources[resourceKey] = attributes;
    }
  }

  getResourcePath(objectKey: LegacyAny, instanceKey: LegacyAny, resourceKey: LegacyAny) {
    return '/' + [objectKey, instanceKey, resourceKey].filter((k) => !!k).join('/');
  }

  showResourceValue(resource: LegacyAny) {
    if (resource && resource.values) {
      return Object.keys(resource.values)
        .map((key) => {
          let value = resource.values[key];
          if (resource.units) {
            value += ` ${resource.units}`;
          }
          return `${key}=${value}`;
        })
        .join(', ');
    } else if (resource && resource.value !== undefined && resource.value !== null) {
      if (resource.units) {
        return resource.value + ' ' + resource.units;
      } else {
        return resource.value;
      }
    } else {
      return null;
    }
  }

  read(objectKey: LegacyAny, instanceKey: LegacyAny, resourceKey: LegacyAny) {
    this.isLoading = true;
    this.devicesService
      .getResource(this.device.deviceId, objectKey, instanceKey, resourceKey)
      .then((response: LegacyAny) => {
        this.debug(response.data);
        if (response.status === 200) {
          this.setResource(objectKey, instanceKey, resourceKey, { value: response.data.value });
        } else {
          const resource = this.getResource(objectKey, instanceKey, resourceKey);
          resource.feedbackUpdateResource = 'failure';
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  openEditDeviceResourceDialog(objectKey: LegacyAny, instanceKey: LegacyAny, resourceKey: LegacyAny) {
    const resource = this.getResource(objectKey, instanceKey, resourceKey);

    const okAction = (value: LegacyAny) => {
      resource.value = value;
      resource.feedbackUpdateResource = 'success';
    };
    const cancelAction = () => {
      this.debug('cancelAction:');
    };

    this.$uibModal
      .open({
        component: 'scEditDeviceResourceModalComponent',
        resolve: {
          resource() {
            return resource;
          },
          value() {
            return resource ? resource.value : null;
          },
          deviceId: () => {
            return this.device.deviceId;
          },
          objectKey() {
            return objectKey;
          },
          instanceKey() {
            return instanceKey;
          },
          resourceKey() {
            return resourceKey;
          },
        },
        backdrop: 'static',
      })
      .result.then(okAction, cancelAction);
  }

  execute(objectKey: LegacyAny, instanceKey: LegacyAny, resourceKey: LegacyAny) {
    const resource = this.getResource(objectKey, instanceKey, resourceKey);
    this.debug(resource);
    this.$uibModal
      .open({
        component: 'scExecuteDeviceResourceModalComponent',
        resolve: {
          resource() {
            return resource;
          },
        },
        backdrop: 'static',
      })
      .result.then(
        (value: LegacyAny) => {
          const body = { value };
          this.isLoading = true;
          this.devicesService
            .executeResource(this.device.deviceId, objectKey, instanceKey, resourceKey, body)
            .then((response: LegacyAny) => {
              if (response.status === 202) {
                this.debug(response.data);
                resource.feedbackExecuteResource = 'success';
              } else {
                resource.feedbackExecuteResource = 'failure';
              }
            })
            .catch((error: LegacyAny) => {
              resource.feedbackExecuteResource = 'failure';
              this.alertsService.showError(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
        () => {
          angular.noop();
        }
      );
  }

  observe(objectKey: LegacyAny, instanceKey: LegacyAny, resourceKey: LegacyAny) {
    const resource = this.getResource(objectKey, instanceKey, resourceKey);
    this.isLoading = true;
    this.devicesService
      .observeResource(this.device.deviceId, objectKey, instanceKey, resourceKey)
      .then((response: LegacyAny) => {
        this.debug(response.data);
        if (response.status === 200) {
          const attributes = response.data;
          attributes.observed = true;
          this.setResource(objectKey, instanceKey, resourceKey, attributes);
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  unobserve(objectKey: LegacyAny, instanceKey: LegacyAny, resourceKey: LegacyAny) {
    const resource = this.getResource(objectKey, instanceKey, resourceKey);
    this.isLoading = true;
    this.devicesService
      .unobserveResource(this.device.deviceId, objectKey, instanceKey, resourceKey)
      .then((response: LegacyAny) => {
        this.debug(response.data);
        if (response.status === 204) {
          const attributes = {
            observed: false,
          };
          this.setResource(objectKey, instanceKey, resourceKey, attributes);
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  readInstance(objectKey: LegacyAny, instanceKey: LegacyAny) {
    this.isLoading = true;
    this.devicesService
      .getInstance(this.device.deviceId, objectKey, instanceKey)
      .then((response: LegacyAny) => {
        this.debug(response.data);
        if (response.status === 200) {
          this.setInstance(objectKey, instanceKey, response.data);
        } else {
          this.alertsService.showError(response.data.message);
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  observeInstance(objectKey: LegacyAny, instanceKey: LegacyAny) {
    const instance = this.getInstance(objectKey, instanceKey);
    this.isLoading = true;
    this.devicesService
      .observeInstance(this.device.deviceId, objectKey, instanceKey)
      .then((response: LegacyAny) => {
        this.debug(response.data);
        if (response.status === 200) {
          const attributes = {
            observed: true,
            feedbackObserveInstance: 'success',
          };
          Object.assign(instance, attributes);
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  unobserveInstance(objectKey: LegacyAny, instanceKey: LegacyAny) {
    const instance = this.getInstance(objectKey, instanceKey);
    this.isLoading = true;
    this.devicesService
      .unobserveInstance(this.device.deviceId, objectKey, instanceKey)
      .then((response: LegacyAny) => {
        this.debug(response.data);
        if (response.status === 204) {
          const attributes = {
            observed: false,
          };
          Object.assign(instance, attributes);
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  deleteDevice() {
    this.devicesService
      .delete(this.$routeParams.id)
      .then(() => {
        this.$location.path('/devices');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  toggleAutoRefresh() {
    if (this.autoRefreshEnabled) {
      this.nextTick = this.$timeout(this.autoRefresh, this.autoRefreshInterval);
      this.debug('Auto Refresh is enabled. Scheduled next tick.');
    } else if (this.nextTick) {
      this.$timeout.cancel(this.nextTick);
      this.nextTick = null;
      this.debug('Auto Refresh is cancelled.');
    }
  }

  autoRefresh() {
    if (this.autoRefreshEnabled) {
      if (this.isWindowVisible) {
        this.refresh();
      } else {
        this.debug('Auto Refresh was skipped because browser is hidden.');
      }

      this.nextTick = this.$timeout(this.autoRefresh, this.autoRefreshInterval);
      this.debug('Auto Refresh is enabled. Scheduled next tick.');
    } else {
      this.debug('Auto Refresh is disabled. Operation was cancelled.');
    }
  }
}
