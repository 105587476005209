import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroupOf } from '@soracom/shared-ng/util-common';
import { LagoonUser, LagoonUserRole } from '../../../../app/shared/core/lagoon_user';
import {
  LagoonUserPermissionUpdatingRequest,
  LagoonUsersService,
} from '../../../../app/shared/lagoon/lagoon_users.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-update-lagoon-user-permission-modal',
  templateUrl: './update-lagoon-user-permission-modal.component.html',
})
export class UpdateLagoonUserPermissionModalComponent
  extends DsModalContentBase<LagoonUser, { success: boolean }>
  implements OnInit
{
  loading = false;
  // @ts-expect-error (legacy code incremental fix)
  form: FormGroupOf<LagoonUserPermissionUpdatingRequest> = this.fb.group({
    role: ['', [Validators.required]],
  });
  roles = [LagoonUserRole.viewer, LagoonUserRole.editor];
  alertsManager = new AlertsManager();
  get canConfirm(): boolean {
    return this.form.valid && this.form.value.role != this.getInput().role;
  }
  canClose(): boolean {
    return !this.loading;
  }

  constructor(private fb: FormBuilder, private lagoonUsersService: LagoonUsersService) {
    super();
  }

  ngOnInit(): void {
    this.form.patchValue({ role: this.getInput().role });
  }

  confirm(): void {
    if (this.canConfirm) {
      const req = this.form.value as LagoonUserPermissionUpdatingRequest;
      this.loading = true;
      this.lagoonUsersService
        .updatePermission(this.getInput().id, req)
        .then(() => {
          this.loading = false;
          this.close({ success: true });
        })
        .catch((error: LegacyAny) => {
          this.alertsManager.add(Alert.fromApiError(error));
          this.loading = false;
        });
    }
  }
}
