/* eslint-disable @angular-eslint/component-selector */
import { Component, HostListener, inject } from '@angular/core';
import { ChatBroadcastChannel } from '@soracom/shared/conversations';
import { SidebarService } from '@soracom/shared-ng/ui-sidebar';
import { SidebarOptions, UiSidebar } from '@soracom/shared/ui-sidebar';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';

@Component({
  selector: 'button[uiAskAiButton]',
  standalone: true,
  providers: [SidebarService],

  template: '<span>Ask&#8201;AI</span>',
  host: {
    id: 'global-ask-ai-button',
    'data-testid': 'global-ask-ai-button',
    'data-ga-event-name': 'ask_ai',
    'data-ga-event-label': 'Ask AI',
    class: 'ds-button --ai __ask-ai',
  },
})
export class UiAskAiButtonComponent {
  featureVisibilityService = inject(FeatureVisibilityService);
  sidebarService = inject(SidebarService);

  get isSidebarVisible() {
    return !!UiSidebar.currentUrl;
  }

  @HostListener('click')
  onClick() {
    this.openChatWindow();
  }

  get isChatEnabled() {
    return this.featureVisibilityService.isEnabled('supportBot');
  }

  chatBroadcaster = new ChatBroadcastChannel();
  chatBroadcastEvent = {
    chatLaunchedFromUrl: '',
    currentUrl: '',
    mood: '',
  };

  openChatWindow() {
    this.sidebarService.toggle('support', {});
  }
}
