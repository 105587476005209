<main class="ds-app__main-container  lagoon-page-root">
  <ng-container *ngIf="hasSubscription">
    <section class="ds-app__main-content ds-cols --no-gap">
      <div class="ds-span --2 --recess">
        <div class="ds-menu">
          <ul>
            <li>
              <a routerLink="/lagoon" [ngClass]="{ '--selected': isActive('index') }">
                {{ 'LagoonPageComponent.tab.index.title' | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/lagoon/users" [ngClass]="{ '--selected': isActive('users') }">
                {{ 'LagoonPageComponent.tab.users.title' | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/lagoon/dashboards" [ngClass]="{ '--selected': isActive('dashboards') }">
                {{ 'LagoonPageComponent.tab.dashboards.title' | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/lagoon/customize" [ngClass]="{ '--selected': isActive('customize') }">
                {{ 'LagoonPageComponent.tab.customize.title' | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/lagoon/subscription" [ngClass]="{ '--selected': isActive('subscription') }">
                {{ 'LagoonPageComponent.tab.subscription.title' | translate }}
              </a>
            </li>
          </ul>
          <div class="ds-text--label">{{ 'LagoonPageComponent.menu_header.lagoon_console' | translate }}</div>
          <ul>
            <li *appFeature="'lagoon3'">
              <a [href]="lagoon3Url()" target="_blank" *ngIf="enabledLagoonV3"> {{ 'LagoonComponent.link_to_lagoon_3' |
                translate }} </a>
            </li>
            <li>
              <a [href]="lagoon2Url()" target="_blank" *ngIf="enabledLagoonV2"> {{ 'LagoonComponent.link_to_lagoon_2' |
                translate }} </a>
            </li>
          </ul>
          <div class="ds-text--label">{{ 'LagoonPageComponent.menu_header.documentation' | translate }}</div>
          <ul>
            <ng-container *appFeature="'lagoon3'">
              <li>
                <a href="https://users.soracom.io/ja-jp/docs/lagoon-v3/" target="_blank"> {{
                  'LagoonComponent.document_lagoon_3' | translate }} </a>
              </li>
              <li *ngIf="enabledLagoonV2">
                <a href="https://users.soracom.io/ja-jp/docs/lagoon-v2/" target="_blank"> {{
                  'LagoonComponent.document_lagoon_2' | translate }} </a>
              </li>
              <li *ngIf="enabledLagoonV2 && enabledLagoonV3">
                <a href="https://users.soracom.io/ja-jp/docs/lagoon-v3/migration-guide/" target="_blank"> {{
                  'LagoonComponent.migration_guide' | translate }} </a>
              </li>
            </ng-container>
            <ng-container *appNoFeature="'lagoon3'">
              <li>
                <a href="https://users.soracom.io/ja-jp/docs/lagoon-v2/" target="_blank"> {{
                  'LagoonComponent.document' | translate }} </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="ds-span --10 ds-rows --indent">
        <ng-content></ng-content>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="!hasSubscription">
    <section class="ds-app__main-content ds-rows">
      <app-lagoon></app-lagoon>
    </section>
  </ng-container>
</main>
