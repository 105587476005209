import { LegacyAny } from '@soracom/shared/core';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BUILD_INFO } from '@soracom/shared/build-info';
import { RemoteLogger } from '@soracom/shared/remote-logging';
// -----------------------------------------------------------------------------
// description forthcoming
// -----------------------------------------------------------------------------
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { injectGoogleTagManagerCode } from '@soracom/shared/google-tag-manager';

RemoteLogger.initRemoteLogger({
  envName: environment.name,
  dataDogToken: environment.dataDogToken,
  serviceName: 'soracom-user-console',
  version: BUILD_INFO.fullBuildString,
});

if (environment.production) {
  enableProdMode();
}

const googleTagManagerId = environment.googleTagManagerId;
if (googleTagManagerId) {
  injectGoogleTagManagerCode(googleTagManagerId);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: LegacyAny) => console.error(err));
