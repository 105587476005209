<ng-container *ngIf="vizType$ | async as vizType">
  <div class="harvest-data-viz-area">
    <aside class="harvest-data-viz-control-panel">
      <details class="ds-disclosure--simple" open>
        <summary class="ds-text--label">Data Series</summary>
        <app-harvest-data-chart-dataseries-control
          [dataSeries]="dataSeriesOptions"
          [visibilities]="visibilities"
          (valueChange)="onVisibilityChange($event)"
        >
        </app-harvest-data-chart-dataseries-control>
      </details>
      <details class="ds-disclosure--simple" open>
        <summary class="ds-text--label">Y Axis</summary>
        <app-harvest-chart-yaxis-control [value]="yAxisCtrlData" (valueChange)="onYAxisCtrlChange($event)">
        </app-harvest-chart-yaxis-control>
      </details>
    </aside>

    <app-harvest-data-chart
      class="harvest-data-viz-chart"
      [type]="$any(vizType)"
      [datasets]="datasets"
      [labels]="labels"
      [visibilities]="visibilities"
      [yAxisCtrl]="yAxisCtrlData"
      (dataClick)="onChartDataClick($event)"
      (visibilitiesChange)="onVisibilityChange($event)"
    >
    </app-harvest-data-chart>

    <app-harvest-data-chart-preview-panel [data]="previewData" class="harvest-data-viz-preview-panel">
    </app-harvest-data-chart-preview-panel>
  </div>
</ng-container>
