import { LegacyAny } from '@soracom/shared/core';

import { CoverageType } from '@foundation/coverage-type';
import { Group } from '@soracom/shared/group';

import { PaginationService, SearchQuery } from '@user-console/legacy-soracom-api-client';
import { PaginationOptions, ScRelation } from '../components/paginator';
import { SoracomApiService } from '../components/soracom_api.service';
import { ApiEvent } from '../core/api_event';
import {
  LegacyBaseSoracomApiService,
  ListApiOptions,
  PaginatableService,
  SoracomApiParams,
  TagParams,
  TaggableService,
} from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';
import { ExtendedSubscriber as LegacySubscriber, ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberSearchOption } from '../subscribers/enumerators/subscriber_enumerator_base';
import { GroupMap } from './groups_cache';
import { GroupsCacheService } from './groups_cache.service';

const DEFAULT_LIMIT = 100;
export class GroupsService implements TaggableService, PaginatableService<Group> {
  static $inject: InjectList = [
    '$log',
    '$q',
    '$rootScope',
    'BaseSoracomApiService',
    'GroupsCacheService',
    'PaginationService',
    'SoracomApi',
  ];

  resourcePath = 'groups';

  private loading = false;

  constructor(
    private $log: ng.ILogService,
    private $q: ng.IQService,
    $rootScope: ng.IRootScopeService,
    private baseSoracomApiService: LegacyBaseSoracomApiService,
    private groupsCacheService: GroupsCacheService,
    private paginationService: PaginationService,
    private soracomApi: SoracomApiService,
  ) {
    $rootScope.$on(ApiEvent.Logout, () => this.reset());
  }

  //////////////////////////////////////////////////////////////////////////////
  // Cache handling
  //////////////////////////////////////////////////////////////////////////////

  reset() {
    this.loading = false;
    this.groupsCacheService.resetAll();
  }

  fetchAllPromise: Promise<GroupMap> | null = null;

  list(
    paginationOptions: PaginationOptions,
    searchQuery?: SearchQuery | undefined,
    apiOptions?: ListApiOptions,
  ): Promise<ScRelation<Group>> {
    const apiParams = {
      method: 'get',
      path: '/v1/groups',
      query: { ...paginationOptions },
    };
    return this.baseSoracomApiService
      .callApiWithToken(apiParams, (apiOptions as LegacyAny).coverageType)
      .then((response: LegacyAny) => {
        const data = response.data.map((element: any) => new Group(element));
        const links = this.paginationService.getPaginationLinks(response.headers.link);
        const relation: ScRelation<Group> = {
          data,
          links,
        };
        return relation;
      });
  }

  listAll(
    lastEvaluatedKey: string | undefined = undefined,
    groups: Group[] = [],
    coverageType?: CoverageType,
  ): Promise<Group[]> {
    return this.list({ limit: DEFAULT_LIMIT, last_evaluated_key: lastEvaluatedKey }, undefined, { coverageType }).then(
      (relation) => {
        groups.push(...relation.data);
        const newLastEvaluatedKey = relation.links.next?.lastEvaluatedKey;
        if (newLastEvaluatedKey) {
          return this.listAll(newLastEvaluatedKey, groups, coverageType);
        } else {
          return groups;
        }
      },
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  // Data IO
  //////////////////////////////////////////////////////////////////////////////

  get(id: string, coverageType?: CoverageType): Promise<Group> {
    const apiParams: SoracomApiParams = {
      method: 'GET',
      path: `/v1/groups/${id}`,
      contentType: 'application/json',
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams, coverageType).then((response: LegacyAny) => {
      return new Group(response.data);
    });
  }

  updateTags(id: string, tags: TagParams[]) {
    const apiParams = {
      method: 'put',
      path: `/v1/${this.resourcePath}/${id}/tags`,
      contentType: 'application/json',
      body: tags,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  deleteTag(id: string, tagName: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/${this.resourcePath}/${id}/tags/${encodeURIComponent(tagName)}`,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  fetchSubscribers(
    groupId: string,
    query: SubscriberSearchOption,
    coverageType?: CoverageType,
  ): Promise<ScRelation<ExtendedSubscriberInterface>> {
    const apiParams: SoracomApiParams = {
      path: '/v1/groups/' + groupId + '/subscribers',
      query: query as any,
    };

    return this.baseSoracomApiService.callApiWithToken(apiParams, coverageType).then((response: LegacyAny) => {
      const data = response.data.map((element: LegacyAny) => new LegacySubscriber(element));
      const links = this.paginationService.getPaginationLinks(response.headers.link);
      const relation: ScRelation<ExtendedSubscriberInterface> = {
        data,
        links,
      };

      return relation;
    });
  }
}
