
  

  <ng-container *ngFor="let msg of conversation?.messages">
    <!-- Input data -->
    <details class="ds-disclosure" *ngIf="msg.role === 'ui-input'">
      <summary class="ds-text --icon-form">{{'harvestDataIntelligence.inputData' | translate}}</summary>
      <figure class="ds-codeblock">
        <pre class="--code"><code>{{msg.content}}</code></pre>
      </figure>
    </details>
    
    <!-- User Question -->
    <div *ngIf="msg.role === 'user'" class="ds-group --indent-small --flow-down --color-blue">
      <div class="ds-cols --no-wrap">
        <div>
          <i class="ds-icon --icon-comment-question --medium --color-celeste-light"></i>
        </div>
        <div>
          <p *ngFor="let l of lines(msg.content)">{{ l }}</p>
        </div>
      </div>
    </div>

    <!-- Ai Message -->
    <div *ngIf="msg.role === 'assistant'" class="ds-group --indent-small --color-ink-lighter">
      <div class="ds-cols --no-wrap">
        <div><i class="ds-icon --icon-atom --medium --color-ink"></i></div>
        <div>
          <p *ngFor="let l of lines(msg.content)">{{ l }}</p>
        </div>
      </div>
    </div>

    <!-- Ui Error  -->
    <div *ngIf="msg.role === 'ui-error'" class="ds-group --indent-small">
      <div class="ds-cols --no-wrap">
        <div><i class="ds-icon --icon-warning --medium --alert"></i></div>
        <div>
          <p class="--alert" *ngFor="let l of lines(msg.content)">{{ l }}</p>
        </div>
      </div>
    </div>

    <!-- Ui Confirmation -->
    <div *ngIf="msg.role === 'ui-confirmation'" class="ds-notice --center --plain">
      <i class="ds-icon --icon-thumbs-up --info --small"></i>
      <p class="ds-text --info">
        {{ msg.content }}
      </p>
    </div>

    <!-- no display for system messages -->
  </ng-container>
  