import { LegacyAny } from '@soracom/shared/core';

import { template } from './ignore_prefixed_event_handlers_by_imsi.component.html';

import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';
import { TargetType } from '../event_handlers/event_handler';
import { EventHandlersService } from '../event_handlers/event_handlers.service';
import { PrefixedEventHandlerViewModel, PrefixedEventHandlerViewModelCollection } from './prefixed_event_handler';

export class IgnorePrefixedEventHandlersByImsiComponent implements ng.IComponentOptions {
  bindings = {
    imsi: '<',
    target: '@',
    settings: '<',
  };

  controller = IgnorePrefixedEventHandlersByImsiComponentController;
  template: any = template;
}

export class IgnorePrefixedEventHandlersByImsiComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$q', 'AlertsService', 'EventHandler'];

  alertsService: AlertsServiceInstance;
  inputNames = {
    sma: {
      DailyTrafficRule: 'smaDailyLimit',
      MonthlyTrafficRule: 'smaMonthlyLimit',
      CumulativeTrafficRule: 'smaCumulativeLimit',
      MonthlyTotalTrafficRule: 'smaMonthlyTotalLimit',
    },
    csca: {
      DailyTrafficRule: 'cscaDailyLimit',
      MonthlyTrafficRule: 'cscaMonthlyLimit',
      CumulativeTrafficRule: 'cscaCumulativeLimit',
      MonthlyTotalTrafficRule: 'cscaMonthlyTotalLimit',
    },
  };
  // @ts-expect-error (legacy code incremental fix)
  ignorePrefixedEventHandlersForm: ng.IFormController;
  // @ts-expect-error (legacy code incremental fix)
  imsi: string;
  // @ts-expect-error (legacy code incremental fix)
  settings: PrefixedEventHandlerViewModelCollection;
  // @ts-expect-error (legacy code incremental fix)
  target: TargetType.group | TargetType.operator;
  submitting = false;

  constructor(
    $log: ng.ILogService,
    private $q: ng.IQService,
    alertsServiceGenerator: AlertsService,
    private eventHandlersService: EventHandlersService
  ) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  updateSettings() {
    this.submitting = true;
    const promises: LegacyAny = [];
    this.debug(this.ignorePrefixedEventHandlersForm);
    // @ts-expect-error (legacy code incremental fix)
    Object.keys(this.settings.SendMailToOperatorAction).forEach((ruleType) => {
      // @ts-expect-error (legacy code incremental fix)
      const properties = this.settings.SendMailToOperatorAction[ruleType];
      const name = (this.inputNames.sma as LegacyAny)[ruleType];
      const formControl = this.ignorePrefixedEventHandlersForm[name];
      if (!name || !formControl) {
        return;
      }
      if (formControl.$dirty) {
        this.debug(name);
        promises.push(this.updateSetting(properties));
      }
    });
    // @ts-expect-error (legacy code incremental fix)
    Object.keys(this.settings.ChangeSpeedClassAction).forEach((ruleType) => {
      // @ts-expect-error (legacy code incremental fix)
      const properties = this.settings.ChangeSpeedClassAction[ruleType];
      const name = (this.inputNames.csca as LegacyAny)[ruleType];
      const formControl = this.ignorePrefixedEventHandlersForm[name];
      if (!name || !formControl) {
        return;
      }
      if (formControl.$dirty) {
        this.debug(name);
        promises.push(this.updateSetting(properties));
      }
    });

    this.$q
      .all(promises)
      .then(() => {
        this.ignorePrefixedEventHandlersForm.$setPristine();
        this.submitting = false;
        this.alertsService.showSuccess('watch.ignore.updated_successfully');
      })
      .catch((error: LegacyAny) => {
        this.submitting = false;
        this.alertsService.showError(error);
      });
  }

  shouldShowMonthlyTotalLimit(): boolean {
    return this.target === TargetType.operator;
  }

  canSubmit(): boolean {
    return this.ignorePrefixedEventHandlersForm.$dirty && !this.submitting;
  }

  private updateSetting(vm: PrefixedEventHandlerViewModel) {
    if (vm.ignored === true) {
      // @ts-expect-error (legacy code incremental fix)
      return this.eventHandlersService.ignore(vm.handlerId, this.imsi);
    } else if (vm.ignored === false) {
      // @ts-expect-error (legacy code incremental fix)
      return this.eventHandlersService.unsetIgnore(vm.handlerId, this.imsi);
    } else {
      return this.$q.resolve();
    }
  }
}
