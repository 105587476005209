<ng-container *ngFor="let c of candidates">
  <button
    class="btn btn-default"
    style="height: 40px"
    [ngClass]="{ active: value === c.value }"
    (click)="onChange(c.value)"
    [title]="c.displayValue"
  >
    <i [ngClass]="c.iconClass"></i>
  </button>
</ng-container>
