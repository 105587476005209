import { Injectable, NgZone } from '@angular/core';
import {
  SidebarOptions,
  UiSidebar,
  UiSidebarAppId,
} from '@soracom/shared/ui-sidebar';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 The Angular wrapper for `UiSidebar`. Note that if you use this, you should always use this; don't use `UiSidebar` directly when using this, or else they (maybe) might get out of sync.
 */
@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private sidebarStateSubject = new BehaviorSubject<boolean>(false);

  constructor(private ngZone: NgZone) {
    // Check if sidebar should be open on initialization
    this.checkInitialState();
    UiSidebar.init();
  }

  private checkInitialState() {
    const url = new URL(window.location.href);
    const sidebarParam = url.searchParams.get('sidebar');
    if (sidebarParam === 'open') {
      this.sidebarStateSubject.next(true);
    }
  }

  show(appId: UiSidebarAppId, options: SidebarOptions): void {
    this.ngZone.run(() => {
      UiSidebar.show(appId, options);
      this.sidebarStateSubject.next(true);
    });
  }

  hide(appId: UiSidebarAppId): void {
    this.ngZone.run(() => {
      UiSidebar.hide(appId);
      this.sidebarStateSubject.next(false);
    });
  }

  toggle(appIdIfShown: UiSidebarAppId, options: SidebarOptions): void {
    this.ngZone.run(() => {
      UiSidebar.toggle(appIdIfShown, options);
      this.sidebarStateSubject.next(!this.sidebarStateSubject.value);
    });
  }

  get isSidebarOpen$(): Observable<boolean> {
    return this.sidebarStateSubject.asObservable();
  }

  get currentUrl(): string | undefined {
    return UiSidebar.currentUrl;
  }
}
