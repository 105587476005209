import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { LteMButtonPageState } from '../../root-store/lte-m-button-store/state';

export interface LteMButtonStateChangedEvent {
  newState: LteMButtonPageState;
  attributes?: {
    imsiList?: string[];
    groupId?: string;
    newGroupName?: string;
    selectedSubscribers?: ExtendedSubscriberInterface[];
  };
}

@Component({
  selector: 'app-lte-m-button',
  templateUrl: './lte-m-button.component.html',
})
export class LteMButtonComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() state: LteMButtonPageState;
  @Output() onStateChanged = new EventEmitter<LteMButtonStateChangedEvent>();

  private logger: Logger = LoggerService.shared();

  relayOnStateChanged(event: LteMButtonStateChangedEvent) {
    this.logger.debug('relayOnStateChanged(event: LteMButtonStateChangedEvent)');
    this.onStateChanged.emit(event);
  }
}
