import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { Alert, AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { Logger } from '@soracom/shared/logger';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import {
  LongTermDiscountPaymentType,
  LongTermDiscountType,
  VolumeDiscountResponse,
} from '../long-term-discounts/VolumeDiscount';

import { DateUtils } from '@soracom/shared/util-common';

class AvailableLongTermDiscountModel {
  volumeDiscountType: LongTermDiscountType;
  volumeDiscountPaymentType: LongTermDiscountPaymentType;
  contractTermMonth: number;
  unitPrice: number;
  taxIncludedUnitPrice: number;

  constructor(params: any = {}) {
    this.volumeDiscountType = params.volumeDiscountType;
    this.volumeDiscountPaymentType = params.volumeDiscountPaymentType;
    this.contractTermMonth = params.contractTermMonth;
    this.unitPrice = params.unitPrice;
    this.taxIncludedUnitPrice = params.taxIncludedUnitPrice;
  }

  get key(): string {
    return [this.volumeDiscountType, this.volumeDiscountPaymentType, this.contractTermMonth].join('-');
  }
}

/**
 * Component for making a Long Term Discount quotation request. Shown in modal.
 */
@Component({
  selector: 'app-new-long-term-discount',
  templateUrl: './new-long-term-discount.component.html',
})
export class NewLongTermDiscountComponent extends DsModalContentBase<void, VolumeDiscountResponse> implements OnInit {
  alertsManager = new AlertsManager();
  // @ts-expect-error (legacy code incremental fix)
  output: VolumeDiscountResponse;

  get canConfirm() {
    return !this.submitting;
  }

  // FIXME: use a controller state not booleans -- for now I am just porting the AngularJS code with minimal changes though
  submitting = false;

  date = new Date();

  /**
   * Sane people use Date but HTML is not a sane person so it uses '1974-09-05' style strings to represent dates. So we have to convert those to Date objects and vice versa when binding to the HTML date input.
   */
  get dateInHtmlFormat() {
    return DateUtils.to('YYYY-MM-DD', this.date);
  }

  set dateInHtmlFormat(value: string) {
    this.date = new Date(value);
  }

  models: AvailableLongTermDiscountModel[] = [];
  // @ts-expect-error (legacy code incremental fix)
  unitPrice: number;
  // @ts-expect-error (legacy code incremental fix)
  taxIncludedUnitPrice: number;

  defaultData: any = {
    // body data for submit
    volumeDiscountType: 'SORACOM_AIR_BASIC_CHARGE_V2',
    volumeDiscountPaymentType: 'MONTHLY',
    contractTermMonth: 12,
    quantity: 1,
    startDate: DateUtils.to('YYYYMMDD', this.date),
    startDateInMilliseconds: this.date.getTime(),
  };

  data = this.defaultData;

  minDate = new Date();

  // @masonmark: I think this is a defect in the original code, it should just be optional as far as I can tell, but just leaving it as null in case there is some special meaning to null I haven't found
  maxDate: Date | null = null;

  get minDateInHtmlFormat() {
    return DateUtils.to('YYYY-MM-DD', this.minDate);
  }

  set minDateInHtmlFormat(value: string) {
    this.minDate = new Date(value);
  }

  get maxDateInHtmlFormat(): string | null {
    return this.maxDate ? DateUtils.to('YYYY-MM-DD', this.maxDate) : null;
  }

  set maxDateInHtmlFormat(value: string) {
    this.maxDate = new Date(value);
  }

  today = new Date();
  allowedDiscountTypeDefinitions: Array<{ nameId: string; value: LongTermDiscountType }> = [];
  allowedPaymentTypeDefinitions: Array<{ nameId: string; value: LongTermDiscountPaymentType }> = [];
  allowedTermDefinitions: Array<{ nameId: string; value: number }> = [];

  get networkActivityMessageId() {
    return this.submitting ? 'long_term_discount.new_long_term_discount_modal_sending' : null;
  }

  constructor(private apiService: SoracomApiService, private logger: Logger) {
    super();
  }

  ngOnInit() {
    this.apiService
      .listAvailableLongTermDiscountPlans()
      .then((result: LegacyAny) => {
        if (
          result.data &&
          result.data.availableLongTermDiscounts &&
          result.data.availableLongTermDiscounts.length > 0
        ) {
          this.models = result.data.availableLongTermDiscounts.map(
            (r: LegacyAny) => new AvailableLongTermDiscountModel(r)
          );

          // Initialize discount type
          const types: LegacyAny = [];
          this.models.forEach((model: LegacyAny) => {
            if (!types.includes(model.volumeDiscountType)) {
              types.push(model.volumeDiscountType);
            }
          });
          this.allowedDiscountTypeDefinitions = types.map((type: LegacyAny) => ({
            nameId: `long_term_discount.discount_type.${type}`,
            value: type,
          }));
          this.onVolumeDiscountTypeChanged();
        }
      })
      .catch((e: LegacyAny) => {
        this.logger.error(e);
      });
  }

  onVolumeDiscountTypeChanged() {
    this.updateAllowedPaymentTypeDefinitions();
    if (
      !this.allowedPaymentTypeDefinitions.map((d: LegacyAny) => d.value).includes(this.data.volumeDiscountPaymentType)
    ) {
      this.data.volumeDiscountPaymentType = this.allowedPaymentTypeDefinitions[0].value;
    }
    this.onVolumeDiscountPaymentTypeChanged();
  }

  onVolumeDiscountPaymentTypeChanged() {
    this.updateAllowedTermDefinitions();
    if (!this.allowedTermDefinitions.map((d: LegacyAny) => d.value).includes(this.data.contractTermMonth)) {
      this.data.contractTermMonth = this.allowedTermDefinitions[0].value;
    }
    this.onVolumeDiscountContractTermChanged();
  }

  onVolumeDiscountContractTermChanged() {
    this.updateUnitPrice();
  }

  confirm() {
    this.submitting = true;

    // go from JS Date object to YYYYMMDD:
    this.data.startDate = DateUtils.to('YYYYMMDD', this.date);

    // // FIXME: The api was changed to use 'startDate' not
    // // 'fromDate' but I think yaman needs to update the
    // // backend to use this new naming here. It still
    // // (as of 2017-05-22) accepts fromDate but not startDate
    // // so I dupe the value here as a temporary hack:
    // this.data.fromDate = this.data.startDate;

    this.apiService
      .createLongTermDiscount(this.data)
      .then((response: LegacyAny) => {
        this.logger.debug('LTD created', response);
        this.close(response.data as VolumeDiscountResponse);
      })
      .catch((error: LegacyAny) => {
        const errorAlert = Alert.danger(error);
        this.alertsManager.add(errorAlert);
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  private updateAllowedPaymentTypeDefinitions() {
    const paymentTypes: LegacyAny = [];
    this.models
      .filter((model: LegacyAny) => model.volumeDiscountType === this.data.volumeDiscountType)
      .forEach((model: LegacyAny) => {
        if (!paymentTypes.includes(model.volumeDiscountPaymentType)) {
          paymentTypes.push(model.volumeDiscountPaymentType);
        }
      });
    this.allowedPaymentTypeDefinitions = paymentTypes.map((paymentType: LegacyAny) => ({
      nameId: `long_term_discount.payment_type.${paymentType}`,
      value: paymentType,
    }));
  }

  private updateAllowedTermDefinitions() {
    const terms: number[] = [];
    this.models
      .filter((model: LegacyAny) => model.volumeDiscountType === this.data.volumeDiscountType)
      .filter((model: LegacyAny) => model.volumeDiscountPaymentType === this.data.volumeDiscountPaymentType)
      .forEach((model: LegacyAny) => {
        if (!terms.includes(model.contractTermMonth)) {
          terms.push(model.contractTermMonth);
        }
      });
    this.allowedTermDefinitions = terms.map((term) => ({
      nameId: `long_term_discount.fixed_term.${term}`,
      value: term,
    }));
  }

  private updateUnitPrice() {
    const key = [this.data.volumeDiscountType, this.data.volumeDiscountPaymentType, this.data.contractTermMonth].join(
      '-'
    );
    const model = this.models.find((m) => m.key === key);
    // @ts-expect-error (legacy code incremental fix)
    this.unitPrice = model ? model.unitPrice : undefined;
    // @ts-expect-error (legacy code incremental fix)
    this.taxIncludedUnitPrice = model ? model.taxIncludedUnitPrice : undefined;
  }
}
