import { LegacyAny } from '@soracom/shared/core';

import { Harvestable, Taggable, Terminatable } from '@soracom/shared/soracom-platform';
import { BaseModel } from '@soracom/shared/soracom-platform';
import { Group, Groupable } from '@soracom/shared/group';

export type LoraDeviceStatus = 'active' | 'terminated';

export class LoraDevice extends BaseModel implements Taggable, Groupable, Harvestable, Terminatable {
  static resourcePath = 'lora_devices';
  // Since some devices from some vendors can not receive the downlink data with
  // fPort=1, the default value of fPort is set to 2, which is the minimum
  // available number of fPort.
  static readonly defaultFport = 2;

  // @ts-expect-error (legacy code incremental fix)
  deviceId: string;
  // @ts-expect-error (legacy code incremental fix)
  operatorId: string;
  // @ts-expect-error (legacy code incremental fix)
  status: LoraDeviceStatus;
  // @ts-expect-error (legacy code incremental fix)
  terminationEnabled: boolean;
  // @ts-expect-error (legacy code incremental fix)
  lastModifiedTime: string;
  // @ts-expect-error (legacy code incremental fix)
  lastSeen: {
    rssi: number;
    snr: number;
    time: string;
  };

  // @ts-expect-error (legacy code incremental fix)
  groupId: string;
  tags: { [id: string]: string };

  // @ts-expect-error (legacy code incremental fix)
  private _group: Group;

  constructor(params?: LegacyAny) {
    super();
    this.tags = {};
    if (params) {
      this.deviceId = params.deviceId;
      this.operatorId = params.operatorId;
      this.status = params.status;
      this.terminationEnabled = params.terminationEnabled;
      this.lastModifiedTime = params.lastModifiedTime;
      this.lastSeen = params.lastSeen || {};

      this.groupId = params.groupId;
      this.tags = params.tags || {};
    }
  }

  get id(): string {
    return this.deviceId;
  }

  get loraDeviceId(): string {
    return this.deviceId;
  }

  set loraDeviceId(deviceId: string) {
    this.deviceId = deviceId;
  }

  get resourceId(): string {
    return this.deviceId;
  }

  readonly resourceType = 'LoraDevice';

  get name() {
    return this.tags?.name;
  }

  groupName() {
    if (this._group) {
      return this._group.name;
    } else {
      return null;
    }
  }

  get group(): Group {
    return this._group;
  }

  set group(group: Group) {
    this._group = group;
    // @ts-expect-error (legacy code incremental fix)
    this.groupId = group ? group.groupId : null;
  }

  isActivated(): boolean {
    return this.status === 'active';
  }

  isTerminated(): boolean {
    return this.status === 'terminated';
  }
}
