import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { UnifiedEndpointResponseFormat } from '@soracom/shared/group';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscribersService } from '../../../../app/shared/subscribers/subscribers.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { multipleEmailValidator } from '../../core/form-validators/MultipleEmailValidator';
import { encode, encodedTextMaxLengthValidator } from '../../core/form-validators/UrlEncodedTextMaxLengthValidator';
import { LteMButtonStoreSelectors, RootStoreState } from '../../root-store';
import * as Actions from '../../root-store/lte-m-button-store/actions';
import {
  GROUP_TAG_NAME_GADGET,
  GROUP_TAG_VALUE_LTE_M_BUTTON,
  LteMButtonConfig,
  SUBSCRIBER_TAG_NAME_EMAIL_BCC,
  SUBSCRIBER_TAG_NAME_EMAIL_BODY,
  SUBSCRIBER_TAG_NAME_EMAIL_SUBJECT,
  SUBSCRIBER_TAG_NAME_EMAIL_TO,
  SUBSCRIBER_TAG_NAME_GADGET,
  SUBSCRIBER_TAG_VALUE_LTE_M_BUTTON_IN_USE,
} from '../../shared/lte_m_button/LteMButtonConfig';
import { SoracomUserConsole } from '../../shared/SoracomUserConsole';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { GroupOrGroupParam, isGroup } from '../../root-store/lte-m-button-store/state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-lte-m-button-config',
  templateUrl: './lte-m-button-config.component.html',
})
export class LteMButtonConfigComponent implements OnInit, OnChanges {
  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);

  // @ts-expect-error (legacy code incremental fix)
  group$: Observable<GroupOrGroupParam>;
  // @ts-expect-error (legacy code incremental fix)
  subscribers$: Observable<ExtendedSubscriberInterface[]>;
  alertsManager = new AlertsManager();
  readonly maxTagValueByteLength = 512;

  // FIXME: Remove it
  // @ts-expect-error (legacy code incremental fix)
  groupOrCreateGroupParam: GroupOrGroupParam;
  // FIXME: Remove it
  subscribers: ExtendedSubscriberInterface[] = [];

  // @ts-expect-error (legacy code incremental fix)
  config: LteMButtonConfig;

  form = this.fb.group({
    SoracomHarvest: [false],
    cellLocation: [false],
    emailNotification: this.fb.group({
      enabled: [false],
      to: ['', [multipleEmailValidator, encodedTextMaxLengthValidator(this.maxTagValueByteLength)]],
      bcc: ['', [multipleEmailValidator, encodedTextMaxLengthValidator(this.maxTagValueByteLength)]],
      subject: ['', [Validators.required, encodedTextMaxLengthValidator(this.maxTagValueByteLength)]],
      body: ['', [Validators.required, encodedTextMaxLengthValidator(this.maxTagValueByteLength)]],
    }),
  });
  private logger = SoracomUserConsole.shared.logger;
  saved = false;

  get to() {
    return this.form.get('emailNotification.to');
  }

  get bcc() {
    return this.form.get('emailNotification.bcc');
  }

  get subject() {
    return this.form.get('emailNotification.subject');
  }

  get body() {
    return this.form.get('emailNotification.body');
  }

  constructor(
    private store$: Store<RootStoreState.State>,
    private modalService: UiDsModalService,
    private subscribersService: SubscribersService,
    private translateService: TranslateService
  ) {}

  // FIXME: This method is very bad in NGRX world, but it's too difficult / complicated to handle it.
  save = async () => {
    const newConfig: LteMButtonConfig = this.applyFormValue(this.config, this.form.getRawValue());
    const group = isGroup(this.groupOrCreateGroupParam)
      ? this.groupOrCreateGroupParam
      : await groupsService.create(this.groupOrCreateGroupParam.name);
    await groupsService.updateConfiguration(group.id, 'SoracomAir', [
      {
        key: 'binaryParserEnabled',
        value: true,
      },
      {
        key: 'binaryParserFormat',
        value: '@button',
      },
      {
        key: 'locationEnabled',
        value: newConfig.cellLocation,
      },
    ]);
    const format = newConfig.harvest
      ? UnifiedEndpointResponseFormat.SoracomHarvest
      : UnifiedEndpointResponseFormat.SoracomFunk;
    await groupsService.updateConfiguration(group.id, 'UnifiedEndpoint', [
      {
        key: 'response',
        value: {
          format,
          success: { skipStatusCode: false },
          failure: { skipStatusCode: false },
        },
      },
    ]);
    if (
      !isGroup(this.groupOrCreateGroupParam) ||
      this.groupOrCreateGroupParam?.configuration.SoracomHarvest?.enabled !== this.form.value.SoracomHarvest
    ) {
      await groupsService.updateConfiguration(group.id, 'SoracomHarvest', [
        {
          key: 'enabled',
          value: newConfig.harvest,
        },
      ]);
    }

    // Email Notification uses SoracomFunk
    if (
      !isGroup(this.groupOrCreateGroupParam) ||
      this.groupOrCreateGroupParam.configuration?.SoracomFunk?.enabled !== this.form.value.emailNotification?.enabled
    ) {
      await groupsService.updateConfiguration(
        group.id,
        'SoracomFunk',
        newConfig.funkConfig.funkParameterRepresentation
      );
    }
    const promises: LegacyAny = [];
    const groupTagsToUpdate: LegacyAny = [];
    const groupTagsToDelete: LegacyAny = [];
    [
      { tagName: SUBSCRIBER_TAG_NAME_EMAIL_TO, newValue: newConfig.emailNotification.to },
      { tagName: SUBSCRIBER_TAG_NAME_EMAIL_BCC, newValue: newConfig.emailNotification.bcc },
      { tagName: SUBSCRIBER_TAG_NAME_EMAIL_SUBJECT, newValue: newConfig.emailNotification.subject },
      { tagName: SUBSCRIBER_TAG_NAME_EMAIL_BODY, newValue: newConfig.emailNotification.body },
      { tagName: GROUP_TAG_NAME_GADGET, newValue: GROUP_TAG_VALUE_LTE_M_BUTTON },
    ].forEach((o) => {
      if (group.tags[o.tagName] !== o.newValue) {
        if (o.newValue) {
          groupTagsToUpdate.push({ tagName: o.tagName, tagValue: o.newValue });
        } else {
          groupTagsToDelete.push(o.tagName);
        }
      }
    });
    if (groupTagsToUpdate.length > 0) {
      promises.push(
        new Promise((resolve, reject) =>
          groupsService.updateTags(group.id, groupTagsToUpdate).then(resolve).catch(reject)
        )
      );
    }
    groupTagsToDelete.forEach((tagName: LegacyAny) =>
      promises.push(
        new Promise((resolve, reject) => groupsService.deleteTag(group.id, tagName).then(resolve).catch(reject))
      )
    );

    this.subscribers.forEach((subscriber) => {
      if (subscriber.groupId !== group.id) {
        promises.push(
          new Promise((resolve, reject) => {
            this.subscribersService
              .setGroup(subscriber.imsi, group.id)
              .then((s: LegacyAny) => {
                resolve(s);
              })
              .catch((e: LegacyAny) => reject(e));
          })
        );
      }
      if (subscriber.tags[SUBSCRIBER_TAG_NAME_GADGET] !== SUBSCRIBER_TAG_VALUE_LTE_M_BUTTON_IN_USE) {
        promises.push(
          new Promise((resolve, reject) => {
            this.subscribersService
              .updateTags(subscriber.imsi, [
                { tagName: SUBSCRIBER_TAG_NAME_GADGET, tagValue: SUBSCRIBER_TAG_VALUE_LTE_M_BUTTON_IN_USE },
              ])
              .then((s: LegacyAny) => {
                resolve(s);
              })
              .catch((e: LegacyAny) => reject(e));
          })
        );
      }
    });

    Promise.all(promises)
      .then(() => {
        // Refresh group cache
        return groupsService.refreshCacheById(group.id);
      })
      .then(() => {
        this.saved = true;
        this.modalService
          .openGenericConfirmModal('LteMButtonConfigComponent.dialog.configurationSaved', { hideCancelButton: true })
          .then(() => {
            this.store$.dispatch(Actions.navigateToIndexPage());
          });
      })
      .catch((e: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(e));
      });
  };

  back = () => {
    this.logger.debug('back is called');
    if (this.saved) {
      this.store$.dispatch(Actions.navigateToIndexPage());
      return;
    }

    if (this.subscribers && this.subscribers.length > 0) {
      this.store$.dispatch(Actions.navigateToSelectGroupPage());
    } else {
      this.store$.dispatch(Actions.navigateToIndexPage());
    }
  };

  buttonBar = UiButtonBar.configure((bar) => {
    bar.leftButtons = [
      UiButton.configure((b) => {
        b.buttonStyle = 'primary';
        b.titleId = 'LteMButtonConfigComponent.actions.save.title';
        b.classes = ['x-save-config'];
        b.isDisabled_ƒ = () => {
          return this.form.invalid;
        };
        b.onClick = this.save;
      }),
      UiButton.configure((b) => {
        b.titleId = 'LteMButtonConfigComponent.actions.back.title';
        b.classes = ['x-back'];
        b.onClick = this.back;
      }),
    ];
  });

  ngOnInit() {
    this.group$ = this.store$.select(LteMButtonStoreSelectors.selectLteMButtonGroup);
    this.subscribers$ = this.store$.select(LteMButtonStoreSelectors.selectLteMButtonSelectedSubscribers);
    this.group$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((group) => {
      if (group) {
        this.groupOrCreateGroupParam = group;
        this.loadLteMButtonConfig(group);
      }
    });
    this.subscribers$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subscribers) => {
      this.subscribers = subscribers;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group && changes.group.currentValue) {
      this.loadLteMButtonConfig(changes.group.currentValue);
    }
  }

  getTagValueByteLength(value: string): number {
    const encodedBody = encode(value || '');
    return encodedBody.length;
  }

  getTagValueByteLengthCssClass(value: string) {
    if (this.getTagValueByteLength(value) > this.maxTagValueByteLength) {
      return 'ds-text--color-red';
    } else {
      return 'ds-text';
    }
  }

  private loadLteMButtonConfig(group: GroupOrGroupParam) {
    try {
      this.config = new LteMButtonConfig(group, this.translateService.currentLang);
    } catch (e) {
      this.logger.error(e);
    }
    this.form.patchValue({
      cellLocation: this.config.cellLocation,
      emailNotification: this.config.emailNotification,
      SoracomHarvest: this.config.harvest,
    });
  }

  private applyFormValue(config: LteMButtonConfig, value: { [key: string]: any }): LteMButtonConfig {
    config.harvest = value.SoracomHarvest;
    config.cellLocation = value.cellLocation;
    config.emailNotification.enabled = value.emailNotification.enabled;
    config.emailNotification.to = value.emailNotification.to || undefined;
    config.emailNotification.bcc = value.emailNotification.bcc || undefined;
    config.emailNotification.subject = value.emailNotification.subject;
    config.emailNotification.body = value.emailNotification.body;
    return config;
  }

  getFormGroup(key: string): FormGroup {
    return this.form?.get(key) as FormGroup;
  }
}
