<div class="ds-rows">
  <ng-container>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </ng-container>
  <div class="ds-notice --info" *ngIf="quota">
    <span
      [innerHtml]="
        (isProUser ? 'LagoonUsersComponent.quota_pro' : 'LagoonUsersComponent.quota') | translate: translateParams
      "
    ></span>
    <ng-container *ngIf="enabledBothVersions">
      <br />
      <span translate="LagoonUsersComponent.migration_info"></span>
    </ng-container>
  </div>
  <div class="ds-cols --stretch">
    <div class="ds-cols --small">
      <ui-button [content]="addLagoonUserButton"></ui-button>
      <ui-menu [content]="menu"></ui-menu>
    </div>
    <div class="ds-cols --end">
      <ui-pagination [content]="paginationContent" [tableContent]="content"></ui-pagination>
    </div>
  </div>
  <ui-generic-table [content]="content" (selectionChanged)="onTableItemSelectionChanged($event)"></ui-generic-table>
</div>
