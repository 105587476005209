import { template } from './long_term_discounts_page.component.html';

import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';

export class LongTermDiscountsPageComponent implements ng.IComponentOptions {
  bindings = {};

  controller = LongTermDiscountsPageComponentController;
  template: any = template;
}

export class LongTermDiscountsPageComponentController extends BaseController {
  static $inject: InjectList = ['$log', 'AlertsService'];

  alertsService: AlertsServiceInstance;

  constructor($log: ng.ILogService, alertsServiceGenerator: AlertsService) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }
}
