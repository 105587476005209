<ng-container *ngIf="isSIM">
  <app-sim-autocomplete-item [simData]="sim" [propsToShowInList]="['simId', 'imsi', 'name']">
  </app-sim-autocomplete-item>
</ng-container>

<ng-container *ngIf="isOtherResource">
  <dl class="ds-details--vertical">
    <dt>
      <span>ID: {{ value?.resourceId }}</span>
    </dt>
    <dd>
      <div *ngIf="value?.name">{{ value?.name }}</div>
    </dd>
  </dl>
</ng-container>
