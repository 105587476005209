import { LegacyAny } from '@soracom/shared/core';

import { LoginUserDataService } from '@soracom/shared/data-access-auth';
import { InjectList } from '../core/injectable';
import { LegacyBaseSoracomApiService, SoracomApiParams } from '@user-console/legacy-soracom-api-client';

export enum ContractName {
  harvest = 'harvest',

  /**
   * `napter` is for the "Napter Audit Logs" subscription type.
   */
  napter = 'napter',

  /**
   * `api_audit_log` is for the "Audit Logs Enterprise" subscription type.
   */
  api_audit_log = 'api_audit_log',
}

export interface HarvestContractDetail {
  plan: 'extened';
  ttl: 731;
}

export class OperatorContractsService {
  static $inject: InjectList = ['$log', 'BaseSoracomApiService', 'LoginUserDataService'];

  constructor(
    private $log: ng.ILogService,
    private soracomApi: LegacyBaseSoracomApiService,
    private loginUserDataService: LoginUserDataService,
  ) {}

  subscribe(contractName: ContractName.napter | ContractName.api_audit_log): LegacyAny;
  subscribe(contractName: ContractName.harvest, contractDetail: HarvestContractDetail): LegacyAny;
  subscribe(contractName: ContractName, contractDetail?: any): LegacyAny {
    const operatorId = this.loginUserDataService.getLoginUser()?.operatorId;
    const apiParams: SoracomApiParams = {
      method: 'post',
      contentType: 'application/json',
      path: `/v1/operators/${operatorId}/contracts`,
      body: {
        contractName,
        contractDetail,
      },
    };

    return this.soracomApi.callApiWithToken(apiParams).then(() => {
      return this.soracomApi.renewToken();
    });
  }

  unsubscribe(contractName: ContractName) {
    const operatorId = this.loginUserDataService.getLoginUser()?.operatorId;
    const apiParams: SoracomApiParams = {
      method: 'delete',
      contentType: 'application/json',
      path: `/v1/operators/${operatorId}/contracts/${contractName}`,
    };

    return this.soracomApi.callApiWithToken(apiParams).then(() => {
      return this.soracomApi.renewToken();
    });
  }
}
