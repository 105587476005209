<div class="harvest-data-chart-preview-panel-container">
  <div class="harvest-data-chart-preview-panel-header">
    <button (click)="onCloseButtonClick()" class="ds-icon--icon-cancel ds-button--plain"></button>
    <div class="ds-label">{{ 'data.preview.selected_data' | translate }}</div>
  </div>

  <div class="harvest-data-chart-preview-panel-content">
    <ng-container *ngFor="let d of data">
      <div class="harvest-data-chart-preview-panel-item">
        <div class="ds-label">
          <span>{{ d.name }}</span>
        </div>
      </div>
      <pre>{{ d.data | json }}</pre>
    </ng-container>
  </div>
</div>
