import { LegacyAny } from '@soracom/shared/core';

import { template } from './lora_gateway_status_label.component.html';

import { BaseController } from '../../components/base_controller';
import { InjectList } from '../../core/injectable';
import { LoraGateway } from '../../core/lora_gateway';

export class LoraGatewayStatusLabelComponent implements ng.IComponentOptions {
  bindings = {
    obj: '<',
  };

  controller = LoraGatewayStatusLabelComponentController;
  template: any = template;
}

export class LoraGatewayStatusLabelComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$translate'];

  // @ts-expect-error (legacy code incremental fix)
  obj: LoraGateway;

  constructor($log: ng.ILogService, private $translate: LegacyAny) {
    super($log);
  }

  get loraGatewaySessionStatus(): string {
    return this.obj.online ? 'online' : 'offline';
  }

  textCssClass(): string[] {
    switch (this.obj.status) {
      case 'ready':
      case 'terminated':
        return [`lora-gateway-status-label__text--${this.obj.status}`];
      case 'active':
        return [`lora-gateway-status-label__text--active-${this.loraGatewaySessionStatus}`];
      default:
        return ['lora-gateway-status-label__text--unknown'];
    }
  }

  iconCssClass(): string[] {
    switch (this.obj.status) {
      case 'ready':
        return ['ds-icon--color-ink', 'ds-icon--icon-confirm'];
      case 'active':
        if (this.obj.online) {
          return ['ds-icon--color-green', 'ds-icon--icon-online-solid'];
        } else {
          return ['ds-icon--color-ink', 'ds-icon--icon-circle'];
        }
      case 'instock':
        return ['ds-icon--icon-minus'];
      case 'terminated':
      default:
        return ['ds-icon--icon-cancel'];
    }
  }

  statusText() {
    if (this.obj.status) {
      return this.$translate.instant(`lora_gateways.values.status.${this.obj.status}`);
    } else {
      return '';
    }
  }
}
