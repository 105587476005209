<div class="ds-rows">
  <h2>
    {{ 'LteMButtonNewSubscribersSelectComponent.title' | translate }}
  </h2>
  <div
    class="ds-notice--info"
    [innerHTML]="'LteMButtonNewSubscribersSelectComponent.myButtonIsNotFound' | translate"
  ></div>
  <div class="ds-cols--stretch">
    <input
      class="ds-input x-subscriber-search-text"
      type="text"
      [formControl]="searchTextControl"
      [attr.placeholder]="'LteMButtonNewSubscribersSelectComponent.search_placeholder' | translate"
    />
    <div class="ds-cols--end">
      <ui-pagination [content]="paginationContent" [tableContent]="content"></ui-pagination>
    </div>
  </div>
  <ui-generic-table
    [creator]="creator"
    [content]="content"
    (selectionChanged)="subscriberSelected($event)"
  ></ui-generic-table>
  <ui-button-bar [content]="buttonBar"></ui-button-bar>
</div>
