export class TrafficVolumeRankingDataJar {
  constructor(private searchFromMillis: number, private searchToMillis: number, private limit: number) {}

  getSearchFromMillis(): number {
    return this.searchFromMillis;
  }

  getSearchToMillis(): number {
    return this.searchToMillis;
  }

  getLimit(): number {
    return this.limit;
  }
}
