import { LegacyAny } from '@soracom/shared/core';

import { template } from './subscribers_search_box.component.html';

import { UCStorage } from '../../../src/app/shared/UCStorage';
import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';

interface SearchCategory {
  id: string;
  code: string;
  placeholder: string;
}

const categoryIds = [
  'subscribers.search_category.any',
  'subscribers.search_category.name',
  'subscribers.search_category.group',
  'subscribers.search_category.sim_id',
  'subscribers.search_category.imsi',
  'subscribers.search_category.msisdn',
  'subscribers.search_category.iccid',
  'subscribers.search_category.serial_number', // may be suppressed by feature visibility below
  'subscribers.search_category.tag',
];

const categoryIdToPlaceholderIdTable = {
  'subscribers.search_category.any': 'subscribers.search_placeholder.any',
  'subscribers.search_category.sim_id': 'subscribers.search_placeholder.sim_id',
  'subscribers.search_category.imsi': 'subscribers.search_placeholder.imsi',
  'subscribers.search_category.msisdn': 'subscribers.search_placeholder.msisdn',
  'subscribers.search_category.iccid': 'subscribers.search_placeholder.iccid',
  'subscribers.search_category.serial_number': 'subscribers.search_placeholder.serial_number',
  'subscribers.search_category.name': 'subscribers.search_placeholder.name',
  'subscribers.search_category.group': 'subscribers.search_placeholder.group',
  'subscribers.search_category.tag': 'subscribers.search_placeholder.tag',
};

const cid2pid = (cid: LegacyAny) => {
  return (categoryIdToPlaceholderIdTable as LegacyAny)[cid];
};

export class SubscriberSearchBoxComponent implements ng.IComponentOptions {
  bindings = {
    onSimSearch: '&',
    onClear: '&',
    searchCategoryId: '=?',
    searchKeywords: '=?',
    apiMode: '<',
  };

  controller = SubscriberSearchBoxComponentController;
  template: any = template;
}

export class SubscriberSearchBoxComponentController extends BaseController {
  static $inject: InjectList = ['$log', 'AlertsService', 'FeatureVisibilityService'];

  // @ts-expect-error (legacy code incremental fix)
  onClear: (arg: {}) => void;
  // @ts-expect-error (legacy code incremental fix)
  onSimSearch: (arg: {
    searchCategoryId: string;
    searchKeywords: string;
    onlineOnly: boolean;
    offlineOnly: boolean;
  }) => void;

  alertsService: AlertsServiceInstance;
  // @ts-expect-error (legacy code incremental fix)
  apiMode: string;
  // @ts-expect-error (legacy code incremental fix)
  categories: SearchCategory[];

  // @ts-expect-error (legacy code incremental fix)
  onlineOnly: boolean;
  // @ts-expect-error (legacy code incremental fix)
  offlineOnly: boolean;

  // @ts-expect-error (legacy code incremental fix)
  searchCategoryId: string;
  // @ts-expect-error (legacy code incremental fix)
  searchKeywords: string;
  // @ts-expect-error (legacy code incremental fix)
  searchPlaceholder: string;
  // @ts-expect-error (legacy code incremental fix)
  simSearchForm: ng.IFormController;

  constructor(
    $log: ng.ILogService,
    alertsServiceGenerator: AlertsService,
    private featureVisibilityService: FeatureVisibilityService
  ) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    this.categories = this.loadCategories();
    this.searchCategoryId = '';

    // Mason 2022-01-12: NOTE: I am replacing ngStorage so I replaced its $sessionStorage here. But the current production console, and my local console, do not update the session storage even when searching. I think this is an unrelated existing bug. (@fhsieh noticed a bug where the search terms aren't preserved across navigation... this is probably that bug. We should fix, but out of scope for current PR. NOTE: doesn't apply to old Subscribers UI, which uses old search UI.)
    this.searchKeywords = UCStorage.searchKeywords;
    this.searchPlaceholder = '';
    this.onlineOnly = false;
    this.offlineOnly = false;
    this.activate();
  }

  activate() {
    this.categories = this.loadCategories();
    const initialId = UCStorage.searchCategoryId;
    if (!this.isSearchCategoryAllowed(initialId)) {
      this.clear(true);
    } else {
      this.searchCategoryId = initialId;
      this.searchPlaceholder = cid2pid(initialId);
    }
  }

  loadCategories(): SearchCategory[] {
    const categories: SearchCategory[] = [];
    categoryIds.forEach((cid) => {
      if (this.isSearchCategoryAllowed(cid)) {
        categories.push({
          id: cid,
          code: cid.substring(cid.lastIndexOf('.') + 1),
          placeholder: cid2pid(cid),
        });
      }
    });

    return categories;
  }

  isSearchCategoryAllowed(categoryId: string): boolean {
    if (categoryIds.indexOf(categoryId) === -1) {
      return false;
    } else if (categoryId === 'subscribers.search_category.serial_number') {
      return this.featureVisibilityService.isEnabled('searchBySIMSerialNumber');
    } else if (categoryId === 'subscribers.search_category.sim_id') {
      return this.apiMode === 'sim';
    }
    return true;
  }

  firstAllowedSearchCategory(optionalCurrentCategory: string): string {
    if (optionalCurrentCategory && this.isSearchCategoryAllowed(optionalCurrentCategory)) {
      return optionalCurrentCategory;
    }
    // @ts-expect-error (legacy code incremental fix)
    return categoryIds.find((o) => this.isSearchCategoryAllowed(o));
  }

  onSearchKeywordsChange() {
    if (this.searchKeywords === undefined || this.searchKeywords === null || this.searchKeywords === '') {
      this.clear();
    } else {
      UCStorage.searchKeywords = this.searchKeywords;
    }
  }

  handleKeydown(e: LegacyAny) {
    if (e.which === 13) {
      if (this.simSearchForm.$valid || this.onlineOnly || this.offlineOnly) {
        this.invokeSearch();
      }
      e.preventDefault();
      e.stopPropagation();
    }
  }

  setSearchCategory(category: SearchCategory | null) {
    this.searchCategoryId = category ? category.id : '';
    this.searchPlaceholder = category ? category.placeholder : '';
    UCStorage.searchCategoryId = this.searchCategoryId;
  }

  toggleWithOnlineOnly() {
    this.offlineOnly = !this.onlineOnly && this.offlineOnly; // for NAND toggling
  }

  toggleWithOfflineOnly() {
    this.onlineOnly = !this.offlineOnly && this.onlineOnly; // for NAND toggling
  }

  invokeSearch() {
    if (this.validateLength(this.searchCategoryId, this.searchKeywords)) {
      this.onSimSearch({
        searchCategoryId: this.searchCategoryId,
        searchKeywords: this.searchKeywords,
        onlineOnly: this.onlineOnly,
        offlineOnly: this.offlineOnly,
      });
    }
  }

  /**
   * Clear the search form. The optional `suppressOnClear` arg controls
   * whether onClear event is emitted. If the arg is present and true, the
   * event will not be emitted. This is only for when using clear during view
   * loading when things may not yet be wired up — in which case the event can
   * cause a crash in SubscribersController, which watches for that event (but
   * cannot process it before it is ready). Really this should be fixed in
   * SubscribersController... :-/
   */
  clear(suppressOnClear?: boolean) {
    UCStorage.searchKeywords = '';
    this.searchKeywords = '';
    this.setSearchCategory(this.findSearchCategoryById(this.firstAllowedSearchCategory(this.searchCategoryId)));
    if (this.simSearchForm) {
      this.simSearchForm.$setPristine();
      this.simSearchForm.$setUntouched();
    }

    if (!suppressOnClear) {
      this.onClear({});
    }
  }

  findSearchCategoryById(cid: string): SearchCategory | null {
    // @ts-expect-error (legacy code incremental fix)
    return this.categories.find((c) => c.id === cid);
  }

  validateLength(categoryId: string, keyword: string) {
    if (this.onlineOnly || this.offlineOnly) {
      return true;
    }

    // The search uses bi-gram tokenizer so that requires two characters at least.
    return keyword !== undefined && keyword !== null && keyword.length >= 2;
  }
}
