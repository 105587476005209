import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { SoraCamDevice } from 'apps/user-console/app/shared/core/sora_cam_device';
import { SoraCamDevicesService } from 'apps/user-console/app/shared/sora_cam/sora_cam_devices.service';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubscriberSearchQueryParam } from '../../../app/shared/components/subscriber_search_query_param';
import { SubscriberSearchType } from '../../../app/shared/components/subscriber_search_type';
import { PaginatableService } from '@user-console/legacy-soracom-api-client';
import { Device } from '../../../app/shared/core/device';
import { LoraDevice } from '../../../app/shared/core/lora_device';
import { SigfoxDevice } from '../../../app/shared/core/sigfox_device';
import { ExtendedSim as Sim } from '@soracom/shared/sim';
import { DevicesService } from '../../../app/shared/devices/devices.service';
import { LoraDevicesService } from '../../../app/shared/lora_devices/lora_devices.service';
import { SigfoxDevicesService } from '../../../app/shared/sigfox_devices/sigfox_devices.service';
import { SimsService } from '../../../app/shared/subscribers/sims.service';
import { SubscriberSearchSessionStatus } from '../../../app/shared/subscribers/subscriber_search_session_status';
import { SatelliteDevice, SatelliteDevicesService } from '../feature-satellite-device/satellite-devices.service';
import { SearchQuery } from '@user-console/legacy-soracom-api-client';
import {
  ResourceOption,
  ResourceType,
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_LORA,
  RESOURCE_TYPE_SATELLITE,
  RESOURCE_TYPE_SIGFOX,
  RESOURCE_TYPE_SIM,
  RESOURCE_TYPE_SORACAM,
  RESOURCE_TYPE_IMSI,
} from './resource-selector.type';
import { SubscribersService } from 'apps/user-console/app/shared/subscribers/subscribers.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';

// TODO : searches should be case-insensitive?

interface AutoCompleteService<T extends ResourceOption> {
  search(term: string): Observable<readonly T[]>;
  preload?: () => void;
  clearCache?: () => void;
}

// AutoCompleteServiceBaseForPaginatable consists of this interface and the following class (type merging)
interface AutoCompleteServiceBaseForPaginatableService<T extends ResourceOption, S extends PaginatableService<T>> {
  searchByApi?: (searchTerm: string) => Promise<T[]>;
}

class AutoCompleteServiceBaseForPaginatableService<T extends ResourceOption, S extends PaginatableService<T>> {
  protected svc: S;
  protected isOnMemory = false;
  // @ts-expect-error (legacy code incremental fix)
  protected cache: T[];
  protected initialized: boolean = false;
  // @ts-expect-error (legacy code incremental fix)
  protected initPromise: Promise<any>;

  protected limitForApi = 100;
  protected minCharLenForSearchApi = 2;

  constructor(service: S, init = false) {
    this.svc = service;
    if (init) {
      this.init();
    }
  }

  public filter(item: T, searchTerm: string): boolean {
    const lowerSearchTerm = searchTerm.toLocaleLowerCase();
    // @ts-expect-error (legacy code incremental fix)
    return (
      (item && item.resourceId?.toLocaleLowerCase().includes(lowerSearchTerm)) ||
      item.name?.toLocaleLowerCase().includes(lowerSearchTerm)
    );
  }

  public get hasSearchApi() {
    return !!this.searchByApi;
  }

  public clearCache() {
    // @ts-expect-error (legacy code incremental fix)
    this.initPromise = null;
    this.initialized = false;
  }

  public search(term: string): Observable<T[]> {
    if (!this.initPromise) {
      this.preload(); // lazy init
    }

    if (!this.initialized) {
      // if init() has already started but not completed, wait for it
      return from(this.initPromise).pipe(switchMap(() => this.search(term)));
    }

    if (this.isOnMemory) {
      return this.searchInMemory(term);
    }

    // If a search term is blank, return all the data we have.
    // It's ok if we just have a part of all data as cache,
    // because this service is for autocomplete
    // and displaying many 'enough' data just means 'too many options yet'.
    if (!term && this.cache) {
      return of(this.cache);
    }

    if (term.length >= this.minCharLenForSearchApi) {
      // errors should be handled by the service consumer
      // @ts-expect-error (legacy code incremental fix)
      return from(this.searchByApi(term));
    }

    return of([]);
  }

  public searchInMemory(term: string) {
    const options = this.cache.filter((item) => this.filter(item, term));
    return of(options);
  }

  public preload() {
    this.init();
  }

  protected init() {
    // @ts-expect-error (legacy code incremental fix)
    if (this.initPromise) {
      // already init() has started
      return;
    }

    this.initPromise = this.svc
      .list({ limit: this.limitForApi })
      .then(({ data, links }) => {
        if (data) {
          this.cache = data;
        }

        if (!links?.next) {
          // if all items are fetched
          this.isOnMemory = true;
        }

        if (!this.hasSearchApi && links?.next) {
          return this.getAll(links.next.lastEvaluatedKey, data).then((data) => {
            this.cache = data;
          });
        }
        // else request search api on demand
      })
      .then(() => {
        this.initialized = true;
      })
      .catch((e: LegacyAny) => {
        this.initialized = true;
        // this method is `preload`, meaning the result of this method may not be used
        // Plus, if the error is reproducible, following `search` request throws error
        // so here, we just log error and does not throw for a service consumer
        console.error(e);
      });
  }

  // this utility should plated be outside
  // @ts-expect-error (legacy code incremental fix)
  protected getAll(lastEvaluatedKey: string = null, acc: T[] = []): Promise<T[]> {
    return this.svc.list({ limit: 1000, last_evaluated_key: lastEvaluatedKey }).then(({ data, links }) => {
      const newData = [...acc, ...data];
      if (links.next?.lastEvaluatedKey) {
        return this.getAll(links.next.lastEvaluatedKey, newData);
      }
      return data;
    });
  }
}

@Injectable()
class SimAutoCompleteService extends AutoCompleteServiceBaseForPaginatableService<Sim, SimsService> {
  constructor(protected svc: SimsService) {
    super(svc);
  }

  // override
  public filter(v: Sim, term: string) {
    const lowerTerm = term.toLocaleLowerCase();
    return (
      (v && v.simId?.toLocaleLowerCase().includes(lowerTerm)) ||
      v.name?.toLocaleLowerCase().includes(lowerTerm) ||
      v.imsi?.toLocaleLowerCase().includes(lowerTerm)
    );
  }

  public searchByApi = (term: LegacyAny) => {
    return this.svc
      .search(
        new SubscriberSearchQueryParam(
          {
            name: term,
            simId: term,
            imsi: term,
          },
          100,
          // @ts-expect-error (legacy code incremental fix)
          null,
          SubscriberSearchType.OR,
          SubscriberSearchSessionStatus.NA,
        ),
      )
      .then(({ data }) => data);
  };
}

@Injectable()
class ImsiAutoCompleteService extends AutoCompleteServiceBaseForPaginatableService<
  ExtendedSubscriberInterface,
  SubscribersService
> {
  constructor(protected svc: SubscribersService) {
    super(svc);
  }

  // override
  public filter(v: ExtendedSubscriberInterface, term: string) {
    const lowerTerm = term.toLocaleLowerCase();
    return (
      (v && v.simId?.toLocaleLowerCase().includes(lowerTerm)) ||
      v.name?.toLocaleLowerCase().includes(lowerTerm) ||
      v.imsi?.toLocaleLowerCase().includes(lowerTerm)
    );
  }

  public searchByApi = (term: LegacyAny) => {
    debugger;
    return this.svc
      .search(
        new SubscriberSearchQueryParam(
          {
            name: term,
            simId: term,
            imsi: term,
          },
          100,
          // @ts-expect-error (legacy code incremental fix)
          null,
          SubscriberSearchType.OR,
          SubscriberSearchSessionStatus.NA,
        ),
      )
      .then(({ data }) => {
        return data;
      });
  };
}

@Injectable()
class DeviceAutoCompleteService extends AutoCompleteServiceBaseForPaginatableService<Device, DevicesService> {
  constructor(protected svc: DevicesService) {
    super(svc);
  }

  searchByApi = (term: string) => {
    return this.svc
      .list(
        {},
        SearchQuery.create({
          name: term,
          deviceId: term,
        }),
      )
      .then(({ data }) => data);
  };
}

@Injectable()
class SigfoxDeviceAutoCompleteService extends AutoCompleteServiceBaseForPaginatableService<
  SigfoxDevice,
  SigfoxDevicesService
> {
  constructor(protected svc: SigfoxDevicesService) {
    super(svc);
  }

  searchByApi = (term: string) => {
    return this.svc
      .list(
        {},
        SearchQuery.create({
          name: term,
          deviceId: term,
        }),
      )
      .then(({ data }) => data);
  };
}

@Injectable()
class LoraDeviceAutoCompleteService extends AutoCompleteServiceBaseForPaginatableService<
  LoraDevice,
  LoraDevicesService
> {
  constructor(protected svc: LoraDevicesService) {
    super(svc);
  }
}

@Injectable({ providedIn: 'root' })
export class SoraCamAutoCompleteService implements AutoCompleteService<SoraCamDevice> {
  constructor(private svc: SoraCamDevicesService) {}

  search(term: string): Observable<SoraCamDevice[]> {
    const termLower = term.toLocaleLowerCase();
    return from(
      this.svc.cache
        .list()
        .then((devices) =>
          devices.filter(
            (d: LegacyAny) =>
              !term || d.id.toLocaleLowerCase().includes(termLower) || d.name.toLocaleLowerCase().includes(termLower),
          ),
        ),
    );
  }
}

@Injectable()
class SatelliteDeviceAutoCompleteService extends AutoCompleteServiceBaseForPaginatableService<
  SatelliteDevice,
  SatelliteDevicesService
> {
  constructor(protected svc: SatelliteDevicesService) {
    super(svc);
  }
}

// TODO: maybe we can inject types and services required on demand,
//       instead of listing them up as constructor arguments
@Injectable()
export class ResourceAutoCompleteService {
  private svcMap: { [key in ResourceType]: AutoCompleteService<any> };

  // Maybe we can do smarter to be more-generic, by using advanced feature of DI
  constructor(
    private simsService: SimsService,
    private devicesService: DevicesService,
    private loraDevicesService: LoraDevicesService,
    private sigfoxDevicesService: SigfoxDevicesService,
    private soraCamAutocompleteService: SoraCamAutoCompleteService,
    private satelliteDevicesService: SatelliteDevicesService,
    private subscribersService: SubscribersService,
  ) {
    this.svcMap = {
      [RESOURCE_TYPE_SIM]: new SimAutoCompleteService(simsService),
      [RESOURCE_TYPE_DEVICE]: new DeviceAutoCompleteService(devicesService),
      [RESOURCE_TYPE_SIGFOX]: new SigfoxDeviceAutoCompleteService(sigfoxDevicesService),
      [RESOURCE_TYPE_LORA]: new LoraDeviceAutoCompleteService(loraDevicesService),
      [RESOURCE_TYPE_SORACAM]: soraCamAutocompleteService,
      [RESOURCE_TYPE_SATELLITE]: new SatelliteDeviceAutoCompleteService(satelliteDevicesService),
      [RESOURCE_TYPE_IMSI]: new ImsiAutoCompleteService(subscribersService),
    };
  }

  public preload(type: ResourceType) {
    // @ts-expect-error (legacy code incremental fix)
    this.svcMap[type]?.preload();
  }

  public clearCache() {
    Object.values(this.svcMap).forEach((svc) => {
      if (svc.clearCache) {
        svc.clearCache();
      }
    });
  }

  search(type: ResourceType, term: LegacyAny): Observable<readonly ResourceOption[]> {
    return this.svcMap[type].search(term);
  }
}
