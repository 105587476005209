import { LegacyAny } from '@soracom/shared/core';

import { BaseModel } from '@soracom/shared/soracom-platform';

export enum LagoonUserRole {
  editor = 'Editor',
  viewer = 'Viewer',
  admin = 'Admin', // for internal only
}

export class LagoonUser extends BaseModel {
  // @ts-expect-error (legacy code incremental fix)
  id: string;
  // @ts-expect-error (legacy code incremental fix)
  login: string;
  // @ts-expect-error (legacy code incremental fix)
  email: string;
  // @ts-expect-error (legacy code incremental fix)
  role: LagoonUserRole;

  constructor(params?: LegacyAny) {
    super();
    if (params) {
      this.id = params.id;
      this.login = params.login;
      this.email = params.email;
      this.role = params.role;
    }
  }
}
