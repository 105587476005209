import { LegacyAny } from '@soracom/shared/core';

import { ExternalCredentialsType } from '@soracom/shared/soracom-platform';
import { LegacyBaseSoracomApiService, SoracomApiParams } from '@user-console/legacy-soracom-api-client';
import { CredentialsSet } from '../core/credentials_set';
import { InjectList } from '../core/injectable';

/**
 * DEPRECATED. This class was ported to Angular as `CredentialsService`. New Angular code should use that instead.
 */
export class CredentialsSetsService {
  static $inject: InjectList = ['$log', '$q', 'BaseSoracomApiService'];

  constructor(
    private $log: ng.ILogService,
    private $q: ng.IQService,
    private soracomApi: LegacyBaseSoracomApiService,
  ) {}

  /**
   * options
   *   type - The credentials type which you need
   *
   * TODO: rename to 'list'
   */
  getCredentialsSets(filterOptions: { type?: ExternalCredentialsType } = {}) {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: '/v1/credentials/',
    };

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const filteredResults = response.data.filter((item: LegacyAny) => {
        if (filterOptions && filterOptions.type) {
          return item.type === filterOptions.type;
        } else {
          return true;
        }
      });
      return this.$q.resolve(filteredResults);
    });
  }

  create(uniqueName: string, credentialsSet: CredentialsSet) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/credentials/${uniqueName}`,
      contentType: 'application/json',
      body: credentialsSet,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  delete(uniqueName: string) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: `/v1/credentials/${uniqueName}`,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  getCredentialsSetOptions(credentialsSets: CredentialsSet[]) {
    return credentialsSets.map((obj: LegacyAny) => {
      const label = obj.description ? `${obj.credentialsId} (${obj.description})` : obj.credentialsId;

      return { id: obj.credentialsId, label, description: obj.description, type: obj.type };
    });
  }
}
