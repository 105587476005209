<div class="ds-cols">
  <div class="ds-span--12">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="ds-rows">
        <h2>
          {{ 'GpsMultiunitConfigComponent.title' | translate }}
        </h2>

        <div class="ds-group">
          <div class="ds-group__title">{{ 'GpsMultiunitConfigComponent.contents.title' | translate }}</div>
          <ui-checkbox modelName="GpsMultiunitConfigComponent" name="location" [group]="form"></ui-checkbox>
          <ui-checkbox modelName="GpsMultiunitConfigComponent" name="temperature" [group]="form"></ui-checkbox>
          <ui-checkbox modelName="GpsMultiunitConfigComponent" name="humidity" [group]="form"></ui-checkbox>
          <ui-checkbox modelName="GpsMultiunitConfigComponent" name="accelerator" [group]="form"></ui-checkbox>
          <ui-checkbox modelName="GpsMultiunitConfigComponent" name="battery" [group]="form"></ui-checkbox>
        </div>

        <div class="ds-group">
          <div class="ds-group__title">{{ 'GpsMultiunitConfigComponent.destination.title' | translate }}</div>
          <!-- label for="" class="ds-label">{{ "GpsMultiunitConfigComponent.destination.labels.configurable" | translate }}</label-->
          <ui-checkbox modelName="GpsMultiunitConfigComponent" name="SoracomHarvest" [group]="form"></ui-checkbox>
          <label for="" class="ds-label">{{
            'GpsMultiunitConfigComponent.destination.labels.readonly' | translate
          }}</label>
          <ui-checkbox modelName="GpsMultiunitConfigComponent" name="SoracomBeam" [group]="form"></ui-checkbox>
          <ui-checkbox modelName="GpsMultiunitConfigComponent" name="SoracomFunnel" [group]="form"></ui-checkbox>
          <ui-checkbox modelName="GpsMultiunitConfigComponent" name="SoracomFunk" [group]="form"></ui-checkbox>
          <div
            class="ds-notice--info"
            [innerHtml]="'GpsMultiunitConfigComponent.destination.description' | translate"
          ></div>
        </div>

        <div class="ds-group">
          <div class="ds-group__title">{{ 'GpsMultiunitConfigComponent.mode.title' | translate }}</div>
          <div class="ds-cols">
            <div class="gps-multiunit-config__mode-selector ds-span--4">
              <label class="ds-radio--panel">
                <input type="radio" value="AUTO" formControlName="mode" />
                <div>{{ 'GpsMultiunitConfigComponent.mode.auto.label' | translate }}</div>
              </label>
              <div class="gps-multiunit-config__mode-selector-label">
                {{ 'GpsMultiunitConfigComponent.mode.auto.description' | translate }}
              </div>
            </div>
            <div class="ds-span--4">
              <label class="ds-radio--panel">
                <input type="radio" value="MANUAL" formControlName="mode" />
                <div>{{ 'GpsMultiunitConfigComponent.mode.manual.label' | translate }}</div>
              </label>
              <div class="gps-multiunit-config__mode-selector-label">
                {{ 'GpsMultiunitConfigComponent.mode.manual.description' | translate }}
              </div>
            </div>
            <div class="ds-span--4">
              <label class="ds-radio--panel">
                <input type="radio" value="NONE" formControlName="mode" />
                <div>{{ 'GpsMultiunitConfigComponent.mode.none.label' | translate }}</div>
              </label>
              <div class="gps-multiunit-config__mode-selector-label">
                {{ 'GpsMultiunitConfigComponent.mode.none.description' | translate }}
              </div>
            </div>
          </div>
          <div class="ds-notice--info" [innerHtml]="'GpsMultiunitConfigComponent.mode.description' | translate"></div>
        </div>

        <app-gps-multiunit-scenario-form
          fgName="intervalScenario"
          i18nPrefix="GpsMultiunitConfigComponent.intervalScenario"
        >
          <div
            *ngIf="form.value.mode !== 'MANUAL'"
            class="ds-notice--warning"
            [innerHtml]="'GpsMultiunitConfigComponent.intervalScenario.description' | translate"
          ></div>
        </app-gps-multiunit-scenario-form>

        <div class="ds-group">
          <div class="ds-group__title">{{ 'GpsMultiunitConfigComponent.accelerationEvent.title' | translate }}</div>
          <ui-checkbox
            modelName="GpsMultiunitConfigComponent"
            name="isEnabledAccelerationEvent"
            [group]="form"
          ></ui-checkbox>
          <label for="" class="ds-label">{{
            'GpsMultiunitConfigComponent.labels.accelerationEventThreshold' | translate
          }}</label>
          <div class="acceleration-event-threshold ds-cols">
            <div class="ds-span--8">
              <div class="ds-field">
                <div class="ds-range">
                  <div class="ds-text">62 mG</div>
                  <input type="range" min="62" max="7874" formControlName="accelerationEventThreshold" step="62" />
                  <div class="ds-text">7874 mG</div>
                </div>
              </div>
            </div>
            <div class="ds-span--4">
              <div class="ds-field">
                <div class="ds-input --icon-time">
                  <input type="number" [value]="form.getRawValue().accelerationEventThreshold" readonly />
                  <div class="ds-text --addon-light">mG</div>
                </div>
              </div>
            </div>
          </div>
          <div class="ds-notice--info">
            {{ 'GpsMultiunitConfigComponent.accelerationEvent.description' | translate }}
          </div>
        </div>

        <div class="ds-group">
          <div class="ds-group__title">
            {{ 'GpsMultiunitConfigComponent.fetchConfigurationMode.title' | translate }}
          </div>
          <div class="ds-cols">
            <div class="ds-span--6">
              <label class="ds-radio--panel">
                <input type="radio" value="AUTO" formControlName="fetchConfigurationMode" />
                <div [innerHtml]="'GpsMultiunitConfigComponent.fetchConfigurationMode.auto.label' | translate"></div>
              </label>
              <div
                class="gps-multiunit-config__fetch-configuration-mode-selector-label"
                [innerHTML]="'GpsMultiunitConfigComponent.fetchConfigurationMode.auto.description' | translate"
              ></div>
            </div>
            <div class="ds-span--6">
              <label class="ds-radio--panel">
                <input type="radio" value="MANUAL" formControlName="fetchConfigurationMode" />
                <div>{{ 'GpsMultiunitConfigComponent.fetchConfigurationMode.manual.label' | translate }}</div>
              </label>
              <div
                class="gps-multiunit-config__fetch-configuration-mode-selector-label"
                [innerHTML]="'GpsMultiunitConfigComponent.fetchConfigurationMode.manual.description' | translate"
              ></div>
            </div>
          </div>
          <div
            class="ds-notice--info"
            [innerHtml]="'GpsMultiunitConfigComponent.fetchConfigurationMode.description' | translate"
          ></div>
        </div>
        <app-gps-multiunit-scenario-form
          fgName="fetchConfigurationScenario"
          i18nPrefix="GpsMultiunitConfigComponent.fetchConfigurationScenario"
        >
          <div
            *ngIf="form.value.fetchConfigurationMode !== 'AUTO'"
            class="ds-notice--warning"
            [innerHtml]="'GpsMultiunitConfigComponent.fetchConfigurationScenario.description' | translate"
          ></div>
        </app-gps-multiunit-scenario-form>

        <div class="ds-rows">
          <ui-button-bar [content]="buttonBar"></ui-button-bar>
        </div>
      </div>
    </form>
  </div>

  <!--div class="ds-span--4">
    <h2>Debug: form values</h2>
    <div style="overflow: scroll; height: 600px;">
      <pre>{{ form.value | json }}</pre>
    </div>
  </div -->
  <!-- div class="ds-span--6">
    <h2>GPS multiunit config (input)</h2>
    <div style="overflow: scroll; height: 600px;">
      <pre>{{ config && config.toJSON() | json }}</pre>
    </div>
  </div>
  <div class="ds-span--6">
    <h2>Group config (input)</h2>
    <div style="overflow: scroll; height: 600px;">
      <pre>{{ group | json }}</pre>
    </div>
  </div -->
</div>
