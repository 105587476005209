import { LegacyAny } from '@soracom/shared/core';

import { CoverageType } from '@foundation/coverage-type';
import { SoracomApiService } from '../components/soracom_api.service';
import { InjectList } from '../core/injectable';
import { EventHandler, TargetType } from '../event_handlers/event_handler';

/**
 * @Deprecated
 */
export class EventHandlersService {
  static $inject: InjectList = ['SoracomApi'];

  constructor(private soracomApi: SoracomApiService) {}

  fetch(target: TargetType, coverageType?: CoverageType): Promise<EventHandler[]> {
    return this.soracomApi
      .getEventHandlers(target, coverageType)
      .then((res: LegacyAny) => res.data.map((d: LegacyAny) => new EventHandler(d)));
  }

  fetchByImsi(imsi: string): Promise<EventHandler[]> {
    return this.soracomApi
      .getEventHandlersByImsi(imsi)
      .then((res: LegacyAny) => res.data.map((d: LegacyAny) => new EventHandler(d)));
  }

  create(eventHandler: EventHandler) {
    return this.soracomApi.createEventHandler(eventHandler.toJSON());
  }

  update(eventHandler: EventHandler) {
    return this.soracomApi.updateEventHandler(eventHandler.toJSON());
  }

  destroy(eventHandler: EventHandler) {
    return this.soracomApi.destroyEventHandler(eventHandler);
  }

  ignore(eventHandlerId: string, imsi: string) {
    return this.soracomApi.ignoreEventHandler(eventHandlerId, imsi);
  }

  unsetIgnore(eventHandlerId: string, imsi: string) {
    return this.soracomApi.unsetIgnoreEventHandler(eventHandlerId, imsi);
  }
}
