import { InjectList } from '../core/injectable';
import { BaseController } from './base_controller';
import { template } from './feature_visibility.component.html';

export class FeatureVisibilityComponent implements ng.IComponentOptions {
  controller = FeatureVisibilityController;
  template: any = template;
}

export class FeatureVisibilityController extends BaseController {
  static $inject: InjectList = ['$log', '$location'];

  returnPath;

  constructor($log: ng.ILogService, $location: ng.ILocationService) {
    super($log);
    this.returnPath = $location.search()['return_to'] || '/';
  }
}
