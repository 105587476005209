import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatBytes } from '../../core/pipes/BytePipe';

const byteFormatter = (v: string | number) => formatBytes(Number(v), { nDigits: 2 });

@Component({
  selector: 'app-traffic-volume-ranking-chart',
  template: `
    <app-chart-container [style.height]="height">
      <canvas baseChart type="bar" [data]="$any(data)" [options]="options" [plugins]="plugins"></canvas>
    </app-chart-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficVolumeRankingChartComponent {
  data: ChartData | undefined;
  height = '480px';

  constructor(private i18n: TranslateService) {}

  plugins = [ChartDataLabels];

  @Input() set chartData(data: { imsi: string; trafficVolume: number }[]) {
    data = data || [];
    const labels = data.map((d: LegacyAny) => d.imsi);
    const datasets: ChartDataset<'bar'>[] = [
      {
        label: 'Traffic Volume Ranking',
        data: data.map((d: LegacyAny) => d.trafficVolume),
        barThickness: 32,
        // TODO: read color definitions from sds
        backgroundColor: '#34cdd7', //celeste
        borderColor: '#34cdd7', //celeste
        hoverBackgroundColor: '#23a3b3', //celeste-shade
      },
    ];
    this.data = { labels, datasets };
    this.height = `${48 * (labels.length || 10)}px`;
  }

  options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      y: {
        title: {
          text: 'IMSIs',
          display: true,
        },
      },
      x: {
        ticks: {
          callback: byteFormatter,
        },
      },
    },
    // @ts-expect-error (legacy code incremental fix)
    onClick: (e, elms, chart) => copy(String(chart.data.labels[elms[0].index])),
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 5,
        formatter: byteFormatter,
      },
      tooltip: {
        callbacks: {
          title: (item) => `IMSI: ${item[0].label}`,
          label: (item) => `Traffic Volume: ${((item.raw as number) || 0).toLocaleString()}`,
          footer: () => this.i18n.instant('traffic_volume_ranking.imsi_clipboard_notice'),
        },
      },
    },
  };
}

const copy = async (s: string) => {
  navigator?.clipboard?.writeText(s);
};
