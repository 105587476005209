import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FocusMeDirective } from '@soracom/shared-ng/ui-common';

@Component({
  templateUrl: './update_napter_contract.modal.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, FocusMeDirective],
})
export class UpdateNapterContractModalComponent
  extends LegacyUibModalAdapterModalBase<{ subscribed: () => boolean }, { shouldUpdate: boolean }>
  implements OnInit
{
  // @ts-expect-error (legacy code incremental fix)
  subscribed: boolean;
  submitting = true;

  ngOnInit() {
    this.subscribed = !!this.resolve?.subscribed();
    this.submitting = false;
  }

  canSubmit(): boolean {
    return true;
  }

  willSubscribe(): boolean {
    return !this.subscribed;
  }

  willUnsubscribe(): boolean {
    return this.subscribed;
  }

  submit() {
    this.close({ shouldUpdate: true });
  }

  cancel() {
    this.dismiss();
  }
}
