import {
  FunkConfig,
  FunkConfigType,
  FunkContentType,
  HOSTED_FUNK_ARN,
  kMagicCredentialIdForSoracomHostedFunk,
} from '@soracom/shared/soracom-services-ui/funk-ui';
import { GroupOrGroupParam, isGroup } from '../../root-store/lte-m-button-store/state';

export const SUBSCRIBER_TAG_NAME_GADGET = 'soracom.gadgets';
export const SUBSCRIBER_TAG_NAME_EMAIL_SUBJECT = 'mailSubject';
export const SUBSCRIBER_TAG_NAME_EMAIL_TO = 'mailAddress';
export const SUBSCRIBER_TAG_NAME_EMAIL_BCC = 'mailAddressBcc';
export const SUBSCRIBER_TAG_NAME_EMAIL_BODY = 'mailBody';
export const SUBSCRIBER_TAG_VALUE_LTE_M_BUTTON_IN_USE = 'LTE-M-Button';
export const GROUP_TAG_NAME_GADGET = 'soracom.gadgets';
export const GROUP_TAG_VALUE_LTE_M_BUTTON = 'LTE-M-Button';

export class LteMButtonEmailNotificationConfig {
  enabled: boolean;
  to?: string;
  subject: string;
  body: string;
  bcc?: string;

  constructor(lang: string = 'en') {
    this.enabled = false;
    if (lang === 'en') {
      this.subject = 'button {{subscriber.tags.name}} was clicked!';
      this.body = `This is sample email for button event.

      event:
        clickTypeName: {{event.clickTypeName}}
        BatteryLevel: {{event.batteryLevel}}

      context:
        imsi: {{context.imsi}}
        imei: {{context.imei}}
        lat: {{context.location.lat}}
        lon: {{context.location.lon}}

      tags:
        name: {{subscriber.tags.name}}"`;
    } else {
      this.subject = 'ボタン {{subscriber.tags.name}} がクリックされました！';
      this.body = `ボタン {{subscriber.tags.name}} が {{event.clickTypeName}} クリックされました

      - 電池残量: {{event.batteryLevelPercent}} %
      - 緯度,経度: {{context.location.lat}},{{context.location.lon}} (簡易位置測位機能で表示)`;
    }
  }
}

export class LteMButtonConfig {
  // @ts-expect-error (legacy code incremental fix)
  harvest: boolean;
  // @ts-expect-error (legacy code incremental fix)
  cellLocation: boolean;
  // @ts-expect-error (legacy code incremental fix)
  emailNotification: LteMButtonEmailNotificationConfig;
  readonly groupFunkConfig: any;
  // @ts-expect-error (legacy code incremental fix)
  readonly isHostedFunk: boolean;

  get funkConfig(): FunkConfig {
    const funkConfig = new FunkConfig(FunkConfigType.AWSLambda);
    funkConfig.contentType = FunkContentType.json;
    funkConfig.resourceUrl = HOSTED_FUNK_ARN;
    funkConfig.credentialsId = kMagicCredentialIdForSoracomHostedFunk;
    funkConfig.enabled = this.emailNotification.enabled;
    return funkConfig;
  }

  constructor(group: GroupOrGroupParam, lang = 'en') {
    if (group) {
      this.harvest = (isGroup(group) && group.configuration.SoracomHarvest?.enabled) || false;
      this.cellLocation = (isGroup(group) && group.configuration.SoracomAir?.locationEnabled) || false;
      this.emailNotification = new LteMButtonEmailNotificationConfig(lang);
      this.groupFunkConfig = isGroup(group) && group.configuration.SoracomFunk;
      this.isHostedFunk = isGroup(group) && group.configuration.SoracomFunk?.resourceUrl === HOSTED_FUNK_ARN;
    }
    // @ts-expect-error (legacy code incremental fix)
    if (this.isHostedFunk) {
      // @ts-expect-error (legacy code incremental fix) WARNING: FIXME use-before-assign
      this.emailNotification.enabled = this.groupFunkConfig?.enabled || false;
      // @ts-expect-error (legacy code incremental fix)
      this.emailNotification.to = isGroup(group) ? group.tags[SUBSCRIBER_TAG_NAME_EMAIL_TO] : undefined;
      // @ts-expect-error (legacy code incremental fix)
      this.emailNotification.bcc = isGroup(group) ? group.tags[SUBSCRIBER_TAG_NAME_EMAIL_BCC] : undefined;
      // @ts-expect-error (legacy code incremental fix)
      this.emailNotification.subject = isGroup(group) ? group.tags[SUBSCRIBER_TAG_NAME_EMAIL_SUBJECT] : undefined;
      // @ts-expect-error (legacy code incremental fix)
      this.emailNotification.body = isGroup(group) ? group.tags[SUBSCRIBER_TAG_NAME_EMAIL_BODY] : undefined;

      const userdata = isGroup(group) && group?.configuration?.SoracomAir?.userdata;

      // For backward-compatibality
      // @ts-expect-error (legacy code incremental fix)
      if (!this.emailNotification.subject && userdata) {
        // @ts-expect-error (legacy code incremental fix)
        this.emailNotification.subject = userdata.split('\n')[0];
        // @ts-expect-error (legacy code incremental fix)
        this.emailNotification.body = userdata.split('\n').slice(1).join('\n');
      }
    } else {
      // @ts-expect-error (legacy code incremental fix)
      this.emailNotification.enabled = this.groupFunkConfig?.enabled || false;
    }
  }
}
