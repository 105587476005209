import { Component, HostListener, Injector, OnDestroy, inject } from '@angular/core';
import { combineLatest } from 'rxjs';
import { HarvestDataStore } from '../harvest-data.store';
import { HarvestDataIntelligenceComponent } from './harvest-data-intelligence.component';
import { UiAppSidebarService } from '@soracom/shared-ng/ui-app-sidebar';
import { HarvestDataVizType } from '../harvest-data-viz/harvest-data-viz-type';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'button[appHarvestDataIntelligenceTrigger]',
  template: `<div data-testid="harvest-ask-ai-main-button">
    {{ 'harvestDataIntelligence.mainButtonTitle' | translate }}
  </div>`,
  standalone: true,
  host: {
    class: 'ds-button --primary --icon-atom --massive',
    '[attr.disabled]': 'isDisabled || null',
  },
  imports: [TranslateModule],
})
export class HarvestDataIntelligenceButtonComponent implements OnDestroy {
  injector = inject(Injector);
  isDisabled = false;

  constructor(private harvestDataStore: HarvestDataStore, private uiAppSidebarService: UiAppSidebarService) {
    combineLatest([
      this.harvestDataStore.numResources$,
      this.harvestDataStore.vizType$,
      this.harvestDataStore.aiDialogOpen$,
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([numResources, vizType, aiDialogOpen]) => {
        if (numResources < 1 || aiDialogOpen || !this.isAllowedVizType(vizType)) {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }
        if (!this.isAllowedVizType(vizType)) {
          this.uiAppSidebarService.removeSidebar();
        }
      });
  }

  ngOnDestroy() {
    this.uiAppSidebarService.removeSidebar();
  }

  @HostListener('click')
  onClick() {
    this.uiAppSidebarService.openComponentInSideBar(HarvestDataIntelligenceComponent, this.injector, [
      '--fill',
      '--simple',
    ]);
  }

  private isAllowedVizType(vizType: HarvestDataVizType) {
    return !(vizType === 'table');
  }
}
