import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CoverageType } from '@foundation/coverage-type';
import { select, Store } from '@ngrx/store';
import { Logger } from '@soracom/shared-ng/logger-service';
import { BreadcrumbService } from '@soracom/shared-ng/routing';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { Breadcrumb, BreadcrumbForDashboard } from '@soracom/shared/routing';
import { LagoonSubscription } from '../../../../app/shared/lagoon/lagoon_plans';
import { NgLocationService } from '../../../../app/shared/subscribers/ng_location.service';
import { environment } from '../../../environments/environment';
import { RootStoreState } from '../../root-store';
import { loadLagoonSubscriptionAndAvailableVersion } from '../../root-store/lagoon-store/actions';
import { selectAvailableVersions, selectSubscription } from '../../root-store/lagoon-store/selectors';
import { skipInitial } from '../../root-store/rxjs-custom-operators';

@Component({
  selector: 'app-lagoon-page',
  templateUrl: './lagoon-page.component.html',
  styleUrls: ['./lagoon-page.component.scss'],
})
export class LagoonPageComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  subscription$ = this.store$.pipe(select(selectSubscription), skipInitial());
  availableVersions$ = this.store$.pipe(select(selectAvailableVersions), skipInitial());

  alertsManager = new AlertsManager();
  // @ts-expect-error (legacy code incremental fix)
  private _pageId: string;
  // @ts-expect-error (legacy code incremental fix)
  private coverageType: CoverageType;
  enabledLagoonV2 = false;
  enabledLagoonV3 = false;
  hasSubscription = false;
  // @ts-expect-error (legacy code incremental fix)
  activePageTitleId: string;

  @Input()
  set pageId(value: string) {
    this._pageId = value;
    this.activePageTitleId = `LagoonPageComponent.tab.${value}.title`;

    this.updateBreadcrumbs();
  }
  get pageId() {
    return this._pageId;
  }

  constructor(
    private coverageTypeService: CoverageTypeService,
    private location: NgLocationService,
    private logger: Logger,
    private store$: Store<RootStoreState.State>,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    // @ts-expect-error (legacy code incremental fix)
    this.coverageType = this.coverageTypeService.getCoverageType();
    this.availableVersions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((versions) => {
      this.enabledLagoonV2 = versions.includes('v2');
      this.enabledLagoonV3 = versions.includes('v3');
    });
    this.subscription$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subscription) => {
      this.logger.debug(this.pageId, subscription);
      if (this.pageId !== 'index' && subscription === LagoonSubscription.none) {
        this.location.updateRoute('/lagoon');
        return;
      }

      this.hasSubscription = subscription !== LagoonSubscription.none;
    });
    this.logger.debug('LagoonPageComponent.ngOnInit');
    this.store$.dispatch(loadLagoonSubscriptionAndAvailableVersion());
  }

  isActive(pageId: string): boolean {
    return this.pageId === pageId;
  }

  lagoon2Url() {
    return environment.lagoonV2ConsoleUrl[this.coverageType];
  }

  lagoon3Url() {
    return environment.lagoonV3ConsoleUrl[this.coverageType];
  }

  updateBreadcrumbs() {
    const breadcrumbs: Breadcrumb[] = [BreadcrumbForDashboard, { labelKey: 'nav.lagoon', url: '/lagoon' }];
    if (!this.isActive('index')) {
      breadcrumbs.push({ labelKey: this.activePageTitleId });
    }
    this.breadcrumbService.overrideBreadcrumbs(breadcrumbs);
  }
}
