import { inject } from '@angular/core';
import { CoverageType, isCoverageType } from '@foundation/coverage-type';
import { TranslateService } from '@ngx-translate/core';
import { ApiCredentialsService, CoverageTypeService } from '@soracom/shared/data-access-auth';
import {
  ConfigurationClass,
  ConfigurationParameters,
  Middleware,
  RequestContext,
  ResponseContext,
  ServerConfiguration,
} from '@soracom/shared/soracom-api-typescript-client';
import { environment } from '../environments/environment';

export class SoracomApiMiddleware implements Middleware {
  private apiCredentialsService = inject(ApiCredentialsService);
  private coverageTypeService = inject(CoverageTypeService);
  private translateService = inject(TranslateService);

  pre(context: RequestContext): Promise<RequestContext> {
    // @ts-expect-error (legacy code incremental fix)
    context.setHeaderParam('X-Soracom-API-Key', this.apiCredentialsService.getApiKey());
    // @ts-expect-error (legacy code incremental fix)
    context.setHeaderParam('X-Soracom-Token', this.apiCredentialsService.getToken());
    context.setHeaderParam('X-Soracom-Lang', this.translateService.currentLang);

    // context.setHeaderParam('X-Soracom-DynamicRoutes', 'conversation-api'); // FIXME
    // context.setHeaderParam('X-Soracom-DynamicRoutes', 'feature-batch-api'); // FIXME
    // context.setHeaderParam('X-Soracom-DynamicRoutes', 'notification-api'); // FIXME

    // @ts-expect-error (legacy code incremental fix)
    const baseUrl = getBaseUrl(this.coverageTypeService.getCoverageType());
    const url = context.getUrl().replace(/https:\/\/base-api-placeholder/, baseUrl);
    context.setUrl(url);
    return Promise.resolve(context);
  }
  post(context: ResponseContext): Promise<ResponseContext> {
    return Promise.resolve(context);
  }
}

function getBaseUrl(coverageType: CoverageType): string {
  if (!isCoverageType(coverageType)) {
    // For public routes like mfa_reset etc there wont be coverage type. We can handle that as jp by default
    console.error(`soracom-api-client getBaseUrl: Invalid coverage type: ${coverageType}}`);
    return environment.apiUrl['jp'];
  }
  return environment.apiUrl[coverageType];
}

const baseApiVersion = 'v1';

export const soracomApiConfigurationProvider = {
  provide: ConfigurationClass,
  useFactory: () => {
    // Set the api base here depending on environment and coverage.
    // Setting up environment based base in every implementation of client is good idea because we don't want to expose dev/staging endpoints in openApi spec file.
    // Placeholder 'base-api-placeholder' will be replaced in ApiMiddleware with correct base url according to coverage type
    const apiBase = `https://base-api-placeholder/${baseApiVersion}`;
    const con: ConfigurationParameters = {
      baseServer: new ServerConfiguration(apiBase, {}),
      promiseMiddleware: [new SoracomApiMiddleware()],
    };
    return new ConfigurationClass(con);
  },
};
