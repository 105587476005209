import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WebStorageModule } from '@soracom/forks/ngx-store';

import { RootStoreModule } from '../root-store/root-store.module';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { SoracomGroupsModule } from '../soracom-groups/soracom-groups.module';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { SubscribersModule } from '../subscribers/subscribers.module';
import { DeleteLteMButtonConfigComponent } from './delete-lte-m-button-config/delete-lte-m-button-config.component';
import { LteMButtonConfigComponent } from './lte-m-button-config/lte-m-button-config.component';
import { LteMButtonIndexComponent } from './lte-m-button-index/lte-m-button-index.component';
import { LteMButtonNewGroupSelectComponent } from './lte-m-button-new-group-select/lte-m-button-new-group-select.component';
import { LteMButtonNewSubscribersSelectComponent } from './lte-m-button-new-subscribers-select/lte-m-button-new-subscribers-select.component';
import { LteMButtonComponent } from './lte-m-button/lte-m-button.component';

import '@soracom/shared/sim-status-info';

@NgModule({
  declarations: [
    DeleteLteMButtonConfigComponent,
    LteMButtonConfigComponent,
    LteMButtonComponent,
    LteMButtonIndexComponent,
    LteMButtonNewGroupSelectComponent,
    LteMButtonNewSubscribersSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RootStoreModule,
    TranslateModule.forRoot(),
    WebStorageModule,

    SoracomCommonModule,
    SoracomGroupsModule,
    SubscribersModule,
    SoracomUiModule,
  ],
  exports: [DeleteLteMButtonConfigComponent, LteMButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LteMButtonModule {}
