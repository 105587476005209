// window.app.directive('scSubscriberSessionCountryInfo', dir);

import { template } from './subscriber_session_country_info.component.html';

import { CellInfo } from '@soracom/shared/soracom-platform';
import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';

export class SubscriberSessionCountryInfoComponent implements ng.IComponentOptions {
  bindings = {
    cellInfo: '<',
  };

  controller = SubscriberSessionCountryInfoComponentController;
  template: any = template;
}

export class SubscriberSessionCountryInfoComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$uibModal', 'AlertsService'];

  alertsService: AlertsServiceInstance;
  // @ts-expect-error (legacy code incremental fix)
  cellInfo: CellInfo;

  constructor($log: ng.ILogService, private $uibModal: any, alertsServiceGenerator: AlertsService) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  shouldShow(): boolean {
    const c = this.cellInfo;
    if (c === undefined || c === null || !c.available) {
      return false;
    }

    return true;
  }
}
