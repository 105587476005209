import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { LagoonVersion } from '../../../../app/shared/lagoon/lagoon.service';
import { LagoonSubscription } from '../../../../app/shared/lagoon/lagoon_plans';
import { State } from './state';

export const selectLagoonState: MemoizedSelector<object, State> = createFeatureSelector<State>('lagoon');

export const selectSubscription = createSelector(
  selectLagoonState,
  (state: State): LagoonSubscription => state?.subscription
);

export const selectAvailableVersions = createSelector(
  selectLagoonState,
  (state: State): LagoonVersion[] => state?.availableVersions || []
);
