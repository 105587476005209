import { LegacyAny } from '@soracom/shared/core';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { InjectList } from '../core/injectable';
import { BaseController } from './base_controller';
import { template } from './group_chooser.component.html';
import { GroupChooser, GroupChooserOptions } from './group_chooser.controller';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { GroupAddModalComponent } from '../../../src/app/soracom-groups/add_group_modal/group_add.modal.component';

export class GroupChooserComponent implements ng.IComponentOptions {
  bindings = {
    ctrl: '<',
    options: '<?',
  };
  controller = GroupChooserComponentController;
  template: any = template;
}

// TODO: Merge GroupChooserController
class GroupChooserComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$q', 'UiDsModalService'];

  // @ts-expect-error (legacy code incremental fix)
  ctrl: GroupChooser;
  // @ts-expect-error (legacy code incremental fix)
  options: GroupChooserOptions;

  // @ts-expect-error (legacy code incremental fix)
  private groups: Group[];
  saving = false;
  removing = false;

  constructor($log: ng.ILogService, private $q: ng.IQService, private uiDsModalService: UiDsModalService) {
    super($log);
  }

  $onInit() {
    if (!this.options) {
      this.options = {
        showCreate: true,
        showUnset: true,
      };
    }
    this.updateGroups();
  }

  getDisplayGroupName() {
    return this.ctrl.getDisplayGroupName();
  }

  updateGroups() {
    this.groups = [];
    groupsService.getCachedAll().then((groupMap) => {
      groupMap.forEach((group) => {
        this.groups.push(group);
      });
    });
  }

  getGroups(): Group[] {
    return this.groups;
  }

  setGroup(group: Group) {
    this.saving = true;
    this.$q.when(this.ctrl.setGroup(group)).finally(() => {
      this.saving = false;
    });
  }

  unsetGroup() {
    this.removing = true;
    this.$q.when(this.ctrl.unsetGroup()).finally(() => {
      this.removing = false;
    });
  }

  createGroup() {
    this.uiDsModalService
      .openAndWaitForResult(GroupAddModalComponent, {
        title: 'group_add.header',
      })
      .then((result) => {
        if (result?.group) {
          this.okAction(result);
        }
      });
  }

  private okAction = (result: LegacyAny) => {
    this.updateGroups();
    this.setGroup(result.group);
  };

  private cancelAction = () => {
    // noop
  };
}
