/**
 * These tutorials are exported so that they can easily be link-checked by a test.
 */
export const tutorials = {
  en: {
    header: 'Build a Solution',
    subheader: 'SORACOM provides services to simplify any IoT requirement',
    solutions: [
      // The number of solutions should ideally be a multiple of 3
      // as this will look better when layed out in rows.
      {
        title: 'Automatically Encrypt Traffic',
        link: 'https://developers.soracom.io/en/docs/beam/',
        icon: '--icon-soracom-beam',
        subtitle: 'With SORACOM Beam',
        duration: '~5 mins',
      },
      {
        title: 'Simple Data Collection',
        link: 'https://developers.soracom.io/en/docs/harvest/',
        icon: '--icon-soracom-harvest',
        subtitle: 'With SORACOM Harvest Data',
        duration: '~2 mins',
      },
      {
        title: 'Visualize your data',
        link: 'https://developers.soracom.io/en/docs/lagoon/',
        icon: '--icon-soracom-lagoon',
        subtitle: 'With SORACOM Lagoon',
        duration: '~5 mins',
      },
      {
        title: 'Send Data to an S3 Bucket',
        link: 'https://developers.soracom.io/en/start/aws/funnel-kinesis/',
        icon: '--icon-soracom-funnel',
        subtitle: 'With SORACOM Funnel',
        duration: '~8 mins',
      },
      {
        title: 'Inter-Device LAN',
        link: 'https://developers.soracom.io/en/docs/gate/basic-configuration/',
        icon: '--icon-soracom-gate',
        subtitle: 'With SORACOM Gate',
        duration: '~2 mins',
      },
      // {
      //   title: 'Comprehensive Statistical Visualisation',
      //   link: 'https://developers.soracom.io/en/docs/junction/inspection/',
      //   icon: '--icon-soracom-junction',
      //   subtitle: 'With SORACOM Junction',
      //   duration: '~15 mins',
      // },
      {
        title: 'Restrict Internet Access',
        link: 'https://developers.soracom.io/en/docs/vpg/private-garden/',
        icon: '--icon-soracom-sim-global',
        subtitle: 'With a SORACOM managed VPG',
        duration: '~5 mins',
      },
    ],
  },
  ja: {
    header: 'ソリューションを構築しよう',
    subheader: 'IoT をもっと簡単に。 SORACOM のサービスで。',
    solutions: [
      // The number of solutions should ideally be a multiple of 3
      // as this will look better when layed out in rows.
      {
        title: 'データ転送支援',
        link: 'https://users.soracom.io/ja-jp/docs/beam/use-beam/',
        icon: '--icon-soracom-beam',
        subtitle: 'SORACOM Beam',
        duration: '読了までの目安：5分',
      },
      {
        title: 'データ収集・蓄積',
        link: 'https://users.soracom.io/ja-jp/docs/harvest/send-data/',
        icon: '--icon-soracom-harvest',
        subtitle: 'SORACOM Harvest Data',
        duration: '読了までの目安：2分',
      },
      {
        title: 'ダッシュボード作成・共有',
        link: 'https://users.soracom.io/ja-jp/docs/lagoon-v3/',
        icon: '--icon-soracom-lagoon',
        subtitle: 'SORACOM Lagoon',
        duration: '読了までの目安：5分',
      },
      {
        title: 'データを Amazon S3 に収集',
        link: 'https://users.soracom.io/ja-jp/docs/funnel/data-firehose-awscfn/',
        icon: '--icon-soracom-funnel',
        subtitle: 'SORACOM Funnel',
        duration: '読了までの目安：8分',
      },
      {
        title: 'デバイス LAN 接続',
        link: 'https://users.soracom.io/ja-jp/docs/gate/device-to-device/',
        icon: '--icon-soracom-gate',
        subtitle: 'SORACOM Gate',
        duration: '読了までの目安：2分',
      },
      {
        title: '透過型トラフィック処理',
        link: 'https://users.soracom.io/ja-jp/docs/junction/use-inspection/',
        icon: '--icon-soracom-junction',
        subtitle: 'SORACOM Junction',
        duration: '読了までの目安：15分',
      },
      {
        title: 'プライベート接続',
        link: 'https://users.soracom.io/ja-jp/docs/vpg/use-private-garden/',
        icon: '--icon-soracom-sim-global',
        subtitle: 'SORACOM managed VPG',
        duration: '読了までの目安：5分',
      },
    ],
  },
} as const;

export type Tutorials = {
  tutorials: LanguageMap;
};

export type LanguageMap = {
  en: TutorialBox;
  ja: TutorialBox;
};

export type TutorialBox = {
  header: string;
  subheader: string;
  solutions: Solution[];
};

export type Solution = {
  title: string;
  link: string;
  subtitle: string;
  icon: string;
  duration: string;
};
