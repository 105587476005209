import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { ActiveElement, Chart, ChartDataset } from 'chart.js';
import { LocalStorageService } from '@soracom/forks/ngx-store';
import { HarvestData } from '../../../../../app/shared/core/harvest_data';
import { KeyValueLocalStorageService } from '../../../shared/key-value-local-storage.service';
import { HarvestDataStore } from '../../harvest-data.store';
import { HarvestResourceType, LORA, SIGFOX } from '../../harvest-data.type';
import { HarvestDataChartComponent } from '../chart/harvest-data-chart.component';
import { AxisControlState, DataSeriesOption, DataSeriesVisibility } from '../harvest-data-viz-type';
import { HarvestDataChartPreviewPanelComponent } from '../preview-panel/harvest-data-chart-preview-panel.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// utility for referencing color function of ng2-charts
const getFuncFromChartjs = (f: LegacyAny) => {
  if (typeof f === 'string') {
    return (o: any) => f;
  }
  if (typeof f === 'function') {
    return function (o: any) {
      const ret = f.apply(null, arguments);
      if (typeof ret === 'string') {
        return ret;
      }
      throw Error('unsupported');
    };
  }
  throw Error('unsupported');
};

interface ChartDataPoint {
  x: any;
  y: number;
  originalData: HarvestData;
}

const getDefaultVisibilitiesForType = (type: HarvestResourceType) =>
  ((
    {
      [SIGFOX]: { device: false, time: false, lat: false, lng: false, seqNumber: false },
      [LORA]: { deveui: false },
    } as LegacyAny
  )[type] || null);

export const LABEL_RESOURCE_SEPARATOR = '@';

function convertHarvestDataToChartData(data: HarvestData[], isMultiResource: boolean) {
  const dataMap: LegacyAny = {};
  data.forEach((record) => {
    const x = record.time;
    const suffix = isMultiResource ? LABEL_RESOURCE_SEPARATOR + record.resourceId : '';
    Object.keys(record.chartData).forEach((orgKey) => {
      const y = record.chartData[orgKey];
      if (Number.isFinite(x) && Number.isFinite(y)) {
        const value: ChartDataPoint = { x, y, originalData: record };
        const label = orgKey + suffix;
        if (!dataMap[label]) {
          dataMap[label] = [];
        }
        dataMap[label].push(value);
      }
    });
  });
  const datasets = Object.entries(dataMap).map(([label, data]) => ({ label, data } as ChartDataset));
  const labels = data.map((d: LegacyAny) => d.time);
  return {
    datasets,
    labels,
  };
}

@Component({
  selector: 'app-harvest-data-viz-container',
  templateUrl: './harvest-data-viz-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HarvestDataChartContainerComponent {
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild(HarvestDataChartComponent) chart: HarvestDataChartComponent;
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild(HarvestDataChartPreviewPanelComponent) preview: HarvestDataChartPreviewPanelComponent;

  private _data: HarvestData[] = [];

  @Input() set data(orig: HarvestData[]) {
    this._data = (orig || [])
      .filter((r) => !!r?.chartData)
      .reverse() // input data is sorted in descending order but we need them in ascending order
      .sort((a: LegacyAny, b: LegacyAny) => a.time - b.time);
    this.generateChartData();
    if (this.preview) {
      this.preview.close();
      this.previewData = [];
    }
  }

  get data() {
    return this._data;
  }

  // @ts-expect-error (legacy code incremental fix)
  datasets: ChartDataset[];
  // @ts-expect-error (legacy code incremental fix)
  labels: any[];
  yAxisCtrlData: AxisControlState = {
    autoAdjust: true,
    maxValue: null,
    minValue: null,
  };
  // @ts-expect-error (legacy code incremental fix)
  visibilities: DataSeriesVisibility;
  // @ts-expect-error (legacy code incremental fix)
  previewData: HarvestData[];

  yAxisLocalStorage: KeyValueLocalStorageService<AxisControlState>;
  visibilityLocalStorage: KeyValueLocalStorageService<DataSeriesVisibility>;

  constructor(private /*  */ svc: HarvestDataStore, localStorage: LocalStorageService, private cd: ChangeDetectorRef) {
    this.yAxisLocalStorage = new KeyValueLocalStorageService(localStorage, { storageKey: 'harvest-data-chart-yaxis' });
    this.visibilityLocalStorage = new KeyValueLocalStorageService(localStorage, {
      storageKey: 'harvest-data-chart-data-series-visibilities',
    });

    this.svc.isMultiResource$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.generateChartData();
    });
  }

  get vizType$() {
    return this.svc.vizType$;
  }

  /** Resources selected on page. not only ones included in chartData */
  get resources() {
    return this.svc.resources;
  }

  get dataSeriesOptions(): DataSeriesOption[] {
    const bkFn = getFuncFromChartjs(Chart.defaults.datasets.line.backgroundColor);
    const bFn = getFuncFromChartjs(Chart.defaults.datasets.line.borderColor);
    return this.datasets.map((v, i) => ({
      label: v.label,
      backgroundColor: bkFn({ datasetIndex: i }),
      borderColor: bFn({ datasetIndex: i }),
    })) as DataSeriesOption[];
  }

  // @ts-expect-error (legacy code incremental fix)
  displayingResourceIds: string[];
  generateChartData() {
    // isMulti is not detected using chart data, as we do pagination and NOT all the data is shown here at a time
    const isMulti = this.resources.length > 1;
    const { datasets, labels } = convertHarvestDataToChartData(this.data, isMulti);
    this.datasets = datasets;
    this.labels = labels;

    // resourceIds appeared in chart data
    const resourceIds = [...new Set(this.data.map((v) => v.resourceId)).values()];
    this.displayingResourceIds = resourceIds;

    // load and set yAxis ctrl data if exists
    if (resourceIds.length === 1) {
      const y = this.yAxisLocalStorage.get(resourceIds[0]);
      if (y) {
        this.yAxisCtrlData = y;
      }
    }

    // load and set visibility data if exists
    const savedVisibilitiesMap = resourceIds.reduce((obj: LegacyAny, id) => {
      const v = this.visibilityLocalStorage.get(id);
      if (v) {
        obj[id] = v;
      } else {
        const resourceType = this.resources.find((r) => r.resourceId === id)?.resourceType;
        // @ts-expect-error (legacy code incremental fix)
        const dv = getDefaultVisibilitiesForType(resourceType);
        if (dv) {
          obj[id] = dv;
        }
      }
      return obj;
    }, {});

    this.visibilities = datasets.reduce((obj, ds) => {
      // @ts-expect-error (legacy code incremental fix)
      const [key, resourceId] = isMulti ? ds.label.split(LABEL_RESOURCE_SEPARATOR) : [ds.label, resourceIds[0]];
      // @ts-expect-error (legacy code incremental fix)
      obj[ds.label] = savedVisibilitiesMap[resourceId]?.[key] ?? true;
      return obj;
    }, {});
    this.svc.setVisibilities(this.visibilities);
  }

  onYAxisCtrlChange(d: AxisControlState) {
    this.yAxisCtrlData = d;
    this.cd.markForCheck();

    if (this.displayingResourceIds.length === 1) {
      this.yAxisLocalStorage.save(this.displayingResourceIds[0], d);
    }
  }

  onVisibilityChange(s: DataSeriesVisibility) {
    this.visibilities = { ...s };
    if (this.resources.length === 1) {
      // we can also do this for multi-resource data if we want. but be careful in case of label including "@"
      this.visibilityLocalStorage.save(this.displayingResourceIds[0], s);
    }
    this.svc.setVisibilities(this.visibilities);
    this.cd.markForCheck();
  }

  onChartDataClick(elements: ActiveElement[]) {
    const set = new Set<HarvestData>();
    elements.forEach((el) => {
      const dataPoint = this.datasets[el.datasetIndex]?.data[el.index] as ChartDataPoint;
      set.add(dataPoint.originalData);
    });

    this.previewData = [...set.values()];
    this.preview.open();
    this.cd.markForCheck();
  }
}
