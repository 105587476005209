export const template = `<div class="sc-date-time-picker" ng-class="$ctrl.pickerCSS()">
  <div class="sc-date-picker-wrapper" ng-class="$ctrl.pickerWrapperCSS()"
    sc-form-group-validity-classes="sc-form-group-validity-classes" target="dateFieldName"><input class="ds-input"
      ng-class="$ctrl.pickerInputCSS()" name="{{ $ctrl.dateFieldName }}" type="text"
      size="{{ $ctrl.dateFieldSize || &quot;10&quot; }}" data-autoclose="true"
      data-date-format="{{ &quot;date_time_picker.date_format&quot; | translate }}" data-date-type="number"
      data-min-date="{{ $ctrl.minDate }}" data-max-date="{{ $ctrl.maxDate }}"
      placeholder="{{ &quot;date_time_picker.date&quot; | translate }}" ng-model="$ctrl.selectedDate"
      ng-change="$ctrl.updateDateTime()" ng-required="$ctrl.required" bs-datepicker="bs-datepicker" />
    <div class="ds-text--addon" ng-if="!$ctrl.noTimeZone &amp;&amp; $ctrl.sds &amp;&amp; $ctrl.noTime">{{ $ctrl.timezone
      }}</div>
  </div>
  <div class="sc-time-picker-wrapper" ng-class="$ctrl.pickerWrapperCSS()" ng-if="!$ctrl.noTime"
    sc-form-group-validity-classes="sc-form-group-validity-classes" target="timeFieldName"><input class="ds-input"
      ng-class="$ctrl.pickerInputCSS()" name="{{ $ctrl.timeFieldName }}" type="text" size="8" data-autoclose="true"
      data-arrow-behavior="picker" data-time-format="{{ &quot;date_time_picker.time_format&quot; | translate }}"
      data-time-type="number" data-hour-step="1" data-minute-step="1"
      placeholder="{{ &quot;date_time_picker.time&quot; | translate }}" ng-model="$ctrl.selectedTime"
      ng-change="$ctrl.updateDateTime()" ng-required="$ctrl.required" bs-timepicker="bs-timepicker" />
    <div class="ds-text--addon" ng-if="!$ctrl.noTimeZone &amp;&amp; $ctrl.sds">{{ $ctrl.timezone }}</div>
  </div>
  <div class="form-group sc-timezone-label-wrapper" ng-if="!$ctrl.noTimeZone &amp;&amp; !$ctrl.sds">
    <div class="form-control-static">{{ $ctrl.timezone }}</div>
  </div>
</div>`;
