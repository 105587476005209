import { LegacyAny } from '@soracom/shared/core';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractController } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiSelect } from '../../soracom-ui/ui-select/UiSelect';
import { LogViewerController } from '../log-viewer/log-viewer.component';
import { LogEntryWrapper } from '../LogEntryWrapper';
import { PaginatedLogEntryList } from '../PaginatedLogEntryList';

@Component({
  selector: 'app-log-viewer-paginator',
  templateUrl: './log-viewer-paginator.component.html',
})
/**
 * This is a very simple view component.  He displays a model object (PaginationState), and fires events upon getting input from the user.
 */
export class LogViewerPaginatorComponent extends AbstractController {
  // 🌸

  // @ts-expect-error (legacy code incremental fix)
  private _currentPage: PaginatedLogEntryList;

  @Input() set currentPage(newValue) {
    this._currentPage = newValue;

    // Mason 2021-04-03 FIXME: We should clean this up and at least break this out into separate method:
    if (this._currentPage) {
      // Can't show more than 100 until Chrome CSS bug workaround done
      //
      // 2021-03-24 Mason: that should be fixed now, let's test this with the higher values enabled (and rendering that many) to confirm the fix. If fixed, we might think about upping the max (though if we do we better test the 'copy as CSV' function too.)
      const availablePerPageCounts = [10, 25, 50, 100 /* 250, 500 */];
      // Begin Ethan's adventure into component lifecycles:
      // ISTM the view-only state like countPerPage should flow out of this
      // component to the paginationState, instead of reading its initial value
      // from there.  And ideally it should take one path. So rather than
      // getting or setting the perPageCount here, we'll set it in OnInit which
      // 🤞 will propagate the initial count into the parent's model at render
      // time.
      const paginationState = this.currentPage.paginationState;

      // And if you buy the above, the following code could be simplified: there
      // should not be new page count options to learn about.
      const perPageCount = paginationState.perPageCount;
      if (!availablePerPageCounts.includes(perPageCount)) {
        availablePerPageCounts.push(perPageCount);
      }
      availablePerPageCounts.sort((a: LegacyAny, b: LegacyAny) => a - b); // 🙄
      this.selectPerPageCount.resetOptions();
      // @ts-expect-error (legacy code incremental fix)
      this.selectPerPageCount.onValueChange = undefined;
      this.selectPerPageCount.addValues(availablePerPageCounts);
      this.selectPerPageCount.value = perPageCount;
      this.selectPerPageCount.onValueChange = (s) => {
        this.perPageCount = s.value;
      };
    }
  }

  get currentPage() {
    return this._currentPage;
  }

  // @ts-expect-error (legacy code incremental fix)
  @Input() logViewerController: LogViewerController;

  /**
   * Owning component can optionally set the initial page count (otherwise, it's 10).
   */
  @Input() initialPerPageCount = 10;

  /**
   * Emits when the user changes the "rows per page" popup.
   */
  @Output() perPageCountChange = new EventEmitter<number>();

  /**
   * Emits when refresh is clicked.
   */
  @Output() refresh = new EventEmitter();

  /**
   * Emits when the Next Page button is clicked.
   */
  @Output() nextPage = new EventEmitter<number>();

  /**
   * Emits when the Previous Page button is clicked.
   */
  @Output() previousPage = new EventEmitter<number>();

  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Returns `true` when the entire component should be disabled, e.g. during loading (or when something is fubar and the required paginationState wasn't passed in).
   */
  get isDisabled() {
    if (!this.currentPage) {
      return true;
    }

    const state = this.logViewerController?.state;
    return ['loading', 'error'].includes(state);
  }

  hasSelection = false;

  get selectionState() {
    return this._selectionState;
  }

  @Input() set selectionState(newValue) {
    this.debug('setSelectionState', newValue);
    this._selectionState = newValue;
    this.hasSelection = !!newValue?.find((e) => e.selected);
  }

  // @ts-expect-error (legacy code incremental fix)
  private _selectionState: LogEntryWrapper[];

  get countSelected() {
    return this.selectionState?.length ?? 0;
  }

  get selectionCountHint() {
    const count = new Intl.NumberFormat().format(this.countSelected);
    return LegacyTextContent.translation('SoracomLogs.selectionCountHint', { count });
  }

  itemsPerPage = LegacyTextContent.translation('SoracomLogs.itemsPerPage');

  get perPageCount() {
    return this.currentPage?.paginationState?.perPageCount;
  }

  set perPageCount(newPerPageCount) {
    this.perPageCountChange.emit(newPerPageCount);
  }

  private _noRange = LegacyTextContent.string('');

  /**
   * Check our passed-in pagination state object to see what the "item 5-10" or "item 5-10 of 10" string should be. (Our pagination scheme only knows the total item count when showing the end of the range.)
   */
  get itemXofY() {
    if (!this.currentPage?.entries?.length) {
      return LegacyTextContent.string('');
    }

    const range = this.currentPage?.paginationState?.currentPageRange;

    if (!range) {
      return this._noRange;
    }
    const formatter = new Intl.NumberFormat();
    const first = formatter.format(range.first);
    const last = formatter.format(range.last);

    if (range.total) {
      const total = formatter.format(range.total);
      return LegacyTextContent.translation('SoracomLogs.showingXtoYofZ', { first, last, total });
    } else {
      return LegacyTextContent.translation('SoracomLogs.showingXtoY', { first, last });
    }
  }

  selectPerPageCount = new UiSelect<number>([this.initialPerPageCount]).configure((x) => {
    // disable everything in select menu when isDisabled:
    x.optionDisabler = (e) => this.isDisabled;
  });

  get hasNextPage() {
    return this.currentPage?.paginationState?.hasNextPage;
  }

  get hasPreviousPage() {
    return this.currentPage?.paginationState?.hasPreviousPage;
  }

  loadNextPage() {
    // no event payload, parent knows what page he is on
    this.nextPage.emit();
  }

  loadPreviousPage() {
    // no event payload, parent knows what page he is on
    this.previousPage.emit();
  }
}
