import { Terminatable } from '@soracom/shared/soracom-platform';
import { TableController } from '../../components/base_table_controller';
import {
  TerminateResourceComponent,
  TerminationResult,
} from '../../../../src/app/ng-rewrites/legacy-terminate-resource/terminate_resource.component';
import { TerminatableService } from '@soracom/shared/soracom-platform';
import { Action } from './Action';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

export class TerminateResourceAction<T extends Terminatable> implements Action<T> {
  constructor(
    private $log: ng.ILogService,
    private uiDsModalService: UiDsModalService,
    private ctrl: TableController<T>,
    private model: string,
    private service: TerminatableService,
    private $translate: any
  ) {}

  run() {
    this.uiDsModalService
      .openAndWaitForResult(TerminateResourceComponent<T>, {
        title: this.$translate.instant('terminate_resource.title', {
          modelName: this.$translate.instant(`model.${this.model}.modelName`),
        }),
        data: {
          resolve: {
            service: () => {
              return this.service;
            },
            params: () => {
              return {
                resources: this.ctrl.tableData.getSelectedData(),
                resourceType: this.model,
              };
            },
          },
        },
      })
      .then((result) => {
        if (result) {
          this.okAction(result);
        }
      });
  }

  actionable() {
    const notTerminated =
      this.ctrl.tableData.getSelectedData().filter((r) => {
        return r.status === 'terminated';
      }).length === 0;
    return this.ctrl.tableData.countChecked() > 0 && notTerminated;
  }

  private okAction = (result: TerminationResult<T>) => {
    if (result) {
      const messageValues = result.messageValues();
      const translationId = messageValues.translate;
      const values = messageValues.values;

      if (result.didSucceed()) {
        this.ctrl.alertsService.showAlert('success', { translationId, values });
      } else {
        this.ctrl.alertsService.showAlert('danger', { translationId, values });
      }

      const successResourceList = result.changed.map((o) => o.id);
      const failureResourceList = result.failed.map((o) => o.id);

      const terminationEnabled = !result.enableTerminationProtection;
      if (result.changed !== null && result.changed !== undefined) {
        this.ctrl.tableData.updateData(result.changed);
      }

      if (result.deleted?.length) {
        this.ctrl.tableData.deleteRows(result.deleted);
      }

      this.ctrl.getSelection().forEach((row) => {
        if (successResourceList.includes(row.obj.id)) {
          row.feedback.changeTerminationProtection = 'success';
          row.feedback.updateStatus = 'success';
        } else if (failureResourceList.includes(row.obj.id)) {
          if (row.obj.terminationEnabled !== terminationEnabled) {
            row.obj.terminationEnabled = terminationEnabled;
            row.feedback.changeTerminationProtection = 'success';
          }
          row.feedback.updateStatus = 'failure';
        } else {
          this.$log.error('logic failure JHS83-JD-823');
        }
      });
      // Mason 2019-09-19: Seems like the update above is not updting the full device record? If so, then it would not fetch the PAC field, which should now be populated, and then the Show PAC command would not be enabled? Investigate.
    }
  };
}
