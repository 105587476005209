import { LegacyAny } from '@soracom/shared/core';

import { ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  SoracomApiParams,
  TaggableService,
  TagParams,
} from '@user-console/legacy-soracom-api-client';
import { GroupableService } from '@soracom/shared/soracom-platform';
import { TerminatableService } from '@soracom/shared/soracom-platform';
import { InjectList } from '../core/injectable';
import { LoraDevice } from '../core/lora_device';

export class LoraDevicesService
  implements TaggableService, GroupableService, PaginatableService<LoraDevice>, TerminatableService
{
  static $inject: InjectList = ['$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'lora_devices';

  constructor(
    private $q: ng.IQService,
    private SoracomApi: LegacyBaseSoracomApiService,
    private PaginationService: PaginationService,
  ) {
    // no-op
  }

  list(queryOptions: LegacyAny): Promise<ScRelation<LoraDevice>> {
    const apiParams = {
      method: 'get',
      path: '/v1/lora_devices',
      query: {
        limit: queryOptions.limit,
        last_evaluated_key: queryOptions.last_evaluated_key,
      },
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const data = response.data.map((element: LegacyAny) => new LoraDevice(element));
      const relation: ScRelation<LoraDevice> = {
        data,
        links: this.PaginationService.getPaginationLinks(response.headers.link),
      };

      return this.$q.resolve(relation);
    });
  }

  register(loraDeviceId: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/lora_devices/${loraDeviceId}/register`,
      contentType: 'application/json',
    };

    return this.SoracomApi.callApiWithToken(apiParams);
  }

  setGroup(id: string, groupId: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/lora_devices/${id}/set_group`,
      body: { groupId },
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  unsetGroup(id: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/lora_devices/${id}/unset_group`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  updateTags(id: string, tags: TagParams[]) {
    const apiParams = {
      method: 'put',
      path: `/v1/lora_devices/${id}/tags`,
      contentType: 'application/json',
      body: tags,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  deleteTag(id: string, tagName: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/lora_devices/${id}/tags/${encodeURIComponent(tagName)}`,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  downlink(id: string, data: string, fPort: number) {
    const populatedData: { data: string; fPort?: number } = { data };
    if (fPort >= 0 && fPort <= 223) {
      populatedData.fPort = fPort;
    }
    const apiParams = {
      method: 'post',
      path: `/v1/lora_devices/${id}/data`,
      contentType: 'application/json',
      body: populatedData,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  terminate(id: string): Promise<LoraDevice> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/terminate`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => new LoraDevice(response.data));
  }

  disableTermination(id: string): Promise<LoraDevice> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/disable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => new LoraDevice(response.data));
  }

  enableTermination(id: string): Promise<LoraDevice> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/enable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => new LoraDevice(response.data));
  }
}
