import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { LagoonSubscription } from '../../../../app/shared/lagoon/lagoon_plans';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { RootStoreState } from '../../root-store';
import { selectSubscription } from '../../root-store/lagoon-store/selectors';
import { UpdateLagoonLicensePackModalComponent } from '../update-lagoon-license-pack-modal/update-lagoon-license-pack-modal.component';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-lagoon-license-packs',
  templateUrl: './lagoon-license-packs.component.html',
})
export class LagoonLicensePacksComponent {
  private destroyRef = inject(DestroyRef);
  subscription$ = this.store$.pipe(select(selectSubscription));

  alertsManager = new AlertsManager();
  hasPermission = false;

  constructor(
    private soracomApi: SoracomApiService,
    private modalService: UiDsModalService,
    private store$: Store<RootStoreState.State>
  ) {}

  ngOnInit() {
    this.subscription$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subscription) => {
      this.hasPermission = subscription === LagoonSubscription.pro;
    });
  }

  editPacks() {
    this.modalService
      .openAndWaitForResult(UpdateLagoonLicensePackModalComponent, {
        title: 'UpdateLagoonLicensePackModalComponent.heading',
      })
      .then((result: LegacyAny) => {
        if (result?.success) {
          this.alertsManager.add(Alert.success('LagoonLicensePacksComponent.update_license_pack_success_message'));
          this.soracomApi.renewToken().catch((e: LegacyAny) => {
            this.alertsManager.add(Alert.fromApiError(e));
          });
        }
      });
  }
}
