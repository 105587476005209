import { LegacyAny } from '@soracom/shared/core';

import { template } from './edit-event-handler.component.html';

import { ScLocationChangeEvent } from '../../../src/app/event-handler/event-handler-list/event-handler-list.component';
import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';

export class EditEventHandlerComponentWrapper implements ng.IComponentOptions {
  bindings = {};

  controller = EditEventHandlerComponentController;
  template: any = template;
}

export class EditEventHandlerComponentController extends BaseController {
  static $inject: InjectList = ['$location', '$log', '$routeParams', '$scope'];

  // @ts-expect-error (legacy code incremental fix)
  handlerId: string;
  // @ts-expect-error (legacy code incremental fix)
  groupId: string;
  // @ts-expect-error (legacy code incremental fix)
  imsi: string;
  // @ts-expect-error (legacy code incremental fix)
  operator: boolean;

  constructor(
    private $location: ng.ILocationService,
    private $log: ng.ILogService,
    private $routeParams: LegacyAny,
    private $scope: ng.IScope
  ) {
    super($log);
  }

  $onInit() {
    this.handlerId = this.$routeParams.handlerId;
    this.groupId = this.$routeParams.groupId;
    this.imsi = this.$routeParams.imsi;
    this.operator = this.$routeParams.operator;
  }

  updateLocation(event: ScLocationChangeEvent) {
    this.$location.path(event.path).search(event.search);
    this.$scope.$applyAsync(); // Callback event coming from Angular world doesn't affect anything somehow.
  }
}
