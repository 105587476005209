<div class="ds-rows">
  <h2>
    {{ 'GpsMultiunitNewGroupSelectComponent.title' | translate }}
  </h2>
  <div class="ds-notice--info" [innerHtml]="'GpsMultiunitNewGroupSelectComponent.description' | translate"></div>
  <div class="ds-group ds-span--12">
    <div class="ds-field">
      <label class="ds-radio" for="mode--create">
        <input id="mode--create" type="radio" name="mode" value="create" [(ngModel)]="mode" />
        <div>{{ 'GpsMultiunitNewGroupSelectComponent.labels.mode.create' | translate }}</div>
      </label>
    </div>
    <div class="ds-field">
      <label class="ds-label" for="newGroupName">{{
        'GpsMultiunitNewGroupSelectComponent.labels.newGroupName' | translate
      }}</label>
      <input class="ds-input" id="newGroupName" type="text" [disabled]="mode !== 'create'" [(ngModel)]="newGroupName" />
    </div>
    <div class="ds-notice--warning" *ngIf="nameExists()">
      {{ 'GpsMultiunitNewGroupSelectComponent.errors.nameExists' | translate }}
    </div>
  </div>
  <div class="ds-group ds-span--12">
    <div class="ds-field">
      <label class="ds-radio" for="mode--select">
        <input
          id="mode--select"
          type="radio"
          name="mode"
          value="select"
          [disabled]="groups.length === 0"
          [(ngModel)]="mode"
        />
        <div>{{ 'GpsMultiunitNewGroupSelectComponent.labels.mode.select' | translate }}</div>
      </label>
    </div>
    <div class="ds-field">
      <label class="ds-label" for="groupId">{{
        'GpsMultiunitNewGroupSelectComponent.labels.groupId' | translate
      }}</label>
      <select class="ds-select" id="groupId" name="groupId" [disabled]="mode !== 'select'" [(ngModel)]="groupId">
        <option *ngFor="let group of groups" [attr.label]="group.name" [ngValue]="group.id">
          {{ group.name || '(no name)' }}
        </option>
      </select>
    </div>
  </div>
  <ui-button-bar [content]="buttonBar"></ui-button-bar>
</div>
