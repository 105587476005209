'use strict';

/**
 * This module serves as an Async wrapper for LocalStorage
 */
module.exports.setItem = function (key, value) {
  return new Promise(function (resolve) {
    localStorage.setItem(key, value);
    resolve();
  });
};
module.exports.getItem = function (key) {
  return Promise.resolve(localStorage.getItem(key));
};
module.exports.removeItem = function (key) {
  return new Promise(function (resolve) {
    localStorage.removeItem(key);
    resolve();
  });
};