import { Taggable } from '@soracom/shared/soracom-platform';
import { Action } from '../../../../app/shared/core/actions/Action';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { EditTagsDialogComponent, TaggableService, Tags } from 'apps/user-console/src/app/feature-tags';

/**
 * Generic action to open Tag editor
 */
export class EditTagsAction<T extends Taggable> implements Action<T> {
  constructor(private uiDsModalService: UiDsModalService, private service: TaggableService) {}

  // tag_list_modal.title
  run(target: T[]) {
    this.uiDsModalService
      .openAndWaitForResult(EditTagsDialogComponent, {
        title: 'tag_list_modal.title',
        headerIconClass: '--icon-edit',
        data: { id: target[0].id, tags: target[0].tags },
      })
      .then((result) => {
        if (result) {
          this.updateTags(String(target[0].id), target[0].tags, result.tags);
        }
      });
  }

  actionable(target?: T[]) {
    return target?.length === 1;
  }

  private async updateTags(id: string, original: Tags = {}, modified: Tags = {}) {
    //This code is almost the same as the code in editTagsAction of feature-tags, but it cannot use ng2 injection since we are in the ng1 world.
    const updateTagParams = Object.keys(modified).map((tagName) => ({ tagName, tagValue: modified[tagName] }));
    const deleteTagNames = Object.keys(original).filter((tagName) => !(tagName in modified));

    if (updateTagParams.length > 0) {
      await this.service.updateTags(id, updateTagParams);
    }
    updateTagParams.forEach(({ tagName, tagValue }) => {
      original[tagName] = tagValue;
    });

    for (const tagName of deleteTagNames) {
      await this.service.deleteTag(id, tagName);
      delete original[tagName];
    }
  }
}
