import { Component, Input, OnInit } from '@angular/core';
import { VolumeDiscount } from '../long-term-discounts/VolumeDiscount';

@Component({
  selector: 'app-long-term-discount-list',
  templateUrl: './long-term-discount-list.component.html',
})
export class LongTermDiscountListComponent implements OnInit {
  get state(): 'loading' | 'no data' | 'has data' {
    if (this.discounts === null || this.discounts === undefined) {
      return 'loading';
    } else {
      return this.discounts.length > 0 ? 'has data' : 'no data';
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  @Input() discounts: VolumeDiscount[];

  ngOnInit() {}
}
