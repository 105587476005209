import { LegacyAny } from '@soracom/shared/core';

import dayjs from 'dayjs';
import { InjectList } from '../core/injectable';
import { BaseDirectiveController } from './base_directive_controller';

export function ScDateTimeDirective(): ng.IDirective {
  return {
    restrict: 'A',
    controller: ScDateTimeController,
    // @ts-expect-error (legacy code incremental fix)
    link: (scope, element, attrs, ctrl: ScDateTimeController) => {
      ctrl.activate(scope, element, attrs);
    },
  };
}

class ScDateTimeController extends BaseDirectiveController {
  static $inject: InjectList = ['$log', '$translate'];

  // @ts-expect-error (legacy code incremental fix)
  timeFormat: string;

  constructor($log: ng.ILogService, private $translate: any) {
    super($log);
  }

  activate(scope: LegacyAny, element: LegacyAny, attrs: LegacyAny) {
    const timeFormatKey = attrs.format || 'common.dayjs_format.datetime';
    this.$translate(timeFormatKey).then((translation: LegacyAny) => {
      this.timeFormat = translation;
      scope.$watch(attrs.scDateTime, (value: LegacyAny) => {
        if (value) {
          element.text(dayjs(value).format(this.timeFormat));
        } else {
          if (attrs.altText) {
            this.$translate(attrs.altText)
              .then((altText: LegacyAny) => {
                element.text(altText);
              })
              .catch((error: LegacyAny) => {
                element.text(error);
              });
          } else {
            element.text('');
          }
        }
      });
    });
  }
}
