import { LegacyAny } from '@soracom/shared/core';

/**
 * Create dummy data for debug
 *
 * @param resourceType
 * @param resourceId
 * @param queryObj
 */
export function getHarvestDummyData(resourceType: LegacyAny, resourceId: LegacyAny, queryObj: LegacyAny): any {
  let dummyData;

  if (resourceId === '111112222233333') {
    const dataInterval = 60000;
    dummyData = [];

    for (let i = 0; i < queryObj.limit; i++) {
      const record = {
        contentType: 'application/json',
        time: Date.now() - i * dataInterval,
        content: null,
      };
      const temperature = 10 + (i % 10) + Math.floor(Math.random() * 3);
      const humidity = 40 + (i % 10) + Math.floor(Math.random() * 3);
      if (i % 10 === 0) {
        // @ts-expect-error (legacy code incremental fix)
        record.content = '';
      } else if (i % 7 === 0) {
        // @ts-expect-error (legacy code incremental fix)
        record.content = JSON.stringify({
          humidity,
        });
      } else if (i % 11 === 0) {
        // @ts-expect-error (legacy code incremental fix)
        record.content = JSON.stringify({
          temperature,
        });
      } else {
        // @ts-expect-error (legacy code incremental fix)
        record.content = JSON.stringify({
          temperature,
          humidity,
        });
      }
      dummyData.push(record);
    }
  }

  if (dummyData) {
    return {
      data: dummyData,
      headers: () => {
        return { link: null };
      },
    };
  } else {
    return null;
  }
}
