import { LegacyAny } from '@soracom/shared/core';

import { ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  TaggableService,
  TagParams,
} from '@user-console/legacy-soracom-api-client';
import { TerminatableService } from '@soracom/shared/soracom-platform';
import { InjectList } from '../core/injectable';
import { LoraGateway } from '../core/lora_gateway';

export class LoraGatewaysService implements TaggableService, PaginatableService<LoraGateway>, TerminatableService {
  static $inject: InjectList = ['$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'lora_gateways';

  constructor(
    private $q: ng.IQService,
    private SoracomApi: LegacyBaseSoracomApiService,
    private PaginationService: PaginationService,
  ) {}

  list(queryOptions: LegacyAny): Promise<ScRelation<LoraGateway>> {
    const apiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}`,
      query: {
        limit: queryOptions.limit,
        last_evaluated_key: queryOptions.last_evaluated_key,
      },
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const data = response.data.map((element: LegacyAny) => new LoraGateway(element));
      const relation: ScRelation<LoraGateway> = {
        data,
        links: this.PaginationService.getPaginationLinks(response.headers.link),
      };

      return this.$q.resolve(relation);
    });
  }

  get(id: string) {
    const apiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}/${id}`,
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const gateway = new LoraGateway(response.data);
      return this.$q.resolve(gateway);
    });
  }

  setLoraNetworkSet(id: string, networkSetId: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/lora_gateways/${id}/set_network_set`,
      contentType: 'application/json',
      body: { networkSetId },
    };

    return this.SoracomApi.callApiWithToken(apiParams);
  }

  unsetLoraNetworkSet(id: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/lora_gateways/${id}/unset_network_set`,
      contentType: 'application/json',
    };

    return this.SoracomApi.callApiWithToken(apiParams);
  }

  updateTags(id: string, tags: TagParams[]) {
    const apiParams = {
      method: 'put',
      path: `/v1/${this.resourcePath}/${id}/tags`,
      contentType: 'application/json',
      body: tags,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  deleteTag(id: string, tagName: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/${this.resourcePath}/${id}/tags/${encodeURIComponent(tagName)}`,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  terminate(id: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/terminate`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  disableTermination(id: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/disable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => new LoraGateway(response.data));
  }

  enableTermination(id: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/enable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => new LoraGateway(response.data));
  }
}
