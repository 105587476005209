<div class="ds-cols--center">
  <div class="ds-span--12">
    <div class="x-gps-multiunit-index" *ngIf="state === 'index'">
      <app-gps-multiunit-index (onStateChanged)="relayOnStateChanged($event)"></app-gps-multiunit-index>
    </div>
    <div class="x-gps-multiunit-new-wizard-1-select-subscribers" *ngIf="state === 'selectSubscribers'">
      <app-gps-multiunit-new-subscribers-select></app-gps-multiunit-new-subscribers-select>
    </div>
    <div class="x-gps-multiunit-new-wizard-2-select-group" *ngIf="state === 'selectGroup'">
      <app-gps-multiunit-new-group-select></app-gps-multiunit-new-group-select>
    </div>
    <div class="x-gps-multiunit-new-wizard-3-edit-config" *ngIf="state === 'newConfig'">
      <app-gps-multiunit-config></app-gps-multiunit-config>
    </div>
    <div class="x-gps-multiunit-edit" *ngIf="state === 'editConfig'">
      <app-gps-multiunit-config></app-gps-multiunit-config>
    </div>
  </div>
</div>
