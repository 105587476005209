import { LegacyAny } from '@soracom/shared/core';

import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LAGOON_SERVICE } from '../../ajs-upgraded-providers';
import * as featureActions from './actions';

@Injectable()
export class LagoonStoreEffects {
  constructor(private actions$: Actions, private injector: Injector) {}

  get lagoonService() {
    return this.injector.get(LAGOON_SERVICE);
  }

  loadLagoonStateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadLagoonSubscriptionAndAvailableVersion),
      mergeMap(() => {
        const promise = new Promise((resolve, reject) => {
          try {
            resolve({
              availableVersions: this.lagoonService.getAvailableVersions(),
              subscription: this.lagoonService.getSubscription(),
            });
          } catch (e) {
            reject(e);
          }
        });
        return from(promise).pipe(
          // @ts-expect-error (legacy code incremental fix)
          map(featureActions.loadLagoonSubscriptionAndAvailableVersionSuccess),
          catchError((error: LegacyAny) => of(featureActions.loadLagoonSubscriptionAndAvailableVersionError(error)))
        );
      })
    )
  );
}
