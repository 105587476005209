import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DsAutocompleteModule } from '@soracom/shared-ng/ui-sds';
import { AutocompleteModule } from '@soracom/shared-ng/autocomplete';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { MultiResourceSelectorComponent } from './multi-resource-selector/multi-resource-selector.component';
import { ResourceSelectOptionComponent } from './resource-selector-option/resource-select-option.component';
// import { ResourceSelectorComponent } from './resource-selector.component';

@NgModule({
  declarations: [
    // ResourceSelectorComponent,
    MultiResourceSelectorComponent,
    ResourceSelectOptionComponent,
  ],
  imports: [
    A11yModule,
    CommonModule,
    ReactiveFormsModule,
    DsAutocompleteModule,
    AutocompleteModule,
    TranslateModule,
    SoracomUiModule,
  ],
  exports: [
    // ResourceSelectorComponent,
    MultiResourceSelectorComponent,
  ],
})
export class ResourceSelectorModule {}
