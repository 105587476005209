import { LegacyAny } from '@soracom/shared/core';

import dayjs from 'dayjs';

import { template } from './logs.component.html';

import { SearchQuery, SearchQueryItem } from '@user-console/legacy-soracom-api-client';

import { AndOr } from '../../../src/app/soracom-ui/ui-predicate-editor/AndOr';
import { AlertsService } from '../components/alerts.service';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import {
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { InjectList } from '../core/injectable';
import { Log } from '@soracom/shared/soracom-platform';
import { LogsService } from './logs.service';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { LogsRawDataModalComponent } from 'apps/user-console/src/app/napter/raw_data.modal.component';

// Mason 2021-03-31: Ethan here is the legacy "Error Logs" implementation to fetch those
export class LogsComponent implements ng.IComponentOptions {
  bindings = {};
  controller = LogsComponentController;
  template: any = template;
}

export class LogsComponentController extends BasePaginatableTableController<Log> {
  static $inject: InjectList = [
    '$location',
    '$log',
    '$translate',
    '$q',
    '$routeParams',
    '$scope',
    '$uibModal',
    'AlertsService',
    'LogsService',
    'TableColumnOptionsService',
    'UiDsModalService',
  ];

  constructor(
    private $location: LegacyAny,
    $log: LegacyAny,
    $translate: LegacyAny,
    $q: LegacyAny,
    private $routeParams: LegacyAny,
    private $scope: LegacyAny,
    private $uibModal: LegacyAny,
    AlertsService: AlertsService,
    private logsService: LogsService,
    private tableColumnOptionsService: TableColumnOptionsService,
    private uiDsModalService: UiDsModalService,
  ) {
    super($log, $translate, $q, AlertsService, logsService);
  }

  // @ts-expect-error (legacy code incremental fix)
  services: Array<{ code: string; label: string }>;
  // @ts-expect-error (legacy code incremental fix)
  resourceTypes: Array<{ code: string; label: string }>;
  // @ts-expect-error (legacy code incremental fix)
  resourceIdPlaceholder: string;

  // @ts-expect-error (legacy code incremental fix)
  service: string;
  resourceType: string | undefined;
  resourceId: string | undefined;
  // @ts-expect-error (legacy code incremental fix)
  min: dayjs.Dayjs;
  // @ts-expect-error (legacy code incremental fix)
  max: dayjs.Dayjs;
  // @ts-expect-error (legacy code incremental fix)
  from: number;
  // @ts-expect-error (legacy code incremental fix)
  to: number;
  path = 'logs';

  $onInit() {
    super.$onInit();

    this.setTraceEnabled(true);
    this.trace(this);

    this.setupColumnOptions(TableColumnOptionsType.Log, this.tableColumnOptionsService, this.uiDsModalService);

    this.service = this.$routeParams.service || 'all';

    const paramsResourceType = this.$routeParams.resource_type;
    if (paramsResourceType === 'LoraDevice') {
      this.resourceType = 'LoraDevice';
    } else if (paramsResourceType === 'SigfoxDevice') {
      this.resourceType = 'SigfoxDevice';
    } else if (paramsResourceType === 'Device') {
      this.resourceType = 'Device';
    } else if (paramsResourceType === 'Subscriber') {
      this.resourceType = 'Subscriber';
    } else {
      this.resourceType = 'all';
    }
    this.resourceId = this.$routeParams.resource_id;

    const now = dayjs();
    this.min = now.subtract(15, 'days');
    this.max = now.hour(0).add(1, 'days');
    this.from = this.min.add(1, 'days').valueOf();
    this.to = this.max.valueOf();
    this.services = this.getServiceOptions();
    this.resourceTypes = this.getResourceTypeOptions();
    this.resourceIdPlaceholder = this.getResourceIdPlaceholder();
    this.search();
    const linkClass: string[] = [];
    // this.breadcrumbs.breadcrumbs = [
    //   { linkClass, path: `/${this.path}`, title: this.$translate.instant(this.getPageTitleKey())},
    // ];
  }

  search() {
    this.searchQuery = this.buildSearchQuery();
    this.paginator.clearPagination();
    this.reloadData();
    this.updateUrl();
  }

  getServiceOptions() {
    const services = [{ code: 'all', label: this.$translate.instant('logs.service_options.all') }];
    this.logsService.serviceOptions().forEach((service) => {
      services.push({ code: service, label: this.$translate.instant('logs.service_options.' + service) });
    });
    return services;
  }

  getResourceTypeOptions() {
    const resourceTypes: LegacyAny = [];
    const options = this.logsService.resourceTypeOptions(this.service);
    options.forEach((resourceType) => {
      resourceTypes.push({
        code: resourceType,
        label: this.$translate.instant('logs.resource_type_options.' + resourceType),
      });
    });
    if (options.length === 1) {
      this.resourceType = options[0];
    }
    return resourceTypes;
  }

  updateResourceTypeOptions() {
    this.resourceTypes = this.getResourceTypeOptions();
  }

  getResourceIdPlaceholder() {
    if (this.resourceType && this.resourceType.length > 0) {
      return this.$translate.instant('logs.query.resource_id_placeholder_' + this.resourceType);
    } else {
      return this.$translate.instant('logs.query.resource_id_placeholder');
    }
  }

  openRawData(log: LegacyAny) {
    this.uiDsModalService.openAndWaitForResult(LogsRawDataModalComponent, {
      title: 'logs.modals.raw_data.title',
      data: {
        resolve: {
          log: () => {
            return log;
          },
        },
      },
    });
  }

  filterResource(log: Log) {
    this.resourceType = log.resourceType;
    this.resourceId = log.resourceId;
    this.search();
  }

  doesFilterResource(log: Log) {
    return this.resourceType === log.resourceType && this.resourceId === log.resourceId;
  }

  private buildSearchQuery(): SearchQuery {
    const queryItems: SearchQueryItem[] = [
      new SearchQueryItem('from', this.from.toString()),
      new SearchQueryItem('to', this.to.toString()),
    ];
    if (this.resourceType && this.resourceType !== 'all') {
      queryItems.push(new SearchQueryItem('resource_type', this.resourceType));
      if (this.resourceId && this.resourceId !== '') {
        queryItems.push(new SearchQueryItem('resource_id', this.resourceId));
      }
    }
    if (this.service && this.service !== 'all') {
      queryItems.push(new SearchQueryItem('service', this.service));
    }

    return new SearchQuery(queryItems, AndOr.and);
  }

  private updateUrl() {
    if (this.resourceType && this.resourceType !== 'all') {
      this.$location.search('resource_type', this.resourceType);
      if (this.resourceId && this.resourceId !== '') {
        this.$location.search('resource_id', this.resourceId);
      } else {
        this.$location.search('resource_id', undefined);
      }
    } else {
      this.$location.search('resource_type', undefined);
      this.$location.search('resource_id', undefined);
    }
    if (this.service && this.service !== 'all') {
      this.$location.search('service', this.service);
    } else {
      this.$location.search('service', undefined);
    }
  }

  getPageTitleKey(): string {
    // Mason 2021-03-03: user-facing name changed with the release of new Audit Logs feature.
    return 'nav.logs_menu.error_logs';
  }
}
