import { LegacyAny } from '@soracom/shared/core';

import dayjs from 'dayjs';

import { InjectList } from '../core/injectable';

export class AppController {
  static $inject: InjectList = ['$document', '$rootScope', '$translate'];

  constructor(
    private $document: ng.IDocumentService,
    private $rootScope: ng.IRootScopeService,
    private $translate: LegacyAny,
  ) {
    this.activate();
  }

  getAppName() {
    return this.$translate.instant('user.appname');
  }

  activate() {
    dayjs.locale(this.$translate.use()); // FIXME: CHECK THIS: don't we have to update this when the language is changed?

    this.$document.prop('title', this.getAppName());

    // Mason 2019-03-20: we know that at this stage of app boot, the Angular module is not yet available. The singleton global SoracomUserConsole object is available, however, as `window['SoracomUserConsole']`. But, I think we don't need to use it for anything here. We no longer rely on this controller to propagate the i18n settings from the AngularJS layer to the Angular layer.

    this.$rootScope.$on('$routeChangeSuccess', (_ev, curr) => {
      let key;

      if (curr.resolve && curr.resolve.title) {
        this.$document.prop('title', curr.resolve.title);
      } else {
        if (curr.titleKey) {
          key = curr.titleKey;
        } else {
          // Convert /foo_bar/1 to foo_bar-1
          const currPath = curr.$$route ? curr.$$route.originalPath : '';
          key = currPath.replace(/^\//, '').replace(/\//g, '-');
        }
        if (key !== null && key !== undefined && key !== '') {
          this.$translate('routes.title.' + key)
            .then((translation: LegacyAny) => {
              if (translation && translation.length > 0) {
                this.$document.prop('title', translation + ' - ' + this.getAppName());
              } else {
                this.$document.prop('title', this.getAppName());
              }
            })
            .catch(() => {
              this.$document.prop('title', this.getAppName());
            });
        } else {
          this.$document.prop('title', this.getAppName());
        }
      }
    });

    this.$rootScope.$on('$translateChangeEnd', () => {
      /**
       * TODO: Update title like $routeChangeSuccess event
       *
       * I can't set subtitle here because current route information is unknown.
       */
      this.$document.prop('title', this.getAppName());
    });
  }
}
