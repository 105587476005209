import { AbstractControl, ValidatorFn } from '@angular/forms';

export function encodedTextMaxLengthValidator(maxLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (encode(control.value).length > maxLength) {
      return { maxLength: true };
    } else {
      return null;
    }
  };
}

// Use same logic with server side logic
// URLEncoder.encode(text, 'utf-8')
// https://docs.oracle.com/javase/jp/8/docs/api/java/net/URLEncoder.html
export function encode(value: string) {
  return encodeURIComponent(value)
    .replace(/\!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/~/g, '%7E')
    .replace(/%20/g, '+');
}
