<div style="margin: 20px auto; max-width: 800px">
  <div class="ds-heading ds-heading--large-bold" style="margin-bottom: 30px; height: 150px">
    <div class="--loading" [attr.data-ds-message]="'support.support_login' | translate"></div>
  </div>
  <div style="margin: 20px 0">
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </div>
  <div style="text-align: center; margin-bottom: 20px" *ngIf="!loading">
    <a href="" onClick="window.close();event.preventDefault()">
      {{ 'common.close_window' | translate }}
    </a>
  </div>
</div>
