export const template = `<div class="sc-pagination ds-cols--end">
  <div class="ds-button-bar"><span class="items-per-page" ng-if="$ctrl.showPerPage()" for="items-per-page-select"
      translate="sc_pagination.items_per_page"></span>
    <div class="ds-field"><select class="items-per-page-select ds-select" ng-if="$ctrl.showPerPage()"
        ng-model="$ctrl.paginator.perPage" ng-options="itemCount for itemCount in $ctrl.itemsPerPageOptions"
        ng-change="$ctrl.updateItemsPerPage()"></select></div><span class="table-data-count"
      ng-show="$ctrl.isVisibleItemCountAndPosition()" translate="sc_pagination.items_count_and_position"
      translate-values="{{$ctrl.translationDataForItemsCountAndPosition()}}"></span><span class="table-selection-count"
      ng-show="$ctrl.isVisibleItemCountAndPositionAndSelection()"
      translate="sc_pagination.items_count_and_position_and_selection"
      translate-values="{{$ctrl.translationDataForItemsCountAndPosition()}}"></span>
  </div>
  <div class="ds-button-bar">
  <button
      class="ds-button--plain ds-button--hide-label ds-button--icon-refresh-cw reload-button"
      ng-disabled="!$ctrl.reloadable() || $ctrl.isLoading" ng-click="$ctrl.reload()"><span
        translate="sc_pagination.reload"></span></button><button
      class="ds-button--plain ds-button--hide-label ds-button--icon-arrow-left pager-prev-button"
      ng-if="$ctrl.showPager()" ng-class="{ disabled: !$ctrl.hasPrev() }" ng-click="$ctrl.prev()"
      ng-disabled="!$ctrl.hasPrev() || $ctrl.isLoading"><span translate="sc_pagination.prev"></span></button><button
      class="ds-button--plain ds-button--hide-label ds-button--icon-arrow-right pager-next-button"
      ng-if="$ctrl.showPager()" ng-class="{ disabled: !$ctrl.hasNext() }" ng-click="$ctrl.next()"
      ng-disabled="!$ctrl.hasNext() || $ctrl.isLoading"><span translate="sc_pagination.next"></span></button>

        <button ng-if="$ctrl.shouldShowSettingsButton()" ng-click="$ctrl.executeParentSettingsFunction()" type="button"  class="ds-button --plain --icon-settings --hide-label"><span>Table Settings</span></button>

      </div>
</div>`;
