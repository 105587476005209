import { LegacyAny } from '@soracom/shared/core';
import { Terminatable } from '@soracom/shared/soracom-platform';
import { instanceOfGroupService } from '@soracom/shared/soracom-platform';
import { TerminatableService } from '@soracom/shared/soracom-platform';
import { instanceOfGroup } from '@soracom/shared/group';
import { openSigfoxDeleteImmediatelySuccessModal } from '../sigfox-delete-immediately-success/sigfox_delete_immediately_success.component';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';

export class TerminationResult<T extends Terminatable> {
  constructor(public enableTerminationProtection: boolean, private translations: { modelName: string }) {}

  changed: T[] = [];
  deleted: T[] = [];
  failed: T[] = [];
  errors: any[] = [];

  didSucceed() {
    return this.errors.length === 0;
  }

  /**
   * Returns an object like `{translate: 'foo.bar', values: {baz: 'hoge'}}` which
   * contains the i18n key, and translation values (if any) for a success or
   * error message, something like:
   *
   * "Enabled termination protection for 7 SIMs", or "Disabling termination
   * protection for the SIM failed: some err message".
   *
   * This method doesn't indicate whether the result is a failure message or
   * success message; use didSucceed() to determine that.
   */
  messageValues() {
    const changed = this.changed.length;
    const failed = this.failed.length;
    const total = changed + failed;
    const info = this.getUnderlyingErrorInfo();
    const values = { changed, failed, total, info, modelName: this.translations.modelName };

    const result = this.didSucceed() ? 'success' : 'failure';
    const singular = this.numberOfResults() === 1 ? 'one' : 'multiple';
    const translate = `terminate_resource.${result}_message_${singular}`;
    return { translate, values };
  }

  getUnderlyingErrorInfo() {
    const errorMessages: string[] = [];

    this.errors.forEach((err) => {
      const msg = (err && err.data && err.data.message) || 'error';
      errorMessages.push(msg);
    });

    // FIXME: was gonna do some fancy error coalescing but gave up. decide if we do that or not...
    return errorMessages.length > 0 ? errorMessages[0] : 'error';
  }

  numberOfResults() {
    return this.changed.length + this.failed.length + this.deleted.length;
  }

  // Mason 2017-10-23: someday we will have TS types for the above, but we don't yet...
}

@Component({
  selector: 'app-terminate-resource',
  standalone: true,
  templateUrl: './terminate_resource.component.html',
  imports: [CommonModule, FormsModule, TranslateModule],
})
export class TerminateResourceComponent<T extends Terminatable>
  extends LegacyUibModalAdapterModalBase<
    {
      service: () => TerminatableService;
      params: () => {
        resources: T[];
        resourceType: string;
      };
    },
    TerminationResult<T>
  >
  implements OnInit
{
  resources: T[] = [];

  // @ts-expect-error (legacy code incremental fix)
  resourceTranslations: {
    modelName: string;
    id: string;
  };
  // @ts-expect-error (legacy code incremental fix)
  confirmationText: string;

  enableTerminationProtection = true;

  // @ts-expect-error (legacy code incremental fix)
  protectionSwitchState: boolean;
  // @ts-expect-error (legacy code incremental fix)
  allProtectionEnabled: boolean;

  submitting = false;

  // @ts-expect-error (legacy code incremental fix)
  service: TerminatableService;

  deleteImmediatelySwitchState = false;

  constructor(private $translate: TranslateService, private uiDsModalService: UiDsModalService) {
    super();
  }

  ngOnInit() {
    if (this.resolve?.service) {
      this.service = this.resolve?.service();
    }
    if (this.resolve && this.resolve.params) {
      this.resources = this.resolve.params().resources || [];
      this.initTranslations();
      this.protectionSwitchState = this.protectedAny();
    }
  }

  initTranslations() {
    const resourceType = this.resolve?.params().resourceType;
    const prefix = `model.${resourceType}`;
    const translationKeys = [`${prefix}.modelName`, `${prefix}.id`, `terminate_resource.confirmation.${resourceType}`];
    this.resourceTranslations = {
      modelName: this.$translate.instant(translationKeys[0]),
      id: this.$translate.instant(translationKeys[1]),
    };
    this.confirmationText = this.$translate.instant(translationKeys[2]);
  }

  protectionSwitchStateChanged() {
    if (this.protectionSwitchState) {
      this.protectAll().then(() => {
        this.enableTerminationProtection = true;
      });
    } else {
      this.unprotectAll().then(() => {
        this.enableTerminationProtection = false;
      });
    }
  }

  isSigfoxDevice() {
    return this.resolve?.params().resourceType === 'sigfox_device';
  }

  protectedAny(): boolean {
    // Return false if a resource exists that termination is disabled.
    return this.resources.filter((r) => !r.terminationEnabled).length > 0;
  }

  canSubmit(): boolean {
    return !this.protectedAny();
  }

  cancel() {
    this.close();
  }

  submit() {
    this.submitting = true;

    const result = new TerminationResult<T>(this.enableTerminationProtection, this.resourceTranslations);

    this.resources.forEach((resource) => {
      const resourceId = String(resource.id);

      let promise;
      if (instanceOfGroup(resource) && instanceOfGroupService(this.service) && resource.groupId) {
        promise = this.service.unsetGroup(resourceId).then(() => {
          return this.isSigfoxDevice()
            ? this.service.terminate(resourceId, this.deleteImmediatelySwitchState)
            : this.service.terminate(resourceId);
        });
      } else {
        promise = this.isSigfoxDevice()
          ? this.service.terminate(resourceId, this.deleteImmediatelySwitchState)
          : this.service.terminate(resourceId);
      }

      promise
        .then((updatedResource: LegacyAny) => {
          if (this.isSigfoxDevice() && this.deleteImmediatelySwitchState) {
            openSigfoxDeleteImmediatelySuccessModal(this.uiDsModalService, updatedResource);
            result.deleted.push(updatedResource);
          } else {
            result.changed.push(updatedResource);
          }
        })
        .catch((err: LegacyAny) => {
          result.errors.push(err);
          result.failed.push(resource);
        })
        .finally(() => {
          // We always close with a result object, pass or fail.
          if (result.numberOfResults() === this.resources.length) {
            this.close(result);
            this.submitting = false;
          }
        });
    });
  }

  protectAll() {
    const promises: Array<Promise<Terminatable>> = [];
    this.resources.forEach((r) => {
      promises.push(
        this.service
          .disableTermination(r.id as string)
          .then(() => {
            r.terminationEnabled = false;
            return r;
          })
          .catch((err: LegacyAny) => {
            return r;
          })
      );
    });
    return Promise.all(promises);
  }

  unprotectAll() {
    const promises: Array<Promise<Terminatable>> = [];
    this.resources.forEach((r) => {
      promises.push(
        this.service
          .enableTermination(r.id as string)
          .then(() => {
            r.terminationEnabled = true;
            return r;
          })
          .catch((err: LegacyAny) => {
            return r;
          })
      );
    });
    return Promise.all(promises);
  }

  getTranslation(translationKey: string) {
    return this.$translate.instant(translationKey, this.resourceTranslations);
  }
}
