import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';

import { InjectList } from '../core/injectable';
import { ReservedTagNames } from '../core/reserved_tag_names';

/**
 * We need to cache the result of the filter function to
 * avoid "10 $digest iterations reached" error because if the
 * filter returns a collection of new objects every time it runs,
 * Angular's ngRepeat doesn't realize that those objects are equal
 * because ngRepeat tracks them by id ($$hashkey) of the objects.
 * New object leads to new id. This makes Angular think that
 * something has changed since the last check, which means that
 * Angular should run another check (aka digest). The next digest
 * ends up getting yet another new set of objects, and so another
 * digest is triggered. That repeats until Angular gives up (10
 * times). That's why we get the "10 $digest iterations reached"
 * error messages unless caching the filter result.
 * Originally we used _.memoize() for the purpose of caching,
 * it seemed working fine but has a memory leak issue due to
 * lacking unmemoize functionality.
 * We use $cacheProvider and clean up the cache everytime if
 * the items we are interested in are changed to avoid the
 * memory leak (unlimited caching).
 * *
 * @param $cacheFactory
 * @param ReservedTagNames
 * @return {filter}
 * @constructor
 */
export const OrderReservedTagFilter = ($cacheFactory: LegacyAny) => {
  const cache = $cacheFactory('OrderReservedTagFilter');

  return (items: LegacyAny, field: LegacyAny, reverse: LegacyAny) => {
    const cacheKey = JSON.stringify(items) + '&field=' + field + '&reverse=' + reverse;
    const cachedResult = cache.get(cacheKey);
    if (!!cachedResult) {
      return cachedResult;
    }

    const filtered: LegacyAny = [];
    angular.forEach(items, (value, key) => {
      filtered.push({
        key,
        value,
      });
    });
    filtered.sort((a: LegacyAny, b: LegacyAny) => {
      const ia = ReservedTagNames.indexOf(a.key);
      const ib = ReservedTagNames.indexOf(b.key);
      if (ia === -1 || ib === -1) {
        return 0;
      }
      return ia - ib;
    });
    if (reverse) {
      filtered.reverse();
    }

    cache.removeAll();
    return cache.put(cacheKey, filtered);
  };
};

const injectList: InjectList = ['$cacheFactory'];
OrderReservedTagFilter.$inject = injectList;
