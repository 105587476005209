export const template = `<div class="ds-rows --indent" ng-if="$ctrl.tutorialBox">
  <div>
    <h2>{{ $ctrl.tutorialBox.header }}</h2>
    <h3 class="ds-heading --small --color-ink-tint">{{ $ctrl.tutorialBox.subheader }}</h3>
  </div>
  <div class="ds-cols--4"><a class="ds-banner --plain --indent-small" ng-href="{{solution.link}}" target="_blank"
      ng-repeat="solution in $ctrl.tutorialBox.solutions"><i
        class="ds-icon --xxlarge --color-celeste {{solution.icon}}"></i>
      <div>
        <h4 class="ds-heading --xsmall-bold">{{ solution.title }}</h4>
        <h5 class="ds-heading --xxsmall">{{ solution.subtitle }}</h5>
        <p class="ds-text --label --xxsmall">{{ solution.duration }}</p>
      </div>
    </a></div>
</div>`;
