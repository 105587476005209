<section dsDialogContent>
  <div class="ds-text">{{ 'group_delete.message' | translate }}</div>
  <dl class="ds-details">
    <dt>
      <div>{{ 'group_delete.name' | translate }}</div>
    </dt>
    <dd>
      <div>{{ name }}</div>
    </dd>
  </dl>
  <app-alerts [alertsManager]="alertsManager" *ngIf="alertsManager.alerts.length > 0"></app-alerts>
</section>
<footer dsDialogFooter>
  <button class="ds-button --primary --alert x-confirm-delete-group" (click)="submit()"><span>{{ 'group_delete.submit' | translate }}</span></button>
  <button class="ds-button" (click)="cancel()" uiFocusMe><span>{{ 'group_delete.cancel' | translate }}</span></button>
</footer>
