/**
 * All searchable fields (canonical reference used internall in user-console).
 */
export enum SigfoxSearchableField {
  any = 'any',
  name = 'name',
  group = 'group',
  deviceId = 'deviceId',
  tag = 'tag',
  status = 'status',
  registration = 'registration',
}

export enum SigfoxSearchOption {
  searchType = 'search_type',

  // limit            = "limit",
  // lastEvaluatedKey = "last_evaluated_key",
  // Mason 2019-03-13: I don't think we need these here? I think ScPaginator handles these keys which are shared for other API queries and limit the pagination whether or not search is being done
}

export enum SigfoxSearchStatusValue {
  active = 'active',
  // inactive   = "inactive",
  // suspended  = "suspended",
  terminated = 'terminated',
  // shipped    = "shipped",
  ready = 'ready',
  inStock = 'inStock',
  // banned     = "banned",
  // standby    = "standby",
}

export enum SigfoxSearchRegistrationValue {
  notStarted = 'notStarted',
  pending = 'pending',
  success = 'success',
  failed = 'failed',
}
