import { LegacyAny } from '@soracom/shared/core';

export interface ItemChooserController<T> {
  items: T[];
  disabled: boolean;
  showCreate: boolean;
  createItem(): LegacyAny;
  getItemName(item: T): string;
  setItem(item: T): void;
  unsetItem(): void;
  unsetLinkClass(): {
    [className: string]: boolean;
  };
}

export class NullItemChooserController implements ItemChooserController<any> {
  items = [];
  disabled = false;
  showCreate = false;

  createItem() {
    // noop
  }

  getItemName(item: any): string {
    return '';
  }

  setItem(item: any): void {
    // noop
  }

  unsetItem(): void {
    // noop
  }

  unsetLinkClass(): { [className: string]: boolean } {
    return {};
  }
}
