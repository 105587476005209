import { HttpLinkHeader } from '@user-console/legacy-soracom-api-client';
import { PaginationState } from './PaginationState';

/**
 * An abstract class representing one or more pages of data records of type `T`, and pagination-related metadata: page number, items per page, and prev/next links. This object maintains some state related to pagination, and one page of records.
 *
 * This initial version doesn't cache page content, it is expected to reload every time we navigate to a new page. This could be added someday if desired. But right now this object models multiple pages of data, but "only one page at a time".
 *
 * FIXME: This generic code should be relocated out of soracom-logs module, once it is tested and known to work well. It is kind of duplicative of some existing pagination code in the user console, too, so we might want to look at unifying it. (This code is trying to minimize dependencies so that the entire soracom-logs module can be moved to the modern console, which is why it was developed independently.)
 *
 * NOTES: There is kind of a disconnect between the way we do paginated data in component controllers and this class. We have all the info we need to re-query HERE in this class, because the HttpLinkHeaders have the full URL. But, we end up just using the last_evaluated_key and re-creating the query, because usually we are using API client / service and don't have an easy way to use a raw URL. Not sure how to fix this or even if we should, but it bugs me...
 */
export abstract class PaginatedData<T> {
  /**
   * Zero-indexed page number (0 is the first page).
   */
  pageNumber = 0;

  private _paginationLinks: HttpLinkHeader[] = [];

  // @ts-expect-error (legacy code incremental fix)
  private _paginationState: PaginationState;

  constructor(
    /**
     * Number of items per page (the pagination setting, regardless of how many items actually exist on this page).
     */
    perPageCount: number,

    /**
     * The log entries contained by the page.
     */
    readonly entries: T[],

    /**
     * Pagination links for every page visited so far. We need to keep track of these to be able to navigate backwards to previous pages.
     */
    paginationLinks: HttpLinkHeader,
  ) {
    this._paginationLinks.push(paginationLinks);
    this._updatePaginationState(perPageCount);
  }

  private _updatePaginationState(perPageCount: number) {
    const start = this.pageNumber * perPageCount;
    const first = start + 1;
    const last = start + (this.entries.length || perPageCount);

    const currentPageLinks = this._paginationLinks[this.pageNumber];
    const previousPageLinks = this._paginationLinks[this.pageNumber - 1];

    this._paginationState = {
      currentPage: this.pageNumber,
      hasNextPage: !!currentPageLinks.next,
      hasPreviousPage: !!previousPageLinks, // .prev won't exist for first page
      perPageCount,
      currentPageRange: { first, last },
    };

    if (!this.paginationState.hasNextPage) {
      // A limitation of our typical pagination mechanism is that we only know
      // the total record count once we get to the last page.But still, that is
      // useful to the user to display in that case.
      this.paginationState.currentPageRange.total = last;
    }
  }

  /**
   * The pagination state of the page. Used by UI to display pagination info.
   */
  get paginationState() {
    return this._paginationState;
  }

  /**
   * Return the pagination links for the current page, e.g. a `prev` link that points to the current page (yes, that is confusing!) and a `next` link that points to the next page. If you need the link to the previous page, see `previousPagePaginationLinks`.
   */
  get currentPagePaginationLinks(): HttpLinkHeader {
    return this._paginationLinks[this.pageNumber];
  }

  /**
   * Return the pagination links for the previous page (if any). This means you get a `prev` link that points to the previous page, and a `next` link that points to the current page.
   */
  get previousPagePaginationLinks(): HttpLinkHeader | undefined {
    return this._paginationLinks[this.pageNumber - 1];
  }

  /**
   * Move to a new page (0 is the first page, the UI will make it human-readable). The caller (presumably a component controller that manages a paginated list of records) is responsible for providing the new `entries`, and pagination `links` as returned from an API call. The receiver will then update its pagination information to provide the UI with the necessary info.
   *
   * NOTE: the `replacing` parameter is the existing page we are replacing (could be the previous page, or the next page). This is kind of a design smell — this could be improved by more cleanly separating "collection of data pages" vs "one page of data". In this case, we mostly treat this object as "one page of data" but it has the pagination info (links) for a set of pages, so when creating a new page that is not the first page, we have to copy the pagination link data from the page we are replacing. This should hopefully be fixed someday and made more clean.
   */
  setPageNumber(pageNumber: number, entries: T[], links: HttpLinkHeader, replacing: PaginatedData<T>) {
    // Make sure to make a copy of entries:
    const newEntries = [...entries];

    if (replacing) {
      this._paginationLinks = replacing._paginationLinks;
    }

    this.pageNumber = pageNumber;
    this.entries.length = 0;
    this.entries.push(...newEntries);
    this._paginationLinks[this.pageNumber] = links;

    const perPageCount = this._paginationState.perPageCount;
    this._updatePaginationState(perPageCount);
  }

  /**
   * Convenience method to provide the page number and pagination key for the previous page. If the previous page is the first page, then the `lastEvaluatedKey` property will not be present in the structure returned.
   */
  get previousPageInfo(): { page: number; lastEvaluatedKey?: string } {
    const page = this.pageNumber - 1;
    const lastEvaluatedKey = this.previousPagePaginationLinks?.prev?.lastEvaluatedKey;
    return { page, lastEvaluatedKey };
  }

  get currentPageInfo(): { page: number; lastEvaluatedKey?: string } {
    const page = this.pageNumber;
    const lastEvaluatedKey = this.currentPagePaginationLinks?.prev?.lastEvaluatedKey;
    return { page, lastEvaluatedKey };
  }

  /**
   * Convenience method to provide the page number and pagination key for the next page. If there
   */
  get nextPageInfo(): { page: number; lastEvaluatedKey?: string } {
    const page = this.pageNumber + 1;
    // @ts-expect-error (legacy code incremental fix)
    const lastEvaluatedKey = this.currentPagePaginationLinks?.next.lastEvaluatedKey;
    return { page, lastEvaluatedKey };
    // FIXME: last page something something
  }
}
