export const template = `<form class="subscriber-searchbox-form" name="$ctrl.simSearchForm" novalidate="novalidate"
  ng-submit="$ctrl.invokeSearch()">
  <div class="input-group"><input class="form-control" id="search-keywords" type="search"
      ng-model="$ctrl.searchKeywords" translate-attr="{ placeholder: $ctrl.searchPlaceholder }"
      ng-change="$ctrl.onSearchKeywordsChange()" ng-keydown="$ctrl.handleKeydown($event)" />
    <div class="input-group-btn"
      ng-show="$ctrl.searchKeywords !== undefined &amp;&amp; $ctrl.searchKeywords !== null &amp;&amp; $ctrl.searchKeywords.length !== 0">
      <button class="btn btn-default" type="button" ng-click="$ctrl.clear()"><i
          class="glyphicon glyphicon-remove-circle"></i></button></div>
    <div class="input-group-btn"><button class="btn btn-default" id="subscriber-search-button" type="button"
        ng-click="$ctrl.invokeSearch()"
        ng-disabled="!$ctrl.onlineOnly &amp;&amp; !$ctrl.offlineOnly &amp;&amp; ($ctrl.searchKeywords === undefined || $ctrl.searchKeywords === null || $ctrl.searchKeywords.length === 0 || $ctrl.simSearchForm.$invalid)"><span
          translate="{{$ctrl.searchCategoryId}}"></span>&nbsp;<i class="glyphicon glyphicon-search"></i></button></div>
    <div class="input-group-btn">
      <div class="btn-group" uib-dropdown="uib-dropdown"><button class="btn btn-default dropdown-toggle"
          id="search-dropdown-toggle" uib-dropdown-toggle="uib-dropdown-toggle"><i class="caret"></i></button>
        <ul class="dropdown-menu" role="menu" uib-dropdown-menu="uib-dropdown-menu">
          <li ng-repeat="category in $ctrl.categories"><a href="" id="subscriber-search-category-{{category.code}}"
              ng-click="$ctrl.setSearchCategory(category)" translate="{{ category.id }}"></a></li>
          <li><!-- XXX: wrapping a tag to apply the style--><a><input type="checkbox"
                id="subscriber-search-category-online" ng-model="$ctrl.onlineOnly"
                ng-click="$ctrl.toggleWithOnlineOnly(); $event.stopPropagation()" /><label
                for="subscriber-search-category-online" ng-click="$event.stopPropagation()" style="font-weight: normal"
                translate="subscribers.search_category.online_only"></label></a></li>
          <li><!-- XXX: wrapping a tag to apply the style--><a><input type="checkbox"
                id="subscriber-search-category-offline" ng-model="$ctrl.offlineOnly"
                ng-click="$ctrl.toggleWithOfflineOnly(); $event.stopPropagation()" /><label
                for="subscriber-search-category-offline" ng-click="$event.stopPropagation()" style="font-weight: normal"
                translate="subscribers.search_category.offline_only"></label></a></li>
        </ul>
      </div>
    </div>
  </div>
</form>`;
