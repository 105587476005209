import { LegacyAny } from '@soracom/shared/core';

import { IFormController } from 'angular';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { BaseEditTagController } from '../components/base_edit_tag.controller';
import { InjectList } from '../core/injectable';
import { LoraNetworkSet } from '../core/lora_network_set';
import { template } from './lora_network_set.component.html';
import { LoraNetworkSetsService } from './lora_network_sets.service';

export class LoraNetworkSetComponent implements ng.IComponentOptions {
  bindings = {};
  controller = LoraNetworkSetComponentController;
  template: any = template;
}

export class LoraNetworkSetComponentController extends BaseController {
  static $inject: InjectList = [
    '$filter',
    '$location',
    '$log',
    '$q',
    '$routeParams',
    'AlertsService',
    'UiDsModalService',
    'LoraNetworkSetsService',
  ];

  alertsService: AlertsServiceInstance;
  // @ts-expect-error (legacy code incremental fix)
  networkSetId: string;
  // @ts-expect-error (legacy code incremental fix)
  loraNetworkSet: LoraNetworkSet;
  nameEditCtrl: LegacyAny;
  tr;
  status: LegacyAny;
  // @ts-expect-error (legacy code incremental fix)
  operatorId: string;
  // @ts-expect-error (legacy code incremental fix)
  addAllowedOperatorForm: IFormController;
  serviceName = 'LoraNetworkSetsService';
  isLoading = true;
  editable = true;

  constructor(
    $filter: ng.IFilterService,
    private $location: ng.ILocationService,
    $log: ng.ILogService,
    private $q: ng.IQService,
    private $routeParams: LegacyAny,
    AlertsService: AlertsService,
    private uiDsModalService: UiDsModalService,
    private loraNetworkSetsService: LoraNetworkSetsService
  ) {
    super($log);
    this.alertsService = AlertsService.generate();
    this.tr = $filter('translate');
  }

  $onInit() {
    this.networkSetId = this.$routeParams.id;
    this.status = {
      permissions: { isOpen: true },
      tags: { isOpen: true },
      delete: { isOpen: false },
    };

    this.loraNetworkSetsService
      .get(this.networkSetId)
      .then((loraNetworkSet) => {
        this.loraNetworkSet = loraNetworkSet;
        const nameEditCtrl = new BaseEditTagController(this.logService, this.$q);
        nameEditCtrl.service = this.loraNetworkSetsService;
        nameEditCtrl.editing = loraNetworkSet;
        nameEditCtrl.alertsService = this.alertsService;
        this.nameEditCtrl = nameEditCtrl;

        return this.loraNetworkSetsService.listGateways(this.networkSetId);
      })
      .then((scRelation) => {
        this.loraNetworkSet.loraGateways = scRelation.data;
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  addPermission() {
    this.loraNetworkSetsService
      .addPermission(this.loraNetworkSet.networkSetId, this.operatorId)
      .then((data) => {
        this.loraNetworkSet = data;
        // @ts-expect-error (legacy code incremental fix)
        this.operatorId = null;
        this.addAllowedOperatorForm.$setPristine();
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  revokePermission(allowedOperatorId: LegacyAny) {
    this.loraNetworkSetsService
      .revokePermission(this.loraNetworkSet.networkSetId, allowedOperatorId)
      .then((data) => {
        this.loraNetworkSet = data;
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  async deleteLoraNetworkSet() {
    const confirmed = await this.uiDsModalService.openGenericConfirmModal('lora_network_set.modals.confirm_delete_ns', {
      modalStyle: 'delete',
    });
    if (confirmed) {
      this.doDeleteLoraNetworkSet();
    }
  }

  doDeleteLoraNetworkSet() {
    this.loraNetworkSetsService
      .destroy(this.loraNetworkSet.networkSetId)
      .then(() => {
        this.$location.path('/lora_network_sets');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  toString(key: LegacyAny, value: LegacyAny): string {
    return (this.tr as LegacyAny)(['lora_network_set', key, value].join('.'));
  }

  getDataCount(): number {
    if (this.loraNetworkSet && this.loraNetworkSet.allowedOperators) {
      return this.loraNetworkSet.allowedOperators.length;
    }
    {
      return 0;
    }
  }
}
