export class TrafficVolumeRankingLimitConstraint {
  private static limitMin = 1;
  private static limitMax = 50;

  static isValidLimitNumber(givenLimit: number): boolean {
    return (
      TrafficVolumeRankingLimitConstraint.limitMin <= givenLimit ||
      givenLimit >= TrafficVolumeRankingLimitConstraint.limitMax
    );
  }
}
