import { LegacyAny } from '@soracom/shared/core';

import jquery from 'jquery';

import { BaseDirectiveController } from './base_directive_controller';

import { InjectList } from '../core/injectable';
import { ContextMenuService } from './context_menu.service';

/** The sc-context-menu directive lets you assign an element (typically a <ul> with directive `uib-dropdown-menu` applied to it, but it can be any element) as a context menu for an element. The element that is the context menu must have a unique HTML id attribute. */
export function ContextMenuDirective(): ng.IDirective {
  return {
    restrict: 'A',
    controller: ContextMenuDirectiveController,
    // @ts-expect-error (legacy code incremental fix)
    link: (
      scope: ng.IScope,
      element: ng.IAugmentedJQuery,
      attributes: ng.IAttributes,
      controller: ContextMenuDirectiveController
    ): void => {
      controller.activate(scope, element, attributes);
    },
  };
}

export class ContextMenuDirectiveController extends BaseDirectiveController {
  static $inject: InjectList = ['$log', 'ContextMenuService'];

  constructor(private $log: ng.ILogService, private ContextMenuService: ContextMenuService) {
    super($log);
  }

  /** The activate() method runs once (per directive instance, i.e. once for each element that uses sc-context-menu), and performs initial setup.
   */
  activate(scope: ng.IScope, element: ng.IAugmentedJQuery, attributes?: ng.IAttributes) {
    super.activate(scope, element, attributes);

    this.setUpEventHandlers(scope, element, attributes);
  }

  setUpEventHandlers(scope: LegacyAny, element: LegacyAny, attributes: LegacyAny) {
    const id = attributes.scContextMenu;
    const cm = jquery('#' + id);

    this.ContextMenuService.hideContextMenu(cm);

    element.bind('contextmenu', (event: LegacyAny) => {
      this.ContextMenuService.timestampOfLastContextMenuEvent = event.timeStamp;

      this.debug(`contextmenu event handler for '${id}' executing for event ${event.timeStamp}`);

      if (id === 'disable') {
        // In the context of a jQuery event handler (like this one), this means: don't let any context menu show at all, whether the browser/os one or any parent element's sc-context-menu. So we return false here to both event.preventDefault() AND event.stopPropagation() (to any parent sc-context-menu, in this case...) https://stackoverflow.com/a/1357151/164017

        this.debug(
          `contextmenu event handler for '${id}' is preventing all further handling of this event (even by the browser).`
        );
        event.stopPropagation();
        event.preventDefault();
        return;
      }

      if (id === 'default') {
        // Just prevent propagation, so that any parent DOM elements with sc-context-menu will not receive this event. This will allow the browser's regular context menu to run.
        this.debug(
          `contextmenu event handler for '${id}' is preventing all further sc-context-menu processing for event ${event.timeStamp} to let the browser handle it.`
        );
        event.stopPropagation();
        return;
      } else {
        this.debug(
          `contextmenu event handler for '${id}' is handling event ${event.timeStamp} and preventing all other handling.`
        );

        const position = this.ContextMenuService.calcContextMenuPosition(event, cm);
        this.ContextMenuService.showContextMenu(cm, position.x, position.y, element);

        event.stopPropagation();
        event.preventDefault();
        return;
      }
    });
  }
}
