import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { Action } from '../../../app/shared/core/actions/Action';
import { HarvestData } from '../../../app/shared/core/harvest_data';
import { HarvestDataService } from '../../../app/shared/harvest_data/harvest_data.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

const i18nPrefix = 'data.delete_data_action_modal';

// AlertsManager can be component-specific so this class should not be `providedIn: 'root'`
@Injectable()
export class HarvestDataDeleteAction implements Action<HarvestData> {
  constructor(private service: HarvestDataService, private alertsManger: AlertsManager) {}

  /** @returns `true`: Data deleted */
  async run(target: HarvestData[]): Promise<boolean> {
    const promises = target.map((t) => Promise.resolve(this.service.delete(t)));
    const totalRecords = promises.length;
    return Promise.allSettled(promises).then((results) => {
      const errors = results.filter((r: LegacyAny) => r.status === 'rejected').map((r: LegacyAny) => r['reason']);
      const numErrors = errors.length;

      if (numErrors === 0) {
        this.alertsManger.add(
          Alert.success(
            LegacyTextContent.translation(`${i18nPrefix}.succeed`, {
              all: String(totalRecords),
              success: String(totalRecords),
            })
          )
        );
      } else {
        this.alertsManger.add(
          Alert.danger(
            LegacyTextContent.translation(`${i18nPrefix}.error`, {
              all: String(totalRecords),
              failure: String(numErrors),
            })
          )
        );
        this.alertsManger.add(Alert.fromApiError(errors[0]));
      }
      return totalRecords > numErrors;
    });
  }

  actionable(target: any[]): boolean {
    return target.length > 0;
  }
}
