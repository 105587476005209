<section dsDialogContent>
  <div class="ds-rows" [formGroup]="form">
    <div class="ds-notice --info" translate="UpdateLagoonLicensePackModalComponent.text"></div>
    <div class="ds-field">
      <label class="ds-label" translate="UpdateLagoonLicensePackModalComponent.user_packs"></label>
      <input type="number" min="0" max="99" name="userPackCount" class="ds-input" formControlName="userPackCount" />
      <div class="ds-text --small">
        {{ 'UpdateLagoonLicensePackModalComponent.user_packs_desc' | translate }}
      </div>
    </div>
    <div class="ds-field">
      <label class="ds-label" translate="UpdateLagoonLicensePackModalComponent.dashboard_packs"></label>
      <input
        type="number"
        min="0"
        max="99"
        name="dashboardPackCount"
        class="ds-input"
        formControlName="dashboardPackCount"
      />
      <div class="ds-text --small">
        {{ 'UpdateLagoonLicensePackModalComponent.dashboard_packs_desc' | translate }}
      </div>
    </div>
    <div class="ds-heading --small" translate="UpdateLagoonLicensePackModalComponent.quotas"></div>
    <dl class="ds-details lagoon-quota">
      <dt>
        <span translate="UpdateLagoonLicensePackModalComponent.editor_quota"></span>
      </dt>
      <dd>
        <div>
          <span class="quota">{{ 1 + userPackCount }}</span>
          <span class="quota-detail">(1 + 1 * {{ userPackCount }})</span>
        </div>
      </dd>
      <dt>
        <span translate="UpdateLagoonLicensePackModalComponent.viewer_quota"></span>
      </dt>
      <dd>
        <div>
          <span class="quota">{{ 9 + 4 * userPackCount }}</span>
          <span class="quota-detail">(9 + 4 * {{ userPackCount }})</span>
        </div>
      </dd>
      <dt>
        <span translate="UpdateLagoonLicensePackModalComponent.dashboard_quota"></span>
      </dt>
      <dd>
        <div>
          <span class="quota">{{ 5 + dashboardPackCount }}</span>
          <span class="quota-detail">(5 + 1 * {{ dashboardPackCount }})</span>
        </div>
      </dd>
      <dt>
        <span translate="UpdateLagoonLicensePackModalComponent.alert_quota"></span>
      </dt>
      <dd>
        <div>
          <span class="quota">{{ 20 + 4 * dashboardPackCount }}</span>
          <span class="quota-detail">(20 + 4 * {{ dashboardPackCount }})</span>
        </div>
      </dd>
    </dl>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm" [ngClass]="{'--loading': loading}">
    <span [innerHTML]="'UpdateLagoonLicensePackModalComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>

