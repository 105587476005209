import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { GpsMultiunitPageRouteState, GpsMultiunitPageState, GroupOrGroupParam, State } from './state';

import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';

export const selectGpsMultiunitState: MemoizedSelector<object, State> = createFeatureSelector<State>('gpsMultiunit');
export const selectGpsMultiunitStateValue: MemoizedSelector<object, GpsMultiunitPageState> = createSelector(
  selectGpsMultiunitState,
  (state: State): GpsMultiunitPageState => state.routing.status
);
export const selectGpsMultiunitSelectedSubscribers: MemoizedSelector<object, ExtendedSubscriberInterface[]> = createSelector(
  selectGpsMultiunitState,
  (state: State): ExtendedSubscriberInterface[] => state.new.selectedSubscribers.data
);
export const selectGpsMultiunitGroup: MemoizedSelector<object, GroupOrGroupParam> = createSelector(
  selectGpsMultiunitState,
  // @ts-expect-error (legacy code incremental fix)
  (state: State): GroupOrGroupParam => state.edit.group.data
);
export const selectGpsMultiunitGroupId: MemoizedSelector<object, string> = createSelector(
  selectGpsMultiunitState,
  // @ts-expect-error (legacy code incremental fix)
  (state: State): string => state.routing.groupId
);
export const selectGpsMultiunitIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectGpsMultiunitState,
  // @ts-expect-error (legacy code incremental fix)
  (state: State): boolean => state.new.selectedSubscribers.loading || state.edit.group.loading
);
export const selectGpsMultiunitIndexPageLoadingRequired: MemoizedSelector<object, boolean> = createSelector(
  selectGpsMultiunitState,
  (state: State): boolean => state.index.loadingRequired
);
export const selectGpsMultiunitError: MemoizedSelector<object, Error | string | null> = createSelector(
  selectGpsMultiunitState,
  (state: State): Error | string | null =>
    // @ts-expect-error (legacy code incremental fix)
    state.new.availableSubscribers.error || state.new.selectedSubscribers.error || state.edit.group.error
);

export const selectGpsMultiunitPageRouteState: MemoizedSelector<object, GpsMultiunitPageRouteState> = createSelector(
  selectGpsMultiunitState,
  (state: State): GpsMultiunitPageRouteState => state.routing
);
