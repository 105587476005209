import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { isEqual } from 'lodash-es';
import { LocalStorageService } from '@soracom/forks/ngx-store';
import { LagoonLicenseQuota } from '../../../../app/shared/core/lagoon_license_quota';
import { LagoonUser, LagoonUserRole } from '../../../../app/shared/core/lagoon_user';
import { LagoonService } from '../../../../app/shared/lagoon/lagoon.service';
import { LagoonSubscription } from '../../../../app/shared/lagoon/lagoon_plans';
import { LagoonUsersService } from '../../../../app/shared/lagoon/lagoon_users.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { RootStoreState } from '../../root-store';
import { selectAvailableVersions, selectSubscription } from '../../root-store/lagoon-store/selectors';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { Translation } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiMenu } from '../../soracom-ui/ui-menu/UiMenu';
import { UiMenuItem } from '../../soracom-ui/ui-menu/UiMenuItem';
import { UiMenuSection } from '../../soracom-ui/ui-menu/UiMenuSection';
import { UiPaginator } from '../../soracom-ui/ui-pagination/UiPaginator';
import { UiTableContent } from '../../soracom-ui/ui-table/ui-table-content';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';
import { AddLagoonUserModalComponent } from '../add-lagoon-user-modal/add-lagoon-user-modal.component';
import { DeleteLagoonUserModalComponent } from '../delete-lagoon-user-modal/delete-lagoon-user-modal.component';
import { UpdateLagoonUserEmailModalComponent } from '../update-lagoon-user-email-modal/update-lagoon-user-email-modal.component';
import { UpdateLagoonUserPasswordModalComponent } from '../update-lagoon-user-password-modal/update-lagoon-user-password-modal.component';
import { UpdateLagoonUserPermissionModalComponent } from '../update-lagoon-user-permission-modal/update-lagoon-user-permission-modal.component';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-lagoon-users',
  templateUrl: './lagoon-users.component.html',
})
export class LagoonUsersComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  // Application Status
  subscription$ = this.store$.pipe(select(selectSubscription));
  availableVersions$ = this.store$.pipe(select(selectAvailableVersions));

  // Component Status
  isProUser = false;
  // @ts-expect-error (legacy code incremental fix)
  viewerCount: number;
  // @ts-expect-error (legacy code incremental fix)
  editorCount: number;
  enabledBothVersions = false;
  // @ts-expect-error (legacy code incremental fix)
  quota: LagoonLicenseQuota;
  isReachedQuota = () => {
    if (this.quota) {
      return this.viewerCount >= this.quota.viewerQuota && this.editorCount >= this.quota.editorQuota;
    } else {
      return false;
    }
  };
  get translateParams() {
    const viewerCount = this.viewerCount === undefined ? '...' : this.viewerCount;
    const editorCount = this.editorCount === undefined ? '...' : this.editorCount;
    return { viewerCount, viewerQuota: this.quota?.viewerQuota, editorCount, editorQuota: this.quota?.editorQuota };
  }

  // UI stuff
  alertsManager = new AlertsManager();
  addLagoonUser = async () => {
    if (this.isReachedQuota()) {
      await this.uiDsModalService.openConfirmModal('LagoonUsersComponent.confirm.user_count_reached_quota.body', {
        title: 'LagoonUsersComponent.confirm.user_count_reached_quota.heading',
        hideCancelButton: true,
      });
      return;
    }
    this.uiDsModalService
      .openAndWaitForResult(AddLagoonUserModalComponent, {
        title: 'AddLagoonUserModalComponent.heading',
      })
      .then((result: LegacyAny) => {
        if (result) {
          this.alertsManager.add(Alert.success('LagoonUsersComponent.creation_success_message'));
          this.paginationContent.reload();
        }
      });
  };
  addLagoonUserButton = UiButton.configure((b) => {
    b.titleId = 'LagoonUsersComponent.actions.register';
    b.buttonStyle = 'primary';
    b.iconName = 'icon-plus';
    b.onClick = this.addLagoonUser;
  });

  // UiMenu
  changeEmailMenuItem = UiMenuItem.configure((i) => {
    i.title = LegacyTextContent.fromTranslation(new Translation('LagoonUsersComponent.actions.change_email'));
    i.disabled = true;
    i.identifier = 'ChangeEmail';
  });
  changePasswordMenuItem = UiMenuItem.configure((i) => {
    i.title = LegacyTextContent.fromTranslation(new Translation('LagoonUsersComponent.actions.change_password'));
    i.disabled = true;
    i.identifier = 'ChangePassword';
  });
  changePermissionMenuItem = UiMenuItem.configure((i) => {
    i.title = LegacyTextContent.fromTranslation(new Translation('LagoonUsersComponent.actions.change_permission'));
    i.disabled = true;
    i.identifier = 'ChangePermission';
  });
  deleteUserMenuItem = UiMenuItem.configure((i) => {
    i.title = LegacyTextContent.fromTranslation(new Translation('LagoonUsersComponent.actions.delete'));
    i.disabled = true;
    i.identifier = 'Delete';
  });
  onMenuItemSelected = (item: UiMenuItem): void => {
    switch (item.identifier) {
      case 'ChangeEmail': {
        this.changeEmail(this.selectedUsers);
        break;
      }
      case 'ChangePassword': {
        this.changePassword(this.selectedUsers);
        break;
      }
      case 'ChangePermission': {
        this.changePermission(this.selectedUsers);
        break;
      }
      case 'Delete': {
        this.deleteLagoonUser(this.selectedUsers);
        break;
      }
      default: {
        this.logger.error(`Unknown ActionItem: ${item.identifier}`);
        break;
      }
    }
  };
  menu = UiMenu.configure((m) => {
    const summary = UiButton.configure((b) => {
      b.titleId = 'LagoonUsersComponent.actions_menu.title';
    });
    m.summary = summary;
    m.menuContents = [
      new UiMenuSection([
        this.changePermissionMenuItem,
        this.changeEmailMenuItem,
        this.changePasswordMenuItem,
        this.deleteUserMenuItem,
      ]),
    ];
    m.onItemSelected = this.onMenuItemSelected;
  });

  // UiTable
  columns: Array<UiTableColumn<LagoonUser>> = [
    new UiTableColumn<LagoonUser>('LagoonUser', 'email', (s) => s.email, {
      tooltipId: 'LagoonUsersComponent.legends.email.description',
    }),
    new UiTableColumn<LagoonUser>(
      'LagoonUser',
      'role',
      (s) => this.translateService.instant(`LagoonUsersComponent.values.role.${s.role}`),
      {
        tooltipId: 'LagoonUsersComponent.legends.role.description',
      }
    ),
  ];
  // @ts-expect-error (legacy code incremental fix)
  creator: new () => LagoonUser;
  content: UiTableContent<LagoonUser> = new UiTableContent<LagoonUser>([], this.columns, {
    multiSelect: true,
    enableContextMenu: true,
  });
  paginationContent: UiPaginator<LagoonUser>;
  // @ts-expect-error (legacy code incremental fix)
  selectedUsers: LagoonUser[];

  constructor(
    private lagoonService: LagoonService,
    lagoonUsersService: LagoonUsersService,
    localStorageService: LocalStorageService,
    private logger: Logger,
    private store$: Store<RootStoreState.State>,
    private translateService: TranslateService,
    private uiDsModalService: UiDsModalService
  ) {
    // LagoonUsersService should not be v2
    lagoonUsersService.v2 = false;
    this.paginationContent = new UiPaginator<LagoonUser>(localStorageService, lagoonUsersService);
    this.paginationContent.onDataLoaded.subscribe((relation) => {
      this.content.setData(relation.data);
    });
  }

  ngOnInit() {
    this.availableVersions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((versions) => {
      this.enabledBothVersions = isEqual(versions, ['v2', 'v3']);
    });
    this.subscription$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (subscription) => {
      this.isProUser = subscription === LagoonSubscription.pro;
      try {
        this.quota = await this.lagoonService.getQuota();
      } catch (e) {
        this.alertsManager.add(Alert.fromApiError(e));
      }

      try {
        this.content.isLoading = true;
        await this.fetch();
      } finally {
        this.content.isLoading = false;
      }
    });
    this.content.updated.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.viewerCount = this.content.tableData.data.filter((u) => u.role === LagoonUserRole.viewer).length;
      this.editorCount = this.content.tableData.data.filter((u) => u.role === LagoonUserRole.editor).length;
    });
  }

  changeEmail(selectedUsers: LagoonUser[]) {
    this.uiDsModalService
      .openAndWaitForResult(UpdateLagoonUserEmailModalComponent, {
        title: 'UpdateLagoonUserEmailModalComponent.heading',
        data: selectedUsers[0],
      })
      .then((result: LegacyAny) => {
        if (result?.success) {
          this.alertsManager.add(Alert.success('LagoonUsersComponent.update_email_success_message'));
          this.paginationContent.reload();
        }
      });
  }

  changePassword(selectedUsers: LagoonUser[]) {
    this.uiDsModalService
      .openAndWaitForResult(UpdateLagoonUserPasswordModalComponent, {
        title: 'UpdateLagoonUserPasswordModalComponent.heading',
        data: selectedUsers[0],
      })
      .then((result: LegacyAny) => {
        if (result?.success) {
          this.alertsManager.add(Alert.success('LagoonUsersComponent.update_password_success_message'));
          this.paginationContent.reload();
        }
      });
  }

  changePermission(selectedUsers: LagoonUser[]) {
    this.uiDsModalService
      .openAndWaitForResult(UpdateLagoonUserPermissionModalComponent, {
        title: 'UpdateLagoonUserPermissionModalComponent.heading',
        data: selectedUsers[0],
      })
      .then((result: LegacyAny) => {
        if (result?.success) {
          this.alertsManager.add(Alert.success('LagoonUsersComponent.update_permission_success_message'));
          this.paginationContent.reload();
        }
      });
  }

  deleteLagoonUser(selectedUsers: LagoonUser[]) {
    this.uiDsModalService
      .openAndWaitForResult(DeleteLagoonUserModalComponent, {
        title: 'DeleteLagoonUserModalComponent.heading',
        data: selectedUsers,
      })
      .then((result: LegacyAny) => {
        if (result?.success) {
          this.alertsManager.add(Alert.success('LagoonUsersComponent.deletion_success_message'));
          this.paginationContent.reload();
        }
      });
  }

  onTableItemSelectionChanged(items: LagoonUser[]): void {
    this.selectedUsers = items;
    const menuItemDisabled = items.length !== 1;
    this.changeEmailMenuItem.disabled = menuItemDisabled;
    this.changePasswordMenuItem.disabled = menuItemDisabled;
    this.changePermissionMenuItem.disabled = menuItemDisabled;
    const menuItemDisabledForDeleteUser = items.length === 0;
    this.deleteUserMenuItem.disabled = menuItemDisabledForDeleteUser;
  }

  private fetch() {
    this.content.isReloading = true;
    return this.paginationContent
      .reload()
      .catch((e: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(e));
      })
      .finally(() => {
        this.content.isReloading = false;
      });
  }
}
