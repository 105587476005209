import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { PaginationOptions, ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  SoracomApiParams,
  TaggableService,
  TagParams,
} from '@user-console/legacy-soracom-api-client';
import { GroupableService } from '@soracom/shared/soracom-platform';
import { Device } from '../core/device';
import { InjectList } from '../core/injectable';

import { PaginationService, SearchQuery } from '@user-console/legacy-soracom-api-client';

export class DevicesService implements TaggableService, GroupableService, PaginatableService<Device> {
  static $inject: InjectList = ['$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'devices';

  constructor(
    private $q: ng.IQService,
    private SoracomApi: LegacyBaseSoracomApiService,
    private PaginationService: PaginationService,
  ) {}

  list(paginationOptions: PaginationOptions, searchQuery: SearchQuery): Promise<ScRelation<Device>> {
    const hasSearchQuery = !!searchQuery;

    const apiParams = {
      method: 'get',
      path: hasSearchQuery ? '/v1/query/devices' : '/v1/devices',
      query: { ...paginationOptions },
      searchQuery,
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const devices: LegacyAny = [];
      response.data.forEach((element: LegacyAny) => {
        devices.push(new Device(element));
      });

      const relation: ScRelation<Device> = {
        data: devices,
        links: this.PaginationService.getPaginationLinks(response.headers.link),
      };

      return this.$q.resolve(relation);
    });
  }

  create(groupId: string, tags: { [tagName: string]: string }) {
    const apiParams = this.getPostParams({
      path: '/v1/devices',
      query: {},
      body: { groupId, tags },
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  get(deviceId: string): Promise<Device> {
    const apiParams = {
      method: 'get',
      path: '/v1/devices/' + deviceId,
      query: { model: 'true' },
    };
    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return new Device(response.data);
    });
  }

  setGroup(deviceId: string, groupId: string) {
    const apiParams = this.getPostParams({
      path: `/v1/devices/${deviceId}/set_group`,
      body: { groupId },
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  unsetGroup(deviceId: string) {
    const apiParams = this.getPostParams({
      path: `/v1/devices/${deviceId}/unset_group`,
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  updateTags(deviceId: string, tags: TagParams[]) {
    const apiParams = {
      method: 'put',
      path: `/v1/devices/${deviceId}/tags`,
      query: { model: 'true' },
      contentType: 'application/json',
      body: tags,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  deleteTag(deviceId: string, tagName: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/devices/${deviceId}/tags/${tagName}`,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  delete(deviceId: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/devices/${deviceId}`,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  getInstance(deviceId: string, object: LegacyAny, instance: LegacyAny) {
    const apiParams = {
      method: 'get',
      path: ['/v1/devices', deviceId, object, instance].join('/'),
      query: { model: 'true' },
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  getResource(deviceId: string, object: LegacyAny, instance: LegacyAny, resource: LegacyAny) {
    const apiParams = {
      method: 'get',
      path: ['/v1/devices', deviceId, object, instance, resource].join('/'),
      query: { model: 'true' },
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  putResource(deviceId: string, object: LegacyAny, instance: LegacyAny, resource: LegacyAny, value: LegacyAny) {
    const apiParams = {
      method: 'put',
      path: ['/v1/devices', deviceId, object, instance, resource].join('/'),
      query: { model: 'true' },
      contentType: 'application/json',
      body: { value },
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  executeResource(deviceId: string, object: LegacyAny, instance: LegacyAny, resource: LegacyAny, body: LegacyAny) {
    const apiParams = this.getPostParams({
      path: ['/v1/devices', deviceId, object, instance, resource, 'execute'].join('/'),
      body: body || {},
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  observeResource(deviceId: string, object: LegacyAny, instance: LegacyAny, resource: LegacyAny) {
    const apiParams = this.getPostParams({
      path: ['/v1/devices', deviceId, object, instance, resource, 'observe'].join('/'),
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  unobserveResource(deviceId: string, object: LegacyAny, instance: LegacyAny, resource: LegacyAny) {
    const apiParams = this.getPostParams({
      path: ['/v1/devices', deviceId, object, instance, resource, 'unobserve'].join('/'),
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  observeInstance(deviceId: string, object: LegacyAny, instance: LegacyAny) {
    const apiParams = this.getPostParams({
      path: ['/v1/devices', deviceId, object, instance, 'observe'].join('/'),
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  unobserveInstance(deviceId: string, object: LegacyAny, instance: LegacyAny) {
    const apiParams = this.getPostParams({
      path: ['/v1/devices', deviceId, object, instance, 'unobserve'].join('/'),
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  activate(deviceId: string) {
    const apiParams = this.getPostParams({
      path: `/v1/devices/${deviceId}/activate`,
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  deactivate(deviceId: string) {
    const apiParams = this.getPostParams({
      path: `/v1/devices/${deviceId}/deactivate`,
    });
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  /// Keys

  listKeys(deviceId: string) {
    const apiParams = {
      method: 'get',
      path: `/v1/devices/${deviceId}/keys`,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  createKey(deviceId: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/devices/${deviceId}/keys`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  deleteKey(deviceId: string, keyId: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/devices/${deviceId}/keys/${keyId}`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  activateKey(deviceId: string, keyId: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/devices/${deviceId}/keys/${keyId}/activate`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  deactivateKey(deviceId: string, keyId: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/devices/${deviceId}/keys/${keyId}/deactivate`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  private defaultPostParams: SoracomApiParams = {
    method: 'post',
    path: '',
    query: { model: 'true' },
    contentType: 'application/json',
    body: {},
  };

  private getPostParams(options: SoracomApiParams) {
    return angular.merge({}, this.defaultPostParams, options);
  }
}
