export const template = `<app-user-notifications></app-user-notifications>
<main class="ds-app__main-container" translate-namespace="devices">
  <div>
    <div class="modal-parent"></div>
  </div>
  <div class="alerts-wrapper">
    <sc-alerts service="$ctrl.alertsService"></sc-alerts>
  </div>
  
  <section class="ds-controls">
    <div class="ds-bar">
      <button
        class="ds-button --primary --icon--plus"
        id="devices-action-add-devices"
        ng-disabled='!$ctrl.actionable("AddDevice")'
        ng-click='$ctrl.doAction("AddDevice")'
      >
        <span
          translate="devices.actions.add_device"
        ></span></button
      ><button
        class="ds-button"
        id="devices-action-show-devices"
        ng-disabled='!$ctrl.actionable("ShowDevice")'
        ng-click='$ctrl.doAction("ShowDevice")'
      >
        <span
          translate="devices.actions.show_device"
        ></span>
      </button>
      <details class="ds-menubutton">
        <summary>
          <span class="ds-button" id="devices-actions-menu">
            <span
              translate="devices.actions_menu.title"
            ></span>
            <i class="ds-icon --icon-ui-arrow-down"></i>
          </span>
        </summary>
        <div class="__content" role="menu">
          <ul id="devices-actions-dropdown-menu">
            <li
              ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Activate')"
              id="devices-actions-menu-activate"
            >
              <button
                ng-click='$ctrl.doAction("Activate")'
                ng-disabled="!$ctrl.actionable('Activate')"
                >{{ 'devices.actions.activate' | translate }}</button
              >
            </li>
            <li
              ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Deactivate')"
              id="devices-actions-menu-deactivate"
            >
              <button
                ng-click='$ctrl.doAction("Deactivate")'
                ng-disabled="!$ctrl.actionable('Deactivate')"
                >{{ 'devices.actions.deactivate' | translate }}</button
              >
            </li>
          </ul>
          <ul>
            <li
              ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeGroup')"
              id="devices-actions-menu-change-group"
            >
              <button
                ng-click='$ctrl.doAction("ChangeGroup")'
                ng-disabled="!$ctrl.actionable('ChangeGroup')"
                >{{ 'devices.actions.change_group' | translate }}</button
              >
            </li>
            <li
              ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('EditTag')"
              id="devices-actions-menu-edit-tag"
            >
              <button
                ng-click='$ctrl.doAction("EditTag")'
                ng-disabled="!$ctrl.actionable('EditTag')"
                >{{ 'devices.actions.edit_tag' | translate }}</button
              >
            </li>
          </ul>
          <ul>
            <li
              ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Logs')"
              id="devices-actions-menu-logs"
            >
              <button
                ng-click='$ctrl.doAction("Logs")'
                ng-disabled="!$ctrl.actionable('Logs')"
                >{{ 'devices.actions.logs' | translate }}</button
              >
            </li>
            <li
              ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Data')"
              id="devices-actions-menu-data"
            >
              <button
                ng-click='$ctrl.doAction("Data")'
                ng-disabled="!$ctrl.actionable('Data')"
                >{{ 'devices.actions.data' | translate }}</button
              >
            </li>
          </ul>
          <ul>
            <li
              ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Delete')"
              id="devices-actions-menu-delete"
            >
              <button
                ng-click='$ctrl.doAction("Delete")'
                ng-disabled="!$ctrl.actionable('Delete')"
                >{{ 'devices.actions.delete' | translate }}</button
              >
            </li>
          </ul>
        </div>
      </details>
      <button
        class="ds-button"
        id="table-settings-button"
        data-testid="ng1-table-settings-button"
        ng-click="$ctrl.openTableSettingsModal()"
      >
        <i class="ds-icon --icon-settings"></i>
      </button>
    </div>
    <sc-pagination paginator="$ctrl.paginator" ctrl="$ctrl"></sc-pagination>
  </section>
    
  <div class="ds-rows --indent-small">
    <ui-search
      [context]="$ctrl.searchContext"
      [parent-load-state]="$ctrl.loadState"
      (changed)="$ctrl.onDeviceSearchQueryChanged($event)"
    ></ui-search>
  </div>

  <section class="ds-app__main-content">
    <table
      class="ds-datatable --striped --pin-first x-devices-table"
      id="devices-table"
    >
      <thead>
        <tr>
          <th
            id="devices-th-checkbox"
            class="--min"
            ng-if='$ctrl.shouldShowColumn("checkbox") &amp;&amp; $ctrl.selectable()'
          >
            <div class="ds-checkbox --hide-label --top-right">
              <input
                type="checkbox"
                id="devices-checkbox-for-all"
                ng-model="$ctrl.checkboxForAll"
                ng-change="$ctrl.checkboxForAllChanged($ctrl.checkboxForAll);"
              />
              <span>Check All</span>
            </div>
          </th>
          <th id="devices-th-id" ng-if='$ctrl.shouldShowColumn("id")'>
            {{ 'devices.columns.id' | translate }}
          </th>
          <th id="devices-th-name" ng-if='$ctrl.shouldShowColumn("name")'>
            {{ 'devices.columns.name' | translate }}
          </th>
          <th id="devices-th-group" ng-if='$ctrl.shouldShowColumn("group")'>
            {{ 'devices.columns.group' | translate }}
          </th>
          <th
            id="devices-th-status"
            ng-if='$ctrl.shouldShowColumn("status")'
          >
            {{ 'devices.columns.status' | translate }}
          </th>
          <th
            id="devices-th-endpoint"
            ng-if='$ctrl.shouldShowColumn("endpoint")'
          >
            {{ 'devices.columns.endpoint' | translate }}
          </th>
          <th id="devices-th-imsi" ng-if='$ctrl.shouldShowColumn("imsi")'>
            <span>{{ 'devices.columns.imsi' | translate }}</span>
            <div class="ds-richtip">
              <i class="__button ds-icon --xxsmall --icon-info"></i>
              <div class="__content" ng-bind-html="'devices.legends.imsi.description' | translate"></div>
            </div>
          </th>
          <th id="devices-th-imei" ng-if='$ctrl.shouldShowColumn("imei")'>
            <span>{{ 'devices.columns.imei' | translate }}</span>
            <div class="ds-richtip">
              <i class="__button ds-icon --xxsmall --icon-info"></i>
              <div class="__content" ng-bind-html="'devices.legends.imei.description' | translate"></div>
            </div>
          </th>
          <th
            id="devices-th-manufacturer"
            ng-if='$ctrl.shouldShowColumn("manufacturer")'
          >
            {{ 'devices.columns.manufacturer' | translate }}
          </th>
          <th
            id="devices-th-model-number"
            ng-if='$ctrl.shouldShowColumn("model_number")'
          >
            {{ 'devices.columns.model_number' | translate }}
          </th>
          <th
            id="devices-th-serial-number"
            ng-if='$ctrl.shouldShowColumn("serial_number")'
          >
            {{ 'devices.columns.serial_number' | translate
            }}
          </th>
        </tr>
      </thead>
      <tbody
        class="x-devices-list"
        ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()"
      >
        <tr
          ng-repeat="row in $ctrl.tableData.rows"
          ng-click="$ctrl.setSelected(row)"
          sc-context-menu="devices-context-menu"
        >
          <td
            ng-if='$ctrl.shouldShowColumn("checkbox") &amp;&amp; $ctrl.selectable()'
          >
            <div class="ds-checkbox --hide-label --top-right">
              <input
                class="checkbox-for-each"
                id="cb{{row.obj.id}}"
                type="checkbox"
                ng-model="row.selected"
                ng-change="$ctrl.checkboxForEachChanged()"
                ng-click="$event.stopPropagation()"
              />
              <span>Check {{ row.obj.id }}</span>
            </div>
          </td>
          <td
            class="devices-id --no-wrap --code"
            ng-if='$ctrl.shouldShowColumn("id")'
            ng-bind="row.obj.id"
          ></td>
          <td
            class="devices-name"
            ng-if='$ctrl.shouldShowColumn("name")'
          >
            <sc-inplace-editable-text
              ng-if="$ctrl.editable"
              ng-mouseover="row.hoverName = true"
              ng-mouseleave="row.hoverName = false"
              key="name"
              cancel-on-blur="true"
              display-value="row.obj.name"
              edit-ctrl="row.nameEditCtrl"
              padding-left='"0"'
              show-pencil-icon="true"
              show-remove-button="false"
              show-save-button="false"
              show-cancel-button="false"
            ></sc-inplace-editable-text
            ><span ng-if="!$ctrl.editable" ng-bind="row.obj.name"></span>
          </td>
          <td
            class="devices-group feedback --no-wrap"
            ng-if='$ctrl.shouldShowColumn("group")'
            sc-update-feedback="sc-update-feedback"
            feedback-classes='{"success": "feedback-success", "failure": "feedback-failure"}'
            feedback-type="row.feedback.updateGroup"
            feedback-duration="3000"
          >            
            <a class="ds-text --link" ng-href="/inventory_device_groups/{{ row.obj.groupId }}"
              >{{ row.obj.groupName() || '—'}}</a
            >
          </td>
          <td
            class="devices-status feedback --no-wrap"
            ng-if='$ctrl.shouldShowColumn("status")'
            sc-update-feedback="sc-update-feedback"
            feedback-classes='{"success": "feedback-success", "failure": "feedback-failure"}'
            feedback-type="row.feedback.updateStatus"
            feedback-duration="3000"
          >
            <sc-device-status-label obj="row.obj"></sc-device-status-label
            ><sc-session-status-label
              is-online="row.obj.online"
              disabled="row.obj.status !== 'active'"
            ></sc-session-status-label>
          </td>
          <td
            class="devices-endpoint"
            ng-if='$ctrl.shouldShowColumn("endpoint")'
            ng-bind="row.obj.endpoint ? row.obj.endpoint : '—'"
          ></td>
          <td
            class="devices-imsi --code"
            ng-if='$ctrl.shouldShowColumn("imsi")'
            ng-bind="row.obj.imsi ? row.obj.imsi : '—'"
          ></td>
          <td
            class="devices-imei --code"
            ng-if='$ctrl.shouldShowColumn("imei")'
            ng-bind="row.obj.imei ? row.obj.imei : '—'"
          ></td>
          <td
            class="devices-manufacturer"
            ng-if='$ctrl.shouldShowColumn("manufacturer")'
            ng-bind="row.obj.manufacturer ? row.obj.manufacturer : '—'"
          ></td>
          <td
            class="devices-model-number"
            ng-if='$ctrl.shouldShowColumn("model_number")'
            ng-bind="row.obj.modelNumber ? row.obj.modelNumber : '—'"
          ></td>
          <td
            class="devices-serial-number --code"
            ng-if='$ctrl.shouldShowColumn("serial_number")'
            ng-bind="row.obj.serialNumber ? row.obj.serialNumber : '—'"
          ></td>
        </tr>
      </tbody>
      <tbody
        class="x-no-devices-exist"
        ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()"
      >
        <tr>
          <td colspan="999">
            {{ 'common.table.no_data' | translate }}
          </td>
        </tr>
      </tbody>
      <tbody
        class="x-is-loading"
        ng-if="$ctrl.isLoading()"
      >
        <tr>
          <td colspan="999">
            {{ 'common.table.loading' | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
  <sc-tutorial-component></sc-tutorial-component>
</main>`;
