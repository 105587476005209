<div class="ds-cols--force">
  <div *ngIf="!isDisabled" class="ds-button-bar">
    <ui-text-content [content]="itemsPerPage"></ui-text-content>

    <ui-select [content]="selectPerPageCount"></ui-select>

    <ui-text-content [content]="itemXofY"></ui-text-content>

    <ng-container *ngIf="countSelected > 0">
      <ui-text-content [content]="selectionCountHint"></ui-text-content>
    </ng-container>
  </div>

  <div class="ds-button-bar">
    <!-- NO column options for v1
        <button class="ds-button--plain ds-button--hide-label ds-button--icon-settings">
        <span>Settings</span>
      </button>
    -->

    <button
      (click)="refresh.emit()"
      class="ds-button--plain ds-button--hide-label ds-button--icon-refresh-cw"
      [disabled]="isDisabled"
    >
      <span>{{ 'SoracomLogs.button.refresh' | translate }}</span>
    </button>

    <button
      (click)="previousPage.emit()"
      class="ds-button--plain ds-button--hide-label ds-button--icon-arrow-left"
      [disabled]="!hasPreviousPage || isDisabled"
    >
      <span>{{ 'SoracomLogs.button.previous' | translate }}</span>
    </button>

    <button
      (click)="nextPage.emit()"
      class="ds-button--plain ds-button--hide-label ds-button--icon-arrow-right"
      [disabled]="!hasNextPage || isDisabled"
    >
      <span>{{ 'SoracomLogs.button.next' | translate }}</span>
    </button>
  </div>
</div>
