import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import {
  getMaaRoutePathFromPath,
  localPortForApp,
  resolveRouteDefinition,
} from '@soracom/shared/routing';

/**
 This route guard is intended for the legacy user-console, when working in multi-app environment. Guard the "page not found" route with this guard, to redirect to the correct app if the route being guarded is actually a multi-app route we have defined.

 It is used to redirect to the correct app when a route is not found in the legacy user-console. This is probably temporary — one of the next steps will be deriving ALL routes, including those of the legacy user-console app, from the RouteDefinition list.

 But that is not done yet, so for now it works like "user console cannot find the route, but before it shows the usual 404 page, it checks to see if the route is defined as a multi-app route, and if so, redirects the **browser** (not the Angular router in the AngularJS hybrid app) to the correct URL".

 This route guard lets it do that.
 */
export const multiAppLegacyAppPre404RedirectGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const path = state.url.split('?')[0];
  debugger;

  const maaRoutePath = getMaaRoutePathFromPath(path.substring(1));

  if (!maaRoutePath) {
    return true; // allow the route to proceed, presumably it is 404 page
  }

  // If we get here, then we know we have an MAA route path and we need to go to the MAA app in question.

  const maaRoute = resolveRouteDefinition(maaRoutePath);

  // If we are in a deployed multi-app web app, whether production or branch deploy, or whatever, all we need to do is redirect the **browser** and not the app — the multi-app CloudFront Voodoo™ will make it Just Work™ and load the right app. But, if we are running locally, then we need to redirect to the correct app including the local port number.

  const currentUrl = new URL(window.location.href);
  const url = new URL(state.url, currentUrl);

  if (
    (url.hostname === 'localhost' || url.hostname === '127.0.0.1') &&
    url.port
  ) {
    const maaAppIdentifier = maaRoute.host;
    const newPort = localPortForApp(maaAppIdentifier);

    url.port = newPort;
  }

  // @masonmark 2024-07-05: This change to using location.replace() on the *redirecting* side seems to solve the Angular router "Back button needs to be pressed twice" [isssue](https://app.shortcut.com/soracom/story/117410/back-button-doesn-t-work-needs-to-be-pressed-twice-in-settings-app)
    // The initial implementation was the below, but that is what led to the Back button issue: 
    // window.location.href = url.toString();
  window.location.replace(url.toString());

  return true;
};
