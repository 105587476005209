import { LegacyAny } from '@soracom/shared/core';

import { PaginationService } from '@user-console/legacy-soracom-api-client';
import { ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  SoracomApiParams,
} from '@user-console/legacy-soracom-api-client';
import { DeviceObjectModel } from '../core/device_object_model';
import { InjectList } from '../core/injectable';

export class DeviceObjectModelsService implements PaginatableService<DeviceObjectModel> {
  static $inject: InjectList = ['$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'device_object_models';

  constructor(
    private $q: ng.IQService,
    private SoracomApi: LegacyBaseSoracomApiService,
    private PaginationService: PaginationService,
  ) {
    // no-op
  }

  list(queryOptions: LegacyAny): Promise<ScRelation<DeviceObjectModel>> {
    let apiParams: SoracomApiParams;
    if (queryOptions.url) {
      apiParams = {
        method: 'get',
        path: queryOptions.url,
      };
    } else {
      apiParams = {
        method: 'get',
        path: `/v1/${this.resourcePath}`,
        query: {
          limit: queryOptions.limit,
          last_evaluated_key: queryOptions.last_evaluated_key,
        },
      };
    }

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const devices: LegacyAny = [];
      response.data.forEach((element: LegacyAny) => {
        devices.push(new DeviceObjectModel(element));
      });

      const relation: ScRelation<DeviceObjectModel> = {
        data: devices,
        links: this.PaginationService.getPaginationLinks(response.headers.link),
      };

      return relation;
    });
  }

  get(id: string) {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}/${id}`,
      contentType: 'application/json',
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const resource = new DeviceObjectModel(response.data);
      return this.$q.resolve(resource);
    });
  }

  getSchema(id: string, format: 'text' | 'json' = 'json') {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}/${id}`,
      contentType: 'application/json',
      responseType: format,
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return this.$q.resolve(response.data);
    });
  }

  create(obj: DeviceObjectModel, schema: string) {
    let contentType: string;
    if (obj.format === 'json') {
      contentType = 'application/json';
    } else if (obj.format === 'xml') {
      contentType = 'application/xml';
    } else {
      return this.$q.reject(`Invalid format supplied. ${obj.format}`);
    }

    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}`,
      contentType,
      body: schema,
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const resource = new DeviceObjectModel(response.data);
      return this.$q.resolve(resource);
    });
  }

  update(obj: DeviceObjectModel, schema: string) {
    let contentType: string;
    if (obj.format === 'json') {
      contentType = 'application/json';
    } else if (obj.format === 'xml') {
      contentType = 'application/xml';
    } else {
      return this.$q.reject(`Invalid format supplied. ${obj.format}`);
    }

    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${obj.id}`,
      contentType,
      body: schema,
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const resource = new DeviceObjectModel(response.data);
      return this.$q.resolve(resource);
    });
  }

  delete(id: string) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: `/v1/${this.resourcePath}/${id}`,
    };

    return this.SoracomApi.callApiWithToken(apiParams);
  }

  setScope(id: string, scopeId: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/lora_devices/${id}/set_scope`,
      body: { scopeId },
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  unsetScope(id: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/lora_devices/${id}/unset_scope`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }
}
