import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-gps-multiunit-scenario-form',
  templateUrl: './gps-multiunit-scenario-form.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class GpsMultiunitScenarioFormComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() fgName: string;
  // @ts-expect-error (legacy code incremental fix)
  @Input() i18nPrefix: string;

  get formGroup(): FormGroup {
    return this.parentF.form.get(this.fgName) as FormGroup;
  }

  constructor(private parentF: FormGroupDirective) {}

  ngOnInit(): void {}

  hasDateError() {
    return this.dateErrors.length > 0;
  }

  get dateErrors() {
    if (this.formGroup.invalid) {
      // WARN: This code is not smart to split errors about Date and Time
      return Object.keys(this.formGroup.errors || {}).filter((e) => /Date/.test(e));
    } else {
      return [];
    }
  }

  hasInvalidTimeRangeError() {
    if (this.formGroup.invalid) {
      const errors = this.formGroup.errors;
      return errors && errors.invalidTimeRange;
    }
  }
}
