export type GMBoolean = 'ON' | 'OFF';
export enum GMMode {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
  NONE = 'NONE',
}
export type GMNull = 'null';
export enum GMWeekday {
  SUN = 'SUN',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
}

export interface GpsMultiunitConfigData {
  SensorData?: {
    acc?: { i1: GMBoolean };
    loc?: { i1: GMBoolean };
    tem?: { i1: GMBoolean };
    hum?: { i1: GMBoolean };
    bat?: { i1: GMBoolean };
  };
  Setting?: {
    itr: { i2: number };
  };
  Common: GMCommonAttributes;
}

export interface GMCommonAttributes {
  C1: string[];
  C2: string[];
  C3: string[];
  C4: string[];
  C5: number[];
  C6: Array<string[] | GMNull>;
  C7: string; // CSV of available scenarios
}

export const DefaultGpsMultiunitConfigData: GpsMultiunitConfigData = {
  SensorData: {
    acc: {
      i1: 'OFF',
    },
    loc: {
      i1: 'ON',
    },
    tem: {
      i1: 'ON',
    },
    hum: {
      i1: 'ON',
    },
    bat: {
      i1: 'ON',
    },
  },
  Setting: {
    itr: {
      i2: 2048,
    },
  },
  Common: {
    C1: ['null', 'null', 'null', 'null', 'null', '1981/04/01', '1981/04/01', '1981/04/01', 'null', 'null'],
    C2: ['null', 'null', 'null', 'null', 'null', '2059/12/31', '2059/12/31', '2059/12/31', 'null', 'null'],
    C3: ['null', 'null', 'null', 'null', 'null', '00:00', '02:00', '00:00', 'null', 'null'],
    C4: ['null', 'null', 'null', 'null', 'null', '23:59', '03:00', '23:59', 'null', 'null'],
    C5: [-20000, -20000, -20000, -20000, -20000, 10, 1440, 2160, -20000, -20000],
    C6: [
      'null',
      'null',
      'null',
      'null',
      'null',
      ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      'null',
      'null',
    ],
    C7: '5,6',
  },
};
