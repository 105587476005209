import { LegacyAny } from '@soracom/shared/core';

import {
  HarvestDataUrlSearchParam,
  HARVEST_DATA_URL_SEARCH_KEYS,
} from '../../../src/app/harvest-data/harvest-data-route-param';
import { ApiEvent } from '../core/api_event';
import { InjectList } from '../core/injectable';
import { template } from './harvest_data.component.html';
import { getCoreService } from '@soracom/shared/data-access-auth';

export interface HarvestResourceSearchQuery {
  resource_type: string;
  resource_id: string;
}

export class HarvestDataComponent implements ng.IComponentOptions {
  controller = HarvestDataController;
  template: any = template;
}

export class HarvestDataController {
  static $inject: InjectList = ['$location', '$rootScope', '$routeParams'];

  constructor(
    private $location: ng.ILocationService,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: LegacyAny,
  ) {}
  // @ts-expect-error (legacy code incremental fix)
  routeParams: HarvestDataUrlSearchParam;

  onRouteParamsChange(params: HarvestDataUrlSearchParam) {
    HARVEST_DATA_URL_SEARCH_KEYS.forEach((key) => {
      this.$location.search(key, params[key] ?? null);
    });
    this.$rootScope.$applyAsync();
  }

  $onInit() {
    this.routeParams = this.$routeParams;
    this.$rootScope.$on(ApiEvent.CoverageTypeChanged.toString(), (event, coverageType) => {
      this.routeParams = {};
      this.$rootScope.$applyAsync();
    });
    getCoreService().AuthService.authEvent$.subscribe((authEvent) => {
      if (authEvent.type === 'token') {
        this.routeParams = { ...this.routeParams };
        this.$rootScope.$applyAsync();
      }
    });
  }
}
