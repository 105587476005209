import { LegacyAny } from '@soracom/shared/core';

import {
  PaginationService,
  SearchQuery,
  LegacyBaseSoracomApiService,
  PaginatableService,
  SoracomApiParams,
} from '@user-console/legacy-soracom-api-client';

import { PaginationOptions, ScRelation } from '../components/paginator';
import { InjectList } from '../core/injectable';
import { NapterLog } from '@soracom/shared/soracom-platform';

// Mason 2021-03-31: Ethan here is the log service to fetch those "Napter Audit Logs" — I think you may want to inject this and use it as-is since we have no Angular equivalent

export class NapterAuditLogsService implements PaginatableService<NapterLog> {
  static $inject: InjectList = ['$log', 'PaginationService', 'BaseSoracomApiService'];

  resourcePath = 'audit_logs';

  constructor(
    private $log: LegacyAny,
    private paginationService: PaginationService,
    private soracomApi: LegacyBaseSoracomApiService,
  ) {}

  list(paginationOptions: PaginationOptions, searchQuery?: SearchQuery): Promise<ScRelation<NapterLog>> {
    const apiParams: SoracomApiParams = {
      path: `/v1/audit_logs/napter`,
      query: { ...paginationOptions },
      searchQuery,
    };
    this.$log.debug(apiParams);
    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      const data = res.data.map((d: LegacyAny) => new NapterLog(d));
      const links = this.paginationService.getPaginationLinks(res.headers.link);
      return { data, links };
    });
  }
}
