import { LegacyAny } from '@soracom/shared/core';

import { Resource } from '@soracom/shared/soracom-platform';
import { BaseModel } from '@soracom/shared/soracom-platform';

export class DeviceObjectModel extends BaseModel implements Resource {
  static resourcePath = 'device_object_models';

  cretedTime;
  format;
  lastModifiedTime;
  modelId;
  objectId;
  objectName;
  operatorId;
  scope;

  constructor(params?: LegacyAny) {
    super();
    if (params) {
      this.cretedTime = params.cretedTime;
      this.format = params.format;
      this.lastModifiedTime = params.lastModifiedTime;
      this.modelId = params.modelId;
      this.objectId = params.objectId;
      this.objectName = params.objectName;
      this.operatorId = params.operatorId;
      this.scope = params.scope;
    }
  }

  get id(): string {
    return this.modelId;
  }
}
