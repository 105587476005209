export interface MapMarkerData {
  lat: number;
  long: number;
}

// @ts-expect-error (legacy code incremental fix)
export const validateLatitude = (lat?: number) => Number.isFinite(lat) && lat >= -90 && lat <= 90;

// @ts-expect-error (legacy code incremental fix)
export const validateLongitude = (lon?: number) => Number.isFinite(lon) && lon >= -180 && lon <= 180;
