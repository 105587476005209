import { Resource } from '@soracom/shared/soracom-platform';
import { TableController } from '../../components/base_table_controller';
import { Action } from './Action';

export class ShowResourceAction<T extends Resource> implements Action<T> {
  constructor(private ctrl: TableController<T>, private $location: ng.ILocationService, private path: string) {}
  run(target: T[]) {
    const resource = target[0];
    this.$location.path(this.path + resource.id);
  }
  actionable(target: T[]) {
    return target.length === 1;
  }
}
