import { LegacyAny } from '@soracom/shared/core';

import { Component, Input, OnInit } from '@angular/core';
import { isEqual } from 'lodash-es';
import { CoverageTypeService, getLoginUserData } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { SoracomUserConsole } from '../../shared/SoracomUserConsole';

const expectedPermission = {
  statements: [
    {
      effect: 'allow',
      api: [
        'Auth:auth',
        'Auth:logout',
        'DataEntry:listDataSourceResources',
        'DataEntry:getDataEntries',
        'FileEntry:getFile',
        'FileEntry:getFileMetadata',
        'FileEntry:putFile',
        'FileEntry:listFiles',
      ],
    },
    {
      effect: 'deny',
      api: [
        'AuditLog:*',
        'Billing:*',
        'CellLocation:*',
        'Payment:*',
        'Order:*',
        'Credentials:*',
        'DataEntry:deleteDataEntry',
        'DataEntry:getDataEntry',
        'DeviceObjectModel:*',
        'Device:*',
        'Diagnostic:*',
        'EventHandler:*',
        'FileEntry:deleteFile',
        'FileEntry:deleteDirectory',
        'Files:*',
        'Gadget:*',
        'Group:*',
        'Lagoon:*',
        'Log:*',
        'LoraDevice:*',
        'LoraGateway:*',
        'LoraNetworkSet:*',
        'Operator:*',
        'Email:*',
        'Role:*',
        'ShippingAddress:*',
        'SystemNotification:*',
        'User:*',
        'PortMapping:*',
        'Query:*',
        'SigfoxDevice:*',
        'Sim:*',
        'Soralet:*',
        'Stats:*',
        'Subscriber:*',
        'VirtualPrivateGateway:*',
      ],
    },
  ],
};

@Component({
  selector: 'app-harvest-files-migration',
  templateUrl: './harvest-files-migration.component.html',
})
export class HarvestFilesMigrationComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() alertsManager: AlertsManager;
  // @ts-expect-error (legacy code incremental fix)
  migrated: boolean;
  // @ts-expect-error (legacy code incremental fix)
  private operatorId: string;
  // @ts-expect-error (legacy code incremental fix)
  private userName: string;
  private logger = SoracomUserConsole.shared.logger;

  constructor(private coverageTypeService: CoverageTypeService, private soracomApiService: SoracomApiService) {}

  async ngOnInit(): Promise<void> {
    this.operatorId = getLoginUserData().operatorId;

    if (this.coverageTypeService.isGlobal()) {
      this.userName = `SORACOM-Lagoon-User-${this.operatorId}-g`;
    } else {
      this.userName = `SORACOM-Lagoon-User-${this.operatorId}`;
    }
    this.migrated = await this.isPermissionMigrated();
  }

  doMigration() {
    this.soracomApiService
      .setUserPermissions(this.operatorId, this.userName, {
        permission: JSON.stringify(expectedPermission),
      })
      .then((result: LegacyAny) => {
        this.alertsManager.add(Alert.success('HarvestFilesMigration.migration_succeeded'));
        return this.isPermissionMigrated();
      })
      .then((migrated: LegacyAny) => {
        this.migrated = migrated;
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
      });
  }

  isPermissionMigrated(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.soracomApiService
        .getUserPermissions(this.operatorId, this.userName)
        .then((result: LegacyAny) => {
          this.logger.debug(result.data);
          if (result.data) {
            try {
              resolve(isEqual(expectedPermission, JSON.parse(result.data.permission)));
            } catch (e) {
              reject(e);
            }
          } else {
            resolve(false);
          }
        })
        .catch((error: LegacyAny) => {
          reject(error);
        });
    });
  }
}
