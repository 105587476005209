import { LegacyAny } from '@soracom/shared/core';

import { Uppy, UppyOptions } from '@uppy/core';
import DragDrop from '@uppy/drag-drop';
// @ts-expect-error (legacy code incremental fix) NOTE: due to lack of types
import Japanese from '@uppy/locales/lib/ja_JP';
import StatusBar from '@uppy/status-bar';
import XHRUpload from '@uppy/xhr-upload';

import { template } from './upload_harvest_file.modal.component.html';

import { BaseModalController } from '../../components/base_modal_controller';
import { LegacyBaseSoracomApiService } from '@user-console/legacy-soracom-api-client';
import { HarvestFile } from '../../core/harvest_file';
import { InjectList } from '../../core/injectable';
import { ApiCredentialsService } from '@soracom/shared/data-access-auth';

export class UploadHarvestFileModalComponent {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = UploadHarvestFileModalComponentController;
  template: any = template;
}

export class UploadHarvestFileModalComponentController extends BaseModalController {
  static $inject: InjectList = ['$log', '$scope', '$translate', 'BaseSoracomApiService', 'ApiCredentialsService'];

  // @ts-expect-error (legacy code incremental fix)
  private uppy: Uppy;
  private headers: LegacyAny;
  // @ts-expect-error (legacy code incremental fix)
  filepath: string;

  constructor(
    $log: ng.ILogService,
    private $scope: ng.IScope,
    private $translate: LegacyAny,
    private soracomApi: LegacyBaseSoracomApiService,
    private apiCredentialsService: ApiCredentialsService,
  ) {
    super($log);
  }

  $onInit() {
    this.filepath = this.resolve.currentDirectory.humanReadablePath;
    const apikey = this.apiCredentialsService.getApiKey();
    const token = this.apiCredentialsService.getToken();
    this.headers = {
      'X-Soracom-API-Key': apikey,
      'X-Soracom-Token': token,
    };
    const uppyOptions: Partial<UppyOptions> = {
      debug: false,
      autoProceed: false,
      restrictions: {
        // set values or leave as null for defaults
        maxNumberOfFiles: 1,
        maxFileSize: 5000000000, // 5GB
        minNumberOfFiles: 1,
        allowedFileTypes: null,
      },
      onBeforeFileAdded: (currentFile, files) => {
        if (HarvestFile.isDirectory(this.filepath)) {
          this.filepath = HarvestFile.getCurrentDirectory(this.filepath) + currentFile.name;
        }
        this.$scope.$applyAsync();
        return true;
      },
    };
    if (this.$translate.use() === 'ja') {
      uppyOptions.locale = Japanese;
    }
    this.uppy = new Uppy(uppyOptions);
    this.uppy.use(DragDrop, { target: '#HarvestDragDrop' }).use(StatusBar, {
      target: '#UploadProgress',
      hideUploadButton: true, // only set to false if you are not auto uploading
      hideAfterFinish: false,
    });
    this.setupUppy();

    this.uppy.on('complete', () => {
      // File upload process is async operation, and no way to know when it completes.
      // so I'll wait for 3 seconds. yeah, this is dirty hack...
      setTimeout(() => {
        this.close({ path: HarvestFile.getCurrentDirectory(this.filepath) });
      }, 3000);
    });
  }

  upload() {
    this.setupUppy();
    this.uppy.upload();
  }

  getfile() {
    if (this.uppy.getFiles().length > 0) {
      return this.uppy.getFiles()[0];
    } else {
      return null;
    }
  }

  hasFile() {
    return !!this.getfile();
  }

  removeFile(file: LegacyAny) {
    this.uppy.removeFile(file.id);
  }

  setupUppy() {
    const endpoint = this.soracomApi.getApiEndpointUrl(
      `/v1/files/private${HarvestFile.formatPath(this.filepath, { encode: true })}`,
    );

    const plugin = this.uppy.getPlugin('XHRUpload');
    if (plugin) {
      this.uppy.removePlugin(plugin);
    }
    this.uppy.use(XHRUpload, {
      endpoint,
      method: 'PUT',
      formData: false,
      headers: this.headers,
      // this overwrites the default response parser that expects a json string
      getResponseData: (responseText: LegacyAny, response: LegacyAny) => {
        return {
          message: responseText,
        };
      },
    });
  }
}
