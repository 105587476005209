import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { FormGroup } from '@angular/forms';

import { MaximumEndDate, MinimumStartDate } from '../../shared/gps_multiunit/GpsMultiunitScenario';
import { GMTime } from './../../shared/gps_multiunit/GMTime';
import { GMNull } from './../../shared/gps_multiunit/GpsMultiunitConfigData';

dayjs.extend(customParseFormat);

export function dateValidator(group: FormGroup): { [key: string]: any } | null {
  // @ts-expect-error (legacy code incremental fix)
  const startDateRaw: string = group.get('startDate').value;
  // @ts-expect-error (legacy code incremental fix)// @ts-expect-error (legacy code incremental fix)
  const endDateRaw: string = group.get('endDate').value;
  const errors: { [key: string]: any } = {};
  if (!startDateRaw || !endDateRaw) {
    return null; // required validation is delegated to Validators.required.
  }

  const startDate = dayjs(startDateRaw, 'YYYY/MM/DD');
  const endDate = dayjs(endDateRaw, 'YYYY/MM/DD');
  if (startDate > endDate) {
    errors.invalidDateRange = true;
  }
  if (startDate < MinimumStartDate) {
    errors.invalidStartDate = true;
  }
  if (endDate > MaximumEndDate) {
    errors.invalidEndDate = true;
  }

  return errors;
}

// @ts-expect-error (legacy code incremental fix)
export function timeValidator(group: FormGroup): { [key: string]: any } | null {
  // @ts-expect-error (legacy code incremental fix)
  const startTime: GMTime | GMNull = GMTime.parse(group.get('startTime').value);
  // @ts-expect-error (legacy code incremental fix)
  const endTime: GMTime | GMNull = GMTime.parse(group.get('endTime').value);
  if (startTime === 'null' || endTime === 'null') {
    return null; // required validation is delegated to Validators.required.
  }
  if (startTime.duration >= endTime.duration) {
    return { invalidTimeRange: true };
  }
}
