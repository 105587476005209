export const template = `<app-user-notifications></app-user-notifications>
<main
  data-testid="x-audit-logs-page-root"
  class="ds-app__main-container"
  translate-namespace="audit_logs"
>
  <sc-alerts service="$ctrl.alertsService"></sc-alerts>

  <ng-container
    ngIf="$ctrl.getLoggedInAsRoot()"
    translate-namespace=".manage_subscription"
  >
    <div class="ds-notice --info --pinned" ng-if="!$ctrl.hasNapterContract">
      <div>
        <span translate=".subscription_guide"></span>&nbsp;<a
          data-testid="x-subscribe-napter-audit-logs-button"
          class="--link"
          href=""
          ng-click="$ctrl.openUpdateSubscriptionModal()"
          translate=".subscribe"
        ></a>
      </div>
    </div>

    <div class="ds-notice --info --pinned" ng-if="$ctrl.hasNapterContract">
      <div>
        <span translate=".unsubscription_guide"></span>&nbsp;<a
          data-testid="x-unsubscribe-napter-audit-logs-button"
          class="--link"
          href=""
          ng-click="$ctrl.openUpdateSubscriptionModal()"
          translate=".unsubscribe"
        ></a>
      </div>
    </div>
  </ng-container>

  <sc-audit-logs></sc-audit-logs>
</main>
`;
