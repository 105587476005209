<details
  class="ds-menubutton --no-background"
  style="position: relative"
  (click)="onDetailsClick($event)"
  (detailsOpen)="onDetailsOpen()"
>
  <summary>
    <span class="ds-button --bottom" [attr.data-ds-texttip]="$any(buttonTextTipId) | translate: { max: max }">
      <span class="ds-text --addon">{{ 'resource-selector.resource.label' | translate }}</span>
      <span *ngIf="$any(resources)?.length > 0">
        <a
          href=""
          style="pointer-events: initial; margin-right: 5px"
          (click)="onClickTagDelete($event, r)"
          *ngFor="let r of resources"
        >
          <span class="ds-tag --color-celeste-lighter --no-case">
            <strong>{{ getLabel(r.resourceType) }} </strong>
            <span>{{ r.resourceId }}</span>
            <i class="ds-icon --icon-cancel --small"></i>
          </span>
        </a>
      </span>
      <i class="ds-icon --icon-ui-arrow-down"></i>
    </span>
  </summary>
  <div
    class="__content"
    style="--ds-menu-width: 500px;"
    cdkTrapFocus
    (keyup)="onMenuKeyup($event)"
  >
    <div>
      <div class="ds-cols --indent-small --gap-xxsmall">
        <label *ngFor="let t of availableTypes" class="ds-tag --medium --outline --no-case">
          <input name="type" type="radio" [formControl]="$any(typeRadioCtrl)" [value]="t" />
          <span>{{ getLabel(t) }}</span>
        </label>
      </div>
      <div dsAutocomplete class="--inline --indent-small --v-indent-xsmall" (selectionChange)="selectOption($any($event))" alwaysExpanded="true">
        <div class="ds-input">
          <input
            type="text"
            dsAutocompleteInput
            #searchInput
            [formControl]="searchInputCtl"
            [placeholder]="getPlaceholder(type)"
          />
        </div>
        <ul dsAutocompleteListbox style="--ds-menu-max-height: 400px">
          <li *ngFor="let o of searchOptions$ | async" [dsOption]="getOptionValue(o)">
            <ng-container *ngIf="!simSelected">
              <!-- TODO: use dedicated component with 'selected' style <app-resource-select-option [value]="o"> </app-resource-select-option> -->
              <dl class="ds-details --vertical">
                <dt>{{ getIdLine(o) }}</dt>
                <dd>
                  <div>{{ o.name }}</div>
                </dd>
              </dl>
            </ng-container>
            <ng-container *ngIf="simSelected">
              <app-sim-autocomplete-item [simData]="$any(o)" [valueKey]="'simId'" [propsToShowInList]="['name']"></app-sim-autocomplete-item>
            </ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
</details>
