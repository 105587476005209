import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataSeriesOption, DataSeriesVisibility } from '../harvest-data-viz-type';

@Component({
  selector: 'app-harvest-data-chart-dataseries-control',
  templateUrl: './harvest-data-chart-dataseries-control.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HarvestDataChartDataSeriesControlComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() dataSeries: DataSeriesOption[];
  // @ts-expect-error (legacy code incremental fix)
  @Input() visibilities: DataSeriesVisibility;

  @Output() valueChange = new EventEmitter<DataSeriesVisibility>();

  isVisible(label: string) {
    return (this.visibilities || {})[label] !== false; // visible if undefined
  }

  onClick(label: string) {
    const newState = { ...this.visibilities, [label]: !this.isVisible(label) };
    this.valueChange.emit(newState);
  }
}
