import { Marked, Tokens } from 'marked';

export class MarkdownRenderer {
  #renderer: Marked;

  constructor() {
    this.#renderer = new Marked({ gfm: true, async: false });

    // Customize the link renderer
    const originalLinkRenderer = this.#renderer.Renderer.prototype.link;
    this.#renderer.Renderer.prototype.link = function (
      this: Marked['Renderer'],
      token: Tokens.Link,
    ) {
      const link = originalLinkRenderer.call(this, token);
      if (link.includes('target="_blank"')) {
        return link.includes('rel="noopener noreferrer"')
          ? link
          : link.replace('<a', '<a rel="noopener noreferrer"');
      } else {
        return link.replace(
          '<a',
          '<a target="_blank" rel="noopener noreferrer"',
        );
      }
    };
  }

  get renderer(): Marked {
    return this.#renderer;
  }
}

// // Usage
// const markdownService = new MarkdownService();
// const markdown =
//   'Here is a [link](https://example.com) and here is a [link with target](https://example.com){target="_blank"}';
// const html = markdownService.renderer.parse(markdown);
// console.log(html);
