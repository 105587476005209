import { LegacyAny } from '@soracom/shared/core';

import { Component, Input, ViewEncapsulation } from '@angular/core';
import { formatDateTime } from '@soracom/shared/util-common';
import { HarvestData } from '../../../../app/shared/core/harvest_data';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiTableContent } from '../../soracom-ui/ui-table/ui-table-content';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';
import { HarvestDataDeleteAction } from '../harvest-data-delete.action';
import { HarvestDataStore } from '../harvest-data.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-harvest-data-table',
  templateUrl: './harvest-data-table.component.html',
  styleUrls: ['./harvest-data-table.component.scss'],
  providers: [HarvestDataDeleteAction],
  encapsulation: ViewEncapsulation.None,
})
export class HarvestDataTableComponent {
  _data: HarvestData[] = [];
  @Input() set data(data: HarvestData[]) {
    this._data = data;
    this.tableCtrl.setData(data);
  }

  get data() {
    return this._data;
  }

  canDelete = false;

  columnDefs: UiTableColumn<HarvestData>[] = [
    new UiTableColumn<HarvestData>('HarvestData', 'resourceType', (r) => r.resourceType, { visible: false }),
    new UiTableColumn<HarvestData>('HarvestData', 'resourceId', (r) => r.resourceId, { visible: false }),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<HarvestData>('HarvestData', 'time', (d: LegacyAny) => formatDateTime(d.time, 'datetime_sec')),
    new UiTableColumn<HarvestData>('HarvestData', 'contentType', (r) => r.contentType),
    new UiTableColumn<HarvestData>('HarvestData', 'content', (r) => r.content, {
      extraTdCssClasses: ['ds-text--code'],
    }),
    new UiTableColumn<HarvestData>('HarvestData', 'decodedData', (r) => jsonStringify(r['decodedData']), {
      extraTdCssClasses: ['ds-text--code'],
    }),
    new UiTableColumn<HarvestData>('HarvestData', 'chartData', (r) => jsonStringify(r['chartData']), {
      extraTdCssClasses: ['ds-text--code'],
    }),
  ];

  tableCtrl: UiTableContent<HarvestData> = new UiTableContent<HarvestData>([], this.columnDefs, {
    multiSelect: true,
    enableContextMenu: true,
  });

  deleteBtnCtrl: UiButton = UiButton.configure((b) => {
    b.titleId = 'HarvestData.delete_action_button.title';
    b.buttonStyle = 'danger';
    b.onClick = () => this.onDelete();
    b.isDisabled = true;
  });

  constructor(private svc: HarvestDataStore, private deleteAction: HarvestDataDeleteAction) {
    this.svc.isMultiResource$.pipe(takeUntilDestroyed()).subscribe((isMulti) => {
      this.columnDefs[0].visible = isMulti;
      this.columnDefs[1].visible = isMulti;
    });
  }

  onTableItemSelectionChanged = (selectedItems: HarvestData[]) => {
    this.canDelete = this.deleteAction.actionable(selectedItems);
  };

  onDelete = () => {
    const selectedData: HarvestData[] = this.tableCtrl.getSelectedRows().map((d: LegacyAny) => d.obj);
    this.deleteAction.run(selectedData).then((deleted) => {
      if (deleted) {
        this.svc.search();
      }
    });
  };
}

const jsonStringify = (v: any) => {
  if (v) {
    try {
      return JSON.stringify(v);
    } catch {}
  }
  return '';
};
