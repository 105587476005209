export const template = `<uib-tabset type="pills"><uib-tab heading="{{ 'prefixed_event_handlers_by_imsi.tab.imsi' | translate }}">
    <div class="ds-rows">
      <div class="ds-notice--info"><a href="/event_handler?imsi={{$ctrl.imsi}}" ng-click="$ctrl.closeModal()">{{
          'watch.advanceSetup.linkText' | translate }}</a>&nbsp; {{ 'watch.advanceSetup.description' | translate }}
      </div>
      <div class="ds-notice--info">{{ 'watch.description' | translate }}</div>
    </div>
    <form class="ds-rows" name="$ctrl.prefixedEventHandlersForm" ng-disabled="$ctrl.nowLoading">
      <h4 class="ds-heading">{{ 'watch.send_mail_to_operator_action.title' | translate }}</h4>
      <div class="ds-cols --spaced"><label class="ds-checkbox"><input name="enableSmaDailyLimit" type="checkbox"
            ng-model="$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.active" />
          <div>{{ 'watch.send_mail_to_operator_action.daily_limit_by_imsi' | translate }}</div>
        </label>
        <div class="ds-input"
          ng-class="$ctrl.prefixedEventHandlersForm.smaDailyLimitMegaByte.$invalid ? &quot;has-error&quot;: &quot;&quot;">
          <input id="sma-daily-limit-mega-byte" name="smaDailyLimitMegaByte" type="number" min="1"
            ng-pattern="$ctrl.positiveInteger"
            ng-disabled="!$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.active"
            ng-required="$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.active"
            ng-model="$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.value" />
          <div class="ds-text --addon">MiB</div>
        </div>
      </div>
      <div class="ds-cols --spaced"><label class="ds-checkbox"><input name="enableSmaMonthlyLimit" type="checkbox"
            ng-model="$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.active" />
          <div>{{ 'watch.send_mail_to_operator_action.monthly_limit_by_imsi' | translate }}</div>
        </label>
        <div class="ds-input"
          ng-class="$ctrl.prefixedEventHandlersForm.smaMonthlyLimitMegaByte.$invalid ? &quot;has-error&quot;: &quot;&quot;">
          <input id="sma-monthly-limit-mega-byte" name="smaMonthlyLimitMegaByte" type="number" min="1"
            ng-pattern="$ctrl.positiveInteger"
            ng-disabled="!$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.active"
            ng-required="$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.active"
            ng-model="$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.value" />
          <div class="ds-text --addon">MiB</div>
        </div>
      </div>
      <div class="ds-cols --spaced"><label class="ds-checkbox"><input name="enableSmaCumulativeLimit" type="checkbox"
            ng-model="$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.active"
            ng-disabled="$ctrl.readonly" />
          <div>{{ 'watch.send_mail_to_operator_action.cumulative_limit_by_imsi' | translate }}</div>
        </label>
        <div class="ds-input"
          ng-class="$ctrl.prefixedEventHandlersForm.smaCumulativeLimitMegaByte.$invalid ? &quot;has-error&quot;: &quot;&quot;">
          <input id="sma-cumulative-limit-mega-byte" name="smaCumulativeLimitMegaByte" type="number" min="1"
            ng-pattern="$ctrl.positiveInteger"
            ng-disabled="!$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.active || $ctrl.readonly"
            ng-required="$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.active"
            ng-model="$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.value" />
          <div class="ds-text --addon">MiB</div>
        </div>
      </div>
      <h4 class="ds-heading">{{ 'watch.change_speed_class_action.title' | translate }}</h4>
      <div class="ds-cols --spaced"><label class="ds-checkbox"><input name="enableCscaDailyLimit" type="checkbox"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.active" />
          <div>{{ 'watch.change_speed_class_action.daily_limit_by_imsi' | translate }}</div>
        </label>
        <div class="ds-input"
          ng-class="$ctrl.prefixedEventHandlersForm.cscaDailyLimitMegaByte.$invalid ? &quot;has-error&quot;: &quot;&quot;">
          <input id="csca-daily-limit-mega-byte" name="cscaDailyLimitMegaByte" type="number" min="1"
            ng-pattern="$ctrl.positiveInteger"
            ng-disabled="!$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.active"
            ng-required="$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.active"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.value" />
          <div class="ds-text --addon">MiB</div>
        </div>
      </div>
      <div class="ds-cols --spaced"><label class="ds-checkbox"><input name="enableCscaMonthlyLimit" type="checkbox"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.active" />
          <div>{{ 'watch.change_speed_class_action.monthly_limit_by_imsi' | translate }}</div>
        </label>
        <div class="ds-input"
          ng-class="$ctrl.prefixedEventHandlersForm.cscaMonthlyLimitMegaByte.$invalid ? &quot;has-error&quot;: &quot;&quot;">
          <input id="csca-monthly-limit-mega-byte" name="cscaMonthlyLimitMegaByte" type="number" min="1"
            ng-pattern="$ctrl.positiveInteger"
            ng-disabled="!$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.active"
            ng-required="$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.active"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.value" />
          <div class="ds-text --addon">MiB</div>
        </div>
      </div>
      <div class="ds-cols --spaced"><label class="ds-checkbox"><input name="enableCscaCumulativeLimit" type="checkbox"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.active"
            ng-disabled="$ctrl.readonly" />
          <div>{{ 'watch.change_speed_class_action.cumulative_limit_by_imsi' | translate }}</div>
        </label>
        <div class="ds-input"
          ng-class="$ctrl.prefixedEventHandlersForm.cscaCumulativeLimitMegaByte.$invalid ? &quot;has-error&quot;: &quot;&quot;">
          <input id="csca-cumulative-limit-mega-byte" name="cscaCumulativeLimitMegaByte" type="number" min="1"
            ng-pattern="$ctrl.positiveInteger"
            ng-disabled="!$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.active || $ctrl.readonly"
            ng-required="$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.active"
            ng-model="$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.value" />
          <div class="ds-text --addon">MiB</div>
        </div>
      </div><sc-alerts service="$ctrl.alertsService"></sc-alerts>
      <div class="ds-cols"><button class="ds-button --primary" ng-disabled="!$ctrl.canSubmit()"
          ng-click="$ctrl.updateSettings()"><span>{{ 'watch.update_button' | translate }}</span></button><i
          class="ds-icon --medium --icon-refresh-cw --spin" ng-hide="!$ctrl.submitting"></i></div>
    </form>
  </uib-tab><uib-tab heading="{{ 'prefixed_event_handlers_by_imsi.tab.group' | translate }}"
    disable="$ctrl.groupTab.disabled">
    <div class="ds-notice--info"><a href="/event_handler?groupId={{$ctrl.groupId}}" ng-click="$ctrl.closeModal()">{{
        'watch.advanceSetup.linkText' | translate }}</a>&nbsp; {{ 'watch.advanceSetup.description' | translate }}</div>
    <sc-ignore-prefixed-event-handlers-by-imsi settings="$ctrl.groupSettings" imsi="$ctrl.imsi"
      target="group"></sc-ignore-prefixed-event-handlers-by-imsi>
  </uib-tab><uib-tab heading="{{ 'prefixed_event_handlers_by_imsi.tab.operator' | translate }}">
    <div class="ds-notice--info"><a href="/event_handler?operator=true" ng-click="$ctrl.closeModal()">{{
        'watch.advanceSetup.linkText' | translate }}</a>&nbsp; {{ 'watch.advanceSetup.description' | translate }}</div>
    <sc-ignore-prefixed-event-handlers-by-imsi settings="$ctrl.operatorSettings" imsi="$ctrl.imsi"
      target="operator"></sc-ignore-prefixed-event-handlers-by-imsi>
  </uib-tab></uib-tabset>`;
