export const template = `<app-user-notifications></app-user-notifications>
<div id="page-wrapper" translate-namespace="lora_network_set">
  <div class="alerts-wrapper"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  <div class="row block">
    <div class="col-sm-12">
      <div class="form-horizontal">
        <div class="form-group"><label class="col-sm-4 control-label" translate=".id"></label>
          <div class="col-sm-8">
            <div class="form-control-static align-left-with-inline-editable-text"><em
                ng-bind="$ctrl.loraNetworkSet.networkSetId"></em></div>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-4 control-label" translate=".name"></label>
          <div class="col-sm-8 col-md-8 col-lg-6"><sc-inplace-editable-text key="name"
              display-value="$ctrl.loraNetworkSet.name" edit-ctrl="$ctrl.nameEditCtrl" show-remove-button="false"
              show-save-button="true" show-cancel-button="true" show-pencil-icon="true"></sc-inplace-editable-text>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row block">
    <div class="col-sm-12"><uib-tabset><uib-tab
          heading="{{ &quot;lora_network_set.tab.basic_settings&quot; | translate }}"><uib-accordion
            close-others="false">
            <div class="panel-default" uib-accordion-group="uib-accordion-group"
              is-open="$ctrl.status.permissions.isOpen"><uib-accordion-heading>
                <div class="clickable-area"><i class="glyphicon"
                    ng-class="{ &quot;glyphicon-chevron-down&quot;: $ctrl.status.permissions.isOpen, &quot;glyphicon-chevron-right&quot;: !$ctrl.status.permissions.isOpen }"></i>{{
                  &quot;lora_network_set.permissions&quot; | translate }}</div>
              </uib-accordion-heading>
              <table class="ds-datatable --embedded --compact x-lora-network-sets-allowed-operators-table">
                <thead>
                  <tr>
                    <th>{{ "lora_network_set.columns.operator_id" | translate }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr ng-repeat="operatorId in $ctrl.loraNetworkSet.allowedOperators">
                    <td>{{ operatorId }}</td>
                    <td><a class="btn btn-danger" sc-confirm="deletion"
                        on-confirm="$ctrl.revokePermission(operatorId)">{{
                        &quot;lora_network_set.revoke_permission&quot; | translate }}</a></td>
                  </tr>
                  <tr class="no-data" ng-show="!$ctrl.isLoading &amp;&amp; $ctrl.getDataCount() === 0">
                    <td class="fullwidth-message" colspan="2">{{ 'lora_network_set.allowed_operators.no_data' |
                      translate }}</td>
                  </tr>
                  <tr class="loading" ng-show="$ctrl.isLoading">
                    <td class="fullwidth-message" colspan="2">{{ 'common.table.loading' | translate }}</td>
                  </tr>
                  <tr ng-form="ng-form" name="$ctrl.addAllowedOperatorForm">
                    <td><input class="form-control" type="text" name="operatorId" ng-model="$ctrl.operatorId"
                        sc-operator-id="sc-operator-id" operator-ids="$ctrl.loraNetworkSet.allowedOperators"
                        placeholder="OP1234567890" />
                      <div class="form-control-static"
                        ng-show="$ctrl.addAllowedOperatorForm.operatorId.$dirty &amp;&amp; $ctrl.addAllowedOperatorForm.operatorId.$error.operatorIdInvalid">
                        {{ &quot;lora_network_set.errors.invalid_operator_id&quot; | translate }}</div>
                      <div class="form-control-static"
                        ng-show="$ctrl.addAllowedOperatorForm.operatorId.$error.operatorIdDuplicated">{{
                        &quot;lora_network_set.errors.duplicated_operator_id&quot; | translate }}</div>
                    </td>
                    <td><button class="btn btn-primary" ng-disabled="$ctrl.addAllowedOperatorForm.$invalid"
                        ng-click="$ctrl.addPermission()">{{ &quot;lora_network_set.add_permission&quot; | translate
                        }}</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </uib-accordion><uib-accordion close-others="false">
            <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.tags.isOpen">
              <uib-accordion-heading>
                <div class="clickable-area"><i class="glyphicon"
                    ng-class="{ &quot;glyphicon-chevron-down&quot;: $ctrl.status.tags.isOpen, &quot;glyphicon-chevron-right&quot;: !$ctrl.status.tags.isOpen }"></i>{{
                  &quot;lora_network_set.tags&quot; | translate }}</div>
              </uib-accordion-heading><sc-tag-list service-name="$ctrl.serviceName"
                obj-one-way="$ctrl.loraNetworkSet"></sc-tag-list></div>
          </uib-accordion></uib-tab><uib-tab
          heading="{{ &quot;lora_network_set.tab.lora_gateways&quot; | translate }}"><sc-lora-gateways-table
            lora-gateways="$ctrl.loraNetworkSet.loraGateways"></sc-lora-gateways-table></uib-tab><uib-tab
          heading="{{ &quot;lora_network_set.tab.advanced_settings&quot; | translate }}"><uib-accordion
            close-others="false">
            <div class="panel-default" uib-accordion-group="uib-accordion-group" is-open="$ctrl.status.delete.isOpen">
              <uib-accordion-heading>
                <div class="clickable-area"><i class="glyphicon"
                    ng-class="{ &quot;glyphicon-chevron-down&quot;: $ctrl.status.delete.isOpen, &quot;glyphicon-chevron-right&quot;: !$ctrl.status.delete.isOpen }"></i>{{
                  &quot;lora_network_set.delete&quot; | translate }}</div>
              </uib-accordion-heading><button class="btn btn-danger" ng-click="$ctrl.deleteLoraNetworkSet()"
                translate=".delete"></button></div>
          </uib-accordion></uib-tab></uib-tabset></div>
  </div>
</div><sc-tutorial-component></sc-tutorial-component>`;
