import { LegacyAny } from '@soracom/shared/core';

import { template } from './inplace_editable_text.component.html';

import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';

export class InplaceEditableTextComponent implements ng.IComponentOptions {
  bindings = {
    key: '@?',
    displayValue: '=',
    showPencilIcon: '=',
    showRemoveButton: '=',
    showSaveButton: '=',
    showCancelButton: '=',
    buttonPlace: '@?',
    cancelOnBlur: '@?',
    paddingLeft: '=?',
    editCtrl: '=',
  };

  controller = InplaceEditableTextComponentController;
  template: any = template;
}

export interface InplaceEditController {
  save(key: string, value: string): LegacyAny;
  remove(key: string): LegacyAny;
}

export class InplaceEditableTextComponentController extends BaseController {
  static $inject: InjectList = ['$element', '$log', '$scope', '$q', '$timeout'];

  // @ts-expect-error (legacy code incremental fix)
  key: string;
  // @ts-expect-error (legacy code incremental fix)
  displayValue: string;
  // @ts-expect-error (legacy code incremental fix)
  showPencilIcon: boolean;
  // @ts-expect-error (legacy code incremental fix)
  showRemoveButton: boolean;
  // @ts-expect-error (legacy code incremental fix)
  showSaveButton: boolean;
  // @ts-expect-error (legacy code incremental fix)
  showCancelButton: boolean;
  buttonPlace: any;
  // @ts-expect-error (legacy code incremental fix)
  cancelOnBlur: boolean;
  // @ts-expect-error (legacy code incremental fix)
  paddingLeft: string;
  // @ts-expect-error (legacy code incremental fix)
  editCtrl: InplaceEditController;

  // @ts-expect-error (legacy code incremental fix)
  innerEditCtrl: InplaceEditController;
  // @ts-expect-error (legacy code incremental fix)
  editValue: string;
  // @ts-expect-error (legacy code incremental fix)
  editing: boolean;
  // @ts-expect-error (legacy code incremental fix)
  dirty: boolean;
  // @ts-expect-error (legacy code incremental fix)
  saving: boolean;
  // @ts-expect-error (legacy code incremental fix)
  removing: boolean;
  // @ts-expect-error (legacy code incremental fix)
  willCommit: boolean;

  constructor(
    private $element: LegacyAny,
    $log: ng.ILogService,
    private $scope: ng.IScope,
    private $q: ng.IQService,
    private $timeout: ng.ITimeoutService
  ) {
    super($log);
  }

  $onChanges(changedObj: LegacyAny) {
    if (changedObj.displayValue) {
      this.editValue = this.displayValue;
    }
    if (changedObj.editCtrl) {
      this.innerEditCtrl = this.editCtrl;
    }
  }

  $onInit() {
    this.innerEditCtrl = this.editCtrl || this.createDummyEditCtrl();
    this.showPencilIcon = this.showPencilIcon || false;
    this.paddingLeft = this.paddingLeft || '13';
    this.cancelOnBlur = this.cancelOnBlur || false;

    this.editing = false;
    this.dirty = false;
    this.saving = false;
    this.removing = false;
    this.willCommit = false;

    // Avoid commit when a user pressed 'Enter' key to choose characters using IME
    // https://garafu.blogspot.jp/2015/09/javascript-ime-enter-event.html
    this.$element.bind('keypress', (e: LegacyAny) => {
      if (e.which === 13) {
        // Enter without IME
        this.willCommit = true;
      }
    });

    this.$element.bind('keydown', (e: LegacyAny) => {
      if (e.which === 229) {
        // Enter with IME
        this.willCommit = false;
      } else if (e.which === 13) {
        this.willCommit = true;
      }
    });

    this.$element.bind('keyup', (e: LegacyAny) => {
      if (e.which === 13 && this.dirty) {
        // Enter
        if (this.willCommit) {
          this.willCommit = false;
          this.$scope.$apply(() => {
            this.commit(e);
          });
        }
      } else if (e.which === 27) {
        // ESC
        this.$scope.$apply(() => {
          this.cancelEdit(e);
        });
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  beginEdit($event: ng.IAngularEvent) {
    $event.preventDefault();
    // @ts-expect-error (legacy code incremental fix)
    $event.stopPropagation();
    this.editing = true;
    this.editValue = this.displayValue;
    this.$timeout(() => {
      const e = this.$element.find('input.edit-value');
      e.on('click', (event: LegacyAny) => {
        e.select();
        event.stopPropagation();
      });
      e.click();
    });
  }

  onChange() {
    this.dirty = this.displayValue !== this.editValue;
  }

  onBlur($event: ng.IAngularEvent) {
    if (this.cancelOnBlur) {
      this.cancelEdit($event);
    }
  }

  commit($event: ng.IAngularEvent) {
    $event.preventDefault();
    // @ts-expect-error (legacy code incremental fix)
    $event.stopPropagation();
    this.saving = true;
    this.innerEditCtrl
      .save(this.key, this.editValue)
      .then(() => {
        this.displayValue = this.editValue;
      })
      .catch(() => {
        this.editValue = this.displayValue;
      })
      .finally(() => {
        this.saving = false;
        this.editing = false;
      });
  }

  cancelEdit($event: ng.IAngularEvent) {
    if ($event) {
      $event.preventDefault();
      // @ts-expect-error (legacy code incremental fix)
      $event.stopPropagation();
    }
    this.editValue = this.displayValue;
    this.editing = false;
  }

  removeThis($event: ng.IAngularEvent) {
    this.cancelEdit($event);
    this.removing = true;
    this.innerEditCtrl
      .remove(this.key)
      .catch((e: LegacyAny) => this.error(e))
      .finally(() => {
        this.showRemoveButton = false;
        this.removing = false;
      });
  }

  createDummyEditCtrl(): InplaceEditController {
    return {
      save: () => {
        return this.$q.resolve();
      },
      remove: () => {
        return this.$q.resolve();
      },
    };
  }
}
