import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { WebStorageModule } from '@soracom/forks/ngx-store';

import { SupportLoginComponent } from '../auth/support-login/support-login.component';
import { SupportLogoutComponent } from '../auth/support-logout/support-logout.component';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { OrderNotificationComponent } from './order-notification/order-notification.component';

@NgModule({
  declarations: [SupportLoginComponent, SupportLogoutComponent, OrderNotificationComponent],
  exports: [SupportLoginComponent, SupportLogoutComponent, OrderNotificationComponent],
  imports: [
    CommonModule,
    FormsModule,
    // FIXME: Remove unused modules
    ReactiveFormsModule,
    TranslateModule.forChild(),
    WebStorageModule,

    SoracomCommonModule,
    SoracomUiModule,
  ],
})
export class SoracomNavigationModule {}
