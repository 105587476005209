import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { kebabCase } from 'lodash-es';

import { AlertsServiceInstance } from './alerts.service';
import { BaseTableData } from './BaseTableData';
import { BaseTableRow } from './BaseTableRow';
import { BaseController } from './base_controller';
import { ScActionContainer } from './sc_action_container';
import {
  ColumnOption,
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { TableSettingsModalComponent } from '../../../src/app/ng-rewrites/legacy-table/table_settings.modal.component';

export interface TableController<T> {
  tableData: BaseTableData<T>;
  setData(data: T[]): LegacyAny;
  reloadData(): LegacyAny;
  isLoading(): boolean;
  hasData(): boolean;
  getSelection(): Array<BaseTableRow<T>>;
  getColumnsCount(): number;
  readonly alertsService: AlertsServiceInstance;
}

/**
 * A base class for Multi selectable Table
 * some common behaviors.
 */
export class BaseTableController<T> extends BaseController implements TableController<T> {
  // You should set AlertsService instance in sub class
  // @ts-expect-error (legacy code incremental fix)
  alertsService: AlertsServiceInstance;

  tableData: BaseTableData<T>;

  /**
   * 'checkbox-for-all' is checked, set it true.
   */
  checkboxForAll = false;

  /**
   * Used by child components to determine each cell is editable
   */
  editable = true;

  /**
   * Control whether the column is visible by user settings.
   * TODO: implement columnOptions in typescript
   */
  private modalPresenter?: UiDsModalService;
  // @ts-expect-error (legacy code incremental fix)
  private columnOptions: ColumnOption[];
  // @ts-expect-error (legacy code incremental fix)
  private tableType: TableColumnOptionsType;

  /**
   * If this transformer function is present, it will be passed to `TableSettingsModalComponent`, who will use it to post-process the column options. This is a hack specifically to let SimsComponent and SubscribersComponent override the columns that the default machinery returns. (They both use table type Subscriber, but the columns they need differ). Don't use this unless you have to.
   */
  // @ts-expect-error (legacy code incremental fix)
  protected columnOptionsTransformer: (opts: ColumnOption[]) => ColumnOption[];

  protected _isLoading = false;

  protected actionContainer: ScActionContainer<T[]>;
  // @ts-expect-error (legacy code incremental fix)
  protected modelName: string;

  constructor($log: ng.ILogService) {
    super($log);
    this.tableData = new BaseTableData<T>([]);
    this.actionContainer = new ScActionContainer();
  }

  setData(data: T[]) {
    this.tableData = new BaseTableData<T>(data);
  }

  reloadData() {
    this.uncheckAll();
  }

  isLoading() {
    return this._isLoading;
  }

  hasData(): boolean {
    return this.tableData.rows.length > 0;
  }

  getSelection(): Array<BaseTableRow<T>> {
    return this.tableData.getSelectedRows();
  }

  doAction(actionName: string, target?: T[] | T): void {
    if (target === null || target === undefined) {
      target = this.tableData.getSelectedData();
    } else if (!Array.isArray(target)) {
      target = [target]; // This is for compatibility
    }
    this.actionContainer.doAction(actionName, target);
  }

  actionable(actionName: string, target?: T[] | T): boolean {
    if (target === null || target === undefined) {
      target = this.tableData.getSelectedData();
    } else if (!Array.isArray(target)) {
      target = [target]; // This is for compatibility
    }
    return this.actionContainer.actionable(actionName, target);
  }

  setupColumnOptions(
    tableType: TableColumnOptionsType,
    service: TableColumnOptionsService,
    modalPresenter: UiDsModalService
  ) {
    this.tableType = tableType;
    this.modalPresenter = modalPresenter;
    this.columnOptions = service.get(tableType);
  }

  /**
   * Return the column should be shown
   */
  shouldShowColumn(columnName: LegacyAny): boolean {
    if (this.columnOptions) {
      for (const option of this.columnOptions) {
        if (option.key === columnName) {
          return option.show;
        }
      }
      return false;
    }
    return true;
  }

  getColumnsCount() {
    if (this.columnOptions) {
      return this.columnOptions.filter((option) => option.show).length + (this.selectable() ? 1 : 0);
    } else {
      return 100; // Giving enough big value
    }
  }

  openTableSettingsModal() {
    this.modalPresenter
      ?.openAndWaitForResult(TableSettingsModalComponent, {
        title: 'table_settings.header',
        data: {
          resolve: {
            params: () => {
              return {
                tableType: this.tableType,
                columnOptionsTransformer: this.columnOptionsTransformer,
              };
            },
          },
        },
      })
      .then((result) => {
        if (result) {
          this.columnOptions = result.columnOptions;
        }
      });
  }

  /**
   * Return the context menu should be shown
   */
  shouldShowContextMenu() {
    return true;
  }

  /**
   * Just copied from subscriber table class.
   * Return if the table is selectable.
   * It should be used in subscribers list in group detail view.
   *
   * TODO: implement
   */
  selectable(): boolean {
    return true;
  }

  getCheckboxForAllElementId(): string {
    return `#${kebabCase(this.modelName)}-checkbox-for-all`;
  }

  /**
   * Update state of 'checkbox-for-all' checkbox.
   */
  updateCheckboxForAll(elementId = this.getCheckboxForAllElementId()) {
    const checked = this.tableData.countChecked();
    const unchecked = this.tableData.countUnchecked();
    // FIXME: [2018-01-29 YUTA] I can't add type `checkbox` variable due to an error.
    const checkbox: any = angular.element(elementId)[0];
    if (checked === 0) {
      this.checkboxForAll = false;
      if (checkbox) {
        checkbox.indeterminate = false;
      }
    } else if (unchecked === 0) {
      this.checkboxForAll = true;
      if (checkbox) {
        checkbox.indeterminate = false;
      }
    } else {
      // @ts-expect-error (legacy code incremental fix)
      this.checkboxForAll = null;
      if (checkbox) {
        checkbox.indeterminate = true;
      }
    }
  }

  /**
   * Select all data from code not UI
   */
  checkAll() {
    this.checkboxForAllChanged(true);
    this.updateCheckboxForAll();
  }

  /**
   * Deselect all data from code not UI
   */
  uncheckAll() {
    this.checkboxForAllChanged(false);
    this.updateCheckboxForAll();
  }

  /**
   * Action that should be triggered when a checkbox-for-all state is changed.
   */
  checkboxForAllChanged(check: boolean): void {
    this.tableData.rows.forEach((element: LegacyAny) => {
      if (check === true) {
        element.check();
      } else {
        element.uncheck();
      }
    });
  }

  /**
   * Action that should be triggered when a checkbox-for-each state is changed.
   */
  checkboxForEachChanged() {
    this.updateCheckboxForAll();
  }

  /**
   * Action that should be triggered when a row selection has been toggled.
   */
  setSelected(row: BaseTableRow<T>) {
    if (this.selectable()) {
      row.selected = !row.selected;
      this.checkboxForEachChanged();
    }
  }
}
