<form [formGroup]="form" (submit)="onSubmit()">
  <div class="ds-rows">
    <div class="ds-field">
      <div class="ds-label">
        <span>{{ 'data.global_options.latitude.label' | translate }}</span>
        <i
          class="ds-icon--xsmall ds-icon--icon-question ds-texttip--bottom"
          [attr.data-ds-texttip]="'data.global_options.latitude.description' | translate"
        ></i>
      </div>
      <input
        type="text"
        class="ds-input"
        formControlName="latitudeKey"
        [placeholder]="'data.global_options.latitude.placeholder' | translate"
      />
    </div>
    <div class="ds-field">
      <label class="ds-label">
        <span>{{ 'data.global_options.longitude.label' | translate }}</span>
        <i
          class="ds-icon--xsmall ds-icon--icon-question ds-texttip--bottom"
          [attr.data-ds-texttip]="'data.global_options.longitude.description' | translate"
        ></i>
      </label>
      <input
        type="text"
        class="ds-input"
        formControlName="longitudeKey"
        [placeholder]="'data.global_options.longitude.placeholder' | translate"
      />
    </div>
    <div class="ds-field">
      <button type="submit" class="ds-button--primary" [disabled]="!form.valid">
        {{ 'data.global_options.apply' | translate }}
      </button>
    </div>
  </div>
</form>
