<ng-container *ngFor="let ds of dataSeries">
  <label class="ds-checkbox">
    <input type="checkbox" [checked]="isVisible(ds.label)" (change)="onClick(ds.label)" />
    <div class="harvest-data-chart-legend">
      <div
        class="harvest-data-chart-legend-box"
        [ngStyle]="{ 'background-color': ds.backgroundColor, 'border-color': ds.borderColor }"
      ></div>
    </div>
    <div class="ds-text--small">{{ ds.label }}</div>
  </label>
</ng-container>
