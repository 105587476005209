export const template = `<form name="$ctrl.form" translate-namespace="device_object_models.modals.delete" novalidate="novalidate">
  <div class="x-form-contents">
    <div class="modal-header">
      <h4 translate=".title"></h4>
    </div>
    <div class="modal-body">
      <p translate=".description"></p>
      <div class="form-horizontal">
        <div class="form-group">
          <label class="col-sm-2 control-label" translate="device_object_models.columns.id"></label>
          <div class="col-sm-10">
            <div class="form-control-static">
              <span ng-bind="$ctrl.model.id"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-group">
        <button class="btn btn-default" ng-click="$ctrl.cancel()" translate=".cancel"></button>
        <button class="btn btn-primary" ng-click="$ctrl.submit()" translate=".delete" data-testid="confirm-delete-object-device-model"></button>
      </div>
    </div>
  </div>
</form>;`;
