<section class="--responsive">
  <table class="ds-datatable --compact --striped">
    <thead>
      <tr>
        <th class="--min">
          <label class="ds-checkbox --hide-label --right">
            <input
              type="checkbox"
              id="checkbox-select-all"
              name="checkbox-select-all"
              class="ds-checkbox__check"
              [checked]="allSelected"
              (change)="toggleSelectAll()"
              [disabled]="isLoading || logEntries.length < 1"
            />
            <span>All</span>
          </label>
        </th>

        <!-- LOOP OVER visibleColumnIds TO LAY OUT <th> (other than special checkbox one above)-->
        <th *ngFor="let columnId of visibleColumnIds" class="{{ additionalClassesForTh(columnId) }}">
          <span>{{ 'SoracomLogs.columnTitle.' + columnId | translate }}</span>
        </th>

        <!-- This empty <th> is because there is a cell with a 'display in popup' button at end of each row -->
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="!isLoading && logEntries.length > 0">
      <!-- LOOP OVER THE LOG ENTRIES AND GENERATE A <tr> for each: -->
      <tr *ngFor="let record of logEntries; let i = index">
        <!-- CHECKBOX FIELD IS SPECIAL: -->
        <th data-label="Select">
          <label class="ds-checkbox --hide-label">
            <input
              type="checkbox"
              [attr.id]="'checkbox-' + i"
              [attr.name]="'checkbox-' + i"
              class="ds-checkbox__check {{ 'x-checkbox-' + i }}"
              [checked]="record.selected"
              (change)="toggleSelected(record)"
            />
            <span>Checkbox label</span>
          </label>
        </th>

        <!-- AFTER CHECKBOX, LOOP OVER visibleColumnIds TO GENERATE A <td> TO DISPLAY EACH FIELD: -->

        <td
          *ngFor="let columnId of visibleColumnIds"
          attr.data-label="{{ 'SoracomLogs.columnTitle.' + columnId | translate }}"
        >
          <div class="ds-text --small --code  x-{{ columnId }} {{ additionalClassesForTd(columnId) }}">
            {{ $any(record)[columnId] }}

            <!-- FIXME: the full HTML table had <td style="white-space: nowrap"> for the requestPath field so we should implement that somehow -->
            <button
              *ngIf="shouldShowFilterButton(columnId) && $any(record)[columnId]"
              class="ds-button --plain --icon-filter --hide-label --small"
              (click)="emitFilterChange($event, $any(record)[columnId])"
            >
              <span>{{ 'SoracomLogs.filterButtonTitle' | translate }}</span>
            </button>
          </div>
        </td>
        <td>
          <button
            *ngIf="!isLoading && record"
            class="ds-button --plain --hide-label --icon-inspect --left"
            (click)="logViewerController.onShowLogDetail({ event: $event, record: record })"
          >
            <span>{{ 'SoracomLogs.logDetail' | translate }}</span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- LOADING-->
  <div *ngIf="!isLoading && logEntries.length < 1" class="ds-notice" style="text-align: center">
    <ng-container *ngIf="isFiltered">
      <ui-text-content [content]="noRecordsMatchingFilterExist"></ui-text-content>
    </ng-container>
    <ng-container *ngIf="!isFiltered">
      <ui-text-content [content]="noRecordsExist"></ui-text-content>
    </ng-container>
  </div>

  <div *ngIf="isLoading" class="ds-group --loading x-loading">
    <div class="ds-notice" style="text-align: center; min-height: 10em">Loading...</div>
  </div>
</section>
