<ng-container *ngIf="mode === 'auditLogs' || mode === 'testAuditLogs'">
  <div class="ds-button-bar">
    <div class="ds-text">
      <span class="ds-text--label">API:</span>
      <em *ngIf="!hasAuditLogsEnterpriseContract"> /v1/auth</em>
    </div>
    <ui-select *ngIf="hasAuditLogsEnterpriseContract" [content]="selectApiKind"></ui-select>
    <!-- FIXME: Mason 2021-06-13: make this a sub-template and re-use-->
    <ui-datetime-range-selector
      [value]="queryTimeRange"
      [relativeTimeRangeOptions]="relativeTimeRangeOptions"
      (timeRangeChange)="onQueryTimeRangeChange($event)"
    ></ui-datetime-range-selector>
  </div>
</ng-container>

<ng-container *ngIf="mode === 'errorLogs' || mode === 'testErrorLogs'">
  <div class="ds-button-bar">
    <ui-select [content]="serviceType"></ui-select>
    <ui-select [content]="resourceType"></ui-select>
    <input
      [(ngModel)]="$any(query).resourceId"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="isLoading"
      class="ds-input--small ds-input--narrow"
      placeholder="{{ 'SoracomLogs.placeholder.resourceId' | translate }}"
    />
    <!-- Adopt above pattern here. -->
  </div>
</ng-container>

<ng-container *ngIf="mode === 'napterAuditLogs' || mode === 'testNapterAuditLogs'">
  <div class="ds-button-bar">
    <input
      name="resourceId"
      [(ngModel)]="$any(query).resourceId"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="isLoading"
      class="ds-input--small ds-input--narrow"
      placeholder="SIM IMSI"
    />
    <!-- Adopt above pattern here. -->
  </div>
</ng-container>
