import { LegacyAny } from '@soracom/shared/core';

import { ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  SoracomApiParams,
} from '@user-console/legacy-soracom-api-client';
import { LagoonUser, LagoonUserRole } from '../core/lagoon_user';

export interface LagoonUserCreationRequest {
  userEmail: string;
  userPassword: string;
  role: LagoonUserRole;
}

export interface LagoonUserPermissionUpdatingRequest {
  role: string;
}

export interface LagoonUserPasswordUpdatingRequest {
  userEmail: string;
  oldPassword: string;
  newPassword: string;
}

export interface LagoonUserEmailUpdatingRequest {
  userEmail: string;
}

const DEFAULT_LIMIT = 100;

export class LagoonUsersService implements PaginatableService<LagoonUser> {
  static $inject = ['$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'lagoon/users';
  v2 = false;

  constructor(
    private $q: ng.IQService,
    private soracomApi: LegacyBaseSoracomApiService,
    private paginationService: PaginationService,
  ) {}

  list(queryOptions: LegacyAny): Promise<ScRelation<LagoonUser>> {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}`,
      query: {
        limit: queryOptions.limit,
        last_evaluated_key: queryOptions.last_evaluated_key,
      },
    };

    if (this.v2) {
      // @ts-expect-error (legacy code incremental fix)
      apiParams.query.v2 = 'true';
    }

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const data = response.data.map((element: LegacyAny) => new LagoonUser(element));
      const relation: ScRelation<LagoonUser> = {
        data,
        links: this.paginationService.getPaginationLinks(response.headers.link),
      };

      return this.$q.resolve(relation);
    });
  }

  // @ts-expect-error (legacy code incremental fix)
  listAll(lastEvaluatedKey: string = null, result: LagoonUser[] = []): Promise<LagoonUser[]> {
    const queryOptions: any = {
      limit: DEFAULT_LIMIT,
      last_evaluated_key: lastEvaluatedKey,
    };

    if (this.v2) {
      queryOptions.v2 = 'true';
    }

    return this.list(queryOptions).then((relation) => {
      result.push(...relation.data);
      const newLastEvaluatedKey = relation.links.next?.lastEvaluatedKey;
      if (newLastEvaluatedKey) {
        return this.listAll(newLastEvaluatedKey, result);
      } else {
        return result;
      }
    });
  }

  get(id: string) {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}/${id}`,
      contentType: 'application/json',
    };

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const user = new LagoonUser(response.data);
      return this.$q.resolve(user);
    });
  }

  create(req: LagoonUserCreationRequest) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}`,
      contentType: 'application/json',
      body: req,
    };

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      if (response.data && !response.data.message) {
        const user = new LagoonUser(response.data);
        return user;
      } else {
        return null;
      }
    });
  }

  updatePermission(id: string, req: LagoonUserPermissionUpdatingRequest) {
    const apiParams: SoracomApiParams = {
      method: 'put',
      path: `/v1/${this.resourcePath}/${id}/permission`,
      contentType: 'application/json',
      body: req,
    };

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const user = new LagoonUser(response.data);
      return this.$q.resolve(user);
    });
  }

  updateEmail(id: string, req: LagoonUserEmailUpdatingRequest) {
    const apiParams: SoracomApiParams = {
      method: 'put',
      path: `/v1/${this.resourcePath}/${id}/email`,
      contentType: 'application/json',
      body: req,
    };

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const user = new LagoonUser(response.data);
      return this.$q.resolve(user);
    });
  }

  updatePassword(id: string, req: LagoonUserPasswordUpdatingRequest) {
    const apiParams: SoracomApiParams = {
      method: 'put',
      path: `/v1/${this.resourcePath}/${id}/password`,
      contentType: 'application/json',
      body: req,
    };

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const user = new LagoonUser(response.data);
      return this.$q.resolve(user);
    });
  }

  delete(id: string) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: `/v1/${this.resourcePath}/${id}`,
    };

    return this.soracomApi.callApiWithToken(apiParams);
  }
}
