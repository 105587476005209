import { LegacyAny } from '@soracom/shared/core';

import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SoracomUiModule } from 'apps/user-console/src/app/soracom-ui/soracom-ui.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';
import { FormsModule, NgForm } from '@angular/forms';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-credd-add-modal',
  templateUrl: './cred_add.modal.component.html',
  standalone: true,
  imports: [FormsModule, SoracomUiModule, TranslateModule, CommonModule],
})
export class CredAddModalComponent
  extends LegacyUibModalAdapterModalBase<
    {
      defaultType: () => string;
      availableTypes: () => string[];
    },
    {
      credentialsId?: string;
      type?: string;
      selectedType?: string;
      description?: string;
    }
  >
  implements OnInit
{
  alertsManager = new AlertsManager();
  credNamePattern = '^[A-Za-z0-9_-]{1,50}$';
  exactlyFourCharactersPattern = '^.{4}$';
  // @ts-expect-error (legacy code incremental fix)
  credName: string;
  // @ts-expect-error (legacy code incremental fix)
  credDescription: string;
  types: Array<{ code: string; label: string }> = [];
  submitting = false;
  @ViewChild('addCredForm') credAddForm?: NgForm;
  selectedType: string | undefined;
  defaultType: string | undefined;

  // Editing form values
  cred: any;

  // @ts-expect-error (legacy code incremental fix)
  hasDefaultType: boolean;

  // @ts-expect-error (legacy code incremental fix)
  credAwsKey: string;
  // @ts-expect-error (legacy code incremental fix)
  credAwsSecret: string;
  // @ts-expect-error (legacy code incremental fix)
  credRoleArn: string;
  // @ts-expect-error (legacy code incremental fix)
  credExternalId: string;
  // @ts-expect-error (legacy code incremental fix)
  credAzureKey: string;
  // @ts-expect-error (legacy code incremental fix)
  credAzureSecret: string;
  // @ts-expect-error (legacy code incremental fix)
  credPreSharedKey: string;
  // @ts-expect-error (legacy code incremental fix)
  credX509Key: string;
  // @ts-expect-error (legacy code incremental fix)
  credX509Cert: string;
  // @ts-expect-error (legacy code incremental fix)
  credX509CA: string;

  constructor(
    private $translate: TranslateService,
    private featureVisibilityService: FeatureVisibilityService,
    private soracomApi: SoracomApiService
  ) {
    super();
  }

  ngOnInit() {
    this.credName = '';
    this.credDescription = '';
    this.cred = {};
    this.types = this.setupTypes();
    this.defaultType = this.resolve?.defaultType();
    this.selectedType = this.defaultType;
    this.hasDefaultType = this.defaultType !== undefined && this.defaultType !== null;
    this.types.forEach((type) => {
      this.cred[type.code] = {};
    });

    if (Array.isArray(this.resolve?.availableTypes)) {
      this.types = this.types.filter((value: LegacyAny) => {
        return this.resolve?.availableTypes().includes(value.code);
      });
    }

    if (this.featureVisibilityService.isEnabled('pfYaskawa')) {
      const c = this.cred['mmcloud-credentials'];
      if (c.machineidtype == null) {
        c.machineidtype = 0;
      }
      c.machineidtype_as_boolean = !!c.machineidtype;
    }
  }

  private setupTypes() {
    const types = [
      {
        code: 'aws-credentials',
        label: this.tr('security.credentials.modals.register.aws_credentials'),
      },
      {
        code: 'aws-iam-role-credentials',
        label: this.tr('security.credentials.modals.register.aws_iam_role_credentials'),
      },
      {
        code: 'azure-credentials',
        label: this.tr('security.credentials.modals.register.azure_credentials'),
      },
      {
        code: 'psk',
        label: this.tr('security.credentials.modals.register.pre_shared_key'),
      },
      {
        code: 'x509',
        label: this.tr('security.credentials.modals.register.x509'),
      },
    ];
    // Generic credentials
    types.push({
      code: 'api-token-credentials',
      label: this.tr('security.credentials.modals.register.api_token_credentials'),
    });
    types.push({
      code: 'username-password-credentials',
      label: this.tr('security.credentials.modals.register.username_password_credentials'),
    });

    // SORACOM Beam
    types.push({
      code: 'azureIoT-credentials',
      label: this.tr('security.credentials.modals.register.azure_io_t_credentials'),
    });

    types.push({
      code: 'pubnub-credentials',
      label: this.tr('security.credentials.modals.register.pubnub_credentials'),
    });

    // SORACOM Funnel
    types.push({
      code: 'googleIoT-credentials',
      label: this.tr('security.credentials.modals.register.google_io_t_credentials'),
    });
    types.push({
      code: 'google-service-account-json',
      label: this.tr('security.credentials.modals.register.google_service_account_json'),
    });

    // Partner Funnel credentials
    if (this.featureVisibilityService.isEnabled('pfEsrij')) {
      types.push({
        code: 'esrij-credentials',
        label: this.tr('security.credentials.modals.register.esrij_credentials'),
      });
    }
    if (this.featureVisibilityService.isEnabled('pfInfoteria')) {
      types.push({
        code: 'infoteria-platio-credentials',
        label: this.tr('security.credentials.modals.register.infoteria_platio_credentials'),
      });
    }
    if (this.featureVisibilityService.isEnabled('pfKii')) {
      types.push({
        code: 'kii-thingif-credentials',
        label: this.tr('security.credentials.modals.register.kii_thingif_credentials'),
      });
    }
    if (this.featureVisibilityService.isEnabled('pfMockmock')) {
      types.push({
        code: 'mockmock-datarecorder-credentials',
        label: this.tr('security.credentials.modals.register.mockmock_datarecorder_credentials'),
      });
    }
    types.push({
      code: 'watsonIoT-credentials',
      label: this.tr('security.credentials.modals.register.watson_io_t_credentials'),
    });
    if (this.featureVisibilityService.isEnabled('pfWingarc')) {
      this.cred.motionboard = {}; //With ng2, [(ngModel)] to a prop that is on an undefined (cred.motionboard) will throw an error, so need to instantiate it
      types.push({
        code: 'motionboard-credentials',
        label: this.tr('security.credentials.modals.register.motionboard_credentials'),
      });
    }
    if (this.featureVisibilityService.isEnabled('pfYaskawa')) {
      types.push({
        code: 'mmcloud-credentials',
        label: this.tr('security.credentials.modals.register.mmcloud_credentials'),
      });
    }
    if (this.featureVisibilityService.isEnabled('pfLandlogLandlog')) {
      types.push({
        code: 'landlog-credentials',
        label: this.tr('security.credentials.modals.register.landlog_credentials'),
      });
    }
    if (this.featureVisibilityService.isEnabled('pfOptimCloudIoTOS')) {
      types.push({
        code: 'optim-cloud-IoT-OS-credentials',
        label: this.tr('security.credentials.modals.register.optim_cloudiotos_credentials'),
      });
    }

    if (this.featureVisibilityService.isEnabled('pfInfocorpusSensorcorpus')) {
      types.push({
        code: 'sensorcorpus-credentials',
        label: this.tr('security.credentials.modals.register.sensorcorpus_credentials'),
      });
    }

    // FIXME: this should be alphabetized since it appears in this order in a menu
    return types;
  }

  private tr(id: string, values: { [key: string]: any } = {}) {
    return this.$translate.instant(id, values);
  }

  submit() {
    if (!this.canSubmit()) {
      return;
    }

    const params: any = {
      description: this.credDescription,
      type: this.selectedType,
    };
    if (this.selectedType === 'aws-credentials') {
      params.credentials = {
        accessKeyId: this.credAwsKey,
        secretAccessKey: this.credAwsSecret,
      };
    } else if (this.selectedType === 'aws-iam-role-credentials') {
      params.credentials = {
        roleArn: this.credRoleArn,
        externalId: this.credExternalId,
      };
    } else if (this.selectedType === 'azure-credentials') {
      params.credentials = {
        policyName: this.credAzureKey,
        key: this.credAzureSecret,
      };
    } else if (this.selectedType === 'motionboard-credentials') {
      params.credentials = this.cred.motionboard;
    } else if (this.selectedType === 'psk') {
      params.credentials = {
        key: this.credPreSharedKey,
      };
    } else if (this.selectedType === 'x509') {
      params.credentials = {
        key: this.credX509Key,
        cert: this.credX509Cert,
      };
      if (this.credX509CA) {
        params.credentials.ca = this.credX509CA;
      }
    } else if (this.selectedType === 'mmcloud-credentials') {
      // Do a weird little dance to make the credentials all right:
      params.credentials = this.cred[this.selectedType];
      params.credentials.machineidtype = params.credentials.machineidtype_as_boolean ? 1 : 0;
      delete params.credentials.machineidtype_as_boolean;
      // } else if (this.selectedType === 'landlog-credentials') {
      //   params.credentials = this.cred[this.selectedType];
    } else if (this.selectedType) {
      // NOTE: This default implementation is sufficient for simple credential types if the template is bound to the right values. I realized this after initially implementing credentials for landlog-credentials above, so see that section of the template for an example.

      // Some of the older code above creates properties via ng-model, this is non-optimal... we should probably update that but I didn't look closely since all of this mess is a prime candidate to be upgraded to 8 soon...

      params.credentials = this.cred[this.selectedType];
    }
    this.submitting = true;
    this.soracomApi
      .createCredentialsSet(this.credName, params)
      .then((res: LegacyAny) => {
        const credentialsSet = {
          credentialsId: this.credName,
          type: this.selectedType,
          selectedType: this.selectedType,
          description: this.credDescription,
          // Mason 2017-06-24: FIXME: It's confusing that this structure uses different
          // property names from what CredentialsSetsService/SoracomApi uses, and both
          // are referred to as 'credentials sets'. We should update this code here (and
          // call sites) to be consistent with API.
        };
        this.close(credentialsSet);
      })
      .catch((response: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(response));
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  canSubmit() {
    return !this.submitting && this.credAddForm?.valid && this.selectedType !== null;
  }
}
