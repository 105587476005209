export const template = `<div class="btn-group group-chooser-outer" uib-dropdown="uib-dropdown"
  ng-class="{ &quot;wait-cursor&quot;: $ctrl.saving || $ctrl.removing }"><button
    class="btn btn-default dropdown-toggle group-chooser-button" id="group-chooser-button"
    uib-dropdown-toggle="uib-dropdown-toggle" type="button">{{ $ctrl.getDisplayGroupName() }} &nbsp;<span
      class="caret"></span></button>
  <ul class="dropdown-menu" id="group-list" role="menu" uib-dropdown-menu="uib-dropdown-menu">
    <li ng-if="$ctrl.options.showUnset"><a href="" ng-click="$ctrl.unsetGroup()" translate="group_chooser.unset"></a>
    </li>
    <li ng-repeat="group in $ctrl.getGroups() | orderGroupByName"><a href="" ng-click="$ctrl.setGroup(group)"
        ng-bind="group.tags.name"></a></li>
    <li class="divider" ng-if="$ctrl.options.showCreate"></li>
    <li id="create-group-button" ng-if="$ctrl.options.showCreate"><a href="" ng-click="$ctrl.createGroup()"
        translate="group_chooser.create"></a></li>
  </ul>
</div>`;
