import { LegacyAny } from '@soracom/shared/core';

import { CoverageType } from '@foundation/coverage-type';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { Group } from '@soracom/shared/group';
import { InjectList } from '../core/injectable';
import { GroupsCache } from './groups_cache';

/**
 * The service to handle groups caches
 */
export class GroupsCacheService {
  static $inject: InjectList = ['$log', 'CoverageTypeService'];

  private storage: { [coverageType: string]: GroupsCache };

  constructor(private $log: LegacyAny, private coverageTypeService: CoverageTypeService) {
    this.storage = {};
  }

  /**
   * Get a cache of coverage type
   */
  get(coverageType?: CoverageType): GroupsCache {
    // @ts-expect-error (legacy code incremental fix)
    coverageType = coverageType || this.coverageTypeService.getCoverageType();
    // @ts-expect-error (legacy code incremental fix)
    return this.storage[coverageType] || this.initialize(null, coverageType);
  }

  /**
   * Initialize a cache with the groups
   */
  initialize(groups: Group[], coverageType?: CoverageType): GroupsCache {
    // @ts-expect-error (legacy code incremental fix)
    coverageType = coverageType || this.coverageTypeService.getCoverageType();
    // @ts-expect-error (legacy code incremental fix)
    let cache = this.storage[coverageType];
    if (cache) {
      return cache.reset(groups);
    } else {
      cache = new GroupsCache(groups, coverageType);
      // @ts-expect-error (legacy code incremental fix)
      this.storage[coverageType] = cache;
      return cache;
    }
  }

  /**
   * Remove a group from cache
   */
  remove(group: Group, coverageType?: CoverageType): GroupsCache {
    const cache = this.get(coverageType);
    cache.remove(group.id);
    return cache;
  }

  /**
   * Replace a cache with given groups
   */
  replace(groups: Group[], coverageType?: CoverageType): GroupsCache {
    return this.initialize(groups, coverageType);
  }

  resetAll() {
    this.storage = {};
  }
}
