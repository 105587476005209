import { LegacyAny } from '@soracom/shared/core';

import { AlertsService } from '../components/alerts.service';
import { BaseEditTagController } from '../components/base_edit_tag.controller';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import {
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { ChangeTerminationProtectionAction } from '../core/actions/change_termination_protection_action';
import { EditTagsAction } from '../../../src/app/ng-rewrites/legacy-actions/edit_tags_action';
import { ShowResourceAction } from '../core/actions/ShowResourceAction';
import { TerminateResourceAction } from '../core/actions/terminate_resource_action';
import { InjectList } from '../core/injectable';
import { LoraGateway } from '../core/lora_gateway';
import { template } from './lora_gateways.component.html';
import { LoraGatewaysService } from './lora_gateways.service';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

export class LoraGatewaysComponent implements ng.IComponentOptions {
  bindings = {};
  controller = LoraGatewaysComponentController;
  template: any = template;
}

export class LoraGatewaysComponentController extends BasePaginatableTableController<LoraGateway> {
  static $inject: InjectList = [
    '$location',
    '$log',
    '$translate',
    '$q',
    '$uibModal',
    'AlertsService',
    'LoraGatewaysService',
    'TableColumnOptionsService',
    'UiDsModalService',
  ];

  editable = true;

  constructor(
    private $location: ng.ILocationService,
    $log: ng.ILogService,
    $translate: LegacyAny,
    $q: ng.IQService,
    public $uibModal: any,
    AlertsService: AlertsService,
    public LoraGatewaysService: LoraGatewaysService,
    private tableColumnOptionsService: TableColumnOptionsService,
    private uiDsModalService: UiDsModalService
  ) {
    super($log, $translate, $q, AlertsService, LoraGatewaysService);
    this.modelName = 'LoraGateways';
  }

  $onInit() {
    super.$onInit();

    this.actionContainer.actions = {
      ShowLoraGateway: new ShowResourceAction<LoraGateway>(this, this.$location, '/lora_gateways/'),
      ChangeTerminationProtection: new ChangeTerminationProtectionAction<LoraGateway>(
        this.logService,
        this.$uibModal,
        this,
        'lora_gateway',
        this.LoraGatewaysService
      ),
      EditTag: new EditTagsAction<LoraGateway>(this.uiDsModalService, this.LoraGatewaysService),
      Terminate: new TerminateResourceAction<LoraGateway>(
        this.logService,
        this.uiDsModalService,
        this,
        'lora_gateway',
        this.LoraGatewaysService,
        this.$translate
      ),
    };

    this.setupColumnOptions(TableColumnOptionsType.LoRaGateway, this.tableColumnOptionsService, this.uiDsModalService);
    this.reloadData();
  }

  setData(data: LoraGateway[]) {
    super.setData(data);
    this.tableData.rows.forEach((row) => {
      const nameEditCtrl = new BaseEditTagController(this.logService, this.$q);
      nameEditCtrl.service = this.LoraGatewaysService;
      nameEditCtrl.editing = row.obj;
      row.nameEditCtrl = nameEditCtrl;
    });
  }

  showGatewayDetail(loraGateway: LoraGateway) {
    this.$location.path(`/lora_gateways/${loraGateway.gatewayId}`);
  }

  statusText(gateway: LoraGateway) {
    if (gateway.status) {
      return this.$translate.instant(`lora_gateways.values.status.${gateway.status}`);
    } else {
      return '';
    }
  }
}
