import { LegacyAny } from '@soracom/shared/core';

/**
 * Error handler for $translate. For UIs that might do complex lookup of localized strings (such as the ContactInfo editors, which lookup not only by language, but next by the selected country to further localize address component names), a mechanism was needed to suppress the default behavior of logging a warning in console, and just do nothing when a lookup fails (so the logic can fall back to looking up something else). That is why this exists. Usually, it just mimcs the default behavior of logging a warning, though.
 */
export const TranslateLookupErrorHandlerFactory = ($log: ng.ILogService) => {
  const ignorePrefixes = ['contact_information'];

  return (translationId: LegacyAny) => {
    if (ignorePrefixes.some((prefix) => translationId.startsWith(prefix))) {
      // no-op
    } else {
      // if (translationId === 'Unknown') {
      //   debugger;
      // }
      $log.debug(`[warning] Translation for ${translationId} doesn't exist`);
    }
  };
};
