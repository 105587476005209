import { LegacyAny } from '@soracom/shared/core';

import { CoverageType } from '@foundation/coverage-type';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { TermsOfServiceIdentifier } from '@soracom/shared/regulatory';
import { InjectList } from '../core/injectable';
import { BaseController } from './base_controller';
import { template } from './terms_of_service_agreement.component.html';

export class TermsOfServiceAgreementComponent implements ng.IComponentOptions {
  bindings = {
    agreed: '=',
    coverageTypes: '=',
    tosIdentifier: '=',
  };
  controller = TermsOfServiceAgreementComponentController;
  template: any = template;
}

class TermsOfServiceAgreementComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$translate', 'CoverageTypeService'];

  // @ts-expect-error (legacy code incremental fix)
  agreed: boolean;
  // @ts-expect-error (legacy code incremental fix)
  coverageTypes: [string];
  // @ts-expect-error (legacy code incremental fix)
  tosIdentifier: TermsOfServiceIdentifier;

  agreedToTermsOfService = false;
  affirmedJpSpecificFilteringServiceRequirement = false;
  shouldShowAdditionalInfo = false;
  // @ts-expect-error (legacy code incremental fix)
  coverageType: CoverageType;

  constructor($log: ng.ILogService, private $translate: LegacyAny, private coverageTypeService: CoverageTypeService) {
    super($log);
  }

  $onInit() {
    this.agreedToTermsOfService = this.agreed;

    if (!this.coverageTypes) {
      // @ts-expect-error (legacy code incremental fix)
      this.coverageTypes = [this.coverageTypeService.getCoverageType()];
    }
  }

  shouldShowJpTerms() {
    return this.coverageTypeService.isJapan();
  }

  shouldShowGTerms() {
    return this.coverageTypeService.isGlobal();
  }

  coverageTypeError() {
    return !this.coverageTypeService.getCoverageType();
  }

  checkboxChanged() {
    let newAgreed = this.agreedToTermsOfService;

    if (this.shouldShowAdditionalInfo) {
      newAgreed = newAgreed && this.affirmedJpSpecificFilteringServiceRequirement;
    }
    this.agreed = newAgreed;
  }

  tosDescription() {
    const translationKey = `tos.accept_linked_tos.${this.tosIdentifier.toLowerCase()}`;
    return this.$translate.instant(translationKey);
  }

  tosLabel() {
    const translationKey = `tos.agree_checkbox_text.${this.tosIdentifier.toLowerCase()}`;
    return this.$translate.instant(translationKey);
  }
}
