import { LegacyAny } from '@soracom/shared/core';

import { AlertsService } from '../components/alerts.service';
import { BaseEditTagController } from '../components/base_edit_tag.controller';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import {
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { ChangeGroupAction } from '../core/actions/ChangeGroupAction';
import { ChangeTerminationProtectionAction } from '../core/actions/change_termination_protection_action';
import { DataAction } from '../core/actions/data_action';
import { EditTagsAction } from '../../../src/app/ng-rewrites/legacy-actions/edit_tags_action';
import { LogsAction } from '../core/actions/LogsAction';
import { ReloadTableAction } from '../core/actions/ReloadTableAction';
import { TerminateResourceAction } from '../core/actions/terminate_resource_action';
import { Group } from '@soracom/shared/group';
import { InjectList } from '../core/injectable';
import { LoraDevice } from '../core/lora_device';
import { template } from './lora_devices.component.html';
import { LoraDevicesService } from './lora_devices.service';
import { DownlinkLoraDeviceAction } from './lora_devices_action';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';

export class LoraDevicesComponent implements ng.IComponentOptions {
  bindings = {};
  controller = LoraDevicesComponentController;
  template: any = template;
}

export class LoraDevicesComponentController extends BasePaginatableTableController<LoraDevice> {
  static $inject: InjectList = [
    '$location',
    '$log',
    '$translate',
    '$q',
    '$uibModal',
    'AlertsService',
    'LoraDevicesService',
    'TableColumnOptionsService',
    'UiDsModalService',
  ];

  editable = true;

  constructor(
    private $location: ng.ILocationService,
    $log: ng.ILogService,
    $translate: LegacyAny,
    $q: ng.IQService,
    public $uibModal: any,
    AlertsService: AlertsService,
    public LoraDevicesService: LoraDevicesService,
    private tableColumnOptionsService: TableColumnOptionsService,
    private uiDsModalService: UiDsModalService,
  ) {
    super($log, $translate, $q, AlertsService, LoraDevicesService);
    this.modelName = 'LoraDevices';
  }

  $onInit() {
    super.$onInit();

    this.setTraceEnabled(true);
    this.trace(this);

    this.actionContainer.actions = {
      // @ts-expect-error (legacy code incremental fix)
      ChangeGroup: new ChangeGroupAction<LoraDevice>(this, this.uiDsModalService, 'LoraDevice'),
      ChangeTerminationProtection: new ChangeTerminationProtectionAction<LoraDevice>(
        this.logService,
        this.$uibModal,
        this,
        'lora_device',
        this.LoraDevicesService,
      ),
      Data: new DataAction<LoraDevice>(this, this.$location),
      Downlink: new DownlinkLoraDeviceAction(this),
      EditTag: new EditTagsAction<LoraDevice>(this.uiDsModalService, this.LoraDevicesService),
      Logs: new LogsAction<LoraDevice>(this, this.$location),
      Reload: new ReloadTableAction(this),
      Terminate: new TerminateResourceAction<LoraDevice>(
        this.logService,
        this.uiDsModalService,
        this,
        'lora_device',
        this.LoraDevicesService,
        this.$translate,
      ),
    };

    this.setupColumnOptions(TableColumnOptionsType.LoRa, this.tableColumnOptionsService, this.uiDsModalService);
    this.reloadData();
  }

  updateGroup(group?: Group) {
    const rows = this.getSelection();
    const promises: LegacyAny = [];
    rows.forEach((row) => {
      if (group && group.groupId !== row.obj.groupId) {
        promises.push(
          this.LoraDevicesService.setGroup(row.obj.loraDeviceId, group.groupId)
            .then(() => {
              row.obj.group = group;
              row.feedback.updateGroup = 'success';
              return true;
            })
            .catch((error: LegacyAny) => {
              row.feedback.updateGroup = 'failure';
              return error;
            }),
        );
      } else if (!group && row.obj.groupId) {
        // @ts-expect-error (legacy code incremental fix)
        row.obj.group = null;
        row.feedback.updateGroup = 'success';
        promises.push(this.LoraDevicesService.unsetGroup(row.obj.loraDeviceId));
      }
    });

    this.$q
      .all(promises)
      .then(() => {
        this.alertsService.showSuccess('lora_devices.change_group_success_message');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  assignGroup(data: LoraDevice[]): Promise<LoraDevice[]> {
    /*
     * TODO: This is a kind of dirty implementation to perform object relation.
     *
     * Problem 1: If the number of groups is too big, we cannot fetch all groups at once.
     */
    return groupsService
      .getCachedAll()
      .then((groups: LegacyAny) => {
        data.forEach((loraDevice) => {
          if (loraDevice.groupId) {
            const group = groups[loraDevice.groupId];
            if (group) {
              loraDevice.group = new Group(group);
            }
          }
        });
        return data;
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
        return data;
      });
  }

  setData(data: LoraDevice[]) {
    return this.assignGroup(data)
      .then((data) => {
        super.setData(data);
        this.tableData.rows.forEach((row) => {
          // FIXME: Super dirty hack to work with nameEditCtrl....
          const nameEditCtrl = new BaseEditTagController(this.logService, this.$q);
          nameEditCtrl.service = this.LoraDevicesService;
          nameEditCtrl.editing = row.obj;
          nameEditCtrl.alertsService = this.alertsService;
          row.nameEditCtrl = nameEditCtrl;
        });
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this._isLoading = false;
      });
  }

  statusText(device: LoraDevice) {
    if (device.status) {
      return this.$translate.instant(`lora_devices.values.status.${device.status}`);
    } else {
      return '';
    }
  }
}
