import { LegacyAny } from '@soracom/shared/core';

enum UserActionType {
  cancel = 'cancel',
  confirm = 'confirm',
}

/**
 * A generic user action class. This is WIP half-baked. Mason plans to improve it later in 2019.
 */
export class UserAction<RESULT_TYPE = any> {
  constructor(public action: UserActionType, public context?: RESULT_TYPE) {
    // nothing else
  }

  static confirm<RESULT_TYPE>(context?: RESULT_TYPE) {
    return new UserAction(UserActionType.confirm, context);
  }

  static cancel<RESULT_TYPE>(context?: RESULT_TYPE) {
    return new UserAction(UserActionType.cancel, context);
  }

  static isCancel(obj: any) {
    return this.isUserAction(obj) && obj.action === UserActionType.cancel;
  }

  static isConfirm(obj: any) {
    return this.isUserAction(obj) && obj.action === UserActionType.confirm;
  }

  static isUserAction(obj: any): obj is UserAction {
    return obj && !!obj.action && !!(UserActionType as LegacyAny)[obj.action];
  }
}
