export const template = `<form name="$ctrl.form" translate-namespace="operators.modals.update_contracts" novalidate="novalidate">
  <div class="x-form-contents">
    <div class="modal-header">
      <h4 translate=".title"></h4>
    </div>
    <div class="modal-body">
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-12">
            <div class="alert alert-warning">
              <p translate=".confirmation_text_subscribe" ng-if="$ctrl.willSubscribe()"></p>
              <p translate=".confirmation_text_unsubscribe" ng-if="$ctrl.willUnsubscribe()"></p>
            </div>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-5 control-label" translate=".current_plan"></label>
          <div class="col-sm-7">
            <p class="form-control-static">{{ "harvest_contract." + $ctrl.current.plan |
              translate:$ctrl.translationData($ctrl.current) }}</p>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-12 text-center"><i class="glyphicon glyphicon-arrow-down"></i></div>
        </div>
        <div class="form-group"><label class="col-sm-5 control-label" translate=".new_plan"></label>
          <div class="col-sm-7">
            <p class="form-control-static">{{ "harvest_contract." + $ctrl.selected.plan |
              translate:$ctrl.translationData($ctrl.selected) }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-group"><button class="btn btn-default" id="confirm-cancel-button" ng-click="$ctrl.cancel()"
          ng-disabled="$ctrl.submitting" translate=".cancel"></button><button class="btn btn-primary"
          id="confirm-submit-button" ng-click="$ctrl.submit()" ng-disabled="$ctrl.submitting || !$ctrl.canSubmit()"
          sc-on-return="sc-on-return" translate=".submit"></button></div>
    </div>
  </div>
</form>`;
