import { LegacyAny } from '@soracom/shared/core';

import { SigfoxSearchableField, SigfoxSearchRegistrationValue, SigfoxSearchStatusValue } from './SigfoxSearchableField';

import { SearchQuery, SearchQueryItem } from '@user-console/legacy-soracom-api-client';
import { Predicate } from '../soracom-ui/ui-predicate-editor/Predicate';
import { PredicateTest } from '../soracom-ui/ui-predicate-editor/PredicateTest';

export class SigfoxSearchPredicate extends Predicate {
  constructor(subject: SigfoxSearchableField, test: PredicateTest, comparisonValue?: string /* | number */) {
    // @ts-expect-error (legacy code incremental fix)
    super(subject, test, comparisonValue);
  }

  get allowedValues(): string[] | undefined {
    if (this.subject === SigfoxSearchableField.status) {
      return Object.keys(SigfoxSearchStatusValue);
    } else if (this.subject === SigfoxSearchableField.registration) {
      return Object.keys(SigfoxSearchRegistrationValue);
    } else {
      return undefined;
    }
  }

  /**
   * Overridden to handle "any" searching.
   */
  get searchQuery(): SearchQuery {
    const queryItems: SearchQueryItem[] = [];

    if (this.subject === SigfoxSearchableField.any) {
      Object.keys(SigfoxSearchableField).forEach((key) => {
        // @ts-expect-error (legacy code incremental fix)
        queryItems.push(new SearchQueryItem(key, this.comparisonValue));
      });
    } else {
      // @ts-expect-error (legacy code incremental fix)
      queryItems.push(new SearchQueryItem(this.subject, this.comparisonValue));
    }

    // Validate the query, by removing illegal values, to prevent Searchlight API from throwing error about invalid key or arg:

    const validatedQueryItems = queryItems.filter((item) => {
      if (item.key === SigfoxSearchableField.any) {
        return false;
      }
      if (item.key === SigfoxSearchableField.status) {
        return !!(SigfoxSearchStatusValue as LegacyAny)[item.value];
      }
      if (item.key === SigfoxSearchableField.registration) {
        return !!(SigfoxSearchRegistrationValue as LegacyAny)[item.value];
      }
      return true;
    });

    return new SearchQuery(validatedQueryItems);

    // FIXME: wanted to use the superclass implementation; but when targeting ES5 you can't access a superclass's get/set implementation from within an override. (https://github.com/Microsoft/TypeScript/issues/338)
  }
}
