import { Component, DestroyRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { AbstractController } from '@soracom/shared-ng/soracom-ui-legacy';
import { DateRange } from '../../soracom-ui/DateRange';
import { TimeRange } from '../../soracom-ui/datetime/datetime-range-selector/datetime-range.type';
import { UiSelect } from '../../soracom-ui/ui-select/UiSelect';
import { LogViewerController, LogViewerControllerMode } from '../log-viewer/log-viewer.component';
import {
  AuditLogQuery,
  LogQuery,
  LogQueryParams,
  LogQueryResourceOption,
  LogQueryServiceOption,
  UISelectResource,
  UISelectService,
} from '../LogViewerQueryTypes';
import { LogViewerSubscriptionModalService } from '../LogViewerSubscriptionModalService';
import { LogViewerDateTimeRanges } from '../LogViewerTimeRanges';
import { LogViewerAllowedApiList } from './LogViewerAllowedApiList';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-log-viewer-query-editor',
  templateUrl: './log-viewer-query-editor.component.html',
  styles: [],
})
export class LogViewerQueryEditorComponent extends AbstractController {
  private destroyRef = inject(DestroyRef);

  /**
   * @see description of this property in component parent
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() logViewerController: LogViewerController;

  /**
   * The query type depends on mode of parent;
   */
  // @ts-expect-error (legacy code incremental fix)
  query: LogQuery;

  /**
   * serviceType will be created on Init for queries that can filter by it.
   */
  // @ts-expect-error (legacy code incremental fix)
  serviceType: UISelectService;

  /**
   * resourceType will be created on Init for queries that can filter by it.
   */
  // @ts-expect-error (legacy code incremental fix)
  resourceType: UISelectResource;

  hasAuditLogsEnterpriseContract: boolean | undefined;

  constructor(logger: Logger, private subscriptionModalService: LogViewerSubscriptionModalService) {
    super(logger);
  }

  ngOnInit(): void {
    this.subscriptionModalService.hasAuditLogsEnterpriseContract
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((c) => {
        this.hasAuditLogsEnterpriseContract = c;
      });

    const mode = this.logViewerController.mode;
    // @ts-expect-error (legacy code incremental fix)
    this.query = LogQueryParams.for(mode);

    if (this.query instanceof AuditLogQuery) {
      this.selectApiKind.value = this.query.apiKind;

      // Wait until after setting the value to set onValueChange, to avoid a superfluous search from being triggered:
      this.selectApiKind.onValueChange = (changed) => {
        const q = this.query as AuditLogQuery;
        q.apiKind = changed.value;
        this.enterKeyPressed(); // FIXME: rename this meth now
      };
    }

    const modeIsIn = (a: LogViewerControllerMode[]) => a.includes(this.mode);

    const canFilterByServiceType: LogViewerControllerMode[] = [
      'errorLogs',
      'testErrorLogs',
      'napterAuditLogs',
      'testNapterAuditLogs',
    ];
    const canFilterByResourceType = canFilterByServiceType;

    // construct this.serviceType if the mode can Drill down by Soracom Service
    if (modeIsIn(canFilterByServiceType) && this.query.hasOwnProperty('serviceType')) {
      const serviceQuery = this.query as { serviceType: LogQueryServiceOption };
      const selectService = new UISelectService();
      selectService.value = serviceQuery.serviceType;
      selectService.onValueChange = (s) => {
        serviceQuery.serviceType = s.value;
      };
      this.serviceType = selectService;
    }

    // construct this.resourceType if the mode can Drill down by Resource
    if (modeIsIn(canFilterByResourceType) && this.query.hasOwnProperty('resourceType')) {
      const resourceQuery = this.query as { resourceType: LogQueryResourceOption };
      const selectResource = new UISelectResource();
      selectResource.value = resourceQuery.resourceType;
      selectResource.onValueChange = (s) => {
        resourceQuery.resourceType = s.value;
      };
      this.resourceType = selectResource;
    }

    // Standard controls are specificed by the template and bind
    // directly onto properties of this.query.
  }

  get mode() {
    return this.logViewerController.mode;
  }

  get isLoading() {
    return this.logViewerController.state === 'loading';
  }

  get isFiltered() {
    return this.logViewerController.userHasSetSearchParameters;
  }

  /**
   * Returns true if user has made edits, e.g., edited any date or search query field, and therefore the UI is not in sync with the results.  (In this state, user should be able to press Search to execute the new search).
   */
  get isDirty() {
    const currentQuery = this.logViewerController.currentQuery;
    const isEditing = !this.query?.isEqual(currentQuery);
    return isEditing;
  }

  // @ts-expect-error (legacy code incremental fix)
  private _queryTimeRange: TimeRange;

  get queryTimeRange() {
    return this._queryTimeRange;
  }

  set queryTimeRange(newValue) {
    this._queryTimeRange = newValue;
    this.enterKeyPressed(); // FIXME: rename this meth now
  }

  relativeTimeRangeOptions = LogViewerDateTimeRanges.defaultRelativeTimeRangeOptions;

  onQueryTimeRangeChange(newTimeRange: TimeRange) {
    const newDateRange = DateRange.fromTimeRange(newTimeRange);

    console.log(`onQueryTimeRangeChange: new date range:`, newDateRange);
    this.query.from = newDateRange.from;
    this.query.to = newDateRange.to;
    this.enterKeyPressed();
  }

  selectApiKind = new UiSelect<string>(LogViewerAllowedApiList);

  @Output() search = new EventEmitter<LogViewerQueryEditorComponent['query']>();

  @Output() reset = new EventEmitter(); // FIXME: needs to be hooked up

  currentSearchRangeDescription = 'Last 7 days';

  updateCurrentSearchRangeDescription(secondsBeforeNowToNow: number) {
    const minutes = secondsBeforeNowToNow / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    if (days >= 1.9) {
      this.currentSearchRangeDescription = `Last ${days} days`;
    } else if (hours >= 1) {
      this.currentSearchRangeDescription = `Last ${hours} hours`;
    } else {
      this.currentSearchRangeDescription = `Last ${minutes} minutes`;
    }
  }

  enterKeyPressed() {
    this.debug('enterKeyPressed(): query:', this.query);
    this.currentSearchRangeDescription = 'FIXME: show human-readable date range';
    this.doSearch();
  }

  filterButtonClicked() {
    this.debug('filterButtonClicked(): query:', this.query);
    this.currentSearchRangeDescription = 'FIXME: show human-readable date range';
    this.doSearch();
  }

  doSearch() {
    this.debug('doSearch()', this.query);

    // A short-term hack, we are going to make a real component out of it but for now we need to hide the menu with JS, so I just get the first .x-log-viewer-time-range-edit and hide it:
    const details = document.getElementsByClassName('x-log-viewer-time-range-edit')[0];
    if (details) {
      (details as any).open = false;
    }

    this.logViewerController.state = 'loading';
    this.search.emit(this.query);
  }

  doReset() {
    this.logViewerController.state = 'loading';
    this.reset.emit(this.query);
  }
}
