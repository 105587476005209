import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import Clipboard from 'clipboard';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { AbstractController } from '@soracom/shared-ng/soracom-ui-legacy';
import { LogViewerController } from '../log-viewer/log-viewer.component';

@Component({
  selector: 'app-log-viewer-controls',
  templateUrl: './log-viewer-controls.component.html',
})
export class LogViewerControlsComponent extends AbstractController {
  /**
   * The parent component (app-log-viewer) implements this interface so we can observe certain things about the parent state.
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() logViewerController: LogViewerController;

  @Output() copyCsv = new EventEmitter();
  @Output() copyText = new EventEmitter();
  @Output() copyJson = new EventEmitter();
  @Output() requestDownloadLink = new EventEmitter();

  constructor(logger: Logger, private featureVisibilityService: FeatureVisibilityService) {
    super(logger);
  }

  // @ts-expect-error (legacy code incremental fix)
  textClipboard: Clipboard;
  // @ts-expect-error (legacy code incremental fix)
  csvClipboard: Clipboard;
  // @ts-expect-error (legacy code incremental fix)
  jsonClipboard: Clipboard;

  ngOnInit() {
    super.ngOnInit();

    this.textClipboard = new Clipboard('button.copy-as-text', { text: () => this.selectionAsText });
    this.csvClipboard = new Clipboard('button.copy-as-csv', { text: () => this.selectionAsCSV });
    this.jsonClipboard = new Clipboard('button.copy-as-json', { text: () => this.selectionAsJSON });
  }

  ngOnDestroy() {
    this.textClipboard.destroy();
    this.csvClipboard.destroy();
    this.jsonClipboard.destroy();
  }

  get selectionAsText() {
    if (!this.logViewerController.selection) {
      return 'error: unable to copy';
    } else {
      return this.logViewerController.selection.map((e) => e.textRepresentation).join('\n');
    }
  }

  get selectionAsCSV() {
    if (!this.logViewerController.selection) {
      return 'error: unable to copy';
    } else {
      return this.logViewerController.selection.map((e) => e.csvRepresentation).join('\n');
    }
  }

  get selectionAsJSON() {
    if (!this.logViewerController.selection) {
      return 'error: unable to copy';
    } else {
      return this.logViewerController.selection.map((e) => e.jsonRepresentation).join('\n');
    }
  }

  get copyButtonsShouldBeDisabled() {
    const selection = this.logViewerController?.selection;
    return !selection || selection.length < 1;
  }

  get shouldShowRequestDownloadLink() {
    return this.logViewerController.mode === 'auditLogs' || this.logViewerController.mode === 'testAuditLogs';
  }
}
