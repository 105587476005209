/**
 * Contract value represents current Lagoon subscription
 */
export enum LagoonSubscription {
  maker = 'lagoon_maker',
  free = 'lagoon_free',
  pro = 'lagoon_pro',
  none = 'none', // unsubscribed
}

/**
 * API value to specify a Lagoon plan to subscribe
 */
export enum LagoonPlan {
  maker = 'maker',
  free = 'free',
  pro = 'pro',
}

export function LagoonSubscriptionToPlan(sub: LagoonSubscription): LagoonPlan {
  switch (sub) {
    case LagoonSubscription.pro:
      return LagoonPlan.pro;
    case LagoonSubscription.maker:
      return LagoonPlan.maker;
    case LagoonSubscription.free:
      return LagoonPlan.free;
    default:
      // @ts-expect-error (legacy code incremental fix)
      return null;
  }
}
