import { APP_INITIALIZER, Injectable, inject, Injector } from '@angular/core';
import { ONE_MINUTE } from '@soracom/shared/core';
import { AuthService, TokenExpirationWatcher } from '@soracom/shared/data-access-auth';
import { filter } from 'rxjs';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
/**
 * This service is used to preload data before the app is loaded. Originally added to init core services before the app is loaded. However, the core services were then updated to do this themselves, so this service is no longer needed for that. However, I am keeping it as the generic ability to do arbitrary async work before app loads is very useful — especially, potentially, in a testing context.
 */
@Injectable()
export class PreloadService {
  /**
   * We can add anything we want to the load() method, but keep in mind that it will block the app from loading until the promise is resolved. So don't do anything slow here, unless it is for e.g. test setup and won't harm production.
   */
  async load(): Promise<boolean> {
    // await sleep(500);
    return true;
  }
}

// FIXME: FIXME_BEFORE_MERGE
const tokenExpirationWarning = 5 * ONE_MINUTE;
// const tokenExpirationWarning = 2 * ONE_MINUTE;

const setVisualBlur = (blurredOrNot: boolean, targetCssClass: string = 'ds-app__main') => {
  const rawElement: any = document.getElementsByClassName(targetCssClass)?.[0];
  const rawElementStyle = rawElement?.style;
  if (!rawElementStyle) {
    return;
  }
  if (blurredOrNot) {
    rawElementStyle.filter = 'blur(4px)';
  } else {
    rawElementStyle.filter = 'none';
  }
};

/**
 * When token expiration gets near to expiration, show a modal dialog
 */
export function setupTokenExpirationWarningHandler() {
  const authSvc = inject(AuthService);
  const modalSvc = inject(UiDsModalService);
  const tokenExpirationWatcher = inject(TokenExpirationWatcher);

  return () => {
    tokenExpirationWatcher.getExpirationWarning$(tokenExpirationWarning).subscribe(async () => {
      modalSvc.openConfirmModal('templates.token_notification_modal.description', {
        title: 'templates.token_notification_modal.title',
        hideCancelButton: true,
        okButtonTitleId: 'templates.token_notification_modal.renewButton',
        onOkClick: () => authSvc.renewToken(),
        showLoadingProgressOnOkClick: true,
        disableEscKeyClose: true,
      });
    });
  };
}

/**
 * when token expires, show a dialog and disallow further operation unless reload
 * @returns
 */
export function setupTokenExpirationHandler() {
  const modalSvc = inject(UiDsModalService);
  const authService = inject(AuthService);
  return () => {
    authService.authEvent$.pipe(filter((ev) => ev.type === 'expired')).subscribe(() => {
      setVisualBlur(true);

      modalSvc.closeAllModals();

      const url = new URL(window.location.href);
      const currentPath = url.pathname;
      if (currentPath === '/login') {
        return;
      }

      modalSvc.openConfirmModal('templates.token_expiration_modal.description', {
        title: 'templates.token_expiration_modal.title',
        hideCancelButton: true,
        okButtonTitleId: 'templates.token_expiration_modal.reload_button',
        onOkClick: async () => {
          // no need to setVisualBlur(false) here because the page will be reloaded
          window.location.reload();
        },
        disableEscKeyClose: true,
      });
    });
  };
}

export function preloadServiceFactory(svc: PreloadService) {
  return () => svc.load();
}

export const providePreloadService = () => {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: setupTokenExpirationHandler,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupTokenExpirationWarningHandler,
      multi: true,
    },
    PreloadService,
    {
      provide: APP_INITIALIZER,
      useFactory: preloadServiceFactory,
      deps: [PreloadService],
      multi: true,
    },
  ];
};
