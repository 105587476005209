import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';

import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { ApiEvent } from '../core/api_event';
import { HarvestContract } from '../core/harvest_contract';
import { InjectList } from '../core/injectable';
import { HarvestDataService } from '../harvest_data/harvest_data.service';
import { template } from './harvest_contract.component.html';

export class HarvestContractComponent implements ng.IComponentOptions {
  controller = HarvestContractComponentController;
  template: any = template;
}

export class HarvestContractComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$rootScope', '$uibModal', 'AlertsService', 'HarvestDataService'];

  constructor(
    $log: LegacyAny,
    private $rootScope: ng.IRootScopeService,
    private $uibModal: LegacyAny,
    alertsService: AlertsService,
    private dataService: HarvestDataService
  ) {
    super($log);
    this.alertsServiceInstance = alertsService.generate();
  }

  alertsServiceInstance: AlertsServiceInstance;
  // @ts-expect-error (legacy code incremental fix)
  private currentContract: HarvestContract;
  // @ts-expect-error (legacy code incremental fix)
  selectedContract: HarvestContract;
  availableContracts: HarvestContract[] = [];
  isLoading = true;

  $onInit() {
    this.$rootScope.$on(ApiEvent.CoverageTypeChanged, () => {
      this.loadContract();
    });
    this.loadContract();
  }

  loadContract() {
    this.isLoading = true;

    this.selectedContract = this.dataService.getContract();
    this.debug(this.selectedContract);
    this.currentContract = angular.copy(this.selectedContract);
    if (this.currentContract.plan === 'custom') {
      this.availableContracts = [HarvestContract.normal, HarvestContract.extended, this.currentContract];
    } else {
      this.availableContracts = [HarvestContract.normal, HarvestContract.extended];
    }

    this.isLoading = false;
  }

  canSubmit(): boolean {
    return !this.isLoading && !this.selectedContract.equals(this.currentContract);
  }

  updateContract() {
    if (!this.canSubmit()) {
      return;
    }

    const modal = this.$uibModal.open({
      backdrop: 'static',
      component: 'scUpdateContractModalComponent',
      resolve: {
        current: () => this.currentContract,
        selected: () => this.selectedContract,
      },
    });

    modal.result.then(
      (newContract: HarvestContract) => {
        if (!this.canSubmit()) {
          return;
        }

        if (this.selectedContract.plan === HarvestContract.normal.plan) {
          this.dataService
            .unsubscribe()
            .then((_res: LegacyAny) => {
              this.currentContract.plan = this.selectedContract.plan;
              this.currentContract.ttl = this.selectedContract.ttl;
              this.alertsServiceInstance.showInfo('harvest_contract_component.disabled_successfully');
            })
            .catch((e: LegacyAny) => {
              this.alertsServiceInstance.showError(e);
            });
        } else {
          this.dataService
            .subscribe()
            .then((_res: LegacyAny) => {
              this.currentContract.plan = this.selectedContract.plan;
              this.currentContract.ttl = this.selectedContract.ttl;
              this.alertsServiceInstance.showInfo('harvest_contract_component.enabled_successfully');
            })
            .catch((e: LegacyAny) => {
              this.alertsServiceInstance.showError(e);
            });
        }
      },
      () => {
        angular.noop();
      }
    );
  }

  translationData(contract: HarvestContract): { ttl: string } {
    return { ttl: contract.ttl.toString() };
  }
}
