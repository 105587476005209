import { Directive } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { SUBSCRIBER_TAG_NAME_GADGET } from '../../shared/lte_m_button/LteMButtonConfig';
import { SubscriberBatchUpdater } from '../../shared/SubscriberBatchUpdater';

@Directive()
export class SubscriberLteMButtonConfigDeleter extends SubscriberBatchUpdater {
  /**
   * This constructor looks useless, but is is required for Angular 15 compatibility. (Because the superclass has legacy dependencies which are not compatible with Angular DI.)
   */
  constructor(logger: Logger, public apiService: SoracomApiService, subscribers: ExtendedSubscriberInterface[]) {
    super(logger, apiService, subscribers);
  }

  updateFunction(subscriber: ExtendedSubscriberInterface): Promise<any> {
    const imsi = subscriber.imsi;

    return this.apiService.deleteTag(imsi, SUBSCRIBER_TAG_NAME_GADGET).then(() => {
      return this.apiService.unsetSubscriberGroup(imsi);
    });
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.newGroup`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    return this.getGroupName(subscriber.groupId);
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    return LegacyTextContent.translation('generic.noGroup'); // "(no group)"
  }
}
