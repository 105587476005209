import { TableCellFormatter } from '../shared/TableCellFormatter';
import { CopyableLogEntry, LogEntryWrapper } from './LogEntryWrapper';
import { NapterAuditLogParams } from './NapterAuditLogEntry';

export class NapterAuditLogEntryWrapper implements LogEntryWrapper, CopyableLogEntry {
  // 🌸
  readonly createdAt: string;
  readonly createdAtForDL: string;
  readonly imsi: string; // column where small filter control is appended
  readonly type: string;
  readonly direction: string;
  readonly openRawData: string; // button for additional details is in td at the end of the row

  /**
   * The legacy implementations use NapterAuditLogEntry, but that really serves
   * the same basic purpose as this class. `NapterAuditLogParams` is the API
   * response format, so that is what we wrap here.
   *
   * (If nobody other than the legacy log viewer implementations are using
   * NapterAuditLogEntry then we might delete him.)
   */
  constructor(readonly logEntry: NapterAuditLogParams, public selected = false) {
    this.createdAt = TableCellFormatter.dateStringWithBrowserTZOffset(logEntry.createdAt);
    this.createdAtForDL = TableCellFormatter.dateStringWithUTCOffset(logEntry.createdAt);
    this.imsi = logEntry.imsi || '';
    // TODO(ethan): add this filter button to the imsi column
    //   a.filter-icon(href="" ng-click="$ctrl.filterResource(row.obj)" ng-if="!$ctrl.doesFilterResource(row.obj)")
    //     i.glyphicon.glyphicon-filter

    this.type = logEntry.type;

    this.direction = TableCellFormatter.napterDirectionSummary(logEntry);

    // TODO(ethan): ⬇
    this.openRawData = '';
    //   button.btn-xs.btn-default(ng-click="$ctrl.openRawData(row.obj)")
    //     i.glyphicon.glyphicon-option-horizontal
  }

  /**
   * @see CopyableLogEntry
   */
  get fieldValues() {
    // prettier-ignore
    return [
      this.createdAtForDL,
      this.imsi,
      this.type,
      this.direction,
    ];
  }

  /**
   * @see CopyableLogEntry
   */
  get textRepresentation() {
    return this.fieldValues.join(' ');
  }

  /**
   * @see CopyableLogEntry
   */
  get csvRepresentation() {
    return this.fieldValues.join(',');
  }

  get jsonRepresentation() {
    return JSON.stringify(this.logEntry, null, 2);
  }
}
