<section dsDialogContent>
  <div class="ds-rows" *ngIf="state === 'plan'">
    <app-lagoon-plan (onSubscriptionChanged)="setPlan($event)"></app-lagoon-plan>
    <div class="ds-field" *ngIf="debugMode">
      <label class="ds-checkbox">
        <input type="checkbox" id="lagoon-version-switch" [(ngModel)]="classic" />
        <div translate="RegisterLagoonModalComponent.create_classic"></div>
      </label>
    </div>
  </div>
  <div class="ds-rows" *ngIf="state === 'password'">
    <div
      class="ds-notice--warning"
      [innerHTML]="'RegisterLagoonModalComponent.text' | translate: translationValues"
    ></div>
    <div class="ds-field">
      <label class="ds-label">{{ 'RegisterLagoonModalComponent.plan' | translate }}</label>
      <div class="ds-text--inset">{{ planName }}</div>
    </div>
    <ui-password-confirm (passwordChange)="userPasswordChanged($event)"></ui-password-confirm>
  </div>
  <app-alerts [alertsManager]="alertsManager"></app-alerts>
</section>

<footer dsDialogFooter>
  <button class="ds-button x-confirm --primary" [ngClass]="{'--loading': isLoading}" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'RegisterLagoonModalComponent.submit' | translate"></span>
  </button>
  <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()">
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>

