export const template = `<app-user-notifications></app-user-notifications>
<main class="ds-app__main-container" ng-class="$ctrl.getRootCssClass()">
  <section class="ds-app__main-content ds-rows --no-gap">
    <header class="ds-banner --plain --indent-small">
      <div>
        <h2>{{ $ctrl.getPageTitleKey() | translate }}</h2>
        <div class="alerts-wrapper"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
      </div>
    </header>
    <div class="ds-span--4" ng-if="$ctrl.activeGroup">
      <div class="ds-rows --indent --no-v-indent" ng-if="$ctrl.isLoading()"><button
          class="ds-button --primary --icon-plus" id="group-add-button-loading" ng-click="$ctrl.addGroup()"
          ng-disabled="true"><span>{{ 'groups.add' | translate }}</span></button></div>
      <div class="ds-rows --indent --no-v-indent --sticky --offset-20"
        ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.getCount() &gt;= 1">
        <div class="ds-bar"><button class="x-add-additional-group-button ds-button --primary --icon-plus"
            id="group-add-button" ng-click="$ctrl.addGroup()"><span>{{ 'groups.add' | translate
              }}</span></button><button class="x-reload-groups-button ds-button --icon-rotate-ccw --hide-label"
            ng-click="$ctrl.reloadGroups()"><span>{{ 'groups.reload' | translate }}</span></button></div>
        <app-group-table [groups]="$ctrl.groups | orderGroupByName" [active-group]="$ctrl.activeGroup"
          (select-group)="$ctrl.onClick($event)"></app-group-table>
      </div>
      <div class="ds-banner --indent-large --plain --center"
        ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.getCount() === 0"><i
          class="ds-icon --icon-group --xxmassive --color-celeste-lighter"></i>
        <div>
          <h1>{{ 'groups.table.no_data' | translate }}</h1>
          <div class="ds-rows">
            <h2 class="ds-text --xlarge">{{ 'groups.table.description' | translate }}</h2>
            <div class="ds-cols --gap-xsmall"><a class="ds-button --small"
                ng-href="{{'service_descriptions.air_cellular'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.air_cellular' + '.name' | translate }}</span></a><a
                class="ds-button --small"
                ng-href="{{'service_descriptions.beam'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.beam' + '.name' | translate }}</span></a><a
                class="ds-button --small"
                ng-href="{{'service_descriptions.endorse'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.endorse' + '.name' | translate }}</span></a><a
                class="ds-button --small"
                ng-href="{{'service_descriptions.funnel'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.funnel' + '.name' | translate }}</span></a><a
                class="ds-button --small"
                ng-href="{{'service_descriptions.harvest'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.harvest' + '.name' | translate }}</span></a></div>
            <div><button class="x-add-first-group-button ds-button --primary --color-celeste --large"
                id="group-add-button" ng-click="$ctrl.addGroup()">{{ 'groups.table.create' | translate }}</button></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ds-span--8" ng-if="$ctrl.activeGroup">
      <div class="ds-rows --indent --no-v-indent"><sc-group-details air-type="$ctrl.airType"
          group-id="$ctrl.activeGroupId"></sc-group-details></div>
    </div>
    <div class="ds-span--12" ng-if="!$ctrl.activeGroup">
      <div class="ds-rows --indent --no-v-indent" ng-if="$ctrl.isLoading()"><button
          class="ds-button --primary --icon-plus" id="group-add-button-loading" ng-click="$ctrl.addGroup()"
          ng-disabled="true"><span>{{ 'groups.add' | translate }}</span></button></div>
      <div class="ds-rows --indent --no-v-indent --sticky --offset-20"
        ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.getCount() &gt;= 1">
        <div class="ds-bar"><button class="x-add-additional-group-button ds-button --primary --icon-plus"
            id="group-add-button" ng-click="$ctrl.addGroup()"><span>{{ 'groups.add' | translate
              }}</span></button><button class="x-reload-groups-button ds-button --icon-rotate-ccw --hide-label"
            ng-click="$ctrl.reloadGroups()"><span>{{ 'groups.reload' | translate }}</span></button></div>
        <app-group-table [groups]="$ctrl.groups | orderGroupByName" [active-group]="$ctrl.activeGroup"
          (select-group)="$ctrl.onClick($event)"></app-group-table>
      </div>
      <div class="ds-banner --indent-large --plain --center"
        ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.getCount() === 0"><i
          class="ds-icon --icon-group --xxmassive --color-celeste-lighter"></i>
        <div>
          <h1>{{ 'groups.table.no_data' | translate }}</h1>
          <div class="ds-rows">
            <h2 class="ds-text --xlarge">{{ 'groups.table.description' | translate }}</h2>
            <div class="ds-cols --gap-xsmall"><a class="ds-button --small"
                ng-href="{{'service_descriptions.air_cellular'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.air_cellular' + '.name' | translate }}</span></a><a
                class="ds-button --small"
                ng-href="{{'service_descriptions.beam'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.beam' + '.name' | translate }}</span></a><a
                class="ds-button --small"
                ng-href="{{'service_descriptions.endorse'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.endorse' + '.name' | translate }}</span></a><a
                class="ds-button --small"
                ng-href="{{'service_descriptions.funnel'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.funnel' + '.name' | translate }}</span></a><a
                class="ds-button --small"
                ng-href="{{'service_descriptions.harvest'+&quot;.links.overview&quot; | translate }}"
                target="_blank"><span>{{ 'service_descriptions.harvest' + '.name' | translate }}</span></a></div>
            <div><button class="x-add-first-group-button ds-button --primary --color-celeste --large"
                id="group-add-button" ng-click="$ctrl.addGroup()">{{ 'groups.table.create' | translate }}</button></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>`;
