import { LegacyAny } from '@soracom/shared/core';

import { AfterViewInit, Component, DestroyRef, Inject, Input, inject } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { assertNever } from '@soracom/shared/core';
import { NapterAuditLogsService } from '../../../../app/shared/audit_logs/napter_audit_logs.service';
import { PaginationLinkParser } from '@user-console/legacy-soracom-api-client';
import { PaginationOptions } from '../../../../app/shared/components/paginator';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { ApiEvent } from '../../../../app/shared/core/api_event';
import { LogsService } from '../../../../app/shared/logs/logs.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { AbstractController } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { SoracomUserConsole } from '../../shared/SoracomUserConsole';
import { AuditLogEntryWrapper } from '../AuditLogEntryWrapper';
import { ErrorLogEntryWrapper } from '../ErrorLogEntryWrapper';
import { LogEntryWrapper } from '../LogEntryWrapper';
import { LogModuleSharedAlertsManager } from '../LogModuleSharedAlertsManager';
import { AuditLogQuery, ErrorLogQuery, LogQuery, NapterAuditLogsQuery } from '../LogViewerQueryTypes';
import { LogViewerSubscriptionModalService } from '../LogViewerSubscriptionModalService';
import { NapterAuditLogEntryWrapper } from '../NapterAuditLogEntryWrapper';
import { PaginatedLogEntryList } from '../PaginatedLogEntryList';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * These modes are exposed to child components.
 */
export type LogViewerControllerMode =
  | 'auditLogs'
  | 'testAuditLogs'
  | 'errorLogs'
  | 'testErrorLogs'
  | 'napterAuditLogs'
  | 'testNapterAuditLogs';

/**
 * Minimally define the interface of a top-level table controller.  Adhering to
 * this makes the LogViewer compatible with the reusable view for this component,
 * as well as some reusable child components.
 */
export interface LogViewerController {
  /**
   * The mode comes from the route. There will in the end be 3 real modes:
   * auditLogs, napterAuditLogs, and errorLogs.
   */
  mode: LogViewerControllerMode;

  /**
   * The state of this top-level parent component is observed by children (e.g.
   * to disable their controls while loading).
   *
   * One might consider 'empty' one of these states, but there is probably only
   * one component who treats 'empty' and 'ready' differently, and checking
   * 'ready' and 'currentPage.length == 0' is easy enough.
   */
  state: 'loading' | 'ready' | 'error';

  /**
   * The UI may want to do subtly different things in both 'loading' and 'ready'
   * states, depending if it is the first page load, or a subsequent load after
   * the user has set parameters.  Let's signal that explicitly, rather than
   * some indirect signal (like "currentPage will be falsey for the first load").
   */
  firstLoadState: 'first' | 'subsequent';

  /**
   * userHasSetSearchParameters is false when a LogViewer is initially constructted.
   * If the user changes the search criteria, it changes to true.
   * If the user then presses the reset search button, it becomes false again.
   */
  userHasSetSearchParameters?: boolean;

  /**
   * Enables a child component to request displaying a single log record (in a modal, currently).
   */
  onShowLogDetail(value: { event: Event; record: LogEntryWrapper }): LegacyAny;

  /**
   * This is the query object passed from the query editor to the parent root component, when the user presses Search.
   */
  currentQuery: LogQuery;

  /**
   * An array indicating the currently selected log entries. Empty array means no selection.
   */
  selection: LogEntryWrapper[];
}

@Component({
  selector: 'app-log-viewer',
  templateUrl: './log-viewer.component.html',
  providers: [LogViewerSubscriptionModalService], // see comment about local providers in LogViewerSubscriptionModalService
})
export class LogViewerComponent extends AbstractController implements LogViewerController, AfterViewInit {
  private destroyRef = inject(DestroyRef);

  initialPerPageCount = 10;

  /**
   * This component implements the LogViewerController interface so that its
   * children can observe the viewer properties (using bindings in this
   * components template).  The interface properties are kept up to date by
   * listening to change events fired by the children.
   */
  logViewerController = this;

  /**
   * @inheritdoc
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() mode: LogViewerControllerMode;

  /**
   * @inheritdoc
   */
  state: 'loading' | 'ready' | 'error' = 'loading';

  /**
   * @inheritdoc
   */
  // @ts-expect-error (legacy code incremental fix)
  firstLoadState: 'first' | 'subsequent';

  /**
   * @inheritdoc
   */
  currentPage?: PaginatedLogEntryList;

  /**
   * @inheritdoc
   */
  // @ts-expect-error (legacy code incremental fix)
  currentQuery: LogQuery;

  /**
   * @inheritdoc
   */
  userHasSetSearchParameters?: boolean;

  /**
   * Napter logs mode shows a little text in the header, with a link allowing
   * the user to trigger a modal where they can subscribe or unsubscribe from
   * Napter Logs.  The modal components are rendered as children of this
   * top-level component.  The reason for _that_ is some CSS thing.
   *
   * The subscription modal service is setup for the day when more than one
   * subscription model exists.  But for now, there is only subscribe /
   * unsubscribe for the napter logs.  And, both of those are handled by a
   * single component.  So, this state can be simplified to a boolean to make
   * ngIf's job easier.
   */
  showSubscriptionModal = false;

  constructor(
    logger: Logger,
    private apiService: SoracomApiService,
    private errorLogsService: LogsService,
    private napterAuditLogsService: NapterAuditLogsService,
    private subscriptionModals: LogViewerSubscriptionModalService,
    @Inject(LogModuleSharedAlertsManager) readonly alertsManager: AlertsManager,
  ) {
    super(logger);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.debug('INITIALIZED WITH MODE', this.mode, this);
    this.subscriptionModals.init();
    this.loadData(this.defaultSearchQuery);
    this.subscriptionModals.currentModal.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((m) => {
      this.showSubscriptionModal = !!m;
    });

    const angularJsRootScope = SoracomUserConsole.legacyRootScope;
    angularJsRootScope.$on(ApiEvent.CoverageTypeChanged, () => this.reloadCurrentSearch());
  }

  /**
   * If the user has not set criteria then we just show all log entries, with no date filter.
   */
  // @ts-expect-error (legacy code incremental fix)
  get defaultSearchQuery(): LogQuery {
    const mode = this.mode;

    switch (mode) {
      case 'auditLogs':
      case 'testAuditLogs':
        return new AuditLogQuery();

      case 'errorLogs':
      case 'testErrorLogs':
        return new ErrorLogQuery();

      case 'napterAuditLogs':
      case 'testNapterAuditLogs':
        return new NapterAuditLogsQuery();

      default:
        assertNever(mode);
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  lastUsedQuery: LogQuery;

  /**
   * Fetch the data via the API.
   */
  async loadData(query: LogQuery = this.defaultSearchQuery, pageInfo?: { page: number; lastEvaluatedKey?: string }) {
    //
    this.state = 'loading';

    this.lastUsedQuery = query;

    // Get the data from the API, differently depending on this.mode:
    try {
      // Get the dates, if the user entered any dates:
      const from = query.from?.getTime() || null;
      const to = query.to?.getTime() || null;

      // Get the pagination state, if we have a current page, otherwise we will be fetching the first page:
      const currentPaginationState = this.currentPage?.paginationState;
      const perPageCount = currentPaginationState?.perPageCount || this.initialPerPageCount;

      // This type of pagination options is only needed by the error log and napter audit log API calls.
      const paginationOptions: PaginationOptions = { limit: perPageCount };
      if (pageInfo?.lastEvaluatedKey) {
        paginationOptions.last_evaluated_key = pageInfo.lastEvaluatedKey;
      }

      // FIXME: this will get first page only... but I now think our new PaginatedData has everything we need to get next/prev page?

      let fetchPromise: any;
      // let data: any[];
      // let links: HttpLinkHeader;
      let entries: LogEntryWrapper[];

      if (this.mode === 'auditLogs') {
        fetchPromise = this.apiService
          .getAuditLogs((query as AuditLogQuery).apiKind, from, to, perPageCount, pageInfo?.lastEvaluatedKey)
          .then((response: LegacyAny) => {
            // Mason 2021-04-13: We don't have a service to fetch Audit Logs, and I don't want to make one with a bunch of AngularJS dependencies (since this code should soon move to all-Angular modern console). So we have to do a little bit of extra work in this case. The two other cases below parse out the pagination links for us.
            const linkHeader = response?.headers?.link || '';
            const linkParser = new PaginationLinkParser();

            // Mimic the result the AngularJS paginated services below return:
            return {
              data: response?.data,
              links: linkParser.getPaginationLinks(linkHeader),
            };
          });
      } else if (this.mode === 'errorLogs') {
        // convert query to what this list() expects:
        // e.g. :path: /v1/logs?limit=10&from=1621831720991&to=1623078000000&resource_type=Subscriber&resource_id=123&service=Beam&search_type=and

        const errorLogQuery = query.toSearchQuery();
        fetchPromise = this.errorLogsService.list(paginationOptions, errorLogQuery);
      } else if (this.mode === 'napterAuditLogs') {
        // convert query to what this list() expects:
        // e.g. :path: /v1/audit_logs/napter?last_evaluated_key=1621935385032&limit=25&from=1620443769713&to=1623122169713&resource_type=Subscriber&resource_id=295101099990031

        const napterSearchQuery = query.toSearchQuery();
        fetchPromise = this.napterAuditLogsService.list(paginationOptions, napterSearchQuery);
      }

      const { data, links } = await fetchPromise;

      if (this.mode === 'auditLogs') {
        entries = data.map((e: LegacyAny) => new AuditLogEntryWrapper(e));
      } else if (this.mode === 'errorLogs') {
        entries = data.map((e: LegacyAny) => new ErrorLogEntryWrapper(e));
      } else if (this.mode === 'napterAuditLogs') {
        entries = data.map((e: LegacyAny) => new NapterAuditLogEntryWrapper(e));
      } else {
        throw new Error('Error: invalid mode in LogViewerComponent: ' + this.mode);
      }

      // Either this is our first page, or we will update the contents of the page:
      if (!this.currentPage) {
        this.currentPage = new PaginatedLogEntryList(perPageCount, entries, links);
      } else {
        // Although the existing this.currentPage has the correct data now, other objects (e.g. child components) bind to this.currentPage, so we need to update the page to a new instance every time we change the page, so that observers can take whatever action needed:
        const pageNumber = pageInfo ? pageInfo.page : 0;

        const newPage = new PaginatedLogEntryList(perPageCount, entries, links);
        newPage.setPageNumber(pageNumber, entries, links, this.currentPage);

        this.currentPage = newPage;
      }

      this.debug(this.currentPage);
    } catch (err) {
      this.error(err);
      this.alertsManager.add(Alert.danger(err));
    }
    this.state = 'ready';
  }

  reloadCurrentSearch() {
    // @ts-expect-error (legacy code incremental fix)
    this.loadData(this.lastUsedQuery, this.currentPage.currentPageInfo);
  }

  onCopyText() {
    // This is not guaranteed to have worked, but it works in any normal browser.
    setTimeout(() => {
      const msg = LegacyTextContent.translation('SoracomLogs.copiedAsText');
      this.alertsManager.add(Alert.success(msg));
    });
  }

  onCopyCsv() {
    // This is not guaranteed to have worked, but it works in any normal browser.
    setTimeout(() => {
      const msg = LegacyTextContent.translation('SoracomLogs.copiedAsCSV');
      this.alertsManager.add(Alert.success(msg));
    });
  }

  onCopyJson() {
    // This is not guaranteed to have worked, but it works in any normal browser.
    setTimeout(() => {
      const msg = LegacyTextContent.translation('SoracomLogs.copiedAsJSON');
      this.alertsManager.add(Alert.success(msg));
    });
  }

  onPerPageCountChange(perPage: number) {
    // @ts-expect-error (legacy code incremental fix)
    this.currentPage.paginationState.perPageCount = perPage;
    // debugger;
    this.loadData(this.lastUsedQuery, { page: 0, lastEvaluatedKey: undefined });
  }

  onRefresh() {
    this.reloadCurrentSearch();
  }

  onPreviousPage() {
    // @ts-expect-error (legacy code incremental fix)
    this.loadData(this.lastUsedQuery, this.currentPage.previousPageInfo);
  }

  onNextPage() {
    // @ts-expect-error (legacy code incremental fix)
    this.loadData(this.lastUsedQuery, this.currentPage.nextPageInfo);
  }

  onSearch(query: LogQuery) {
    this.loadData(query);
  }

  onRequestDownloadLink() {
    this.showRequestDownloadLinkModal = true;
  }

  /**
   * Normally `undefined`, but when `recordBeingInspected` exists then the modal `log-viewer-record-detail` component becomes visible and displays it.
   */
  recordBeingInspected: string | null = null;

  onShowLogDetail(value: { event: Event; record: LogEntryWrapper }) {
    this.recordBeingInspected = value.record.jsonRepresentation;
  }

  onCloseLogRecordDetailInspector() {
    this.recordBeingInspected = null;
  }

  onCloseNapterSubscriptionModal() {
    // Let the service know the modal wants to close, and this component will
    // hear back because it watches currentModal.  A bit round-about, but
    // it allows the ins and outs in the view to look the same as other modals.
    this.subscriptionModals.closeModal();
  }

  onFilterByResourceId(value: string) {
    alert(`onFilterByResourceId(${value})`);
  }

  selection: LogEntryWrapper[] = [];

  onSelectionChange(event: LogEntryWrapper[]) {
    this.debug('onSelectChange', event);
    this.selection = event;
  }

  showRequestDownloadLinkModal = false;

  downloadLinkRequestSuccess() {
    this.alertsManager.add(Alert.success('LogViewerComponent.message.downloadLinkRequested'));
  }

  onCloseRequestDownloadLinkModal() {
    this.showRequestDownloadLinkModal = false;
  }
}
