import { APP_INITIALIZER, Provider } from '@angular/core';
import {
  ApiCredentialsService,
  AuthService,
  CoverageTypeService,
  LoginUserDataService,
  TokenExpirationWatcher,
  getCoreService,
  CoreServiceOption,
  initCoreService,
  FeatureVisibilityService,
  SwitchUserStore,
} from '@soracom/shared/data-access-auth';

/**
 * Provide the coreService member services through Angular DI.
 * Make sure to call `initCoreServices` before calling this method,
 *  or you can use provideAndInitCoreService() to get everything done by 1 step
 */
export function provideCoreService(): Provider[] {
  const core = getCoreService();

  return [
    {
      provide: AuthService,
      useValue: core.AuthService,
    },
    {
      provide: TokenExpirationWatcher,
      useValue: core.TokenExpirationWatcher,
    },
    {
      provide: LoginUserDataService,
      useValue: core.LoginUserDataService,
    },
    {
      provide: ApiCredentialsService,
      useValue: core.ApiCredentialsService,
    },
    {
      provide: CoverageTypeService,
      useValue: core.CoverageTypeService,
    },
    {
      provide: FeatureVisibilityService,
      useValue: core.FeatureVisibilityService,
    },
    {
      provide: SwitchUserStore,
      useValue: core.SwitchUserStore,
    },
  ];
}

/**
 * Initialize the CoreService and provide member services through ANgular DI.
 * The initialization calls SSO API and await it before application bootstrap.
 *
 * @param option
 * @returns
 */
export function provideAndInitCoreService(
  option: CoreServiceOption
): Provider[] {
  initCoreService(option);

  return [
    {
      provide: APP_INITIALIZER,
      multi: true,
      // returns promise and then Angular will await it befor the application bootstrap
      useValue: () => getCoreService().initState(),
    },
    provideCoreService(),
  ];
}
