import { LegacyOptionalNullable } from '@soracom/shared/core';
import { Group, Groupable } from '@soracom/shared/group';
import { AlertsServiceInstance } from '../../../../app/shared/components/alerts.service';
import { GroupChooserOptions } from '../../../../app/shared/components/group_chooser.controller';
import { Component, OnInit } from '@angular/core';
import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SoracomGroupsModule } from '../soracom-groups.module';
@Component({
  selector: 'app-change-group-modal',
  templateUrl: './change_group_modal.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, SoracomGroupsModule],
})
export class ChangeGroupModalComponent
  extends LegacyUibModalAdapterModalBase<
    {
      targets: () => Groupable[];
    },
    { group: Group | null }
  >
  implements OnInit
{
  /** After init, this is the **instance** of AlertsService that can be used to display errors. */
  alertsService: LegacyOptionalNullable<AlertsServiceInstance> = null;

  selectedGroup: Group | null = null;
  // @ts-expect-error (legacy code incremental fix)
  groupChooserOptions: GroupChooserOptions;

  targets: any[] = [];

  /** flag that tracks whether submission is in progress (used e.g. to disable UI appropriately) */
  protected isSubmitting = false;

  ngOnInit() {
    this.groupChooserOptions = {
      showUnset: true,
      showCreate: false,
    };

    this.targets = this.resolve?.targets() ?? [];
  }

  canSubmit(): boolean {
    return !!this.selectedGroup;
  }

  cancel() {
    this.dismiss();
  }

  unset() {
    this.close({
      group: null,
    });
  }

  submit() {
    this.close({
      group: this.selectedGroup,
    });
  }
}
