import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { formatDateTime } from '@soracom/shared/util-common';
import { LocalStorageService } from '@soracom/forks/ngx-store';
import { HarvestData } from '../../../../../app/shared/core/harvest_data';
import { MapMarkerData, validateLatitude, validateLongitude } from '../../../map/map.type';
import { KeyValueLocalStorageService } from '../../../shared/key-value-local-storage.service';
import { HarvestDataStore } from '../../harvest-data.store';
import { MapDataParserConfig } from '../map-parser-setting-control/harvest-data-map-parser-setting-control.component';
import { HarvestDataChartPreviewPanelComponent } from '../preview-panel/harvest-data-chart-preview-panel.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface MapData extends MapMarkerData {
  data: HarvestData;
  latitudeKey: string;
  longitudeKey: string;
}

@Component({
  selector: 'app-harvest-data-map-container',
  templateUrl: './harvest-data-map-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HarvestDataMapContainerComponent {
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild(HarvestDataChartPreviewPanelComponent) preview: HarvestDataChartPreviewPanelComponent;

  // @ts-expect-error (legacy code incremental fix)
  _data: HarvestData[];
  // @ts-expect-error (legacy code incremental fix)
  mapData: MapData[];
  previewData: HarvestData[] = [];
  parserConfig: MapDataParserConfig | null = null;

  @Input() set data(data: HarvestData[]) {
    this._data = data;
    this.updateData();
  }

  parserConfigStorage: KeyValueLocalStorageService<MapDataParserConfig>;

  constructor(private svc: HarvestDataStore, private cd: ChangeDetectorRef, localStorage: LocalStorageService) {
    this.parserConfigStorage = new KeyValueLocalStorageService(localStorage, {
      storageKey: 'harvest-data-map-parser-config',
    });

    this.svc.resources$.pipe(takeUntilDestroyed()).subscribe(this.onResourcesChange);
  }

  onResourcesChange = (resources: LegacyAny) => {
    const configs = resources
      .map((r: LegacyAny) => r.resourceId)
      .map((id: LegacyAny) => this.parserConfigStorage.get(id))
      .filter((v: LegacyAny) => !!v);

    if (
      resources.length > 0 &&
      resources.length === configs.length &&
      configs.every((c: LegacyAny) => mapDataParserEquals(c, configs[0]))
    ) {
      this.parserConfig = configs[0];
      this.cd.markForCheck();
    }
  };

  updateData() {
    // @ts-expect-error (legacy code incremental fix)
    this.mapData = generateMapData(this._data, this.parserConfig);
    if (this.preview) {
      this.preview.close();
      this.previewData = [];
      this.cd.markForCheck();
    }
    this.svc.patchState({
      mapDisplayData: this.mapData,
    });
  }

  mapTooltipHtml = (d: MapData) => {
    return `
      <dl class="ds-details" style="background-color: rgb(var(--color-background))">
        <dt>ID</dt>
        <dd><div>${d.data.resourceId}</div></dd>
        <dt>Type</dt>
        <dd><div>${d.data.resourceType}</div></dd>
        <dt>Time</dt>
        <dd><div>${formatDateTime(d.data.time, 'datetime_sec')}</div></dd>
      </dl>
    `;
  };

  onParserConfigChange(c: MapDataParserConfig) {
    this.parserConfig = c;
    this.svc.resources.forEach((r) => {
      this.parserConfigStorage.save(r.resourceId, c);
    });
    this.updateData();
  }

  onClick(d: MapData) {
    this.previewData = [d.data];
    this.preview.open();
    this.cd.detectChanges();
  }
}

const LONGITUDE_REGEX = /\.?(lon|lng|long|longitude)$/;
const LATITUDE_REGEX = /\.?lat(itude)?$/;

function getMapData(harvestData: HarvestData, latitudeKey?: string, longitudeKey?: string): MapData | null {
  const d = harvestData?.chartData;
  if (!d) {
    return null;
  }

  latitudeKey = latitudeKey || Object.keys(d).find((key) => LATITUDE_REGEX.test(key));
  longitudeKey = longitudeKey || Object.keys(d).find((key) => LONGITUDE_REGEX.test(key));

  // @ts-expect-error (legacy code incremental fix)
  const lat = d[latitudeKey];
  // @ts-expect-error (legacy code incremental fix)
  const long = d[longitudeKey];

  if (validateLatitude(lat) && validateLongitude(long)) {
    // @ts-expect-error (legacy code incremental fix)
    return { data: harvestData, lat, long, latitudeKey, longitudeKey };
  }

  return null;
}

const generateMapData = (harvestData: HarvestData[], parserConfig: MapDataParserConfig): MapData[] => {
  return harvestData
    .map((v) => getMapData(v, parserConfig?.latitudeKey, parserConfig?.longitudeKey))
    .filter((v) => !!v);
};

const mapDataParserEquals = (a: MapDataParserConfig, b: MapDataParserConfig) =>
  a.latitudeKey === b.latitudeKey && a.longitudeKey === b.longitudeKey;
