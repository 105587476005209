import { BaseModel } from '@soracom/shared/soracom-platform';

export class PrefixedEventHandlerViewModelCollection extends BaseModel {
  SendMailToOperatorAction?: {
    DailyTrafficRule: PrefixedEventHandlerViewModel;
    CumulativeTrafficRule: PrefixedEventHandlerViewModel;
    MonthlyTotalTrafficRule: PrefixedEventHandlerViewModel;
    MonthlyTrafficRule: PrefixedEventHandlerViewModel;
  } = {
    DailyTrafficRule: new PrefixedEventHandlerViewModel(),
    CumulativeTrafficRule: new PrefixedEventHandlerViewModel(),
    MonthlyTotalTrafficRule: new PrefixedEventHandlerViewModel(),
    MonthlyTrafficRule: new PrefixedEventHandlerViewModel(),
  };

  ChangeSpeedClassAction?: {
    DailyTrafficRule: PrefixedEventHandlerViewModel;
    CumulativeTrafficRule: PrefixedEventHandlerViewModel;
    MonthlyTotalTrafficRule: PrefixedEventHandlerViewModel;
    MonthlyTrafficRule: PrefixedEventHandlerViewModel;
  } = {
    DailyTrafficRule: new PrefixedEventHandlerViewModel(),
    CumulativeTrafficRule: new PrefixedEventHandlerViewModel(),
    MonthlyTotalTrafficRule: new PrefixedEventHandlerViewModel(),
    MonthlyTrafficRule: new PrefixedEventHandlerViewModel(),
  };
}

export class PrefixedEventHandlerViewModel extends BaseModel {
  active = false;
  // @ts-expect-error (legacy code incremental fix)
  value: number;
  handlerId?: string;
  ignored?: boolean; // attribute for IMSI target type
}
