
  <section dsDialogContent>
    <div class="ds-field">
      <label class="ds-label" for="simaddtag-tagname" translate="add_tag_modal.tag_name"></label>
      <input
        class="ds-input" id="tag-name" type="text" [attr.placeholder]="'add_tag_modal.tag_name' | translate"
        name="tag-name" [(ngModel)]="tagParams.tagName" uiFocusM />
    </div>
    <div class="ds-field">
      <label class="ds-label" for="simaddtag-tagvalue" translate="add_tag_modal.tag_value"></label>
      <input
        class="ds-input" id="tag-value" type="text" [attr.placeholder]="'add_tag_modal.tag_value' | translate"
        name="tag-value" [(ngModel)]="tagParams.tagValue" />
    </div>
  </section>
  <footer dsDialogFooter>
    <button class="ds-button --primary"
      id="save-submit-button" (click)="submit()" [disabled]="submitting || !canSubmit()"
      translate="add_tag_modal.submit"></button>
    <button class="ds-button" id="save-cancel-button" (click)="cancel()"
      [disabled]="submitting" translate="add_tag_modal.cancel"></button>
  </footer>
