import { LegacyAny } from '@soracom/shared/core';

import { AlertsService, AlertsServiceInstance } from '../../components/alerts.service';
import { BaseController } from '../../components/base_controller';
import { SoracomApiService } from '../../components/soracom_api.service';
import { InjectList } from '../../core/injectable';
import {
  createDailyDeviceHarvestStatsAlert,
  EventHandler,
  ExecutionDateTimeConst,
  isDailyDeviceHarvestStatsAlert,
  isDailyStatsCountRule,
  isDeviceTargetType,
  isSendMailToOperatorAction,
} from '../event_handler';
import { template } from './device_harvest_usage_alert.component.html';

export class DeviceHarvestUsageAlertComponent implements ng.IComponentOptions {
  bindings = {
    device: '<',
  };

  controller = DeviceHarvestUsageAlertComponentController;
  template: any = template;
}

const translateNamespace = 'prefixed_event_handlers.handler_text.SendMailToOperatorAction.DeviceHarvestUsageAlert';
const translationKeys = [`${translateNamespace}.Subject`, `${translateNamespace}.Message`];
const defaultThreshold = 2000;

export class DeviceHarvestUsageAlertComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$translate', '$uibModal', 'AlertsService', 'SoracomApi'];

  // @ts-expect-error (legacy code incremental fix)
  alertsService: AlertsServiceInstance;

  // @ts-expect-error (legacy code incremental fix)
  threshold: number;
  // @ts-expect-error (legacy code incremental fix)
  title: string;
  // @ts-expect-error (legacy code incremental fix)
  message: string;
  enabled = false;

  // @ts-expect-error (legacy code incremental fix)
  private eventHandler: EventHandler;
  // TODO: Use Device class in parent component
  private device?: any;

  constructor(
    $log: ng.ILogService,
    private $translate: any,
    public $uibModal: any,
    private alertsServiceGenerator: AlertsService,
    private soracomApi: SoracomApiService
  ) {
    super($log);
  }

  $onInit() {
    this.setTraceEnabled(true);
    this.alertsService = this.alertsServiceGenerator.generate();
  }

  $onChanges(changesObj: LegacyAny) {
    /**
     * this.device is initialized by parent component after $onInit().
     * I have to watch the update and apply to this.. It's weird.
     * I guess I shouldn't get object in the component..?
     *
     * Yuta
     */
    this.trace(changesObj);
    if (changesObj.device.currentValue) {
      if (changesObj.device.previousValue === null || changesObj.device.previousValue === undefined) {
        this.fetchEventHandler();
      } else if (changesObj.device.previousValue.deviceId !== changesObj.device.currentValue.deviceId) {
        this.fetchEventHandler();
      }
    } else {
      this.trace('current device id is not changed.');
    }
  }

  private fetchEventHandler() {
    // @ts-expect-error (legacy code incremental fix)
    this.eventHandler = null;
    // TODO: Specify 'device'
    this.soracomApi
      .getEventHandlers()
      .then((res: LegacyAny) => {
        res.data.forEach((element: LegacyAny) => {
          const eventHandler = new EventHandler(element);
          if (isDailyDeviceHarvestStatsAlert(eventHandler)) {
            if (isDeviceTargetType(eventHandler.target) && eventHandler.target.deviceId === this.device.deviceId) {
              this.eventHandler = eventHandler;
              this.enabled = eventHandler.status === 'active';
              const ruleConfig = this.eventHandler.ruleConfig;
              if (isDailyStatsCountRule(ruleConfig)) {
                this.threshold = ruleConfig.properties.limitTotalStatsCount;
                const actionConfig = this.eventHandler.actionConfigList[0];
                if (isSendMailToOperatorAction(actionConfig)) {
                  this.title = actionConfig.properties.title;
                }
              }
            }
          }
        });
        if (this.eventHandler === null || this.eventHandler === undefined) {
          this.eventHandler = createDailyDeviceHarvestStatsAlert(this.device.deviceId);
          this.enabled = this.eventHandler.status === 'active';
          this.threshold = defaultThreshold;
          this.$translate(translationKeys, { value: this.threshold }).then((translations: LegacyAny) => {
            this.title = translations[translationKeys[0]];
          });
        }
        this.trace(this.eventHandler);
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  save() {
    const actionConfig = this.eventHandler.actionConfigList[0];
    this.eventHandler.status = this.enabled ? 'active' : 'inactive';
    this.$translate(translationKeys, { value: this.threshold })
      .then((translations: LegacyAny) => {
        if (isDailyStatsCountRule(this.eventHandler.ruleConfig)) {
          if (isSendMailToOperatorAction(actionConfig)) {
            actionConfig.properties.executionDateTimeConst = ExecutionDateTimeConst.IMMEDIATELY;
            actionConfig.properties.title = this.title;
            actionConfig.properties.message = translations[translationKeys[1]];
            this.eventHandler.ruleConfig.properties.limitTotalStatsCount = this.threshold;
          }

          if (this.eventHandler.handlerId) {
            return this.soracomApi
              .updateEventHandler(this.eventHandler)
              .then((res: LegacyAny) => {
                this.alertsService.showSuccess('device_harvest_usage_alert_component.success');
              })
              .catch((error: LegacyAny) => {
                this.alertsService.showError(error.data);
              });
          } else {
            return this.soracomApi
              .createEventHandler(this.eventHandler)
              .then((res: LegacyAny) => {
                this.alertsService.showSuccess('device_harvest_usage_alert_component.success');
                this.fetchEventHandler();
              })
              .catch((error: LegacyAny) => {
                this.alertsService.showError(error.data);
              });
          }
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  delete() {
    this.soracomApi
      .destroyEventHandler(this.eventHandler)
      .then((res: LegacyAny) => {
        this.alertsService.showSuccess('device_harvest_usage_alert_component.success');
        this.fetchEventHandler();
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error.data);
      });
  }
}
