import { LegacyAny } from '@soracom/shared/core';

import { decodeBase64EncodedUtf8Text } from '@soracom/shared/core';
import * as angular from 'angular';

export interface HarvestData {
  resourceType: string;
  resourceId: string;
  id: string;
  time: number;
  contentType: string;
  content: string;
  metadata?: { [key: string]: any };
  data?: any;
  name?: string;

  decodedData: LegacyAny;
  chartData: { [key: string]: number };
}

export class HarvestDataInstance {
  // @ts-expect-error (legacy code incremental fix)
  time: number;
  // @ts-expect-error (legacy code incremental fix)
  contentType: string;
  // @ts-expect-error (legacy code incremental fix)
  content: string;
  metadata?: { [key: string]: any };

  decodedData: LegacyAny;
  chartData: { [key: string]: number };

  constructor(public resourceType: string, public resourceId: string, params: any) {
    if (params) {
      this.time = params.time;
      this.contentType = params.contentType;
      this.content = params.content;
      this.metadata = params.metadata;
    }

    // Data manipulation step:
    // 1. Decode data (ascii2binary, hex2dec, hex2ascii)
    //   => decodedData
    // 2. Format data (Parse json and flatten, remove non-value attributes)
    //   => chartData
    // @ts-expect-error (legacy code incremental fix)
    this.decodedData = this._decodeData(this.content);
    if (this.metadata) {
      this.decodedData = { ...this.decodedData, $metadata: this.metadata };
    }
    this.chartData = this._formatDataForChart(this.decodedData);
  }

  get id(): string {
    return `${this.resourceType}_${this.resourceId}_${this.time}`;
  }

  _decodeData(content: LegacyAny) {
    let parsedData;
    if (content === null || content === undefined) {
      return content;
    }

    try {
      parsedData = JSON.parse(content);
      if (this._isCustomPayload(parsedData)) {
        return parsedData;
      } else if (this._isLoraPayload(parsedData)) {
        return this._decodeLoraPayload(parsedData);
      } else if (this._isSigfoxPayload(parsedData)) {
        return this._decodeSigfoxPayload(parsedData);
      } else if (this._isUdpPayload(parsedData)) {
        return this._decodeUdpPayload(parsedData);
      } else {
        return parsedData;
      }
    } catch (e) {
      return content;
    }
  }

  _isLoraPayload(obj: LegacyAny) {
    return (
      typeof obj === 'object' &&
      obj.hasOwnProperty('deveui') &&
      obj.hasOwnProperty('gatewayData') &&
      obj.hasOwnProperty('data')
    );
  }

  _decodeLoraPayload(obj: LegacyAny) {
    obj.data_hex2dec = this._hex2dec(obj.data);
    obj.data_hex2a = this._hex2a(obj.data);

    if (obj.data_hex2dec > 999999999999999999999) {
      delete obj.data_hex2dec;
    }

    return obj;
  }

  _isSigfoxPayload(obj: LegacyAny) {
    return (
      typeof obj === 'object' &&
      obj.hasOwnProperty('device') &&
      obj.hasOwnProperty('seqNumber') &&
      obj.hasOwnProperty('data')
    );
  }

  _isCustomPayload(obj: LegacyAny) {
    return typeof obj === 'object' && obj.binaryParserEnabled;
  }

  _decodeSigfoxPayload(obj: LegacyAny) {
    obj.data_hex2dec = this._hex2dec(obj.data);
    obj.data_hex2a = this._hex2a(obj.data);
    return obj;
  }

  _isUdpPayload(obj: LegacyAny) {
    if (typeof obj === 'object' && Object.keys(obj).length === 1 && obj.hasOwnProperty('payload')) {
      // true if obj.payload can be decoded as base64
      // this._a2b returns base64 decoded string if possible. Otherwise, it returns its raw argument
      return this._decodeBase64EncodedUtf8Text(obj.payload) !== obj.payload;
    }
    return false;
  }

  _decodeUdpPayload(obj: LegacyAny) {
    return {
      value: this._decodeBase64EncodedUtf8Text(obj.payload),
    };
  }

  _convertArrayForChart(obj: LegacyAny, hash: {} = {}) {
    // TODO: Implement
    return hash;
  }

  _formatDataForChart(orig: any, hash: LegacyAny = {}, prefix = ''): { [key: string]: number } {
    let json;

    if (prefix === 'value.') {
      prefix = '';
    }

    const numval = this._isNumber(orig);
    if (numval !== false) {
      hash[prefix + 'value'] = numval;
    } else if (Array.isArray(orig)) {
      hash = this._convertArrayForChart(orig, hash);
    } else if (typeof orig === 'object') {
      const obj = angular.copy(orig);
      if (obj !== null) {
        Object.keys(obj).forEach((key) => {
          const val = obj[key];
          const numval = this._isNumber(val);
          if (numval !== false) {
            if (numval !== Infinity && numval !== -Infinity) {
              hash[prefix + key] = numval;
            }
          } else if (typeof val === 'object') {
            // nested object process
            hash = this._formatDataForChart(val, hash, prefix + key + '.');
          } else {
            try {
              json = JSON.parse(val);
              if (typeof json === 'object') {
                hash = this._formatDataForChart(json, hash, prefix + key + '.');
              }
            } catch (e) {
              // Unknown type, ignore it
            }
          }
        });
      }
    }

    return hash;
  }

  _decodeBase64EncodedUtf8Text(a: LegacyAny) {
    try {
      return decodeBase64EncodedUtf8Text(a);
    } catch (e) {
      return a;
    }
  }

  _hex2dec(hexx: LegacyAny) {
    try {
      return parseInt(hexx, 16);
    } catch (e) {
      return hexx;
    }
  }

  _hex2a(hexx: LegacyAny) {
    try {
      const hex = hexx.toString();
      let str = '';
      for (let i = 0; i < hex.length; i += 2) {
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
      }
      return str;
    } catch (e) {
      return hexx;
    }
  }

  _isNumber(x: number | string): number | boolean {
    if (x === undefined || x === null) {
      return false;
    } else if (typeof x === 'number') {
      return x as number;
    } else if (typeof x === 'string') {
      if (x === '') {
        return false;
      }
      const parsed = Number(x);
      if (!isNaN(parsed)) {
        return parsed;
      }
      return false;
    } else {
      return false;
    }
  }
}
