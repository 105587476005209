<section dsDialogContent>
  <div class="x-form-contents">
    <div class="ds-rows">
      <div class="ds-text">{{ 'terminate_resource.sigfoxDeletedImmediatelySuccessModal.description.raw1' | translate }}</div>
      <dl class="ds-details">
        <dt>{{ 'terminate_resource.sigfoxDeletedImmediatelySuccessModal.description.deviceId' | translate }}</dt>
        <dd>
          <div>{{ afterDeleteResponse.deviceId }}</div>
        </dd>
        <dt>{{ 'terminate_resource.sigfoxDeletedImmediatelySuccessModal.description.PAC' | translate }}</dt>
        <dd>
          <div class="ds-field">
            <div class="ds-input --copy"><input data-testid="sigfox-pac" readonly="true" [ngModel]="afterDeleteResponse.pac" /><button
                class="ds-button --plain --hide-label --icon-clipboard copy-pac-button"
                [attr.data-clipboard-text]="afterDeleteResponse.pac"><span>Copy PAC</span></button></div>
          </div>
        </dd>
      </dl>
      <div class="ds-notice--alert">{{ 'terminate_resource.sigfoxDeletedImmediatelySuccessModal.description.caution' |
        translate }}</div>
    </div>
  </div>
</section>
<footer dsDialogFooter>
  <button class="ds-button" data-testid="sigfox-delete-immediately-close" (click)="close()">{{ 'terminate_resource.sigfoxDeletedImmediatelySuccessModal.closeButton' | translate }}</button>
</footer>
