export const template = `<form name="$ctrl.roleAddForm" novalidate="novalidate">
  <div class="modal-header">
    <h4>{{ &quot;security.roles.modals.create.header&quot; | translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="form-group"><label class="control-label" for="role-name">{{ &quot;security.roles.modals.create.id&quot;
        | translate }}&nbsp;<span class="text-danger">*</span></label><input class="form-control" id="role-name"
        type="text" placeholder="{{ &quot;security.roles.modals.create.id&quot; | translate }}" name="roleName"
        ng-model="$ctrl.roleName" ng-pattern="$ctrl.roleNamePattern" focus-me="true" required="required" /><span
        class="help-block"><small
          ng-bind-html="&quot;security.roles.modals.create.name_notice&quot; | translate"></small></span></div>
    <div class="form-group"><label class="control-label" for="role-description">{{
        &quot;security.roles.modals.create.description&quot; | translate }}</label><input class="form-control"
        id="role-description" type="text"
        placeholder="{{ &quot;security.roles.modals.create.description&quot; | translate }}" name="roleDescription"
        ng-model="$ctrl.roleDescription" /></div>
    <div class="form-group"><label class="control-label" for="role-permissions">{{
        &quot;security.roles.modals.create.permissions&quot; | translate }}</label><app-permissions-editor
        [on-data-changes]="$ctrl.onDataChanges"></app-permissions-editor></div>
    <div class="form-group"><span class="help-block"><em><span class="text-danger">*</span></em>&nbsp; {{
        &quot;security.roles.modals.create.required&quot; | translate }}</span></div>
    <div class="form-group"><sc-alerts id="role-add-alert" service="$ctrl.alertsService"></sc-alerts></div>
  </div>
  <div class="modal-footer">
    <div class="form-group"><button class="btn btn-default" id="role-add-cancel" type="button"
        ng-click="$ctrl.dismiss()">{{ &quot;security.roles.modals.create.cancel&quot; | translate }}</button><button
        class="btn btn-primary" id="role-add-submit" type="submit" ng-click="$ctrl.submit()"
        ng-disabled="!$ctrl.canSubmit()" sc-modal-default-action="sc-modal-default-action">{{
        &quot;security.roles.modals.create.submit&quot; | translate }}</button></div>
  </div>
</form>`;
