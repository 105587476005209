import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  createUrlTreeFromSnapshot,
  DetachedRouteHandle,
  Route,
  RouteReuseStrategy,
} from '@angular/router';

/**
 * Custom "RouteReuseStrategy" for the Angular router, which
 *
 * - Reload page if route is same but coverage_type changed
 * - Cache & restore a page if the route config has `data.reusePathPrefix` property
 */
@Injectable()
export class UserConsoleRouteReuseStrategy implements RouteReuseStrategy {
  // "cache"
  private prev: {
    reusePathPrefix: string;
    route: Route;
    coverageType: string | null;
    handle: DetachedRouteHandle;
  } | null = null;

  /*
   * If returns true, then the route will be cached.
   * Otherwise (default), it will be just destroyed and discarded .
   */
  shouldDetach(route?: ActivatedRouteSnapshot): boolean {
    return route?.data?.reusePathPrefix ?? false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // store only previous route
    this.prev = {
      reusePathPrefix: route.data?.reusePathPrefix,
      // @ts-expect-error (legacy code incremental fix)
      route: route.routeConfig,
      coverageType: getCoverageType(route),
      handle,
    };
  }

  /*
   * If returns true, then restore the cached page (cached at shouldDetached)
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (this.prev) {
      // `shouldAttach` is called on navigation.
      // if it navigates away from cached page's coverage routes (defined by data.reusePathPrefix property), then discard the cache
      // e.g. /sims has `{ data.reusePathPrefix: '/sims' }`
      //      /sims -> /sims/:id -> /sims: cache will be restored!
      //      /sims -> /sims/:id -> /sims/:id/hello -> /sims: cache will be restored!
      //      /sims -> /dashboard -> /sims: no cache.
      //      /sims -> /groups/:id -> /sims: no cache
      const path = getPath(route);
      if (!path.startsWith(this.prev.reusePathPrefix)) {
        this.prev = null;
      }
    }
    return route.data?.reusePathPrefix && !!this.retrieve(route);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    // if same route & coverageType, then we might be able to reuse it
    // @ts-expect-error (legacy code incremental fix)
    return this.prev?.route === route.routeConfig && this.prev.coverageType === getCoverageType(route)
      ? this.prev.handle
      : null;
  }

  /*
   * If returns true, components for route will be re-used (= Components will not be re-created/re-initialized.)
   */
  shouldReuseRoute(future?: ActivatedRouteSnapshot, curr?: ActivatedRouteSnapshot): boolean {
    // Angular's default strategy code is `future.routeConfig === curr.routeConfig`
    // That is, it reloads components (for router-outlet) if "Route" (object for route definition) changes. Only URL query parameters change doesn't trigger the component reload.
    // Here, we add another condition for reuse.
    // If "Route"s are same but coverage_type in queryParameter is changed, then the page will not be reused (= will be reloaded)
    return future?.routeConfig === curr?.routeConfig && getCoverageType(future) === getCoverageType(curr);
  }
}

function getCoverageType(route?: ActivatedRouteSnapshot): string | null {
  return route?.queryParamMap?.get('coverage_type') ?? null;
}

function getPath(route: ActivatedRouteSnapshot) {
  return createUrlTreeFromSnapshot(route, []).toString();
}
