import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgChartsModule } from '@soracom/shared-ng/ng-charts';
import { TrafficVolumeRankingChartComponent } from './traffic-volume-ranking-chart/traffic-volume-ranking-chart.component';

@NgModule({
  declarations: [TrafficVolumeRankingChartComponent],
  exports: [TrafficVolumeRankingChartComponent],
  imports: [CommonModule, NgChartsModule],
})
export class TrafficVolumeRankingModule {}
