import { LegacyAny } from '@soracom/shared/core';

import { template } from './device_status_label.component.html';

import { BaseController } from '../../components/base_controller';
import { Device, DeviceStatus } from '../../core/device';
import { InjectList } from '../../core/injectable';

export class DeviceStatusLabelComponent implements ng.IComponentOptions {
  bindings = {
    obj: '<',
  };

  controller = DeviceStatusLabelComponentController;
  template: any = template;
}

export class DeviceStatusLabelComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$translate'];

  // @ts-expect-error (legacy code incremental fix)
  obj: Device;

  constructor($log: ng.ILogService, private $translate: LegacyAny) {
    super($log);
  }

  get status(): DeviceStatus {
    // @ts-expect-error (legacy code incremental fix)
    return this.obj ? this.obj.status : undefined;
  }

  textCssClass(): string[] {
    switch (this.status) {
      case 'active':
      case 'inactive':
      case 'deleted':
        return [`-device-status-label__text--${this.obj.status}`];
      default:
        return ['-device-status-label__text--unknown'];
    }
  }

  iconCssClass(): string[] {
    switch (this.status) {
      case 'active':
        if (this.obj.online) {
          return ['ds-icon--color-green', 'ds-icon--icon-online-solid'];
        } else {
          return ['ds-icon--color-ink', 'ds-icon--icon-circle'];
        }
      case 'inactive':
        return ['ds-icon--color-ink', 'ds-icon--icon-inactive'];
      case 'deleted':
      default:
        return ['ds-icon--icon-cancel'];
    }
  }

  statusText() {
    if (this.status) {
      return this.$translate.instant(`devices.values.status.${this.status}`);
    } else {
      return '';
    }
  }
}
