export const template = `<div class="btn-group pull-right" uib-dropdown="uib-dropdown" dropdown-append-to-body="dropdown-append-to-body"
  is-open="$ctrl.status.isOpen"><a class="dropdown-toggle" id="sort-filter-menu-dropdown-toggle" href=""
    uib-dropdown-toggle="uib-dropdown-toggle" style="color: #555;"><i class="glyphicon glyphicon-collapse-down"></i></a>
  <ul class="dropdown-menu" id="sort-filter-menu" role="menu" uib-dropdown-menu="uib-dropdown-menu">
    <li style="padding: 0 10px"><span>{{ 'sort_filter_menu.sort.title' | translate }}</span></li>
    <li><a href="" ng-click="$ctrl.setSortOrder(&quot;ascend&quot;)"><i class="glyphicon glyphicon-ok"
          ng-show="$ctrl.sortOrder === &quot;ascend&quot;"></i><i class="glyphicon glyphicon-blank"
          ng-show="$ctrl.sortOrder !== &quot;ascend&quot;"></i>&nbsp; {{ 'sort_filter_menu.sort.ascend' | translate
        }}</a></li>
    <li><a href="" ng-click="$ctrl.setSortOrder(&quot;descend&quot;)"><i class="glyphicon glyphicon-ok"
          ng-show="$ctrl.sortOrder === &quot;descend&quot;"></i><i class="glyphicon glyphicon-blank"
          ng-show="$ctrl.sortOrder !== &quot;descend&quot;"></i>&nbsp; {{ 'sort_filter_menu.sort.descend' | translate
        }}</a></li>
    <li class="divider"></li>
    <li style="padding: 0 10px"><span>{{ 'sort_filter_menu.filter.title' | translate }}</span>
      <div style="padding-left: 10px"><span class="small"><a id="sort-filter-menu-select-all" href=""
            ng-click="$ctrl.selectAll($event)">{{ 'sort_filter_menu.filter.select_all' | translate
            }}</a></span>&nbsp;<span class="small"><a id="sort-filter-menu-select-none" href=""
            ng-click="$ctrl.clear($event)">{{ 'sort_filter_menu.filter.clear' | translate }}</a></span></div>
    </li>
    <li ng-repeat="item in $ctrl.innerFilteredItems"><a ng-click="$ctrl.cancelClick($event);"><input
          class="sort-filter-menu-status-checkbox" type="checkbox" ng-model="item.checked"
          ng-change="$ctrl.changed(item)" ng-click="$event.stopPropagation()"
          ng-class="$ctrl.getCssClass(item.id)" />&nbsp;<span ng-click="item.checked = !item.checked">{{ item.id |
          translate }}</span></a></li>
    <li style="padding: 0 10px"><br /><button class="btn-sm btn-default" id="sort-filter-menu-cancel-button"
        ng-click="$ctrl.cancel($event)">{{ 'sort_filter_menu.filter.cancel' | translate }}</button><button
        class="btn-sm pull-right" id="sort-filter-menu-apply-button"
        ng-class="$ctrl.canApplyFilter() ? &quot;btn-primary&quot; : &quot;btn-default&quot;"
        ng-click="$ctrl.applyFilter($event)" ng-disabled="!$ctrl.canApplyFilter()">{{ 'sort_filter_menu.filter.apply' |
        translate }}</button></li>
  </ul>
</div>`;
