<div class="ds-rows">
  <div class="ds-notice">
    <h4>{{ 'LteMButtonIndexComponent.heading.title' | translate }}</h4>
    <p [innerHtml]="'LteMButtonIndexComponent.heading.description' | translate"></p>
  </div>
  <h4>{{ 'LteMButtonIndexComponent.subscriberList.title' | translate }}</h4>
  <ui-button-bar [content]="buttonBar"></ui-button-bar>
  <div class="ds-cols--stretch">
    <input
      class="ds-input x-subscriber-search-text"
      type="text"
      [formControl]="searchTextControl"
      [attr.placeholder]="'LteMButtonIndexComponent.search_placeholder' | translate"
    />
    <div class="ds-cols--end">
      <ui-pagination [content]="paginationContent" [tableContent]="content"></ui-pagination>
    </div>
  </div>
  <ui-generic-table [creator]="creator" [content]="content" (selectionChanged)="subscriberSelected($event)">
    <ng-template let-content="content" let-row #dataRow>
      <td [ngClass]="content.columns[0].tdClasses">{{ row.obj.tags.DSN || 'unknown' }}</td>
      <td [ngClass]="content.columns[1].tdClasses">{{ row.obj.name }}</td>
      <td [ngClass]="content.columns[2].tdClasses">{{ row.obj.imsi }}</td>
      <td [ngClass]="content.columns[3].tdClasses">{{ row.obj.groupName }}</td>
      <td [ngClass]="content.columns[4].tdClasses">
        <sim-status-info
          [isOnline]="false"
          [status]="row.obj.status"
          [isTransferring]="row.obj.isTransferring()">
        </sim-status-info>
      </td>
    </ng-template>
  </ui-generic-table>
</div>
