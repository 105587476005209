export const template = `<app-user-notifications></app-user-notifications>
<div class="alerts"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
<div class="x-traffic-volume-ranking-page-root" id="page-wrapper">
  <div class="row block">
    <div class="col-sm-12">
      <h1>{{ 'traffic_volume_ranking.page_header' | translate }}</h1>
      <p>{{ 'traffic_volume_ranking.exemption_from_responsibility' | translate }}</p>
    </div>
  </div>
  <div class="row block">
    <div class="col-sm-12">
      <form class="query-traffic-volume-ranking-form form-inline" name="searchQuery" ng-submit="$ctrl.search()">
        <div class="form-group">
          <div class="col-limit-number"><input class="form-control" id="limit-number" type="number"
              ng-min="$ctrl.limitConstraint.limitMin" ng-max="$ctrl.limitConstraint.limitMax"
              ng-model="$ctrl.dataJar.limit" /><label class="control-label" id="limit-number-label"
              for="limit-number">{{ 'traffic_volume_ranking.limit ' | translate }}</label></div><label
            class="control-label sr-only">{{ 'traffic_volume_ranking.time' | translate }}</label>
          <div class="col-date-time-picker"><sc-date-time-picker date-time-msec="$ctrl.dataJar.searchFromMillis"
              required="true" field-name="&quot;from&quot;"></sc-date-time-picker></div>
          <div class="hidden-sm col-separator">
            <p class="form-control-static text-center">-</p>
          </div>
          <div class="col-date-time-picker"><sc-date-time-picker date-time-msec="$ctrl.dataJar.searchToMillis"
              required="true" field-name="&quot;to&quot;"></sc-date-time-picker></div>
          <div class="col-search-button"><button class="btn btn-primary" type="submit"
              ng-disabled="searchQuery.$invalid">{{ 'traffic_volume_ranking.search' | translate }}</button></div>
        </div>
      </form>
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-body"><app-traffic-volume-ranking-chart
        [chart-data]="$ctrl.chartData"></app-traffic-volume-ranking-chart></div>
  </div>
</div>`;
