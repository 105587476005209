import { LegacyAny } from '@soracom/shared/core';

import { Component } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { VolumeDiscountResponse } from '../long-term-discounts/VolumeDiscount';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-confirm-long-term-discount',
  templateUrl: './confirm-long-term-discount.component.html',
})
export class ConfirmLongTermDiscountComponent extends DsModalContentBase<
  VolumeDiscountResponse,
  VolumeDiscountResponse
> {
  alertsManager = new AlertsManager();

  // @ts-expect-error (legacy code incremental fix)
  outputType: VolumeDiscountResponse;
  // @ts-expect-error (legacy code incremental fix)
  output: VolumeDiscountResponse;

  submitting = false;

  constructor(private logger: Logger, private apiService: SoracomApiService) {
    super();
  }

  get discountResponse() {
    return this.getInput();
  }

  get canConfirm() {
    return !this.submitting;
  }

  confirm() {
    this.submitting = true;

    this.apiService
      .confirmLongTermDiscount(this.getInput().orderId)
      .then((response: LegacyAny) => {
        this.logger.debug('LTD created', response);
        this.close(response.data as VolumeDiscountResponse);
      })
      .catch((error: LegacyAny) => {
        const errorAlert = Alert.danger(error);
        this.alertsManager.add(errorAlert);
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
