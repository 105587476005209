import { template } from './item_chooser.component.html';

import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';
import { ItemChooserController, NullItemChooserController } from './ItemChooserController';

export class ItemChooserComponent implements ng.IComponentOptions {
  bindings = {
    ctrl: '<',
  };

  controller = ItemChooserComponentController;
  template: any = template;
}

export class ItemChooserComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$uibModal', 'AlertsService'];

  ctrl: ItemChooserController<any> = new NullItemChooserController();

  constructor($log: ng.ILogService) {
    super($log);
  }

  get disabled(): boolean {
    return this.ctrl.disabled;
  }

  get items(): any[] {
    return this.ctrl.items;
  }

  get showCreate(): boolean {
    return this.ctrl.showCreate;
  }

  setItem(item: any) {
    this.ctrl.setItem(item);
  }

  getItemName(item: any): string {
    return this.ctrl.getItemName(item);
  }

  unsetItem() {
    this.ctrl.unsetItem();
  }

  createItem() {
    this.ctrl.createItem();
  }

  unsetLinkClass() {
    this.ctrl.unsetLinkClass();
  }
}
