<section dsDialogContent>
  <div class="ds-rows">
    <ng-container *ngIf="content.isLoading || content.dataCount > 0">
      <div class="ds-notice--info">{{ textId | translate }}</div>
      <ui-generic-table
        [creator]="itemCreator"
        [content]="content"
        (selectionChanged)="uiTableSelectionChanged($event)"
      ></ui-generic-table>
    </ng-container>
    <ng-container *ngIf="!content.isLoading && content.dataCount == 0">
      <div class="ds-notice--info">
        {{ 'ActivateLagoonV2ModalComponent.' + mode + '.text_no_dashboards' | translate }}
      </div>
    </ng-container>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" [class.--loading]="loading" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="confirmButtonTitleId | translate"></span>
  </button>
  <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()">
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>

