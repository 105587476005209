import { Terminatable, instanceOfTerminatable } from '@soracom/shared/soracom-platform';
import { TableController } from '../../components/base_table_controller';
import { Group, Groupable } from '@soracom/shared/group';
import { Action } from './Action';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { ChangeGroupModalComponent } from 'apps/user-console/src/app/soracom-groups/change_group_modal/change_group_modal.component';

export class ChangeGroupAction<T extends Groupable> implements Action<T> {
  constructor(
    private ctrl: TableController<T> & {
      updateGroup: (group: any) => any;
    },
    private uiDsModalService: UiDsModalService,
    private model: string
  ) {}

  run() {
    this.uiDsModalService
      .openAndWaitForResult(ChangeGroupModalComponent, {
        title: 'templates.change_group_modal.title',
        data: {
          resolve: {
            targets: () => {
              return this.ctrl.tableData.getSelectedData();
            },
          },
        },
      })
      .then((result) => {
        if (result) {
          this.ctrl.updateGroup(result.group);
        }
      });
  }

  // @ts-expect-error (legacy code incremental fix)
  actionable(target: T[]) {
    if (target) {
      if (target.length === 0) {
        return false;
      }
      if (instanceOfTerminatable(target[0])) {
        return !target.find((t) => (t as unknown as Terminatable).isTerminated());
      }
      return true;
    }
  }
}
