import { LegacyAny } from '@soracom/shared/core';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

export function ConfirmDirective(uiDsModalService: UiDsModalService): ng.IDirective {
  const link = (scope: LegacyAny, element: LegacyAny, attrs: LegacyAny) => {
    const showConfirmationModal = (event: LegacyAny) => {
      if (attrs.disabled) {
        event.preventDefault();
        return;
      }
      // Overwrite 'type' value with `sc-confirm='hoge'`
      if (attrs.scConfirm && attrs.scConfirm !== 'sc-confirm') {
        scope.type = attrs.scConfirm;
      }

      const modal = {
        body: '',
        cancel: '',
        confirm: '',
        title: '',
      };
      let localizationPrefix = '';
      if (scope.type) {
        localizationPrefix = 'sc_confirm.' + scope.type;
      } else if (scope.key) {
        localizationPrefix = scope.key;
      } else {
        localizationPrefix = 'sc_confirm.default';
      }
      modal.title = localizationPrefix + '.title';
      modal.body = localizationPrefix + '.body';
      modal.confirm = localizationPrefix + '.confirm';
      modal.cancel = localizationPrefix + '.cancel';
      uiDsModalService
        .openConfirmModal(modal.body, {
          title: modal.title,
          cancelButtonTitleId: modal.cancel,
          okButtonTitleId: modal.confirm,
          okButton: (button) => {
            button.titleId = modal.confirm;
            button.buttonStyle = scope.type === 'deletion' ? 'danger' : 'primary';
          },
        })
        .then((confirmed) => {
          if (confirmed) {
            scope.onConfirm();
          } else {
            scope.onCancel();
          }
        });
    };
    element.bind('click', showConfirmationModal);
  };

  return {
    restrict: 'A',
    scope: {
      onConfirm: '&',
      onCancel: '&?',
      key: '@?',
      values: '=?',
    },
    link,
  };
}

ConfirmDirective.$inject = ['UiDsModalService'];
