import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { CoverageTypeGuard } from '@soracom/shared-ng/core-service';
import { NotFoundPageComponent } from '@soracom/shared-ng/feature-common-pages';
import { DummyComponent, RedirectGuard } from '@soracom/shared-ng/util-common';
import { environment } from '../environments/environment';
import { MfaRecoveryVerifyComponent } from './auth/mfa-recovery-verify/recovery-verify.component';
import { MfaRecoveryComponent } from './auth/mfa-recovery/mfa-recovery.component';
import { SupportLoginComponent } from './auth/support-login/support-login.component';
import { AuthGuard } from './core/auth-guard';
import { CountryNotJapanGuard } from './core/country-not-japan-guard';
import { LoginPageGuard, LoginPageGuardData } from './core/login-page-guard';
import { NoContactInfoGuard } from './core/no-contact-info-guard';
import { DashboardRouteVisibilityGuard, featureVisibilityGuard } from './core/route-visibility-guard';
import { LegacySimsPageComponent } from './legacy-sims-page.component';
import { PrefixedEventHandlersPageComponent } from './prefixed_event_handlers/prefixed-event-handlers-page.component';
import { PasswordRecoveryComponent } from './soracom-before-login/password-recovery/password-recovery.component';
import { VerifyPasswordResetComponent } from './soracom-before-login/verify-password-reset/verify-password-reset.component';
import { SubscribersWrapperComponent } from './subscribers-wrapper/subscribers-wrapper.component';
import { UserConsoleRouteReuseStrategy } from './user-console-route-reuse-strategy';
import { multiAppLegacyAppPre404RedirectGuard } from '@soracom/shared-ng/multi-app';

/**
 * Public Path
 */
const publicRoutes: Routes = [
  {
    path: 'sample',
    loadChildren: () => import('./sample/sample.module').then((m) => m.SampleModule),
  },
  {
    path: 'logout',
    loadComponent: () => import('@soracom/shared-ng/ui-logout').then((m) => m.LogoutComponent),
  },
  {
    path: 'signup',
    data: {
      externalUrl: environment.signupUrl,
    },
    // The guard will redirect before any attempt to actually render the component, but the component is required here, or else a runtime error will occur.
    component: DummyComponent,
    canActivate: [RedirectGuard],
  },
  {
    path: 'signup_kddi',
    component: DummyComponent,
  },
  {
    path: 'signup_kddi_finished',
    component: DummyComponent,
  },
  // @mason 2023-03-30: this route was merged back in and caused major bug https://app.shortcut.com/soracom/story/82849/email-verification-is-broken-in-user-console
  // Because the bug is affecting several users already, it is urgent and I am fast-fixing without full investigation of WHY this happens (do that as follow up). That is why I leave this comment here for now... did we break any other routes by merging this back in? I don't know yet.
  // But this route's effect was, when user clicks the email link like /operators/add_email_token/verify?87e4490d-729f-405d-bb88-ef4f2a271b62-15292d53-32dc-4d91-9563-41d98248b467 they just see an empty page.
  // {
  //   path: 'operators/add_email_token/verify',
  //   component: DummyComponent,
  // },
  {
    path: 'login',
    component: DummyComponent,
    data: {
      ssoLoginUrl: `${environment.authSSOUrl}/login`,
    } satisfies LoginPageGuardData,
    canActivate: [LoginPageGuard],
  },
  {
    path: 'sam_login',
    component: DummyComponent,
    data: {
      ssoLoginUrl: `${environment.authSSOUrl}/login/samlogin`,
    } satisfies LoginPageGuardData,
    canActivate: [LoginPageGuard],
  },
  {
    path: 'not_allowed',
    component: DummyComponent,
  },
  {
    path: 'mfa_reset',
    component: MfaRecoveryComponent,
  },
  {
    path: 'password_reset',
    component: PasswordRecoveryComponent,
  },
  {
    path: 'auth/password_reset_token/verify',
    component: VerifyPasswordResetComponent,
  },
  {
    path: 'auth/mfa_reset_token/verify',
    component: MfaRecoveryVerifyComponent,
  },
  {
    // Deprecated
    path: 'password/reset',
    component: VerifyPasswordResetComponent,
  },
  // NOTE: mason 2023-03-30: part of fix for sc-82849 (see above) is here. For now I left the old comment although I think it is inaccurate and should be edited/deleted. This route was private, so non-authenticated users could not verify email token (woudl redirect to login page instead) even after the bug was fixed for authenticated users.
  //
  // I moved this route here to be public, and that fixes it.
  {
    // NOTE: The SoracomAccountManagementModule handles these child routes in the normal way here, **but** there are still /account/xxx routes that are handled by AngularJS and legacy components. So we handle those routes in the AngularJS router. So e.g. /account/email is handled by the SoracomAccountManagementModule, but /account/contact is handled by the legacy Angular components (as of 2022-07-07 anyway) and /account/coupons is handled separately above. I note this just because it is confusing; it looks here like SoracomAccountManagementModule would handle any /account/hoge route, but it is not (yet) true. See also apps/user-console/src/app/routing-strategy.ts and apps/user-console/app/shared/main/app.route.ts.
    path: 'operators',
    loadChildren: () =>
      import('./soracom-account-management/soracom-account-management.module').then(
        (m) => m.SoracomAccountManagementModule,
      ),
    // this route is public for /operators/add_email_token/verify
  },

  // NOTE: The next 2 routes are the same, one is just legacy but it is used by external systems:
  // See: https://app.shortcut.com/soracom/story/78847/createoperator-api-fails-because-verify-route-was-wrongly-removed
  {
    path: 'operators/verify',
    loadComponent: () => {
      return import('@soracom/shared-ng/feature-verify-token').then((m) => m.VerifyTokenComponent);
    },
  },
  {
    path: 'verification',
    loadComponent: () => {
      return import('@soracom/shared-ng/feature-verify-token').then((m) => m.VerifyTokenComponent);
    },
  },
  /**
   * Redirect old path to new path
   */
  {
    path: 'account/lang',
    pathMatch: 'full',
    redirectTo: 'settings/language',
  },
  {
    path: 'account/password',
    pathMatch: 'full',
    redirectTo: 'security/password',
  },
  {
    path: 'account/auth_keys',
    pathMatch: 'full',
    redirectTo: 'security/auth_keys',
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'billing_dashboard',
    redirectTo: 'billings/billing_dashboard',
    pathMatch: 'full',
  },
  {
    path: 'billing_alert',
    redirectTo: 'billings/billing_alert',
    pathMatch: 'full',
  },
  {
    path: 'payments',
    redirectTo: 'billings/payment_settings',
    pathMatch: 'full',
  },
];

/**
 * NOTE: (mason 2022-06-30): Even though we can (and must) define routes in the normal Angular way here (and in these lazy-loaded child modules' routing modules), we **also** have to define those routes in the AngularJS router (in `apps/user-console/app/shared/main/app.route.ts`).
 *
 * Otherwise, AngularJS will redirect the user to / when there is a route it does not understand. So:
 *
 * - When adding a new Angular route don't forget to update the AngularJS routes too.
 *
 * - Because of this, all **breadcrumb** definitions are also defined in `app.route.ts` as well. While I hoped to leverage Angular's routing facilities to define the breadcrumb structure, there wasn't a good way to do so while AngularJS is still handling so much of the routing.
 */
export const privateRoutes: Routes = [
  {
    path: 'batch',
    loadChildren: () => import('@soracom/shared-ng/ui-batch').then((m) => m.uiBatchRoutes),
    canActivate: [featureVisibilityGuard('batchProcessing')],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [DashboardRouteVisibilityGuard],
    pathMatch: 'full',
  },
  {
    path: 'orders',
    loadChildren: () => import('./order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'esim-profile-orders',
    loadComponent: () =>
      import('@soracom/shared-ng/feature-esim-profile-qr-order').then((m) => m.ESimProfilesOrderListComponent),
    canActivate: [featureVisibilityGuard('eSimProfileUi')],
  },
  {
    path: 'esim-profile-orders/order',
    loadComponent: () =>
      import('@soracom/shared-ng/feature-esim-profile-qr-order').then((m) => m.FeatureEsimProfileQrOrderComponent),
    canActivate: [featureVisibilityGuard('eSimProfileUi')],
  },
  {
    path: 'account/coupons', // I think there should be better routing based on module, like may be only /coupons ?
    loadChildren: () => import('./coupons/coupons.module').then((m) => m.CouponsModule),
    canActivate: [featureVisibilityGuard('coupon')],
  },
  {
    path: 'virtual_private_gateways',
    loadChildren: () =>
      import('./virtual-private-gateway/virtual-private-gateway.module').then((m) => m.VirtualPrivateGatewayModule),
  },
  {
    path: 'event_handler',
    loadChildren: () => import('./event-handler/event-handler.module').then((m) => m.EventHandlerModule),
  },
  {
    path: 'soralets',
    loadChildren: () => import('./soracom-soralets/soracom-soralets.module').then((m) => m.SoracomSoraletsModule),
  },
  {
    path: 'security',
    loadChildren: () => import('./security/security.module').then((m) => m.SecurityModule),
  },
  {
    path: 'sora_cam/cellular_packs',
    loadChildren: () =>
      import('./feature-sora-cam-cellular-pack/feature-sora-cam-cellular-pack.module').then(
        (m) => m.FeatureSoraCamCellularPacksModule,
      ),
    canActivate: [featureVisibilityGuard('soraCamCellularPacks')],
  },
  {
    path: 'sora_cam',
    loadChildren: () => import('./sora-cam/sora-cam.module').then((m) => m.SoraCamModule),
    canActivate: [featureVisibilityGuard('soraCam')],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./soracom-account-management/soracom-account-management.module').then(
        (m) => m.SoracomAccountManagementModule,
      ),
  },
  {
    path: 'watch',
    component: PrefixedEventHandlersPageComponent,
  },
  {
    path: 'groups',
    component: DummyComponent,
  },
  {
    path: 'groups/:id',
    component: DummyComponent,
  },
  /* Inventory Devices*/
  {
    path: 'devices',
    component: DummyComponent,
  },
  {
    path: 'devices/:id',
    component: DummyComponent,
  },
  {
    path: 'inventory_device_groups',
    component: DummyComponent,
  },
  {
    path: 'inventory_device_groups/:id',
    component: DummyComponent,
  },
  {
    path: 'device_groups',
    component: DummyComponent,
  },
  {
    path: 'device_groups/:id',
    component: DummyComponent,
  },
  {
    path: 'device_object_models',
    component: DummyComponent,
  },
  /* LoRa */
  {
    path: 'lora_devices',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lora')],
  },
  {
    path: 'lora_device_groups',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lora')],
  },
  {
    path: 'lora_device_groups/:id',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lora')],
  },
  {
    path: 'lora_gateways',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lora')],
  },
  {
    path: 'lora_gateways/:id',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lora')],
  },
  {
    path: 'lora_network_sets',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lora')],
  },
  {
    path: 'lora_network_sets/:id',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lora')],
  },
  /* Sigfox */
  {
    path: 'sigfox_devices',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('sigfox')],
  },
  {
    path: 'sigfox_device_groups',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('sigfox')],
  },
  {
    path: 'sigfox_device_groups/:id',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('sigfox')],
  },
  {
    path: 'satellite_devices',
    loadChildren: () =>
      import('./feature-satellite-device/feature-satellite-device.module').then((m) => m.FeatureSatelliteDeviceModule),
    canActivate: [featureVisibilityGuard('satellite')],
  },
  {
    path: 'satellite_device_groups',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('satellite')],
  },
  {
    path: 'satellite_device_groups/:id',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('satellite')],
  },
  {
    path: 'long_term_discounts',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('longTermDiscount')],
  },
  {
    path: 'traffic_volume_ranking',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('trafficVolumeRanking')],
  },

  {
    path: 'buttons',
    loadChildren: () => import('./feature-aws-button/feature-aws-button.module').then((m) => m.FeatureAwsButtonModule),
    canActivate: [featureVisibilityGuard('button')],
  },
  {
    path: 'relay',
    loadChildren: () => import('./relay/relay.module').then((m) => m.RelayModule),
    canActivate: [featureVisibilityGuard('relay')],
  },
  {
    path: 'gadgets/aws_button',
    loadChildren: () => import('./feature-aws-button/feature-aws-button.module').then((m) => m.FeatureAwsButtonModule),
    canActivate: [featureVisibilityGuard('button')],
  },
  {
    path: 'gadgets/lte_m_button',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lteMButton')],
  },
  {
    path: 'gadgets/lte_m_button/new',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lteMButton')],
  },
  {
    path: 'gadgets/lte_m_button/:groupId/edit',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('lteMButton')],
  },
  {
    path: 'gadgets/gps_multiunit',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('gpsMultiunit')],
  },
  {
    path: 'gadgets/gps_multiunit/new',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('gpsMultiunit')],
  },
  {
    path: 'gadgets/gps_multiunit/:groupId/edit',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('gpsMultiunit')],
  },
  {
    path: 'debug',
    component: DummyComponent,
    canActivate: [featureVisibilityGuard('debugComponent')],
  },

  {
    path: 'billings',
    loadChildren: () => import('./billing/billing.module').then((m) => m.BillingModule),
  },
  {
    path: 'support_login',
    component: SupportLoginComponent,
  },
  {
    path: 'support_logout',
    component: DummyComponent,
  },
  // Mason 2023-05-01: These routes are provisional; ideally I think we'd want a "sims" module with child routes... but maybe not actually?
  {
    path: 'sims/subscriptions',
    loadComponent: () =>
      import('@soracom/shared-ng/feature-manage-subscriptions').then((m) => m.ManageSubscriptionsComponent),
  },
  {
    path: 'sims/:simId/subscriptions',
    loadComponent: () =>
      import('@soracom/shared-ng/feature-manage-subscriptions').then((m) => m.ManageSubscriptionsComponent),
  },
  {
    path: 'sims',
    loadComponent: () => import('./feature-sims-page/sim-table/sim-table.component').then((m) => m.SimTableComponent),
    data: {
      // cache and restore this page when navigating back from routes starting from '/sims (= /sims/:simId )
      reusePathPrefix: '/sims',
    },
    children: [
      {
        path: ':simId',
        loadChildren: () =>
          import('./feature-sim-details-page/feature-sim-details-page.module').then(
            (m) => m.FeatureSimDetailsPageModule,
          ),
      },
    ],
  },
  {
    path: 'switch-user',
    loadComponent: () =>
      import('./feature-switch-user/feature-switch-user.component').then((m) => m.FeatureSwitchUserComponent),
    children: [
      /**
       * Handling switch back logic here, in same component.
       * Reason 1 is to avoid any dirty patches in core header (otherwise it could be easily handled there as core service is available)
       * Reason 2 is its not that complicated that the route needs separate component
       */
      {
        path: 'back',
        loadComponent: () =>
          import('./feature-switch-user/feature-switch-user.component').then((m) => m.FeatureSwitchUserComponent),
      },
    ],
  },
  {
    path: 'legacy_sims',
    component: LegacySimsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'subscribers',
    loadComponent: () => SubscribersWrapperComponent,
  },
  {
    path: 'terminate_account',
    loadComponent: () =>
      import('./terminate-account/terminate-account.component').then((m) => m.TerminateAccountComponent),
  },
  {
    path: 'subscriber_transfer_token/verify',
    loadComponent: () =>
      import('./subscribers/verify-subscriber-transfer/verify-subscriber-transfer.component').then(
        (m) => m.VerifySubscriberTransferComponent,
      ),
  },
  {
    path: 'service_usage_history',
    loadChildren: () =>
      import('./feature-service-usage-history/feature-service-usage-history.module').then(
        (m) => m.FeatureServiceUsageHistoryModule,
      ),
  },
  {
    path: 'support/plans',
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [AuthGuard, NoContactInfoGuard, CountryNotJapanGuard],
  },
  {
    path: 'sora_cam_monitor_campaign_202308',
    loadComponent: () =>
      import('./google_forms/embedded-google-form-page.component').then((m) => m.EmbeddedGoogleFormPageComponent),
    data: {
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSc8Df2l6CsYXgptlE3gYlwt7_gjFMXnR9AUQLQiFoUURSoegw/viewform?embedded=true',
      prefillFieldIds: {
        operatorId: 'entry.1385362738',
        companyName: 'entry.1946348984',
      },
      onlyCorporateAccount: true,
    },
  },
  {
    path: 'sora_cam_monitor_survey_202308',
    loadComponent: () =>
      import('./google_forms/embedded-google-form-page.component').then((m) => m.EmbeddedGoogleFormPageComponent),
    data: {
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSevpFdBuJW3Paw9t2YErH0Ss23N311CQNPfNpYBs-4-0ADp2A/viewform?embedded=true',
      prefillFieldIds: {
        operatorId: 'entry.1385362738',
        companyName: 'entry.1503412485',
      },
      onlyCorporateAccount: true,
    },
  },
  {
    path: 'usage-statistics',
    loadChildren: () => import('./usage-statistics/usage-statistics.module').then((m) => m.UsageStatisticsModule),
  },
  {
    path: 'flux',
    loadChildren: () => import('@user-console/flux-page').then((m) => m.fluxPageRoutes),
    canActivate: [featureVisibilityGuard('flux')],
  },
  {
    path: 'lagoon',
    component: DummyComponent,
  },
  {
    path: 'lagoon/dashboards',
    component: DummyComponent,
  },
  {
    path: 'lagoon/users',
    component: DummyComponent,
  },
  {
    path: 'lagoon/subscription',
    component: DummyComponent,
  },
  {
    path: 'lagoon/customize',
    component: DummyComponent,
  },
  {
    path: 'error_logs',
    component: DummyComponent,
  },
  {
    path: 'audit_logs',
    component: DummyComponent,
  },
  {
    path: 'napter_audit_logs',
    component: DummyComponent,
  },
  {
    path: 'data',
    component: DummyComponent,
  },
  {
    path: 'harvest_data',
    component: DummyComponent,
  },
  {
    path: 'harvest_files',
    component: DummyComponent,
  },
  {
    path: 'debug',
    component: DummyComponent,
  },
];

export const AppRoutes: Routes = [
  ...publicRoutes,

  /* Private routes */
  // none for now but we can have pages like /account here

  /* Private & Coverage-Type-Dependent routes */
  {
    // tos page is special
    path: 'tos',
    canActivate: [AuthGuard],
    loadComponent: () => import('./tos/tos-page/tos-page.component').then((m) => m.ToSPageComponent),
  },
  {
    path: '',
    // canActivate will only be called when going into this route config from outside
    // canActivateChild will be called on every navigation between routes inside this route config
    canActivate: [AuthGuard],
    canActivateChild: [CoverageTypeGuard],
    children: [
      ...privateRoutes,
      {
        path: 'settings',
        canActivate: [multiAppLegacyAppPre404RedirectGuard],
        component: DummyComponent,
      },
      {
        path: 'settings/language',
        canActivate: [multiAppLegacyAppPre404RedirectGuard],
        component: DummyComponent,
      },
      {
        path: 'support-bot',
        canActivate: [multiAppLegacyAppPre404RedirectGuard],
        component: DummyComponent,
      },
      {
        path: 'chatdb',
        canActivate: [multiAppLegacyAppPre404RedirectGuard],
        component: DummyComponent,
      },

      // TODO: Other MAA app routes here once released. This should later be autogenerated from MAA base build-time config
      //
      // In future we may stop using the pre-404 MAA guard here because we may explicitly handle all MAA routes above
      {
        path: '**',
        canActivate: [multiAppLegacyAppPre404RedirectGuard],
        component: NotFoundPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: RouteReuseStrategy, useClass: UserConsoleRouteReuseStrategy },
  ],
})
export class AppRoutingModule {}
