import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { LagoonService } from '../../../../app/shared/lagoon/lagoon.service';
import { environment } from '../../../environments/environment';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { RootStoreState } from '../../root-store';
import { selectAvailableVersions } from '../../root-store/lagoon-store/selectors';
import { UiTableContent } from '../../soracom-ui/ui-table/ui-table-content';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';
import { LagoonMigrator } from '../lagoon_migration_status';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export class Dashboard {
  get id() {
    return this.uid;
  }
  uid: string;
  title: string;
  url: string;

  constructor(rawData: LagoonMigrator.Dashboard, orgId: string, lagoonDashboardUrl: string) {
    this.uid = rawData.Uid;
    this.title = rawData.Title;
    this.url = `${lagoonDashboardUrl}/d/${rawData.Uid}?orgId=${orgId}`;
  }
}

@Component({
  selector: 'app-activate-lagoon-v2-modal',
  templateUrl: './activate-lagoon-v2-modal.component.html',
})
export class ActivateLagoonV2ModalComponent extends DsModalContentBase<void, { success: boolean }> implements OnInit {
  private destroyRef = inject(DestroyRef);

  alertsManager = new AlertsManager();
  loading = false;
  // Application Status
  availableVersions$ = this.store$.pipe(select(selectAvailableVersions));

  mode: 'activation' | 'migration' = 'activation';
  private _textId = `ActivateLagoonV2ModalComponent.${this.mode}.text`;
  get textId() {
    return this._textId;
  }
  className = 'ActivateLagoonV2ModalComponent';
  migrated = false;
  dashboards: Dashboard[] = [];
  // @ts-expect-error (legacy code incremental fix)
  itemCreator: new () => Dashboard;
  columns: Array<UiTableColumn<Dashboard>> = [
    new UiTableColumn<Dashboard>('LagoonDashboard', 'name', (d: LegacyAny) => d.title),
    new UiTableColumn<Dashboard>('LagoonDashboard', 'url', (d: LegacyAny) => d.url),
  ];
  content: UiTableContent<Dashboard> = new UiTableContent<Dashboard>([], this.columns, { multiSelect: true });
  // @ts-expect-error (legacy code incremental fix)
  status: LagoonMigrator.LagoonMigrationStatus;
  // @ts-expect-error (legacy code incremental fix)
  migratingDashboardIds: string[];

  get canConfirm(): boolean {
    if (this.mode === 'migration' && this.content.dataCount === 0) {
      return false;
    }
    return true;
  }

  constructor(
    private coverageTypeService: CoverageTypeService,
    private lagoonService: LagoonService,
    private store$: Store<RootStoreState.State>
  ) {
    super();
  }

  get confirmButtonTitleId() {
    return `ActivateLagoonV2ModalComponent.${this.mode}.confirm`;
  }

  ngOnInit(): void {
    this.availableVersions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((versions) => {
      this.mode = versions.includes('v2') ? 'migration' : 'activation';
      this._textId = `ActivateLagoonV2ModalComponent.${this.mode}.text`;
    });
    this.loading = true;
    this.content.isLoading = true;
    this.lagoonService
      .getMigrationStatus()
      .then((data) => {
        this.status = data;
        const dashboards: Dashboard[] = [];
        if (data?.dashboardstatus?.missingdashboards) {
          const orgId = data?.sourceorg?.id;
          // @ts-expect-error (legacy code incremental fix)
          const lagoonDashboardUrl = environment.lagoonConsoleUrl[this.coverageTypeService.getCoverageType()];
          dashboards.push(
            ...data.dashboardstatus.missingdashboards.map((d: LegacyAny) => new Dashboard(d, orgId, lagoonDashboardUrl))
          );
        }
        this.content.setData(dashboards);
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
      })
      .finally(() => {
        this.loading = false;
        this.content.isLoading = false;
      });
  }

  uiTableSelectionChanged(dashboards: Dashboard[]) {
    this.migratingDashboardIds = dashboards.map((d: LegacyAny) => d.uid);
  }

  confirm() {
    const body: LagoonMigrator.MigrationPostRequest = {
      dashboardIds: this.migratingDashboardIds,
    };
    this.loading = true;
    this.alertsManager.clear();
    this.lagoonService
      .executeMigration(body)
      .then(() => {
        this.loading = false;
        this.close({ success: true });
      })
      .catch((e: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(e));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  canClose() {
    return !this.loading;
  }
}
