import { LegacyAny } from '@soracom/shared/core';

import { Action } from '../core/actions/Action';
import { SigfoxDevice } from '../core/sigfox_device';
import { SigfoxDevicesComponentController } from './sigfox_devices.component';

import * as angular from 'angular';

export class DownlinkSigfoxDeviceAction implements Action<SigfoxDevice> {
  private $uibModal;

  constructor(private ctrl: SigfoxDevicesComponentController) {
    this.$uibModal = ctrl.$uibModal;
  }

  run() {
    this.$uibModal
      .open({
        component: 'scSendDownlinkMessageComponent',
        backdrop: 'static',
        resolve: {
          params: () => {
            return {
              sigfoxDevices: this.ctrl.tableData.getSelectedData(),
            };
          },
        },
      })
      .result.then(this.okAction, this.cancelAction);
  }

  actionable() {
    const notTerminated =
      this.ctrl.tableData.getSelectedData().filter((r) => {
        return r.status === 'terminated';
      }).length === 0;
    return this.ctrl.tableData.countChecked() > 0 && notTerminated;
  }

  private okAction = (result: LegacyAny) => {
    this.ctrl.alertsService.showInfo('sigfox_devices.downlink_sent');
  };

  private cancelAction = () => {
    // noop
  };
}

export class RegisterSigfoxDeviceAction implements Action<SigfoxDevice> {
  private $uibModal;

  constructor(private ctrl: SigfoxDevicesComponentController) {
    this.$uibModal = ctrl.$uibModal;
  }

  run() {
    this.$uibModal
      .open({
        component: 'scRegisterSigfoxDeviceComponent',
        backdrop: 'static',
      })
      .result.then(this.okAction, this.cancelAction);
  }

  actionable() {
    return true;
  }

  private okAction = (result: LegacyAny) => {
    if (result instanceof SigfoxDevice) {
      this.ctrl.tableData.appendData(result);
      this.ctrl.alertsService.showInfo('sigfox_devices.registration_success_message');
    } else {
      this.ctrl.alertsService.showAlert('danger', { translationId: 'sigfox_devices.registration_failure_message' });
    }
  };

  private cancelAction = () => {
    // noop
  };
}

export class ShowPacSigfoxDeviceAction implements Action<SigfoxDevice> {
  private $uibModal;

  constructor(private ctrl: SigfoxDevicesComponentController) {
    this.$uibModal = ctrl.$uibModal;
  }

  // @ts-expect-error (legacy code incremental fix)
  actionable(devices: SigfoxDevice | SigfoxDevice[]) {
    // This will cause the Show PAC command to be disabled if we don't have any
    // devices with a PAC field populated.

    // So (my theory) if we do not reliably update the complete device record on
    // terminate (and I think as of this writing we don't, we just set the terminated
    // flag on the cached device model), then the Show PAC command will be
    // improperly disabled after terminating a device (because the PAC field is
    // only populated after termination)

    if (!Array.isArray(devices)) {
      devices = [devices];
    }
    let devicesWithPac = devices.filter((d: LegacyAny) => !!d.pac);

    const win = window as LegacyAny;
    let noPacHack = win['soracom_user_console_debug_show_pac_if_deviceId_has_prefix'];
    noPacHack = typeof noPacHack === 'string' && noPacHack;
    // For debugging, you can set window.soracom_user_console_debug_show_pac_if_deviceId_has_prefix to a string like "6", and then Sigfox devices whose deviceId starts with "6" will enable the "Show PAC" command. This is because it's hard to get real Sigfox devices with a PAC to test with (they only have a PAC after they have been terminated).

    // Mason 2019-05-29: FIXME: move this feature to the new SoracomUserConsole singleton, where we now want to consolidate all this kind of "debug functionality that is exposed to tests/developers via the Window object" stuff.

    if (devicesWithPac.length < 1 && noPacHack) {
      // FIXME: Hack for debug since I have access to no terminated Sigfox devices currently...
      devicesWithPac = devices.filter((d: LegacyAny) => !!d.deviceId.startsWith(noPacHack));
      // console.log("DEBUG: enabling hack to allow showing the PAC dialog even when device not terminated");
    }

    return devicesWithPac.length > 0;
  }

  // @ts-expect-error (legacy code incremental fix)
  run(devices: SigfoxDevice | SigfoxDevice[]) {
    if (!Array.isArray(devices)) {
      devices = [devices];
    }
    this.$uibModal
      .open({
        component: 'scSigfoxShowPac',
        backdrop: 'static',
        resolve: {
          params: () => ({ devices }),
        },
      })
      .result.then(angular.noop, angular.noop);
    // Mason 2018-08-23: This modal does not need any action taken when it is closed, but if we don't pass a no-op function for the cancel action, a "possibly unhandled rejection" error is logged by $uibModal library code.
  }
}
