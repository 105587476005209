export const template = `<div class="agree-terms-notices tos-jp" ng-if="$ctrl.shouldShowJpTerms()">
  <div class="form-group">
    <div class="accept-linked-tos-jp" translate="tos.accept_linked_tos.jp"></div>
  </div>
  <div class="form-group">
    <div class="checkbox agree-terms"><label><input id="agree-jp" type="checkbox"
          ng-model="$ctrl.agreedToTermsOfService" ng-change="$ctrl.checkboxChanged()" /><span class="tos-checkbox-jp"
          translate="tos.agree_checkbox_text.jp"></span></label><br /><br /><label><input
          id="affirm-minor-requirements-jp" type="checkbox"
          ng-model="$ctrl.affirmedJpSpecificFilteringServiceRequirement" ng-change="$ctrl.checkboxChanged()" /><span
          class="tos-checkbox-jp" translate="tos.affirm_minor_requirements_jp"></span></label></div>
  </div>
</div>
<div class="agree-terms-notices tos-g" ng-if="$ctrl.shouldShowGTerms()">
  <div class="form-group">
    <div class="accept-linked-tos" ng-bind-html="$ctrl.tosDescription()"></div>
  </div>
  <div class="form-group">
    <div class="checkbox agree-terms"><label><input id="agree-g" type="checkbox" ng-model="$ctrl.agreedToTermsOfService"
          ng-change="$ctrl.checkboxChanged()" /><span class="tos-checkbox">{{ $ctrl.tosLabel() }}</span></label></div>
  </div>
</div>
<div class="agree-terms-notices-error" ng-show="$ctrl.coverageTypeError()">
  <div class="text-danger"><strong>ERROR: Invalid coverage type.</strong></div>
</div>`;
