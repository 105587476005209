export const template = `<form class="x-lora-network-sets-add-form" name="$ctrl.loraNetworkSetAddForm" ng-submit="$ctrl.submit()">
  <div class="modal-header">
    <h4>{{ &quot;lora_network_set.modals.add_network_set.header&quot; | translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="form-group" sc-form-group-validity-classes="sc-form-group-validity-classes" target="name"><label
        class="control-label">{{ &quot;lora_network_set.modals.add_network_set.name&quot; | translate
        }}</label>&nbsp;<span class="text-danger">*</span><input class="form-control" id="lora-network-set-name"
        name="name" type="text" ng-model="$ctrl.name" ng-required="true" focus-me="true" /></div>
    <div class="form-group"><span class="help-block"><em><span class="text-danger">*</span>&nbsp;{{
          &quot;lora_network_set.modals.add_network_set.required&quot; | translate }}</em></span></div>
    <div class="form-group"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  </div>
  <div class="modal-footer">
    <div class="form-group"><button class="btn btn-default" id="lora-network-set-add-cancel" type="button"
        ng-click="$ctrl.cancel();">{{ &quot;lora_network_set.modals.add_network_set.cancel&quot; | translate
        }}</button><button class="btn btn-primary" id="lora-network-set-add-submit" type="button"
        ng-click="$ctrl.submit();" ng-disabled="!$ctrl.canSubmit()" sc-modal-default-action="sc-modal-default-action">{{
        &quot;lora_network_set.modals.add_network_set.submit&quot; | translate }}</button></div>
  </div>
</form>`;
