export const template = `<div class="btn-group item-chooser-outer" uib-dropdown="uib-dropdown"
  ng-class="{ &quot;wait-cursor&quot;: $ctrl.saving || $ctrl.removing }"><button
    class="btn btn-default dropdown-toggle item-chooser-button" uib-dropdown-toggle="uib-dropdown-toggle"
    ng-disabled="$ctrl.disabled">{{ $ctrl.currentItemName }} &nbsp;<span class="caret"></span></button>
  <ul class="dropdown-menu" role="menu" uib-dropdown-menu="uib-dropdown-menu">
    <li ng-show="$ctrl.showUnset" ng-class="$ctrl.unsetLinkClass()"><a href="" ng-click="$ctrl.unsetItem()">{{
        'item_chooser.unset' | translate }}</a></li>
    <li ng-repeat="item in $ctrl.items"><a href="" ng-click="$ctrl.setItem(item)">{{ $ctrl.getItemName(item) }}</a></li>
    <li class="divider" ng-show="$ctrl.showCreate"></li>
    <li id="create-item-button" ng-show="$ctrl.showCreate"><a href="" ng-click="$ctrl.createItem()">{{
        'item_chooser.create' | translate }}</a></li>
  </ul>
</div>`;
