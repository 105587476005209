import { LegacyAny } from '@soracom/shared/core';

import { InjectList } from '../core/injectable';
import { BaseDirectiveController } from './base_directive_controller';
import { KeyEventService } from './key_event_service';

/**
 * The sc-modal-default-action directive is for buttons in modals which should
 * respond to the Return key when no other element in the modal has keyboard
 * focus. The button's ng-click action will be invoked when Return is pressed,
 * unless the button also has an ng-disabled expression that evaluates to
 * true. This can also be used with <input type='submit'> element; if there is
 * no ng-click attribute, then the element's submit() will be invoked instead.
 *
 * Example:
 * <button sc-modal-default-action
 *         ng-click='vm.doSomething()'
 *         ng-disabled='!vm.canDoSomething()'
 * >
 *
 * This directive can also be used on any kind of element, to respond to the
 * Return key, if an Angular expression is provided. Example:
 *
 * <div sc-modal-default-action='vm.doSomething()'>
 *
 * The key event is listened for at the window level, using KeyEventService,
 * and this directive will execute its behavior if the modal containing the
 * element is frontmost, and no other element that can handle the Return
 * key (e.g., another button or text field) has the keyboard focus.
 *
 * This is useful because we cannot reliably trap the Return key using regular
 * DOM element 'onkeypress' events, when using $uibModal, because the focused
 * element will sometimes be a backing view behind the modal ('above' the modal
 * in the DOM hierarchy) and so nothing inside the modal will receive the event.
 *
 * CAUTION: If you have a single modal that shows/hides multiple views, then
 * you should use ng-if, not ng-show, to conditionally display the different
 * views. Otherwise, views not present in the DOM will still be active, and if
 * you have multiple elements using this sc-modal-default-action directive, all
 * of their actions will fire when Return is pressed. Using ng-if avoids this
 * problem.
 *
 * See Also: sc_on_return.directive.ts, which is essentially the first, less
 * specialized, version of this.
 */

export function ScModalDefaultActionDirective(): ng.IDirective {
  return {
    restrict: 'A',
    controller: ScModalDefaultActionController,

    // @ts-expect-error (legacy code incremental fix)
    link: (
      scope: ng.IScope,
      element: ng.IAugmentedJQuery,
      attributes: ng.IAttributes,
      controller: ScModalDefaultActionController
    ): void => {
      controller.activate(scope, element, attributes);
    },
  };
}

class ScModalDefaultActionController extends BaseDirectiveController {
  static $inject: InjectList = ['$log', '$timeout', 'KeyEventService'];
  // FIXME: Mason 2017-08-25: It is suboptimal to have to redundantly declare this in every subclass, and to have to modify all subclasses whenever the superclasses' $inject requirements change... maybe there is a better way?

  protected modalDefaultActionAttrValue = '';

  constructor($log: ng.ILogService, private $timeout: ng.ITimeoutService, private KeyEventService: KeyEventService) {
    super($log);

    this.setTraceEnabled(true);

    this.trace(
      'ScModalDefaultActionController constructed with injected properties $log and KeyEventService:',
      $log,
      KeyEventService
    );
  }

  /**
   *  The activate() method runs once, and performs initial setup.
   */
  activate(scope: ng.IScope, element: ng.IAugmentedJQuery, attributes?: ng.IAttributes) {
    super.activate(scope, element, attributes);

    // @ts-expect-error (legacy code incremental fix)
    this.modalDefaultActionAttrValue = attributes.scModalDefaultAction;

    // Mason 2017-08-24: FIXME: Maybe I should be using $watch(), not $observe() ?
    // https://stackoverflow.com/a/14907826/164017

    // @ts-expect-error (legacy code incremental fix)
    attributes.$observe('scModalDefaultAction', (value: string) => {
      this.modalDefaultActionAttrValue = value;
      this.trace('The value of sc-modal-default-action attribute was updated:', value);
    });

    scope.$on('$destroy', () => {
      this.debug('sc-modal-default-action scope will be destroyed; removing keypress handler.');
      this.KeyEventService.removeReturnKeyHandler(this.handleReturnKeyPress);
    });

    this.KeyEventService.addReturnKeyHandler(this.handleReturnKeyPress);
  }

  /**
   * The internal method that handles Return key press. This looks up the Angular
   * expression that is bound to the sc-modal-default-action attribute (e.g., '$ctrl.doFooBar()')
   * and evaluates (executes) it.
   */
  handleReturnKeyPress = (e: any) => {
    this.trace('sc-modal-default-action: internal Return keypress handler executing:');

    if (!this.shouldHandleReturnKeyPress(e)) {
      this.trace('This event does not qualify for sc-modal-default-action; ignoring.');
    } else {
      // Mason 2017-09-20: This code below is wrapped in scope.$apply() because
      // otherwise sometimes data structures will be updated but angular won't
      // see the changes until the digest() loop fires the next time.

      const handler = () => {
        if (this.ngDisabled()) {
          this.trace(
            `sc-modal-default-action: Return key press will be ignored, because ng-disabled value "${this.attributes.ngDisabled}" evaluates to a truthy value.`
          );
          return;
        }

        let actionExpression = this.modalDefaultActionAttrValue;

        if (!actionExpression || actionExpression === 'sc-modal-default-action') {
          actionExpression = this.attributes.ngClick;
        }

        if (actionExpression) {
          this.trace('sc-modal-default-action: will handle Return key press with this expression: ', actionExpression);
          this.eval(actionExpression);
        } else {
          this.trace(
            'The value bound to sc-modal-default-action is not a function, in which case the default is to use the action bound to the ng-click attribute of the element. However, that attribute does not exist.'
          );

          const jqueryElement: any = this.element;
          // Get underlying JQuery element, cast to 'any' because TS doesn't know about
          // jqueryElement[0].type.

          const hasSubmitFunc = jqueryElement.submit && typeof jqueryElement.submit === 'function';
          const typeIsSubmit = jqueryElement[0] && jqueryElement[0].type === 'submit';

          if (hasSubmitFunc && typeIsSubmit) {
            this.trace('However, this element is a submit input. So this Return key will invoke the submit() action.');

            this.$timeout(
              () => {
                jqueryElement.submit();
              },
              0,
              false
            );
            // We have to use $timeout() to wrap the submit() call, to avoid
            // https://docs.angularjs.org/error/$rootScope/inprog?p0=$apply
            // because we are already inside of an $apply() call.
          } else {
            this.trace(
              'This element also does not seem to be a form submit input. Nothing left to try; handling Return key press will be abandoned here.'
            );
          }
        }
      };

      this.scope.$apply(handler);
    }
  };

  shouldHandleReturnKeyPress(e: any) {
    const targetElement = e.target;
    const isModalContainer = targetElement.classList.contains('modal') && targetElement.tabIndex === -1;
    // We rely on these 2 implementation details of $uibModal to detect when the modal's
    // outer container has the focus (per document.activeElement). If so, we assume we should
    // respond to Return key by invoking the default action.

    // Mason 2017-08-23: However, in the case of a modal stacked upon another modal, the above tests
    // might still be true so we have to also make sure the target element contains the element
    // that pertains to this directive:
    const isAncestor = targetElement.contains(this.element[0]);
    return isModalContainer && isAncestor;
  }
}
