import { Harvestable } from '@soracom/shared/soracom-platform';
import { TableController } from '../../components/base_table_controller';
import { Action } from './Action';

const MAX_RESOURCES = 5;

/**
 * Generic action to open "SORACOM Harvest Data (a.k.a Data)" page.
 */
export class DataAction<T extends Harvestable> implements Action<T> {
  constructor(private ctrl: TableController<T>, private $location: ng.ILocationService) {}

  run() {
    if (this.ctrl.tableData.getSelectedData().length > MAX_RESOURCES) {
      const firstResource = this.ctrl.tableData.getSelectedData()[0];
      const resourceType = firstResource.resourceType;
      this.ctrl.alertsService.showAlert('danger', {
        translationId: `actions.data_action.${resourceType}.too_many_resources`,
        values: { max: MAX_RESOURCES },
      });
      return;
    }
    const resources = this.ctrl.tableData
      .getSelectedData()
      .map((resource) => {
        return `${resource.resourceType}#${resource.resourceId}`;
      })
      .join('&');
    this.$location.path('/harvest_data').search({ resources });
  }

  actionable() {
    return this.ctrl.tableData.countChecked() > 0;
  }
}
