export const template = `<app-user-notifications></app-user-notifications>
<div class="alerts"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
<div class="x-debug-root" id="page-wrapper">
  <div class="row block">
    <div class="col-sm-12">
      <h1>DEBUGGERY</h1>
    </div>
  </div>
  <div class="row block">
    <div class="col-sm-12">
      <h2>sc-group-params-krypton</h2>
      <hr /><sc-group-params-krypton group="$ctrl.dataForKryptonConfigEditor"></sc-group-params-krypton>
      <hr />
    </div>
  </div>
  <hr />
  <div class="row block">
    <div class="col-sm-12"><sc-contact-information contact-information="$ctrl.contactInformation" show-vat-id="true"
        allow-edit-company-name="true" allow-edit-country-code="true"></sc-contact-information></div>
  </div>
  <hr />
  <div class="row block">
    <div class="col-sm-12">
      <p>Favorite color (via the root component's <span style='font-family: monospace;'>favoriteColor</span> input
        binding): {{ $ctrl.favoriteColor }}</p>
    </div>
  </div>
  <div class="row block">
    <hr />
    <div class="bottom" sc-context-menu="bottom-context-menu"
      style="background-color: hotpink; width: 300px; height: 300px;">
      <div id="bottom-context-menu" style="background-color: brown;">
        <p>This is the bottom context menu.</p>
      </div>
      <div class="middle" sc-context-menu="middle-context-menu"
        style="background-color: yellow; width: 200px; height: 200px;">
        <div id="middle-context-menu" style="background-color: green;">
          <p>This is the middle context menu.</p>
        </div>
        <div class="browser-menu" sc-context-menu="default"
          style="background-color: blue; width: 100px; height: 100px;">
          <div class="no-menu-allowed" sc-context-menu="disable"
            style="background-color: red; width: 50px; height: 50px;"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row block">
    <div class="col-sm-12">
      <h2>"Test, bro..."</h2><button class="btn btn-default" type="button" ng-click="$ctrl.testBro()">testBro()</button>
      <form>
        <div class="form-horizontal">
          <div class="form-group" sc-form-group-validity-classes="sc-form-group-validity-classes" target="default-name">
            <label class="col-sm-3 control-label" for="default-name">input</label>
            <div class="col-sm-9"><input class="form-control" name="default-name" type="text"
                ng-model="$ctrl.inputForTestBro" /></div>
          </div>
        </div>
      </form>
    </div>
  </div><br />
  <div class="row block">
    <div class="col-sm-12">
      <h1 sc-on-return="$ctrl.testScOnReturnKeypressHandler()">sc-on-return</h1>
      <p>{{ $ctrl.testScOnReturnStatusMessage }}</p><button
        ng-click="$ctrl.shouldReactToScOnReturnEvents = true">REGISTER</button><button
        ng-click="$ctrl.shouldReactToScOnReturnEvents = false">UNREGISTER</button>
    </div>
  </div>
  <div class="row block">
    <div class="col-sm-12">
      <h1>sc-modal-default-action</h1>
      <p>Modal default action count: {{$ctrl._testScModalDefaultActionCount}}</p><button
        id="test-sc-modal-default-action" sc-modal-default-action="sc-modal-default-action"
        ng-click="$ctrl.showTestModalForScModalDefaultAction()">TEST sc-modal-default-action</button>
    </div>
  </div>
  <div class="row block">
    <div class="col-sm-12">
      <h1>KeyEventService</h1>
      <p>--</p><button id="test-key-event-service"
        ng-click="$ctrl.debugPrintKeyEventService()">debugPrintKeyEventService()</button>
    </div>
  </div>
</div>`;
