import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { BaseTableController } from '../components/base_table_controller';
import { Action } from '../core/actions/Action';
import { DeviceObjectModel } from '../core/device_object_model';
import { DeviceObjectModelsService } from './device_object_models.service';

export class AddDeviceObjectModelAction implements Action<DeviceObjectModel> {
  constructor(
    private ctrl: BaseTableController<DeviceObjectModel>,
    private $uibModal: LegacyAny,
    private service: DeviceObjectModelsService
  ) {}

  run(target?: DeviceObjectModel | DeviceObjectModel[]) {
    this.$uibModal
      .open({
        backdrop: 'true',
        component: 'scAddDeviceObjectModelModalComponent',
        resolve: {
          model: () => {
            return new DeviceObjectModel({ format: 'xml', scope: 'default' });
          },
          schema: () => {
            return null;
          },
        },
        size: 'lg',
      })
      .result.then((result: { model: DeviceObjectModel }) => {
        this.ctrl.reloadData();
      })
      .catch(angular.noop);
  }

  actionable(target?: DeviceObjectModel | DeviceObjectModel[]): boolean {
    return true;
  }
}

export class ShowDeviceObjectModelAction implements Action<DeviceObjectModel> {
  constructor(
    private ctrl: BaseTableController<DeviceObjectModel>,
    private $q: ng.IQService,
    private $uibModal: LegacyAny,
    private service: DeviceObjectModelsService
  ) {}

  run(target?: DeviceObjectModel | DeviceObjectModel[]) {
    // @ts-expect-error (legacy code incremental fix)
    const model = target[0];
    this.fetchSchema(model).then((schema) => {
      this.$uibModal
        .open({
          backdrop: 'true',
          component: 'scAddDeviceObjectModelModalComponent',
          resolve: {
            model: () => {
              return model;
            },
            schema: () => {
              return schema;
            },
          },
          size: 'lg',
        })
        .result.then((result: { model: DeviceObjectModel }) => {
          this.ctrl.reloadData();
        })
        .catch(angular.noop);
    });
  }

  private fetchSchema(obj: DeviceObjectModel) {
    const defered = this.$q.defer();
    const modelId = obj.id;
    if (modelId) {
      // This looks weird, but API returns XML with content-type text/plain
      const schemaFormat = obj.format === 'xml' ? 'text' : 'json';

      this.service
        .getSchema(modelId, schemaFormat)
        .then((schema: LegacyAny) => {
          if (typeof schema === 'object') {
            defered.resolve(JSON.stringify(schema, null, 2));
          } else {
            defered.resolve(schema);
          }
        })
        .catch((error: LegacyAny) => {
          defered.reject(error);
        });
    } else {
      defered.reject();
    }

    return defered.promise;
  }

  actionable(target?: DeviceObjectModel | DeviceObjectModel[]): boolean {
    return this.ctrl.tableData.countChecked() === 1;
  }
}

export class DeleteDeviceObjectModelAction implements Action<DeviceObjectModel> {
  constructor(
    private ctrl: BaseTableController<DeviceObjectModel>,
    private $uibModal: LegacyAny,
    private service: DeviceObjectModelsService
  ) {}

  run(target?: DeviceObjectModel | DeviceObjectModel[]) {
    let model: DeviceObjectModel;
    if (target instanceof DeviceObjectModel) {
      model = target;
    } else {
      // @ts-expect-error (legacy code incremental fix)
      model = target[0];
    }
    this.$uibModal
      .open({
        backdrop: 'true',
        component: 'scDeleteDeviceObjectModelModalComponent',
        resolve: {
          model: () => {
            return model;
          },
        },
        size: 'md',
      })
      .result.then((result: { model: DeviceObjectModel }) => {
        this.service.delete(model.id).then((result: LegacyAny) => {
          this.ctrl.reloadData();
          this.ctrl.alertsService.showSuccess('device_object_models.deleted_successfully');
        });
      })
      .catch(angular.noop);
  }

  actionable(target?: DeviceObjectModel | DeviceObjectModel[]): boolean {
    return this.ctrl.tableData.countChecked() === 1;
  }
}
