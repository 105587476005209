import { HarvestDirectory, HarvestFile } from '../core/harvest_file';

export class HarvestFileBreadcrumb {
  path: string;
  href: string;

  constructor(path: string, public label: string) {
    this.path = HarvestFile.formatPath(path);
    this.href = `/harvest_files?path=${this.path}`;
  }

  static generate(directory: HarvestDirectory): HarvestFileBreadcrumb[] {
    const formatted = directory.humanReadablePath;
    const decoded = directory.decodedPath.split('/');

    return formatted
      .split('/')
      .map((element, index, arr) => {
        if (index === 0) {
          // Add a special label to root element
          return new HarvestFileBreadcrumb('/', '[root]');
        } else if (index !== arr.length - 1) {
          // it's directory. so, it should include trailing slash in the path.
          const path = decoded.slice(0, index + 1).join('/') + '/';
          return new HarvestFileBreadcrumb(path, element);
        } else {
          // index === arr.length -1, thus, the last element
          // Ignore the last element. because "file" should be removed in breadcrumb
          //
          // Example A: "/a/".split("/") => ["", "a", ""] => ["[root]", "a", null])
          // Example B: "/a/b".split("/") => ["", "a", "b"] => ["[root]", "a", null])
          return null;
        }
      })
      .filter((e) => e !== null);
  }
}
