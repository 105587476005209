import { LegacyAny } from '@soracom/shared/core';

export class SubscriberSearchStatusFactory {
  // noinspection JSMethodCanBeStatic
  create(searchStatusCode: number): SubscriberSearchStatus {
    return new SubscriberSearchStatus(searchStatusCode);
  }
}

class SubscriberSearchStatus {
  private static readonly API_CALL_LIMIT_EXCEEDED_ERROR_CODE = 429;
  private static readonly DENIED_ATTRIBUTE_ERROR_CODE = 499;

  constructor(private searchStatusCode: LegacyAny) {}

  isSucceeded(): boolean {
    return 200 <= this.searchStatusCode && this.searchStatusCode <= 299;
  }

  isAPICallLimitExceeded(): boolean {
    return this.searchStatusCode === SubscriberSearchStatus.API_CALL_LIMIT_EXCEEDED_ERROR_CODE;
  }

  isInvalidRequest(): boolean {
    return 400 <= this.searchStatusCode && this.searchStatusCode <= 499;
  }

  isDeniedAttribute(): boolean {
    return this.searchStatusCode === SubscriberSearchStatus.DENIED_ATTRIBUTE_ERROR_CODE;
  }

  isServerError(): boolean {
    return 500 <= this.searchStatusCode && this.searchStatusCode <= 599;
  }
}
