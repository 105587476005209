import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoggerService } from '@soracom/shared-ng/logger-service';
import { Logger } from '@soracom/shared/logger';
import { LocalStorageService } from '@soracom/forks/ngx-store';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SubscriberSearchQueryParam } from '../../../../app/shared/components/subscriber_search_query_param';
import { SubscriberSearchType } from '../../../../app/shared/components/subscriber_search_type';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscribersService } from '../../../../app/shared/subscribers/subscribers.service';
import { SubscriberSearchSessionStatus } from '../../../../app/shared/subscribers/subscriber_search_session_status';
import { LteMButtonStoreSelectors, RootStoreState } from '../../root-store';
import * as Actions from '../../root-store/lte-m-button-store/actions';
import { DEFAULT_INPUT_DEBOUNCE_TIME } from '../../shared/user_interaction';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiPaginator } from '../../soracom-ui/ui-pagination/UiPaginator';
import { UiTableContent } from '../../soracom-ui/ui-table/ui-table-content';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';
import { LteMButtonStateChangedEvent } from '../lte-m-button/lte-m-button.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const MAX_RESOURCES = 5;

export enum LteMButtonIndexComponentActions {
  newLteMButtonConfig = 'x-new-lte-m-button-config',
  editLteMButtonConfig = 'x-edit-lte-m-button-config',
  editGroupConfig = 'x-edit-group-config',
  linkToHarvestData = 'x-link-to-harvest-data',
  removeLteMButtonConfig = 'x-remove-lte-m-button-config',
}

@Component({
  selector: 'app-lte-m-button-index',
  templateUrl: './lte-m-button-index.component.html',
})
export class LteMButtonIndexComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  // This output is only used for navigation.
  @Output() onStateChanged = new EventEmitter<LteMButtonStateChangedEvent>();
  private logger: Logger;

  // Selected subscribers in this component
  private selectedSubscribers: ExtendedSubscriberInterface[] = [];
  private loadingRequired = this.store$.select(LteMButtonStoreSelectors.selectLteMButtonIndexPageLoadingRequired);

  // UiTable
  columns: Array<UiTableColumn<ExtendedSubscriberInterface>> = [
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('LteMButtonNewSubscribersSelectComponent', 'dsn', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('LteMButtonNewSubscribersSelectComponent', 'name', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('LteMButtonNewSubscribersSelectComponent', 'imsi', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('LteMButtonNewSubscribersSelectComponent', 'group', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('LteMButtonNewSubscribersSelectComponent', 'status', null),
  ];
  // @ts-expect-error (legacy code incremental fix)
  creator: new () => ExtendedSubscriberInterface;
  content: UiTableContent<ExtendedSubscriberInterface> = new UiTableContent<ExtendedSubscriberInterface>([], this.columns, {
    multiSelect: true,
  });
  paginationContent: UiPaginator<ExtendedSubscriberInterface>;
  searchTextControl = new FormControl();

  constructor(
    localStorageService: LocalStorageService,
    private store$: Store<RootStoreState.State>,
    subscribersService: SubscribersService
  ) {
    this.logger = LoggerService.shared();
    this.paginationContent = new UiPaginator<ExtendedSubscriberInterface>(localStorageService, subscribersService);
    this.paginationContent.onDataLoaded.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((relation) => {
      this.content.setData(relation.data);
    });
    // Need to subscribe here not to miss a redux event.
    this.loadingRequired.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((required) => {
      if (required) {
        this.searchSubscribers();
      }
    });
  }

  searchSubscribers = (searchText = '') => {
    const queryParam = new SubscriberSearchQueryParam(
      {
        tag: SubscriberSearchQueryParam.getValueWithDefault('LTE-M-Button', SubscriberSearchSessionStatus.NA),
        imsi: '44052',
      },
      this.paginationContent.perPage,
      // @ts-expect-error (legacy code incremental fix)
      undefined,
      SubscriberSearchType.AND,
      SubscriberSearchSessionStatus.NA
    );
    this.paginationContent.listApiOptions.mode = 'query';
    this.paginationContent.listApiOptions.assignGroup = true;
    if (searchText && searchText.length >= 2) {
      queryParam.query.name = searchText;
    }
    this.paginationContent.searchQuery = queryParam.toSearchQuery();
    this.paginationContent
      .reload()
      .catch((e: LegacyAny) => {
        this.store$.dispatch(Actions.loadConfiguredSubscribersError(e));
      })
      .finally(() => {
        this.store$.dispatch(Actions.loadConfiguredSubscribersSuccess());
      });
  };

  ngOnInit() {
    this.searchTextControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((input) => input.length !== 1),
        debounceTime(DEFAULT_INPUT_DEBOUNCE_TIME),
        distinctUntilChanged()
      )
      .subscribe(this.searchSubscribers);
  }

  /**
   * Actions
   */

  createNew = () => {
    this.logger.debug('createNew called');
    this.store$.dispatch(Actions.configureNewDevice());
  };

  editLteMButtonConfig = () => {
    if (this.selectedSubscribers.length === 0) {
      this.logger.error('Please select subscribers');
      return;
    }

    const groupId = this.selectedSubscribers[0].groupId;
    if (groupId) {
      this.store$.dispatch(Actions.editLteMButtonConfiguration({ groupId }));
    } else {
      const imsiList = this.selectedSubscribers.map((s) => s.imsi);
      this.store$.dispatch(Actions.selectGroup({ imsiList }));
    }
  };

  editGroupConfig = () => {
    if (this.selectedSubscribers.length === 0) {
      this.logger.error('Please select subscribers');
      return;
    }

    const groupId = this.selectedSubscribers[0].groupId;
    if (groupId) {
      this.onStateChanged.emit({ newState: 'editGroupConfig', attributes: { groupId } });
    }
  };

  linkToHarvestData = () => {
    this.logger.debug('linkToHarvestData called');
    this.onStateChanged.emit({
      newState: 'linkToHarvestData',
      attributes: { selectedSubscribers: this.selectedSubscribers },
    });
  };

  removeLteMButtonConfig = () => {
    this.logger.debug('removeLteMButtonConfig called');
    this.onStateChanged.emit({
      newState: 'removeLteMButtonConfig',
      attributes: { selectedSubscribers: this.selectedSubscribers },
    });
  };

  buttonBar = UiButtonBar.configure((bar) => {
    bar.leftButtons = [
      UiButton.configure((b) => {
        b.iconName = 'icon-plus';
        b.buttonStyle = 'primary';
        b.titleId = 'LteMButtonIndexComponent.actions.newLteMButtonConfig.title';
        b.classes = [LteMButtonIndexComponentActions.newLteMButtonConfig];
        b.onClick = this.createNew;
      }),
      UiButton.configure((b) => {
        b.titleId = 'LteMButtonIndexComponent.actions.editLteMButtonConfig.title';
        b.classes = [LteMButtonIndexComponentActions.editLteMButtonConfig];
        b.onClick = this.editLteMButtonConfig;
        b.isDisabled_ƒ = () => {
          const length = this.selectedSubscribers.length;
          return length !== 1;
        };
      }),
      UiButton.configure((b) => {
        b.titleId = 'LteMButtonIndexComponent.actions.editGroupConfig.title';
        b.classes = [LteMButtonIndexComponentActions.editGroupConfig];
        b.onClick = this.editGroupConfig;
        b.isDisabled_ƒ = () => {
          const length = this.selectedSubscribers.length;
          return !(length === 1 && this.selectedSubscribers[0].groupId);
        };
      }),
      UiButton.configure((b) => {
        b.titleId = 'LteMButtonIndexComponent.actions.linkToHarvestData.title';
        b.classes = [LteMButtonIndexComponentActions.linkToHarvestData];
        b.onClick = this.linkToHarvestData;
        b.isDisabled_ƒ = () => {
          const length = this.selectedSubscribers.length;
          return !(length > 0 && length <= MAX_RESOURCES);
        };
      }),
      UiButton.configure((b) => {
        b.titleId = 'LteMButtonIndexComponent.actions.removeLteMButtonConfig.title';
        b.classes = [LteMButtonIndexComponentActions.removeLteMButtonConfig];
        b.onClick = this.removeLteMButtonConfig;
        b.isDisabled_ƒ = () => {
          return this.selectedSubscribers.length === 0;
        };
      }),
    ];
  });

  subscriberSelected(subscribers: ExtendedSubscriberInterface[]) {
    this.selectedSubscribers = subscribers;
  }
}
