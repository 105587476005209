import { LegacyAny } from '@soracom/shared/core';

import { InjectList } from '../core/injectable';
import { BaseController } from './base_controller';
import { template } from './tutorial.component.html';
import { TutorialBox, Tutorials, tutorials } from './tutorials';
export class TutorialComponent implements ng.IComponentOptions {
  bindings = {};
  controller = TutorialController;
  template: any = template;
}

export class TutorialController extends BaseController {
  static $inject: InjectList = ['$log', '$translate'];

  tutorials: Tutorials;
  // @ts-expect-error (legacy code incremental fix)
  tutorialBox: TutorialBox;
  constructor(private $log: ng.ILogService, private $translate: any) {
    super($log);
    this.tutorials = tutorials as {} as Tutorials;
  }

  $onInit() {
    const lang = this.$translate.use();
    try {
      if (!!(this.tutorials as LegacyAny)[lang]) {
        this.tutorialBox = (this.tutorials as LegacyAny)[lang];
      }
    } catch (err) {
      // @ts-expect-error (legacy code incremental fix)
      this.tutorialBox = null;
    }
  }
}
