import { AlertsService, AlertsServiceInstance } from '../../components/alerts.service';
import { BaseModalController } from '../../components/base_modal_controller';
import { DeviceKey } from '../../core/device_key';
import { InjectList } from '../../core/injectable';
import { template } from './add_device_key_modal.component.html';

/**
 * A component for add device (in a modal).
 */
export class AddDeviceKeyModalComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = AddDeviceKeyModalComponentController;
  template: any = template;
}

class AddDeviceKeyModalComponentController extends BaseModalController {
  static $inject: InjectList = ['$log', '$timeout', 'AlertsService'];

  alertsService: AlertsServiceInstance;

  // @ts-expect-error (legacy code incremental fix)
  deviceId: string;
  // @ts-expect-error (legacy code incremental fix)
  deviceKey: DeviceKey;

  constructor(
    private $log: ng.ILogService,
    private $timeout: ng.ITimeoutService,
    private AlertsService: AlertsService
  ) {
    super($log);
    this.alertsService = AlertsService.generate();
    this.setTraceEnabled(true);
  }

  $onInit() {
    this.deviceId = this.resolve.deviceId;
    this.deviceKey = this.resolve.deviceKey;
  }

  doCopy() {
    this.$timeout(() => {
      window.jQuery('#add-device-key-copy').click();
    });
  }

  copy() {
    return `deviceId,keyId,secretKey\n${this.deviceId},${this.deviceKey.keyId},${this.deviceKey.secretKey}`;
  }

  copyable() {
    return true;
  }

  onCopied() {
    this.alertsService.showSuccess('device_keys.modals.add_device_key.copied');
  }

  onCopyError() {
    this.alertsService.showError('device_keys.modals.add_device_key.copy_failed');
  }
}
