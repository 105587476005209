<section dsDialogContent>
  <div class="ds-rows">
    <div class="ds-notice--warning" [innerHTML]="'DeleteLagoonUserModalComponent.text' | translate"></div>
    <div class="ds-field">
      <label class="ds-label">{{ 'DeleteLagoonUserModalComponent.email' | translate }}</label>
      <div *ngFor="let lagoonUser of lagoonUsers" class="ds-text--inset">
        {{ lagoonUser.email }}
      </div>
    </div>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary --alert x-confirm" [class.--loading]="loading" (click)="confirm()">
    <span [innerHTML]="'DeleteLagoonUserModalComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>
