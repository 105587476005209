import { LegacyAny } from '@soracom/shared/core';

import { Taggable } from '@soracom/shared/soracom-platform';
import { TaggableService } from '@user-console/legacy-soracom-api-client';
import { AlertsServiceInstance } from './alerts.service';
import { BaseController } from './base_controller';

export interface EditTagController {
  editing: Taggable;
  save(key: string, value: string): Promise<any>;
  remove(key: string): Promise<any>;
}

export class BaseEditTagController extends BaseController implements EditTagController {
  // @ts-expect-error (legacy code incremental fix)
  editing: Taggable;
  // @ts-expect-error (legacy code incremental fix)
  service: TaggableService;
  // @ts-expect-error (legacy code incremental fix)
  alertsService: AlertsServiceInstance;
  okAction: () => void;

  constructor(
    $log: ng.ILogService,
    private $q: ng.IQService,
  ) {
    super($log);
    this.okAction = () => {
      /* noop */
    };
  }

  /**
   * Save tag key & value to editing object.
   */
  save(key: string, value: any) {
    const d = this.$q.defer();
    return this.updateTag(key, value)
      .then(() => {
        return this.okAction();
      })
      .catch((error: LegacyAny) => {
        this.ngAction(error);
      });
  }

  remove(key: string) {
    return this.deleteTag(key)
      .then(() => {
        return this.okAction();
      })
      .catch((error: LegacyAny) => {
        this.ngAction(error);
      });
  }

  updateTag(key: string, value: any) {
    const idString = String(this.editing.id);
    return this.service.updateTags(idString, [{ tagName: key, tagValue: value }]).then(() => {
      this.editing.tags[key] = value;
    });
  }

  deleteTag(key: string) {
    const idString = String(this.editing.id);
    return this.service.deleteTag(idString, key).then(() => {
      delete this.editing.tags[key];
    });
  }

  ngAction(error: Error) {
    if (this.alertsService) {
      this.alertsService.showError(error);
    }
  }
}
