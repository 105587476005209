import { LegacyAny } from '@soracom/shared/core';

import { AlertsService } from '../components/alerts.service';
import { BaseEditTagController } from '../components/base_edit_tag.controller';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import { Action } from '../core/actions/Action';
import { EditTagsAction } from '../../../src/app/ng-rewrites/legacy-actions/edit_tags_action';
import { ReloadTableAction } from '../core/actions/ReloadTableAction';
import { ShowResourceAction } from '../core/actions/ShowResourceAction';
import { InjectList } from '../core/injectable';
import { LoraNetworkSet } from '../core/lora_network_set';
import { template } from './lora_network_sets.component.html';
import { LoraNetworkSetsService } from './lora_network_sets.service';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

export class LoraNetworkSetsComponent implements ng.IComponentOptions {
  bindings = {};
  controller = LoraNetworkSetsComponentController;
  template: any = template;
}

export class LoraNetworkSetsComponentController extends BasePaginatableTableController<LoraNetworkSet> {
  static $inject: InjectList = [
    '$location',
    '$log',
    `$translate`,
    '$q',
    '$uibModal',
    'AlertsService',
    'LoraNetworkSetsService',
    'UiDsModalService',
  ];

  constructor(
    private $location: ng.ILocationService,
    $log: ng.ILogService,
    $translate: LegacyAny,
    $q: ng.IQService,
    private $uibModal: LegacyAny,
    alertsService: AlertsService,
    private loraNetworkSetsService: LoraNetworkSetsService,
    private uiDsModalService: UiDsModalService
  ) {
    // @ts-expect-error (legacy code incremental fix)
    super($log, $translate, $q, alertsService, loraNetworkSetsService);
    this.modelName = 'LoraNetworkSets';
  }

  $onInit() {
    super.$onInit();

    this.setTraceEnabled(true);
    this.trace(this);

    this.actionContainer.actions = {
      Add: new AddLoraNetworkSetAction(this, this.$uibModal),
      EditTag: new EditTagsAction<LoraNetworkSet>(this.uiDsModalService, this.loraNetworkSetsService),
      Reload: new ReloadTableAction(this),
      Show: new ShowResourceAction(this, this.$location, '/lora_network_sets/'),
    };

    this.reloadData();
  }

  setData(data: LoraNetworkSet[]) {
    super.setData(data);
    this.tableData.rows.forEach((row) => {
      // FIXME: Super dirty hack to work with nameEditCtrl....
      const nameEditCtrl = new BaseEditTagController(this.logService, this.$q);
      nameEditCtrl.service = this.loraNetworkSetsService;
      nameEditCtrl.editing = row.obj;
      nameEditCtrl.alertsService = this.alertsService;
      row.nameEditCtrl = nameEditCtrl;
    });
  }
}

export class AddLoraNetworkSetAction implements Action<LoraNetworkSet> {
  constructor(private ctrl: LoraNetworkSetsComponentController, private $uibModal: LegacyAny) {}

  run() {
    this.$uibModal
      .open({
        component: 'scAddLoraNetworkSetModalComponent',
        backdrop: 'static',
      })
      .result.then(this.okAction, this.cancelAction);
  }

  actionable() {
    return true;
  }

  private okAction = (result: LegacyAny) => {
    if (result instanceof LoraNetworkSet) {
      this.ctrl.tableData.appendData(result);
      this.ctrl.alertsService.showInfo('lora_network_sets.addition_success_message');
    } else {
      this.ctrl.alertsService.showAlert('danger', { translationId: 'lora_network_sets.addition_failure_message' });
    }
  };

  private cancelAction = () => {
    // noop
  };
}
