import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoggerService } from '@soracom/shared-ng/logger-service';
import { Logger } from '@soracom/shared/logger';
import { LocalStorageService } from '@soracom/forks/ngx-store';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SubscriberSearchQueryParam } from '../../../../app/shared/components/subscriber_search_query_param';
import { SubscriberSearchType } from '../../../../app/shared/components/subscriber_search_type';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscribersService } from '../../../../app/shared/subscribers/subscribers.service';
import { SubscriberSearchSessionStatus } from '../../../../app/shared/subscribers/subscriber_search_session_status';
import { GpsMultiunitStoreSelectors, RootStoreState } from '../../root-store';
import * as Actions from '../../root-store/gps-multiunit-store/actions';
import { DEFAULT_INPUT_DEBOUNCE_TIME } from '../../shared/user_interaction';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiPaginator } from '../../soracom-ui/ui-pagination/UiPaginator';
import { UiTableContent } from '../../soracom-ui/ui-table/ui-table-content';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';
import { GpsMultiunitStateChangedEvent } from '../gps-multiunit/gps-multiunit.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const MAX_RESOURCES = 5;

export enum GpsMultiunitIndexComponentActions {
  newGpsMultiunitConfig = 'x-new-gps-multiunit-config',
  editGpsMultiunitConfig = 'x-edit-gps-multiunit-config',
  editGroupConfig = 'x-edit-group-config',
  linkToHarvestData = 'x-link-to-harvest-data',
  removeGpsMultiunitConfig = 'x-remove-gps-multiunit-config',
}

@Component({
  selector: 'app-gps-multiunit-index',
  templateUrl: './gps-multiunit-index.component.html',
})
export class GpsMultiunitIndexComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  // This output is only used for navigation.
  @Output() onStateChanged = new EventEmitter<GpsMultiunitStateChangedEvent>();

  private logger: Logger;

  // Selected subscribers in this component
  private selectedSubscribers: ExtendedSubscriberInterface[] = [];
  private loadingRequired = this.store$.select(GpsMultiunitStoreSelectors.selectGpsMultiunitIndexPageLoadingRequired);

  // UiTable
  columns: Array<UiTableColumn<ExtendedSubscriberInterface>> = [
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'imsi', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'name', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'group', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'status', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'subscription', null),
  ];
  // @ts-expect-error (legacy code incremental fix)
  creator: new () => ExtendedSubscriberInterface;
  content: UiTableContent<ExtendedSubscriberInterface> = new UiTableContent<ExtendedSubscriberInterface>([], this.columns, {
    multiSelect: true,
  });
  paginationContent: UiPaginator<ExtendedSubscriberInterface>;
  searchTextControl = new FormControl();

  searchSubscribers = (searchText = '') => {
    const queryParam = new SubscriberSearchQueryParam(
      {
        tag: SubscriberSearchQueryParam.getValueWithDefault('GPS-Multiunit', SubscriberSearchSessionStatus.NA),
      },
      this.paginationContent.perPage,
      // @ts-expect-error (legacy code incremental fix)
      undefined,
      SubscriberSearchType.AND,
      SubscriberSearchSessionStatus.NA
    );
    this.paginationContent.listApiOptions.mode = 'query';
    this.paginationContent.listApiOptions.assignGroup = true;
    if (searchText && searchText.length >= 2) {
      queryParam.query.name = searchText;
    }
    this.paginationContent.searchQuery = queryParam.toSearchQuery();
    this.paginationContent
      .reload()
      .catch((e: LegacyAny) => {
        this.store$.dispatch(Actions.loadConfiguredSubscribersError(e));
      })
      .finally(() => {
        this.store$.dispatch(Actions.loadConfiguredSubscribersSuccess());
      });
  };

  /**
   * Actions
   */

  createNew = () => {
    this.logger.debug('createNew called');
    this.store$.dispatch(Actions.configureNewDevice());
  };

  editGpsMultiunitConfig = () => {
    if (this.selectedSubscribers.length === 0) {
      this.logger.error('Please select subscribers');
      return;
    }

    const groupId = this.selectedSubscribers[0].groupId;
    if (groupId) {
      this.store$.dispatch(Actions.editGpsMultiunitConfiguration({ groupId }));
    } else {
      const imsiList = this.selectedSubscribers.map((s) => s.imsi);
      this.store$.dispatch(Actions.selectGroup({ imsiList }));
    }
  };

  editGroupConfig = () => {
    if (this.selectedSubscribers.length === 0) {
      this.logger.error('Please select subscribers');
      return;
    }

    const groupId = this.selectedSubscribers[0].groupId;
    if (groupId) {
      this.onStateChanged.emit({ newState: 'editGroupConfig', attributes: { groupId } });
    }
  };

  linkToHarvestData = () => {
    this.logger.debug('linkToHarvestData called');
    this.onStateChanged.emit({
      newState: 'linkToHarvestData',
      attributes: { selectedSubscribers: this.selectedSubscribers },
    });
  };

  removeGpsMultiunitConfig = () => {
    this.logger.debug('removeGpsMultiunitConfig called');
    this.onStateChanged.emit({
      newState: 'removeGpsMultiunitConfig',
      attributes: { selectedSubscribers: this.selectedSubscribers },
    });
  };

  buttonBar = UiButtonBar.configure((bar) => {
    bar.leftButtons = [
      UiButton.configure((b) => {
        b.iconName = 'icon-plus';
        b.buttonStyle = 'primary';
        b.titleId = 'GpsMultiunitIndexComponent.actions.newGpsMultiunitConfig.title';
        b.classes = [GpsMultiunitIndexComponentActions.newGpsMultiunitConfig];
        b.onClick = this.createNew;
      }),
      UiButton.configure((b) => {
        b.titleId = 'GpsMultiunitIndexComponent.actions.editGpsMultiunitConfig.title';
        b.classes = [GpsMultiunitIndexComponentActions.editGpsMultiunitConfig];
        b.onClick = this.editGpsMultiunitConfig;
        b.isDisabled_ƒ = () => {
          const length = this.selectedSubscribers.length;
          return length !== 1;
        };
      }),
      UiButton.configure((b) => {
        b.titleId = 'GpsMultiunitIndexComponent.actions.editGroupConfig.title';
        b.classes = [GpsMultiunitIndexComponentActions.editGroupConfig];
        b.onClick = this.editGroupConfig;
        b.isDisabled_ƒ = () => {
          const length = this.selectedSubscribers.length;
          return !(length === 1 && this.selectedSubscribers[0].groupId);
        };
      }),
      UiButton.configure((b) => {
        b.titleId = 'GpsMultiunitIndexComponent.actions.linkToHarvestData.title';
        b.classes = [GpsMultiunitIndexComponentActions.linkToHarvestData];
        b.onClick = this.linkToHarvestData;
        b.isDisabled_ƒ = () => {
          const length = this.selectedSubscribers.length;
          return !(length > 0 && length <= MAX_RESOURCES);
        };
      }),
      UiButton.configure((b) => {
        b.titleId = 'GpsMultiunitIndexComponent.actions.removeGpsMultiunitConfig.title';
        b.classes = [GpsMultiunitIndexComponentActions.removeGpsMultiunitConfig];
        b.onClick = this.removeGpsMultiunitConfig;
        b.isDisabled_ƒ = () => {
          return this.selectedSubscribers.length === 0;
        };
      }),
    ];
  });

  constructor(
    localStorageService: LocalStorageService,
    private store$: Store<RootStoreState.State>,
    subscribersService: SubscribersService
  ) {
    this.logger = LoggerService.shared();
    this.paginationContent = new UiPaginator<ExtendedSubscriberInterface>(localStorageService, subscribersService);
    this.paginationContent.onDataLoaded.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((relation) => {
      this.content.setData(relation.data);
    });
    // Need to subscribe here not to miss a redux event.
    this.loadingRequired.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((required) => {
      if (required) {
        this.searchSubscribers();
      }
    });
  }

  ngOnInit() {
    this.searchTextControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((input) => input.length !== 1),
        debounceTime(DEFAULT_INPUT_DEBOUNCE_TIME),
        distinctUntilChanged()
      )
      .subscribe(this.searchSubscribers);
  }

  subscriberSelected(subscribers: ExtendedSubscriberInterface[]) {
    this.selectedSubscribers = subscribers;
  }
}
