import { InjectionToken } from '@angular/core';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

/**
 * Note: this module uses the AlertsManager class in an unusual way. Normally,
 * an AlertsManager is instantiated and held privately in the component which
 * renders the alert element.  Here the AlertManager is injected into the class
 * that hosts it, and is available to that or any object in the module:
 * children, as well as service classes that aren't associated otherwise
 * with the dom (eg, LogViewerSubscriptionModalService).
 */
export const LogModuleSharedAlertsManager = new InjectionToken<AlertsManager>(
  'AlertsManager shared between components of SoracomLogsModule',
  {
    factory: () => new AlertsManager(),
    // sure seems like 'providedIn' should be possible, using forwardRef...
    // but, nope!  We just receive the usual runtime errors, something about NgModule undefined
    // FIXME: when user console modules become Lazy, this providedIn should work (and it will be important)
    // providedIn: forwardRef( () => SoracomLogsModule ),
  }
);
