export const template = `<form name="sendDownlinkMessageForm" translate-namespace="send_downlink_message" novalidate="novalidate">
  <div class="x-form-contents">
    <div class="modal-header">
      <h4 translate=".title"></h4>
    </div>
    <div class="modal-body">
      <div class="form-horizontal">
        <div class="form-group"><label class="col-sm-3 control-label"
            translate="{{ '.' + $ctrl.downlinkMessageType + '.device_id' }}"></label>
          <div class="col-sm-9">
            <p class="form-control-static" ng-repeat="deviceId in $ctrl.deviceIds">{{ deviceId }}</p>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-3 control-label"><span translate=".data"></span><sc-tooltip
              description-id="'.' + $ctrl.downlinkMessageType + '.tooltip_for_data'" size="'md'"></sc-tooltip></label>
          <div class="col-sm-9"><input class="form-control" type="text" ng-model="$ctrl.downlinkMessageData"
              ng-pattern="$ctrl.dataRegex()" ng-minlength="$ctrl.dataMinimumLength()" ng-required="true" /></div>
        </div>
        <div class="form-group" ng-if="$ctrl.downlinkMessageType === &quot;lora&quot;"><label
            class="col-sm-3 control-label"><span translate=".lora.f_port"></span><sc-tooltip
              description-id="'.lora.tooltip_for_f_port'" size="'md'"></sc-tooltip></label>
          <div class="col-sm-9"><input class="form-control" type="number" ng-model="$ctrl.fPort" min="0" max="223" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10 col-sm-offset-1"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
        </div>
      </div>
    </div>
    <div class="modal-footer"><button class="btn btn-default" id="beam-cancel" ng-click="$ctrl.cancel()" type="button"
        translate=".cancel"></button><button class="btn btn-primary" id="beam-submit" ng-click="$ctrl.confirm()"
        type="button" sc-on-return="sc-on-return" ng-disabled="sendDownlinkMessageForm.$invalid"
        translate=".confirm"></button></div>
  </div>
</form>`;
