export const template = `<app-user-notifications></app-user-notifications>
<div class="x-lora_devices-page-root" id="page-wrapper" translate-namespace="lora_devices">
  <div>
    <div class="modal-parent"></div>
  </div>
  <div class="alerts-wrapper"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  <div class="ds-rows">
    <div class="ds-cols --spaced">
      <div>
        <div class="btn-group" uib-dropdown="uib-dropdown"><button class="btn btn-default dropdown-toggle"
            id="lora-devices-actions-menu" type="button" uib-dropdown-toggle="uib-dropdown-toggle"><span
              class="button-text" translate="lora_devices.actions_menu.title"></span><span
              class="caret"></span></button>
          <ul class="dropdown-menu" id="lora-devices-actions-dropdown-menu" role="menu"
            uib-dropdown-menu="uib-dropdown-menu">
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeGroup')"
              id="lora-devices-actions-menu-change-group"
              ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeGroup&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;ChangeGroup&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;ChangeGroup&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;ChangeGroup&quot;)">{{
                &quot;lora_devices.actions.change_group&quot; | translate }}</a></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('EditTag')"
              id="lora-devices-actions-menu-edit-tag"
              ng-class="{ disabled: !$ctrl.actionable(&quot;EditTag&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;EditTag&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;EditTag&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;EditTag&quot;)">{{ &quot;lora_devices.actions.edit_tag&quot; |
                translate }}</a></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Downlink')"
              id="lora-devices-actions-menu-downlink"
              ng-class="{ disabled: !$ctrl.actionable(&quot;Downlink&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Downlink&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Downlink&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;Downlink&quot;)">{{ &quot;lora_devices.actions.downlink&quot; |
                translate }}</a></li>
            <li class="divider"></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Logs')" id="lora-devices-actions-menu-logs"
              ng-class="{ disabled: !$ctrl.actionable(&quot;Logs&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Logs&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Logs&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;Logs&quot;)">{{ &quot;lora_devices.actions.logs&quot; |
                translate }}</a></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Data')" id="lora-devices-actions-menu-data"
              ng-class="{ disabled: !$ctrl.actionable(&quot;Data&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Data&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Data&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;Data&quot;)">{{ &quot;lora_devices.actions.data&quot; |
                translate }}</a></li>
            <li class="divider"></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeTerminationProtection')"
              id="lora-devices-actions-menu-change-termination-protection"
              ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeTerminationProtection&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;ChangeTerminationProtection&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;ChangeTerminationProtection&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;ChangeTerminationProtection&quot;)">{{
                &quot;lora_devices.actions.change_termination_protection&quot; | translate }}</a></li>
            <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Terminate')"
              id="lora-devices-actions-menu-terminate"
              ng-class="{ disabled: !$ctrl.actionable(&quot;Terminate&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Terminate&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Terminate&quot;)}">
              <a href="" ng-click="$ctrl.doAction(&quot;Terminate&quot;)">{{ &quot;lora_devices.actions.terminate&quot;
                | translate }}</a></li>
          </ul>
        </div><button class="btn btn-default" id="table-settings-button" data-testid="ng1-table-settings-button" ng-click="$ctrl.openTableSettingsModal()"><i
            class="glyphicon glyphicon-cog"></i></button>
      </div><sc-pagination paginator="$ctrl.paginator" ctrl="$ctrl"></sc-pagination>
    </div>
    <div>
      <ul class="dropdown-menu" id="lora-devices-context-menu" role="menu" uib-dropdown-menu="uib-dropdown-menu"
        ng-if="$ctrl.shouldShowContextMenu()">
        <li class="dropdown-header" translate="lora_devices.context_menu.header"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeGroup')"
          id="lora-devices-context-menu-change-group"
          ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeGroup&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;ChangeGroup&quot;)">{{ &quot;lora_devices.actions.change_group&quot; |
            translate }}</a></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('EditTag')" id="lora-devices-context-menu-edit-tag"
          ng-class="{ disabled: !$ctrl.actionable(&quot;EditTag&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;EditTag&quot;)">{{ &quot;lora_devices.actions.edit_tag&quot; | translate
            }}</a></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Downlink')"
          id="lora-devices-context-menu-downlink" ng-class="{ disabled: !$ctrl.actionable(&quot;Downlink&quot;) }"><a
            role="menuitem" href="" ng-click="$ctrl.doAction(&quot;Downlink&quot;)">{{
            &quot;lora_devices.actions.downlink&quot; | translate }}</a></li>
        <li class="divider"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Logs')" id="lora-devices-context-menu-logs"
          ng-class="{ disabled: !$ctrl.actionable(&quot;Logs&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;Logs&quot;)">{{ &quot;lora_devices.actions.logs&quot; | translate }}</a></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Data')" id="lora-devices-context-menu-data"
          ng-class="{ disabled: !$ctrl.actionable(&quot;Data&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;Data&quot;)">{{ &quot;lora_devices.actions.data&quot; | translate }}</a></li>
        <li class="divider"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeTerminationProtection')"
          id="lora-devices-context-menu-change-termination-protection"
          ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeTerminationProtection&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;ChangeTerminationProtection&quot;)">{{
            &quot;lora_devices.actions.change_termination_protection&quot; | translate }}</a></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Terminate')"
          id="lora-devices-context-menu-terminate" ng-class="{ disabled: !$ctrl.actionable(&quot;Terminate&quot;) }"><a
            role="menuitem" href="" ng-click="$ctrl.doAction(&quot;Terminate&quot;)">{{
            &quot;lora_devices.actions.terminate&quot; | translate }}</a></li>
      </ul>
      <div class="table-responsive table-lora-devices">
        <table class="sc-table table ds-datatable--striped x-lora-devices-table" id="lora-devices-table">
          <thead>
            <tr>
              <th class="th-checkbox ds-datatable__col--min" id="lora-devices-th-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check"
                    id="lora-devices-checkbox-for-all" type="checkbox" ng-model="$ctrl.checkboxForAll"
                    ng-change="$ctrl.checkboxForAllChanged($ctrl.checkboxForAll);" />
                  <div class="ds-checkbox__label">Check All</div>
                </div>
              </th>
              <th id="lora-devices-th-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)">{{
                &quot;lora_devices.columns.id&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'lora_devices.legends.id.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="lora-devices-th-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)">{{
                &quot;lora_devices.columns.name&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'lora_devices.legends.name.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="lora-devices-th-group" ng-if="$ctrl.shouldShowColumn(&quot;group&quot;)">{{
                &quot;lora_devices.columns.group&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'lora_devices.legends.group.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="lora-devices-th-status" ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)">{{
                &quot;lora_devices.columns.status&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'lora_devices.legends.status.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="lora-devices-th-rssi" ng-if="$ctrl.shouldShowColumn(&quot;rssi&quot;)">{{
                &quot;lora_devices.columns.rssi&quot; | translate }}&nbsp;</th>
              <th id="lora-devices-th-snr" ng-if="$ctrl.shouldShowColumn(&quot;snr&quot;)">{{
                &quot;lora_devices.columns.snr&quot; | translate }}&nbsp;</th>
              <th id="lora-devices-th-last-seen" ng-if="$ctrl.shouldShowColumn(&quot;last_seen&quot;)">{{
                &quot;lora_devices.columns.last_seen&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'lora_devices.legends.last_seen.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="lora-devices-th-termination-protection"
                ng-if="$ctrl.shouldShowColumn(&quot;termination_protection&quot;)">{{
                &quot;lora_devices.columns.termination_protection&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'lora_devices.legends.termination_protection.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
            </tr>
          </thead>
          <tbody class="x-lora-devices-list" ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()">
            <tr ng-repeat="row in $ctrl.tableData.rows" ng-click="$ctrl.setSelected(row)"
              sc-context-menu="lora-devices-context-menu">
              <td class="td-checkbox lora-devices-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check checkbox-for-each"
                    id="cb{{row.obj.id}}" type="checkbox" ng-model="row.selected"
                    ng-change="$ctrl.checkboxForEachChanged()" ng-click="$event.stopPropagation()" />
                  <div class="ds-checkbox__label">Check {{ row.obj.id }}</div>
                </div>
              </td>
              <td class="lora-devices-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)" ng-bind="row.obj.id"></td>
              <td class="lora-devices-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)"><sc-inplace-editable-text
                  ng-if="$ctrl.editable" ng-mouseover="row.hoverName = true" ng-mouseleave="row.hoverName = false"
                  key="name" cancel-on-blur="true" display-value="row.obj.name" edit-ctrl="row.nameEditCtrl"
                  padding-left="&quot;5&quot;" show-pencil-icon="row.hoverName" show-remove-button="false"
                  show-save-button="false" show-cancel-button="false"></sc-inplace-editable-text><span
                  ng-if="!$ctrl.editable" ng-bind="row.obj.name"></span></td>
              <td class="lora-devices-group feedback" ng-if="$ctrl.shouldShowColumn(&quot;group&quot;)"
                sc-update-feedback="sc-update-feedback"
                feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                feedback-type="row.feedback.updateGroup" feedback-duration="3000">
                <div class="overflow-auto"><a ng-href="/lora_device_groups/{{ row.obj.groupId }}"><span>{{
                      row.obj.groupName() }}</span></a></div>
              </td>
              <td class="lora-devices-status feedback" ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)"
                sc-update-feedback="sc-update-feedback"
                feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                feedback-type="row.feedback.updateStatus" feedback-duration="3000"><sc-lora-device-status-label
                  obj="row.obj"></sc-lora-device-status-label></td>
              <td class="lora-device-rssi text-right" ng-if="$ctrl.shouldShowColumn(&quot;rssi&quot;)">{{
                row.obj.lastSeen && row.obj.lastSeen.rssi !== undefined ? row.obj.lastSeen.rssi + 'dBm' : '' }}</td>
              <td class="lora-device-snr text-right" ng-if="$ctrl.shouldShowColumn(&quot;snr&quot;)">{{ row.obj.lastSeen
                && row.obj.lastSeen.snr !== undefined ? row.obj.lastSeen.snr : '' }}</td>
              <td class="lora-device-last-seen" ng-if="$ctrl.shouldShowColumn(&quot;last_seen&quot;)"><span
                  sc-date-time="row.obj.lastSeen.time" alt-text=""
                  ng-if="row.obj.lastSeen &amp;&amp; row.obj.lastSeen.time"></span></td>
              <td class="lora-devices-termination-protection"
                ng-if="$ctrl.shouldShowColumn(&quot;termination_protection&quot;)"
                sc-update-feedback="sc-update-feedback"
                feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                feedback-type="row.feedback.changeTerminationProtection" feedback-duration="3000"><i
                  class="ds-icon --icon-unlock x-tp-disabled" ng-if="row.obj.terminationEnabled"
                  style="color: lightgray;"></i><i class="ds-icon --icon-lock x-tp-enabled"
                  ng-if="!row.obj.terminationEnabled"></i></td>
            </tr>
          </tbody>
          <tbody class="x-no-lora-devices-exist" ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()">
            <tr class="no-data">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.no_data' |
                translate }}</td>
            </tr>
          </tbody>
          <tbody class="x-is-loading" ng-if="$ctrl.isLoading()">
            <tr class="loading">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.loading' |
                translate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div><sc-tutorial-component></sc-tutorial-component>`;
