import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@soracom/shared-ng/logger-service';
import { getOperatorData } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-support-login',
  templateUrl: './support-login.component.html',
})
export class SupportLoginComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  alertsManager = new AlertsManager();
  loading = true;
  private logger = LoggerService.shared();

  constructor(
    private route: ActivatedRoute,
    private soracomApi: SoracomApiService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      if (params.return_to) {
        this.logger.debug('support login', decodeURIComponent(params.return_to));
        // we can safely ignore this warning because the function below will be parsed and evaluated up to once due to the if condition above
        this.soracomApi
          .getSupportToken()
          .then((response: LegacyAny) => {
            this.logger.debug('getSupportToken() result ==', response);
            this.gotoSupportPage(response.data.token, params.return_to);
          })
          .catch((response: LegacyAny) => {
            this.alertsManager.add(Alert.danger(response));
            this.loading = false;
          });
      }
    });
  }

  gotoSupportPage(token: string, returnTo: string) {
    const orgCode = getOperatorData().getKddiOrganizationCode();
    const url: string =
      orgCode === null || orgCode === undefined
        ? this.translate.instant('nav.support.zendesk_auth_url_base')
        : this.translate.instant('nav.support.zendesk_auth_url_base_kddi');

    const form = document.createElement('form');
    form.action = url;
    form.method = 'POST';

    const tokenInput = document.createElement('input');
    tokenInput.type = 'hidden';
    tokenInput.value = token;
    tokenInput.name = 'jwt';

    const returnToInput = document.createElement('input');
    returnToInput.name = 'return_to';
    returnToInput.value = returnTo;
    returnToInput.type = 'hidden';

    form.appendChild(tokenInput);
    form.appendChild(returnToInput);

    document.body.appendChild(form);
    form.submit();
  }
}
