import { InjectList } from '../core/injectable';

/**
 * BaseObject is a low-level base class from which various
 * concrete classes may inherit. This class is intended to be a
 * base class for all different kinds of objects, so only
 * truly universal, common functionality belongs here.
 * So far, that's just logging. But nearly every single
 * object we ever create should be able to log, so this
 * class was created.
 */
export class BaseObject {
  static $inject: InjectList = ['$log'];

  /** A reference to Angular's logging service. */
  protected logService: ng.ILogService;

  /** Equivalent to Angulars's $log.log(). */
  log: angular.ILogCall;

  /** Equivalent to Angulars's $log.info(). */
  info: angular.ILogCall;

  /** Equivalent to Angulars's $log.warn(). */
  warn: angular.ILogCall;

  /** Equivalent to Angulars's $log.error(). */
  error: angular.ILogCall;

  /** Equivalent to Angulars's $log.debug(). */
  debug: angular.ILogCall;

  /**
   * A trace-level logging function for development.
   *
   * Unlike log, info, warn, error, and debug, which just call
   * through to Angular's logging service, trace-level logs can be
   * enabled/disabled by subclasses (via setTraceEnabled()). The
   * idea here is that we like to log a whole bunch of info when
   * developing or modifying a class, but we are not interested in
   * seeing that output at other times. The trace() function may be
   * used to log that kind of stuff.
   */
  // @ts-expect-error (legacy code incremental fix)
  trace: (...args: any[]) => void;

  private _isTraceLoggingEnabled = false;

  /**
   * Subclasses must pass Angular's log service as the sole parameter
   * when invoking this as super() from their own constructors.
   */
  constructor($log: ng.ILogService) {
    this.logService = $log;

    this.log = this.logService.log;
    this.info = this.logService.info;
    this.warn = this.logService.warn;
    this.error = this.logService.error;
    this.debug = this.logService.debug;

    // ↑ Mason 2017-08-24: The reason we make the above properties, not methods,
    // is that capturing the args and then passing them on to $log converts the
    // args to an array, which changes the output formatting. This way is a
    // bit weird, but keeps output exactly the same as using $log directly.

    this.setTraceEnabled(false);
  }

  /**
   * Enable or disable trace-level logging (on a per-instance basis).
   * When disabled, trace() does nothing.
   */
  setTraceEnabled(newEnabled: boolean) {
    if (newEnabled) {
      this.trace = this.debug;
    } else {
      this.trace = this.dontTrace;
    }
  }

  /**
   * Fast do-nothing implementation for when trace logging is disabled.
   * (Approximately 3000 calls/millesecond on my computer as of 2017-08-24.)
   * @param args - anything; will be ignored
   */
  private dontTrace(...args: any[]) {
    return;
  }
}
