import { LegacyAny } from '@soracom/shared/core';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { DashboardPermission, LagoonDashboard, LagoonPermission } from '../../../../app/shared/core/lagoon_dashboard';
import { LagoonUser, LagoonUserRole } from '../../../../app/shared/core/lagoon_user';
import {
  LagoonDashboardsService,
  LagoonDashboardUserPermission,
} from '../../../../app/shared/lagoon/lagoon_dashboards.service';
import { LagoonUsersService } from '../../../../app/shared/lagoon/lagoon_users.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiTableContent } from '../../soracom-ui/ui-table/ui-table-content';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-update-lagoon-dashboard-permission-modal',
  templateUrl: './update-lagoon-dashboard-permission-modal.component.html',
})
export class UpdateLagoonDashboardPermissionModalComponent
  extends DsModalContentBase<{ dashboard: LagoonDashboard; v2: boolean }, { success: boolean }>
  implements OnInit, OnDestroy
{
  loading = false;
  alertsManager = new AlertsManager();
  get canConfirm(): boolean {
    return !this.nobodyCanAccess();
  }
  private columns: Array<UiTableColumn<LagoonDashboardUserPermission>> = [
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<LagoonDashboardUserPermission>('LagoonDashboardUserPermission', 'email', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<LagoonDashboardUserPermission>('LagoonDashboardUserPermission', 'role', null),
    // @ts-expect-error (legacy code incremental fix)
    new UiTableColumn<LagoonDashboardUserPermission>('LagoonDashboardUserPermission', 'permission', null),
  ];
  content = new UiTableContent<LagoonDashboardUserPermission>([], this.columns);
  dashboardPermissionsSet = false;
  // @ts-expect-error (legacy code incremental fix)
  permissions: { [key: number]: LagoonPermission };

  get dashboard() {
    return this.getInput().dashboard;
  }
  get v2() {
    return this.getInput().v2;
  }

  constructor(
    private lagoonDashboardsService: LagoonDashboardsService,
    private lagoonUsersService: LagoonUsersService,
    private logger: Logger
  ) {
    super();
  }

  ngOnInit(): void {
    this.lagoonDashboardsService.v2 = this.v2;
    this.lagoonUsersService.v2 = this.v2;
    this.dashboardPermissionsSet = this.dashboard.permissions.length > 0;

    // turn the array of permissions into a map with the userId as the key
    this.permissions = this.dashboard.permissions.reduce((map, obj) => {
      // @ts-expect-error (legacy code incremental fix)
      map[obj.userId] = obj;
      return map;
    }, {});

    const getPermission = (user: LagoonUser): DashboardPermission => {
      // @ts-expect-error (legacy code incremental fix)
      if (this.permissions[user.id]) {
        // If viewer has editor permission, overwrite it to viewer on UI.
        // @ts-expect-error (legacy code incremental fix)
        const dashboardPermission = this.permissions[user.id].permissionName;
        if (user.role === LagoonUserRole.viewer && dashboardPermission === DashboardPermission.Edit) {
          return DashboardPermission.View;
        } else {
          return dashboardPermission;
        }
      } else if (this.dashboardPermissionsSet) {
        // if dashboard permissions were set and this user was left out
        // they should not have access
        return DashboardPermission.None;
      } else {
        return user.role === LagoonUserRole.editor ? DashboardPermission.Edit : DashboardPermission.View;
      }
    };

    this.content.isLoading = true;
    this.lagoonUsersService
      // @ts-expect-error (legacy code incremental fix)
      .listAll(null, [])
      .then((results) => {
        const users: LagoonDashboardUserPermission[] = results.map((u) => {
          const obj = new LagoonDashboardUserPermission();
          obj.userId = Number(u.id);
          obj.userEmail = u.email;
          obj.userRole = u.role;
          obj.permission = getPermission(u);
          return obj;
        });

        this.content.setData(users);
      })
      .catch((e: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(e));
      })
      .finally(() => {
        this.content.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.lagoonUsersService.v2 = false;
  }

  availablePermissions(role: LagoonUserRole): DashboardPermission[] {
    if (role === LagoonUserRole.editor) {
      return [DashboardPermission.Edit, DashboardPermission.View, DashboardPermission.None];
    } else if (role === LagoonUserRole.viewer) {
      return [DashboardPermission.View, DashboardPermission.None];
    } else if (role === LagoonUserRole.admin) {
      return [DashboardPermission.Edit, DashboardPermission.View];
    } else {
      // Unknown role
      this.logger.error(`Unknown LagoonUserRole: ${role}`);
      return [];
    }
  }

  // This method returns true if nobody can access to this dashboard.
  nobodyCanAccess() {
    // if there no users then return false
    if (!this.content.hasData) {
      return false;
    }
    let accessible = false;
    this.content.tableData.data.forEach((perm) => {
      if (perm.permission !== DashboardPermission.None) {
        accessible = true;
        return;
      }
    });

    return !accessible;
  }

  removePermissions() {
    // To reset the permissions just update them with an empty object.
    this.updatePermissions({});
  }

  updatePermissions(userPermMap: { [userId: number]: DashboardPermission }) {
    this.loading = true;
    this.lagoonDashboardsService
      .updateDashboardPermissions(this.dashboard.id, userPermMap)
      .then(() => {
        this.loading = false;
        this.close({ success: true });
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
        this.loading = false;
      });
  }

  confirm(): void {
    const userPermMap: { [userId: number]: DashboardPermission } = {};

    this.content.tableData.data.forEach((userPermission) => {
      if (userPermission.permission !== DashboardPermission.None) {
        userPermMap[userPermission.userId] = userPermission.permission;
      }
    });

    this.updatePermissions(userPermMap);
  }

  canClose(): boolean {
    return !this.loading;
  }
}
