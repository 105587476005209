import { createAction, props, union } from '@ngrx/store';

import { Group } from '@soracom/shared/group';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { LteMButtonConfig } from '../../shared/lte_m_button/LteMButtonConfig';

export const applyRouteParameters = createAction(
  '[LTE-M Button] Apply Route Parameters',
  props<{ imsiList: string[] | null; groupId: string | null; newGroupName: string | null; path: string }>()
);

//
// Actions on index page
//

export const loadConfiguredSubscribers = createAction('[LTE-M Button] Load Configured Subscribers Request');

export const loadConfiguredSubscribersSuccess = createAction('[LTE-M Button] Load Configured Subscribers Success');

export const loadConfiguredSubscribersError = createAction(
  '[LTE-M Button] Load Configured Subscribers Failure',
  (error: any) => ({ error })
);

export const loadAvailableSubscribersSuccess = createAction('[LTE-M Button] Load Available Subscribers Success');

export const loadAvailableSubscribersError = createAction(
  '[LTE-M Button] Load Available Subscribers Failure',
  (error: any) => ({ error })
);

export const configureNewDevice = createAction('[LTE-M Button] Configure New Device');

export const editLteMButtonConfiguration = createAction(
  '[LTE-M Button] Edit LTE-M Button Configuration',
  props<{ groupId: string }>()
);

export const initializeGroup = createAction('[LTE-M Button] Initialize Group', props<{ name: string }>());

export const loadSelectedSubscribers = createAction(
  '[LTE-M Button] Load Selected Subscribers Request',
  props<{ imsiList: string[] }>()
);

export const loadSelectedSubscribersSuccess = createAction(
  '[LTE-M Button] Load Selected Subscribers Success',
  (payload: ExtendedSubscriberInterface[]) => ({ payload })
);

export const loadSelectedSubscribersError = createAction(
  '[LTE-M Button] Load Selected Subscribers Failure',
  (error: any) => ({ error })
);

export const loadGroup = createAction('[LTE-M Button] Load Group Request', props<{ groupId: string }>());

export const loadGroupSuccess = createAction('[LTE-M Button] Load Group Success', (group: Group) => ({ group }));

export const loadGroupError = createAction('[LTE-M Button] Load Group Failure', (error: any) => ({ error }));

export const saveConfiguration = createAction(
  '[LTE-M Button] Save configuration',
  props<{ group: Group; newConfig: LteMButtonConfig; isSoracomHarvestEnabled: boolean }>()
);

export const selectGroup = createAction('[LTE-M Button] Select Group', props<{ imsiList: string[] }>());

export const navigateToIndexPage = createAction('[LTE-M Button] Navigate to Index Page');

export const navigateToSelectGroupPage = createAction('[LTE-M Button] Navigate to Select Group Page');

export const navigateToSelectSubscribersPage = createAction('[LTE-M Button] Back to select subscribers page');

export const newConfiguration = createAction(
  '[LTE-M Button] Edit new configuration',
  props<{ newGroupName: string }>()
);

export const unknownError = createAction('[LTE-M Button] Unknown Failure', (error: any) => ({ error }));

export const noop = createAction('[LTE-M Button] noop');

const actions = union({
  applyRouteParameters,
  configureNewDevice,
  editLteMButtonConfiguration,
  initializeGroup,
  loadConfiguredSubscribers,
  loadConfiguredSubscribersSuccess,
  loadConfiguredSubscribersError,
  loadAvailableSubscribersSuccess,
  loadAvailableSubscribersError,
  loadSelectedSubscribers,
  loadSelectedSubscribersSuccess,
  loadSelectedSubscribersError,
  loadGroup,
  loadGroupSuccess,
  loadGroupError,
  navigateToIndexPage,
  navigateToSelectGroupPage,
  navigateToSelectSubscribersPage,
  newConfiguration,
  noop,
  selectGroup,
  unknownError,
});

export type ActionsUnion = typeof actions;
