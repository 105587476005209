import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';
import { localize } from '@soracom/shared/locale-service';

const i18n = {
  notFoundMessage: {
    en: 'Sorry, the requested page was not found.',
    ja: 'お探しのページが見つかりませんでした',
  },
  backToTopLabel: {
    en: 'Back to top page',
    ja: 'トップページに戻る',
  },
} as const;

@Component({
  standalone: true,
  imports: [RouterModule, SoracomI18nModule],
  templateUrl: './not-found-page.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent {
  readonly i18n = i18n;
  t = localize;
}
