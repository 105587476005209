import { LegacyAny } from '@soracom/shared/core';

// window.app.directive('scSortFilterMenu', SortFilterMenuDirective);
import * as angular from 'angular';
import { template } from './sort_filter_menu.component.html';

import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';

export class SortFilterMenuComponent implements ng.IComponentOptions {
  bindings = {
    sortOrder: '=',
    filterItems: '=',
    filteredItems: '=',
    onSortOrderChange: '&',
    onFilterChange: '&',
  };

  controller = SortFilterMenuComponentController;
  template: any = template;
}

export class SortFilterMenuComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$scope'];

  sortOrder: any;
  // @ts-expect-error (legacy code incremental fix)
  innerFilteredItems: any[];
  filterItems: any;
  filteredItems: any;
  // @ts-expect-error (legacy code incremental fix)
  onSortOrderChange: (newOrder) => void;
  // @ts-expect-error (legacy code incremental fix)
  onFilterChange: (option: { filteredItems: any[] }) => void;

  // @ts-expect-error (legacy code incremental fix)
  status: { isOpen: boolean };

  constructor($log: ng.ILogService, private $scope: ng.IScope) {
    super($log);
  }

  $onInit() {
    this.status = {
      isOpen: false,
    };
    this.$scope.$watch(
      () => this.status.isOpen,
      () => {
        if (!this.status.isOpen) {
          this.resetInnerFilteredItems();
        }
      }
    );
    this.resetInnerFilteredItems();
  }

  setSortOrder(newOrder: LegacyAny) {
    if (this.sortOrder !== newOrder) {
      this.onSortOrderChange({ newOrder });
    } else {
      this.onSortOrderChange({ newOrder: null });
    }
  }

  resetInnerFilteredItems() {
    this.innerFilteredItems = [];
    angular.forEach(this.filterItems, (itemId) => {
      this.innerFilteredItems.push({
        id: itemId,
        checked: this.filteredItems.includes(itemId),
      });
    });
  }

  cancelClick(e: LegacyAny) {
    e.stopPropagation();
  }

  selectAll(e: LegacyAny) {
    angular.forEach(this.innerFilteredItems, (item: LegacyAny) => {
      item.checked = true;
    });
    e.stopPropagation();
    e.preventDefault();
  }

  clear(e: LegacyAny) {
    angular.forEach(this.innerFilteredItems, (item: LegacyAny) => {
      item.checked = false;
    });
    e.stopPropagation();
    e.preventDefault();
  }

  applyFilter(e: LegacyAny) {
    const filteredItems: LegacyAny = [];
    angular.forEach(this.innerFilteredItems, (item: LegacyAny) => {
      if (item.checked) {
        filteredItems.push(item.id);
      }
    });
    this.status.isOpen = false;
    e.stopPropagation();
    e.preventDefault();
    this.onFilterChange({ filteredItems });
  }

  canApplyFilter() {
    let result = false;
    angular.forEach(this.innerFilteredItems, (item: LegacyAny) => {
      if (item.checked) {
        result = true;
      }
    });
    return result;
  }

  cancel(e: LegacyAny) {
    this.status.isOpen = false;
    e.stopPropagation();
    e.preventDefault();
  }

  getCssClass(filterId: LegacyAny) {
    return 'sort-filter-menu-status-' + filterId.split('.').pop();
  }
}
