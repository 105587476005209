import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { WebStorageModule } from '@soracom/forks/ngx-store';

import { RootStoreModule } from '../root-store/root-store.module';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { SoracomGroupsModule } from '../soracom-groups/soracom-groups.module';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { SubscribersModule } from '../subscribers/subscribers.module';
import { DeleteGpsMultiunitConfigComponent } from './delete-gps-multiunit-config/delete-gps-multiunit-config.component';
import { GpsMultiunitConfigComponent } from './gps-multiunit-config/gps-multiunit-config.component';
import { GpsMultiunitIndexComponent } from './gps-multiunit-index/gps-multiunit-index.component';
import { GpsMultiunitNewGroupSelectComponent } from './gps-multiunit-new-group-select/gps-multiunit-new-group-select.component';
import { GpsMultiunitNewSubscribersSelectComponent } from './gps-multiunit-new-subscribers-select/gps-multiunit-new-subscribers-select.component';
import { GpsMultiunitScenarioFormComponent } from './gps-multiunit-scenario-form/gps-multiunit-scenario-form.component';
import { GpsMultiunitComponent } from './gps-multiunit/gps-multiunit.component';

import '@soracom/shared/sim-status-info';

@NgModule({
  declarations: [
    DeleteGpsMultiunitConfigComponent,
    GpsMultiunitConfigComponent,
    GpsMultiunitComponent,
    GpsMultiunitIndexComponent,
    GpsMultiunitNewGroupSelectComponent,
    GpsMultiunitNewSubscribersSelectComponent,
    GpsMultiunitScenarioFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RootStoreModule,
    TranslateModule.forChild(),
    WebStorageModule,

    SoracomCommonModule,
    SoracomGroupsModule,
    SubscribersModule,
    SoracomUiModule,
  ],
  exports: [DeleteGpsMultiunitConfigComponent, GpsMultiunitComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GpsMultiunitModule {}
