import { LegacyAny } from '@soracom/shared/core';

import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { environment } from 'apps/user-console/src/environments/environment';

const CACHE_BUSTER = Math.random().toString(36).slice(-8);

export function TimestampInjectorService(
  $log: LegacyAny,
  $templateCache: LegacyAny,
  coverageTypeService: CoverageTypeService
) {
  const request = (config: LegacyAny) => {
    if (excludeUrl(config.url)) {
      // $log.debug(config.url + ' is excluded');
      return config;
    }

    if (config.url.indexOf('?') >= 0) {
      config.url += '&t=' + CACHE_BUSTER;
    } else {
      config.url += '?t=' + CACHE_BUSTER;
    }
    return config;
  };

  const excludeUrl = (url: string) => {
    if ($templateCache.get(url) !== undefined) {
      return true;
    }

    if (url.startsWith('template/')) {
      return true;
    }

    if (url.startsWith('datepicker/')) {
      return true;
    }

    if (url.startsWith('timepicker/')) {
      return true;
    }

    if (url.endsWith('.html') || url.endsWith('.scss')) {
      return false;
    }

    if (url.startsWith(environment.apiUrl[coverageTypeService.getCoverageType() ?? 'g'])) {
      return true;
    }

    if (url.endsWith('auth.soracom.io/')) {
      return true;
    }

    // Don't add a timestamp to s3 posts
    if (url.includes('soracom-customer-images.s3')) {
      return true;
    }

    return false;
  };

  return { request };
}

TimestampInjectorService.$inject = ['$log', '$templateCache', 'CoverageTypeService'];
