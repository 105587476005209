import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

export interface MapDataParserConfig {
  latitudeKey: string;
  longitudeKey: string;
}

const DEFAULT_MAP_PARSER_CONFIG = () =>
  ({
    latitudeKey: '',
    longitudeKey: '',
  } as MapDataParserConfig);

@Component({
  selector: 'app-harvest-data-map-parser-setting-control',
  templateUrl: './harvest-data-map-parser-setting-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HarvestDataMapParserSettingControlComponent {
  _parserConfig: MapDataParserConfig = DEFAULT_MAP_PARSER_CONFIG();

  @Input() set parserConfig(c: MapDataParserConfig | null) {
    this._parserConfig = c || DEFAULT_MAP_PARSER_CONFIG();
    // @ts-expect-error (legacy code incremental fix)
    this.form.setValue(this.parserConfig, { emitEvent: false });
  }

  get parserConfig() {
    return this._parserConfig;
  }

  @Output() parserConfigChange = new EventEmitter<MapDataParserConfig>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      // @ts-expect-error (legacy code incremental fix)
      latitudeKey: [this.parserConfig.latitudeKey],
      // @ts-expect-error (legacy code incremental fix)
      longitudeKey: [this.parserConfig.longitudeKey],
    });
  }

  onSubmit() {
    const v = this.form.value;
    // @ts-expect-error (legacy code incremental fix)
    if (v?.latitudeKey !== this.parserConfig.latitudeKey || v?.longitudeKey !== this.parserConfig.longitudeKey) {
      this.parserConfigChange.emit({
        latitudeKey: v.latitudeKey,
        longitudeKey: v.longitudeKey,
      } as MapDataParserConfig);
    }
  }
}
