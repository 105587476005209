import { template } from './tooltip.component.html';

import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';

export class TooltipComponent implements ng.IComponentOptions {
  bindings = {
    alignRight: '=?',
    descriptionId: '=?',
    description: '=?',
    size: '=?',
  };

  controller = TooltipComponentController;
  template: any = template;
}

export class TooltipComponentController extends BaseController {
  static $inject: InjectList = ['$log'];

  // @ts-expect-error (legacy code incremental fix)
  dropdownClasses: string[];
  alignRight: any;
  // @ts-expect-error (legacy code incremental fix)
  size: string;

  constructor($log: ng.ILogService) {
    super($log);
  }

  $onInit() {
    this.dropdownClasses = ['dropdown-menu'];
    if (this.alignRight) {
      this.dropdownClasses.push('dropdown-menu-right');
    }

    if (this.size === 'lg') {
      this.dropdownClasses.push('size-lg');
    } else if (this.size === 'md') {
      this.dropdownClasses.push('size-md');
    } else if (this.size === 'sm') {
      this.dropdownClasses.push('size-sm');
    } else {
      this.dropdownClasses.push('size-md');
    }
  }
}
