import { LegacyAny } from '@soracom/shared/core';

import { template } from './device_keys.component.html';

import { AlertsServiceInstance } from '../components/alerts.service';
import { BaseTableController } from '../components/base_table_controller';
import { Action } from '../core/actions/Action';
import { DeviceKey, DeviceKeyStatus } from '../core/device_key';
import { InjectList } from '../core/injectable';
import { DevicesService } from './devices.service';

/**
 * A component for display Device api-keys
 */
export class DeviceKeysComponent implements ng.IComponentOptions {
  bindings = {
    device: '<',
    alertsService: '<',
  };
  controller = DeviceKeysComponentController;
  template: any = template;
}

class CreateDeviceKeyAction implements Action<any> {
  constructor(private ctrl: DeviceKeysComponentController) {}

  actionable(target: LegacyAny) {
    return true;
  }

  run(target: LegacyAny) {
    this.ctrl.createKey();
  }
}

abstract class DeviceKeyAction implements Action<DeviceKey> {
  constructor(protected ctrl: DeviceKeysComponentController) {}

  getDeviceKey(target: DeviceKey[]): DeviceKey | undefined {
    if (target && target.length === 1 && target[0] instanceof DeviceKey) {
      return target[0];
    } else {
      return undefined;
    }
  }

  actionable(_target: DeviceKey[]) {
    return false;
  }

  run(_target: DeviceKey[]) {
    // Do nothing
  }
}

class ActivateDeviceKeyAction extends DeviceKeyAction {
  // @ts-expect-error (legacy code incremental fix)
  actionable(target: DeviceKey[]) {
    const deviceKey = this.getDeviceKey(target);
    return deviceKey && deviceKey.status !== DeviceKeyStatus.active;
  }

  run(target: DeviceKey[]) {
    const deviceKey = this.getDeviceKey(target);
    if (deviceKey) {
      this.ctrl.activateKey(deviceKey);
    }
  }
}

class DeactivateDeviceKeyAction extends DeviceKeyAction {
  // @ts-expect-error (legacy code incremental fix)
  actionable(target: DeviceKey[]) {
    const deviceKey = this.getDeviceKey(target);
    return deviceKey && deviceKey.status !== DeviceKeyStatus.inactive;
  }

  run(target: DeviceKey[]) {
    const deviceKey = this.getDeviceKey(target);
    if (deviceKey) {
      this.ctrl.deactivateKey(deviceKey);
    }
  }
}

class DeleteDeviceKeyAction extends DeviceKeyAction {
  // @ts-expect-error (legacy code incremental fix)
  actionable(target: DeviceKey[]) {
    const deviceKey = this.getDeviceKey(target);
    return deviceKey && deviceKey.status === DeviceKeyStatus.inactive;
  }

  run(target: DeviceKey[]) {
    const deviceKey = this.getDeviceKey(target);
    if (deviceKey) {
      this.ctrl.deleteKey(deviceKey);
    }
  }
}

export class DeviceKeysComponentController extends BaseTableController<DeviceKey> {
  static $inject: InjectList = ['$log', '$uibModal', 'DevicesService'];

  // @ts-expect-error (legacy code incremental fix)
  alertsService: AlertsServiceInstance;
  // TODO: Use Device class in parent component
  device?: any;

  constructor($log: ng.ILogService, private $uibModal: any, public devicesService: DevicesService) {
    super($log);
    this.setTraceEnabled(true);
    this.trace(this);
    this.modelName = 'DeviceKeys';
  }

  $onInit() {
    this.actionContainer.actions = {
      Create: new CreateDeviceKeyAction(this),
      // @ts-expect-error (legacy code incremental fix)
      Activate: new ActivateDeviceKeyAction(this),
      // @ts-expect-error (legacy code incremental fix)
      Deactivate: new DeactivateDeviceKeyAction(this),
      // @ts-expect-error (legacy code incremental fix)
      Delete: new DeleteDeviceKeyAction(this),
    };
  }

  $onChanges(changesObj: LegacyAny) {
    /**
     * this.device is initialized by parent component after $onInit().
     * I have to watch the update and apply to this.. It's weird.
     * I guess I shouldn't get object in the component..?
     *
     * Yuta
     */
    this.trace(changesObj);
    if (changesObj.device.currentValue) {
      if (changesObj.device.previousValue === null || changesObj.device.previousValue === undefined) {
        this.reloadData();
      } else if (changesObj.device.previousValue.deviceId !== changesObj.device.currentValue.deviceId) {
        this.reloadData();
      }
    } else {
      this.trace('current device id is not changed.');
    }
  }

  reloadData() {
    this._isLoading = true;
    if (this.device) {
      this.devicesService
        .listKeys(this.device.deviceId)
        .then((response: LegacyAny) => {
          const keys: DeviceKey[] = [];
          response.data.forEach((element: LegacyAny) => {
            keys.push(new DeviceKey(element));
          });
          this.setData(keys);
        })
        .catch((error: LegacyAny) => {
          this.alertsService.showError(error);
        })
        .finally(() => {
          this._isLoading = false;
        });
    }
  }

  createKey() {
    return this.devicesService
      .createKey(this.device.deviceId)
      .then((response: LegacyAny) => {
        const newDeviceKey = new DeviceKey(response.data);
        this.openAddDeviceKeyModal(newDeviceKey);
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  private openAddDeviceKeyModal(deviceKey: DeviceKey) {
    this.$uibModal
      .open({
        backdrop: 'static',
        component: 'scAddDeviceKeyModalComponent',
        resolve: {
          deviceId: () => this.device.deviceId,
          deviceKey: () => deviceKey,
        },
      })
      .result.then(
        () => {
          this.reloadData();
        },
        () => {
          this.reloadData();
        }
      );
  }

  activateKey(key: DeviceKey) {
    return this.devicesService
      .activateKey(this.device.deviceId, key.keyId)
      .then((response: LegacyAny) => {
        const deviceKey = new DeviceKey(response.data);
        this.reloadData();
        this.alertsService.showSuccess('device_keys.messages.activate_success');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  deactivateKey(key: DeviceKey) {
    return this.devicesService
      .deactivateKey(this.device.deviceId, key.keyId)
      .then((response: LegacyAny) => {
        const deviceKey = new DeviceKey(response.data);
        this.reloadData();
        this.alertsService.showSuccess('device_keys.messages.deactivate_success');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  deleteKey(key: DeviceKey) {
    return this.devicesService
      .deleteKey(this.device.deviceId, key.keyId)
      .then((response: LegacyAny) => {
        this.reloadData();
        this.alertsService.showSuccess('device_keys.messages.delete_success');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }
}
