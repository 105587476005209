import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GpsMultiunitStoreModule } from './gps-multiunit-store';
import { LagoonStoreModule } from './lagoon-store';
import { LteMButtonStoreModule } from './lte-m-button-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GpsMultiunitStoreModule,
    LagoonStoreModule,
    LteMButtonStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
  ],
})
export class RootStoreModule {}
