export const template = `<sc-alerts service="$ctrl.alertsService"></sc-alerts>
<div class="ds-controls">
  <app-multi-resource-selector
    [max]="1";
    [available-types]="$ctrl.availableResourceTypes"
    [preload-types]="$ctrl.availableResourceTypes"
    [initial-type]="$ctrl.availableResourceTypes[0]"
    [resources]="$ctrl.resourcesInModernFormat"
    (resources-change)="$ctrl.onResourcesChange($event)"
  />
  <ui-datetime-range-selector
    class="ds-datetime-panel__range"
    [value]="$ctrl.searchTimeRange"
    (resolved-time-range-change)="$ctrl.searchTimeRangeChanged($event)"
  />
</div>

<div
  class="ds-controls"
  ng-show="$ctrl.hasData()"
>
  <div></div>
  <div>
    <sc-pagination
      paginator="$ctrl.paginator"
      ctrl="$ctrl"
      settings="true"
    ></sc-pagination>
  </div>
</div>

<div>
  <div class="modal-parent"></div>
</div>

<section class="ds-app__main-content">
  <table
    class="ds-datatable --striped"
    data-testid="audit-logs-table"
  >
    <thead class="--sticky">
      <tr>
        <th
          data-testid="audit-logs-th-created-at"
          ng-if="$ctrl.shouldShowColumn('created_at')"
        >
          {{ 'audit_logs.columns.created_at' | translate }}
        </th>
        <th
          data-testid="audit-logs-th-imsi"
          ng-if="$ctrl.shouldShowColumn('imsi')"
        >
          {{ 'audit_logs.columns.imsi' | translate }}
        </th>
        <th
          data-testid="audit-logs-th-type"
          ng-if="$ctrl.shouldShowColumn('type')"
        >
          {{ 'audit_logs.columns.type' | translate }}
        </th>
        <th
          data-testid="audit-logs-th-direction"
          ng-if="$ctrl.shouldShowColumn('direction')"
        >
          {{ 'audit_logs.columns.direction' | translate }}
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody
      data-testid="audit-logs-list"
      ng-if="!$ctrl.isLoading() && $ctrl.hasData()"
    >
      <tr ng-repeat="row in $ctrl.tableData.rows">
        <td
          data-testid="audit-logs-created-at"
          ng-if="$ctrl.shouldShowColumn('created_at')"
          sc-date-time="row.obj.createdAt"
        ></td>
        <td
          data-testid="audit-logs-imsi"
          ng-if="$ctrl.shouldShowColumn('imsi')"
        >{{ row.obj.imsi }}&nbsp;<button
            class="ds-button --in-place --icon-filter"
            data-testid="filter-icon"
            ng-click="$ctrl.filterResource(row.obj)"
            ng-if="!$ctrl.doesFilterResource(row.obj)"
          >
            <span>{{ 'audit_logs.table.filter' | translate }}</span>
          </button>
        </td>
        <td
          ng-class="['audit-logs-type', 'type' + row.obj.type]"
          ng-if="$ctrl.shouldShowColumn('type')"
        >
          <span ng-if="row.obj.type">{{ 'audit_logs.values.type.' + row.obj.type | translate }}</span>
        </td>
        <td
          data-testid="audit-logs-direction"
          ng-if="$ctrl.shouldShowColumn('direction')"
        >
          <p class="ds-text --code" ng-bind-html="$ctrl.directionSummary(row.obj)"></p>
        </td>
        <td>
          <button
            class="ds-button --small --plain"
            data-testid="open-raw-data"
            ng-click="$ctrl.openRawData(row.obj)"
          >
            <i class="ds-icon --icon-more-vertical"></i>
          </button>
        </td>
      </tr>
    </tbody>
    <tbody
      data-testid="no-audit-logs-exist"
      ng-if="!$ctrl.isLoading() && !$ctrl.hasData()"
    >
      <tr>
        <td colspan="999">{{ 'common.table.no_data' | translate }}</td>
      </tr>
    </tbody>
    <tbody
      data-testid="is-loading"
      ng-if="$ctrl.isLoading()"
    >
      <tr>
        <td colspan="999">{{ 'common.table.loading' | translate }}</td>
      </tr>
    </tbody>
  </table>
</section>
`;
