import { LegacyAny } from '@soracom/shared/core';

import { Resource } from '@soracom/shared/soracom-platform';
import { ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  SoracomApiParams,
} from '@user-console/legacy-soracom-api-client';
import { DashboardPermission, LagoonDashboard } from '../core/lagoon_dashboard';
import { LagoonUserRole } from '../core/lagoon_user';

export interface LagoonDashboardUpdatePermissionRequest {
  [key: number]: DashboardPermission;
}

export class LagoonDashboardUserPermission implements Resource {
  get id(): string {
    return String(this.userId);
  }
  // @ts-expect-error (legacy code incremental fix)
  userId: number;
  // @ts-expect-error (legacy code incremental fix)
  userEmail: string;
  // @ts-expect-error (legacy code incremental fix)
  userRole: LagoonUserRole;
  // @ts-expect-error (legacy code incremental fix)
  permission: DashboardPermission;
}

export class LagoonDashboardsService implements PaginatableService<LagoonDashboard> {
  static $inject = ['$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'lagoon/dashboards/permissions';
  v2 = false;

  constructor(
    private $q: ng.IQService,
    private soracomApi: LegacyBaseSoracomApiService,
    private paginationService: PaginationService,
  ) {}

  list(_queryOptions: LegacyAny): Promise<ScRelation<LagoonDashboard>> {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: `/v1/${this.resourcePath}`,
      query: {},
    };

    if (this.v2) {
      // @ts-expect-error (legacy code incremental fix)
      apiParams.query.v2 = 'true';
    }

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      // first lets get the result object
      const data: LagoonDashboard[] = [];

      if (response && response.data) {
        const perms = response.data;
        for (const perm of perms) {
          data.push(
            new LagoonDashboard({
              id: perm.dashboardId,
              name: perm.dashboardTitle,
              permissions: perm.permissions,
            }),
          );
        }
      }
      const relation: ScRelation<LagoonDashboard> = {
        data,
        links: this.paginationService.getPaginationLinks(response.headers.link),
      };

      return this.$q.resolve(relation);
    });
  }

  // initPermissions resets the permissions to default i.e no individually set permissions, everything
  // is inherited from each user's ROLE
  initPermissions(dashboardID: number) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/lagoon/dashboards/${dashboardID}/permissions/init`,
      query: {},
    };

    if (this.v2) {
      // @ts-expect-error (legacy code incremental fix)
      apiParams.query.v2 = 'true';
    }

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return this.$q.resolve(response.data);
    });
  }

  // Accepts an object with user ids as keys and DashboardPermissions("Edit","View" etc...) as values
  updateDashboardPermissions(dashboardID: number, userID2Perm: LagoonDashboardUpdatePermissionRequest) {
    interface Perm {
      userId: number;
      permission: DashboardPermission;
    }

    const perms: Perm[] = [];
    for (const userID in userID2Perm) {
      if (typeof userID === 'string') {
        perms.push({
          userId: Number(userID),
          permission: userID2Perm[userID],
        });
      }
    }

    const apiParams: SoracomApiParams = {
      method: 'put',
      path: `/v1/lagoon/dashboards/${dashboardID}/permissions`,
      query: {},
      contentType: 'application/json',
      body: { permissions: perms },
    };

    if (this.v2) {
      // @ts-expect-error (legacy code incremental fix)
      apiParams.query.v2 = 'true';
    }

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return this.$q.resolve(response.data);
    });
  }
}
