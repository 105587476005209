import { LegacyAny } from '@soracom/shared/core';

import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { AlertsServiceInstance } from '../../../../app/shared/components/alerts.service';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AbstractController } from '@soracom/shared-ng/soracom-ui-legacy';
import { Browser } from '../../shared/Browser';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { ConfirmLongTermDiscountComponent } from '../confirm-long-term-discount/confirm-long-term-discount.component';
import { NewLongTermDiscountComponent } from '../new-long-term-discount/new-long-term-discount.component';
import { VolumeDiscountResponse } from './VolumeDiscount';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

@Component({
  selector: 'app-long-term-discounts',
  templateUrl: './long-term-discounts.component.html',
})
export class LongTermDiscountsComponent extends AbstractController implements OnInit {
  /**
   * This is an example of how it is possible to use the old AngularJS `AlertsService` with a modern Angular component (e.g. if a child Angular component needs to post an error to the AlertsServiceInstance owned by its AngularJS parent component).
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() alertsService: AlertsServiceInstance;

  alerts: Alert[] = [];

  constructor(logger: Logger, private apiService: SoracomApiService, private modalService: UiDsModalService) {
    super(logger);
    this.traceEnabled = true;
  }

  ngOnInit() {
    this.trace('ngOnInit()', this);
    this.listLongTermDiscounts();

    // if (window.location.search.includes('newDiscount')) {
    //   this.createNewLongTermDiscount();
    // }
  }

  get isProhibitedBrowser() {
    return Browser.isProhibited;
  }

  buttonBar = UiButtonBar.configure((bar) => {
    bar.leftButtons = [
      UiButton.configure((b) => {
        b.buttonStyle = 'primary';
        b.titleId = 'long_term_discount.new_long_term_discount_button_title';
        b.classes = ['x-new-long-term-discount-button'];
        b.onClick = () => {
          this.createNewLongTermDiscount();
        };
      }),
    ];
  });

  /**
   * undefined when no data, array if data exists
   */
  // @ts-expect-error (legacy code incremental fix)
  longTermDiscounts: any[];

  isLoading = false;

  get infoVar() {
    return {
      heading: 'long_term_discount.notice_heading',
      body: 'long_term_discount.notice_body_html_content',
    };
  }

  createNewLongTermDiscount() {
    this.modalService
      .openAndWaitForResult(NewLongTermDiscountComponent, {
        title: 'NewLongTermDiscountComponent.heading',
        data: undefined,
      })
      .then((result: LegacyAny) => {
        if (result) {
          this.debug('The dialog was confirmed:', result);
          const discountResponse: VolumeDiscountResponse = result;
          this.showConfirmLongTermDiscountModalWithData(discountResponse);
        } else {
          this.debug('The dialog was canceled');
        }
      });
  }

  showConfirmLongTermDiscountModalWithData(data: VolumeDiscountResponse) {
    this.modalService
      .openAndWaitForResult(ConfirmLongTermDiscountComponent, {
        title: 'long_term_discount.confirm_long_term_discount_modal_heading',
        data: data,
      })
      .then((result: LegacyAny) => {
        if (result) {
          this.debug('The dialog was confirmed:', result);
          this.listLongTermDiscounts();
        } else {
          this.debug('The dialog was canceled');
        }
      });
  }

  listLongTermDiscounts() {
    this.isLoading = true;

    this.apiService
      .listLongTermDiscounts()
      .then((response: LegacyAny) => {
        this.debug('got listLongTermDiscounts():', response);

        const discountList = response.data.volumeDiscountList;
        if (!discountList || !Array.isArray(discountList)) {
          // ctrl.alertsService.showError('Data format error: unknown format'); // FIXME
          this.error('Data format error: unknown format: ');
        } else {
          this.longTermDiscounts = discountList.sort((lhs, rhs) => {
            // sort by end date, in the way so items are sorted by most active discount to least active
            // in this case start date cannot be sorted from newest to later bcoz we want to show
            // most active discounts first and expired after that
            return rhs.endDate && lhs.endDate ? rhs.endDate - lhs.endDate : 0;
          });
        }
      })
      .catch(function (response: LegacyAny) {
        // @ts-expect-error (legacy code incremental fix)
        this.debug('error from listLongTermDiscounts():', response);

        // ctrl.alertsService.showError(response); // FIXME
      })
      .finally(() => {
        this.debug('listLongTermDiscounts done.');
        this.isLoading = false;
      });
  }
}
