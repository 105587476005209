export const template = `<div class="modal-header">
  <h4 translate="devices.modals.edit_device_resource.title"></h4>
</div>
<div class="modal-body">
  <form class="form-horizontal" name="editDeviceResourceForm" novalidate="novalidate">
    <div class="form-group"><label class="col-sm-4 control-label" translate="devices.resource.name"></label>
      <div class="col-sm-8">
        <div class="form-control-static" ng-bind="$ctrl.resolve.resource.name"></div>
      </div>
    </div>
    <div class="form-group"><label class="col-sm-4 control-label" translate="devices.resource.type"></label>
      <div class="col-sm-8">
        <div class="form-control-static" ng-bind="$ctrl.resolve.resource.type"></div>
      </div>
    </div>
    <div class="form-group"><label class="col-sm-4 control-label"
        translate="devices.resource.range_enumeration"></label>
      <div class="col-sm-8">
        <div class="form-control-static" ng-bind="$ctrl.resolve.resource.rangeEnumeration || 'None' "></div>
      </div>
    </div>
    <div class="form-group"><label class="col-sm-4 control-label" translate="devices.resource.mandatory"></label>
      <div class="col-sm-8">
        <div class="form-control-static" ng-bind="$ctrl.resolve.resource.mandatory"></div>
      </div>
    </div>
    <div class="form-group"><label class="col-sm-4 control-label" translate="devices.resource.multiple"></label>
      <div class="col-sm-8">
        <div class="form-control-static" ng-bind="$ctrl.resolve.resource.multiple"></div>
      </div>
    </div>
    <div class="form-group" sc-form-group-validity-classes="sc-form-group-validity-classes"><label
        class="col-sm-4 control-label" translate="devices.resource.value"></label>
      <div class="col-sm-8"><input class="form-control" name="value" type="text" ng-model="$ctrl.value" /></div>
    </div>
    <div class="form-group" style="padding: 0 20px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="submitting-indicator" ng-show="$ctrl.submitting"><img
              src="https://assets.soracom.io/images/async-working.gif" />&nbsp;<span
              translate="devices.modals.edit_device_resource.sending"></span></div>
        </div>
      </div>
    </div>
    <div class="form-group" style="padding: 0 20px;"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  </form>
</div>
<div class="modal-footer">
  <div class="form-group" translate-namespace="devices.modals.edit_device_resource"><button class="btn btn-default"
      ng-click="$ctrl.dismiss()" type="button" translate=".cancel"></button><button class="btn btn-primary"
      id="button-save-device-resource" type="button" translate=".submit" ng-click="$ctrl.submit()"
      ng-disabled="$ctrl.submitting"></button></div>
</div>`;
