import { LoggerService } from '@soracom/shared-ng/logger-service';
import { LegacyTextContent, UiText } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiElement } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiKeyboardEventHandler } from '../UiKeyboardEventHandler';
import { UiKeyboardEventHandlerRegistrationToken } from '../UiKeyboardEventHandlerRegistrationToken';
import { UiKeyboardEventService } from '../UiKeyboardEventService';
import { UiMenuItem } from './UiMenuItem';
import { UiMenuSection } from './UiMenuSection';

/**
 * A menu, as in a popup menu, or a context menu.
 *
 * NOTE: There is no need as of 2020-11-09 to have API for removing things from menus. We should add that if it is ever needed. Right now, you just build up the menu and to remove items you would need to just create a new menu. I expect the need for remove functionality will probably arise someday, though.
 */
export class UiMenu extends UiElement {
  /**
   * The sections in the menu. Many menus will have only one section with no header, but they can have multiple.
   */
  // @ts-expect-error (legacy code incremental fix)
  private _sections: UiMenuSection[];
  get sections() {
    return this._sections;
  }

  // @ts-expect-error (legacy code incremental fix)
  summary: UiButton | UiText;
  private _menuContents: UiMenuSection[] | UiText[] | { [key: string]: UiText[] } = [];
  get menuContents() {
    return this._menuContents;
  }
  set menuContents(value) {
    this._menuContents = value;
    this.generateSections(value);
  }

  constructor(
    /**
     * You can create a `UiMenu` instance with `UiMenu.configure()` or via the constructor. The `menuContents` parameter will be parsed into a `UiMenuSection[]`, which will be stored in the `sections` property, but for convenience, you can instantiate a menu in other ways. The simplest case is passing an array of `UiText` objects, such as i18n IDs of the menu item title strings (or `TextContent` objects):
     *
     * ```
     *     const fooMenu = new UiMenu(['hoge.add', 'hoge.edit', 'hoge.delete']);
     *
     *     // The above is shorthand for:
     *     const item1 = new UiMenuItem('hoge.add');
     *     const item2 = new UiMenuItem('hoge.edit');
     *     const item3 = new UiMenuItem('hoge.delete');
     *
     *     const theOnlySection = new UiMenuSection([item1, item2, item3]);
     *
     *     const fooMenu = new UiMenu([theOnlySection]);
     *
     * ```
     *
     * If you need multiple sections, you can use the object shorthand:
     *
     * ```
     *     const content = {
     *         'foo.section1': ['foo.item1', 'foo.item2'],
     *         'bar.section2': ['bar.item1', 'bar.item2'],
     *     };
     *     barMenu = new UiMenu(content);
     * ```
     *
     * Or, just create an array of `UiMenuSection` objects yourself, and pass that.
     */
    config:
      | {
          summary?: UiButton | UiText;
          menuContents?: UiMenuSection[] | UiText[] | { [key: string]: UiText[] };
        }
      | UiMenuSection[]
      | UiText[]
      | { [key: string]: UiText[] } = {}
  ) {
    super();

    // @ts-expect-error (legacy code incremental fix)
    let menuContents: UiMenuSection[] | UiText[] | { [key: string]: UiText[] } = null;
    // UiMenuSection[] or UiText[]
    if (Array.isArray(config)) {
      // @ts-expect-error (legacy code incremental fix)
      this.summary = null;
      menuContents = config;
    } else if ((config && config.summary) || config.menuContents) {
      // { summary?, menuContents? }
      if (
        config?.summary instanceof UiButton ||
        config?.summary instanceof LegacyTextContent ||
        typeof config?.summary === 'string' ||
        !Array.isArray(config.summary)
      ) {
        // @ts-expect-error (legacy code incremental fix)
        this.summary = config.summary;
        // @ts-expect-error (legacy code incremental fix)
        menuContents = config.menuContents;
        // { [key: string]: UiText[] }
      } else {
        // @ts-expect-error (legacy code incremental fix)
        this.summary = null;
        menuContents = config as { [key: string]: UiText[] };
      }
    } else {
      // do nothing
    }
    this.menuContents = menuContents;
  }

  private generateSections(menuContents: UiMenuSection[] | UiText[] | { [key: string]: UiText[] }) {
    let sectionCount = 0;
    let uiTextCount = 0;

    if (Array.isArray(menuContents)) {
      for (const e of menuContents) {
        if (e instanceof UiMenuSection) {
          sectionCount++;
        } else if (typeof e === 'string' || e instanceof LegacyTextContent) {
          uiTextCount++;
        }
      }
      if (sectionCount !== menuContents.length && uiTextCount !== menuContents.length) {
        throw new Error('UiMenuSection cannot be initialized with different menu content types.');
      }
    }

    if (Array.isArray(menuContents) && menuContents.length === 0) {
      this._sections = [new UiMenuSection()];
    } else if (UiMenuSection.isUiMenuSectionArray(menuContents)) {
      this._sections = menuContents;
    } else if (LegacyTextContent.isUiTextArray(menuContents)) {
      this._sections = [UiMenuSection.fromUiTextArray(menuContents)];
    } else {
      // @ts-expect-error (legacy code incremental fix)
      this._sections = UiMenuSection.fromObject(menuContents);
    }

    if (!this.sections) {
      // then invalid value was passed
      const section = new UiMenuSection();
      section.add('UiMenu.invalidMenuContents');
      this._sections = [section];
    }
  }

  /**
   * Add one or more menu items at the end of the menu (if the menu has sections, the item will be added at the end of the last section). The individual items can be a `UiMenuItem` instance, or just a `UiText` object for simple cases.
   */
  add(...items: Array<UiMenuItem | UiText> | UiMenuSection[]) {
    if (UiMenuSection.isUiMenuSectionArray(items)) {
      this.sections.push(...items);
    } else {
      let lastSection = this.sections[this.sections.length - 1];
      if (!lastSection) {
        lastSection = new UiMenuSection();
        this.sections.push(lastSection);
      }
      items.forEach((item) => lastSection.add(item));
    }
  }

  itemSelected(item: UiMenuItem) {
    this.dismissMenu();
    if (this.onItemSelected) {
      this.onItemSelected(item);
    }
  }

  dismissMenu() {
    this.detailsElement.open = false;
  }

  openMenu() {
    this.detailsElement.open = true;
  }

  get isOpen() {
    return this.detailsElement?.open;
  }

  get triggerHasFocus() {
    const activeElement = document.activeElement;
    if (!activeElement) {
      return false;
    }

    if (this.isOpen) {
      return false;
    }

    const isContained = this.detailsElement?.contains(activeElement);
    return isContained; // FIXME: further qualify this?
  }

  // @ts-expect-error (legacy code incremental fix)
  detailsElement: HTMLDetailsElement;

  /**
   * Invoked when a menu item is selected. Use this to implement the behavior of the menu.
   */
  onItemSelected: (item: UiMenuItem) => void = (item) => LoggerService.shared().debug('Menu item selected:', item);

  /**
   * In normal usage, this will be set by the `ui-menu` component managing the `UiMenu` instance.
   */
  set keyboardEventService(newValue: UiKeyboardEventService) {
    this._unregisterAllPreviouslyRegisteredKeyboardHandlers();
    this._keyboardEventService = newValue;

    // @ts-expect-error (legacy code incremental fix)
    const handler: UiKeyboardEventHandler = (event) => {
      LoggerService.shared().log('GOT KEYBOARD EVENT:', event);

      const key = event.key;
      if (key === 'ArrowDown') {
        this._handleArrowDownKey();
      } else if (key === 'ArrowUp') {
        this._handleArrowUpKey();
      } else if (key === 'Escape') {
        this._handleEscapeKey();
      } else if (key === 'Tab') {
        this._handleTabKey();
      } else {
        return false;
      }
    };

    const up = this._keyboardEventService.addKeyboardEventHandler('ArrowUp', handler);
    const down = this._keyboardEventService.addKeyboardEventHandler('ArrowDown', handler);
    const tab = this._keyboardEventService.addKeyboardEventHandler('Tab', handler);
    const esc = this._keyboardEventService.addKeyboardEventHandler('Escape', handler);

    this._keyboardEventHandlerRegistrationTokens.push(up, down, tab, esc);
  }

  // Mason 2020-11-12: A ui-menu should ONLY handle key events when either:
  // a.) its menu-trigger element(s) have focus, or
  // b.) its menu is currently open

  private _handleArrowDownKey() {
    LoggerService.shared().debug('_handleArrowDownKey()', this);

    if (this.triggerHasFocus) {
      // the menu is open but no item is selected, so select first one:
      this._focusChildElement(this._selectorForMenuItemElement('first'));
      return true;
    }

    if (this.isOpen) {
      const activeElement = document.activeElement;

      LoggerService.shared().debug(
        '_handleArrowDownKey(): FIXME: keyboard event handling for ui-menu not yet implemented',
        this
      );

      return false;
    } else if (this.triggerHasFocus) {
      this.openMenu();
      this._focusChildElement(this._selectorForMenuItemElement('first'));
      return true;
    } else {
      return false;
    }
  }

  private _handleArrowUpKey() {
    if (this.isOpen) {
      LoggerService.shared().debug(
        '_handleArrowUpKey(): FIXME: keyboard event handling for ui-menu not yet implemented',
        this
      );

      return true;
    } else if (this.triggerHasFocus) {
      this.openMenu();
      this._focusChildElement(this._selectorForMenuItemElement('last'));
      return true;
    } else {
      return false;
    }
  }

  private _selectorForMenuItemElement(which: 'last' | 'first') {
    return `.ui-menu__content .ui-menu__section:${which}-of-type .ui-menu__list li.ui-menu__item:${which}-of-type a.ui-menu__item`;
  }

  private _focusChildElement(selector: string): boolean {
    // We have to hack around a bit because not ALL Element objects support focus():

    const e = this.detailsElement.querySelector(selector) as HTMLElement;
    const isFunction = typeof e?.focus === 'function';
    if (isFunction) {
      e.focus();
    } else {
      LoggerService.shared().warn('_focusChildElement(): focus() does not exist for element:', e);
    }
    return isFunction;
  }

  private _handleEscapeKey() {
    if (this.isOpen) {
      this.dismissMenu();
      // FIXME: "return focus to the button"
      return true;
    } else {
      return false;
    }
  }

  private _handleTabKey() {
    // only handle this key if the menu is open; otherwise the native browser behavior should cover it
    if (this.isOpen) {
      this.dismissMenu();
    }
    return false; // even if it was open, we hope browser will move to next element for us
  }

  private _unregisterAllPreviouslyRegisteredKeyboardHandlers() {
    if (!this._keyboardEventService) {
      return;
    }
    this._keyboardEventHandlerRegistrationTokens.forEach((t) => {
      this._keyboardEventService.removeKeyboardEventHandler(t);
    });
  }

  private _keyboardEventHandlerRegistrationTokens: UiKeyboardEventHandlerRegistrationToken[] = [];

  // @ts-expect-error (legacy code incremental fix)
  private _keyboardEventService: UiKeyboardEventService;
}
