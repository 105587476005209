import { LegacyAny } from '@soracom/shared/core';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FocusMeDirective } from '@soracom/shared-ng/ui-common';
import { SoracomUiModule } from 'apps/user-console/src/app/soracom-ui/soracom-ui.module';

@Component({
  selector: 'soracom-group-add-modal',
  templateUrl: './group_add.modal.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, FocusMeDirective, SoracomUiModule],
})
export class GroupAddModalComponent extends LegacyUibModalAdapterModalBase<void, { group: Group }> implements OnInit {
  alertsManager = new AlertsManager();
  // @ts-expect-error (legacy code incremental fix)
  name: string;
  // @ts-expect-error (legacy code incremental fix)
  submitting: boolean;
  @ViewChild('addGroupForm', { static: true }) groupAddForm?: NgForm;

  ngOnInit() {
    this.submitting = false;
  }

  cancel() {
    this.close();
  }

  submit() {
    if (!this.canSubmit()) {
      return;
    }

    this.submitting = true;
    groupsService
      .create(this.name)
      .then((group) => {
        const result = { group };
        this.submitting = false;
        this.close(result);
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
        this.submitting = false;
      });
  }

  nameExists() {
    return !this.submitting && groupsService.nameExists(this.name);
  }

  canSubmit() {
    return !this.submitting && this.groupAddForm?.valid && !this.nameExists();
  }
}
