export const template = `<app-user-notifications></app-user-notifications>
<div class="x-harvest_files-page-root" id="page-wrapper" translate-namespace="harvest_files">
  <div class="modal-parent"></div>
  <div class="row block alerts-wrapper">
    <div class="col-xs-12"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  </div>
  <div class="row">
    <div class="col-xs-12 compact-block"><button class="btn btn-primary" id="harvest-files-upload-file-button"
        ng-disabled="!$ctrl.actionable(&quot;UploadFile&quot;)" ng-click="$ctrl.doAction(&quot;UploadFile&quot;)"><span
          class="button-text">{{ &quot;harvest_files.actions.upload_file&quot; | translate }}</span></button>
      <div class="btn-group" uib-dropdown="uib-dropdown"><button class="btn btn-default dropdown-toggle"
          id="harvest-files-actions-menu" type="button" uib-dropdown-toggle="uib-dropdown-toggle"><span
            class="button-text" translate="harvest_files.actions_menu.title"></span><span class="caret"></span></button>
        <ul class="dropdown-menu" id="harvest-files-actions-dropdown-menu" role="menu"
          uib-dropdown-menu="uib-dropdown-menu">
          <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Delete')" id="harvest-files-actions-menu-delete"
            ng-class="{ disabled: !$ctrl.actionable(&quot;Delete&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Delete&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Delete&quot;)}">
            <a href="" ng-click="$ctrl.doAction(&quot;Delete&quot;)">{{ &quot;harvest_files.actions.delete&quot; |
              translate }}</a></li>
        </ul>
      </div><button class="btn btn-default" id="table-settings-button" data-testid="ng1-table-settings-button" ng-click="$ctrl.openTableSettingsModal()"><i
          class="glyphicon glyphicon-cog"></i></button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-xs-12 compact-block">
      <form name="harvestFilesSearchForm" novalidate="novalidate" ng-submit="$ctrl.invokeSearch()">
        <div class="input-group"><input class="form-control" type="search" ng-model="$ctrl.searchPath"
            translate-attr-placeholder=".search_placeholder" />
          <div class="input-group-btn" ng-show="$ctrl.shouldShowClearPathButton()"><button class="btn btn-default"
              type="button" ng-click="$ctrl.clearSearch()"><i class="glyphicon glyphicon-remove-circle"></i></button>
          </div>
          <div class="input-group-btn"><button class="btn btn-default" id="harvest-files-search-button" type="button"
              ng-click="$ctrl.invokeSearch()" ng-disabled="$ctrl.shouldDisableSearchButton()"><span
                translate=".actions.open_directory"></span></button></div>
        </div>
      </form>
    </div>
    <div class="col-md-6 col-xs-12 compact-block"><sc-pagination paginator="$ctrl.paginator"
        ctrl="$ctrl"></sc-pagination></div>
  </div>
  <div class="row block">
    <div class="col-xs-12">
      <ul class="dropdown-menu" id="harvest-files-context-menu" role="menu" uib-dropdown-menu="uib-dropdown-menu"
        ng-if="$ctrl.shouldShowContextMenu()">
        <li class="dropdown-header" translate="harvest_files.context_menu.header"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Delete')" id="harvest-files-context-menu-delete"
          ng-class="{ disabled: !$ctrl.actionable(&quot;Delete&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;Delete&quot;)">{{ &quot;harvest_files.actions.delete&quot; | translate }}</a>
        </li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('CopyHarvestFileUrlForAir')"
          id="harvest-files-context-menu-copy-harvest-file-url-for-air"
          ng-class="{ disabled: !$ctrl.actionable(&quot;CopyHarvestFileUrlForAir&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;CopyHarvestFileUrlForAir&quot;)">{{
            &quot;harvest_files.actions.copy_harvest_file_url_for_air&quot; | translate }}</a></li>
      </ul>
      <div class="table-responsive table-harvest-files">
        <table class="sc-table table ds-datatable--striped x-harvest-files-table" id="harvest-files-table">
          <thead>
            <tr>
              <th class="th-checkbox ds-datatable__col--min" id="harvest-files-th-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check"
                    id="harvest-files-checkbox-for-all" type="checkbox" ng-model="$ctrl.checkboxForAll"
                    ng-change="$ctrl.checkboxForAllChanged($ctrl.checkboxForAll);" />
                  <div class="ds-checkbox__label">Check All</div>
                </div>
              </th>
              <th id="harvest-files-th-filename" ng-if="$ctrl.shouldShowColumn(&quot;filename&quot;)">{{
                &quot;harvest_files.columns.filename&quot; | translate }}&nbsp;</th>
              <th id="harvest-files-th-content-type" ng-if="$ctrl.shouldShowColumn(&quot;content_type&quot;)">{{
                &quot;harvest_files.columns.content_type&quot; | translate }}&nbsp;</th>
              <th id="harvest-files-th-content-length" ng-if="$ctrl.shouldShowColumn(&quot;content_length&quot;)">{{
                &quot;harvest_files.columns.content_length&quot; | translate }}&nbsp;</th>
              <th id="harvest-files-th-etag" ng-if="$ctrl.shouldShowColumn(&quot;etag&quot;)">{{
                &quot;harvest_files.columns.etag&quot; | translate }}&nbsp;</th>
              <th id="harvest-files-th-created-time" ng-if="$ctrl.shouldShowColumn(&quot;created_time&quot;)">{{
                &quot;harvest_files.columns.created_time&quot; | translate }}&nbsp;</th>
              <th id="harvest-files-th-last-modified-time"
                ng-if="$ctrl.shouldShowColumn(&quot;last_modified_time&quot;)">{{
                &quot;harvest_files.columns.last_modified_time&quot; | translate }}&nbsp;</th>
              <th id="harvest-files-th-expiry-time" ng-if="$ctrl.shouldShowColumn(&quot;expiry_time&quot;)">{{
                &quot;harvest_files.columns.expiry_time&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'harvest_files.legends.expiry_time.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="harvest-files-th-url-for-air" ng-if="$ctrl.shouldShowColumn(&quot;url_for_air&quot;)">{{
                &quot;harvest_files.columns.url_for_air&quot; | translate }}&nbsp;<div class="ds-richtip --bottom"><i
                    class="ds-icon --icon-info ds-richtip__button --xxsmall"></i>
                  <div class="ds-richtip__content">{{ 'harvest_files.tooltip.url_for_air' | translate }}</div>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody class="x-harvest-files-list" ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()">
            <tr ng-repeat="row in $ctrl.tableData.rows" ng-click="$ctrl.setSelected(row)"
              sc-context-menu="harvest-files-context-menu">
              <td class="td-checkbox harvest-files-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check checkbox-for-each"
                    id="cb{{row.obj.id}}" type="checkbox" ng-model="row.selected"
                    ng-change="$ctrl.checkboxForEachChanged()" ng-click="$event.stopPropagation()" />
                  <div class="ds-checkbox__label">Check {{ row.obj.id }}</div>
                </div>
              </td>
              <td class="harvest-files-filename" ng-if="$ctrl.shouldShowColumn(&quot;filename&quot;)"><a
                  ng-if="row.obj.isDirectory" href="" ng-click="$ctrl.openItem(row.obj)">{{ row.obj.decodedFilename
                  }}</a><span ng-if="!row.obj.isDirectory">{{ row.obj.decodedFilename }}</span></td>
              <td class="harvest-files-content-type" ng-if="$ctrl.shouldShowColumn(&quot;content_type&quot;)"
                ng-bind="row.obj.contentType"></td>
              <td class="harvest-files-content-length" ng-if="$ctrl.shouldShowColumn(&quot;content_length&quot;)"
                ng-bind="row.obj.contentLength"></td>
              <td class="harvest-files-etag" ng-if="$ctrl.shouldShowColumn(&quot;etag&quot;)" ng-bind="row.obj.etag">
              </td>
              <td class="harvest-files-created-time" ng-if="$ctrl.shouldShowColumn(&quot;created_time&quot;)"
                sc-date-time="row.obj.createdTime"></td>
              <td class="harvest-files-last-modified-time"
                ng-if="$ctrl.shouldShowColumn(&quot;last_modified_time&quot;)" sc-date-time="row.obj.lastModifiedTime">
              </td>
              <td class="harvest-files-expiry-time" ng-if="$ctrl.shouldShowColumn(&quot;expiry_time&quot;)"
                sc-date-time="row.obj.expiryTime"></td>
              <td class="harvest-files-url-for-air" ng-if="$ctrl.shouldShowColumn(&quot;url_for_air&quot;)"><span>{{
                  row.obj.urlForAir }}</span><button
                  class="ds-button--icon-clipboard-text ds-button--hide-label --small --plain" type="button"
                  ng-if="!row.obj.isDirectory" ng-click="$ctrl.copyUrl(row.obj); $event.stopPropagation();"><span
                    translate="common.copy"></span></button></td>
              <td><button class="ds-button--icon-download ds-button--hide-label" type="button"
                  ng-if="!row.obj.isDirectory" ng-click="$ctrl.downloadFile(row.obj); $event.stopPropagation();"><span
                    translate="harvest_files.actions.download"></span></button></td>
            </tr>
          </tbody>
          <tbody class="x-no-harvest-files-exist" ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()">
            <tr class="no-data" ng-if="$ctrl.listApiError">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ $ctrl.listApiError.data.message
                }}</td>
            </tr>
            <tr class="no-data" ng-if="!$ctrl.listApiError">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'harvest_files.table.no_data' |
                translate }}</td>
            </tr>
          </tbody>
          <tbody class="x-is-loading" ng-if="$ctrl.isLoading()">
            <tr class="loading">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.loading' |
                translate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div><sc-tutorial-component></sc-tutorial-component>`;
