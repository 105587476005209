import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UiKeyboardEventHandlerRegistrationToken } from '../../soracom-ui/UiKeyboardEventHandlerRegistrationToken';
import { UiKeyboardEventService } from '../../soracom-ui/UiKeyboardEventService';

@Component({
  selector: 'app-log-viewer-record-detail-inspector',
  templateUrl: './log-viewer-record-detail-inspector.component.html',
  styles: [],
})
export class LogViewerRecordDetailInspectorComponent implements OnInit, OnDestroy {
  // @ts-expect-error (legacy code incremental fix)
  keyboardEventHandlerToken: UiKeyboardEventHandlerRegistrationToken;

  constructor(private keyboardEventService: UiKeyboardEventService) {
    //
  }

  ngOnInit() {
    // @ts-expect-error (legacy code incremental fix)
    this.keyboardEventHandlerToken = this.keyboardEventService.addKeyboardEventHandler('Enter', () => {
      if (this.isOpen) {
        this.close.emit();
        return true; // prevent further processing of this event
      }
    });
  }

  ngOnDestroy() {
    const token = this.keyboardEventHandlerToken;
    if (token) {
      this.keyboardEventService.removeKeyboardEventHandler(token);
    }
  }

  /**
   * Slightly messy in that this relies on the owning/parent component conditionally displaying this component with `*ngIf="recordBeingInspected"`. That is, however, exactly how the one user of this component (app-log-viewer) does it. So, this works. I'd like to make a ModalService-like thing for SDS modals, so that we could show/hide them in a more imperative style, but that is out of scope for current work.
   */
  get isOpen() {
    return !!this.recordDetail;
  }

  // @ts-expect-error (legacy code incremental fix)
  @Input() recordDetail: string;

  @Output() close = new EventEmitter();
}
