import { Component, OnInit } from '@angular/core';
import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NapterLog } from '@soracom/shared/soracom-platform';

@Component({
  selector: 'app-logs-raw-data-modal',
  templateUrl: './raw_data.modal.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class LogsRawDataModalComponent
  extends LegacyUibModalAdapterModalBase<{ log: () => NapterLog }, void>
  implements OnInit
{
  // @ts-expect-error (legacy code incremental fix)
  rawData: string;

  ngOnInit() {
    this.rawData = JSON.stringify(
      this.resolve?.log(),
      (key, value) => {
        // Removing properties added by Angular JS. e.g. '$$hashKey'.
        if (key.startsWith('$')) {
          return undefined;
        } else {
          return value;
        }
      },
      2
    );
  }
}
