import { LegacyAny, LegacyOptional } from '@soracom/shared/core';
import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { SoracomApiService } from '../components/soracom_api.service';
import { InjectList } from '../core/injectable';
import { TrafficVolumeRankingDataJar } from './traffic_volume_ranking_data_jar';
import { TrafficVolumeRankingLimitConstraint } from './traffic_volume_ranking_limit_constraint';
import { TrafficVolumeRankingSearchingPeriodConstraint } from './traffic_volume_ranking_period_range_constraint';

export class TrafficVolumeRankingController extends BaseController {
  private alertsService: LegacyOptional<AlertsServiceInstance>;
  private limitConstraint: LegacyOptional<TrafficVolumeRankingLimitConstraint>;
  private translator: LegacyOptional<(key: string) => string>;

  private dataJar: LegacyOptional<TrafficVolumeRankingDataJar>;
  private isLoading: LegacyOptional<boolean>;
  chartData: LegacyOptional<Array<{ imsi: string; trafficVolume: number }>>;

  static $inject: InjectList = ['$log', 'SoracomApi', '$filter', 'AlertsService'];

  constructor(
    private $log: ng.ILogService,
    private SoracomApi: SoracomApiService,
    private $filter: ng.IFilterService,
    private AlertsService: AlertsService,
  ) {
    super($log);
  }

  $onInit(): void {
    this.$log.debug('TrafficVolumeRankingController: $onInit()...');

    this.alertsService = this.AlertsService.generate();
    this.dataJar = new TrafficVolumeRankingDataJar(0, 0, 10);
    this.translator = this.$filter('translate');
    this.limitConstraint = TrafficVolumeRankingLimitConstraint;
    this.isLoading = false;
  }

  search(): void {
    // @ts-expect-error (legacy code incremental fix)
    const fromMillis = this.dataJar.getSearchFromMillis();
    // @ts-expect-error (legacy code incremental fix)
    const toMillis = this.dataJar.getSearchToMillis();
    // @ts-expect-error (legacy code incremental fix)
    const limit = this.dataJar.getLimit();

    if (!TrafficVolumeRankingSearchingPeriodConstraint.isValidMagnitudeCorrelation(fromMillis, toMillis)) {
      // @ts-expect-error (legacy code incremental fix)
      this.alertsService.showError(this.translator('traffic_volume_ranking.error.invalid_range_order'));
      return;
    }

    if (!TrafficVolumeRankingSearchingPeriodConstraint.isValidAllowedRetrospectiveRange(fromMillis)) {
      // @ts-expect-error (legacy code incremental fix)
      this.alertsService.showError(this.translator('traffic_volume_ranking.error.invalid_retrospective_range'));
      return;
    }

    if (!TrafficVolumeRankingSearchingPeriodConstraint.isValidSearchingRange(fromMillis, toMillis)) {
      // @ts-expect-error (legacy code incremental fix)
      this.alertsService.showError(this.translator('traffic_volume_ranking.error.invalid_search_range'));
      return;
    }

    if (!TrafficVolumeRankingLimitConstraint.isValidLimitNumber(limit)) {
      // @ts-expect-error (legacy code incremental fix)
      this.alertsService.showError(this.translator('traffic_volume_ranking.error.invalid_limit_number'));
      return;
    }

    this.isLoading = true;
    this.SoracomApi.searchTrafficVolumeRanking(fromMillis, toMillis, limit, 'desc')
      .then((response: LegacyAny) => {
        this.$log.debug('got searchTrafficVolumeRanking():', response);

        const trafficVolumeRanking = response.data;

        if (!trafficVolumeRanking || !Array.isArray(trafficVolumeRanking)) {
          this.$log.error('Data format error: unknown format');
          // @ts-expect-error (legacy code incremental fix)
          this.alertsService.showError('Data format error: unknown format');
          // @ts-expect-error (legacy code incremental fix)
          this.alertsService.showError(this.translator('traffic_volume_ranking.error.internal_server_error'));
        }
        this.chartData = trafficVolumeRanking;
      })
      .catch((response: LegacyAny) => {
        this.chartData = [];
        this.$log.debug('error from searchTrafficVolumeRanking():', response);
        // @ts-expect-error (legacy code incremental fix)
        this.alertsService.showError(response);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
