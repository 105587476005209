export const LogViewerAllowedApiList = [
  '/v1/audit_logs',
  '/v1/auth',
  '/v1/bills',
  '/v1/cell_locations',
  '/v1/coupons',
  '/v1/credentials',
  '/v1/data',
  '/v1/device_object_models',
  '/v1/devices',
  '/v1/diagnostics',
  '/v1/event_handlers',
  '/v1/files',
  '/v1/gadgets',
  '/v1/groups',
  '/v1/lagoon',
  '/v1/logs',
  '/v1/lora_devices',
  '/v1/lora_gateways',
  '/v1/lora_network_sets',
  '/v1/operators',
  '/v1/orders',
  '/v1/payment_history',
  '/v1/payment_methods',
  '/v1/payment_statements',
  '/v1/port_mappings',
  '/v1/products',
  '/v1/query',
  '/v1/resource_summaries',
  '/v1/sigfox_devices',
  '/v1/sims',
  '/v1/sora_cam',
  '/v1/soralets',
  '/v1/stats',
  '/v1/subscribers',
  '/v1/virtual_private_gateways',
  '/v1/volume_discounts',
];
