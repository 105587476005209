/**
 * Model states can be used when multiple objects (e.g. controllers) display details of a model, but one or more of them is tasked with updating the model object. This is useful in the specific circumstance that you have 2 or more controllers/objects sharing a reference to an instance of some kind of model object, and you don't want to add any additional coupling/relationship between the controllers/objects themselves.
 *
 * If the model has an attribute describing its runtime state, then there doesn't need to be any coupling of the controllers (or whatever interested parties exist) other than the shared model object instance.
 *
 * As a design pattern, this seems pretty dubious to me, but OTOH it is very practical, especially as we migrate gradually from the older console design, which just assumed network operations were instantaneous...
 */
export enum ModelState {
  /**
   * The Loading state indicates the model object is being loaded (presumably asynchronously over the network, and thus should not be editable yet).
   */
  Loading = 'Loading',

  /**
   * The Updating state indicates that the model object is being updated, presumably via API calls, and therefore editing should be temporarily disabled.
   */
  Updating = 'Updating',

  /**
   * The Ready state indicates that the object is not in the middle of being loaded or updated. What this means depends on the use case, but it might mean "ready to be edited". Note this generally does NOT mean "ready to submit" because model state is usually not used to indicate "data is valid.
   */
  Ready = 'Ready',
}
