export const template = `<form name="changeDeviceStatusForm" translate-namespace="devices.modals.change_device_status" novalidate="novalidate">
  <div class="x-form-contents">
    <div class="modal-header">
      <h4 translate=".title"></h4>
    </div>
    <div class="modal-body">
      <div class="form-horizontal">
        <div class="form-group"><label class="col-sm-5 control-label" translate=".device_id"></label>
          <div class="col-sm-7">
            <p class="form-control-static"><span ng-bind-html="$ctrl.describeSelection()"></span></p>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-offset-5 col-sm-5">&nbsp;&nbsp;<i class="glyphicon glyphicon-arrow-down"></i></div>
        </div>
        <div class="form-group"><label class="col-sm-5 control-label" translate=".destination"></label>
          <div class="col-sm-7">
            <p class="form-control-static"><span
                translate="{{ &quot;devices.values.status.&quot; + $ctrl.resolve.destinationStatus }}"></span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-group"><button class="btn btn-default" ng-click="$ctrl.cancel()"
          translate=".cancel"></button><button class="btn btn-primary" ng-click="$ctrl.submit()" translate=".update"
          sc-modal-default-action="sc-modal-default-action"></button></div>
    </div>
  </div>
</form>`;
