import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { ActivateLagoonV2ModalComponent } from './activate-lagoon-v2-modal/activate-lagoon-v2-modal.component';
import { AddLagoonUserModalComponent } from './add-lagoon-user-modal/add-lagoon-user-modal.component';
import { DeleteLagoonUserModalComponent } from './delete-lagoon-user-modal/delete-lagoon-user-modal.component';
import { HarvestFilesMigrationComponent } from './harvest-files-migration/harvest-files-migration.component';
import { LagoonDashboardsComponent } from './lagoon-dashboards/lagoon-dashboards.component';
import { LagoonLicensePacksComponent } from './lagoon-license-packs/lagoon-license-packs.component';
import { LagoonLogoComponent } from './lagoon-logo/lagoon-logo.component';
import { LagoonPageComponent } from './lagoon-page/lagoon-page.component';
import { LagoonPlanComponent } from './lagoon-plan/lagoon-plan.component';
import { LagoonUsersComponent } from './lagoon-users/lagoon-users.component';
import { LagoonComponent } from './lagoon/lagoon.component';
import { RegisterLagoonModalComponent } from './register-lagoon-modal/register-lagoon-modal.component';
import { UpdateLagoonDashboardPermissionModalComponent } from './update-lagoon-dashboard-permission-modal/update-lagoon-dashboard-permission-modal.component';
import { UpdateLagoonLicensePackModalComponent } from './update-lagoon-license-pack-modal/update-lagoon-license-pack-modal.component';
import { UpdateLagoonUserEmailModalComponent } from './update-lagoon-user-email-modal/update-lagoon-user-email-modal.component';
import { UpdateLagoonUserPasswordModalComponent } from './update-lagoon-user-password-modal/update-lagoon-user-password-modal.component';
import { UpdateLagoonUserPermissionModalComponent } from './update-lagoon-user-permission-modal/update-lagoon-user-permission-modal.component';
import { RouterModule } from "@angular/router";

@NgModule({ declarations: [
        ActivateLagoonV2ModalComponent,
        AddLagoonUserModalComponent,
        DeleteLagoonUserModalComponent,
        HarvestFilesMigrationComponent,
        LagoonComponent,
        LagoonDashboardsComponent,
        LagoonLogoComponent,
        LagoonLicensePacksComponent,
        LagoonPageComponent,
        LagoonPlanComponent,
        LagoonUsersComponent,
        RegisterLagoonModalComponent,
        UpdateLagoonDashboardPermissionModalComponent,
        UpdateLagoonLicensePackModalComponent,
        UpdateLagoonUserPasswordModalComponent,
        UpdateLagoonUserPermissionModalComponent,
        UpdateLagoonUserEmailModalComponent,
    ],
    exports: [
        ActivateLagoonV2ModalComponent,
        AddLagoonUserModalComponent,
        DeleteLagoonUserModalComponent,
        LagoonComponent,
        LagoonDashboardsComponent,
        LagoonLogoComponent,
        LagoonPageComponent,
        LagoonPlanComponent,
        LagoonUsersComponent,
        RegisterLagoonModalComponent,
        UpdateLagoonDashboardPermissionModalComponent,
        UpdateLagoonLicensePackModalComponent,
        UpdateLagoonUserPasswordModalComponent,
        UpdateLagoonUserPermissionModalComponent,
        UpdateLagoonUserEmailModalComponent,
    ], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        RouterModule,
        SoracomCommonModule,
        SoracomUiModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class LagoonModule {}
