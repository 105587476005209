import { LegacyAny } from '@soracom/shared/core';

import { AlertsService } from '../components/alerts.service';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import { ReloadTableAction } from '../core/actions/ReloadTableAction';
import { DeviceObjectModel } from '../core/device_object_model';
import { InjectList } from '../core/injectable';
import { template } from './device_object_models.component.html';
import { DeviceObjectModelsService } from './device_object_models.service';
import {
  AddDeviceObjectModelAction,
  DeleteDeviceObjectModelAction,
  ShowDeviceObjectModelAction,
} from './device_object_models_action';

/**
 * A component for display DeviceObjectModels page
 */
export class DeviceObjectModelsComponent implements ng.IComponentOptions {
  bindings = {};

  controller = DeviceObjectModelsComponentController;
  template: any = template;
}

export class DeviceObjectModelsComponentController extends BasePaginatableTableController<DeviceObjectModel> {
  static $inject: InjectList = [
    '$location',
    '$log',
    '$translate',
    '$q',
    '$timeout',
    '$uibModal',
    'AlertsService',
    'DeviceObjectModelsService',
  ];

  constructor(
    private $location: ng.ILocationService,
    $log: ng.ILogService,
    $translate: LegacyAny,
    $q: ng.IQService,
    private $timeout: LegacyAny,
    public $uibModal: any,
    AlertsService: AlertsService,
    public DeviceObjectModelsService: DeviceObjectModelsService,
  ) {
    super($log, $translate, $q, AlertsService, DeviceObjectModelsService);
    this.modelName = 'DeviceObjectModels';
  }

  $onInit() {
    super.$onInit();

    this.setTraceEnabled(true);
    this.trace(this);

    this.actionContainer.actions = {
      AddDeviceObjectModel: new AddDeviceObjectModelAction(this, this.$uibModal, this.DeviceObjectModelsService),
      Delete: new DeleteDeviceObjectModelAction(this, this.$uibModal, this.DeviceObjectModelsService),
      ShowDeviceObjectModel: new ShowDeviceObjectModelAction(
        this,
        this.$q,
        this.$uibModal,
        this.DeviceObjectModelsService,
      ),
      Reload: new ReloadTableAction(this),
    };

    this.reloadData();
  }

  getSelectedModel(): DeviceObjectModel {
    const selection = this.tableData.getSelectedData();
    if (selection.length === 1) {
      return selection[0];
    } else {
      // @ts-expect-error (legacy code incremental fix)
      return undefined;
    }
  }
}
