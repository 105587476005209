<div class="ds-rows">
  <h2 translate="LagoonLicensePacksComponent.title"></h2>
  <div class="ds-notice--info" translate="LagoonLicensePacksComponent.text"></div>
  <ng-container>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </ng-container>
  <div>
    <button class="ds-button" (click)="editPacks()" [disabled]="!hasPermission">
      <span translate="LagoonLicensePacksComponent.edit_license_packs"></span>
    </button>
  </div>
</div>
