import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrencyCode } from '@soracom/shared/regulatory';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { VolumeDiscount } from '../long-term-discounts/VolumeDiscount';

dayjs.extend(customParseFormat);

@Component({
  selector: 'app-long-term-discount-details, [app-long-term-discount-details]',
  templateUrl: './long-term-discount-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LongTermDiscountDetailsComponent {
  /**
   * The discount record the component should display.
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() discount: VolumeDiscount;

  /**
   * The tax amount. This value may or may not be present, depending on the context. (Shown only when confirming the purchase of a new long term discount.)
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() taxAmount: number;

  /**
   * The total purchase amount. This value may or may not be present, depending on the context. (Shown only when confirming the purchase of a new long term discount.)
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() totalAmount: number;

  // @ts-expect-error (legacy code incremental fix)
  @Input() currency: CurrencyCode;

  /**
   * If supplied, list styling will be applied, with borders and alternating row background colors.
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() listIndex: number;

  get isFirst() {
    return this.listIndex != null && this.listIndex === 0;
  }

  get isEven() {
    return this.listIndex != null && this.listIndex % 2 === 0;
  }

  /**
   * Generates a human-friendly summary of the discount term. (Ported from old AngularJS component.)
   */
  get humanReadableDuration() {
    const d = this.discount;

    if (!d) {
      return '--';
    } else if (!d.startDate || !d.contractTermMonth || !d.endDate) {
      return '(invalid data: start/term undefined)';
    } else {
      const startDate = dayjs(d.startDate, 'YYYYMMDD');
      const endDate = dayjs(d.endDate, 'YYYYMMDD');

      // NOTE: Mason 2017-05-22: we discussed putting endDate in the API
      // data structure, but currently it is at least sometimes missing
      // (e.g., new LTD which has not been approved yet).
      // Mason 2019-05-14: So I guess you mean that's why we test for it above?

      const startStr = startDate.format('YYYY-MM-DD');
      const endStr = endDate.format('YYYY-MM-DD');

      return startStr + ' - ' + endStr;
    }
  }
}
