export const template = `<div class="x-device-harvest-usage-alert-component">
  <div class="panel panel-default" translate-namespace="device_harvest_usage_alert_component">
    <div class="panel-heading">
      <h4 translate=".title"></h4>
    </div>
    <div class="panel-body">
      <p class="description" translate=".description"></p>
      <form ng-submit="$ctrl.save()">
        <div class="form-group"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
        <div class="form-group"><label class="ds-switch"><input type="checkbox" ng-model="$ctrl.enabled" />
            <div translate=".labels.enabled"></div>
          </label></div>
        <div class="form-group"><label for="threshold" translate=".labels.threshold"></label><input class="form-control"
            id="threshold" type="text" ng-model="$ctrl.threshold" ng-required="true" /></div>
        <div class="form-group"><label for="title" translate=".labels.title"></label><input class="form-control"
            id="threshold" type="text" ng-model="$ctrl.title" ng-required="true" readonly="readonly" /></div><button
          class="btn btn-default" type="submit" translate=".save"></button>
      </form>
    </div>
  </div>
</div>`;
