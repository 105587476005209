import { Loggable } from '@soracom/shared/soracom-platform';
import { TableController } from '../../components/base_table_controller';
import { Action } from './Action';

/**
 * Generic action to open "Log" page.
 */
export class LogsAction<T extends Loggable> implements Action<T> {
  constructor(private ctrl: TableController<T>, private $location: ng.ILocationService) {}
  run(target: T[]) {
    const resourceType = target[0].resourceType;
    const resourceId = target[0].resourceId;
    this.$location.path('/error_logs').search({ resource_type: resourceType, resource_id: resourceId });
  }
  actionable(target: T[]) {
    return target.length === 1;
  }
}
