import { LegacyBaseSoracomApiService } from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';

export class DefaultPermissionsService {
  static $inject: InjectList = ['BaseSoracomApiService'];

  constructor(private soracomApi: LegacyBaseSoracomApiService) {}

  retrieveDefaultPermission(operatorId: string): any {
    const apiParams = {
      method: 'get',
      path: `/v1/operators/${operatorId}/users/default_permissions`,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  updateDefaultPermission(permissions: string, operatorId: string): any {
    const apiParams = {
      method: 'put',
      path: `/v1/operators/${operatorId}/users/default_permissions`,
      contentType: 'application/json',
      body: {
        permissions: permissions,
      },
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }
}
