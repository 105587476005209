import { LegacyAny } from '@soracom/shared/core';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, mapTo, startWith, switchMap, tap } from 'rxjs/operators';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { DEFAULT_INPUT_DEBOUNCE_TIME } from '../../shared/user_interaction';
import { UiDetailsDirective } from '../../soracom-ui/details/details.directive';
import { ResourceAutoCompleteService } from '../resource-selector.service';
import {
  Resource,
  ResourceOption,
  ResourcesChangeEvent,
  ResourceType,
  RESOURCE_TYPES,
  RESOURCE_TYPE_SIM,
  RESOURCE_TYPE_IMSI,
} from '../resource-selector.type';

/**
 * @masonmark 2023-11-23: I am migrating legacy code (Napter Audit Logs) and I need this resource selector. However, as of this note it seems like this code might be kind of half-baked still. It contemplates being used for various resource types, but in fact so far it has only ever been used for RESOURCE_TYPE_SIM. It also has some bugs. I am using it for the first time with a different resource type. so I am also having to add a few more features. This `DEFAULT_INITIAL_RESOURCE_TYPE` replaces some hardcoded assumptions of `Subscriber` (which === `RESOURCE_TYPE_SIM`).
 */
const DEFAULT_INITIAL_RESOURCE_TYPE = RESOURCE_TYPE_SIM;

/**
 *  This component is a not-yet-widely-used component for selecting multiple resources.
 *
 *  @masonmark 2023-11-23: I am migrating legacy code (Napter Audit Logs) so I am using it for the first time for non-SIM resources.
 *
 * Ideas for future improvements:
 * - If there is only one resource, make sure it is pre-selected and not deletable
 */
@Component({
  selector: 'app-multi-resource-selector',
  templateUrl: './multi-resource-selector.component.html',
  // cancel unexpected general rules
  providers: [ResourceAutoCompleteService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MultiResourceSelectorComponent implements OnInit {
  @Input() availableTypes: ResourceType[] = [...RESOURCE_TYPES];

  /**
   * Initial type to be selected. The default is `RESOURCE_TYPE_SIM` ('Subscriber').
   */
  @Input() initialType: ResourceType = DEFAULT_INITIAL_RESOURCE_TYPE;

  @Input() preloadTypes: ResourceType[] = [DEFAULT_INITIAL_RESOURCE_TYPE];

  @Input() max = 5;

  _resources: Resource[] = [];

  @Input() set resources(resources: Resource[]) {
    this._resources = resources;
  }

  get resources() {
    return this._resources;
  }

  @Output() resourcesChange = new EventEmitter<ResourcesChangeEvent>();

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('searchInput') inputRef: ElementRef;
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild(UiDetailsDirective) uiDetails: UiDetailsDirective;

  searchInputCtl: FormControl = new FormControl();
  typeRadioCtrl: FormControl = new FormControl(DEFAULT_INITIAL_RESOURCE_TYPE);

  type: ResourceType = RESOURCE_TYPE_SIM;

  // @ts-expect-error (legacy code incremental fix)
  searchOptions$: Observable<ResourceOption[]>;

  clearAutoComplete$ = new EventEmitter<string>();

  constructor(
    private translate: TranslateService,
    private autoCompleteSvc: ResourceAutoCompleteService,
    @Optional() private alertsManager: AlertsManager
  ) {}

  ngOnInit(): void {
    this.preloadTypes?.forEach((t) => {
      this.autoCompleteSvc.preload(t);
    });

    if (this.initialType && this.initialType !== this.type) {
      this.typeRadioCtrl.setValue(this.initialType);
      this.type = this.initialType;
      this.clearInput();
    }

    const inputChange = this.searchInputCtl.valueChanges.pipe(
      startWith(''),
      debounceTime(DEFAULT_INPUT_DEBOUNCE_TIME),
      distinctUntilChanged()
    );

    const typeChange = this.typeRadioCtrl.valueChanges.pipe(
      tap((type) => {
        if (this.type !== type) {
          this.type = type;
          this.clearInput();
        }
      }),
      // emit '' to search for autocomplete candidates
      mapTo('')
    );

    this.searchOptions$ = merge(inputChange, typeChange, this.clearAutoComplete$).pipe(switchMap(this.search));
  }

  clear() {
    this.clearType();
    this.clearInput();
    this.clearAutoComplete$.emit('');
  }

  public clearCache() {
    this.clear();
    this.autoCompleteSvc.clearCache();
    this.preloadTypes?.forEach((t) => {
      this.autoCompleteSvc.preload(t);
    });
  }

  addResource(rsc: Resource) {
    if (this.resources.length < this.max && this.resources.every((r) => r.resourceId !== rsc.resourceId)) {
      this.resources = [...this.resources, rsc];

      this.resourcesChange.emit({
        type: 'add',
        targetValue: rsc,
        resources: [...this.resources],
      });
    }
  }

  onClickTagDelete($event: Event, rsc: Resource) {
    this.removeResource(rsc);
    $event.preventDefault();
    $event.stopPropagation();
  }

  removeResource(rsc: Resource) {
    this.resources = this.resources.filter((r) => r !== rsc);

    this.resourcesChange.emit({
      type: 'remove',
      targetValue: rsc,
      resources: [...this.resources],
    });

    this.clearAutoComplete$.emit(this.searchInputCtl.value || '');
  }

  clearType() {
    this.typeRadioCtrl.setValue(this.availableTypes[0]);
  }
  clearInput() {
    this.searchInputCtl.setValue('');
  }

  closeDetails() {
    this.clearType();
    this.clearInput();
    this.uiDetails.close();
  }

  selectOption(option: ResourceOption) {
    this.addResource(option as Resource); //TODO: type
    this.closeDetails();
  }

  setFocusOnSearchInput() {
    (<HTMLElement>this.inputRef?.nativeElement)?.focus();
  }

  get buttonTextTipId() {
    return this.resources.length < this.max ? null : 'resource-selector.maxItemDescription';
  }

  onDetailsClick(e: Event) {
    if (this.resources.length >= this.max) {
      // Prevent opening panel
      e?.preventDefault();
    }
  }

  onDetailsOpen() {
    this.setFocusOnSearchInput();
  }

  onMenuKeyup($event: KeyboardEvent) {
    if ($event.code == 'Escape') {
      this.closeDetails();
    }
  }

  getPlaceholder(type: ResourceType): string {
    return this.translate.instant(`resource-selector.resource.${type}.placeholder`);
  }

  getIdLine(resource: ResourceOption): string {
    if (this.type === RESOURCE_TYPE_IMSI) {
      // @masonmark 2023-12-07: Sorry, this is hacky...
      const simId = (resource as { simId?: string }).simId;
      const simIdStr = simId ? `(SIM ID: ${simId})` : '';
      return `IMSI: ${resource.resourceId} ${simIdStr}`;
    } else {
      // the old behavior before IMSI was added:
      return `${this.getLabel(resource.resourceType)} ID: ${resource.resourceId}`;
    }
  }

  getLabel(type: ResourceType): string {
    if (this.type === RESOURCE_TYPE_IMSI && type === RESOURCE_TYPE_SIM) {
      return 'IMSI'; // no i18n needed
    }
    return this.translate.instant(`resource-selector.resource.${type}.label`);
  }

  search = (term: LegacyAny): Observable<ResourceOption[]> => {
    const existingIds = new Set(this.resources.map((r) => r.resourceId));
    return this.autoCompleteSvc.search(this.type, term).pipe(
      map((options) => options.filter((o) => !existingIds.has(o.resourceId))),
      catchError((err) => {
        this.handleError(err);
        return of([]);
      })
    );
  };

  getOptionValue(item: ResourceOption): ResourceOption {
    return {
      resourceType: item.resourceType,
      resourceId: item.resourceId,
      name: item.name,
    };
  }

  handleError(error: LegacyAny) {
    if (error && this.alertsManager) {
      const alert = Alert.fromApiError(error);
      if (alert) {
        this.alertsManager.add(alert);
      }
    } else {
      console.error(error);
    }
  }

  get simSelected(): boolean {
    return this.type === RESOURCE_TYPE_SIM;
  }
}
