import { LegacyAny } from '@soracom/shared/core';

export function FocusMeDirective($timeout: LegacyAny) {
  const link = (scope: LegacyAny, element: LegacyAny, attr: LegacyAny) => {
    scope.$on('focusMe', (e: LegacyAny, name: LegacyAny) => {
      if (name === attr.focusMe) {
        $timeout(() => {
          element[0].focus();
        }, 100);
      }
    });
    attr.$observe('focusMe', (value: LegacyAny) => {
      if (value === 'true' || value === true) {
        $timeout(() => {
          element[0].focus();
        }, 100);
      }
    });
  };
  const directive = {
    link,
  };

  return directive;
}
FocusMeDirective.$inject = ['$timeout'];
