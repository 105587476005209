export const template = `<div class="x-modal">
  <div class="modal-header">
    <h4 translate="sigfox-show-pac.title"></h4>
  </div>
  <div class="modal-body">
    <div class="x-sigfox-pac-explanation">
      <p translate="sigfox-show-pac.help.paragraph1"></p>
      <p translate="sigfox-show-pac.help.paragraph2"></p>
      <p translate="sigfox-show-pac.help.paragraph3"></p>
      <table class="sc-table table table-condensed table-striped table-hover">
        <thead>
          <th>Device ID</th>
          <th>PAC</th>
        </thead>
        <tbody>
          <tr ng-repeat="device in $ctrl.devices">
            <td>{{ device.deviceId }}</td>
            <td>{{ device.pac }}</td>
          </tr>
        </tbody>
      </table>
      <div class="row block">
        <div class="col-sm-12 text-center"><span>&nbsp;</span><span ng-if="$ctrl.showCopiedSuccessfully"
            translate="sigfox-show-pac.help.copiedSuccessfully"></span><span>&nbsp;</span></div>
      </div>
      <div class="row block">
        <div class="col-sm-12 text-center"><button class="btn btn-default"
            data-clipboard-text="{{ $ctrl.clipboardText() }}" translate="{{$ctrl.copyButtonTitleId}}"></button></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group"><button class="btn btn-default x-modal-cancel" ng-if="false"
        id="sigfox-show-pac-cancel-button" ng-click="$ctrl.cancel()" ng-disabled="$ctrl.canCancel()"
        translate="core.label.cancel"></button><button class="btn btn-primary x-modal-confirm" ng-if="true"
        id="sigfox-show-pac-confirm-button" ng-click="$ctrl.confirm()" ng-disabled="!$ctrl.canConfirm()"
        translate="Done" sc-on-return="$ctrl.onReturn()"></button></div>
  </div>
</div>`;
