export const template = `<app-user-notifications></app-user-notifications>
<div class="x-lora_gateways-page-root" id="page-wrapper" translate-namespace="lora_gateways">
  <div class="row block alerts-wrapper">
    <div class="col-sm-12"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  </div>
  <div class="row block">
    <div class="col-lg-5 col-md-5 col-xs-12" style="margin-bottom: 10px;"><button class="btn btn-default"
        id="lora-gateways-action-show-lora-gateways" ng-disabled="!$ctrl.actionable(&quot;ShowLoraGateway&quot;)"
        ng-click="$ctrl.doAction(&quot;ShowLoraGateway&quot;)"><span class="button-text"
          translate=".actions.show_lora_gateway"></span></button>
      <div class="btn-group" uib-dropdown="uib-dropdown"><button class="btn btn-default dropdown-toggle"
          id="lora-gateways-actions-menu" type="button" uib-dropdown-toggle="uib-dropdown-toggle"><span
            class="button-text" translate="lora_gateways.actions_menu.title"></span><span class="caret"></span></button>
        <ul class="dropdown-menu" id="lora-gateways-actions-dropdown-menu" role="menu"
          uib-dropdown-menu="uib-dropdown-menu">
          <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('EditTag')"
            id="lora-gateways-actions-menu-edit-tag"
            ng-class="{ disabled: !$ctrl.actionable(&quot;EditTag&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;EditTag&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;EditTag&quot;)}">
            <a href="" ng-click="$ctrl.doAction(&quot;EditTag&quot;)">{{ &quot;lora_gateways.actions.edit_tag&quot; |
              translate }}</a></li>
          <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeTerminationProtection')"
            id="lora-gateways-actions-menu-change-termination-protection"
            ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeTerminationProtection&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;ChangeTerminationProtection&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;ChangeTerminationProtection&quot;)}">
            <a href="" ng-click="$ctrl.doAction(&quot;ChangeTerminationProtection&quot;)">{{
              &quot;lora_gateways.actions.change_termination_protection&quot; | translate }}</a></li>
          <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Terminate')"
            id="lora-gateways-actions-menu-terminate"
            ng-class="{ disabled: !$ctrl.actionable(&quot;Terminate&quot;), &quot;x-disabled&quot;: !$ctrl.actionable(&quot;Terminate&quot;), &quot;x-enabled&quot;: $ctrl.actionable(&quot;Terminate&quot;)}">
            <a href="" ng-click="$ctrl.doAction(&quot;Terminate&quot;)">{{ &quot;lora_gateways.actions.terminate&quot; |
              translate }}</a></li>
        </ul>
      </div><button class="btn btn-default" id="table-settings-button"  data-testid="ng1-table-settings-button" ng-click="$ctrl.openTableSettingsModal()"><i
          class="glyphicon glyphicon-cog"></i></button>
    </div>
    <div class="col-lg-7 col-md-7 col-xs-12"><sc-pagination paginator="$ctrl.paginator" ctrl="$ctrl"></sc-pagination>
    </div>
  </div>
  <div class="row block">
    <div class="col-xs-12">
      <ul class="dropdown-menu" id="lora-gateways-context-menu" role="menu" uib-dropdown-menu="uib-dropdown-menu"
        ng-if="$ctrl.shouldShowContextMenu()">
        <li class="dropdown-header" translate="lora_gateways.context_menu.header"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('EditTag')"
          id="lora-gateways-context-menu-edit-tag" ng-class="{ disabled: !$ctrl.actionable(&quot;EditTag&quot;) }"><a
            role="menuitem" href="" ng-click="$ctrl.doAction(&quot;EditTag&quot;)">{{
            &quot;lora_gateways.actions.edit_tag&quot; | translate }}</a></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ShowLoraGateway')"
          id="lora-gateways-context-menu-show-lora-gateway"
          ng-class="{ disabled: !$ctrl.actionable(&quot;ShowLoraGateway&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;ShowLoraGateway&quot;)">{{
            &quot;lora_gateways.actions.show_lora_gateway&quot; | translate }}</a></li>
        <li class="divider"></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('ChangeTerminationProtection')"
          id="lora-gateways-context-menu-change-termination-protection"
          ng-class="{ disabled: !$ctrl.actionable(&quot;ChangeTerminationProtection&quot;) }"><a role="menuitem" href=""
            ng-click="$ctrl.doAction(&quot;ChangeTerminationProtection&quot;)">{{
            &quot;lora_gateways.actions.change_termination_protection&quot; | translate }}</a></li>
        <li ng-if="!$ctrl.shouldShowAction || $ctrl.shouldShowAction('Terminate')"
          id="lora-gateways-context-menu-terminate" ng-class="{ disabled: !$ctrl.actionable(&quot;Terminate&quot;) }"><a
            role="menuitem" href="" ng-click="$ctrl.doAction(&quot;Terminate&quot;)">{{
            &quot;lora_gateways.actions.terminate&quot; | translate }}</a></li>
      </ul>
      <div class="table-responsive table-lora-gateways">
        <table class="sc-table table ds-datatable--striped x-lora-gateways-table" id="lora-gateways-table">
          <thead>
            <tr>
              <th class="th-checkbox ds-datatable__col--min" id="lora-gateways-th-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check"
                    id="lora-gateways-checkbox-for-all" type="checkbox" ng-model="$ctrl.checkboxForAll"
                    ng-change="$ctrl.checkboxForAllChanged($ctrl.checkboxForAll);" />
                  <div class="ds-checkbox__label">Check All</div>
                </div>
              </th>
              <th id="lora-gateways-th-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)">{{
                &quot;lora_gateways.columns.id&quot; | translate }}&nbsp;</th>
              <th id="lora-gateways-th-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)">{{
                &quot;lora_gateways.columns.name&quot; | translate }}&nbsp;</th>
              <th id="lora-gateways-th-gateway-type" ng-if="$ctrl.shouldShowColumn(&quot;gateway_type&quot;)">{{
                &quot;lora_gateways.columns.gateway_type&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'lora_gateways.legends.gateway_type.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="lora-gateways-th-status" ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)">{{
                &quot;lora_gateways.columns.status&quot; | translate }}&nbsp;</th>
              <th id="lora-gateways-th-contract-end-time" ng-if="$ctrl.shouldShowColumn(&quot;contract_end_time&quot;)">
                {{ &quot;lora_gateways.columns.contract_end_time&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'lora_gateways.legends.contract_end_time.description' | translate"></a></li>
                    </ul>
                  </div>
                </span></th>
              <th id="lora-gateways-th-termination-protection"
                ng-if="$ctrl.shouldShowColumn(&quot;termination_protection&quot;)">{{
                &quot;lora_gateways.columns.termination_protection&quot; | translate }}&nbsp;<span class="th-legend">
                  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle legend-toggle" href=""
                      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                      <li><a class="notice" href=""
                          ng-bind-html="'lora_gateways.legends.termination_protection.description' | translate"></a>
                      </li>
                    </ul>
                  </div>
                </span></th>
            </tr>
          </thead>
          <tbody class="x-lora-gateways-list" ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()">
            <tr ng-repeat="row in $ctrl.tableData.rows" ng-click="$ctrl.setSelected(row)"
              sc-context-menu="lora-gateways-context-menu">
              <td class="td-checkbox lora-gateways-checkbox"
                ng-if="$ctrl.shouldShowColumn(&quot;checkbox&quot;) &amp;&amp; $ctrl.selectable()">
                <div class="ds-checkbox ds-checkbox--hide-label"><input class="ds-checkbox__check checkbox-for-each"
                    id="cb{{row.obj.id}}" type="checkbox" ng-model="row.selected"
                    ng-change="$ctrl.checkboxForEachChanged()" ng-click="$event.stopPropagation()" />
                  <div class="ds-checkbox__label">Check {{ row.obj.id }}</div>
                </div>
              </td>
              <td class="lora-gateways-id" ng-if="$ctrl.shouldShowColumn(&quot;id&quot;)" ng-bind="row.obj.id"></td>
              <td class="lora-gateways-name" ng-if="$ctrl.shouldShowColumn(&quot;name&quot;)"><sc-inplace-editable-text
                  ng-if="$ctrl.editable" ng-mouseover="row.hoverName = true" ng-mouseleave="row.hoverName = false"
                  key="name" cancel-on-blur="true" display-value="row.obj.name" edit-ctrl="row.nameEditCtrl"
                  padding-left="&quot;5&quot;" show-pencil-icon="row.hoverName" show-remove-button="false"
                  show-save-button="false" show-cancel-button="false"></sc-inplace-editable-text><span
                  ng-if="!$ctrl.editable" ng-bind="row.obj.name"></span></td>
              <td ng-class="['lora-gateways-gateway-type', 'gateway-type' + row.obj.gatewayType]"
                ng-if="$ctrl.shouldShowColumn(&quot;gateway_type&quot;)"><span ng-if="row.obj.gatewayType">{{
                  'lora_gateways.values.gateway_type.' + row.obj.gatewayType | translate }}</span></td>
              <td class="lora-gateways-status feedback" ng-if="$ctrl.shouldShowColumn(&quot;status&quot;)"
                sc-update-feedback="sc-update-feedback"
                feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                feedback-type="row.feedback.updateStatus" feedback-duration="3000"><sc-lora-gateway-status-label
                  obj="row.obj"></sc-lora-gateway-status-label><sc-session-status-label is-online="row.obj.online"
                  disabled="row.obj.status !== 'active'"></sc-session-status-label></td>
              <td class="lora-gateways-contract-end-time" ng-if="$ctrl.shouldShowColumn(&quot;contract_end_time&quot;)"
                sc-date-time="row.obj.contractEndTime"></td>
              <td class="lora-gateways-termination-protection"
                ng-if="$ctrl.shouldShowColumn(&quot;termination_protection&quot;)"
                sc-update-feedback="sc-update-feedback"
                feedback-classes="{&quot;success&quot;: &quot;feedback-success&quot;, &quot;failure&quot;: &quot;feedback-failure&quot;}"
                feedback-type="row.feedback.changeTerminationProtection" feedback-duration="3000"><i
                  class="ds-icon --icon-unlock x-tp-disabled" ng-if="row.obj.terminationEnabled"
                  style="color: lightgray;"></i><i class="ds-icon --icon-lock x-tp-enabled"
                  ng-if="!row.obj.terminationEnabled"></i></td>
            </tr>
          </tbody>
          <tbody class="x-no-lora-gateways-exist" ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()">
            <tr class="no-data">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.no_data' |
                translate }}</td>
            </tr>
          </tbody>
          <tbody class="x-is-loading" ng-if="$ctrl.isLoading()">
            <tr class="loading">
              <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.loading' |
                translate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div><sc-tutorial-component></sc-tutorial-component>`;
