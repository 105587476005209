// This file is in the .prettierignore file
import { LegacyAny } from '@soracom/shared/core';

import { UCStorage } from '../../shared/UCStorage';

import { Injectable } from '@angular/core';
import { CoverageType } from '@foundation/coverage-type';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { CoverageTypeService, FeatureVisibilityService } from '@soracom/shared/data-access-auth';

export type CustomTagArray = string[];
interface RegionCustomTags {
  [key: string]: CustomTagArray;
}

export enum TableColumnOptionsType {
  Device = 'columnOptionsDevice',
  HarvestData = 'columnOptionsHarvestData',
  HarvestFile = 'columnOptionsHarvestFile',
  Subscriber = 'columnOptions', // this name is from when only Subscribers existed
  SigFox = 'columnOptionsSigFox',
  Log = 'columnOptionsLog',
  LoRa = 'columnOptionsLoRa',
  LoRaGateway = 'columnOptionsLoRaGateway',
  NapterAuditLog = 'columnOptionNapterAuditLog',
  Button = 'columnOptionsButton',
}

export interface ColumnOption {
  key: string;
  show: boolean;
}

export type TableColumnOptions = ColumnOption[];

// // We don't want to build this for production, but I leave it commented out so it can easily be used for debugging.
// // Returns object indicating diff between `oldOptions` and `newOptions`. For debug/test.
// const diffTableColumnOptions = (oldOptions: TableColumnOptions, newOptions: TableColumnOptions) => {
//   const oldValues: { [key: string]: boolean } = {}
//   const newValues: { [key: string]: boolean; } = {}
//   const result: { [key: string]: boolean; } = {}

//   for (const { key, show } of oldOptions || []) {
//     oldValues[key] = !!show;
//   }
//   for (const { key, show } of newOptions || []) {
//     newValues[key] = !!show;
//   }
//   for (const k in { ...oldValues, ...newValues }) {
//     if (oldValues[k] !== newValues[k]) {
//       result[k] = newValues[k];
//     }
//   }
//   return result;
// }

/**
 * This type is what is actually persisted to localStorage, under a key like `columnOptionsXXX` (e.g. `"columnOptionsButton"`). But, the persisted value read out from localStorage could potentially be an older format, so we check/update when reading from localStorage.
 */
export interface TableColumnOptionsConfig {
  [coverageType: string]: TableColumnOptions;
}

export enum TranslationPrefix {
  Device = 'devices.columns.',
  HarvestData = 'harvest_data.columns.',
  HarvestFile = 'harvest_files.columns.',
  Subscriber = 'subscribers.columns.',
  SigFox = 'sigfox_devices.columns.',
  Log = 'logs.columns.',
  LoRa = 'lora_devices.columns.',
  LoRaGateway = 'lora_gateways.columns.',
  NapterAuditLog = 'audit_logs.columns.',
  Button = 'button.columns.',
}

/**
 * Private type to store the column options that have been fetched from persistent storage as in-memory cache while the app is loaded (localStorage is just to persist between launches).
 */
type TableColumnOptionsCache = {
  [key in TableColumnOptionsType]: TableColumnOptionsConfig | false;
};

@Injectable({ providedIn: 'root' })
export class TableColumnOptionsService {
  private logger: Logger = LoggerService.shared();

  constructor(
    private CoverageTypeService: CoverageTypeService,
    private FeatureVisibilityService: FeatureVisibilityService
  ) {}

  private customTags: RegionCustomTags = {};

  /**
   * This structure contains the shared object references that are given to other objects by `get()` and are modified by set(). The `false` value indicates that device type's column options haven't been cached yet.
   *
   * NOTE: I don't like sharing mutable state this way, especially between arbitrary external objects which cannot be known here. (Any UI component might use this service, although in practice it is really just the SIM table column settings modal as of 2022-01-21). However, this is the original design from back when we first implemented this using the AngularJS ngStorage library, so for now I am preserving the existing design.
   */
  private _cachedColumnOptions: TableColumnOptionsCache = {
    columnOptionsDevice: false,
    columnOptionsHarvestData: false,
    columnOptionsHarvestFile: false,
    columnOptions: false, // SIM column options
    columnOptionsSigFox: false,
    columnOptionsLog: false,
    columnOptionsLoRa: false,
    columnOptionsLoRaGateway: false,
    columnOptionNapterAuditLog: false,
    columnOptionsButton: false,
  };

  /**
   * Caches (and also writes to localStorage) the column options config object for type `optionsType` (e.g. `columnOptionsSigFox`, `columnOptionsLoRa` etc). Creates the cached config if none has yet been cached, but otherwise UPDATES the object reference, because these configs are shared by reference (for historical reasons).
   *
   * Note that we perform this caching when the column layouts are modified by the user, and also when the app loads and we read the last-used layouts (if any) from `localStorage`.
   *
   * Note 2: this method requires `updatedConfig` to be a `TableColumnOptionsConfig`, but that object doesn't require every coverage type to be represented. If `updatedConfig` does not contain a value for a given coverage type, the cached table options for that coverage type will not be modified. (This distinction is important when saving, so that you know you can safely overwrite only a single coverage type's column configuration at a time.)
   */
  private cacheColumnOptionsConfig(optionsType: TableColumnOptionsType, updatedConfig: TableColumnOptionsConfig) {
    // Create am in-memory cache storage for this `optionsType`, if this is the first time caching anything for this type:
    if (!this._cachedColumnOptions[optionsType]) {
      this._cachedColumnOptions[optionsType] = {};
    }
    const cachedConfigForThisType = this._cachedColumnOptions[optionsType];

    // the `cachedConfigForThisType` structure now is either `false` (no cache exists yet), or looks like this:
    //
    // {
    //   g: [{ key: 'name', show: true }, /* ... */],
    //   jp: [{ key: 'name', show: true }, /* ... */],
    // }

    // Note, however, that the CHILDREN of `cachedConfigForThisType` are shared by reference with other components/objects! So, if we update them, we must empty and repopulate them, rather than overwriting the stored reference:
    Object.keys(updatedConfig).forEach((ct: LegacyAny) => {
      // Be sure to use a copy, so as not to inadvertently modify object, we'll use it later:
      const updatedOptionsForThisCoverageType = [...updatedConfig[ct]];

      // Be defensive here: treat an empty array as if it didn't exist in the updatedConfig at all. In fact, as of 2022-01-18 the tabel column settings modal sends a structure like this, actually. And even if not, it would be an easy mistake to make, and would never be valid, so we guard against it here.
      //
      if (Array.isArray(updatedOptionsForThisCoverageType) && updatedOptionsForThisCoverageType.length > 0) {
        // We have a valid new value, so cache it:
        const cachedOptionsForThisCoverageType: TableColumnOptions | false = cachedConfigForThisType[ct];
        if (!cachedOptionsForThisCoverageType) {
          // console.log(`cacheColumnOptionsConfig(): no existing TableColumnOptions for ${ct}, so it will be replaced.`, updatedOptionsForThisCoverageType);
          cachedConfigForThisType[ct] = updatedConfig[ct];
        } else {
          const updatedColumnOptions = [...updatedConfig[ct]];
          this.updateColumnOptionsInPlace(cachedOptionsForThisCoverageType, updatedColumnOptions);

          // DEBUG only, comment this out or delete for prod build:
          // const diff = diffTableColumnOptions(cachedOptionsForThisCoverageType, updatedOptionsForThisCoverageType);
          // console.warn('cacheColumnOptionsConfig(): SAVED CHANGES:', diff);
        }
      } else {
        // Don't cache it, it's not a valid columns array. But, this is expected sometimes (e.g. some UI saves for only a single coverage type), so don't warn or anything here. Just ignore the value.
        //
        // console.warn('IGNORING invalid column settings:', ct, updatedOptionsForThisCoverageType);
      }
    });

    // Get the final reference, we may have changed only one coverage type, or all of them:
    const finalConfigToWriteToLocalStorage = this._cachedColumnOptions[optionsType];

    // Write it to localStorage whenever we update the in-memory cache used by UI, so they never get out of sync:
    UCStorage[optionsType] = finalConfigToWriteToLocalStorage;
  }

  private resetObject(obj: any) {
    for (var k in obj) {
      if (obj.hasOwnProperty(k)) {
        delete obj[k];
      }
    }
  }

  /**
   * This method returns a reference that is shared (for historical reasons). Each supported device type has a `TableColumnOptions` object  associated with it, for each coverage type.
   *
   * Multiple tables use the options returned by this method, but a different modal UI is used to modify them. So `get()` needs to get the same object reference that `set()` modifies. This value is also written to localStorage, during both `get()` and `set()` to ensure that the localStorage value persisted across app reloads is never out of sync with the in-memory representation.
   *
   * Note that the object has column options for various different types of entity, e.g. SIM or Button etc.
   *
   * Historical notes: (mason 2022-01-12): This previously relied on `ngStorage` library, and the shared reference for each device type was `$localStorage[columnOptionsXXX]` where XXX is the device type (e.g. columnOptionsButton). So the reference was owned/managed by that library (and magically sync'd to localStorage). I want to make a minimal change here, so I am keeping the shared reference pattern. But the library is gone now and i am not keeping the implementation detail that the reference be managed externally. It should be owned by this service, and this service can load and save it at the appropriate times.
   *
   * @param optionsType — enum value indicated the device type being shown in the table whose column options we are returning
   */
  get(optionsType = TableColumnOptionsType.Subscriber): TableColumnOptions {
    // We will return only the current coverage type's TableColumnOptions, so check it:
    const coverageType = this.CoverageTypeService.getCoverageType();

    const cachedConfig = this._cachedColumnOptions[optionsType];

    let updatedConfig: TableColumnOptionsConfig = cachedConfig == false ? {} : cachedConfig;

    // Read the value out of localStorage. But, it might be an older format that we need to migrate to the current format, so handle that here:
    const storedValue: TableColumnOptionsConfig | string | ColumnOption[] = UCStorage[optionsType];

    if (
      !storedValue
      // @ts-expect-error (legacy code incremental fix)
      || (typeof storedValue === 'object' && storedValue[coverageType] === undefined)
      || storedValue instanceof Array
      || typeof storedValue === 'string'
    ) {
      // In this case, we are nuking some old format. We are going to use the default options, because the format of the stored column options is too old:
      this.resetObject(updatedConfig);
      this.CoverageTypeService.getAvailableCoverageTypes().forEach((ct) => {
        updatedConfig[ct] = this.getDefaultColumnOptions(ct, optionsType);
      });
    } else {
      updatedConfig = storedValue;

      // We have the right basic storage format, but it still might need to be updated for fields that have been added or removed:
      this.CoverageTypeService.getAvailableCoverageTypes().forEach((ct) => {
        const optionsForThisCoverageType = updatedConfig[ct];
        this.updateColumnOptionsIfNeededAndLoadCustomTags(optionsForThisCoverageType, ct, optionsType);
      });
    }

    // Now that any updates are done, cache the (potentially updated) config, in both memory and localStorage:
    this.cacheColumnOptionsConfig(optionsType, updatedConfig);

    // Return the cached value, by reference:
    // @ts-expect-error (legacy code incremental fix)
    return this._cachedColumnOptions[optionsType][coverageType];
  }

  /**
   * Persist the column options for the specified type. This will detect and use the current coverage type; column options are store per device type and per coverage type. The in-memory cache will be updated, and the cache will also be persisted to localStorage as a result of this call.
   */
  set(columnOptions: TableColumnOptions, optionsType: TableColumnOptionsType) {
    //
    const coverageType = this.CoverageTypeService.getCoverageType();

    // Get the cached config for this type:
    let cachedConfig = this._cachedColumnOptions[optionsType];

    // First, make sure the cached object exists and is valid:
    if (!cachedConfig) {
      cachedConfig = {};
      this.CoverageTypeService.getAvailableCoverageTypes().forEach((ct) => {
        // @ts-expect-error (legacy code incremental fix)
        cachedConfig[ct] = columnOptions;
      });
    }

    // Make sure to capture any custom tags here at the save step. Custom tags were added much later than the orginial column-visibility feature, and they are handled a bit differently:
    // @ts-expect-error (legacy code incremental fix)
    this.updateCustomTags(columnOptions, coverageType);

    // Even though we have modified columnOptions above, and the columnOptions is shared by reference with the components using it, ensure the shared reference now exists in the cache (it wouldn't be if this was the first time it was used). The cacheColumnOptionsConfig() call will also write to localStorage, at which point the set() operation is complete:
    // @ts-expect-error (legacy code incremental fix)
    const newConfig: TableColumnOptionsConfig = { [coverageType]: columnOptions };
    this.cacheColumnOptionsConfig(optionsType, newConfig);
  }

  /**
   * This is the primitive method to update the column options. It updates `columnOptions` **in place** (because they are shared by reference for historical reasons), in the case they are in older format, while preserving any custom tags.
   *
   * `get()` calls this after reading the raw stored settings data out of localStorage (it calls it for each coverage type).
   */
  private updateColumnOptionsIfNeededAndLoadCustomTags(
    columnOptions: TableColumnOptions,
    coverageType: CoverageType,
    optionsType: TableColumnOptionsType
  ) {
    const canonicalizedOptions = this.canonicalizeColumnOptions(columnOptions, coverageType, optionsType);
    this.updateCustomTags(canonicalizedOptions, coverageType);

    // Update the columnOptions structure without changing the reference:
    this.updateColumnOptionsInPlace(columnOptions, canonicalizedOptions);
  }

  /**
   * Update destinationObject to equal updateSource. This is useful/required because the current design of this class shares these objects with external components via reference. So when you need to overwrite the contents of a TableColumnOptions reference, but keep the reference the same, use this.
   *
   * @param destinationObject the TableColumnOptions that will be updated — this object will be modified in place
   * @param updateSource the TableColumnOptions object to copy the values from
   */
  private updateColumnOptionsInPlace(destinationObject: TableColumnOptions, updateSource: TableColumnOptions) {
    destinationObject.length = 0;
    destinationObject.push(...updateSource);
    // NOTE: There was a thought that I need to preserve AngularJS magic `$$hashKey` values present in the object here. AngularJS does in fact insert those values at times during the usage of the app, but it works without them so I don't want to treat them specially, just nuke them here, it is OK to do so.
  }

  private getDefaultColumnOptions(coverageType: CoverageType, optionsType: TableColumnOptionsType): TableColumnOptions {
    switch (optionsType) {
      case TableColumnOptionsType.Device:
        return this.getDefaultDeviceColumnOptions(coverageType);
      case TableColumnOptionsType.NapterAuditLog:
        return this.getDefaultNapterAuditLogColumnOptions(coverageType);
      case TableColumnOptionsType.HarvestData:
        return this.getDefaultHarvestDataColumnOptions(coverageType);
      case TableColumnOptionsType.HarvestFile:
        return this.getDefaultHarvestFileColumnOptions(coverageType);
      case TableColumnOptionsType.Log:
        return this.getDefaultLogColumnOptions(coverageType);
      case TableColumnOptionsType.LoRa:
        return this.getDefaultLoRaColumnOptions(coverageType);
      case TableColumnOptionsType.SigFox:
        return this.getDefaultSigFoxColumnOptions(coverageType);
      case TableColumnOptionsType.LoRaGateway:
        return this.getDefaultLoRaGatewayColumnOptions(coverageType);
      case TableColumnOptionsType.Button:
        return this.getDefaultButtonColumnOptions(coverageType);
      default:
        return this.getDefaultSubscriberColumnOptions(coverageType);
    }
  }

  private getDefaultDeviceColumnOptions(coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    co.push({ key: 'checkbox', show: true });
    co.push({ key: 'id', show: true });
    co.push({ key: 'name', show: true });
    co.push({ key: 'group', show: true });
    co.push({ key: 'status', show: true });
    co.push({ key: 'endpoint', show: true });
    co.push({ key: 'online', show: true });
    co.push({ key: 'imsi', show: true });
    co.push({ key: 'imei', show: true });
    co.push({ key: 'manufacturer', show: true });
    co.push({ key: 'model_number', show: true });
    co.push({ key: 'serial_number', show: true });
    /* beautify preserve:end */

    return co;
  }

  private getDefaultNapterAuditLogColumnOptions(_coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    co.push({ key: 'created_at', show: true });
    co.push({ key: 'imsi', show: true });
    co.push({ key: 'type', show: true });
    co.push({ key: 'direction', show: true });
    /* beautify preserve:end */

    return co;
  }

  private getDefaultSubscriberColumnOptions(coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    co.push({ key: 'checkbox', show: true });
    co.push({ key: 'sim_id', show: coverageType === 'g' });
    co.push({ key: 'name', show: true });
    co.push({ key: 'group', show: true });
    if (!this.FeatureVisibilityService.isEnabled('showSerialNumber', coverageType)) {
      co.push({ key: 'iccid', show: true });
    } else {
      co.push({ key: 'iccid', show: false });
    }
    co.push({ key: 'imsi', show: false });
    co.push({ key: 'msisdn', show: false });
    if (this.FeatureVisibilityService.isEnabled('showSerialNumber', coverageType)) {
      co.push({ key: 'serial_number', show: false });
    }
    co.push({ key: 'ip_address', show: false });

    co.push({ key: 'status', show: true });
    co.push({ key: 'last_seen', show: false });
    if (this.FeatureVisibilityService.isEnabled('zoneInfo', coverageType)) {
      co.push({ key: 'country', show: true });
    }
    co.push({ key: 'plan', show: true });
    co.push({ key: 'bundles', show: true });
    co.push({ key: 'subscription', show: true });
    co.push({ key: 'module_type', show: false });
    co.push({ key: 'speed_class', show: true });
    co.push({ key: 'expiry', show: true });
    co.push({ key: 'imei_lock', show: true });
    co.push({ key: 'termination_protection', show: true });
    /* beautify preserve:end */

    return co;
  }

  private getDefaultSigFoxColumnOptions(coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    co.push({ key: 'checkbox', show: true });
    co.push({ key: 'id', show: true });
    co.push({ key: 'name', show: true });
    co.push({ key: 'group', show: true });
    co.push({ key: 'status', show: true });
    co.push({ key: 'registration_status', show: true });
    co.push({ key: 'activated_time', show: coverageType === 'g' });
    co.push({ key: 'registered_time', show: coverageType === 'jp' });
    co.push({ key: 'lqi', show: true });
    co.push({ key: 'last_seen', show: true });
    co.push({ key: 'termination_protection', show: true });
    co.push({ key: 'product_certification_id', show: false });
    /* beautify preserve:end */

    return co;
  }

  private getDefaultLogColumnOptions(_coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    // co.push({   key: 'operatorId',   show: false });
    // co.push({   key: 'logLevel',     show: false });
    // co.push({   key: 'body',         show: false });
    co.push({ key: 'time', show: true });
    co.push({ key: 'service', show: true });
    co.push({ key: 'resource_type', show: true });
    co.push({ key: 'resource_id', show: true });
    co.push({ key: 'message', show: true });
    /* beautify preserve:end */

    return co;
  }

  private getDefaultLoRaColumnOptions(_coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    co.push({ key: 'checkbox', show: true });
    co.push({ key: 'id', show: true });
    co.push({ key: 'name', show: true });
    co.push({ key: 'group', show: true });
    co.push({ key: 'status', show: true });
    co.push({ key: 'rssi', show: true });
    co.push({ key: 'snr', show: true });
    co.push({ key: 'last_seen', show: true });
    co.push({ key: 'termination_protection', show: true });
    /* beautify preserve:end */

    return co;
  }

  private getDefaultLoRaGatewayColumnOptions(_coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    co.push({ key: 'checkbox', show: true });
    co.push({ key: 'id', show: true });
    co.push({ key: 'name', show: true });
    co.push({ key: 'gateway_type', show: true });
    co.push({ key: 'online', show: true });
    co.push({ key: 'status', show: true });
    co.push({ key: 'termination_protection', show: true });
    /* beautify preserve:end */

    return co;
  }

  private getDefaultHarvestDataColumnOptions(_coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    co.push({ key: 'checkbox', show: true });
    co.push({ key: 'resource_type', show: true });
    co.push({ key: 'resource_id', show: true });
    co.push({ key: 'time', show: true });
    co.push({ key: 'content_type', show: true });
    co.push({ key: 'content', show: true });
    co.push({ key: 'decoded_data', show: true });
    co.push({ key: 'chart_data', show: true });
    /* beautify preserve:end */

    return co;
  }

  private getDefaultHarvestFileColumnOptions(_coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    co.push({ key: 'checkbox', show: true });
    co.push({ key: 'filename', show: true });
    co.push({ key: 'content_type', show: true });
    co.push({ key: 'content_length', show: true });
    co.push({ key: 'created_time', show: true });
    co.push({ key: 'etag', show: true });
    co.push({ key: 'last_modified_time', show: true });
    co.push({ key: 'expiry_time', show: true });
    co.push({ key: 'url_for_air', show: true });
    /* beautify preserve:end */

    return co;
  }

  private getDefaultButtonColumnOptions(_coverageType: CoverageType) {
    /* beautify preserve:start */
    const co: TableColumnOptions = [];
    co.push({ key: 'checkbox', show: true });
    co.push({ key: 'serial_number', show: true });
    co.push({ key: 'name', show: true });
    co.push({ key: 'type', show: true });
    co.push({ key: 'status', show: true });
    co.push({ key: 'clicks_remaining', show: true });
    co.push({ key: 'expire_date', show: true });
    co.push({ key: 'last_seen', show: true });
    co.push({ key: 'termination_protection', show: true });
    /* beautify preserve:end */

    return co;
  }

  private getCurrentValue(currentOptions: TableColumnOptions, key: string): boolean | null {
    // @ts-expect-error (legacy code incremental fix)
    const opt: ColumnOption = currentOptions.find((o) => o.key === key);
    if (opt) {
      return opt.show;
    } else {
      return null;
    }
  }

  /**
   * Returns a new `canonicalizeColumnOptions` object that is a copy of `currentOptions`, but with any unnecessary fields removed, and missing fields added. This updates the structure from a previous format, if needed. Custom tags, if any, are preserved.
   */
  private canonicalizeColumnOptions(
    currentOptions: TableColumnOptions,
    coverageType: CoverageType,
    optionsType: TableColumnOptionsType
  ): TableColumnOptions {
    const defaults = this.getDefaultColumnOptions(coverageType, optionsType);
    const co = [];
    for (let i = 0; i < defaults.length; i++) {
      const k = defaults[i].key;
      const v = this.getCurrentValue(currentOptions, k);
      co.push({
        key: k,
        show: v !== null ? v : defaults[i].show,
      });
    }

    // add any custom tags
    for (let j = 0; j < currentOptions.length; j++) {
      const key = currentOptions[j].key;
      if (key.lastIndexOf('tag_', 0) === 0) {
        const val = this.getCurrentValue(currentOptions, key);
        co.push({
          key,
          show: val !== null ? val : currentOptions[j].show,
        });
      }
    }

    return co;
  }

  translationPrefix(tableType: TableColumnOptionsType): TranslationPrefix {
    switch (tableType) {
      case TableColumnOptionsType.Device:
        return TranslationPrefix.Device;
      case TableColumnOptionsType.NapterAuditLog:
        return TranslationPrefix.NapterAuditLog;
      case TableColumnOptionsType.HarvestData:
        return TranslationPrefix.HarvestData;
      case TableColumnOptionsType.HarvestFile:
        return TranslationPrefix.HarvestFile;
      case TableColumnOptionsType.Log:
        return TranslationPrefix.Log;
      case TableColumnOptionsType.LoRa:
        return TranslationPrefix.LoRa;
      case TableColumnOptionsType.LoRaGateway:
        return TranslationPrefix.LoRaGateway;
      case TableColumnOptionsType.SigFox:
        return TranslationPrefix.SigFox;
      case TableColumnOptionsType.Button:
        return TranslationPrefix.Button;
      default:
        return TranslationPrefix.Subscriber;
    }
  }

  /** Add a custom column to the end of existing columns if it doesnt exist already */
  addCustomTagColumn(
    columnName: string,
    currentOptions?: TableColumnOptions,
    optionsType = TableColumnOptionsType.Subscriber
  ) {
    if (columnName == null || columnName === '') {
      this.logger.error('Attemped to add column option with empty columnName');
      return;
    }
    columnName = 'tag_' + columnName;

    if (currentOptions == null) {
      currentOptions = this.get(optionsType);
    }
    const currentValue = this.getCurrentValue(currentOptions, columnName);

    if (currentValue !== null) {
      this.logger.debug('Can not add, custom column already exists: ', columnName);
      return;
    }

    const newOptions = currentOptions.concat([{ key: columnName, show: true }]);
    return newOptions;
  }

  /** Remove a custom tag column if it exists */
  removeCustomTagColumn(
    columnName: string,
    currentOptions: TableColumnOptions,
    optionsType = TableColumnOptionsType.Subscriber
  ) {
    if (columnName == null || columnName === '') {
      this.logger.error('Attemped to add column option with empty columnName');
      return;
    }

    if (currentOptions == null) {
      currentOptions = this.get(optionsType);
    }

    this.logger.debug('Removing: ', columnName);
    const newOptions: ColumnOption[] = [];
    for (let i = 0; i < currentOptions.length; i++) {
      const k = currentOptions[i].key;
      const v = this.getCurrentValue(currentOptions, k);

      // add back all the options except the one we want to remove
      if (k !== columnName) {
        newOptions.push({
          key: k,
          show: v !== null ? v : currentOptions[i].show,
        });
      }
    }

    return newOptions;
  }

  /** Update in place, preserving reference, list of custom tags  */
  private updateCustomTags(currentOptions: TableColumnOptions, coverageType: CoverageType) {
    const refreshedTags = this.customTagColumns(currentOptions);

    // Check to make sure that an array exists for this coverage type.
    if (!this.customTags.hasOwnProperty(coverageType) || !Array.isArray(this.customTags[coverageType])) {
      this.customTags[coverageType] = [];
    }

    this.customTags[coverageType].length = 0;
    for (let i = 0; i < refreshedTags.length; i++) {
      this.customTags[coverageType].push(refreshedTags[i]);
    }
  }

  /** returns reference to custom tags member variable */
  getCustomTags() {
    const coverageType = this.CoverageTypeService.getCoverageType();

    // @ts-expect-error (legacy code incremental fix)
    if (this.customTags[coverageType] == null || !Array.isArray(this.customTags[coverageType])) {
      // @ts-expect-error (legacy code incremental fix)
      this.customTags[coverageType] = [];
    }
    // @ts-expect-error (legacy code incremental fix)
    return this.customTags[coverageType];
  }

  /** Returns the columns that are custom tag ones */
  private customTagColumns(currentOptions: LegacyAny, optionsType = TableColumnOptionsType.Subscriber) {
    if (currentOptions == null) {
      currentOptions = this.get(optionsType);
    }
    const newOptions = [];
    for (let i = 0; i < currentOptions.length; i++) {
      const k = currentOptions[i].key;
      const v = this.getCurrentValue(currentOptions, k);

      // add back all the options except the one we want to remove
      if (this.isCustomTag(k) && v === true) {
        newOptions.push(this.stripTagPrefix(k));
      }
    }
    return newOptions;
  }

  private isCustomTag(key: string) {
    if (typeof key === 'string' && key.lastIndexOf('tag_', 0) === 0) {
      return true;
    }
    return false;
  }

  private stripTagPrefix(key: string) {
    if (!this.isCustomTag(key)) {
      return key;
    }
    return key.substring('tag_'.length);
  }
}
