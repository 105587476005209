import { LegacyAny } from '@soracom/shared/core';

import { LoginUserDataService } from '@soracom/shared/data-access-auth';
import { InjectList } from '../core/injectable';
import {
  ActionConfig,
  ActionConfigType,
  EventHandler,
  ExecutionDateTimeConst,
  InactiveTimeoutDateConst,
  isCumulativeTrafficRule,
  isDailyTrafficRule,
  isMonthlyTotalTrafficRule,
  isMonthlyTrafficRule,
  RuleConfig,
  RuleConfigType,
  TargetType,
} from '../event_handlers/event_handler';
import { EventHandlersService } from '../event_handlers/event_handlers.service';
import { PrefixedEventHandlerViewModel, PrefixedEventHandlerViewModelCollection } from './prefixed_event_handler';

export class PrefixedEventHandlersService {
  static $inject: InjectList = ['$log', '$q', '$translate', 'EventHandler', 'LoginUserDataService'];

  constructor(
    private $log: ng.ILogService,
    private $q: ng.IQService,
    private $translate: LegacyAny,
    private eventHandlersService: EventHandlersService,
    private loginUserDataService: LoginUserDataService,
  ) {}

  _getText(keys: string[], values?: { [key: string]: any }) {
    return this.$translate.instant('prefixed_event_handlers.handler_text.' + keys.join('.'), values);
  }

  /*
   * Parse event handlers to prefixed event handler view model.
   * Input may include unrelated event handlers. they will be filtered out.
   */
  parseEventHandlers(
    eventHandlers: EventHandler[],
    targetType: string,
    targetId?: string,
  ): PrefixedEventHandlerViewModelCollection {
    const settings = new PrefixedEventHandlerViewModelCollection();
    const filteredEventHandlers = eventHandlers
      .filter((eh) => eh.name === 'Prefixed event handler')
      .filter((eh) => eh.target.type === targetType)
      .filter((eh) => !(eh.target.type === TargetType.group && eh.target.groupId !== targetId));

    filteredEventHandlers.forEach((eventHandler: EventHandler) => {
      if (
        !isDailyTrafficRule(eventHandler.ruleConfig) &&
        !isMonthlyTrafficRule(eventHandler.ruleConfig) &&
        !isMonthlyTotalTrafficRule(eventHandler.ruleConfig) &&
        !isCumulativeTrafficRule(eventHandler.ruleConfig)
      ) {
        this.$log.error('Invalid event handler exists: ', eventHandler);
        return;
      }

      const ruleType = eventHandler.ruleConfig.type;
      const value = Number(eventHandler.ruleConfig.properties.limitTotalTrafficMegaByte);
      const actionType = eventHandler.actionConfigList[0].type;
      const statusIsActive = eventHandler.status === 'active';

      if (
        actionType !== ActionConfigType.SendMailToOperatorAction &&
        actionType !== ActionConfigType.ChangeSpeedClassAction
      ) {
        this.$log.error('Invalid event handler exists: ', eventHandler);
        return;
      }

      // @ts-expect-error (legacy code incremental fix)
      settings[actionType][ruleType].active = statusIsActive;
      // @ts-expect-error (legacy code incremental fix)
      settings[actionType][ruleType].value = value;
      // @ts-expect-error (legacy code incremental fix)
      settings[actionType][ruleType].handlerId = eventHandler.handlerId;
      if (eventHandler.ignored !== undefined) {
        // @ts-expect-error (legacy code incremental fix)
        settings[actionType][ruleType].ignored = eventHandler.ignored;
      }
    });
    return settings;
  }

  updateSettings(
    settings: PrefixedEventHandlerViewModelCollection,
    prefixedEventHandlers: EventHandler[],
    targetType: TargetType,
    targetId: string,
  ) {
    const apiCalls: LegacyAny = [];
    let promise;

    const candidates: Array<[ActionConfigType, RuleConfigType, PrefixedEventHandlerViewModel]> = [];
    const a = settings.SendMailToOperatorAction;
    if (a) {
      candidates.push(
        [ActionConfigType.SendMailToOperatorAction, RuleConfigType.DailyTrafficRule, a.DailyTrafficRule],
        [ActionConfigType.SendMailToOperatorAction, RuleConfigType.MonthlyTrafficRule, a.MonthlyTrafficRule],
        [ActionConfigType.SendMailToOperatorAction, RuleConfigType.CumulativeTrafficRule, a.CumulativeTrafficRule],
        [ActionConfigType.SendMailToOperatorAction, RuleConfigType.MonthlyTotalTrafficRule, a.MonthlyTotalTrafficRule],
      );
    }
    const b = settings.ChangeSpeedClassAction;
    if (b) {
      candidates.push(
        [ActionConfigType.ChangeSpeedClassAction, RuleConfigType.DailyTrafficRule, b.DailyTrafficRule],
        [ActionConfigType.ChangeSpeedClassAction, RuleConfigType.MonthlyTrafficRule, b.MonthlyTrafficRule],
        [ActionConfigType.ChangeSpeedClassAction, RuleConfigType.CumulativeTrafficRule, b.CumulativeTrafficRule],
        [ActionConfigType.ChangeSpeedClassAction, RuleConfigType.MonthlyTotalTrafficRule, b.MonthlyTotalTrafficRule],
      );
    }

    candidates.forEach((arr) => {
      const actionType = arr[0];
      const ruleType = arr[1];
      const properties = arr[2];
      promise = this.applyChangeToEventHandler(
        actionType,
        ruleType,
        properties,
        prefixedEventHandlers,
        targetType,
        targetId,
      );
      if (promise) {
        apiCalls.push(promise);
      }
    });
    return this.$q.all(apiCalls);
  }

  /**
   * Apply prefixed event handler changes.
   */
  applyChangeToEventHandler(
    actionType: ActionConfigType,
    ruleType: RuleConfigType,
    viewModel: PrefixedEventHandlerViewModel,
    prefixedEventHandlers: EventHandler[],
    targetType: TargetType,
    targetId: string,
  ): Promise<any> | null {
    if (!viewModel) {
      return null;
    }
    const { handlerId, value, active } = viewModel;

    let newRuleConfig: RuleConfig;
    switch (ruleType) {
      case RuleConfigType.DailyTrafficRule:
        newRuleConfig = {
          type: RuleConfigType.DailyTrafficRule,
          properties: {
            inactiveTimeoutDateConst: InactiveTimeoutDateConst.BEGINNING_OF_NEXT_DAY,
            limitTotalTrafficMegaByte: value,
          },
        };
        break;
      case RuleConfigType.DailyTotalTrafficRule:
        newRuleConfig = {
          type: RuleConfigType.DailyTotalTrafficRule,
          properties: {
            inactiveTimeoutDateConst: InactiveTimeoutDateConst.BEGINNING_OF_NEXT_DAY,
            limitTotalTrafficMegaByte: value,
          },
        };
        break;
      case RuleConfigType.MonthlyTrafficRule:
        newRuleConfig = {
          type: RuleConfigType.MonthlyTrafficRule,
          properties: {
            inactiveTimeoutDateConst: InactiveTimeoutDateConst.BEGINNING_OF_NEXT_MONTH,
            limitTotalTrafficMegaByte: value,
          },
        };
        break;
      case RuleConfigType.MonthlyTotalTrafficRule:
        const runOnceAmongTarget = actionType === ActionConfigType.SendMailToOperatorAction;
        newRuleConfig = {
          type: RuleConfigType.MonthlyTotalTrafficRule,
          properties: {
            inactiveTimeoutDateConst: InactiveTimeoutDateConst.BEGINNING_OF_NEXT_MONTH,
            limitTotalTrafficMegaByte: value,
            runOnceAmongTarget,
          },
        };
        break;
      case RuleConfigType.CumulativeTrafficRule:
        newRuleConfig = {
          type: RuleConfigType.CumulativeTrafficRule,
          properties: {
            inactiveTimeoutDateConst: InactiveTimeoutDateConst.NEVER,
            limitTotalTrafficMegaByte: value,
          },
        };
        break;
      default:
        this.$log.debug('Invalid RuleType: ', ruleType);
        return null;
    }

    if (handlerId) {
      const eventHandler = prefixedEventHandlers.find((e) => e.handlerId === handlerId);
      if (eventHandler === null) {
        this.$log.error(`Target handler id doesn't found ${handlerId}`);
        return null;
      }
      if ((value === undefined || value === null) && !active) {
        // @ts-expect-error (legacy code incremental fix)
        return this.eventHandlersService.destroy(eventHandler);
      } else {
        // @ts-expect-error (legacy code incremental fix)
        eventHandler.ruleConfig = newRuleConfig;
        // @ts-expect-error (legacy code incremental fix)
        eventHandler.status = viewModel.active ? 'active' : 'inactive';
        // @ts-expect-error (legacy code incremental fix)
        eventHandler.actionConfigList = this.getDefaultActionConfigList(ruleType, actionType, value);

        // @ts-expect-error (legacy code incremental fix)
        return this.eventHandlersService.update(eventHandler);
      }
    } else {
      if ((value === undefined || value === null) && !active) {
        // Nothing to do
        return Promise.resolve();
      } else {
        const eventHandler: EventHandler = new EventHandler();
        eventHandler.name = 'Prefixed event handler';
        if (targetType === TargetType.imsi) {
          eventHandler.target = {
            type: TargetType.imsi,
            imsi: targetId,
          };
        } else if (targetType === TargetType.group) {
          eventHandler.target = {
            type: TargetType.group,
            groupId: targetId,
          };
        } else if (targetType === TargetType.operator) {
          eventHandler.target = {
            type: TargetType.operator,
            // @ts-expect-error (legacy code incremental fix)
            operatorId: this.loginUserDataService.getLoginUser()?.operatorId,
          };
        } else {
          this.$log.error(`Unexpected target type: ${targetType}`);
          return null;
        }

        eventHandler.ruleConfig = newRuleConfig;
        eventHandler.status = active ? 'active' : 'inactive';
        eventHandler.actionConfigList = this.getDefaultActionConfigList(ruleType, actionType, value);

        return this.eventHandlersService.create(eventHandler);
      }
    }
  }

  getDefaultActionConfigList(ruleType: string, actionType: string, value: number): ActionConfig[] {
    let title: string;
    let message: string;
    let includeImsi: boolean;
    const imsiHeader = this._getText(['imsiHeader']);

    switch (ruleType) {
      case 'DailyTrafficRule':
      case 'MonthlyTrafficRule':
      case 'CumulativeTrafficRule':
        includeImsi = true;
        break;
      case 'DailyTotalTrafficRule':
      case 'MonthlyTotalTrafficRule':
        includeImsi = false;
        break;
      default:
        break;
    }

    switch (actionType) {
      case 'ChangeSpeedClassAction':
        let executionDate: LegacyAny;
        switch (ruleType) {
          case 'DailyTrafficRule':
          case 'DailyTotalTrafficRule':
            executionDate = ExecutionDateTimeConst.BEGINNING_OF_NEXT_DAY;
            break;
          case 'MonthlyTrafficRule':
          case 'MonthlyTotalTrafficRule':
            executionDate = ExecutionDateTimeConst.BEGINNING_OF_NEXT_MONTH;
            break;
          case 'CumulativeTrafficRule':
            executionDate = ExecutionDateTimeConst.NEVER;
            break;
          default:
            break;
        }

        // TODO: How to rollback every SIMs change?
        const currentSpeedClass = 's1.standard';
        const limitedSpeedClass = 's1.minimum';
        let startMessage = this._getText([actionType, 'StartMessage', ruleType], {
          value,
          speedClass: limitedSpeedClass,
        });
        let endMessage = this._getText([actionType, 'EndMessage'], { speedClass: currentSpeedClass });
        // @ts-expect-error (legacy code incremental fix)
        if (includeImsi) {
          startMessage = imsiHeader + startMessage;
          endMessage = imsiHeader + endMessage;
        }

        return [
          {
            type: ActionConfigType.ChangeSpeedClassAction,
            properties: {
              speedClass: limitedSpeedClass,
              executionDateTimeConst: ExecutionDateTimeConst.IMMEDIATELY,
            },
          },
          {
            type: ActionConfigType.ChangeSpeedClassAction,
            properties: {
              speedClass: currentSpeedClass,
              executionDateTimeConst: executionDate,
            },
          },
          {
            type: ActionConfigType.SendMailToOperatorAction,
            properties: {
              title: this._getText(['SendMailToOperatorAction', 'Subject', 'TransferSpeedsIsLimited']),
              message: startMessage,
              executionDateTimeConst: ExecutionDateTimeConst.IMMEDIATELY,
            },
          },
          {
            type: ActionConfigType.SendMailToOperatorAction,
            properties: {
              title: this._getText(['SendMailToOperatorAction', 'Subject', 'TransferSpeedsIsRecovered']),
              message: endMessage,
              executionDateTimeConst: executionDate,
            },
          },
        ];

      case 'SendMailToOperatorAction':
        // TODO: Add console url
        title = this._getText(['SendMailToOperatorAction', 'Alert', 'Subject']);
        message = this._getText(['SendMailToOperatorAction', 'Alert', 'Message', ruleType], { value });
        // @ts-expect-error (legacy code incremental fix)
        if (includeImsi) {
          message = imsiHeader + message;
        }

        return [
          {
            type: ActionConfigType.SendMailToOperatorAction,
            properties: {
              title,
              message,
              executionDateTimeConst: ExecutionDateTimeConst.IMMEDIATELY,
            },
          },
        ];

      default:
        return [];
    }
  }
}
