import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';

import { InjectList } from '../core/injectable';
import { ReservedTagNames } from '../core/reserved_tag_names';

export const FilterReservedTagFilter = ($cacheFactory: LegacyAny) => {
  const cache = $cacheFactory('FilterReservedTagCache');

  return (tags: { [tagName: string]: any }, options: { invert: boolean }) => {
    const cacheKey = JSON.stringify(tags) + '&' + JSON.stringify(options);
    const cachedResult = cache.get(cacheKey);
    if (!!cachedResult) {
      return cachedResult;
    }

    const filtered: LegacyAny = {};
    const invert = options && !!options.invert;

    angular.forEach(tags, (value, key) => {
      if ((invert && ReservedTagNames.indexOf(key) === -1) || (!invert && ReservedTagNames.indexOf(key) !== -1)) {
        filtered[key] = value;
      }
    });
    cache.removeAll();
    return cache.put(cacheKey, filtered);
  };
};

const injectList: InjectList = ['$cacheFactory'];
FilterReservedTagFilter.$inject = injectList;
