/**
 * All searchable fields (canonical reference used internall in user-console).
 */

export enum DeviceSearchableField {
  any = 'any',
  deviceId = 'deviceId',
  name = 'name',
  group = 'group',
  tag = 'tag',
  imsi = 'imsi',
  imei = 'imei',
}

export enum DeviceSearchOption {
  searchType = 'search_type',

  // limit            = "limit",
  // lastEvaluatedKey = "last_evaluated_key",
  // Mason 2019-03-13: I don't think we need these here? I think ScPaginator handles these keys which are shared for other API queries and limit the pagination whether or not search is being done
}
