const BANNED_ELEMENTS = [null, undefined, '..', '.', '*', '**', ''];

export interface HarvestFileOption {
  encode?: boolean;
  decode?: boolean;
}

export interface HarvestFileParams {
  contentType: string;
  contentLength: number;
  createdTime: number;
  etag: string;
  filename: string;
  isDirectory: boolean;
  lastModifiedTime: number;
  expiryTime: number;
}

export class HarvestFile {
  contentType: string;
  contentLength: number;
  createdTime: number;
  etag: string;
  filename: string;
  isDirectory: boolean;
  lastModifiedTime: number;
  expiryTime: number;

  // @ts-expect-error (legacy code incremental fix)
  _base: string;
  // @ts-expect-error (legacy code incremental fix)
  _path: string;

  /**
   * format file path
   * @param path
   * @param options
   */
  static formatPath(path: string, options: HarvestFileOption = {}) {
    if (!path) {
      return '/';
    }

    if (options.decode) {
      path = path.replace(/%2F/gi, '/');
    }

    const endWithSlash = path.lastIndexOf('/') === path.length - 1;
    const sanitizedElements = path
      .split('/')
      .filter((elem) => elem.length > 0 && !BANNED_ELEMENTS.includes(elem))
      .map((elem) => (options.encode ? this.encodePath(elem) : elem))
      .map((elem) => (options.decode ? this.decodePath(elem) : elem));

    if (sanitizedElements.length === 0) {
      return '/';
    }
    return `/${sanitizedElements.join('/')}${endWithSlash ? '/' : ''}`;
  }

  static getParent(path: string) {
    const formatted = HarvestFile.formatPath(path);
    if (formatted === null || formatted === undefined || formatted === '/') {
      return '/';
    }
    const elements = formatted.split('/').filter((e) => e.length > 0);
    return '/' + elements.slice(0, elements.length - 1).join('/') + '/';
  }

  static isDirectory(path: string): boolean {
    // @ts-expect-error (legacy code incremental fix)
    return path && path.length > 0 && path[path.length - 1] === '/';
  }

  static getCurrentDirectory(path: string) {
    const formatted = HarvestFile.formatPath(path);
    if (this.isDirectory(formatted)) {
      return formatted;
    } else {
      return this.getParent(formatted);
    }
  }

  static encodePath(element: string): string {
    return encodeURIComponent(element).replace(/%3A/gi, ':'); // ch21595
  }

  static decodePath(element: string): string {
    return decodeURIComponent(element.replace(/%3A/g, '%253A').replace(/%3a/g, '%253a')); // ch21595
  }

  constructor(params: HarvestFileParams, base = '/') {
    this.contentType = params.contentType;
    this.contentLength = params.contentLength;
    this.createdTime = params.createdTime;
    this.etag = params.etag;
    this.filename = params.filename;
    this.isDirectory = params.isDirectory;
    this.lastModifiedTime = params.lastModifiedTime;
    this.expiryTime = params.expiryTime;
    this.base = base;
  }

  // Return encoded path
  get path() {
    return `${this.base}${this.filename}`;
  }

  // Return encoded base directory
  get base() {
    return this._base;
  }

  set base(newValue) {
    // base should be always directory. so I added "/" every time. Unnecessary  "/" will be removed in `formatPath` method.
    this._base = HarvestFile.formatPath(newValue + '/');
  }

  get decodedFilename(): string {
    return HarvestFile.decodePath(this.filename);
  }

  get urlForAir(): string {
    if (this.isDirectory) {
      return '';
    }
    return `http://harvest-files.soracom.io${this.path}`;
  }
}
