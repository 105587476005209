import { LegacyAny } from '@soracom/shared/core';

export function NgEnterDirective() {
  const link = (scope: LegacyAny, element: LegacyAny, attrs: LegacyAny) => {
    element.bind('keydown keypress', (event: LegacyAny) => {
      if (event.which === 13) {
        scope.$apply(() => {
          scope.$eval(attrs.ngEnter);
        });
        event.preventDefault();
      }
    });
  };

  const directive = {
    link,
  };

  return directive;
}
