import { LegacyAny } from '@soracom/shared/core';

import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concat, from, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LteMButtonStoreSelectors } from '.';
import { RootStoreState } from '..';
import { SubscriberSearchQueryParam } from '../../../../app/shared/components/subscriber_search_query_param';
import { GROUPS_SERVICE, SUBSCRIBERS_SERVICE } from '../../ajs-upgraded-providers';
import * as featureActions from './actions';

@Injectable()
export class LteMButtonStoreEffects {
  constructor(private actions$: Actions, private store$: Store<RootStoreState.State>, private injector: Injector) {}

  get groupsService() {
    return this.injector.get(GROUPS_SERVICE);
  }

  get subscribersService() {
    return this.injector.get(SUBSCRIBERS_SERVICE);
  }

  applyRouteParametersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.applyRouteParameters),
      withLatestFrom(this.store$.select(LteMButtonStoreSelectors.selectLteMButtonStateValue)),
      switchMap(([action, state]) => {
        switch (state) {
          case 'index': {
            return of(featureActions.loadConfiguredSubscribers());
          }
          case 'selectSubscribers': {
            return of(featureActions.noop());
          }
          case 'selectGroup': {
            // @ts-expect-error (legacy code incremental fix)
            return of(featureActions.loadSelectedSubscribers({ imsiList: action.imsiList }));
          }
          case 'newConfig': {
            return concat(
              // @ts-expect-error (legacy code incremental fix)
              of(featureActions.loadSelectedSubscribers({ imsiList: action.imsiList })),
              // @ts-expect-error (legacy code incremental fix)
              of(featureActions.initializeGroup({ name: action.newGroupName }))
            );
          }
          case 'editConfig': {
            return concat(
              // @ts-expect-error (legacy code incremental fix)
              of(featureActions.loadSelectedSubscribers({ imsiList: action.imsiList })),
              // @ts-expect-error (legacy code incremental fix)
              of(featureActions.loadGroup({ groupId: action.groupId }))
            );
          }
          default: {
            return of(featureActions.unknownError(`Unknown state ${state}`));
          }
        }
      })
    )
  );

  loadGroupEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadGroup),
      switchMap((action) => {
        const promise = this.groupsService.get(action.groupId);
        return from(promise).pipe(
          map(featureActions.loadGroupSuccess),
          catchError((error: LegacyAny) => of(featureActions.loadGroupError(error)))
        );
      })
    )
  );

  loadSelectedSubscribersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadSelectedSubscribers),
      switchMap((action) => {
        if (action.imsiList && action.imsiList.length > 0) {
          const queryParam = SubscriberSearchQueryParam.createSubscriberSearchMultipleIMSIQueryParam(action.imsiList);
          const promise = this.subscribersService.search(queryParam).then((relation) => relation.data);
          return from(promise).pipe(
            map(featureActions.loadSelectedSubscribersSuccess),
            catchError((error: LegacyAny) => of(featureActions.loadSelectedSubscribersError(error)))
          );
        } else {
          return of(featureActions.loadSelectedSubscribersSuccess([]));
        }
      })
    )
  );
}
