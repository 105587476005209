import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { formatDateTime } from '@soracom/shared/util-common';
import { HarvestData } from '../../../../../app/shared/core/harvest_data';

const getName = (d: HarvestData) => `${d.resourceType}@${d.resourceId}`;

@Component({
  selector: 'app-harvest-data-chart-preview-panel',
  templateUrl: './harvest-data-chart-preview-panel.component.html',
  styleUrls: ['./harvest-data-chart-preview-panel.component.scss'],
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HarvestDataChartPreviewPanelComponent {
  @HostBinding('class.preview-panel-open') isShowing = false;

  // @ts-expect-error (legacy code incremental fix)
  private _data: any[];
  @Input() set data(data: HarvestData[] | undefined | null) {
    this._data = this.convertToPreviewJsonData(data || []);
  }

  get data() {
    return this._data;
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  convertToPreviewJsonData(original: HarvestData[]) {
    return original.map((d: LegacyAny) => ({
      name: getName(d),
      data: {
        resourceType: d.resourceType,
        resourceId: d.resourceId,
        time: formatDateTime(d.time, 'iso_8601_msec_tz'),
        decodedData: d.decodedData,
        chartData: d.chartData,
      },
    }));
  }

  public open() {
    if (!this.isShowing) {
      this.isShowing = true;
      this.cdRef.markForCheck();
    }
  }

  public close() {
    if (this.isShowing) {
      this.isShowing = false;
      this.cdRef.markForCheck();
    }
  }

  onCloseButtonClick() {
    this.close();
  }
}
