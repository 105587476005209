import { Component, EventEmitter, Input, Output } from '@angular/core';
import { assertNever } from '@soracom/shared/core';
import { AbstractController } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { LogsTableColumnId } from '../ErrorLogEntryWrapper';
import { LogViewerController, LogViewerControllerMode } from '../log-viewer/log-viewer.component';
import { LogEntryWrapper, LogEntryWrapperColumnId } from '../LogEntryWrapper';
import { PaginatedLogEntryList } from '../PaginatedLogEntryList';

@Component({
  selector: 'app-log-viewer-table',
  templateUrl: './log-viewer-table.component.html',
})
export class LogViewerTableComponent extends AbstractController {
  /**
   * The parent component (app-log-viewer) implements this interface so we can observe certain things about the parent state.
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() logViewerController: LogViewerController;

  /**
   * Emitted when the filter button is clicked in a cell in the Resource ID column
   */
  @Output() filterByResourceId = new EventEmitter<string>();

  /**
   * This table component renders all types of log, but it doesn't switch modes, so we can use ngOnInit() to calculate the default table columns that should be shown. Someday this might be a persisted setting in user preferences, but for now we always start with the default.
   */
  ngOnInit(): void {
    super.ngOnInit();
    const mode = this.logViewerController.mode;

    this.updateInitialVisibleColumns(mode);
  }

  /**
   * The ordered list of currently-visible columns. Decided in `ngOnInit()` based on `mode`.
   */
  visibleColumnIds: LogEntryWrapperColumnId[] = [];

  /**
   * Display the correct default set of columns, based on the `mode`.
   */
  updateInitialVisibleColumns(mode: LogViewerControllerMode) {
    // prettier-ignore
    if (mode === 'auditLogs' || mode === 'testAuditLogs') {
      this.visibleColumnIds = [
        'displayDate',
        'remoteIpAddress',
        'userName',
        'requestPath',
        'statusCode',
      ];
    } else if (mode === 'napterAuditLogs' || mode === 'testNapterAuditLogs') {
      this.visibleColumnIds = [
        'createdAt',
        'imsi',
        'type',
        'direction'
      ];
    } else if (mode === 'errorLogs' || mode === 'testErrorLogs') {
      this.visibleColumnIds = [
        'time',
        'service',
        'resourceType',
        'resourceId',
        'message'
      ];
    } else {
      this.error("UNKNOWN MODE", mode, this);
    }
  }

  /**
   * Expose our parent's loading state to the template.
   */
  get isLoading() {
    return this.logViewerController.state === 'loading';
  }

  /**
   * Returns a **space-separated** list of classes that should be applied to the `<th>` for column `columnId`. Used to control column width.
   */
  additionalClassesForTh(columnId: LogEntryWrapperColumnId): string {
    if (columnId === 'message') {
      return 'ds-datatable__col--auto';
    } else {
      return 'ds-datatable__col--min';
    }
  }

  /**
   * Returns a **space-separated** list of classes that should be applied to the `<td>` which will display the log entry field matching `columnId`. Used by certain special columns, to control wrap, or show an icon.
   */
  additionalClassesForTd(columnId: LogEntryWrapperColumnId): string {
    if (columnId === 'displayDate') {
      return 'ds-text--icon-calendar-dates ds-text--nowrap';
    } else if (columnId === 'message') {
      return ''; // This column should wrap
    } else {
      return 'ds-text--nowrap';
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  private _currentPage: PaginatedLogEntryList;

  /**
   * The parent `app-log-viewer` component gives us a page of data that contains an array of `LogEntryWrapper`. Depending on the `mode` of the parent, those wrappers might be `AuditLogEntryWrapper` or `NapterAuditLogEntryWrapper` or `ErrorLogEntryWrapper` (and maybe some day other types). But, this table component is implemented in a way that he doesn't have to care much, since all wrappers just provide various string representations of data, so this component just needs to display those.
   *
   * However, when the page changes (e.g. when the user changes their search or goes to the next page or whatever) certain side effects need to occur. Those side effects are implemented here.
   */
  @Input() set currentPage(newValue: PaginatedLogEntryList) {
    this._currentPage = newValue;

    this.updateAllSelected();
    this.emitSelectionChange();
  }

  get currentPage() {
    return this._currentPage;
  }

  /**
   * This is what the table in the template binds to.
   */
  get logEntries() {
    // Always return an array. Don't use undefined to mean anything special. The parent's loading state should indicate when we are loading.
    return this.currentPage?.entries || [];
  }

  @Output() selectionChange = new EventEmitter<LogEntryWrapper[]>();

  allSelected = false;

  updateAllSelected() {
    this.allSelected = this.logEntries.length < 1 ? false : this.logEntries.find((e) => !e.selected) == null;
  }

  toggleSelectAll() {
    const newSelectedValue = !this.allSelected;

    for (const e of this.logEntries) {
      e.selected = newSelectedValue;
    }

    this.allSelected = newSelectedValue;
    this.emitSelectionChange();
  }

  toggleSelected(entry: LogEntryWrapper) {
    entry.selected = !entry.selected;
    this.updateAllSelected();
    this.emitSelectionChange();
  }

  emitSelectionChange() {
    // debugger;
    const newSelection = this.allSelected ? [...this.logEntries] : this.logEntries.filter((e) => e.selected);

    setTimeout(() => {
      // This needs to be wrapped in setTimeout() because otherwise events being emitted from app-log-viewer-v1-controls can trigger things (e.g. changing the per-page count) that cause the selection to be updated. In that case, ExpressionChangedAfterItHasBeenCheckedError might occur (which in production isn't an error but a missed change).

      this.selectionChange.emit(newSelection);
    });
  }

  emitFilterChange($event: Event, value: string) {
    this.filterByResourceId.emit(value);
  }

  sortColumns(column: LogsTableColumnId) {
    alert('sort by ' + column);
  }

  static readonly FILTERABLE_COL_IDS: LogsTableColumnId[] = ['resourceId'];

  shouldShowFilterButton(columnId: LogsTableColumnId | LogEntryWrapperColumnId) {
    return (LogViewerTableComponent.FILTERABLE_COL_IDS as string[]).includes(columnId);
  }

  get isFiltered() {
    return this.logViewerController.userHasSetSearchParameters;
  }

  private _noAuditLogsRecordsExist = LegacyTextContent.translation('SoracomLogs.noAuditLogRecordsExist');
  private _noErrorLogsRecordsExist = LegacyTextContent.translation('SoracomLogs.noErrorLogRecordsExist');
  private _noNapterAuditLogsRecordsExist = LegacyTextContent.translation('SoracomLogs.noNapterAuditLogRecordsExist');

  // @ts-expect-error (legacy code incremental fix)
  get noRecordsExist(): LegacyTextContent {
    const mode = this.logViewerController.mode;
    switch (mode) {
      case 'auditLogs':
      case 'testAuditLogs':
        return this._noAuditLogsRecordsExist;
      case 'errorLogs':
      case 'testErrorLogs':
        return this._noErrorLogsRecordsExist;
      case 'napterAuditLogs':
      case 'testNapterAuditLogs':
        return this._noNapterAuditLogsRecordsExist;
      default:
        assertNever(mode);
    }
  }

  private _noAuditLogsRecordsMatchingTheFilterExist = LegacyTextContent.translation(
    'SoracomLogs.noAuditLogRecordsMatchingTheFilterExist'
  );
  private _noErrorLogsRecordsMatchingTheFilterExist = LegacyTextContent.translation(
    'SoracomLogs.noErrorLogRecordsMatchingTheFilterExist'
  );
  private _noNapterAuditLogsRecordsMatchingTheFilterExist = LegacyTextContent.translation(
    'SoracomLogs.noNapterAuditLogRecordsMatchingTheFilterExist'
  );

  // @ts-expect-error (legacy code incremental fix)
  get noRecordsMatchingFilterExist(): LegacyTextContent {
    const mode = this.logViewerController.mode;
    switch (mode) {
      case 'auditLogs':
      case 'testAuditLogs':
        return this._noAuditLogsRecordsMatchingTheFilterExist;
      case 'errorLogs':
      case 'testErrorLogs':
        return this._noErrorLogsRecordsMatchingTheFilterExist;
      case 'napterAuditLogs':
      case 'testNapterAuditLogs':
        return this._noNapterAuditLogsRecordsMatchingTheFilterExist;
      default:
        assertNever(mode);
    }
  }
}
