import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';

import { Group } from '@soracom/shared/group';
import { InjectList } from '../core/injectable';

export const OrderGroupByNameFilter = ($cacheFactory: LegacyAny) => {
  const cache = $cacheFactory('OrderGroupByNameFilterCache');
  return (groups: { [id: string]: Group }, field: string, reverse: boolean) => {
    const key = JSON.stringify(groups) + '&field=' + field + '&reverse=' + reverse;
    const cachedResult = cache.get(key);
    if (!!cachedResult) {
      return cachedResult;
    }

    const filtered: LegacyAny = [];
    angular.forEach(groups, (group) => filtered.push(group));
    filtered.sort((a: LegacyAny, b: LegacyAny) => {
      // case insensitive compare
      const aName = (a.tags && a.tags.name) || '';
      const bName = (b.tags && b.tags.name) || '';

      return aName.localeCompare(bName, undefined, { sensitivity: 'base' });
    });
    if (reverse) {
      filtered.reverse();
    }

    cache.removeAll();
    return cache.put(key, filtered);
  };
};

const injectList: InjectList = ['$cacheFactory'];
OrderGroupByNameFilter.$inject = injectList;
