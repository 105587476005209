import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { LagoonLicenseQuota } from '../../../../app/shared/core/lagoon_license_quota';
import {
  LagoonLicensePackType,
  LagoonLicensePackUpdatingRequest,
  LagoonService,
} from '../../../../app/shared/lagoon/lagoon.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-update-lagoon-license-pack-modal',
  templateUrl: './update-lagoon-license-pack-modal.component.html',
  styleUrls: ['./update-lagoon-license-pack-modal.component.scss'],
})
export class UpdateLagoonLicensePackModalComponent
  extends DsModalContentBase<void, { success: boolean }>
  implements OnInit
{
  alertsManager = new AlertsManager();
  loading = false;
  // @ts-expect-error (legacy code incremental fix)
  quota: LagoonLicenseQuota;
  get uiModalAlertsManager() {
    return this.alertsManager;
  }
  get canConfirm(): boolean {
    return !this.loading && this.form.valid && this.form.dirty;
  }
  get userPackCount(): number {
    // @ts-expect-error (legacy code incremental fix)
    return this.form.get('userPackCount').value;
  }
  get dashboardPackCount(): number {
    // @ts-expect-error (legacy code incremental fix)
    return this.form.get('dashboardPackCount').value;
  }
  form = this.fb.group({
    userPackCount: [0, [Validators.required, Validators.min(0), Validators.max(99)]],
    dashboardPackCount: [0, [Validators.required, Validators.min(0), Validators.max(99)]],
  });
  translationValues = { url: '' };

  constructor(
    private coverageTypeService: CoverageTypeService,
    private fb: FormBuilder,
    private lagoonService: LagoonService,
    private modalService: UiDsModalService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    const coverageType = this.coverageTypeService.getCoverageType();
    this.translateService.get(`LagoonComponent.pricing_url_${coverageType}`).subscribe((url: string) => {
      this.translationValues = { url };
    });
  }

  ngAfterViewInit(): void {
    // get the current counts
    this.loading = true;
    this.cdRef.detectChanges();
    this.lagoonService
      .getQuota()
      .then((quota: LegacyAny) => {
        this.quota = quota;
        this.form.setValue({ userPackCount: quota.userPackCount, dashboardPackCount: quota.dashboardPackCount });
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async confirm() {
    const result = await this.modalService.openConfirmModal('UpdateLagoonLicensePackModalComponent.confirmation.body', {
      title: 'UpdateLagoonLicensePackModalComponent.confirmation.title',
      okButtonTitleId: 'UpdateLagoonLicensePackModalComponent.confirmation.confirm',
      cancelButtonTitleId: 'UpdateLagoonLicensePackModalComponent.confirmation.cancel',
    });
    if (result) {
      await this.updateLicensePacks();
    }
  }

  async updateLicensePacks() {
    this.loading = true;
    try {
      const req: LagoonLicensePackUpdatingRequest[] = [];
      const userPackFormControl = this.form.get('userPackCount');
      // @ts-expect-error (legacy code incremental fix)
      if (userPackFormControl.dirty) {
        req.push({
          licensePackName: LagoonLicensePackType.BasicUser,
          // @ts-expect-error (legacy code incremental fix)
          desiredQuantity: userPackFormControl.value,
        });
      }
      const dashboardPackFormControl = this.form.get('dashboardPackCount');
      // @ts-expect-error (legacy code incremental fix)
      if (dashboardPackFormControl.dirty) {
        req.push({
          licensePackName: LagoonLicensePackType.BasicDashboard,
          // @ts-expect-error (legacy code incremental fix)
          desiredQuantity: dashboardPackFormControl.value,
        });
      }
      await this.lagoonService.setLicensePackCount(req);
      this.loading = false;
      this.close({ success: true });
    } catch (err) {
      this.alertsManager.add(Alert.fromApiError(err));
      this.loading = false;
    }
  }

  canClose(): boolean {
    return !this.loading;
  }
}
