export const template = `<form name="$ctrl.form" translate-namespace="devices.modals.execute_device_resource" novalidate="novalidate">
  <div class="x-form-contents">
    <div class="modal-header">
      <h4 translate=".title"></h4>
    </div>
    <div class="modal-body">
      <div class="alert alert-warning">
        <p translate=".description"></p>
      </div>
      <div class="form-horizontal">
        <div class="form-group"><label class="col-sm-5 control-label" translate=".resource_name"></label>
          <div class="col-sm-7">
            <p class="form-control-static"><span ng-bind="$ctrl.resource().name"></span></p>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-5 control-label" translate=".parameter"></label>
          <div class="col-sm-7"><input class="form-control" type="text" ng-model="$ctrl.parameter" /></div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-group"><button class="btn btn-default" ng-click="$ctrl.cancel()"
          translate=".cancel"></button><button class="btn btn-primary" ng-click="$ctrl.submit()" translate=".execute"
          sc-modal-default-action="sc-modal-default-action"></button></div>
    </div>
  </div>
</form>`;
