export const template = `<div translate-namespace="harvest_files.modals.upload_harvest_file">
  <div class="modal-header">
    <h4 translate=".header"></h4>
  </div>
  <div class="modal-body">
    <div class="block">
      <div id="HarvestDragDrop"></div>
      <div id="UploadProgress"></div>
    </div>
    <div class="block" ng-show="$ctrl.hasFile()">
      <div class="form-group"><label for="harvest-file-path" translate=".path"></label><input class="form-control"
          id="harvest-file-path" type="text" ng-model="$ctrl.filepath" ng-maxlength="1024" /></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group"><button class="btn btn-default" type="button" ng-click="$ctrl.dismiss();"
        translate=".close"></button><button class="btn btn-primary" type="button" ng-click="$ctrl.upload();"
        translate=".upload"></button></div>
  </div>
</div>`;
