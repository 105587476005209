import { APP_BASE_HREF } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { getLocaleService, LocaleService } from '@soracom/shared/locale-service';
import { environment } from '../environments/environment';
import {
  addressServiceProvider,
  alertsServiceProvider,
  authServiceProvider,
  billingsServiceProvider,
  buttonsServiceProvider,
  companyInformationServiceProvider,
  countryServiceProvider,
  coverageTypeServiceProvider,
  credentialsSetsServiceProvider,
  defaultPermissionsServiceProvider,
  devicesServiceProvider,
  errorLogsServiceProvider,
  groupsServiceProvider,
  groupsServiceTokenProvider,
  harvestDataServiceProvider,
  harvestFilesServiceProvider,
  individualInformationServiceProvider,
  lagoonDashboardsServiceProvider,
  lagoonServiceProvider,
  lagoonServiceTokenProvider,
  lagoonUsersServiceProvider,
  loraDevicesServiceProvider,
  loraNetworkSetsServiceProvider,
  napterAuditLogServiceProvider,
  navigationInterceptionServiceProvider,
  ngLocationServiceProvider,
  operatorContractsServiceProvider,
  ordersServiceProvider,
  paginationServiceProvider,
  postalServiceProvider,
  rolesServiceProvider,
  shippingAddressesServiceProvider,
  sigfoxDevicesServiceProvider,
  simsServiceProvider,
  soraCamCellularPacksServiceProvider,
  soraCamDevicesServiceProvider,
  soraCamEventsServiceProvider,
  soraCamImageExportsServiceProvider,
  soraCamVideoExportsServiceProvider,
  soraCamDeviceSentShareServiceProvider,
  soraCamDeviceReceivedShareServiceProvider,
  soraletLogsServiceProvider,
  soraletsServiceProvider,
  soraletVersionsServiceProvider,
  subscribersServiceProvider,
  subscribersServiceTokenProvider,
  userRolesServiceProvider,
  usersServiceProvider,
  virtualPrivateGatewaysServiceProvider,
} from './ajs-upgraded-providers';
import { AsyncOperationService } from './async-operation.service';
import { PortMappingsApiService } from './port-mappings-api.service';
import { soracomApiConfigurationProvider } from './soracom-api-client-service-provider';
import { provideAndInitCoreService } from '@soracom/shared-ng/core-service';
import { LOGOUT_HOUSEKEEPER } from '@soracom/shared-ng/ui-logout-utils';
import { AuthService } from 'apps/user-console/app/shared/components/auth.service';
import { OperatorConfigurationNamespaceService } from '@soracom/shared-ng/operator-configuration';
import { provideHttpClient } from '@angular/common/http';

const production = environment.production;
const loggerEnvironment = { production };

export const appModuleProvidersForHybridApp: any[] = [
  addressServiceProvider,
  alertsServiceProvider,
  authServiceProvider,
  billingsServiceProvider,
  companyInformationServiceProvider,
  buttonsServiceProvider,
  coverageTypeServiceProvider,
  credentialsSetsServiceProvider,
  defaultPermissionsServiceProvider,
  groupsServiceProvider,
  harvestFilesServiceProvider,
  individualInformationServiceProvider,
  countryServiceProvider,
  lagoonDashboardsServiceProvider,
  lagoonServiceProvider,
  lagoonUsersServiceProvider,
  navigationInterceptionServiceProvider,
  ngLocationServiceProvider,
  ordersServiceProvider,
  paginationServiceProvider,
  postalServiceProvider,
  rolesServiceProvider,
  shippingAddressesServiceProvider,
  simsServiceProvider,
  devicesServiceProvider,
  loraDevicesServiceProvider,
  sigfoxDevicesServiceProvider,
  harvestDataServiceProvider,
  soraCamCellularPacksServiceProvider,
  soraCamDevicesServiceProvider,
  soraCamEventsServiceProvider,
  soraCamImageExportsServiceProvider,
  soraCamVideoExportsServiceProvider,
  soraCamDeviceSentShareServiceProvider,
  soraCamDeviceReceivedShareServiceProvider,
  soraletsServiceProvider,
  soraletLogsServiceProvider,
  soraletVersionsServiceProvider,
  subscribersServiceProvider,
  virtualPrivateGatewaysServiceProvider,
  loraNetworkSetsServiceProvider,
  usersServiceProvider,
  userRolesServiceProvider,
  errorLogsServiceProvider,
  napterAuditLogServiceProvider,
  operatorContractsServiceProvider,
  // - Put upgraded AngularJS services above this line, and Angular ones below. The pure Angular app has only the ones below.
  PortMappingsApiService,
  AsyncOperationService,
  { provide: LocaleService, useValue: getLocaleService() },
  { provide: LOCALE_ID, useValue: 'ja' },
  {
    provide: 'LoggerEnvironment',
    useValue: loggerEnvironment,
  },
  { provide: Logger, useClass: LoggerService },
  { provide: APP_BASE_HREF, useValue: '/' },
  groupsServiceTokenProvider,
  lagoonServiceTokenProvider,
  subscribersServiceTokenProvider,
  OperatorConfigurationNamespaceService,

  // Typescript api client wrapper in ng
  soracomApiConfigurationProvider,

  // New core services
  ...provideAndInitCoreService({
    lang: getLocaleService().locale$,

    tokenTimeoutSeconds: 60 * 60, // 60 minutes because of interplay with legacy SoracomApi, same as legacy AuthService
    env: environment.name,
    development: environment.development,
    test: environment.test,
  }),
  {
    provide: LOGOUT_HOUSEKEEPER,
    useExisting: AuthService,
  },
  provideHttpClient(),
];
