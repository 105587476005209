'use strict';

/**
 * Manages communications with Companion
 */
var RequestClient = require('./RequestClient');
var Provider = require('./Provider');
var SearchProvider = require('./SearchProvider');
var Socket = require('./Socket');
module.exports = {
  RequestClient: RequestClient,
  Provider: Provider,
  SearchProvider: SearchProvider,
  Socket: Socket
};