import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';
import { DsNotice, DsNoticeAlert } from '@soracom/shared/sds-utils';
import { DsNoticesComponent } from '@soracom/shared-ng/ui-sds';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { localize } from '@soracom/shared/locale-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SoracomGroupsModule } from '../../soracom-groups/soracom-groups.module';
import { useResolveApiErrorReason } from '@soracom/shared-ng/util-common';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { Device } from 'apps/user-console/app/shared/core/device';

const i18n = {
  title: {
    en: 'Create Device',
    ja: 'デバイスを追加',
  },
  labels: {
    deviceName: {
      en: 'Name',
      ja: 'デバイス名',
    },
    group: {
      en: 'Group',
      ja: 'グループ',
    },
  },
  validation: {
    deviceName: {
      required: {
        en: 'Device name is required',
        ja: 'デバイス名は必須です',
      },
    },
    group: {
      required: {
        en: 'Group is required',
        ja: 'グループは必須です',
      },
    },
  },
  buttons: {
    add: {
      en: 'Create',
      ja: '追加',
    },
    cancel: {
      en: 'Cancel',
      ja: 'キャンセル',
    },
  },
};

@Component({
  selector: 'uc-feature-add-device-modal',
  standalone: true,
  imports: [CommonModule, DsNoticesComponent, FormsModule, ReactiveFormsModule, SoracomGroupsModule, SoracomI18nModule],
  templateUrl: './feature-add-device-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureAddDeviceModalComponent extends DsModalContentBase<void, { device: Device }> {
  private apiService = inject(SoracomApiService);
  private parseApiError = useResolveApiErrorReason();

  i18n = i18n;
  isLoading = false;

  deviceName = '';
  groupId = '';
  group = null;

  addDevicerErrorNotices: DsNotice[] = [];

  ngOnInit() {
    this.modalRef.modalConfig.title = localize(this.i18n.title);
  }

  groupChooserChangeHandler(event: any) {
    this.groupId = event.newGroup?.groupId || '';
    this.group = event.newGroup || null;
  }

  validate() {
    const errors = [];
    if (!this.deviceName) {
      errors.push(DsNoticeAlert(localize(this.i18n.validation.deviceName.required)));
    }

    if (!this.groupId) {
      errors.push(DsNoticeAlert(localize(this.i18n.validation.group.required)));
    }

    return errors;
  }

  async submit() {
    this.addDevicerErrorNotices = [];

    const errors = this.validate();

    if (errors.length) {
      this.addDevicerErrorNotices = errors;
      return;
    }

    this.isLoading = true;
    try {
      const response = await this.apiService.createDevice(this.groupId, {
        name: this.deviceName,
      });
      const createdDevice = new Device(response.data); // TODO: Migrate this `device` to angular or core js lib
      createdDevice.group = this.group!;

      this.close({ device: createdDevice });
    } catch (e: any) {
      this.addDevicerErrorNotices = [DsNoticeAlert(this.parseApiError(e))];
    } finally {
      this.isLoading = false;
    }
  }
}
