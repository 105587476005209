<app-log-viewer-header [mode]="mode"></app-log-viewer-header>

<div class="ds-app__notice">
  <app-alerts [alertsManager]="alertsManager"></app-alerts>
</div>

<app-log-viewer-controls
  [logViewerController]="logViewerController"
  (copyCsv)="onCopyCsv()"
  (copyJson)="onCopyJson()"
  (copyText)="onCopyText()"
  (requestDownloadLink)="onRequestDownloadLink()"
  class="ds-controls"
></app-log-viewer-controls>

<section class="ds-controls">
  <app-log-viewer-query-editor
    [logViewerController]="logViewerController"
    (search)="onSearch($event)"
  ></app-log-viewer-query-editor>

  <app-log-viewer-paginator
    [initialPerPageCount]="initialPerPageCount"
    [currentPage]="$any(currentPage)"
    (perPageCountChange)="onPerPageCountChange($event)"
    (refresh)="onRefresh()"
    (previousPage)="onPreviousPage()"
    (nextPage)="onNextPage()"
  ></app-log-viewer-paginator>
</section>

<app-log-viewer-table
  (filterByResourceId)="onFilterByResourceId($event)"
  (selectionChange)="onSelectionChange($event)"
  [logViewerController]="logViewerController"
  [currentPage]="$any(currentPage)"
></app-log-viewer-table>

<!-- The component below will display as a modal and obscure the entire content window. This is an SDS modal, which our ModalService doesn't yet support. So we can just conditionally render this component, and if rendered, it will be a modal on top of everything else (via SDS CSS). -->
<app-log-viewer-record-detail-inspector
  *ngIf="recordBeingInspected"
  [recordDetail]="recordBeingInspected"
  (close)="onCloseLogRecordDetailInspector()"
></app-log-viewer-record-detail-inspector>

<!-- Another SDS modal, rooted in log-viewer:  -->
<app-log-viewer-subscription-modal
  *ngIf="showSubscriptionModal"
  [mode]="mode"
  (close)="onCloseNapterSubscriptionModal()"
></app-log-viewer-subscription-modal>

<app-log-viewer-request-download-link
  *ngIf="showRequestDownloadLinkModal"
  (downloadLinkRequestSuccess)="downloadLinkRequestSuccess()"
  (close)="onCloseRequestDownloadLinkModal()"
></app-log-viewer-request-download-link>
