import { LegacyAny } from '@soracom/shared/core';

const OPERATOR_ID_REGEXP = /^OP\d{10}$/;

export function OperatorIdDirective() {
  const link = (scope: LegacyAny, _elm: LegacyAny, _attrs: LegacyAny, model: LegacyAny) => {
    model.$validators.operatorIdInvalid = (_modelValue: LegacyAny, viewValue: LegacyAny) => {
      return OPERATOR_ID_REGEXP.test(viewValue);
    };

    model.$validators.operatorIdDuplicated = (_modelValue: LegacyAny, viewValue: LegacyAny) => {
      if (!scope.operatorIds) {
        return true;
      }
      return scope.operatorIds.indexOf(viewValue) === -1;
    };
  };

  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      operatorIds: '=?',
    },
    link,
  };
}
