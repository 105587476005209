<section dsDialogContent>
  <div class="ds-text" [innerHtml]="'long_term_discount.confirm_long_term_discount_modal_help_text_html' | translate"></div>
  
  <br />

  <app-long-term-discount-details
    class="ds-group --indent-xsmall"
    [discount]="discountResponse.volumeDiscount"
    [taxAmount]="discountResponse.taxAmount"
    [totalAmount]="discountResponse.totalAmount"
    [currency]="discountResponse.currency"
    [listIndex]="0"
  ></app-long-term-discount-details>

  <app-alerts [alertsManager]="alertsManager"></app-alerts>

  <div class="submitting" *ngIf="submitting" style="margin-top: 0.5em">
    <img
      class="submitting-ltd-confirmation"
      src="https://assets.soracom.io/images/async-working.gif"
      alt="loading..."
    />
    &nbsp; <span>{{ 'long_term_discount.new_long_term_discount_modal_sending' | translate }}</span>
  </div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'ConfirmLongTermDiscountComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>

