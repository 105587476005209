export const template = `<app-user-notifications></app-user-notifications>
<div id="page-wrapper" translate-namespace="lora_gateway">
  <div class="alerts-wrapper"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  <div class="row block">
    <div class="col-sm-12">
      <div class="form-horizontal">
        <div class="form-group"><label class="col-sm-4 control-label" translate="lora_gateways.columns.id"></label>
          <div class="col-sm-8">
            <div class="form-control-static align-left-with-inline-editable-text"><em
                ng-bind="$ctrl.loraGateway.gatewayId"></em></div>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-4 control-label" translate="lora_gateways.columns.name"></label>
          <div class="col-sm-8 col-md-8 col-lg-6"><sc-inplace-editable-text key="name"
              display-value="$ctrl.loraGateway.tags.name" edit-ctrl="$ctrl.nameEditCtrl" show-remove-button="false"
              show-save-button="true" show-cancel-button="true" show-pencil-icon="true"></sc-inplace-editable-text>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-4 control-label"
            translate="lora_gateways.columns.gateway_type"></label>
          <div class="col-sm-8">
            <div class="form-control-static align-left-with-inline-editable-text">{{ 'lora_gateways.values.owned.' +
              $ctrl.loraGateway.owned | translate }}</div>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-4 control-label"
            translate="lora_gateways.columns.network_set_id"></label>
          <div class="col-sm-8"><sc-item-chooser ctrl="$ctrl.itemChooserController"></sc-item-chooser>
            <p><small><em class="text-muted" ng-show="!!$ctrl.loraGateway.networkSetId">({{
                  $ctrl.loraGateway.networkSetId }})</em></small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div><sc-tutorial-component></sc-tutorial-component>`;
