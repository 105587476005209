<section dsDialogContent>
  <div class="x-form-contents ds-rows">
        <p class="ds-text" [translate]="getTranslation('terminate_resource.message')"></p>
        <p class="ds-text" *ngIf="confirmationText" [innerHtml]="confirmationText"></p>

        <dl class="ds-details">
          <ng-container *ngFor="let resource of resources">
            <dt>{{getTranslation('terminate_resource.resource_label')}}</dt>
            <dd>
              <div>{{resource.id}}</div>
            </dd>
          </ng-container>
        </dl>
        
        <div>
          <div class="ds-switch">
            <input type="checkbox"
              [(ngModel)]="protectionSwitchState" (ngModelChange)="protectionSwitchStateChanged()" />
            <label [translate]="getTranslation('terminate_resource.protection_label')"></label>
          </div>
          <div class="ds-text" [translate]="getTranslation('terminate_resource.protection_description')"></div>
        </div>

        <div *ngIf="isSigfoxDevice()">
          <div class="ds-switch">
            <input data-testid="sigfox-delete-immediately" type="checkbox" [(ngModel)]="deleteImmediatelySwitchState" />
            <label [translate]="getTranslation('terminate_resource.delete_immediately_label')"></label>
          </div>
          <div class="ds-text" [translate]="getTranslation('terminate_resource.delete_immediately_description')"></div>
        </div>
  </div>
</section>
<footer dsDialogFooter>
      <button class="ds-button --primary --alert" id="modal-terminate-submit-button" (click)="submit()"
        [disabled]="submitting || !canSubmit()" [translate]="getTranslation('terminate_resource.submit')"></button>
      <button class="ds-button" id="modal-terminate-cancel-button"
        (click)="cancel()" [disabled]="submitting" [translate]="getTranslation('terminate_resource.cancel')"></button>
</footer>
