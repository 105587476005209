import { LegacyAny } from '@soracom/shared/core';

export function FormGroupValidityClassesDirective($log: LegacyAny): ng.IDirective {
  return {
    restrict: 'A',
    require: '^form',
    scope: {
      target: '=',
    },
    link: (scope, elem, attr, form) => {
      scope.$watch(
        () => {
          const target = form && attr.target && form[attr.target];
          // Mason: check to prevent rapid-fire errors during digest cycle, when target is undefined. JIRA issue SS-513.
          return target && target.$valid;
        },
        () => {
          const target = form && attr.target && form[attr.target];
          if (!target) {
            return;
          }
          if (target.$valid) {
            elem.addClass('has-success').removeClass('has-error');
          } else {
            elem.removeClass('has-success').addClass('has-error');
          }
        }
      );
    },
  };
}

FormGroupValidityClassesDirective.$inject = ['$log'];
