import { BroadcastEvent } from '@soracom/shared/core';

const EVENT_NAME = 'chat_broadcast_event';

interface EventData {
  chatLaunchedFromUrl: string;
  currentUrl: string;
  mood: string;
}

/**
 The `ChatBroadcastChannel` class lets us share context from the main User Console with the Support Bot (or other conversational app, in theory) when it is running in a separate browser window/tab/iframe.

 However, the initial version of the conversations feature doesn't use this capability.
 */
export class ChatBroadcastChannel extends BroadcastEvent<
  typeof EVENT_NAME,
  EventData
> {
  constructor() {
    super(EVENT_NAME);
  }

  override emit(data: EventData): void {
    const chatLaunchedFromUrl = data.chatLaunchedFromUrl;
    const currentUrl = data.currentUrl;
    const mood = data.mood;
    localStorage.setItem('chat-bot-shared-context-currentUrl', currentUrl);
    localStorage.setItem('chat-bot-shared-context-mood', mood);
    localStorage.setItem(
      'chat-bot-shared-context-chatLaunchedFromUrl',
      chatLaunchedFromUrl,
    );
    super.emit(data);
  }

  destroy() {
    this.disconnect();
  }
}
