export const template = `<form name="addDeviceObjectModelForm" translate-namespace="device_object_models.modals.add" novalidate="novalidate">
  <div class="x-form-contents">
    <div class="modal-header">
      <h4 translate=".title"></h4>
    </div>
    <div class="modal-body">
      <div class="form-horizontal">
        <div class="form-group"><label class="col-sm-2 control-label"
            translate="device_object_models.columns.scope"></label>
          <div class="col-sm-10">
            <div class="form-control-static"><span ng-bind="$ctrl.model.scope"></span></div>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-2 control-label"
            translate="device_object_models.columns.format"></label>
          <div class="col-sm-10"><select class="form-control" ng-options="format for format in $ctrl.formatOptions"
              ng-model="$ctrl.model.format" ng-change="$ctrl.updateSchema($ctrl.model.format)"></select></div>
        </div>
        <div class="form-group"><label class="col-sm-2 control-label"
            translate="device_object_models.columns.schema"></label>
          <div class="col-sm-10">
            <div class="device-object-model-schema-editor" ui-ace="$ctrl.aceOption" ng-model="$ctrl.schema"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-group"><button class="btn btn-default" ng-click="$ctrl.cancel()"
          translate=".cancel"></button><button class="btn btn-primary" ng-click="$ctrl.submit()"
          ng-disabled="!$ctrl.canSubmit()" translate=".save" sc-modal-default-action="sc-modal-default-action"></button>
      </div>
    </div>
  </div>
</form>`;
