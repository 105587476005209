/**
 * All possible statuses of a Sigfox device.
 */
export enum SigfoxDeviceStatus {
  active = 'active',
  inactive = 'inactive',
  suspended = 'suspended',
  terminated = 'terminated',
  shipped = 'shipped',
  ready = 'ready',
  inStock = 'inStock',
  banned = 'banned',
  standby = 'standby',
}
