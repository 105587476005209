import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { GMNull } from './GpsMultiunitConfigData';

dayjs.extend(duration);

const TIME_FORMAT_REGEX = /^[012][0-9]:[0-5][0-9]$/;

export class GMTime {
  static parse(val: string, defaultTime = '00:00'): GMTime | GMNull {
    if (!val || val === 'null') {
      return 'null';
    } else {
      if (this.isValidInput(val)) {
        return new GMTime(val);
      } else {
        return new GMTime(defaultTime);
      }
    }
  }

  static isValidInput(val: string): boolean {
    if (!TIME_FORMAT_REGEX.test(val)) {
      return false;
    }
    const [hour, minute] = val.split(':').map((v) => Number(v));
    return hour >= 0 && hour < 24 && minute >= 0 && minute < 60;
  }

  private _duration: duration.Duration;

  get hour(): number {
    return this._duration.hours();
  }

  set hour(val: number) {
    this._duration = dayjs.duration({
      minutes: this._duration.minutes(),
      hours: val,
    });
  }

  get minute(): number {
    return this._duration.minutes();
  }

  set minute(val: number) {
    this._duration = dayjs.duration({
      minutes: val,
      hours: this._duration.hours(),
    });
  }

  get duration() {
    return this._duration.clone();
  }

  private constructor(val: string) {
    if (val === null || val === undefined || !/[0-9]{2}:[0-9]{2}/.test(val)) {
      this._duration = dayjs.duration(0);
    } else {
      const [hours, minutes] = val.split(':').map((v) => Number(v));
      this._duration = dayjs.duration({ hours, minutes });
    }
  }

  isValid(): boolean {
    const minutes = this.duration.asMinutes();
    return minutes >= 0 && minutes < 1440; // 00:00 - 23:59
  }

  toString() {
    return this._duration.format('HH:mm');
  }
}
