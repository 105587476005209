<header class="ds-banner --sticky --color-ink">
  <i class="ds-icon --icon-atom"></i>
  <div>
    <h3>{{'harvestDataIntelligence.title' | translate}}</h3>
    <p>
      {{'harvestDataIntelligence.subTitle' | translate}}
    </p>
  </div>
  <button class="ds-button __close" (click)="close()">
    <span>{{'generic.button_title.close' | translate}}</span>
  </button>
</header>
  <section data-testid="harvest-intelligence-component-section">
    <div class="ds-notice --center --plain">
      <i class="ds-icon --icon-info --info --small"></i>
      <p [innerHtml]="'harvestDataIntelligence.messages.autoRefreshNotice' | translate"></p>
    </div>
    <ng-container *ngFor="let conversation of svc.conversations$ | async; let first = first;">
      <ng-container *ngIf="!first">
        <hr>
      </ng-container>
      <div class="ds-rows" appHarvestDataConversationGroup [conversation]="conversation"></div>
    </ng-container>

    <div *ngIf="loading" class="ds-group --indent-small --color-ink-lighter --pulse --icon-atom --loading" [attr.data-ds-message]="'harvestDataIntelligence.messages.analysis' | translate">
      <div class="ds-cols --no-wrap">
        <div><i class="ds-icon --icon-atom --small --color-ink"></i></div>
      </div>
    </div>

    <div *ngIf="awaitingRetry" class="ds-group --indent-small --color-ink-lighter --pulse --icon-stopwatch" [attr.data-ds-message]="'harvestDataIntelligence.messages.rateLimitExceeded' | translate">
      <div class="ds-cols --no-wrap">
        <div><i class="ds-icon --icon-atom --small --color-ink"></i></div>
      </div>
    </div>

    <div *ngIf="showResetConversationDialog && !awaitingRetry && !loading" class="ds-notice --center --plain" data-testid="harvest-intel-reset-data-question">
      <i class="ds-icon --icon-refresh-cw-alert --info --small"></i>
      <p class="ds-text --info">
        {{'harvestDataIntelligence.messages.resetDataQuestion' | translate}}
      </p>
      <div>
        <button class="ds-button --xsmall" (click)="dataSettingsUpdateNo()">
          <span>{{'harvestDataIntelligence.messages.resetRefuse' | translate}}</span>
        </button>
        <button class="ds-button --xsmall --primary" (click)="dataSettingsUpdateYes()">
          <span>{{'harvestDataIntelligence.messages.resetConfirm' | translate}}</span>
        </button>
      </div>
    </div>

    <div #conversationEndMarker></div>
  </section>

  <footer class="--sticky">
    <details class="ds-menubutton --top-left" #askAiDetails [ngClass]="{'--disabled': loading || awaitingRetry || showResetConversationDialog}">
      <summary>
        <span class="ds-button --icon-comment-question --xhide-label --primary --large --circle" data-testid="harvest-intelligence-ask-ai">
          <span>{{'harvestDataIntelligence.questionsButtonTitle' | translate}}</span>
        </span>
      </summary>
      <div class="__content --top --wide">
        <ul>
          <li *ngFor="let o of questions">
            <button (click)="questionClicked(o)">{{ o }}</button>
          </li>
        </ul>
      </div>
    </details>
  </footer>
  