import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HarvestData } from '../../../../app/shared/core/harvest_data';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { HarvestDataDownloadModalComponent } from '../harvest-data-download-modal/harvest-data-download-modal.component';
import { HarvestDataStore } from '../harvest-data.store';

@Component({
  selector: 'app-harvest-data-download-button',
  templateUrl: './harvest-data-download-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: inline;
      }
    `,
  ],
})
export class HarvestDataDownloadButtonComponent {
  @Input() data: HarvestData[] = [];

  constructor(private svc: HarvestDataStore, private modalService: UiDsModalService) {}

  downloadBtnContent = UiButton.configure((b) => {
    b.titleId = 'HarvestData.download_actions_menu.title';
    b.isDisabled_ƒ = () => !this.isShownDownloadBtn();
    b.onClick = (e) => {
      this.modalService.open(HarvestDataDownloadModalComponent, {
        title: 'HarvestData.download.heading',
        data: { resources: this.svc.resources, timeRange: this.svc.timeRange },
      });
    };
  });

  isShownDownloadBtn(): boolean {
    return this.svc.resources.length >= 1;
  }
}
