export const template = `<app-user-notifications></app-user-notifications>
<main class="ds-app__main-container x-logs-page-root">
  <header class="ds-banner --simple --indent-small">
    <div>
      <h2>{{ $ctrl.getPageTitleKey() | translate }}</h2>
      <p class="ds-notice --info" ng-bind-html="'logs.description' | translate"></p>
    </div>
  </header>
  <div class="alerts"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  <form class="query-logs-form ds-controls --indent-small" ng-submit="$ctrl.search()">
    <div class="ds-button-bar">
      <div class="ds-select"><select id="service" ng-model="$ctrl.service"
          ng-options="s.code as s.label for s in $ctrl.services" ng-change="$ctrl.updateResourceTypeOptions()"></select>
      </div>
      <div class="ds-select"><select id="resource-type" ng-model="$ctrl.resourceType"
          ng-options="rt.code as rt.label for rt in $ctrl.resourceTypes"
          ng-change="$ctrl.updateResourceIdPlaceholder()"></select></div>
      <div class="ds-input"><input id="resource-id" type="text" ng-disabled="$ctrl.resourceType === 'all'"
          ng-attr-placeholder="{{ $ctrl.resourceIdPlaceholder }}" ng-model="$ctrl.resourceId" /></div>
    </div>
    <div class="ds-button-bar"><label class="control-label sr-only">{{ &quot;logs.query.time&quot; | translate
        }}</label><sc-date-time-picker date-time-msec="$ctrl.from" required="false" field-name="&quot;from&quot;"
        min-date="$ctrl.min"></sc-date-time-picker>
      <div class="hidden-sm col-separator">
        <p class="form-control-static text-center">-</p>
      </div><sc-date-time-picker date-time-msec="$ctrl.to" required="false" field-name="&quot;to&quot;"
        min-date="$ctrl.min"></sc-date-time-picker><button class="ds-button --primary" type="submit"><span>{{
          &quot;logs.query.search&quot; | translate }}</span></button>
    </div>
  </form>
  <div class="ds-controls">
    <div><button class="ds-button --icon-settings" id="table-settings-button" data-testid="ng1-table-settings-button"
        ng-click="$ctrl.openTableSettingsModal()"></button></div><sc-pagination paginator="$ctrl.paginator"
      ctrl="$ctrl"></sc-pagination>
  </div>
  <div class="table-responsive table-logs">
    <table class="sc-table table ds-datatable--striped x-logs-table" id="logs-table">
      <thead>
        <tr>
          <th id="logs-th-time" ng-if="$ctrl.shouldShowColumn(&quot;time&quot;)">{{ &quot;logs.columns.time&quot; |
            translate }}&nbsp;</th>
          <th id="logs-th-service" ng-if="$ctrl.shouldShowColumn(&quot;service&quot;)">{{
            &quot;logs.columns.service&quot; | translate }}&nbsp;</th>
          <th id="logs-th-resource-type" ng-if="$ctrl.shouldShowColumn(&quot;resource_type&quot;)">{{
            &quot;logs.columns.resource_type&quot; | translate }}&nbsp;</th>
          <th id="logs-th-resource-id" ng-if="$ctrl.shouldShowColumn(&quot;resource_id&quot;)">{{
            &quot;logs.columns.resource_id&quot; | translate }}&nbsp;</th>
          <th id="logs-th-message" ng-if="$ctrl.shouldShowColumn(&quot;message&quot;)">{{
            &quot;logs.columns.message&quot; | translate }}&nbsp;</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="x-logs-list" ng-if="!$ctrl.isLoading() &amp;&amp; $ctrl.hasData()">
        <tr ng-repeat="row in $ctrl.tableData.rows">
          <td class="logs-time" ng-if="$ctrl.shouldShowColumn(&quot;time&quot;)" sc-date-time="row.obj.time"
            alt-text=""></td>
          <td ng-class="['logs-service', 'service' + row.obj.service]"
            ng-if="$ctrl.shouldShowColumn(&quot;service&quot;)"><span ng-if="row.obj.service">{{ 'logs.values.service.'
              + row.obj.service | translate }}</span></td>
          <td ng-class="['logs-resource-type', 'resource-type' + row.obj.resourceType]"
            ng-if="$ctrl.shouldShowColumn(&quot;resource_type&quot;)"><span ng-if="row.obj.resourceType">{{
              'logs.values.resource_type.' + row.obj.resourceType | translate }}</span></td>
          <td class="logs-resource-id" ng-if="$ctrl.shouldShowColumn(&quot;resource_id&quot;)">{{ row.obj.resourceId
            }}<a class="filter-icon" href="" ng-click="$ctrl.filterResource(row.obj)"
              ng-if="!$ctrl.doesFilterResource(row.obj)"><i class="glyphicon glyphicon-filter"></i></a></td>
          <td class="logs-message" ng-if="$ctrl.shouldShowColumn(&quot;message&quot;)">{{ row.obj.message }}</td>
          <td><button class="ds-button --plain" data-testid="open-raw-data" ng-click="$ctrl.openRawData(row.obj)"><i
                class="glyphicon glyphicon-option-horizontal"></i></button></td>
        </tr>
      </tbody>
      <tbody class="x-no-logs-exist" ng-if="!$ctrl.isLoading() &amp;&amp; !$ctrl.hasData()">
        <tr class="no-data">
          <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.no_data' | translate }}
          </td>
        </tr>
      </tbody>
      <tbody class="x-is-loading" ng-if="$ctrl.isLoading()">
        <tr class="loading">
          <td class="fullwidth-message" colspan="{{ $ctrl.getColumnsCount() }}">{{ 'common.table.loading' | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</main>`;
