<section dsDialogContent>
  <form name="form" novalidate="novalidate">
    <div class="x-form-contents">
              <div class="alert alert-warning">
                <p [innerHtml]="'operators.modals.update_napter_contracts.confirmation_text_subscribe' | translate" *ngIf="willSubscribe()"></p>
                <p [innerHtml]="'operators.modals.update_napter_contracts.confirmation_text_unsubscribe' | translate" *ngIf="willUnsubscribe()"></p>
              </div>
              <dl class="ds-details">
                <dt [innerHtml]="'operators.modals.update_napter_contracts.current_plan' | translate"></dt>
                <dd>
                  <p class="ds-text" [innerHtml]="'operators.modals.update_napter_contracts.subscribed' | translate" *ngIf="subscribed"></p>
                  <p class="ds-text" [innerHtml]="'operators.modals.update_napter_contracts.unsubscribed' | translate" *ngIf="!subscribed"></p>
                </dd>
              </dl>
    </div>
  </form>
</section>


<footer dsDialogFooter>
  <button class="ds-button --primary"
    id="confirm-submit-button" (click)="submit()" [disabled]="submitting || !canSubmit()"
    translate="operators.modals.update_napter_contracts.submit"></button>
  <button class="ds-button" id="confirm-cancel-button" (click)="cancel()"
    [disabled]="submitting" translate="operators.modals.update_napter_contracts.cancel" uiFocusMe></button>
</footer>
