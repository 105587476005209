import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HarvestDataVizType } from '../harvest-data-viz-type';

type HarvestDataVizConfig = {
  value: HarvestDataVizType;
  displayValue: string;
  iconClass: string;
};

const HarvestDataVizConfigs: Array<HarvestDataVizConfig> = [
  {
    value: 'table',
    iconClass: 'ds-icon--icon-inspect',
    displayValue: 'Table',
  },
  {
    value: 'line_chart',
    iconClass: 'si line-chart',
    displayValue: 'Line Chart',
  },
  {
    value: 'grouped_column_chart',
    iconClass: 'si grouped-column-chart',
    displayValue: 'Grouped Column Chart',
  },
  {
    value: 'stacked_column_chart',
    iconClass: 'si stacked-column-chart',
    displayValue: 'Column Chart',
  },
  {
    value: 'map',
    iconClass: 'glyphicon glyphicon-map-marker',
    displayValue: 'Map',
  },
];

@Component({
  selector: 'app-harvest-data-viz-type-selector',
  templateUrl: './harvest-data-viz-type-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'btn-group',
  },
})
export class HarvestDataVizTypeSelectorComponent {
  @Input() value: HarvestDataVizType = 'line_chart';

  @Output() readonly valueChange = new EventEmitter<HarvestDataVizType>();

  readonly candidates = HarvestDataVizConfigs;

  onChange(value: HarvestDataVizType) {
    this.valueChange.emit(value);
  }
}
