/**
 * Utility class to handle path information correctly
 */
import { HarvestFile } from './harvest_file';

export class HarvestDirectory {
  /**
   * Used for list API
   * (https://api.soracom.io/v1/files/private/foo/%E6%97%A5%E6%9C%AC%E8%AA%9E/)
   */
  private readonly _decodedPath: string;
  /**
   * Used for user console navigation
   * (https://console.soracom.io/harvest_files?path=%2Ffoo%2F%25E6%2597%25A5%25E6%259C%25AC%25E8%25AA%259E%2F)
   */
  private readonly _encodedPath: string;
  /**
   * Used for user console display (in search input field)
   * (/foo/日本語/)
   */
  private readonly _humanReadablePath: string;

  get decodedPath(): string {
    return this._decodedPath;
  }

  get encodedPath(): string {
    return this._encodedPath;
  }

  get humanReadablePath(): string {
    return this._humanReadablePath;
  }

  /**
   * @param decodedPath
   */
  constructor(decodedPath: string) {
    const path = decodedPath ? `${decodedPath}/` : '/';
    this._decodedPath = HarvestFile.formatPath(path);
    this._encodedPath = HarvestFile.formatPath(path, { encode: true });
    this._humanReadablePath = HarvestFile.formatPath(path, { decode: true });
  }

  toString(): string {
    return JSON.stringify(
      {
        decodedPath: this.decodedPath,
        encodedPath: this.encodedPath,
        humanReadablePath: this.humanReadablePath,
      },
      null,
      2
    );
  }
}
