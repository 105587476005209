import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import {
  convertToCsvFilteredByDataKeys,
  convertToHarvestDataForDownload,
} from '../harvest-data-download-modal/harvest-data-download.action';
import { HarvestDataStore } from '../harvest-data.store';
import { InsightsApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { AskInsightMessage, AskInsightRequest } from '@soracom/shared/soracom-api-typescript-client';
import { HarvestDataVizType } from '../harvest-data-viz/harvest-data-viz-type';
import { DataSeriesVisibility } from '../harvest-data-viz/harvest-data-viz-type';

const staticPromptEn: AskInsightMessage = {
  role: 'system',
  content: `Format your response using bullet points and bold to organize the information`,
} as const;
const staticPromptJa: AskInsightMessage = {
  role: 'system',
  content: `箇条書きを用いて返答を読みやすく整理してください。`,
} as const;

@Injectable()
export class HarvestDataIntelligenceService {
  private store = inject(HarvestDataStore);
  private t = inject(TranslateService);
  private insightsApiService = inject(InsightsApiService);
  private _conversations$ = new BehaviorSubject<HarvestDataIntelligenceUiConversation[]>([]);

  conversations$ = this._conversations$.asObservable();

  getInputData(): string {
    const vizTypeCategory = whichIntelligenceVizTypeCategory(this.store.vizType);
    let visibilities: DataSeriesVisibility = {};
    const currentDisplayData = this.store.getDisplayItems();
    if (vizTypeCategory === 'map-type') {
      visibilities = this.getMapVizTypeVisibilities();
    } else {
      //chart-type
      visibilities = this.store.getVisibilities(false);
    }
    const resourceCount = this.store.resources.length;
    // Convert json array to csv to reduce amonunt of data
    return convertToCsvFilteredByDataKeys(currentDisplayData.map(convertToHarvestDataForDownload), visibilities, {
      defaultReservedHeaderKeys: {
        resourceType: {
          label: '__resourceType',
          include: resourceCount > 1,
        },
        resourceId: {
          label: '__resourceId',
          include: resourceCount > 1,
        },
        formattedTime: {
          label: '__time',
          include: true,
        },
        iso8601Time: {
          include: false,
          label: '',
        },
      },
      sortByTime: true,
    });
  }

  getConversations() {
    return this._conversations$.getValue();
  }

  public appendUiErrorMessage(error: any) {
    this.appendMessageToLatestConversation({
      role: 'ui-error',
      content: error.data.message ?? 'Unexpected Error',
    });
  }

  public appendUiConfirmationMessage(message: string) {
    this.appendMessageToLatestConversation({
      role: 'ui-confirmation',
      content: message,
    });
  }

  private getDataPromptMessages(inputData: string): AskInsightMessage[] {
    return this.t.currentLang === 'ja'
      ? [
          {
            role: 'system',
            content: `下記の csv データに基づいて回答してください:\n${inputData}`,
          },
        ]
      : [
          {
            role: 'system',
            content: `Answer questions based on the following csv data:\n${inputData}`,
          },
        ];
  }

  appendUserQuestion(content: string) {
    const userMessage: AskInsightMessage = {
      role: 'user',
      content,
    };
    this.appendMessageToLatestConversation(userMessage);
  }

  /**
   * Takes the latest conversation's messages and sends them to the ask insigh api.  For a new question, callers of the service should call appendUserQuestion first.
   */
  async askCurrentConversation() {
    const messages = [...this.getCurrentConversation().messages];
    const request = {
      messages: [
        this.t.currentLang === 'ja' ? staticPromptJa : staticPromptEn,
        ...this.filterOutUiDefinedMessages(messages), //filter out user defined role, "error"
      ],
    } as AskInsightRequest;

    const response = await this.insightsApiService.harvestDataAskInsight({ askInsightRequest: request });
    const responseMessage = response.data.choices?.[0]?.message;
    if (responseMessage) {
      this.appendMessageToLatestConversation(responseMessage);
    }
  }

  private getCurrentConversation() {
    const conversations = [...this.getConversations()];
    return conversations[conversations.length - 1];
  }

  private appendMessageToLatestConversation(message: HarvestDataIntelligenceUiMessage) {
    const conversations = [...this.getConversations()];
    const latestConversation = conversations[conversations.length - 1];
    latestConversation.messages.push(message);
    this._conversations$.next(conversations);
  }

  /**There are chat-gpt defined roles, and roles used just for UI purposes. When sending to the API, want to filter out UI-defined specific items like errors and confirmations */
  private filterOutUiDefinedMessages(messages: HarvestDataIntelligenceUiMessage[]) {
    return messages.filter((m) => m.role !== 'ui-error' && m.role !== 'ui-confirmation' && m.role !== 'ui-input');
  }

  /**Start a new conversation
   * @param starterMessages - optional messages to start the conversation with, before the input block.
   */
  initNewConversation(starterMessages: HarvestDataIntelligenceUiMessage[] = []) {
    const newInputData = this.getInputData();
    this._conversations$.next([
      ...this.getConversations(),
      {
        messages: [
          ...this.getDataPromptMessages(newInputData),
          ...starterMessages,
          this.generateInputMessage(newInputData),
        ],
      },
    ]);
  }

  generateInputMessage(inputData: string): HarvestDataIntelligenceUiMessage {
    return {
      role: 'ui-input',
      content: inputData,
    };
  }

  /**Get a visibility object of all visibility props set to false except for the keys used for the map */
  private getMapVizTypeVisibilities() {
    const mapTypeVisibilities: Record<string, boolean> = {};
    this.store.mapDisplayData.reduce((visibilitesMap, mapData) => {
      visibilitesMap[mapData.latitudeKey] = true;
      visibilitesMap[mapData.longitudeKey] = true;
      return visibilitesMap;
    }, mapTypeVisibilities);
    return mapTypeVisibilities;
  }
}

export interface HarvestDataIntelligenceUiMessage {
  role: 'user' | 'system' | 'assistant' | 'ui-error' | 'ui-confirmation' | 'ui-input';
  content: string;
}

export interface HarvestDataIntelligenceUiConversation {
  messages: HarvestDataIntelligenceUiMessage[];
}

export type HarvestDataIntelligenceVizTypeCategory = 'chart-type' | 'map-type';

export function whichIntelligenceVizTypeCategory(vizType: HarvestDataVizType): HarvestDataIntelligenceVizTypeCategory {
  switch (vizType) {
    case 'line_chart':
    case 'grouped_column_chart':
    case 'stacked_column_chart':
      return 'chart-type';
    case 'map':
      return 'map-type';
    default:
      // @ts-expect-error (legacy code incremental fix)
      return undefined;
  }
}
