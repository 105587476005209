import { LegacyAny } from '@soracom/shared/core';

import { AbstractControl, Validators } from '@angular/forms';

export function multipleEmailValidator(control: AbstractControl): { [key: string]: any } | null {
  if (control.value) {
    const emails = control.value.split(/[;,\s]/);
    const errors = emails
      .map((email: LegacyAny) => Validators.email(<AbstractControl>{ value: email }))
      .reduce((accumulator: LegacyAny, curr: LegacyAny) => Object.assign(accumulator, curr), {});
    return errors && Object.keys(errors).length > 0 ? errors : null;
  } else {
    return null;
  }
}
