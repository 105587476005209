import { LegacyAny } from '@soracom/shared/core';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { FocusMeDirective } from '@soracom/shared-ng/ui-common';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { SoracomUiModule } from 'apps/user-console/src/app/soracom-ui/soracom-ui.module';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, FocusMeDirective, SoracomUiModule],
  selector: 'app-group-delete-modal',
  templateUrl: './group_delete.modal.component.html',
})
export class GroupDeleteModalComponent
  extends LegacyUibModalAdapterModalBase<{ params: () => { group: Group } }, { success: boolean }>
  implements OnInit
{
  alertsManager = new AlertsManager();
  group?: Group;
  submitting = false;

  get name(): string {
    return this.group ? this.group.name : '';
  }

  get groupId(): string | null {
    return this.group ? this.group.groupId : null;
  }

  ngOnInit() {
    this.group = this.resolve?.params().group;
  }

  cancel() {
    this.close();
  }

  submit() {
    this.submitting = true;
    groupsService
      .remove(this.groupId as string)
      .then(() => {
        this.close({ success: true });
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
