import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SoracomUserConsole } from './shared/SoracomUserConsole';

@Component({
  selector: 'app-main',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(public translateService: TranslateService) {
    SoracomUserConsole.modernTranslationServiceDidLoad(translateService);
  }
}
