import { LegacyTextContent, UiText } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiMenuItem } from './UiMenuItem';

/**
 * A shorthand convenience form to define a `UiMenuSection`.
 */
export type UiMenuSectionObjectRepresentation = { [key: string]: UiText[] };

/**
 * NOTE: For normal usage, UiMenu itself is intended to provide the convenience API that means regular application code probably does not have to directly use `UiMenuSection`.
 *
 * `UiMenuSection` defines part of a menu. It is made up of one or more menu items (`UiMenuItem`) and an optional header. Menus probably usually will have only one section, with no header. But they may have multiple sections.
 */
export class UiMenuSection {
  // 🌸
  constructor(
    /**
     * The items contained by the menu section.
     */
    public items: UiMenuItem[] = [],

    /**
     * Optional section header.
     */
    public header?: UiText
  ) {
    // Hi, mom!
  }

  add(item: UiMenuItem | UiText) {
    const menuItem = item instanceof UiMenuItem ? item : new UiMenuItem(item);

    this.items.push(menuItem);
  }

  static isUiMenuSectionArray(obj: any): obj is UiMenuSection[] {
    return Array.isArray(obj) && obj.every((e) => e instanceof UiMenuSection);
  }

  static fromUiTextArray(a: UiText[]) {
    const menuItems = UiMenuItem.fromUiTextArray(a);
    return new this(menuItems);
  }

  /**
   * Returns an array of `UiMenuSection` instances, or `undefined` if `obj` is not a valid shorthand representation.
   *
   * @param obj a shorthand object representation like `{'section1': ['item1', 'item2']}
   */
  static fromObject(obj: UiMenuSectionObjectRepresentation) {
    const result: UiMenuSection[] = [];

    if (obj === null) {
      return result;
    }

    const keys = Object.keys(obj);

    for (const key of keys) {
      const itemValues = obj[key];

      if (!LegacyTextContent.isUiTextArray(itemValues)) {
        return undefined;
      }

      const menuItems: UiMenuItem[] = [];

      for (const itemValue of itemValues) {
        const menuItem = new UiMenuItem(itemValue);
        menuItems.push(menuItem);
      }
      const section = new UiMenuSection(menuItems, key);
      result.push(section);
    }

    return result;
  }
}
