export class TrafficVolumeRankingSearchingPeriodConstraint {
  private static maxAllowedRangeMillis: number = 60 * 60 * 24 * 40 * 1000; // 40 days

  static isValidMagnitudeCorrelation(from: number, to: number): boolean {
    return from <= to;
  }

  static isValidSearchingRange(from: number, to: number): boolean {
    return to - from <= TrafficVolumeRankingSearchingPeriodConstraint.maxAllowedRangeMillis;
  }

  static isValidAllowedRetrospectiveRange(from: number): boolean {
    return Date.now() - from <= TrafficVolumeRankingSearchingPeriodConstraint.maxAllowedRangeMillis;
  }
}
