export const template = `<form name="addDeviceKeyForm" translate-namespace="device_keys.modals.add_device_key" novalidate="novalidate">
  <div class="x-form-contents">
    <div class="modal-header">
      <h4 translate=".title"></h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <p translate=".message"></p>
      </div>
      <div class="form-group">
        <p class="text-danger" translate=".message_important"></p>
      </div>
      <div class="form-group"><label class="control-label" for="device-id"
          translate="devices.columns.device_id"></label>
        <div class="form-control-static" id="new-device-key-device-id" ng-bind="$ctrl.deviceId"></div>
      </div>
      <div class="form-group"><label class="control-label" for="device-id"
          translate="device_keys.columns.key_id"></label>
        <div class="form-control-static" id="new-device-key-id" ng-bind="$ctrl.deviceKey.keyId"></div>
      </div>
      <div class="form-group"><label class="control-label" for="secret-key"
          translate="device_keys.columns.secret_key"></label>
        <div class="form-control-static" id="new-device-key-secret-key" ng-bind="$ctrl.deviceKey.secretKey"></div>
      </div>
      <div class="form-group"><sc-alerts id="authkey-add-alert" service="$ctrl.alertsService"></sc-alerts></div>
    </div>
    <div class="modal-footer"><button class="btn btn-default" id="add-device-key-close" ng-click="$ctrl.close()"
        type="button" translate=".close"></button><button class="btn btn-primary" id="add-device-key-copy"
        ngclipboard="ngclipboard" data-clipboard-text="{{$ctrl.copy()}}" ngclipboard-success="$ctrl.onCopied()"
        ngclipboard-error="$ctrl.onCopyError()" ng-disabled="!$ctrl.copyable()"
        sc-modal-default-action="$ctrl.doCopy()"><span translate=".copy"></span></button></div>
  </div>
</form>`;
