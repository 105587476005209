export const template = `<div class="ds-notice--info">{{ 'watch.description' | translate }}</div>
<div class="ds-notice--info" ng-if="!!$ctrl.groupId"><a href="/event_handler?groupId={{$ctrl.groupId}}">{{
    'watch.advanceSetup.linkText' | translate }}</a>&nbsp; {{ 'watch.advanceSetup.description' | translate }}</div>
<form name="$ctrl.prefixedEventHandlersForm" ng-disabled="$ctrl.nowLoading || $ctrl.submitting"
  ng-submit="$ctrl.updateSettings()">
  <div class="x-prefixed-event-handlers-loading" ng-if="$ctrl.nowLoading">
    <div class="sc-loading x-loading delayed-appearance-1000ms"><img
        src="https://assets.soracom.io/images/async-working.gif" /><span
        translate="core.label.loading_dot_dot_dot"></span></div>
  </div>
  <div class="x-prefixed-event-handlers-ready ds-rows--indent" ng-if="!$ctrl.nowLoading">
    <h4>{{ 'watch.send_mail_to_operator_action.title' | translate }}</h4>
    <div class="ds-cols--spaced"><label class="ds-checkbox"><input id="enable-sma-daily-limit"
          name="enableSmaDailyLimit" type="checkbox"
          ng-model="$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.active" />
        <div>{{ 'watch.send_mail_to_operator_action.daily_limit' | translate }}</div>
      </label>
      <div class="ds-input"><input id="sma-daily-limit-mega-byte" name="smaDailyLimitMegaByte" type="number" min="1"
          ng-pattern="$ctrl.positiveInteger"
          ng-disabled="!$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.active"
          ng-required="$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.active"
          ng-model="$ctrl.settings.SendMailToOperatorAction.DailyTrafficRule.value" />
        <div class="ds-text --addon">MiB</div>
      </div>
    </div>
    <div class="ds-cols--spaced"><label class="ds-checkbox"><input id="enable-sma-monthly-limit"
          name="enableSmaMonthlyLimit" type="checkbox"
          ng-model="$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.active" />
        <div>{{ 'watch.send_mail_to_operator_action.monthly_limit' | translate }}</div>
      </label>
      <div class="ds-input"><input id="sma-monthly-limit-mega-byte" name="smaMonthlyLimitMegaByte" type="number" min="1"
          ng-pattern="$ctrl.positiveInteger"
          ng-disabled="!$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.active"
          ng-required="$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.active"
          ng-model="$ctrl.settings.SendMailToOperatorAction.MonthlyTrafficRule.value" />
        <div class="ds-text--addon">MiB</div>
      </div>
    </div>
    <div class="ds-cols--spaced"><label class="ds-checkbox"><input id="enable-sma-cumulative-limit"
          name="enableSmaCumulativeLimit" type="checkbox"
          ng-model="$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.active" />
        <div>{{ 'watch.send_mail_to_operator_action.cumulative_limit' | translate }}</div>
      </label>
      <div class="ds-input"><input id="sma-cumulative-limit-mega-byte" name="smaCumulativeLimitMegaByte" type="number"
          min="1" ng-pattern="$ctrl.positiveInteger"
          ng-disabled="!$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.active"
          ng-required="$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.active"
          ng-model="$ctrl.settings.SendMailToOperatorAction.CumulativeTrafficRule.value" />
        <div class="ds-text--addon">MiB</div>
      </div>
    </div>
    <div class="ds-cols--spaced" ng-if="$ctrl.target == &quot;operator&quot;"><label class="ds-checkbox"><input
          id="enable-sma-monthly-total-limit" name="enableSmaMonthlyTotalLimit" type="checkbox"
          ng-model="$ctrl.settings.SendMailToOperatorAction.MonthlyTotalTrafficRule.active" />
        <div>{{ 'watch.send_mail_to_operator_action.monthly_total_limit' | translate }}</div>
      </label>
      <div class="ds-input"><input id="sma-monthly-total-limit-mega-byte" name="smaMonthlyTotalLimitMegaByte"
          type="number" min="1" ng-pattern="$ctrl.positiveInteger"
          ng-disabled="!$ctrl.settings.SendMailToOperatorAction.MonthlyTotalTrafficRule.active"
          ng-required="$ctrl.settings.SendMailToOperatorAction.MonthlyTotalTrafficRule.active"
          ng-model="$ctrl.settings.SendMailToOperatorAction.MonthlyTotalTrafficRule.value" />
        <div class="ds-text--addon">MiB</div>
      </div>
    </div>
    <h4>{{ 'watch.change_speed_class_action.title' | translate }}</h4>
    <div class="ds-cols--spaced"><label class="ds-checkbox"><input id="enable-csca-daily-limit"
          name="enableCscaDailyLimit" type="checkbox"
          ng-model="$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.active" />
        <div>{{ 'watch.change_speed_class_action.daily_limit' | translate }}</div>
      </label>
      <div class="ds-input"><input id="csca-daily-limit-mega-byte" name="cscaDailyLimitMegaByte" type="number" min="1"
          ng-pattern="$ctrl.positiveInteger"
          ng-disabled="!$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.active"
          ng-required="$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.active"
          ng-model="$ctrl.settings.ChangeSpeedClassAction.DailyTrafficRule.value" />
        <div class="ds-text--addon">MiB</div>
      </div>
    </div>
    <div class="ds-cols--spaced"><label class="ds-checkbox"><input id="enable-csca-monthly-limit"
          name="enableCscaMonthlyLimit" type="checkbox"
          ng-model="$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.active" />
        <div>{{ 'watch.change_speed_class_action.monthly_limit' | translate }}</div>
      </label>
      <div class="ds-input"><input id="csca-monthly-limit-mega-byte" name="cscaMonthlyLimitMegaByte" type="number"
          min="1" ng-pattern="$ctrl.positiveInteger"
          ng-disabled="!$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.active"
          ng-required="$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.active"
          ng-model="$ctrl.settings.ChangeSpeedClassAction.MonthlyTrafficRule.value" />
        <div class="ds-text--addon">MiB</div>
      </div>
    </div>
    <div class="ds-cols--spaced"><label class="ds-checkbox"><input id="enable-csca-cumulative-limit"
          name="enableCscaCumulativeLimit" type="checkbox"
          ng-model="$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.active" />
        <div>{{ 'watch.change_speed_class_action.cumulative_limit' | translate }}</div>
      </label>
      <div class="ds-input"><input id="csca-cumulative-limit-mega-byte" name="cscaCumulativeLimitMegaByte" type="number"
          min="1" ng-pattern="$ctrl.positiveInteger"
          ng-disabled="!$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.active"
          ng-required="$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.active"
          ng-model="$ctrl.settings.ChangeSpeedClassAction.CumulativeTrafficRule.value" />
        <div class="ds-text--addon">MiB</div>
      </div>
    </div>
    <div class="ds-cols--spaced" ng-if="$ctrl.target == &quot;operator&quot;"><label class="ds-checkbox"><input
          id="enable-csca-monthly-total-limit" name="enableCscaMonthlyTotalLimit" type="checkbox"
          ng-model="$ctrl.settings.ChangeSpeedClassAction.MonthlyTotalTrafficRule.active" />
        <div>{{ 'watch.change_speed_class_action.monthly_total_limit' | translate }}</div>
      </label>
      <div class="ds-input"><input id="csca-monthly-total-limit-mega-byte" name="cscaMonthlyTotalLimitMegaByte"
          type="number" min="1" ng-pattern="$ctrl.positiveInteger"
          ng-disabled="!$ctrl.settings.ChangeSpeedClassAction.MonthlyTotalTrafficRule.active"
          ng-required="$ctrl.settings.ChangeSpeedClassAction.MonthlyTotalTrafficRule.active"
          ng-model="$ctrl.settings.ChangeSpeedClassAction.MonthlyTotalTrafficRule.value" />
        <div class="ds-text--addon">MiB</div>
      </div>
    </div><sc-alerts service="$ctrl.alertsService"></sc-alerts><button class="ds-button--primary"
      id="save-watch-settings" type="submit" ng-disabled="!$ctrl.canSubmit()"><span
        translate="watch.update_button"></span></button>
  </div>
</form>`;
