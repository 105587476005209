import { template } from './redirect.component.html';

import { BaseController } from '../components/base_controller';
import { InjectList } from '../core/injectable';

export class RedirectComponent implements ng.IComponentOptions {
  bindings = {
    path: '@',
  };

  controller = RedirectComponentController;
  template: any = template;
}

export class RedirectComponentController extends BaseController {
  static $inject: InjectList = ['$location', '$log'];

  // @ts-expect-error (legacy code incremental fix)
  path: string;

  constructor(private $location: ng.ILocationService, $log: ng.ILogService) {
    super($log);
  }

  $onInit() {
    this.$location.path(this.path);
  }
}
