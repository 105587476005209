import { LegacyAny } from '@soracom/shared/core';

export function CapitalizeDirective(): ng.IDirective {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (_scope, element, _attributes, ctrl: any) => {
      ctrl.$parsers.push((input: LegacyAny) => {
        return input ? input.toUpperCase() : '';
      });
      element.css('text-transform', 'uppercase');
    },
  };
}
