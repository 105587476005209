import { LegacyAny } from '@soracom/shared/core';

import { samUserErrorCodes } from 'apps/user-console/src/app/shared/samUserErrorCodes';
import { LoadObserver, LoadState } from '../../../src/app/shared/load-observer';
import { SearchQuery, PaginatableService } from '@user-console/legacy-soracom-api-client';
import { AlertsService } from './alerts.service';
import { BaseTableController } from './base_table_controller';
import { Paginator, ScPaginator, ScPaginatorOptions, ScRelation } from './paginator';

export class BasePaginatableTableController<T> extends BaseTableController<T> implements LoadObserver {
  paginator: Paginator<T>;
  // @ts-expect-error (legacy code incremental fix)
  _searchQuery: SearchQuery;
  listApiError: any;

  /**
   * To support search queries via the Searchlight APIs, subclasses may override this to return the query structure. (Default implementation returns an empty one.) Note that subclasses must also clear the paginator's searchQuery when it is replaced (FIXME: verify that is still true during code review, it might end up being that we can reset tha pagination only?)
   */
  get searchQuery(): SearchQuery {
    return this._searchQuery;
  }

  set searchQuery(searchQuery) {
    this._searchQuery = searchQuery;
  }

  /**
   * The loadState provides an easy mechanism to expose our load state to child components. This is not always required, but it is probably a common thing to need to do when a child component is mostly independent of its parent component, but does need to know the parent's loading state.
   */
  loadState = LoadState.initial;

  protected get paginatorOptions(): ScPaginatorOptions {
    return {};
  }

  constructor(
    $log: ng.ILogService,
    protected $translate: any,
    protected $q: ng.IQService,
    protected alertsServiceGenerator: AlertsService,
    service: PaginatableService<T>,
  ) {
    super($log);

    this.paginator = new ScPaginator<T>($q, service, this.paginatorOptions);
  }

  $onInit() {
    this.alertsService = this.alertsServiceGenerator.generate();
  }

  clearData() {
    this.paginator.clearPagination();
    this.tableData.refreshData([]);
  }

  /**
   * For a special case of error, we modify `error` to replace its message, which is not helpful to the user, with a more helpful one that lets them know what SAM permission they need to enable. Note that this actually
   */
  protected transformUnhelpfulErrorToHelpfulError(error: any) {
    // We will return the error as-is, unless we know how to map it to something more user-friendly (currently only one case of this):
    const result = error;

    if (samUserErrorCodes.includes(error?.data?.code)) {
      // This same situation can occur with the "old SIM Management UI" and the "new SIM Management UI" — but the error messages should be different. We can detect which error message should be shown by inspecting `error.config.url`.
      const url = error?.config?.url || error?.url || 'unknown';

      if (url.includes('/v1/query/sims')) {
        result.message = this.$translate.instant('subscribers.fallback_sim_search');
      } else if (url.includes('/v1/query/subscribers')) {
        result.message = this.$translate.instant('subscribers.fallback_subscriber_search');
      }
      // else we don't know how to transform this error, so don't do anything
    }
    return result;
  }

  protected fetchAndUpdateTableData(fetcher: () => Promise<ScRelation<T>>) {
    this.listApiError = null;
    this.loadingDidBegin();
    this.uncheckAll();
    this.paginator.searchQuery = this.searchQuery;
    fetcher()
      .then((relation) => {
        this.setData(relation.data);
        this.loadingDidComplete();
      })
      .catch((error: LegacyAny) => {
        this.listApiError = error;
        if (samUserErrorCodes.includes(error?.data?.code)) {
          this.transformUnhelpfulErrorToHelpfulError(error);
        }
        this.loadingDidFail();
        this.alertsService.showError(error);
      });
  }

  reloadData() {
    this.fetchAndUpdateTableData(() => {
      return this.paginator.getPage(this.paginator.getCurrentPageNumber());
    });
  }

  showPrevPage() {
    this.fetchAndUpdateTableData(() => {
      return this.paginator.getPrevPage();
    });
  }

  showNextPage() {
    this.fetchAndUpdateTableData(() => {
      return this.paginator.getNextPage();
    });
  }

  loadingDidBegin() {
    this.loadState = LoadState.loading;
    this._isLoading = true;
  }

  loadingDidComplete() {
    this._isLoading = false;
    this.loadState = LoadState.complete;
  }

  loadingDidFail() {
    this._isLoading = false;
    this.loadState = LoadState.failed;
  }
}
