import { LegacyAny } from '@soracom/shared/core';

import { BreadcrumbService } from '@soracom/shared-ng/routing';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { Breadcrumb, BreadcrumbForDashboard } from '@soracom/shared/routing';
import {
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { SearchQuery } from '@user-console/legacy-soracom-api-client';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import { ReloadTableAction } from '../core/actions/ReloadTableAction';
import { ApiEvent } from '../core/api_event';
import { HarvestDirectory, HarvestFile } from '../core/harvest_file';
import { InjectList } from '../core/injectable';
import { ScPaginatorOptions } from './../components/paginator';
import { CopyHarvestFileUrlForAirAction } from './actions/copy_harvest_file_url_for_air_action';
import { DeleteHarvestFileAction } from './actions/delete_harvest_file_action';
import { DownloadHarvestFileAction } from './actions/download_harvest_file_action';
import { UploadHarvestFileAction } from './actions/upload_harvest_file_action';
import { HarvestFileBreadcrumb } from './harvest_file_breadcrumb';
import { HarvestFilesService } from './harvest_files.service';
import { template } from './harvest_files_page.component.html';

export class HarvestFilesPageComponent implements ng.IComponentOptions {
  controller = HarvestFilesPageController;
  template: any = template;
}

export class HarvestFilesPageController extends BasePaginatableTableController<HarvestFile> {
  static $inject: InjectList = [
    '$location',
    '$log',
    '$translate',
    '$q',
    '$rootScope',
    '$routeParams',
    '$scope',
    '$uibModal',
    'AlertsService',
    'UiDsModalService',
    'HarvestFilesService',
    'TableColumnOptionsService',
    'BreadcrumbService',
  ];

  private _harvestFilesSearchQuery: SearchQuery;

  // it's referred from UploadHarvestFileAction
  // @ts-expect-error (legacy code incremental fix)
  currentDirectory: HarvestDirectory;

  // Human-readable directory path.
  // @ts-expect-error (legacy code incremental fix)
  searchPath: string;

  // Output
  breadcrumb: Array<{ path: string; label: string }> = [];

  protected get paginatorOptions(): ScPaginatorOptions {
    return {
      defaultPerPage: 100,
      itemsPerPage: [100, 500, 1000],
    };
  }

  get searchQuery() {
    return this._harvestFilesSearchQuery;
  }

  private get queryItem() {
    return this._harvestFilesSearchQuery.queryItems.find((i) => i.key === 'path');
  }

  get currentPath(): string {
    return this.currentDirectory.humanReadablePath;
  }

  set currentPath(decodedPath: string) {
    this.currentDirectory = new HarvestDirectory(decodedPath);
    // @ts-expect-error (legacy code incremental fix)
    this.queryItem.value = this.currentDirectory.decodedPath;
  }

  constructor(
    private $location: ng.ILocationService,
    $log: ng.ILogService,
    $translate: LegacyAny,
    $q: ng.IQService,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: LegacyAny,
    private $scope: ng.IScope,
    public $uibModal: any,
    AlertsService: any,
    public uiDsModalService: UiDsModalService,
    public harvestFilesService: HarvestFilesService,
    private tableColumnOptionsService: TableColumnOptionsService,
    private breadcrumbService: BreadcrumbService,
  ) {
    super($log, $translate, $q, AlertsService, harvestFilesService);
    this.modelName = 'HarvestFile';
    this._harvestFilesSearchQuery = new SearchQuery([{ key: 'path', value: '' }]);
  }

  $onInit() {
    super.$onInit();

    this.setTraceEnabled(true);
    this.trace(this);

    this.actionContainer.actions = {
      UploadFile: new UploadHarvestFileAction(this, this.$uibModal),
      Delete: new DeleteHarvestFileAction(this.$q, this, this.harvestFilesService, this.uiDsModalService),
      Download: new DownloadHarvestFileAction(this, this.harvestFilesService),
      Reload: new ReloadTableAction(this),
      CopyHarvestFileUrlForAir: new CopyHarvestFileUrlForAirAction(),
    };

    this.setupColumnOptions(TableColumnOptionsType.HarvestFile, this.tableColumnOptionsService, this.uiDsModalService);

    this.loadPath(this.$routeParams.path);

    this.$scope.$on('$locationChangeSuccess', () => {
      this.alertsService.clearAll();
      this.loadPath(this.$routeParams.path);
    });

    this.$rootScope.$on(ApiEvent.CoverageTypeChanged.toString(), () => {
      this.openDirectory('/');
    });
  }

  downloadFile(item: HarvestFile) {
    this.doAction('Download', [item]);
  }

  openItem(item: HarvestFile) {
    this.openDirectory(item.path);
  }

  copyUrl(item: HarvestFile): void {
    this.actionContainer.actions.CopyHarvestFileUrlForAir.run([item]);
  }

  openDirectory(decodedPath: string, event?: ng.IAngularEvent) {
    event?.preventDefault();
    // Format input as a directory
    const directoryToSearch = new HarvestDirectory(decodedPath);
    // path will be encoded in $location
    this.$location.search('path', directoryToSearch.decodedPath);
  }

  invokeSearch() {
    const decodedPath = HarvestFile.formatPath(this.searchPath + '/', { encode: true });
    this.openDirectory(decodedPath);
  }

  clearSearch() {
    this.searchPath = '/';
    this.$scope.$applyAsync();
  }

  shouldShowClearPathButton() {
    return this.searchPath && this.searchPath !== '/';
  }

  shouldDisableSearchButton() {
    return this.searchPath === this.currentDirectory.humanReadablePath;
  }

  private loadPath(decodedPath: string) {
    this.alertsService.clearAll();
    this.currentDirectory = new HarvestDirectory(decodedPath);
    this.searchPath = this.currentDirectory.humanReadablePath;

    // Load the breadcrumbs. Note that there was an older implementation of breadcrumbs for Harvest Files (they are calculated based on the user's browsing), so I simply kept the old implementation and mapped them to Breadcrumb. (It is last-minute and I don't want to break anything, and that seemed the most reliable thing to do: not change HarvestFileBreadcrumb at all.)
    this.breadcrumb = HarvestFileBreadcrumb.generate(this.currentDirectory);

    // @ts-expect-error (legacy code incremental fix)
    const pathBreadcrumbs = this.breadcrumb.map((e: HarvestFileBreadcrumb) => {
      // Note: we can't use URL here because the legacy HarvestFileBreadcrumb doesn't give us a valid URL. It instead gives a string like '/harvest_files?path=/foo/bar'
      //
      // So, don't do this: const legacyUrl = new URL(e.href);

      // And no need to do this either, because e.href always starts with harvest_files?path=
      // const pathname = e.href?.split('?')[0];
      // const path = e.href?.split('?path=')[1];

      const pathname = '/harvest_files';
      const path = e.href.substring('harvest_files?path='.length + 1);
      const pathQuery = path ? { path } : {};

      const result: Breadcrumb = {
        label: e.label,
        url: pathname,
        // @ts-expect-error (legacy code incremental fix)
        queryParams: pathQuery,
      };
      return result;
    });
    this.breadcrumbService.overrideBreadcrumbs([...BaseBreadcrumbs, ...pathBreadcrumbs]);

    this.searchQuery.setQueryItem('path', this.currentDirectory.decodedPath);
    this.tableData.refreshData([]);
    this.reloadData();
  }
}

const BaseBreadcrumbs: Breadcrumb[] = [
  BreadcrumbForDashboard,
  { labelKey: 'routes.title.harvest_files', url: '/harvest_files' },
];
