import { LegacyAny } from '@soracom/shared/core';

import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';

/**
 *
 * Usage:
 * <div sc-feature="foo">This text is visible if 'foo' feature is enabled.</div>
 *
 * @param ngIfDirective
 * @param featureVisibilityService
 */
export function FeatureVisibilityDirective(
  ngIfDirective: LegacyAny,
  featureVisibilityService: FeatureVisibilityService
): ng.IDirective {
  // https://stackoverflow.com/a/24065378
  const ngIf = ngIfDirective[0];

  return {
    multiElement: ngIf.multiElement,
    transclude: ngIf.transclude,
    priority: ngIf.priority - 1,
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    $$tlb: ngIf.$$tlb,
    link: (
      scope: ng.IScope,
      element: ng.IAugmentedJQuery,
      attributes: ng.IAttributes,
      ctrl?: LegacyAny,
      transclude?: LegacyAny
    ): void => {
      // find the initial ng-if attribute
      const initialNgIf = attributes.ngIf;
      let ifEvaluator;
      // if it exists, evaluates ngIf && featureVisibilityService
      if (initialNgIf) {
        ifEvaluator = () => {
          return scope.$eval(initialNgIf) && featureVisibilityService.isEnabled(attributes.scFeature);
        };
      } else {
        // if there's no ng-if, process normally
        ifEvaluator = () => {
          return featureVisibilityService.isEnabled(attributes.scFeature);
        };
      }
      attributes.ngIf = ifEvaluator;
      ngIf.link.apply(ngIf, [scope, element, attributes, ctrl, transclude]);
    },
  } as any;
  // $$tlb is hidden attribute. https://github.com/angular/angular.js/issues/6042
}

FeatureVisibilityDirective.$inject = ['ngIfDirective', 'FeatureVisibilityService'];

/**
 * Copy of FeatureVisibilityDirective, but the result of featureVisibilityService.inEnabled is used as opposite meaning.
 *
 * <div sc-no-feature="foo">This text is hidden if 'foo' feature is enabled.</div>
 * @param ngIfDirective
 * @param featureVisibilityService
 */
export function FeatureVisibilityInverseDirective(
  ngIfDirective: LegacyAny,
  featureVisibilityService: FeatureVisibilityService
): ng.IDirective {
  // https://stackoverflow.com/a/24065378
  const ngIf = ngIfDirective[0];

  return {
    multiElement: ngIf.multiElement,
    transclude: ngIf.transclude,
    priority: ngIf.priority - 1,
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    $$tlb: ngIf.$$tlb,
    link: (
      scope: ng.IScope,
      element: ng.IAugmentedJQuery,
      attributes: ng.IAttributes,
      ctrl?: LegacyAny,
      transclude?: LegacyAny
    ): void => {
      // find the initial ng-if attribute
      const initialNgIf = attributes.ngIf;
      let ifEvaluator;
      // if it exists, evaluates ngIf && featureVisibilityService
      if (initialNgIf) {
        ifEvaluator = () => {
          return scope.$eval(initialNgIf) && !featureVisibilityService.isEnabled(attributes.scNoFeature);
        };
      } else {
        // if there's no ng-if, process normally
        ifEvaluator = () => {
          return !featureVisibilityService.isEnabled(attributes.scNoFeature);
        };
      }
      attributes.ngIf = ifEvaluator;
      ngIf.link.apply(ngIf, [scope, element, attributes, ctrl, transclude]);
    },
  } as any;
  // $$tlb is hidden attribute. https://github.com/angular/angular.js/issues/6042
}

FeatureVisibilityInverseDirective.$inject = ['ngIfDirective', 'FeatureVisibilityService'];
