<section dsDialogContent>
  <table class="ds-datatable">
    <thead>
      <tr>
        <th>{{ 'table_settings.show_column' | translate }}</th>
        <th class="--max">{{ 'table_settings.column_name' | translate }}</th>
      </tr>
    </thead>
    <tr *ngFor="let column of columnOptions">
      <ng-container *ngIf="column.key !== 'checkbox'">
        <td>
          <div class="ds-checkbox">
            <input
              id="{{ 'column-option-cb-' + column.key }}"
              type="checkbox"
              [(ngModel)]="column.show"
            />
          </div>
        </td>
        <td>
          <label for="{{ 'column-option-cb-' + column.key }}">{{ getColumnName(column.key) }}</label>
        </td>
        <td>
          <button class="ds-button --plain --small --icon-delete"
            *ngIf="isTag(column.key)"
            (click)="removeTag(column.key)"
            id="{{ 'subscribers-table-settings-remove-tag-button-' + column.key }}"
          >
            <span>{{ 'table_settings.remove_tag_column' | translate }}</span>
          </button>
        </td>
      </ng-container>
    </tr>
  </table>
</section>
<footer dsDialogFooter>
  <button
    class="ds-button--primary"
    id="button-save-table-settings"
    (click)="save()"
    [disabled]="disableSave"
    type="button"
  >
    <span>{{ 'table_settings.save' | translate }}</span>
  </button>
  <button
    class="ds-button"
    id="subscribers-table-settings-modal-cancel-button"
    (click)="cancel()"
    type="button"
  >
    <span>{{ 'table_settings.cancel' | translate }}</span>
  </button>
</footer>
