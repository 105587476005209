import { LegacyAny } from '@soracom/shared/core';

import { Terminatable } from '@soracom/shared/soracom-platform';
import { TableController } from '../../components/base_table_controller';
import { ChangeTerminationProtectionResult } from '../../components/modals/change_termination_protection.component';
import { TerminatableService } from '@soracom/shared/soracom-platform';
import { Action } from './Action';

export class ChangeTerminationProtectionAction<T extends Terminatable> implements Action<T> {
  constructor(
    private $log: ng.ILogService,
    private $uibModal: any,
    private ctrl: TableController<T>,
    private model: string,
    private service: TerminatableService
  ) {}

  run() {
    this.$uibModal
      .open({
        backdrop: 'static',
        component: 'scChangeTerminationProtectionComponent',
        resolve: {
          service: () => {
            return this.service;
          },
          params: () => {
            return {
              resources: this.ctrl.tableData.getSelectedData(),
              resourceType: this.model,
            };
          },
        },
      })
      .result.then(this.okAction, this.cancelAction);
  }

  actionable() {
    const notTerminated =
      this.ctrl.tableData.getSelectedData().filter((r) => {
        return r.status === 'terminated';
      }).length === 0;
    return this.ctrl.tableData.countChecked() > 0 && notTerminated;
  }

  private okAction = (result: ChangeTerminationProtectionResult) => {
    if (result) {
      const messageValues = result.messageValues();
      const translationId = messageValues.translate;
      const values = messageValues.values;

      if (result.didSucceed()) {
        this.ctrl.alertsService.showAlert('success', { translationId, values });
      } else {
        this.ctrl.alertsService.showAlert('danger', { translationId, values });
      }

      const successResourceList = result.changed.map((o) => o.id);
      const failureResourceList = result.failed.map((o) => o.id);

      this.ctrl.getSelection().forEach((row) => {
        if (successResourceList.includes(row.obj.id)) {
          row.obj.terminationEnabled = !result.enableTerminationProtection;
          row.feedback.changeTerminationProtection = 'success';
        } else if (failureResourceList.includes(row.obj.id)) {
          row.feedback.changeTerminationProtection = 'failure';
        } else {
          this.$log.error('logic failure JHS83-JD-823');
        }
      });
    }
  };

  private cancelAction = (reason: LegacyAny) => {
    // The component passes a result object to resultAction() above, for success
    // and/or failure. This cancel action will only be invoked due to user cancellation.
    this.$log.debug('changeTerminationProtection modal cancelAction()...', reason);
  };
}
