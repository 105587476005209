import { LegacyAny } from '@soracom/shared/core';

import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, DestroyRef, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Logger } from '@soracom/shared-ng/logger-service';
import { Observable, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { LegacyBaseSoracomApiService, SoracomApiParams } from '@user-console/legacy-soracom-api-client';
import { LagoonSubscription } from '../../../../app/shared/lagoon/lagoon_plans';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { RootStoreState } from '../../root-store';
import { selectSubscription } from '../../root-store/lagoon-store/selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface S3Info {
  amz_credentials: string;
  amz_date: string;
  amz_token: string;
  bucket: string;
  date: string;
  key: string;
  policy: string;
  policy_base64: string;
  region: string;
  signature: string;
  url: string;
}

@Component({
  selector: 'app-lagoon-logo',
  templateUrl: './lagoon-logo.component.html',
  styleUrls: ['./lagoon-logo.component.scss'],
})
export class LagoonLogoComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  subscription$ = this.store$.pipe(select(selectSubscription));

  alertsManager = new AlertsManager();
  // @ts-expect-error (legacy code incremental fix)
  image: boolean;
  isLoading = false;
  // @ts-expect-error (legacy code incremental fix)
  logoDetails: S3Info;
  loading = {
    image: false,
    logo: '',
    progress: 0,
  };
  barStyle = {
    width: '0%',
  };
  text = 'no image';
  hasPermission = false;

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('fileInput') el: ElementRef;

  constructor(
    private httpClient: HttpClient,
    private logger: Logger,
    private soracomApi: LegacyBaseSoracomApiService,
    private store$: Store<RootStoreState.State>,
  ) {}

  ngOnInit(): void {
    this.subscription$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subscription) => {
      this.hasPermission = subscription === LagoonSubscription.pro;
      if (this.hasPermission) {
        this.isLoading = true;
        this.getImageUpload()
          .then(() => {
            this.isLoading = false;
          })
          .catch((err: LegacyAny) => {
            this.alertsManager.add(Alert.fromApiError(err));
            this.isLoading = false;
          });
      }
    });
  }

  getImageUpload() {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: '/v1/lagoon/image/link',
    };
    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      this.logoDetails = response.data;
      return this.showImageIfExists(this.logoDetails.url);
    });
  }

  showImageIfExists(imageUrl: string) {
    return this.httpClient.head(imageUrl, { observe: 'response' }).subscribe((result: LegacyAny) => {
      if (result.status === 200) {
        this.loading.logo = imageUrl;
      }
    });
  }

  fileSelected(event: LegacyAny) {
    // @ts-expect-error (legacy code incremental fix)
    const file = (event.target as HTMLInputElement).files[0];
    this.loading.image = true;
    this.loading.logo = '';
    this.loading.progress = 0;
    this.barStyle.width = '0%';
    this.isLoading = true;

    // we need to force the scope to apply when called from the input
    const formData = new FormData();
    formData.append('key', this.logoDetails.key);
    formData.append('acl', 'public-read');
    formData.append('success_action_redirect', this.logoDetails.url);
    formData.append('Content-Type', file.type);
    formData.append('Cache-Control', 'no-cache'); // this doesn't actually mean don't cache, it means always revalidate the content hasn't changed
    formData.append('X-Amz-Credential', this.logoDetails.amz_credentials);
    formData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
    formData.append('X-Amz-Date', this.logoDetails.amz_date);
    formData.append('x-amz-meta-tag', '');
    formData.append('Policy', this.logoDetails.policy_base64);
    formData.append('X-Amz-Signature', this.logoDetails.signature);
    formData.append('file', file);

    const actionURL = `https://${this.logoDetails.bucket}.s3.amazonaws.com/`;
    return this.httpClient
      .post(actionURL, formData, { observe: 'events', reportProgress: true, responseType: 'blob' })
      .pipe(
        map((e: HttpEvent<Blob>) => this.getEventMessage(e, file)),
        tap((message) => this.logger.debug(message)),
        last(), // return last (completed) message to caller
        catchError((err, caught) => this.handleError(err, caught)),
      )
      .toPromise();
  }

  private getEventMessage(event: HttpEvent<Blob>, file: any): string {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;
      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        // @ts-expect-error (legacy code incremental fix)
        this.loading.progress = Math.round((100 * event.loaded) / event.total);
        this.barStyle.width = `${this.loading.progress}%`;
        return `Uploading... "${this.barStyle.width}"`;
      case HttpEventType.ResponseHeader:
        this.loading.image = false;
        this.isLoading = false;
        // Image file path is included in 303 response header.
        // @ts-expect-error (legacy code incremental fix)
        this.loading.logo = event.url;
        return 'Upload is finished';
      // 2021/04/02 yuta: I can't find a way to stop redirection & downloading assets from S3.
      case HttpEventType.DownloadProgress:
        return 'Start downloading uploaded S3 object';
      case HttpEventType.Response:
        return 'Finish downloading uploaded S3 object';
      default:
        return `File "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

  private handleError(err: HttpErrorResponse, caught: Observable<string>) {
    this.alertsManager.add(Alert.fromApiError(err));
    this.loading.image = false;
    this.isLoading = false;
    return throwError(() => new Error(err.message));
  }
}
