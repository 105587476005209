import { LegacyAny } from '@soracom/shared/core';

import { template } from './audit_logs_page.component.html';

import { AlertsService, AlertsServiceInstance } from '../components/alerts.service';
import { BaseController } from '../components/base_controller';
import { ApiEvent } from '../core/api_event';
import { InjectList } from '../core/injectable';
import { ContractName, OperatorContractsService } from '../operators/operator_contracts.service';
import { getCoverageType, getOperatorData, getLoginUserData } from '@soracom/shared/data-access-auth';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { UpdateNapterContractModalComponent } from 'apps/user-console/src/app/napter/update_napter_contract.modal.component';

export class AuditLogsPageComponent implements ng.IComponentOptions {
  bindings = {};

  controller = AuditLogsPageComponentController;
  template: any = template;
}

export class AuditLogsPageComponentController extends BaseController {
  static $inject: InjectList = [
    '$log',
    '$rootScope',
    'UiDsModalService',
    'AlertsService',
    'OperatorContractsService',
    'LoginUserDataService',
  ];

  alertsService: AlertsServiceInstance;
  // @ts-expect-error (legacy code incremental fix)
  hasNapterContract: boolean;

  constructor(
    $log: ng.ILogService,
    private $rootScope: ng.IRootScopeService,
    private uiDsModalService: UiDsModalService,
    alertsServiceGenerator: AlertsService,
    private operatorContractsService: OperatorContractsService
  ) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    this.hasNapterContract = this.checkNapterContract();
    this.$rootScope.$on(ApiEvent.CoverageTypeChanged.toString(), (event, coverageType) => {
      this.hasNapterContract = this.checkNapterContract();
    });
  }

  checkNapterContract(): boolean {
    const contracts = getOperatorData().getContracts(getCoverageType()) ?? [];
    return contracts.includes(ContractName.napter);
  }

  getLoggedInAsRoot(): boolean {
    return getLoginUserData().isRootUser === true;
  }

  subscribeNapterAuditLogs() {
    this.operatorContractsService
      .subscribe(ContractName.napter)
      .then(() => {
        this.hasNapterContract = this.checkNapterContract();
        this.alertsService.showSuccess('audit_logs.manage_subscription.subscribed');
      })
      .catch((e: LegacyAny) => {
        this.alertsService.showError(e);
      });
  }

  unsubscribeNapterAuditLogs() {
    this.operatorContractsService
      .unsubscribe(ContractName.napter)
      .then(() => {
        this.hasNapterContract = this.checkNapterContract();
        this.alertsService.showSuccess('audit_logs.manage_subscription.unsubscribed');
      })
      .catch((e: LegacyAny) => {
        this.alertsService.showError(e);
      });
  }

  openUpdateSubscriptionModal() {
    this.uiDsModalService
      .openAndWaitForResult(UpdateNapterContractModalComponent, {
        title: 'operators.modals.update_napter_contracts.title',
        data: {
          resolve: {
            subscribed: () => this.hasNapterContract,
          },
        },
      })
      .then((shouldUpdate) => {
        if (shouldUpdate) {
          this.debug(this.hasNapterContract);
          if (this.hasNapterContract) {
            this.unsubscribeNapterAuditLogs();
          } else {
            this.subscribeNapterAuditLogs();
          }
        }
      });
  }
}
