import { LegacyAny } from '@soracom/shared/core';

import { template } from './edit_device_resource.component.html';

import { AlertsService, AlertsServiceInstance } from '../../components/alerts.service';
import { BaseModalController } from '../../components/base_modal_controller';
import { InjectList } from '../../core/injectable';
import { DevicesService } from './../devices.service';

export class EditDeviceResourceModalComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };

  controller = EditDeviceResourceModalComponentController;
  template: any = template;
}

export class EditDeviceResourceModalComponentController extends BaseModalController {
  static $inject: InjectList = ['$log', 'AlertsService', 'DevicesService'];

  alertsService: AlertsServiceInstance;
  submitting = false;
  value: any;

  constructor($log: ng.ILogService, alertsServiceGenerator: AlertsService, private devicesService: DevicesService) {
    super($log);
    this.alertsService = alertsServiceGenerator.generate();
  }

  $onInit() {
    this.value = this.resolve.value;
  }

  submit() {
    this.write();
  }

  write() {
    this.submitting = true;
    let value: LegacyAny;
    if (this.resolve.resource.type.toLowerCase() === 'integer') {
      value = Number(this.value);
    } else if (this.resolve.resource.type.toLowerCase() === 'string') {
      value = this.value;
    } else {
      value = this.value;
    }

    this.devicesService
      .putResource(
        this.resolve.deviceId,
        this.resolve.objectKey,
        this.resolve.instanceKey,
        this.resolve.resourceKey,
        value
      )
      .then((response: LegacyAny) => {
        this.debug(response.data);
        if (response.status === 202) {
          this.close({ $value: value });
        } else {
          this.alertsService.showError(response.data.message);
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
