import { LegacyAny } from '@soracom/shared/core';

import { BaseModel } from '@soracom/shared/soracom-platform';

export class DeviceKey extends BaseModel {
  // @ts-expect-error (legacy code incremental fix)
  keyId: string;
  // @ts-expect-error (legacy code incremental fix)
  status: string;
  // @ts-expect-error (legacy code incremental fix)
  secretKey: string;
  // @ts-expect-error (legacy code incremental fix)
  createdTime: number;

  constructor(params?: LegacyAny) {
    super();
    if (params) {
      this.keyId = params.keyId;
      this.secretKey = params.secretKey;
      this.status = params.status;
      this.createdTime = params.createdTime;
    }
  }

  get id() {
    return this.keyId;
  }
}

export enum DeviceKeyStatus {
  active = 'active',
  inactive = 'inactive',
}
