import { LegacyAny } from '@soracom/shared/core';

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CoverageTypeService, getCoverageType, getOperatorData } from '@soracom/shared/data-access-auth';
import { ApiEvent } from '../../../app/shared/core/api_event';
import { ContractName, OperatorContractsService } from '../../../app/shared/operators/operator_contracts.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { SoracomUserConsole } from '../shared/SoracomUserConsole';
import { LogModuleSharedAlertsManager } from './LogModuleSharedAlertsManager';

export type NapterSubscriptionModals =
  | 'subscribeNapterAuditLogs'
  | 'unsubscribeNapterAuditLogs'
  | 'subscribeAuditLogsEnterprise'
  | 'unsubscribeAuditLogsEnterprise';

// Mason 2021-06-08: I am confused as to why this next type exists, but leaving it for now...
export type SubscriptionModal = NapterSubscriptionModals /* | PrematureImplementation */;

/**
 * LogViewerSubscriptionModalService is an injectable class exposing RxJS objects
 * so that the LogViewer, LogViewerHeader, LogViewerSubscriptionStatusComponent,
 * SubscriptionModalComponents and their sub-components may all communicate.
 *
 * At construction time, observables may be initialized with the undefined value.
 * Any early subscribers should be prepared to handle the undefined, which will
 * fire during their call to subscribe().  The observables will be updated with
 * actual values around the time init() is called.
 *
 * This service is local to the SoracomLogsModule.  The Angular docs explain
 * several ways to configure the provider for local service:
 * 1. set the providedIn: parameter to the Injectable annotation.  This is the
 *    preferred approach.
 * 2. set the providedIn: as above, but use forwardRef to refer to the module.
 * 3. add the service class to the providers array in the NgModule.  This makes
 *    the service local to the module.    ...Assuming the module is lazy-loaded.
 *    For eagerly-loaded modules, the providers are copied into the global scope.
 * 4. configure the service in the providers array of a component:
 *    "Providing a service in the component limits the service only to that
 *    component and its descendants,  Other components in the same module can’t
 *    access it."
 * During development of this service, approaches #1 and #2 both caused
 * runtime exceptions from the ngModule compilation in the browser, resulting
 * in a blank screen.  Approach #3 doesn't work, because all components in the
 * user-console app are eagerly loaded.  Fortunately, there is a topmost
 * component that is a good candidate for approach #4: LogViewerComponent.
 * So that's where this service is provided for now.
 */
@Injectable()
export class LogViewerSubscriptionModalService {
  /**
   * Does the user have a contract for Napter Audit Logs?  This property affects which
   * text and links are shown to the user when the LogViewer component is in
   * Napter mode.
   */
  hasNapterContract = new BehaviorSubject<boolean | undefined>(undefined);

  /**
   * Does the user have a contract for Audit Logs Enterprise?  This property affects which
   * text and links are shown to the user when the LogViewer component is in
   * Audit Logs mode.
   */
  hasAuditLogsEnterpriseContract = new BehaviorSubject<boolean | undefined>(undefined);

  /**
   * `currentModal` is used to trigger the appearance of a subscription modal.
   * Links to open these appear in the header component, depending on the User's
   * subscription state.  It makes sense for the initial value to be "no modals",
   * so this component uses false as its undefined value.
   */
  currentModal: BehaviorSubject<SubscriptionModal | false> = new BehaviorSubject<SubscriptionModal | false>(false);

  constructor(
    private coverageTypeService: CoverageTypeService,
    private operatorContractsService: OperatorContractsService,
    @Inject(LogModuleSharedAlertsManager) private alertsManager: AlertsManager
  ) {}

  /**
   * init must be called by an owning component, around ngInit time.  It gets
   * initial values and fires up the observables.
   */
  init() {
    const angularJsRootScope = SoracomUserConsole.legacyRootScope;
    const updateContracts = () => {
      this.hasNapterContract.next(this._checkContract(ContractName.napter));
      this.hasAuditLogsEnterpriseContract.next(this._checkContract(ContractName.api_audit_log));
    };
    updateContracts();
    angularJsRootScope.$on(ApiEvent.CoverageTypeChanged.toString(), updateContracts);
  }

  showModal(modal: SubscriptionModal) {
    this.currentModal.next(modal);
  }

  closeModal() {
    this.currentModal.next(false);
  }

  subscribeNapterAuditLogs() {
    this._subscriptionUpdateApiCall(() => this.operatorContractsService.subscribe(ContractName.napter));
  }

  unsubscribeNapterAuditLogs() {
    this._subscriptionUpdateApiCall(() => this.operatorContractsService.unsubscribe(ContractName.napter));
  }

  subscribeAuditLogsEnterprise() {
    this._subscriptionUpdateApiCall(() => this.operatorContractsService.subscribe(ContractName.api_audit_log));
  }

  unsubscribeAuditLogsEnterprise() {
    this._subscriptionUpdateApiCall(() => this.operatorContractsService.unsubscribe(ContractName.api_audit_log));
  }

  private _subscriptionUpdateApiCall(apiCallFn: LegacyAny) {
    const saving = Alert.info('SoracomLogs.savingChangesToSubscription');
    this.alertsManager.add(saving);

    apiCallFn()
      .finally((res: LegacyAny) => {
        this.alertsManager.remove(saving);
        return res;
      })
      .then(() => {
        // When a subscription change is successful, the contract state should
        // change, which should update the subscription status to change in the
        // header. So, no additional alerts necessary...
        this.hasNapterContract.next(this._checkContract(ContractName.napter));
        this.hasAuditLogsEnterpriseContract.next(this._checkContract(ContractName.api_audit_log));
      })
      .catch((e: LegacyAny) => {
        // OTOH, if a subscription change fails, we will show an error indicator
        // in addition to the subscription state.
        this.alertsManager.add(Alert.fromApiError(e));
      });
  }

  ngOnDestroy() {
    this.hasAuditLogsEnterpriseContract.complete();
    this.hasAuditLogsEnterpriseContract.complete();
  }

  private _checkContract(name: ContractName) {
    const contracts = getOperatorData().getContracts(getCoverageType()) || [];
    return contracts.includes(name);
  }
}
