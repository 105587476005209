<section class="ds-app__main-content ds-rows --indent-small x-long-term-discounts-page-root">
  <h2>{{ 'long_term_discount.page_header' | translate }}</h2>

  @if (infoVar) {
    <div class="ds-notice --info">
      <h4 class="ds-text --bold">{{ infoVar.heading | translate }}</h4>
      <div [innerHTML]="infoVar.body | translate"></div>
    </div>
  }

  <ui-button-bar [content]="buttonBar"></ui-button-bar>
  <app-long-term-discount-list [discounts]="longTermDiscounts"></app-long-term-discount-list>
</section>
