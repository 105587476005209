import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { formatDateTime } from '@soracom/shared/util-common';
import { ScRelation } from '../../../../app/shared/components/paginator';
import { HarvestData } from '../../../../app/shared/core/harvest_data';
import {
  HarvestDataPrevQuery,
  HarvestDataQuery,
  HarvestDataService,
} from '../../../../app/shared/harvest_data/harvest_data.service';
import { DataSeriesVisibility } from '../harvest-data-viz/harvest-data-viz-type';
import { HarvestResourceType } from '../harvest-data.type';
import {
  API_ERROR,
  API_REQUEST_LIMIT,
  HarvestDownloadErrorCode,
  REACH_SIZE_LIMIT,
  USER_CANCEL,
} from './harvest-data-download-error-code';
import { union } from 'lodash-es';

export type HarvestDataDownloadType = 'content' | 'chart_data' | 'chart_data_csv';

interface HarvestDataForDownload extends HarvestData {
  formattedTime: string;
  iso8601Time: string;
}

export interface HarvestDataDownloadResult {
  err: boolean;
  code?: HarvestDownloadErrorCode;
}

interface HarvestDataDownloadProgressData {
  // state: HarvestDataDownloadState;
  data: HarvestDataForDownload[];
  query: HarvestDataQuery | HarvestDataPrevQuery;
  fileCount: number; // num of file generated
  dataSize: number; // downloaded data size for a file
  totalDataSize: number; // total
  fileName: string;
  type: HarvestDataDownloadType;
  resourceType: HarvestResourceType;
  resourceId: string;
}

@Injectable()
export class HarvestDataDownloadAction {
  constructor(private api: HarvestDataService) {}
  API_CALL_INTERVAL_MS = 100;
  // The max of Blob size is 500MB. But in case of a user who access with lower memory machine
  DATA_SIZE_THRESHOLD = 1000 * 1000 * 250; // 250M
  // @ts-expect-error (legacy code incremental fix)
  downloadProgress: HarvestDataDownloadProgressData;
  // @ts-expect-error (legacy code incremental fix)
  stopping: boolean; // interruption flag from Component

  async startDownload(
    query: HarvestDataQuery,
    type: HarvestDataDownloadType,
    fileName?: string,
  ): Promise<HarvestDataDownloadResult> {
    this.stopping = false;
    query.sort = 'desc';
    this.downloadProgress = {
      query: query,
      data: [],
      dataSize: 0,
      totalDataSize: 0,
      fileCount: 1,
      // @ts-expect-error (legacy code incremental fix)
      fileName: fileName,
      type: type,
      resourceType: query.resourceType,
      resourceId: query.resourceId,
    };
    const res = await this.downloadAllData();
    return res;
  }

  async resumeDownload(): Promise<HarvestDataDownloadResult> {
    const res = await this.downloadAllData();
    return res;
  }

  private async downloadAllData(): Promise<HarvestDataDownloadResult> {
    let limitReached = false;
    let data = this.downloadProgress.data;
    let dataSize = this.downloadProgress.dataSize;
    let totalDataSize = this.downloadProgress.totalDataSize;
    let fileCount = this.downloadProgress.fileCount;

    // download from API
    let q: HarvestDataPrevQuery | HarvestDataQuery = this.downloadProgress.query;
    let res: ScRelation<HarvestData>;
    while (q !== undefined) {
      try {
        res = await this.downloadFromAPI(q);
      } catch (e: LegacyAny) {
        if ('status' in e) {
          if (e.status === 429) {
            return { err: true, code: API_REQUEST_LIMIT };
          }
        }
        return { err: true, code: API_ERROR };
      }
      if (res.data) {
        const tmp: HarvestDataForDownload[] = res.data.map((v) => {
          const size = new Blob([v.content], { type: 'text/plain' }).size;
          dataSize += size;
          totalDataSize += size;
          return convertToHarvestDataForDownload(v);
        });
        data = data.concat(tmp);
      } else {
        // data is empty
        return { err: false };
      }

      // @ts-expect-error (legacy code incremental fix)
      q = undefined;
      // if prev have
      if (res.links.prev) {
        q = {
          resourceType: this.downloadProgress.resourceType,
          resourceId: this.downloadProgress.resourceId,
          url: res.links.prev.url,
          sort: 'desc',
        };
      }

      if (q && dataSize >= this.DATA_SIZE_THRESHOLD) {
        limitReached = true; // continue to download but file size is exceeded
      }

      if (
        !q || // Download done
        limitReached
      ) {
        let tmpFilename = this.downloadProgress.fileName;
        if (fileCount > 1) {
          tmpFilename = this.downloadProgress.fileName + `_${fileCount}`;
        }
        downloadHarvestData(data, this.downloadProgress.type, tmpFilename);
        fileCount++;
        data = [];
        dataSize = 0;
      }

      // save progress anyway
      this.downloadProgress = {
        ...this.downloadProgress,
        query: q,
        data: data,
        dataSize: dataSize,
        totalDataSize: totalDataSize,
        fileCount: fileCount,
      };

      if (this.stopping) {
        this.stopping = false;
        return { err: true, code: USER_CANCEL };
      }

      if (limitReached) {
        return { err: true, code: REACH_SIZE_LIMIT };
      }
    }
    // @ts-expect-error (legacy code incremental fix)
    this.downloadProgress = null;
    return { err: false };
  }

  private async downloadFromAPI(query: HarvestDataPrevQuery | HarvestDataQuery) {
    let res;
    await new Promise((resolve) => setTimeout(resolve, this.API_CALL_INTERVAL_MS));
    // HarvestDataPrevQuery
    if ('url' in query) {
      res = await this.api.listPrevious(query);
    } else {
      // HarvestDataQuery
      res = await this.api.list(query);
    }
    return res;
  }

  cancelDownload() {
    this.stopping = true;
  }
}

function downloadHarvestData(source: HarvestDataForDownload[], type: HarvestDataDownloadType, filename?: string) {
  if (!filename) {
    filename = getDefaultFileName(type);
  }
  const data = new Blob([generateDownloadData(source, type)], { type: getBlobType(type) });

  const nav = window.navigator as any;
  if (typeof nav.msSaveOrOpenBlob === 'function') {
    nav.msSaveOrOpenBlob(data, filename);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}

function getDefaultFileName(type: HarvestDataDownloadType): string {
  switch (type) {
    case 'content':
      return 'Content.json';
    case 'chart_data':
      return 'ChartData.json';
    case 'chart_data_csv':
      return 'ChartData.csv';
    default:
      throw new Error(`Unsupported Type: ${type}`);
  }
}

function getBlobType(type: HarvestDataDownloadType): string {
  switch (type) {
    case 'content':
    case 'chart_data':
      return 'application/json';
    case 'chart_data_csv':
      return 'text/csv';
    default:
      throw new Error(`Unsupported Type: ${type}`);
  }
}

function generateDownloadData(source: HarvestDataForDownload[], type: HarvestDataDownloadType): string {
  let data: any;

  switch (type) {
    case 'content':
      data = source.map((log) => {
        const d: Record<string, any> = {
          resourceType: log.resourceType,
          resourceId: log.resourceId,
          time: log.iso8601Time,
          contentType: log.contentType,
          content: parseLogContent(log),
        };
        if (log.metadata) {
          d.metadata = log.metadata;
        }
        return d;
      });
      return JSON.stringify(data);
    case 'chart_data':
      data = source.map((log) => {
        return {
          resourceType: log.resourceType,
          resourceId: log.resourceId,
          time: log.iso8601Time,
          chartData: log.chartData,
        };
      });
      return JSON.stringify(data);
    case 'chart_data_csv':
      return convertToCsv(source);
    default:
      throw new Error(`Unsupported Type: ${type}`);
  }
}

function parseLogContent(log: HarvestData): any {
  if (log.contentType === 'application/json') {
    let content: any;
    try {
      content = JSON.parse(log.content);
    } catch (e) {
      content = log.content;
    }
    return content;
  } else {
    return log.content;
  }
}

export interface HarvestDataCSVGeneratorOptions {
  defaultReservedHeaderKeys: {
    resourceType: {
      label: string;
      include: boolean;
    };
    resourceId: {
      label: string;
      include: boolean;
    };
    formattedTime: {
      label: string;
      include: boolean;
    };
    iso8601Time: {
      label: string;
      include: boolean;
    };
  };
  sortByTime: boolean;
}

export function convertToCsv(
  data: HarvestDataForDownload[],
  options: HarvestDataCSVGeneratorOptions = {
    defaultReservedHeaderKeys: {
      resourceType: {
        label: '__resourceType',
        include: true,
      },
      resourceId: {
        label: '__resourceId',
        include: true,
      },
      formattedTime: {
        label: '__time',
        include: true,
      },
      iso8601Time: {
        label: '__iso8601Time',
        include: true,
      },
    },
    sortByTime: false,
  },
): string {
  if (options.sortByTime) {
    data = structuredClone(data);
    data.sort((a: LegacyAny, b: LegacyAny) => a.time - b.time);
  }
  const reservedHeaderKeys = Object.keys(options.defaultReservedHeaderKeys).filter(
    // @ts-expect-error (legacy code incremental fix)
    (key) => options.defaultReservedHeaderKeys[key].include,
  );
  const names = getCsvHeaders(data);
  const records = data.map((log) => {
    const record: string[] = [];
    reservedHeaderKeys.forEach((key) => {
      // @ts-expect-error (legacy code incremental fix)
      record.push(log[key]);
    });
    return record.concat(
      names.map((name) => {
        const value = log.chartData[name];
        if (value !== null && value !== undefined) {
          return value.toString();
        } else {
          return '';
        }
      }),
    );
  });
  const headerText = reservedHeaderKeys
    // @ts-expect-error (legacy code incremental fix)
    .map((key) => options.defaultReservedHeaderKeys[key].label)
    .concat(names)
    .join(',');
  const recordText = records.map((record) => '"' + record.join('","') + '"').join(getReturnCode());
  const csv = `${headerText}${getReturnCode()}${recordText}`;
  return csv;
}

export function convertToCsvFilteredByDataKeys(
  data: HarvestDataForDownload[],
  visibilities: DataSeriesVisibility,
  csvOptions?: HarvestDataCSVGeneratorOptions,
): string {
  const dataClone = structuredClone(data);
  dataClone.forEach((data) => {
    if (data.chartData) {
      for (const chartProp in data.chartData) {
        if (!visibilities[chartProp]) {
          delete data.chartData[chartProp];
        }
      }
    }
  });
  return convertToCsv(dataClone, csvOptions);
}

function getReturnCode(): string {
  const platform = window.navigator.platform;
  if (platform.includes('Win')) {
    return '\r\n';
  } else {
    return '\n';
  }
}

function getCsvHeaders(data: any[]): string[] {
  let result: string[] = [];
  data.forEach((log) => {
    if (log.chartData !== undefined && log.chartData !== null) {
      result = union(result, Object.keys(log.chartData));
    }
  });
  return result.sort();
}

export function convertToHarvestDataForDownload(v: HarvestData): HarvestDataForDownload {
  return {
    ...v,
    // @ts-expect-error (legacy code incremental fix)
    formattedTime: formatDateTime(v.time, 'datetime_sec'),
    // @ts-expect-error (legacy code incremental fix)
    iso8601Time: formatDateTime(v.time, 'iso_8601_msec_tz'),
  };
}
