import { LegacyAny } from '@soracom/shared/core';

import { template } from './lora_device_status_label.component.html';

import { BaseController } from '../../components/base_controller';
import { InjectList } from '../../core/injectable';
import { LoraDevice, LoraDeviceStatus } from '../../core/lora_device';

export class LoraDeviceStatusLabelComponent implements ng.IComponentOptions {
  bindings = {
    obj: '<',
  };

  controller = LoraDeviceStatusLabelComponentController;
  template: any = template;
}

export class LoraDeviceStatusLabelComponentController extends BaseController {
  static $inject: InjectList = ['$log', '$translate'];

  // @ts-expect-error (legacy code incremental fix)
  obj: LoraDevice;

  constructor($log: ng.ILogService, private $translate: LegacyAny) {
    super($log);
  }

  get status(): LoraDeviceStatus {
    // @ts-expect-error (legacy code incremental fix)
    return this.obj ? this.obj.status : undefined;
  }

  textCssClass(): string[] {
    switch (this.status) {
      case 'active':
      case 'terminated':
        return [`lora-device-status-label__text--${this.obj.status}`];
      default:
        return ['lora-device-status-label__text--unknown'];
    }
  }

  iconCssClass(): string[] {
    switch (this.status) {
      case 'active':
        if (this.obj.lastSeen) {
          return ['ds-icon--color-green', 'ds-icon--icon-online-solid'];
        } else {
          return ['ds-icon--color-ink', 'ds-icon--icon-circle'];
        }
      case 'terminated':
      default:
        return ['ds-icon--icon-cancel'];
    }
  }

  statusText() {
    if (this.status) {
      return this.$translate.instant(`lora_devices.values.status.${this.status}`);
    } else {
      return '';
    }
  }
}
