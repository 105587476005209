import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { LagoonService } from '../../../../app/shared/lagoon/lagoon.service';
import { LagoonPlan, LagoonSubscription, LagoonSubscriptionToPlan } from '../../../../app/shared/lagoon/lagoon_plans';
import { NgLocationService } from '../../../../app/shared/subscribers/ng_location.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { RootStoreState } from '../../root-store';
import { loadLagoonSubscriptionAndAvailableVersion } from '../../root-store/lagoon-store/actions';
import { selectSubscription } from '../../root-store/lagoon-store/selectors';
import { skipInitial } from '../../root-store/rxjs-custom-operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * This component is used in the two use cases:
 *  - When a user chooses an initial plan
 *  - When a user updates a plan
 *
 * This component behaves differently whether Lagoon subscription exists.
 */
@Component({
  selector: 'app-lagoon-plan',
  templateUrl: './lagoon-plan.component.html',
  styleUrls: ['./lagoon-plan.component.scss'],
})
export class LagoonPlanComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  LagoonSubscription = LagoonSubscription;
  subscription$ = this.store$.pipe(select(selectSubscription), skipInitial());
  // @ts-expect-error (legacy code incremental fix)
  subscription: LagoonSubscription;
  // @ts-expect-error (legacy code incremental fix)
  currentSubscription: LagoonSubscription;

  alertsManager = new AlertsManager();

  translationNamespace: 'edit_plan' | 'register' = 'register';
  translationValues: { url: string } = { url: '' };
  loading = false;
  submitting = false;
  hasSubscription = false;

  @Output() onSubscriptionChanged = new EventEmitter<LagoonPlan>();

  constructor(
    private coverageTypeService: CoverageTypeService,
    private lagoonService: LagoonService,
    private ngLocationService: NgLocationService,
    private store$: Store<RootStoreState.State>,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.subscription$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subscription) => {
      this.currentSubscription = subscription;
      this.hasSubscription = subscription !== LagoonSubscription.none;
      this.translationNamespace = subscription ? 'edit_plan' : 'register';

      this.subscription = this.currentSubscription;
    });

    const coverageType = this.coverageTypeService.getCoverageType();
    this.translateService.get(`LagoonComponent.pricing_url_${coverageType}`).subscribe((url: string) => {
      this.translationValues = { url };
    });
  }

  isLoading() {
    return this.loading;
  }

  isDirty(): boolean {
    return this.currentSubscription !== this.subscription;
  }

  canSubmit(): boolean {
    return this.isDirty();
  }

  isPro(): boolean {
    return this.subscription === LagoonSubscription.pro;
  }

  isMaker(): boolean {
    return this.subscription === LagoonSubscription.maker;
  }

  isFree(): boolean {
    return this.subscription === LagoonSubscription.free;
  }

  setPlan(subscription: LagoonSubscription) {
    this.subscription = subscription;
    if (!this.hasSubscription) {
      const plan = LagoonSubscriptionToPlan(this.subscription);
      this.onSubscriptionChanged.emit(plan);
    }
  }

  currentPlanDescription(): string {
    if (this.currentSubscription) {
      return this.translateService.instant(`LagoonPlanComponent.active.${this.currentSubscription}`);
    } else {
      return '';
    }
  }

  // User can't downgrade from Maker/Pro to Free plan for system restriction.
  canSelectFree(): boolean {
    return this.currentSubscription === LagoonSubscription.none || this.currentSubscription === LagoonSubscription.free;
  }

  /**
   * This method should be called only when a user wants to upgrade/downgrade a current plan.
   */
  updateSubscription() {
    this.submitting = true;
    // first convert subscription to Plan like the api expects
    const plan = LagoonSubscriptionToPlan(this.subscription);

    if (this.hasSubscription) {
      this.alertsManager.clear();
      this.lagoonService
        .changePlan(plan)
        .then(() => {
          this.store$.dispatch(loadLagoonSubscriptionAndAvailableVersion());
          this.alertsManager.add(Alert.success('LagoonPlanComponent.change_success_message'));
          this.submitting = false;
        })
        .catch((err: LegacyAny) => {
          this.alertsManager.add(Alert.fromApiError(err));
          this.subscription = this.currentSubscription;
          this.submitting = false;
        });
    } else {
      this.onSubscriptionChanged.emit(plan);
    }
  }

  /**
   * This method should be called when Lagoon subscription is registered.
   */
  cancelSubscription() {
    this.submitting = true;
    this.alertsManager.clear();
    this.lagoonService
      .terminate()
      .then(() => {
        this.submitting = false;
        this.subscription$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((subscription) => {
          if (subscription === LagoonSubscription.none) {
            this.ngLocationService.updateRoute('/lagoon');
          }
        });
        this.store$.dispatch(loadLagoonSubscriptionAndAvailableVersion());
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
        this.submitting = false;
      });
  }
}
