import { LegacyAny } from '@soracom/shared/core';

import { saveAs } from 'file-saver';
import { PaginationOptions, ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginationService,
  PaginatableService,
  SoracomApiParams,
  SearchQuery,
} from '@user-console/legacy-soracom-api-client';
import { HarvestFile } from '../core/harvest_file';
import { InjectList } from '../core/injectable';

export class HarvestFilesService implements PaginatableService<HarvestFile> {
  static $inject: InjectList = ['$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'files';

  constructor(
    private $q: ng.IQService,
    private SoracomApi: LegacyBaseSoracomApiService,
    private paginationService: PaginationService,
  ) {
    // no-op
  }

  list(paginationOptions: PaginationOptions, searchQuery?: SearchQuery | undefined): Promise<ScRelation<HarvestFile>> {
    const path = this.getPath(searchQuery);
    const apiParams = {
      method: 'get',
      path: `/v1/files/private${path}`,
      query: {
        limit: paginationOptions.limit,
        last_evaluated_key: paginationOptions.last_evaluated_key,
      },
    };

    // @ts-expect-error (legacy code incremental fix)
    return this.SoracomApi.callApiWithToken(apiParams)
      .then((response: LegacyAny) => {
        if (Array.isArray(response.data)) {
          const data = response.data.map((element: LegacyAny) => new HarvestFile(element, path));
          const relation: ScRelation<HarvestFile> = {
            data,
            links: this.paginationService.getPaginationLinks(response.headers.link),
          };

          return this.$q.resolve(relation);
        } else {
          const relation: ScRelation<HarvestFile> = {
            data: [],
            links: this.paginationService.getPaginationLinks(response.headers.link),
          };

          return this.$q.resolve(relation);
        }
      })
      .catch((response: LegacyAny) => {
        // For user-friendly,
        if (response.data?.code === 'FEM0006') {
          response.data.message = response.data.message.replace('ファイル', 'フォルダ');
          response.data.message = response.data.message.replace('file', 'directory');
        }
        return this.$q.reject(response);
      });
  }

  get(file: HarvestFile) {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: `/v1/files/private${HarvestFile.formatPath(file.path)}`,
      accept: file.contentType,
      responseType: 'arraybuffer',
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      this.saveFileAs(response, file);
      return;
    });
  }

  getMetadata(filePath: string) {
    const path = `/v1/files/private${filePath}`;
    const apiParams = {
      method: 'head',
      path,
    };

    return this.SoracomApi.callApiWithToken(apiParams);
  }

  // This API isn't used when uploading file.
  // Please see upload_harvest_file.modal.component.ts in detail.
  upload(filePath: string, body: any) {
    const path = `/v1/files/private${HarvestFile.formatPath(filePath, { encode: true })}`;
    const apiParams = {
      method: 'post',
      path,
      body,
    };

    return this.SoracomApi.callApiWithToken(apiParams);
  }

  delete(file: HarvestFile) {
    const path = `/v1/files/private${HarvestFile.formatPath(file.path)}`;
    const apiParams = {
      method: 'delete',
      path,
    };

    return this.SoracomApi.callApiWithToken(apiParams);
  }

  private getPath(searchQuery: SearchQuery | undefined) {
    const pathQuery = searchQuery?.queryItems?.find((item) => item.key === 'path');
    return HarvestFile.formatPath(`${pathQuery?.value || ''}/`);
  }

  private saveFileAs(response: LegacyAny, file: HarvestFile) {
    const fileName = file.filename;
    const blob = new Blob([response.data]);
    saveAs(blob, fileName);
  }
}
