import { LegacyAny } from '@soracom/shared/core';
import { TagParams } from '@user-console/legacy-soracom-api-client';
import { LegacyUibModalAdapterModalBase } from '@soracom/shared-ng/legacy-uib-modal-adapter';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FocusMeDirective } from '@soracom/shared-ng/ui-common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-add-tag-modal',
  templateUrl: './add_tag.modal.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, FocusMeDirective, FormsModule],
})
export class AddTagModalComponent
  extends LegacyUibModalAdapterModalBase<{ params: () => { currentTags: Record<string, any> } }, TagParams>
  implements OnInit
{
  currentTags: LegacyAny;
  // @ts-expect-error (legacy code incremental fix)
  submitting: boolean;
  tagParams: TagParams = {
    tagName: '',
    // @ts-expect-error (legacy code incremental fix)
    tagValue: null,
  };

  ngOnInit() {
    this.currentTags = this.resolve?.params().currentTags || {};
  }

  canSubmit() {
    if (!this.tagParams.tagName || !this.tagParams.tagValue) {
      return false;
    }

    if (this.currentTags[this.tagParams.tagName] !== undefined) {
      return false;
    }

    return true;
  }

  submit() {
    this.close(this.tagParams);
  }

  cancel() {
    this.close();
  }
}
