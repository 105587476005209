import { LegacyAny } from '@soracom/shared/core';

import { AlertsService, AlertsServiceInstance } from '../../components/alerts.service';
import { BaseModalController } from '../../components/base_modal_controller';
import { DeviceObjectModel } from '../../core/device_object_model';
import { InjectList } from '../../core/injectable';
import { DeviceObjectModelsService } from '../device_object_models.service';
import { template } from './add_device_object_model_modal.component.html';

const sampleJson = `{
  "id": 31024,
  "name": "Sample",
  "description": "Sample object",
  "multiple": true,
  "mandatory": false,
  "resources": {
    "1": {
      "id": 1,
      "name": "test",
      "operations": "RW",
      "multiple": false,
      "mandatory": true,
      "type": "INTEGER",
      "rangeEnumeration": "0-255",
      "units": "",
      "description": "1st resource in sample object"
    },
    "2": {
      "id": 2,
      "name": "exec",
      "operations": "E",
      "multiple": false,
      "mandatory": true,
      "type": "STRING",
      "rangeEnumeration": "",
      "units": "",
      "description": "2nd resource in sample test object"
    },
    "3": {
      "id": 3,
      "name": "dec",
      "operations": "RW",
      "multiple": false,
      "mandatory": true,
      "type": "FLOAT",
      "rangeEnumeration": "",
      "units": "",
      "description": "3rd resource in sample test object"
    }
  }
}`;

const sampleXml = `<?xml version="1.0" encoding="utf-8"?>

<LWM2M  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="http://openmobilealliance.org/tech/profiles/LWM2M.xsd">

    <Object ObjectType="MODefinition">
        <Name>Sample</Name>
        <Description1>Sample object</Description1>
        <ObjectID>31024</ObjectID>
        <ObjectURN>urn:oma:lwm2m:ext:31024</ObjectURN>
        <MultipleInstances>Multiple</MultipleInstances>
        <Mandatory>Optional</Mandatory>
        <Resources>
            <Item ID="1">
                <Name>test</Name>
                <Operations>RW</Operations>
                <MultipleInstances>Single</MultipleInstances>
                <Mandatory>Mandatory</Mandatory>
                <Type>Integer</Type>
                <RangeEnumeration>0-255</RangeEnumeration>
                <Units></Units>
                <Description>1st resource in sample object</Description>
            </Item>
            <Item ID="2">
                <Name>exec</Name>
                <Operations>E</Operations>
                <MultipleInstances>Single</MultipleInstances>
                <Mandatory>Mandatory</Mandatory>
                <Type>String</Type>
                <RangeEnumeration></RangeEnumeration>
                <Units></Units>
                <Description>2nd resource in sample object</Description>
            </Item>
            <Item ID="3">
                <Name>dec</Name>
                <Operations>RW</Operations>
                <MultipleInstances>Single</MultipleInstances>
                <Mandatory>Mandatory</Mandatory>
                <Type>Float</Type>
                <RangeEnumeration></RangeEnumeration>
                <Units></Units>
                <Description>3rd resource in sample object</Description>
            </Item>
        </Resources>
        <Description2></Description2>
    </Object>
</LWM2M>`;
export class AddDeviceObjectModelModalComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = AddDeviceObjectModelModalController;
  template: any = template;
}

class AddDeviceObjectModelModalController extends BaseModalController {
  static $inject: InjectList = ['$log', '$uibModal', 'AlertsService', 'DeviceObjectModelsService'];

  /** After init, this is the **instance** of AlertsService that can be used to display errors. */
  // @ts-expect-error (legacy code incremental fix)
  alertsService: AlertsServiceInstance = null;

  // @ts-expect-error (legacy code incremental fix)
  model: DeviceObjectModel;
  // This should be string, but I can't compile when it is defined as string
  schema: LegacyAny;
  formatOptions = ['json', 'xml'];

  get aceOption() {
    const format = (this.model && this.model.format) || 'xml';
    return {
      mode: format,
      useWrapMode: true,
      showGutter: true,
      enableBasicAutocompletion: true,
      enableSnippets: true,
      enableLiveAutocompletion: false,
    };
  }

  constructor(
    private $log: ng.ILogService,
    private $uibModal: LegacyAny,
    private AlertsService: AlertsService,
    private DeviceObjectModelsService: DeviceObjectModelsService
  ) {
    super($log);
    this.setTraceEnabled(true);
  }

  $onInit() {
    this.alertsService = this.AlertsService.generate();
    this.model = this.resolve.model;
    this.schema = this.resolve.schema;
    this.updateSchema(this.model.format);
  }

  updateSchema(format: string) {
    // Replace text if the object is not created.
    if (!this.model.id) {
      if (format === 'xml') {
        this.schema = sampleXml;
      } else if (format === 'json') {
        this.schema = sampleJson;
      }
    }
  }

  canSubmit(): boolean {
    return true;
  }

  cancel() {
    this.dismiss();
  }

  submit() {
    if (this.model.id === null || this.model.id === undefined) {
      this.DeviceObjectModelsService.create(this.model, this.schema)
        .then((model: LegacyAny) => {
          this.close({ model });
        })
        .catch((error: LegacyAny) => {
          this.alertsService.showError(error);
        });
    } else {
      this.DeviceObjectModelsService.update(this.model, this.schema)
        .then((model: LegacyAny) => {
          this.close({ model });
        })
        .catch((error: LegacyAny) => {
          this.alertsService.showError(error);
        });
    }
  }
}
