<div class="ds-group" [formGroupName]="fgName">
  <legend class="ds-group__title">{{ i18nPrefix + '.title' | translate }}</legend>
  <div class="ds-field">
    <label for="duration" class="ds-label">{{ i18nPrefix + '.duration.title' | translate }}</label>
    <div class="ds-input --icon-time">
      <input type="number" id="duration" name="duration" formControlName="duration" />
      <div class="ds-text --addon-light">{{ i18nPrefix + '.duration.units.minute' | translate }}</div>
    </div>
  </div>
  <div>{{ i18nPrefix + '.duration.description' | translate }}</div>
  <hr />
  <div class="ds-field">
    <label for="" class="ds-label">{{ i18nPrefix + '.date.title' | translate }}</label>
    <ui-checkbox [modelName]="i18nPrefix" name="noDateLimit" [group]="formGroup"></ui-checkbox>
  </div>
  <div class="ds-field">
    <div>
      <input
        type="date"
        id="startDate"
        name="startDate"
        class="ds-input--icon-calendar-dates"
        formControlName="startDate"
      />
    </div>
    <i class="ds-icon--icon-arrow-right"></i>
    <div>
      <input type="date" id="endDate" name="endDate" class="ds-input--icon-calendar-dates" formControlName="endDate" />
    </div>
  </div>
  <div class="ds-notice--warning" *ngIf="hasDateError()">
    <ul>
      <li *ngFor="let errorKey of dateErrors">
        {{ i18nPrefix + '.date.' + errorKey | translate }}
      </li>
    </ul>
  </div>
  <div class="ds-field">
    <label for="noWeekdayLimit" class="ds-label">{{ i18nPrefix + '.weekdays.title' | translate }}</label>
    <ui-checkbox [modelName]="i18nPrefix" name="noWeekdayLimit" [group]="formGroup"></ui-checkbox>
  </div>
  <div class="ui-checkbox--weekdays" formGroupName="weekdays">
    <label class="ds-checkbox ds-checkbox--dots">
      <input type="checkbox" formControlName="SUN" />
      <div class="ds-checkbox__label">{{ i18nPrefix + '.weekdays.labels.SUN' | translate }}</div>
    </label>
    <label class="ds-checkbox ds-checkbox--dots">
      <input type="checkbox" formControlName="MON" />
      <div class="ds-checkbox__label">{{ i18nPrefix + '.weekdays.labels.MON' | translate }}</div>
    </label>
    <label class="ds-checkbox ds-checkbox--dots">
      <input type="checkbox" formControlName="TUE" />
      <div class="ds-checkbox__label">{{ i18nPrefix + '.weekdays.labels.TUE' | translate }}</div>
    </label>
    <label class="ds-checkbox ds-checkbox--dots">
      <input type="checkbox" formControlName="WED" />
      <div class="ds-checkbox__label">{{ i18nPrefix + '.weekdays.labels.WED' | translate }}</div>
    </label>
    <label class="ds-checkbox ds-checkbox--dots">
      <input type="checkbox" formControlName="THU" />
      <div class="ds-checkbox__label">{{ i18nPrefix + '.weekdays.labels.THU' | translate }}</div>
    </label>
    <label class="ds-checkbox ds-checkbox--dots">
      <input type="checkbox" formControlName="FRI" />
      <div class="ds-checkbox__label">{{ i18nPrefix + '.weekdays.labels.FRI' | translate }}</div>
    </label>
    <label class="ds-checkbox ds-checkbox--dots">
      <input type="checkbox" formControlName="SAT" />
      <div class="ds-checkbox__label">{{ i18nPrefix + '.weekdays.labels.SAT' | translate }}</div>
    </label>
  </div>
  <div class="ds-field">
    <label for="noTimeLimit" class="ds-label">{{ i18nPrefix + '.time.title' | translate }}</label>
    <ui-checkbox [modelName]="i18nPrefix" name="noTimeLimit" [group]="formGroup"></ui-checkbox>
  </div>
  <div class="ds-field">
    <div>
      <input type="time" id="startTime" name="startTime" class="ds-input--icon-time" formControlName="startTime" />
    </div>
    <i class="ds-icon--icon-arrow-right"></i>
    <div>
      <input type="time" id="endTime" name="endTime" class="ds-input--icon-time" formControlName="endTime" />
    </div>
  </div>

  <div>{{ i18nPrefix + '.time.description' | translate }}</div>
  <div class="ds-notice--warning" *ngIf="hasInvalidTimeRangeError()">
    {{ i18nPrefix + '.time.invalidTimeRange' | translate }}
  </div>
  <ng-content></ng-content>
</div>
