import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-logout',
  templateUrl: './support-logout.component.html',
})
export class SupportLogoutComponent implements OnInit {
  ngOnInit() {
    setTimeout(() => {
      window.close();
    });
  }
}
