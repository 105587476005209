import { LegacyAny } from '@soracom/shared/core';

import { assertNever } from '@soracom/shared/core';
import { SearchCondition } from '../../../src/app/shared/SearchCondition';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { InjectList } from '../core/injectable';
import { GroupsService } from '../groups/groups.service';
import { SubscriberEnumerator, SubscriberEnumeratorBase } from './enumerators/subscriber_enumerator_base';
import { SubscriberEnumeratorByGroupId } from './enumerators/subscriber_enumerator_by_group_id';
import { SimsService } from './sims.service';
import { SubscriberFilter } from './SubscriberFilter';
import { SubscribersService } from './subscribers.service';
import { SubscriberSearchSessionStatusFactory } from './subscriber_search_session_status';

export interface SubscriberEnumeratorsFactoryInterface {
  createDefault(filters?: SubscriberFilter): SubscriberEnumerator;
  createUnified(condition: SearchCondition): SubscriberEnumerator;
  create(
    searchCategoryId: string,
    searchKeywords: string,
    onlineOnly: boolean,
    offlineOnly: boolean,
    filters?: SubscriberFilter
  ): SubscriberEnumerator;
}

/**
 * All possible values for search category ID (selectable from menu in UI).
 */
export const SubscriberSearchCategory = {
  // @masonmark 2023-09-14: all other type shave been removed but there is legacy code still relying on this last one:
  groupId: 'subscribers.search_category.group_id',
} as const;

export type SubscriberSearchCategoryId = typeof SubscriberSearchCategory[keyof typeof SubscriberSearchCategory];

export const isValidSubscriberSearchCategoryId = (s: any): s is SubscriberSearchCategoryId => {
  return typeof s === 'string' && Object.values(SubscriberSearchCategory).includes(s as any);
};

export class SubscriberEnumeratorsFactory implements SubscriberEnumeratorsFactoryInterface {
  static $inject: InjectList = [
    '$log',
    '$q',
    '$translate',
    'GroupsService',
    'SubscribersService',
    'CoverageTypeService',
    'SimsService',
  ];

  constructor(
    private $log: ng.ILogService,
    private $q: ng.IQService,
    private $translate: any,
    private groupsService: GroupsService,
    private subscribersService: SubscribersService,
    private coverageTypeService: CoverageTypeService,
    private simService: SimsService
  ) {}

  createUnified(condition: SearchCondition): SubscriberEnumerator {
    throw new Error('Method not implemented.');
  }

  createDefault(filters?: SubscriberFilter): SubscriberEnumerator {
    return new SubscriberEnumeratorBase(this.$log, this.$q, this.$translate, this.subscribersService, filters);
  }

  create(
    searchCategoryId: SubscriberSearchCategoryId,
    searchKeywords: string,
    onlineOnly: boolean,
    offlineOnly: boolean,
    filters?: SubscriberFilter
  ): SubscriberEnumerator {
    const subscriberSearchSessionStatus = SubscriberSearchSessionStatusFactory.createFrom(onlineOnly, offlineOnly);

    // FIXME: these case values should really be defined as a union type, and this switch should either use assertNever() to throw the error, OR should reset the value in sessionStorage (UCStorage.searchKeywords) to default.
    switch (searchCategoryId) {
      case SubscriberSearchCategory.groupId:
        return new SubscriberEnumeratorByGroupId(
          this.$log,
          this.$q,
          this.$translate,
          this.subscribersService,
          this.groupsService,
          searchKeywords,
          filters,
          this.coverageTypeService,
          subscriberSearchSessionStatus
        );

      default:
        // Cause a pre-build code-time error if we forget a valid search category ID here:
        assertNever(searchCategoryId);
        throw new Error(`Invalid search category ID: ${searchCategoryId}`);
    }
  }
}
