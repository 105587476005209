import * as angular from 'angular';
import { BaseObject } from './base_object';

/**
 * BaseController is a low-level object from which various
 * concrete controllers may inherit.
 *
 * Mason 2017-08-24: This class is intended to be a
 * base class for different kinds of controllers (directive
 * controllers, component controllers, hoge controllers...)
 * so only truly generic functionality belongs here. Basically
 * so far that's just logging, so all this class does at
 * present is extend BaseObject (which provides logging).
 */
export class BaseController extends BaseObject {
  constructor($log: ng.ILogService) {
    super($log);
  }

  /*--------------------------------------------------------------------------------
    MENU SUPPORT

    Generic functionality to manage mapping arrays of objects to menu data that is
    easy to use within UI template code. This may be used by components and
    directives that need to display menus, using e.g. <select> and <option> , <li>
    with Bootstrap, or really any kind of mechanism that needs to map a UI menu to
    a list of arbitrary objects.

    This should make it easy for subclasses to provide menus corresponding to any
    kind of object, using the same convention.

  --------------------------------------------------------------------------------*/

  /**
   * Fetches, updates if necessary, and returns menu content (i.e. `MenuItem[]`) based on the
   * given source.
   *
   * The source would typically be an array of some type of object, enum value, or similar.
   *
   * The returned menu content array instance is always the same instance (to play nice
   * with Angular bindings and ng-repeat). However its _contents_ will be updated to reflect
   * the current contents of `source`. The `transformer` function passed in defines how the
   * source objects are converted to `MenuItem` form.
   *
   * @param identifier  any unique string
   * @param source      the array of values (of whatever type) that the menu describes
   * @param transformer a `MenuTransformer` to turn the source values into `MenuItem` values
   */
  getMenuContent(identifier: string, source: any[], transformer: MenuTransformer) {
    if (!this.menuContent) {
      this.menuContent = {};
    }

    if (!this.menuContent[identifier]) {
      this.menuContent[identifier] = [];
    }

    const content = this.buildMenuContentFromSource(source, transformer);

    if (!angular.equals(this.menuContent[identifier], content)) {
      angular.copy(content, this.menuContent[identifier]);
    }
    return this.menuContent[identifier];
  }

  private buildMenuContentFromSource(src: any[], transformer: MenuTransformer) {
    const result: MenuItem[] = [];
    for (const x of src) {
      result.push(transformer(x));
    }
    return result;
  }

  /** The menuContent property defines storage for the contents of menus, as
   * managed by `getMenuContent()`.
   */
  // @ts-expect-error (legacy code incremental fix)
  private menuContent: { [identifier: string]: MenuItem[] };
}

/**
 * A simple label-value pair type for menu items. This could be e.g. a `<select>` tag's
 * `<option>`, an `<li>`, or whatever -- anything that has a title (possibly localized)
 * to be displayed in the UI, and a corresponding value (which could be anything).
 */
export interface MenuItem {
  label: string;
  value: any;
}

/**
 * The MenuTransformer type defines a function that takes an arbitrary value,
 * and returns an object of type `{ label: string, value: any }`, which is
 * frequently-used to build menus using `ng-repeat`.
 */
export type MenuTransformer = (some: any) => MenuItem;
