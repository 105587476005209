import { LegacyAny } from '@soracom/shared/core';

import { BaseController } from '../components/base_controller';
import { KeyEventService } from '../components/key_event_service';
import { SoracomApiService } from '../components/soracom_api.service';
import { ContactInformation } from '../contact_information/contact_information';
import { InjectList } from '../core/injectable';
import { ModelState } from '../core/model_state';
import { template } from './debug.component.html';

/**
 * A debug UI to make it easier to prototype and develop UI designs,
 * and test new features before final implementation.
 */
export class DebugComponent implements ng.IComponentOptions {
  bindings = {
    favoriteColor: '@',
    // Just an example. This is a root component so it normally
    // wouldn't have any bindings. But I hardcoded this attribute
    // in app.route.js just to prove this worked...
  };
  controller = DebugComponentController;
  template: any = template;
}

export class DebugComponentController extends BaseController {
  favoriteColor?: string;

  private defaultFavoriteColor = 'pink';

  /**
   *  To test components designed to be shown in modals (which normally are bound
   *  to a 'resolve' object supplied by $uibModal after its initialization).
   */
  resolves = {
    'sc-beam-sms-entry-point-editor-component': { params: {} },
  };

  contactInformation = new ContactInformation();

  static $inject: InjectList = [
    '$log',
    '$rootScope',
    '$timeout',
    '$uibModal',
    'KeyEventService',
    'PostalAddressService',
    'SoracomApi',
  ];

  constructor(
    private $log: ng.ILogService,
    private $timeout: ng.ITimeoutService,
    private $rootScope: any, // needed so we can use apply() after JS event listener callback
    private $uibModal: any,

    private KeyEventService: KeyEventService,
    private SoracomApi: SoracomApiService
  ) {
    super($log);
    this.contactInformation.modelState = ModelState.Ready;
  }

  $onInit() {
    if (!this.favoriteColor) {
      this.favoriteColor = this.defaultFavoriteColor;
    }

    this.debug(`HELLO, I am DebugComponent. My favorite color is ${this.favoriteColor}`);
  }

  inputForTestBro = 'input, bro';

  testBro() {
    this.debug('testBro()');

    this.SoracomApi.reportLocalInfo(this.inputForTestBro)
      .then((response: LegacyAny) => {
        this.debug(response);
        // debugger;
      })
      .catch((err: LegacyAny) => {
        this.warn(err);
        // debugger;
      });
  }

  dataForKryptonConfigEditor = {
    operatorId: 'OP0043429948',
    groupId: 'f9b89206-faf8-414c-8648-45e42c1939e3',
    createdAt: 1529472163440,
    lastModifiedAt: 1529472746181,
    configuration: {
      SoracomAir: {
        slsDisabled: false,
        userdata: '',
        binaryParserEnabled: false,
        metadata: { enabled: false, readonly: true, allowOrigin: '' },
        useCustomDns: false,
        vpgId: '',
        dnsServers: [],
        useVpg: false,
      },
    },
    tags: { name: 'stink head' },
    createdTime: 1529472163440,
    lastModifiedTime: 1529472746181,
  };

  // ------------------------------------------------------
  // sc-on-return tests
  // ------------------------------------------------------
  private testScOnReturnCount = 0;
  shouldReactToScOnReturnEvents = false;
  testScOnReturnStatusMessage = 'Return keypress count: ' + this.testScOnReturnCount;

  testScOnReturnKeypressHandler() {
    if (!this.shouldReactToScOnReturnEvents) {
      return;
    }

    this.testScOnReturnCount += 1;
    this.testScOnReturnStatusMessage = 'Return keypress count: ' + this.testScOnReturnCount;
    this.$rootScope.apply();
    // This last call forces the UI to update (which it wouldn't otherwise, because the
    // event that triggers the callback comes from "outside Angular").
  }

  // ------------------------------------------------------
  // sc-modal-default-action tests
  // ------------------------------------------------------

  private testScModalDefaultActionCount = 0;

  showTestModalForScModalDefaultAction() {
    this.testScModalDefaultActionCount = 0;

    const owningController = this;
    const $log = this;

    class TestModalForScModalDefaultAction {
      doDefaultAction() {
        owningController.testScModalDefaultActionCount += 1;
        owningController.$rootScope.apply();
        // force Angular UI refresh after non-Angular callback

        const kReturnCountKey = 'soracom_user_console_test_sc_modal_default_action_return_key_count';
        // @ts-expect-error (legacy code incremental fix) FIXME: DELETE THIS COMPONENT
        window[kReturnCountKey] = (window[kReturnCountKey] || 0) + 1;
        // To share data with external test process, we use this global property of window.
        // Mason 2019-05-29: FIXME: move this feature to the new SoracomUserConsole singleton, where we now want to consolidate all this kind of "debug functionality that is exposed to tests/developers via the Window object" stuff.
      }

      getCount() {
        return owningController.testScModalDefaultActionCount;
      }
    }

    const template = `
      <h1>hoge</h1>
      <p>
        Modal default action count:
        <span id='modal-default-action-count'>{{ $ctrl.getCount() }}<span>
      </p>
      <button sc-modal-default-action ng-click="$ctrl.doDefaultAction()">
        DEFAULT BUTTON
      </button>
    `;

    this.$uibModal
      .open({
        template,
        controller: TestModalForScModalDefaultAction,
        controllerAs: '$ctrl',
        backdrop: 'static',
      })
      .result.then();
  }

  // ------------------------------------------------------
  // Manual debugging aids
  // ------------------------------------------------------

  debugPrintKeyEventService() {
    this.KeyEventService.debugPrintKeyEventService(true);
  }
}
