import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { UCStorage } from '../shared/UCStorage';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: ``,
})
export class SubscribersWrapperComponent {
  constructor(private router: Router) {
    let showNewUiIntroModal =
      UCStorage.showNewSimsIntroModalAfterRedirectFromSubscribers !== undefined &&
      UCStorage.showNewSimsIntroModalAfterRedirectFromSubscribers !== null
        ? UCStorage.showNewSimsIntroModalAfterRedirectFromSubscribers
        : true;

    this.router.navigate(['/sims'], { queryParamsHandling: 'preserve', state: { showNewUiIntroModal } });
  }
}
