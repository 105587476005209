import { BaseModalController } from '../../components/base_modal_controller';
import { DeviceObjectModel } from '../../core/device_object_model';
import { InjectList } from '../../core/injectable';
import { template } from './delete_device_object_model_modal.component.html';

export class DeleteDeviceObjectModelModalComponent implements ng.IComponentOptions {
  bindings = {
    modalInstance: '<',
    resolve: '<',
  };
  controller = DeleteDeviceObjectModelModalController;
  template: any = template;
}

class DeleteDeviceObjectModelModalController extends BaseModalController {
  static $inject: InjectList = ['$log'];

  // @ts-expect-error (legacy code incremental fix)
  model: DeviceObjectModel;

  constructor(private $log: ng.ILogService) {
    super($log);
    this.setTraceEnabled(true);
  }

  $onInit() {
    this.model = this.resolve.model;
  }

  cancel() {
    this.dismiss();
  }

  submit() {
    this.close();
  }
}
