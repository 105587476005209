<div class="ds-rows">
  <ng-container *ngIf="hasSubscription">
    <h2>{{ 'LagoonComponent.title' | translate }}</h2>
    <ng-container>
      <app-alerts [alertsManager]="alertsManager"></app-alerts>
    </ng-container>
    <div class="ds-text" [innerHTML]="'LagoonComponent.description' | translate"></div>
    <ng-container *ngIf="enabledLagoonV3">
      <div class="ds-notice --info">
        <h4>{{ 'LagoonComponent.new_version_is_available' | translate }}</h4>
        <p [innerHTML]="'LagoonComponent.new_version_detail' | translate"></p>
        <p *ngIf="enabledBothVersions" [innerHTML]="'LagoonComponent.new_version_detail_for_existing_users' | translate"></p>
      </div>
    </ng-container>
    <app-harvest-files-migration [alertsManager]="alertsManager"></app-harvest-files-migration>
    <div>
      <ng-container *appNoFeature="'lagoon3'">
        <a class="ds-button --right-icon --icon-external-link" [href]="lagoon2Url()" target="_blank">
          <span translate="LagoonComponent.link_to_lagoon_2"></span>
        </a>
      </ng-container>
      <ng-container *appFeature="'lagoon3'">
        <a class="ds-button --right-icon --icon-external-link" [href]="lagoon2Url()" target="_blank"
          *ngIf="enabledLagoonV2">
          <span translate="LagoonComponent.link_to_lagoon_2"></span>
        </a>
        <a class="ds-button --right-icon --icon-external-link" [href]="lagoon3Url()" target="_blank"
          *ngIf="enabledLagoonV3">
          <span translate="LagoonComponent.link_to_lagoon_3"></span>
        </a>
      </ng-container>
    </div>
  </ng-container>
  <div class="text-center" *ngIf="!hasSubscription">
    <div class="service-icon-wrapper"><i class="ds-icon --icon-soracom-lagoon --massive"></i></div>
    <div class="service-title" [innerHTML]="'LagoonComponent.no_subscription' | translate"></div>
    <div class="service-description" [innerHTML]="'LagoonComponent.description' | translate"></div>
    <div class="service-actions">
      <button class="ds-button--primary" (click)="openRegisterLagoonModal()" [disabled]="!canTryLagoon()">
        <span translate="LagoonComponent.register"></span>
      </button>
    </div>
    <div class="service-links ds-cols --center">
      <a [href]="'service_descriptions.harvest.links.overview' | translate" class="ds-text --icon-external-link" target="_blank">
        <span>{{ 'service_descriptions.harvest.name' | translate }}</span>
      </a>
      <a [href]="'service_descriptions.lagoon.links.overview' | translate" class="ds-text --icon-external-link" target="_blank">
        <span>{{ 'service_descriptions.lagoon.name' | translate }}</span>
      </a>
    </div>
  </div>
</div>
