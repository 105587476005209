import { LegacyAny } from '@soracom/shared/core';

import { ActionReducerMap, combineReducers } from '@ngrx/store';

import { Group } from '@soracom/shared/group';

import * as actions from './actions';
import * as s from './state';

function getStatus(path: string, imsiList: string[] | null, newGroupName: string | null): s.GpsMultiunitPageState {
  if (path === 'new') {
    if (imsiList !== null && imsiList.length > 0) {
      if (newGroupName) {
        return 'newConfig';
      } else {
        return 'selectGroup';
      }
    } else {
      return 'selectSubscribers';
    }
  } else if (path === 'index') {
    return 'index';
  } else if (path === 'edit') {
    return 'editConfig';
  } else {
    return 'unknown';
  }
}

function indexPageReducer(state = s.initialIndexPageState, action: actions.ActionsUnion): s.GpsMultiunitIndexPageState {
  switch (action.type) {
    case actions.navigateToIndexPage.type:
      return {
        ...s.initialIndexPageState,
      };
    case actions.loadConfiguredSubscribers.type:
      return {
        ...state,
        loadingRequired: true,
      };
    case actions.loadConfiguredSubscribersSuccess.type:
      return {
        ...state,
        loadingRequired: false,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}

function newPageReducer(state = s.initialNewPageState, action: actions.ActionsUnion): s.GpsMultiunitNewPageState {
  switch (action.type) {
    // Step 1. Select subscribers from available subscribers
    case actions.navigateToSelectSubscribersPage.type:
      return {
        ...s.initialNewPageState,
      };

    // Step 2. Select group
    case actions.navigateToSelectGroupPage.type:
      return {
        ...s.initialNewPageState,
      };
    case actions.loadAvailableSubscribersSuccess.type:
      return {
        ...state,
        availableSubscribers: {
          error: null,
        },
      };
    case actions.loadAvailableSubscribersError.type:
      return {
        ...state,
        availableSubscribers: {
          error: action.error,
        },
      };
    case actions.loadSelectedSubscribers.type:
      return {
        ...state,
        selectedSubscribers: {
          data: [],
          error: null,
          loading: true,
        },
      };
    case actions.loadSelectedSubscribersError.type:
      return {
        ...state,
        selectedSubscribers: {
          data: [],
          error: action.error,
          loading: false,
        },
      };
    case actions.loadSelectedSubscribersSuccess.type:
      return {
        ...state,
        selectedSubscribers: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };

    default: {
      return {
        ...state,
      };
    }
  }
}

function editPageReducer(state = s.initialEditPageState, action: actions.ActionsUnion): s.GpsMultiunitEditPageState {
  let group: s.GroupOrGroupParam;
  switch (action.type) {
    // Case 1. Edit new group configuration
    case actions.initializeGroup.type:
      group = { name: action.name };
      return {
        ...state,
        group: {
          data: group,
          error: null,
          loading: false,
        },
      };

    // Case 2. Load existing group configuration and edit it
    case actions.loadGroup.type:
      return {
        ...state,
        group: {
          data: null,
          error: null,
          loading: true,
        },
      };
    case actions.loadGroupError.type:
      return {
        ...state,
        group: {
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case actions.loadGroupSuccess.type:
      return {
        ...state,
        group: {
          data: action.group,
          error: null,
          loading: false,
        },
      };

    default: {
      return {
        ...state,
      };
    }
  }
}

function routeReducer(state = s.initialState.routing, action: actions.ActionsUnion): s.GpsMultiunitPageRouteState {
  switch (action.type) {
    case actions.applyRouteParameters.type:
      const status = getStatus(action.path, action.imsiList, action.newGroupName);
      return {
        ...state,
        status,
        // @ts-expect-error (legacy code incremental fix)
        imsiList: action.imsiList,
        groupId: action.groupId,
      };
    case actions.navigateToIndexPage.type:
      // Reset state
      return {
        ...s.initialRoutingState,
      };
    case actions.configureNewDevice.type:
      return {
        ...state,
        status: 'selectSubscribers',
      };
    case actions.navigateToSelectSubscribersPage.type:
      return {
        ...s.initialRoutingState,
        status: 'selectSubscribers',
      };
    case actions.navigateToSelectGroupPage.type:
      return {
        status: 'selectGroup',
        groupId: null,
        imsiList: state.imsiList,
        newGroupName: null,
      };
    case actions.selectGroup.type:
      return {
        ...state,
        status: 'selectGroup',
        imsiList: action.imsiList,
      };
    case actions.newConfiguration.type:
      return {
        ...state,
        status: 'newConfig',
        newGroupName: action.newGroupName,
      };
    case actions.editGpsMultiunitConfiguration.type:
      return {
        ...state,
        status: 'editConfig',
        groupId: action.groupId,
      };
    default:
      return {
        ...state,
      };
  }
}

const _featureReducer: ActionReducerMap<s.State> = {
  // @ts-expect-error (legacy code incremental fix)
  routing: routeReducer,
  // @ts-expect-error (legacy code incremental fix)
  index: indexPageReducer,
  // @ts-expect-error (legacy code incremental fix)
  new: newPageReducer,
  // @ts-expect-error (legacy code incremental fix)
  edit: editPageReducer,
};

const metaReducer = combineReducers(_featureReducer, s.initialState);

export function featureReducer(state: LegacyAny, action: LegacyAny) {
  return metaReducer(state, action);
}
