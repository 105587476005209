import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { Action } from '@soracom/shared-ng/util-common';

class NullAction implements Action<unknown> {
  protected logger: Logger;

  constructor() {
    this.logger = LoggerService.shared();
  }

  actionable(_targets: Array<unknown>) {
    return false;
  }

  run() {
    this.logger.debug('NullAction has been invoked.');
  }
}

export class ScActionContainer<T> {
  private logger: Logger;
  private nullAction: NullAction;
  private _actions: { [id: string]: Action<T> } = {};

  constructor() {
    this.nullAction = new NullAction();
    this.logger = LoggerService.shared();
  }

  set actions(actions: { [id: string]: Action<T> }) {
    this._actions = actions;
  }

  get actions() {
    return this._actions;
  }

  actionable(actionName: string, target: T): boolean {
    // @ts-expect-error (legacy code incremental fix)
    return this.getAction(actionName).actionable(target);
  }

  doAction(actionName: string, target: T): void {
    const action = this.getAction(actionName);
    // @ts-expect-error (legacy code incremental fix)
    if (action.actionable(target)) {
      action.run(target);
    }
  }

  private getAction(actionName: string): Action<any> {
    const action = this._actions[actionName];
    if (action) {
      return action;
    } else {
      this.logger.debug(`Action '${actionName}' is not found`);
      return this.nullAction;
    }
  }
}
