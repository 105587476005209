import { LegacyAny } from '@soracom/shared/core';

import { BaseEditTagController } from '../components/base_edit_tag.controller';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import { assignGroup } from '../components/GroupFetcher';
import {
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { ChangeGroupAction } from '../core/actions/ChangeGroupAction';
import { ChangeTerminationProtectionAction } from '../core/actions/change_termination_protection_action';
import { DataAction } from '../core/actions/data_action';
import { EditTagsAction } from '../../../src/app/ng-rewrites/legacy-actions/edit_tags_action';
import { LogsAction } from '../core/actions/LogsAction';
import { ReloadTableAction } from '../core/actions/ReloadTableAction';
import { TerminateResourceAction } from '../core/actions/terminate_resource_action';
import { Group } from '@soracom/shared/group';
import { InjectList } from '../core/injectable';
import { SigfoxDevice } from '../core/sigfox_device';
import { template } from './sigfox_devices.component.html';
import { SigfoxDevicesService } from './sigfox_devices.service';
import {
  DownlinkSigfoxDeviceAction,
  RegisterSigfoxDeviceAction,
  ShowPacSigfoxDeviceAction,
} from './sigfox_devices_action';

import { enumValues } from '../../../src/app/core/util/enumValues';
import { SearchQuery } from '@user-console/legacy-soracom-api-client';
import { SigfoxSearchableField } from '../../../src/app/sigfox-search/SigfoxSearchableField';
import { SigfoxSearchPredicate } from '../../../src/app/sigfox-search/SigfoxSearchPredicate';
import { PredicateTest } from '../../../src/app/soracom-ui/ui-predicate-editor/PredicateTest';
import { SearchContext } from '../../../src/app/soracom-ui/ui-search/ui-search.component';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

export class SigfoxDevicesComponent implements ng.IComponentOptions {
  // Requirements for AngularJS component to be compatible with Angular/ngUpgrade:
  // https://angular.io/guide/upgrade#using-component-directives

  restrict = 'E';
  scope = {};
  bindToController = true;

  // Mason 2019-03-13: FIXME: I think the above is unnecessary because AngularJS 1.5+ components do that for us

  bindings = {};
  controller = SigfoxDevicesComponentController;
  template: any = template;
}

export class SigfoxDevicesComponentController extends BasePaginatableTableController<SigfoxDevice> {
  /**
   * For now (and maybe forever) we do not display Angular components on MSIE browser.
   */
  browserIsBroken = /msie\s|trident\//i.test(window.navigator.userAgent);
  editable = true;
  searchContext: SearchContext = {
    defaultPredicate: () => {
      return new SigfoxSearchPredicate(SigfoxSearchableField.any, PredicateTest.stringPartialMatchCaseInsensitive);
    },
    possibleSubjects: () => {
      return enumValues(SigfoxSearchableField);
    },
  };

  static $inject: InjectList = [
    '$location',
    '$log',
    '$translate',
    '$q',
    '$uibModal',
    'AlertsService',
    'SigfoxDevicesService',
    'TableColumnOptionsService',
    'UiDsModalService',
  ];

  constructor(
    private $location: ng.ILocationService,
    $log: ng.ILogService,
    $translate: LegacyAny,
    $q: ng.IQService,
    public $uibModal: any,
    AlertsService: any,
    public sigfoxDevicesService: SigfoxDevicesService,
    private tableColumnOptionsService: TableColumnOptionsService,
    private uiDsModalService: UiDsModalService,
  ) {
    super($log, $translate, $q, AlertsService, sigfoxDevicesService);
    this.modelName = 'SigfoxDevices';
  }

  $onInit() {
    super.$onInit();

    this.setTraceEnabled(true);
    this.trace(this);

    this.actionContainer.actions = {
      // @ts-expect-error (legacy code incremental fix)
      ChangeGroup: new ChangeGroupAction<SigfoxDevice>(this, this.uiDsModalService, 'SigfoxDevice'),
      ChangeTerminationProtection: new ChangeTerminationProtectionAction<SigfoxDevice>(
        this.logService,
        this.$uibModal,
        this,
        'sigfox_device',
        this.sigfoxDevicesService,
      ),
      Data: new DataAction<SigfoxDevice>(this, this.$location),
      Downlink: new DownlinkSigfoxDeviceAction(this),
      EditTag: new EditTagsAction<SigfoxDevice>(this.uiDsModalService, this.sigfoxDevicesService),
      Logs: new LogsAction<SigfoxDevice>(this, this.$location),
      Register: new RegisterSigfoxDeviceAction(this),
      Reload: new ReloadTableAction(this),
      // @ts-expect-error (legacy code incremental fix)
      ShowPac: new ShowPacSigfoxDeviceAction(this),
      Terminate: new TerminateResourceAction<SigfoxDevice>(
        this.logService,
        this.uiDsModalService,
        this,
        'sigfox_device',
        this.sigfoxDevicesService,
        this.$translate,
      ),
    };

    this.setupColumnOptions(TableColumnOptionsType.SigFox, this.tableColumnOptionsService, this.uiDsModalService);
    this.reloadData();
  }

  onSigfoxSearchQueryChanged(newQuery: LegacyAny) {
    this.debug('onSigfoxSearchQueryChanged():', newQuery);

    this._sigfoxSearchQuery = newQuery;
    this.paginator.clearPagination();
    this.reloadData();
  }

  protected getErrorMessage(translationId: string) {
    return this.$translate.instant(`sigfox_devices.errors.${translationId}`);
  }

  // @ts-expect-error (legacy code incremental fix)
  private _sigfoxSearchQuery: SearchQuery;

  get searchQuery() {
    return this._sigfoxSearchQuery;
  }

  updateGroup(group?: Group) {
    const rows = this.getSelection();
    const promises: LegacyAny = [];
    rows.forEach((row) => {
      if (group && group.groupId !== row.obj.groupId) {
        promises.push(
          this.sigfoxDevicesService
            .setGroup(row.obj.sigfoxDeviceId, group.groupId)
            .then(() => {
              row.obj.group = group;
              row.feedback.updateGroup = 'success';
              return true;
            })
            .catch((error: LegacyAny) => {
              row.feedback.updateGroup = 'failure';
              return error;
            }),
        );
      } else if (!group && row.obj.groupId) {
        // @ts-expect-error (legacy code incremental fix)
        row.obj.group = null;
        row.feedback.updateGroup = 'success';
        promises.push(this.sigfoxDevicesService.unsetGroup(row.obj.sigfoxDeviceId));
      }
    });

    this.$q
      .all(promises)
      .then(() => {
        this.alertsService.showSuccess('sigfox_devices.change_group_success_message');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  setData(data: SigfoxDevice[]) {
    return assignGroup(this.alertsService, data)
      .then((dataWithGroup) => {
        super.setData(dataWithGroup);
        this.tableData.rows.forEach((row) => {
          // FIXME: Super dirty hack to work with nameEditCtrl....
          const nameEditCtrl = new BaseEditTagController(this.logService, this.$q);
          nameEditCtrl.service = this.sigfoxDevicesService;
          nameEditCtrl.editing = row.obj;
          nameEditCtrl.alertsService = this.alertsService;
          row.nameEditCtrl = nameEditCtrl;
        });
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }
}
