import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Logger } from '@soracom/shared-ng/logger-service';
import { LocalStorageService } from '@soracom/forks/ngx-store';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { LagoonDashboard } from '../../../../app/shared/core/lagoon_dashboard';
import { LagoonLicenseQuota } from '../../../../app/shared/core/lagoon_license_quota';
import { LagoonService } from '../../../../app/shared/lagoon/lagoon.service';
import { LagoonDashboardsService } from '../../../../app/shared/lagoon/lagoon_dashboards.service';
import { LagoonSubscription } from '../../../../app/shared/lagoon/lagoon_plans';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { RootStoreState } from '../../root-store';
import { selectAvailableVersions, selectSubscription } from '../../root-store/lagoon-store/selectors';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiMenu } from '../../soracom-ui/ui-menu/UiMenu';
import { UiMenuItem } from '../../soracom-ui/ui-menu/UiMenuItem';
import { UiMenuSection } from '../../soracom-ui/ui-menu/UiMenuSection';
import { UiPaginator } from '../../soracom-ui/ui-pagination/UiPaginator';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';
import { UiTableContent } from '../../soracom-ui/ui-table/ui-table-content';
import { ActivateLagoonV2ModalComponent } from '../activate-lagoon-v2-modal/activate-lagoon-v2-modal.component';
import { UpdateLagoonDashboardPermissionModalComponent } from '../update-lagoon-dashboard-permission-modal/update-lagoon-dashboard-permission-modal.component';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LegacyTextContent, Translation } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-lagoon-dashboards',
  templateUrl: './lagoon-dashboards.component.html',
})
export class LagoonDashboardsComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  // Application Status
  subscription$ = this.store$.pipe(select(selectSubscription));
  availableVersions$ = this.store$.pipe(select(selectAvailableVersions));
  // @ts-expect-error (legacy code incremental fix)
  availableVersions: string[];

  // Component Status
  isProUser = false;
  // Enabled V2 & V3
  enabledBothVersions = false;
  // @ts-expect-error (legacy code incremental fix)
  quota: LagoonLicenseQuota;
  // @ts-expect-error (legacy code incremental fix)
  dashboardCount: number;
  canUseLagoon2 = true;
  set v2(newValue: boolean) {
    this.lagoonDashboardsService.v2 = newValue;
    this.fetch();
  }
  get v2() {
    return this.lagoonDashboardsService.v2;
  }
  get translateParams() {
    const current = this.dashboardCount === undefined ? '...' : this.dashboardCount;
    return { current, limit: this.quota?.dashboardQuota };
  }

  // UI stuff
  alertsManager = new AlertsManager();

  // UiMenu
  summary = UiButton.configure((b) => {
    b.titleId = 'LagoonDashboardsComponent.actions_menu.title';
  });
  updatePermissionMenuItem = UiMenuItem.configure((i) => {
    i.title = LegacyTextContent.fromTranslation(new Translation('LagoonDashboardsComponent.actions.update_permission'));
    i.disabled = true;
    i.identifier = 'UpdatePermission';
  });
  onMenuItemSelected = (item: UiMenuItem): void => {
    switch (item.identifier) {
      case 'UpdatePermission': {
        this.updatePermission(this.selectedItems);
        break;
      }
      default: {
        this.logger.error(`Unknown ActionItem: ${item.identifier}`);
        break;
      }
    }
  };
  menu = UiMenu.configure((m) => {
    m.summary = this.summary;
    m.menuContents = [new UiMenuSection([this.updatePermissionMenuItem])];
    m.onItemSelected = this.onMenuItemSelected;
  });

  // UiTable
  columns: Array<UiTableColumn<LagoonDashboard>> = [
    new UiTableColumn<LagoonDashboard>('LagoonDashboard', 'name', (s) => s.name),
  ];
  // @ts-expect-error (legacy code incremental fix)
  creator: new () => LagoonDashboard;
  content: UiTableContent<LagoonDashboard> = new UiTableContent<LagoonDashboard>([], this.columns, {
    multiSelect: true,
    enableContextMenu: true,
  });
  paginationContent: UiPaginator<LagoonDashboard>;
  // @ts-expect-error (legacy code incremental fix)
  selectedItems: LagoonDashboard[];

  constructor(
    private featureVisibilityService: FeatureVisibilityService,
    private lagoonDashboardsService: LagoonDashboardsService,
    private lagoonService: LagoonService,
    localStorageService: LocalStorageService,
    private logger: Logger,
    private store$: Store<RootStoreState.State>,
    private uiDsModalService: UiDsModalService
  ) {
    lagoonDashboardsService.v2 = true;
    this.paginationContent = new UiPaginator<LagoonDashboard>(localStorageService, lagoonDashboardsService);
  }

  ngOnInit() {
    this.paginationContent.onDataLoaded.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((relation) => {
      this.content.setData(relation.data);
    });
    this.content.updated.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.dashboardCount = this.content.tableData.data.length;
    });
    this.availableVersions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((versions) => {
      this.enabledBothVersions = versions.includes('v2') && versions.includes('v3');
      this.canUseLagoon2 = versions.includes('v2');
    });
    this.subscription$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (subscription) => {
      this.isProUser = subscription === LagoonSubscription.pro;
      if (subscription === LagoonSubscription.pro && this.canUseLagoon2) {
        try {
          this.quota = await this.lagoonService.getQuota();
        } catch (e) {
          this.alertsManager.add(Alert.fromApiError(e));
        }

        try {
          this.content.isLoading = true;
          await this.fetch();
        } finally {
          this.content.isLoading = false;
        }
      }
    });

    this.availableVersions$.subscribe((versions) => {
      this.availableVersions = versions;
    });
  }

  updatePermission(selectedItems: LagoonDashboard[]) {
    const input = { dashboard: selectedItems[0], v2: this.v2 };
    this.uiDsModalService
      .openAndWaitForResult(UpdateLagoonDashboardPermissionModalComponent, {
        title: 'UpdateLagoonDashboardPermissionModalComponent.heading',
        data: input,
      })
      .then((result: LegacyAny) => {
        if (result?.success) {
          this.alertsManager.add(Alert.success('LagoonDashboardsComponent.update_permission_success_message'));
          this.paginationContent.reload();
        }
      });
  }

  onTableItemSelectionChanged(items: LagoonDashboard[]): void {
    this.selectedItems = items;
    const menuItemDisabled = items.length !== 1;
    this.updatePermissionMenuItem.disabled = menuItemDisabled;
  }

  openMigrationModal() {
    const mode = this.availableVersions?.includes('v2') ? 'migration' : 'activation';
    const headingId = `ActivateLagoonV2ModalComponent.${mode}.heading`;
    this.uiDsModalService
      .openAndWaitForResult(ActivateLagoonV2ModalComponent, {
        title: headingId,
      })
      .then((result: LegacyAny) => {
        if (result?.success) {
          this.alertsManager.add(Alert.success('LagoonDashboardsComponent.migration_tool_success_message'));
          this.paginationContent.reload();
        }
      });
  }

  private fetch() {
    this.content.isReloading = true;
    return this.paginationContent
      .reload()
      .catch((e: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(e));
      })
      .finally(() => {
        this.content.isReloading = false;
      });
  }
}
