import { LegacyAny } from '@soracom/shared/core';

import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

// https://stackoverflow.com/a/66544040
export const skipInitial = () => {
  return <T>(source: Observable<T>): Observable<T> => {
    return source.pipe(skipWhile((value: LegacyAny) => value === undefined));
  };
};
