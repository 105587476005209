import { LegacyAny } from '@soracom/shared/core';

import { BaseModel } from '@soracom/shared/soracom-platform';

type PermissionHash = { [key: number]: LagoonPermission[] };

export enum LagoonDashboardPermission {
  editor = 'Editor',
  viewer = 'Viewer',
}

export enum DashboardPermission {
  Edit = 'Edit',
  View = 'View',
  None = 'None',
}

export interface LagoonPermission {
  userId: number;
  userEmail: string;
  dashboardId: number;
  dashboardTitle: string;
  permissionName: DashboardPermission;
}

export class LagoonDashboard extends BaseModel {
  // @ts-expect-error (legacy code incremental fix)
  id: number;
  // @ts-expect-error (legacy code incremental fix)
  name: string;
  // @ts-expect-error (legacy code incremental fix)
  permissions: LagoonPermission[];

  constructor(params?: LegacyAny) {
    super();
    if (params) {
      this.id = params.id;
      this.name = params.name;
      this.permissions = params.permissions;
    }
  }
}
