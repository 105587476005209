import { LegacyAny } from '@soracom/shared/core';

import * as angular from 'angular';
import { AlertsServiceInstance } from '../components/alerts.service';
import { ItemChooserController } from '../components/ItemChooserController';
import { LoraGateway } from '../core/lora_gateway';
import { LoraNetworkSet } from '../core/lora_network_set';
import { LoraNetworkSetsService } from '../lora_network_sets/lora_network_sets.service';
import { LoraGatewaysService } from './lora_gateways.service';

export class NetworkSetChooserController implements ItemChooserController<LoraNetworkSet> {
  // @ts-expect-error (legacy code incremental fix)
  private currentItem: LoraNetworkSet;
  // @ts-expect-error (legacy code incremental fix)
  currentItemName: string;
  items: LoraNetworkSet[] = [];
  disabled = true;
  showCreate = false;
  showUnset = true;

  constructor(
    private alertsService: AlertsServiceInstance,
    private loraGatewaysService: LoraGatewaysService,
    private loraNetworkSetsService: LoraNetworkSetsService,
    public loraGateway: LoraGateway
  ) {
    this.disabled = !loraGateway.owned;
    this.loraNetworkSetsService
      .list()
      .then((scRelation) => {
        this.items = scRelation.data;
        this.items.push(
          new LoraNetworkSet({ networkSetId: 'SORACOM-LoRa-Space', tags: { name: 'SORACOM LoRa Space' } })
        );
        if (this.loraGateway && this.loraGateway.networkSetId) {
          angular.forEach(this.items, (item) => {
            if (item.networkSetId === this.loraGateway.networkSetId) {
              this.currentItem = item;
              this.currentItemName = this.getItemName(item);
            }
          });
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  getItemName(item: LoraNetworkSet) {
    if (!item) {
      return '';
    } else if (item.tags && item.tags.name) {
      return item.tags.name;
    } else {
      return item.networkSetId;
    }
  }

  setItem(item: LoraNetworkSet) {
    this.loraGatewaysService
      .setLoraNetworkSet(this.loraGateway.gatewayId, item.networkSetId)
      .then(() => {
        this.currentItem = item;
        this.currentItemName = this.getItemName(item);
        this.loraGateway.networkSet = item;
        this.alertsService.showSuccess('lora_gateway.message.set_network_set');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  unsetItem() {
    this.loraGatewaysService
      .unsetLoraNetworkSet(this.loraGateway.gatewayId)
      .then(() => {
        // @ts-expect-error (legacy code incremental fix)
        this.currentItem = null;
        this.currentItemName = '';
        // @ts-expect-error (legacy code incremental fix)
        this.loraGateway.networkSet = null;
        this.alertsService.showSuccess('lora_gateway.message.unset_network_set');
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      });
  }

  unsetLinkClass() {
    return {
      disabled: !this.currentItem,
    };
  }

  // Not used
  createItem() {
    throw new Error('Method not implemented.');
  }
}
