export type ChartConfig = {
  title: string;
  chartType: string;
  parameters: {
    x: string;
    y: string;
    color?: string;
    barmode?: string;
    title?: string;
    xaxisType?: 'auto' | 'category';
  };
};

type CSVData = Record<string, string | number | null>;

export class PlotlyUtils {
  static normalizeHeader(header: string): string {
    return header.toLowerCase().trim();
  }

  static parseCSV(csv: string): CSVData[] {
    const lines = csv
      .trim()
      .split('\n')
      .map((line) => line.trim());
    const [headerLine, ...dataLines] = lines;
    const headers = headerLine.split(',').map((h) => this.normalizeHeader(h));

    return dataLines.map((line) => {
      const values = line.split(',').map((value) => value.trim());
      return headers.reduce((obj, header, index) => {
        const parsedValue =
          values[index] === 'null'
            ? null
            : isNaN(Number(values[index]))
              ? values[index]
              : Number(values[index]);
        obj[header] = parsedValue;
        return obj;
      }, {} as CSVData);
    });
  }

  static generatePlotData(csvData: string, config: ChartConfig) {
    const parsedData = this.parseCSV(csvData);

    const xKey = this.normalizeHeader(config.parameters.x);
    const yKey = this.normalizeHeader(config.parameters.y);
    const colorKey = config.parameters.color
      ? this.normalizeHeader(config.parameters.color)
      : undefined;

    // X-axis category heuristic check
    const xAxisCategoryHeuristic = [
      'MCC',
      'MNC',
      'ICCID',
      'IMSI',
      'IMEI',
      'VPLMN',
    ];
    if (xAxisCategoryHeuristic.includes(config.parameters.x.toUpperCase())) {
      config.parameters.xaxisType = 'category';
    }

    let data: any[];

    if (colorKey) {
      // If color key is specified, create separate traces for each unique color value
      const colorValues = [...new Set(parsedData.map((row) => row[colorKey]))];
      data = colorValues.map((colorValue) => ({
        x: parsedData
          .filter((row) => row[colorKey] === colorValue)
          .map((row) => row[xKey]),
        y: parsedData
          .filter((row) => row[colorKey] === colorValue)
          .map((row) => row[yKey]),
        type: config.chartType.split('.').pop(),
        mode: config.chartType.includes('scatter')
          ? 'lines+markers'
          : undefined,
        name: colorValue,
      }));
    } else {
      // If no color key, create a single trace
      data = [
        {
          x: parsedData.map((row) => row[xKey]),
          y: parsedData.map((row) => row[yKey]),
          type: config.chartType.split('.').pop(),
          mode: config.chartType.includes('scatter')
            ? 'lines+markers'
            : undefined,
        },
      ];
    }

    const layout = {
      title: config.title,
      xaxis: {
        title: config.parameters.x,
        type: config.parameters.xaxisType || 'auto',
      },
      yaxis: {
        title: config.parameters.y,
      },
      barmode: config.parameters.barmode || 'group',
    };

    return { data, layout };
  }
}

// // Example usage:
// const csvData = `
// MCC,EVENTCOUNT
// 440,3608260
// 200,1010395
// `;

// const config: ChartConfig = {
//   title: 'Event Count per MCC',
//   chartType: 'plotly.express.bar',
//   parameters: {
//     x: 'MCC',
//     y: 'EventCount',
//     color: 'MCC',
//     barmode: 'group',
//     title: 'Event Count per MCC',
//   },
// };

// const { plotData, layout } = PlotlyUtils.generatePlotData(csvData, config);

// console.log(plotData);
// console.log(layout);
