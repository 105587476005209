import { Component, Input } from '@angular/core';
import { LogViewerControllerMode } from '../log-viewer/log-viewer.component';

@Component({
  selector: 'app-log-viewer-header',
  templateUrl: './log-viewer-header.component.html',
  styles: [],
})
export class LogViewerHeaderComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() mode: LogViewerControllerMode;

  get headingId(): string {
    switch (this.mode) {
      case 'auditLogs':
      case 'testAuditLogs':
        return 'SoracomLogs.heading.auditLogs';

      case 'errorLogs':
      case 'testErrorLogs':
        return 'SoracomLogs.heading.errorLogs';

      case 'napterAuditLogs':
      case 'testNapterAuditLogs':
        return 'SoracomLogs.heading.napterAuditLogs';

      default:
        return 'ERROR: Unknown mode in app-log-viewer-header';
    }
  }
}
