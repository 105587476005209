import { UiText } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiElement } from '@soracom/shared-ng/soracom-ui-legacy';

/**
 * `UiMenuItemIcon` is a string icon identifier supported by SDS.
 */
export type UiMenuItemIcon = string;

/**
 * A number to show a badge with a number, or just `true` to show the badge without number.
 *
 * [SDS docs](https://design-system.soracom.io/docs/components/ds-menu/#notification-indicator)
 */
export type UiMenuNotification = number | boolean;

export class UiMenuItem extends UiElement {
  /**
   * A menu item object.
   */
  constructor(
    /**
     * The title of the menu item.
     */
    public title: UiText = 'no title',
    /**
     * FIXME: `disabled` is not yet implemented as of 2020-11-16
     */
    public disabled: boolean | (() => boolean) = false,
    /**
     * FIXME: `icon` is not yet implemented as of 2020-11-16
     */
    public icon?: UiMenuItemIcon,
    /**
     * FIXME: `description` is not yet implemented as of 2020-11-16
     */
    public description?: UiText
  ) {
    super();
  }

  // @ts-expect-error (legacy code incremental fix)
  private _identifier: string;

  /**
   * The `identifier` can optionally be used to differentiate menu items. By default it is not used but client code may opt to use it. In many cases they can instead be differentiated merely by checking the `title`.
   */
  get identifier() {
    return this._identifier ?? this.title.toString();
  }

  set identifier(newValue) {
    this._identifier = newValue;
  }

  /**
   * Converts a `UiText` to a `UiMenuItem`.
   */
  static fromUiText(obj: UiText) {
    return new this(obj);
  }

  /**
   * Converts a `UiText[]` to a `UiMenuItem[]`.
   */
  static fromUiTextArray(a: UiText[]) {
    return a.map((e) => this.fromUiText(e));
  }
}
