<section dsDialogContent>
  <div class="ds-rows">
    <p [innerHTML]="'long_term_discount.new_long_term_discount_modal_help_text_html' | translate"></p>

    <div class="ds-cols">
      <!-- TYPE-->
      <div class="ds-span --6">
        <div class="ds-field">
          <label for="long-term-discount-type" class="ds-text --label">
            {{ 'long_term_discount.labels.volume_discount_type' | translate }}
          </label>
          <div class="ds-select">
            <select [(ngModel)]="data.volumeDiscountType" class="x-ediscount-type"
              (ngModelChange)="onVolumeDiscountTypeChanged()" data-testid="long-term-discount-type">
              <option *ngFor="let type of allowedDiscountTypeDefinitions" [ngValue]="type.value">
                {{ type.nameId | translate }}
              </option>
            </select>
          </div>
          <div class="ds-text"><!-- in the old days we sometimes needed help text but I think that's over --></div>
        </div>
      </div>

      <!-- PAYMENT TYPE-->
      <div class="ds-span --6">
        <div class="ds-field">
          <label for="long-term-discount-payment-type" class="ds-text --label">
            {{ 'long_term_discount.labels.payment_type' | translate }}
          </label>
          <div class="ds-select">
            <select [(ngModel)]="data.volumeDiscountPaymentType" class="x-payment-type"
              (ngModelChange)="onVolumeDiscountPaymentTypeChanged()" data-testid="long-term-discount-payment-type">
              <option *ngFor="let paymentType of allowedPaymentTypeDefinitions" [ngValue]="paymentType.value">
                {{ paymentType.nameId | translate }}
              </option>
            </select>
          </div>
          <div class="ds-text"><!-- in the old days we sometimes needed help text but I think that's over --></div>
        </div>
      </div>

    </div>

    <div class="ds-cols">

      <!-- DISCOUNT TERM-->
      <div class="ds-span --6">

        <div class="ds-field">
          <label for="long-term-discount-term" class="ds-text --label">
            {{ 'long_term_discount.labels.term' | translate }}
          </label>
          <div class="ds-select">
            <select [(ngModel)]="data.contractTermMonth" class="x-discount-term"
              (ngModelChange)="onVolumeDiscountContractTermChanged()" data-testid="long-term-discount-term">
              <option *ngFor="let term of allowedTermDefinitions" [ngValue]="term.value">
                {{ term.nameId | translate }}
              </option>
            </select>
          </div>
          <div class="ds-text"><!-- in the old days we sometimes needed help text but I think that's over --></div>
        </div>

      </div>

      <!-- BASIC CHARGE -->
      <div class="ds-span --6">

        <div class="ds-field">
          <label for="long-term-discount-unit-price" class="ds-text --label">
            {{ 'long_term_discount.labels.unit_price' | translate }}
          </label>
          <div class="ds-input">
            <input type="text" readonly class="x-unit-price" [(ngModel)]="taxIncludedUnitPrice"
              placeholder="{{ 'long_term_discount.labels.unit_price' | translate }}" style="text-align: right" data-testid="long-term-discount-price"/>
            <span class="ds-text --suffix">{{ 'long_term_discount.labels.jpy' | translate }}</span>
          </div>
          <div class="ds-text"><!-- in the old days we sometimes needed help text but I think that's over --></div>
        </div>

      </div>

    </div>

    <div class="ds-cols">

      <!-- NUMBER OF SIMS -->
      <div class="ds-span --6">

        <div class="ds-field">
          <label for="long-term-discount-number-of-sims" class="ds-text --label">
            {{ 'long_term_discount.labels.number_of_sims' | translate }}
          </label>
          <div class="ds-input">
            <input type="number" min="1" max="99999" maxlength="5" [(ngModel)]="data.quantity"
              class="x-number-of-sims" />
          </div>
          <div class="ds-text"><!-- in the old days we sometimes needed help text but I think that's over --></div>
        </div>

      </div>

      <!-- START DATE-->
      <div class="ds-span --6">

        <div class="ds-field">
          <label for="long-term-discount-start-date" class="ds-text --label">
            {{ 'long_term_discount.labels.start_date' | translate }}
          </label>
          <div class="ds-input">

            <div class="ds-input --date">
              <input class="x-start-date" type="date" [(ngModel)]="dateInHtmlFormat" [min]="minDateInHtmlFormat"
                [max]="maxDateInHtmlFormat" />
            </div>

          </div>
          <div class="ds-text"><!-- in the old days we sometimes needed help text but I think that's over --></div>
        </div>

      </div>
    </div>
  </div>

  <app-alerts [alertsManager]="alertsManager"></app-alerts>

  <ui-network-activity-indicator [messageId]="$any(networkActivityMessageId)"></ui-network-activity-indicator>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'NewLongTermDiscountComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>
