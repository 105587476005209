<section dsDialogContent>
  <div class="ds-rows" [formGroup]="form">
    <div class="ds-notice--info" translate="UpdateLagoonUserPasswordModalComponent.text"></div>
    <ui-input [content]="currentPasswordInputContent"></ui-input>
    <ui-password-confirm (passwordChange)="onPasswordChanged($event)"></ui-password-confirm>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm" [ngClass]="{'--loading': isLoading}">
    <span [innerHTML]="'UpdateLagoonUserPasswordModalComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()">
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>

