<section dsDialogContent>
  <div class="ds-rows" [innerHtml]="(inputSubscribers.length > 1 ? 'DeleteLteMButtonConfigComponent.explanatoryText.multiple' : 'DeleteLteMButtonConfigComponent.explanatoryText.single') | translate"></div>
  <app-subscriber-batch-change [updater]="updater"></app-subscriber-batch-change>
  <app-alerts [alertsManager]="alertsManager"></app-alerts>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'DeleteLteMButtonConfigComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>
