<form [formGroup]="form" (ngSubmit)="save()">
  <div class="ds-rows">
    <h2>{{ 'LteMButtonConfigComponent.title' | translate }}</h2>
    <div class="ds-group">
      <div class="ds-group__title">{{ 'LteMButtonConfigComponent.destination.title' | translate }}</div>
      <ui-checkbox modelName="LteMButtonConfigComponent" name="SoracomHarvest" [group]="form"></ui-checkbox>
      <div class="ds-notice--info" [innerHtml]="'LteMButtonConfigComponent.destination.description' | translate"></div>
    </div>

    <div class="ds-group">
      <div class="ds-group__title">{{ 'LteMButtonConfigComponent.cellLocation.title' | translate }}</div>
      <ui-checkbox modelName="LteMButtonConfigComponent" name="cellLocation" [group]="form"></ui-checkbox>
      <div class="ds-notice--info" [innerHtml]="'LteMButtonConfigComponent.cellLocation.description' | translate"></div>
    </div>

    <div class="ds-group" formGroupName="emailNotification">
      <div class="ds-group__title">{{ 'LteMButtonConfigComponent.emailNotification.title' | translate }}</div>
      <ui-checkbox
        modelName="LteMButtonConfigComponent"
        name="enabled"
        [group]="getFormGroup('emailNotification')"
      ></ui-checkbox>
      <div class="ds-field">
        <label class="ds-label">{{ 'LteMButtonConfigComponent.emailNotification.labels.emailTo' | translate }}</label>
        <input class="ds-input" type="text" formControlName="to" />
        <div
          class="ds-text"
          [innerHtml]="'LteMButtonConfigComponent.emailNotification.description.emailTo' | translate"
        ></div>
        <div
          class="ds-text--alert"
          *ngIf="$any(to).errors?.maxLength"
          [innerHTML]="'LteMButtonConfigComponent.emailNotification.to.errors.maxLength' | translate"
        ></div>
        <div
          class="ds-text--alert"
          *ngIf="$any(to).errors?.email"
          [innerHTML]="'LteMButtonConfigComponent.emailNotification.to.errors.email' | translate"
        ></div>
      </div>
      <div class="ds-field">
        <label class="ds-label">{{ 'LteMButtonConfigComponent.emailNotification.labels.emailBcc' | translate }}</label>
        <input class="ds-input" type="text" formControlName="bcc" />
        <div
          class="ds-text"
          [innerHtml]="'LteMButtonConfigComponent.emailNotification.description.emailBcc' | translate"
        ></div>
        <div
          class="ds-text--alert"
          *ngIf="$any(bcc).errors?.maxLength"
          [innerHTML]="'LteMButtonConfigComponent.emailNotification.to.errors.maxLength' | translate"
        ></div>
        <div
          class="ds-text--alert"
          *ngIf="$any(bcc).errors?.email"
          [innerHTML]="'LteMButtonConfigComponent.emailNotification.to.errors.email' | translate"
        ></div>
      </div>
      <div class="ds-field">
        <label class="ds-label">
          {{ 'LteMButtonConfigComponent.emailNotification.labels.emailSubject' | translate }}
        </label>
        <input class="ds-input" type="text" formControlName="subject" />
        <div
          class="ds-text--alert"
          *ngIf="$any(subject).errors?.maxLength"
          [innerHTML]="'LteMButtonConfigComponent.emailNotification.subject.errors.maxLength' | translate"
        ></div>
      </div>
      <div class="ds-field">
        <label class="ds-label">
          {{ 'LteMButtonConfigComponent.emailNotification.labels.emailBody' | translate }}
        </label>
        <textarea class="ds-input" style="height: auto; padding: 14px" rows="15" formControlName="body"></textarea>
        <div class="ds-text">
          <span [class]="getTagValueByteLengthCssClass($any(body).value)">{{ getTagValueByteLength($any(body).value) }} </span>
          &nbsp;/ {{ maxTagValueByteLength }}&nbsp;
          <span [innerHTML]="'LteMButtonConfigComponent.emailNotification.subText.tagValue' | translate"> </span>
        </div>
        <div
          class="ds-text--alert"
          *ngIf="$any(body).errors?.maxLength"
          [innerHTML]="'LteMButtonConfigComponent.emailNotification.body.errors.maxLength' | translate"
        ></div>
      </div>
      <div class="ds-notice--info">
        <p [innerHtml]="'LteMButtonConfigComponent.emailNotification.description.emailBody' | translate"></p>
      </div>
    </div>
    <div>
      <app-alerts [alertsManager]="alertsManager"></app-alerts>
      <ui-button-bar [content]="buttonBar"></ui-button-bar>
    </div>
  </div>
</form>
