import { LegacyAny } from '@soracom/shared/core';

import { InjectList } from '../core/injectable';
import { BaseDirectiveController } from './base_directive_controller';
import { KeyEventService } from './key_event_service';

/**
 * The sc-on-return directive lets you specify an Angular expression, like
 * '$ctrl.doFooBar()', that will be evaluated/executed when the Return key
 * is pressed.
 *
 * The key event is listened for at the window level, so the bound behavior
 * will execute whenever the Return key is pressed, regardless of which
 * DOM element may have keyboard focus.
 *
 * This directive does not give you any help in figuring out when NOT to
 * handle the Return keypress, however. This is a lower-level initial
 * version of sc-modal-default-action, which may be more generally useful.
 *
 * UPDATE: Actually, it now gives you one small help: the sc-on-return-exempt
 * pseudo-directive. If an element has this attribute applied, and it has
 * keyboard focus at the time the Return key is pressed, all Return key
 * handling will be suppressed, and sc-on-return will do nothing. This is a
 * kinda gross hack, but this whole directive is a pretty gross hack, and
 * this way you can express in a template "No matter what going on, any
 * Return key press should trigger this action, UNLESS ONE OF THESE ELEMENTS
 * HAS KEYBOARD FOCUS". E.g.:
 *
 * <button ng-click="$ctrl.cancel()" sc-on-return-exempt>Cancel</button>
 * <button ng-click="$ctrl.fooBar()" sc-on-return>Do foo bar</button>
 *
 * The 'Do foo bar' button action will be invoked on Return key press, no
 * matter what element has focus, UNLESS it is the Cancel button, in which
 * case it will do nothing (and let the cancel() action happen unmolested).
 *
 * The use case for this is a modal with a default button, that also has a
 * Cancel button, or other buttons that should be invocable via Return key.
 */
export function ScOnReturnDirective(): ng.IDirective {
  return {
    restrict: 'A',
    controller: ScOnReturnController,

    // @ts-expect-error (legacy code incremental fix)
    link: (
      scope: ng.IScope,
      element: ng.IAugmentedJQuery,
      attributes: ng.IAttributes,
      controller: ScOnReturnController
    ): void => {
      controller.activate(scope, element, attributes);
    },
  };
}

class ScOnReturnController extends BaseDirectiveController {
  static $inject: InjectList = ['$log', 'KeyEventService'];

  protected onReturnAttrValue = '';

  // @ts-expect-error (legacy code incremental fix)
  protected scope: ng.IScope;

  constructor($log: ng.ILogService, private KeyEventService: KeyEventService) {
    super($log);
    this.setTraceEnabled(true);
    this.trace(
      'ScOnReturnController constructed with injected properties $log and KeyEventService:',
      $log,
      KeyEventService
    );
  }

  /**
   *  The activate() method runs once, and performs initial setup.
   */
  activate(scope: ng.IScope, element: ng.IAugmentedJQuery, attributes?: ng.IAttributes) {
    super.activate(scope, element, attributes);

    // @ts-expect-error (legacy code incremental fix)
    attributes.$observe('scOnReturn', (value: string) => {
      this.onReturnAttrValue = value;
      this.trace('The value of sc-on-return attribute was updated:', value);
    });

    scope.$on('$destroy', () => {
      this.trace('sc-on-return scope will be destroyed; removing keypress handler.');
      this.KeyEventService.removeReturnKeyHandler(this.handleReturnKeyPress);
    });

    this.KeyEventService.addReturnKeyHandler(this.handleReturnKeyPress);
  }

  /**
   * The internal method that handles Return key press. This looks up the Angular
   * expession that is bound to the sc-on-return attribute (e.g., '$ctrl.doFooBar()')
   * and evaluates (executes) it.
   */
  handleReturnKeyPress = (e: any) => {
    if (this.ngDisabled()) {
      this.trace(
        `sc-on-return:: Return key press will be ignored, because ng-disabled value "${this.attributes.ngDisabled}" evaluates to a truthy value.`
      );
      return;
    }

    if (this.exemptElementHasKeyboardFocus()) {
      this.trace(
        `sc-on-return:: Return key press will be ignored, because the currently active DOM element has the sc-on-return-exempt attribute.`
      );
      return;
    }

    let actionExpression = null;

    if (this.onReturnAttrValue && this.onReturnAttrValue !== 'sc-on-return') {
      actionExpression = this.onReturnAttrValue;
    } else {
      actionExpression = this.attributes.ngClick;
    }

    if (!actionExpression) {
      this.trace('sc-on-return: ignoring Return key because no action expression exists.');
    } else {
      this.trace('sc-on-return: handling Return keypress by evaluating expresson:', actionExpression);
      this.eval(actionExpression);
    }
  };

  exemptElementHasKeyboardFocus() {
    const e = document.activeElement;
    // @ts-expect-error (legacy code incremental fix)
    return e && e.attributes && e.attributes['sc-on-return-exempt'];
  }
}

// IMPLEMENTATION NOTES:
// Mason 2017-08-07: Experimental implementation of a simple directive
// in TypeScript. I could not figure out how to implement dependency
// injection of things like $log with the approach used by AlertsDirective;
// This one is based on the approach described here:
//
// https://stackoverflow.com/a/32934956/164017
//
// This approach would also work for a directive with a template, but we
// have switched to favor Angular 1.5 components instead of those kinds of
// directives where possible. In JS this directive would not need the
// controller and would be really simple:
//
//    window.app.directive('FooDirective', FooDirective);
//
//    function FooDirective($log) {
//      'ngInject';
//      return function (scope, element, attrs) {
//        $log.debug('Angular directive approach #2 has initialized! ');;
//      };
//    }
//
// .. so this is a lot more code and may not be the best approach. This is
// more like "Mason's first working approach".
//
// In app.ts, the directive is registered like:
//     app.directive('scOnReturn', ScOnReturnDirective)
