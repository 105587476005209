<section dsDialogContent>
  <div class="ds-rows">
    <div
      class="ds-notice--info"
      *ngIf="!nobodyCanAccess()"
      translate="UpdateLagoonDashboardPermissionModalComponent.text"
    ></div>
    <div
      class="ds-notice--warning"
      *ngIf="nobodyCanAccess()"
      translate="UpdateLagoonDashboardPermissionModalComponent.no_access_error"
    ></div>
    <ui-generic-table [content]="content">
      <ng-template let-content="content" let-row #dataRow>
        <td [ngClass]="content.columns[0].tdClasses">{{ row.obj.userEmail }}</td>
        <td [ngClass]="content.columns[1].tdClasses">
          {{ 'lagoon_users.values.role.' + row.obj.userRole | translate }}
        </td>
        <td [ngClass]="content.columns[2].tdClasses">
          <select
            class="ds-select"
            name="dashboard-user-permission"
            [(ngModel)]="row.obj.permission"
            style="width: 100%"
          >
            <option
              *ngFor="let permission of availablePermissions(row.obj.userRole)"
              [attr.label]="'lagoon_dashboards.values.permissions.' + permission | translate"
              [ngValue]="permission"
            ></option>
          </select>
        </td>
      </ng-template>
    </ui-generic-table>
    <app-alerts [alertsManager]="alertsManager"></app-alerts>
  </div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm" [ngClass]="{'--loading': loading}">
    <span [innerHTML]="'UpdateLagoonDashboardPermissionModalComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()">
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
  <button class="ds-button --plain --alert --icon-delete" (click)="removePermissions()" [disabled]="loading" *ngIf="dashboardPermissionsSet">
    <span [innerHTML]="'UpdateLagoonDashboardPermissionModalComponent.reset_permissions' | translate"></span>
  </button>
</footer>

