export const template = `<span class="sc-tooltip">
  <div class="btn-group" uib-dropdown="uib-dropdown"><a class="dropdown-toggle" href=""
      uib-dropdown-toggle="uib-dropdown-toggle"><i class="glyphicon glyphicon-question-sign"></i></a>
    <ul ng-class="$ctrl.dropdownClasses" role="menu">
      <li>
        <div class="notice" href=""><em><small ng-if="$ctrl.descriptionId"
              translate="{{ $ctrl.descriptionId }}"></small></em><em><small ng-if="$ctrl.description"
              ng-bind-html="$ctrl.description"></small></em></div>
      </li>
    </ul>
  </div>
</span>`;
