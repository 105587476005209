const e = /\n/g;
function n(n) {
  const o = [...n.matchAll(e)].map(e => e.index || 0);
  o.unshift(-1);
  const s = t(o, 0, o.length);
  return e => r(s, e);
}
function t(e, n, r) {
  if (r - n == 1) return {
    offset: e[n],
    index: n + 1
  };
  const o = Math.ceil((n + r) / 2),
    s = t(e, n, o),
    l = t(e, o, r);
  return {
    offset: s.offset,
    low: s,
    high: l
  };
}
function r(e, n) {
  return function (e) {
    return Object.prototype.hasOwnProperty.call(e, "index");
  }(e) ? {
    line: e.index,
    column: n - e.offset
  } : r(e.high.offset < n ? e.high : e.low, n);
}
function o(e, t = "", r = {}) {
  const o = "string" != typeof t ? t : r,
    l = "string" == typeof t ? t : "",
    c = e.map(s),
    f = !!o.lineNumbers;
  return function (e, t = 0) {
    const r = f ? n(e) : () => ({
      line: 0,
      column: 0
    });
    let o = t;
    const s = [];
    e: for (; o < e.length;) {
      let n = !1;
      for (const t of c) {
        t.regex.lastIndex = o;
        const c = t.regex.exec(e);
        if (c && c[0].length > 0) {
          if (!t.discard) {
            const e = r(o),
              n = "string" == typeof t.replace ? c[0].replace(new RegExp(t.regex.source, t.regex.flags), t.replace) : c[0];
            s.push({
              state: l,
              name: t.name,
              text: n,
              offset: o,
              len: c[0].length,
              line: e.line,
              column: e.column
            });
          }
          if (o = t.regex.lastIndex, n = !0, t.push) {
            const n = t.push(e, o);
            s.push(...n.tokens), o = n.offset;
          }
          if (t.pop) break e;
          break;
        }
      }
      if (!n) break;
    }
    return {
      tokens: s,
      offset: o,
      complete: e.length <= o
    };
  };
}
function s(e, n) {
  return {
    ...e,
    regex: l(e, n)
  };
}
function l(e, n) {
  if (0 === e.name.length) throw new Error(`Rule #${n} has empty name, which is not allowed.`);
  if (function (e) {
    return Object.prototype.hasOwnProperty.call(e, "regex");
  }(e)) return function (e) {
    if (e.global) throw new Error(`Regular expression /${e.source}/${e.flags} contains the global flag, which is not allowed.`);
    return e.sticky ? e : new RegExp(e.source, e.flags + "y");
  }(e.regex);
  if (function (e) {
    return Object.prototype.hasOwnProperty.call(e, "str");
  }(e)) {
    if (0 === e.str.length) throw new Error(`Rule #${n} ("${e.name}") has empty "str" property, which is not allowed.`);
    return new RegExp(c(e.str), "y");
  }
  return new RegExp(c(e.name), "y");
}
function c(e) {
  return e.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, "\\$&");
}
export { o as createLexer };