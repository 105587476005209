import { LegacyAny } from '@soracom/shared/core';

import { GpsMultiunitConfig } from '../../shared/gps_multiunit/GpsMultiunitConfig';

import { Group } from '@soracom/shared/group';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { initialModelCollectionState, initialModelState, ModelCollectionState, ModelState } from '../root-state';

export type CreateGroupParam = Pick<Group, 'name'>;

export type GroupOrGroupParam = Group | CreateGroupParam;
export const isGroup = (v: GroupOrGroupParam): v is Group => !!(v as LegacyAny)['id'];

export type GpsMultiunitPageState =
  | 'index'
  | 'newConfig'
  | 'selectSubscribers'
  | 'selectGroup'
  | 'editConfig'
  | 'editGroupConfig'
  | 'linkToHarvestData'
  | 'removeGpsMultiunitConfig'
  | 'unknown';

export interface GpsMultiunitPageRouteState {
  status: GpsMultiunitPageState;
  imsiList: string[];
  groupId: string | null;
  newGroupName: string | null;
}

export interface GpsMultiunitIndexPageState {
  loadingRequired: boolean;
}

export interface GpsMultiunitNewPageState {
  availableSubscribers: { error: Error | string | null };
  selectedSubscribers: ModelCollectionState<ExtendedSubscriberInterface>;
}

export interface GpsMultiunitEditPageState {
  config: GpsMultiunitConfig | null;
  group: ModelState<GroupOrGroupParam> | null;
}

export interface State {
  routing: GpsMultiunitPageRouteState;
  index: GpsMultiunitIndexPageState;
  new: GpsMultiunitNewPageState;
  edit: GpsMultiunitEditPageState;
}

export const initialIndexPageState: GpsMultiunitIndexPageState = {
  loadingRequired: true,
};

export const initialNewPageState: GpsMultiunitNewPageState = {
  availableSubscribers: {
    error: null,
  },
  selectedSubscribers: {
    ...initialModelCollectionState,
  },
};

export const initialEditPageState: GpsMultiunitEditPageState = {
  config: null,
  group: {
    ...initialModelState,
  },
};

export const initialRoutingState: GpsMultiunitPageRouteState = {
  status: 'index',
  groupId: null,
  imsiList: [],
  newGroupName: null,
};

export const initialState: State = {
  routing: initialRoutingState,
  index: initialIndexPageState,
  new: initialNewPageState,
  edit: initialEditPageState,
};
