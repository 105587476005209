import { template } from './subscriber_status_label.component.html';

import { BaseController } from '../../components/base_controller';
import { InjectList } from '../../core/injectable';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';

export class SubscriberStatusLabelComponent implements ng.IComponentOptions {
  bindings = {
    subscriber: '<',
  };

  controller = SubscriberStatusLabelComponentController;
  template: any = template;
}

export class SubscriberStatusLabelComponentController extends BaseController {
  static $inject: InjectList = ['$log'];

  // @ts-expect-error (legacy code incremental fix)
  subscriber: ExtendedSubscriberInterface;

  constructor($log: ng.ILogService) {
    super($log);
  }
}
