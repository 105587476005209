import { createAction, props, union } from '@ngrx/store';
import { LagoonVersion } from '../../../../app/shared/lagoon/lagoon.service';
import { LagoonSubscription } from '../../../../app/shared/lagoon/lagoon_plans';

export const loadLagoonSubscriptionAndAvailableVersion = createAction(
  '[Lagoon] Load subscription and available version'
);

export const loadLagoonSubscriptionAndAvailableVersionSuccess = createAction(
  '[Lagoon] Load subscription and available version Success',
  props<{ availableVersions: LagoonVersion[]; subscription: LagoonSubscription }>()
);

export const loadLagoonSubscriptionAndAvailableVersionError = createAction(
  '[Lagoon] Load subscription and available version Error',
  (error: any) => ({ error })
);

const actions = union({
  loadLagoonSubscriptionAndAvailableVersion,
  loadLagoonSubscriptionAndAvailableVersionSuccess,
  loadLagoonSubscriptionAndAvailableVersionError,
});

export type ActionsUnion = typeof actions;
