import { LegacyAny } from '@soracom/shared/core';

import { template } from './group_subscribers_table.component.html';

import { formatDateTime } from '@soracom/shared/util-common';
import { AlertsService } from '../components/alerts.service';
import { BasePaginatableTableController } from '../components/base_paginatable_table_controller';
import { assignGroup } from '../components/GroupFetcher';
import {
  TableColumnOptionsService,
  TableColumnOptionsType,
} from '../../../src/app/ng-rewrites/legacy-table/table_column_options.service';
import { InjectList } from '../core/injectable';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberFilter } from './SubscriberFilter';
import { SubscribersService } from './subscribers.service';
import { SubscriberEnumeratorsFactory, SubscriberSearchCategory } from './subscriber_enumerators.factory';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

export class GroupSubscribersTableComponent implements ng.IComponentOptions {
  bindings = {
    groupId: '<',
  };
  controller = GroupSubscribersTableComponentController;
  template: any = template;
}

export class GroupSubscribersTableComponentController
  extends BasePaginatableTableController<ExtendedSubscriberInterface>
  implements ng.IOnInit, ng.IOnChanges
{
  static $inject: InjectList = [
    '$log',
    '$translate',
    '$q',
    '$uibModal',
    'AlertsService',
    'SubscriberEnumeratorsFactory',
    'SubscribersService',
    'TableColumnOptionsService',
    'UiDsModalService',
  ];

  DEFAULT_SORT_PREDICATE = 'imsi';

  // The attribute name the subscribers table sort by order_object_by_filter.
  // @ts-expect-error (legacy code incremental fix)
  sortPredicate: string;

  // The sort order is reversed if this value is true. used by order_object_by_filter.
  // @ts-expect-error (legacy code incremental fix)
  sortReverse: boolean;

  editable = false;

  defaultFeedbackDuration = 3000;

  // @ts-expect-error (legacy code incremental fix)
  groupId: string;

  statusFilter: LegacyAny;

  /**
   *  Variables for statusList
   */

  statusList = [
    'subscribers.status.standby',
    'subscribers.status.ready',
    'subscribers.status.active',
    'subscribers.status.inactive',
    'subscribers.status.suspended',
    'subscribers.status.terminated',
  ];

  defaultSelectedStatusList = [
    'subscribers.status.standby',
    'subscribers.status.ready',
    'subscribers.status.active',
    'subscribers.status.inactive',
    'subscribers.status.suspended',
  ];

  sortFilterOptions: { statusSortOrder: string; selectedStatusList: string[] } = {
    // @ts-expect-error (legacy code incremental fix)
    statusSortOrder: null,
    selectedStatusList: [...this.defaultSelectedStatusList],
  };

  constructor(
    $log: ng.ILogService,
    $translate: LegacyAny,
    $q: LegacyAny,
    private $uibModal: LegacyAny,
    alertsService: AlertsService,
    private subscriberEnumeratorsFactory: SubscriberEnumeratorsFactory,
    subscribersService: SubscribersService,
    private tableColumnOptionsService: TableColumnOptionsService,
    private uiDsModalService: UiDsModalService,
  ) {
    super($log, $translate, $q, alertsService, subscribersService);
  }

  $onInit(): void {
    super.$onInit();

    this.setTraceEnabled(true);
    this.trace(this);

    this.sortPredicate = this.DEFAULT_SORT_PREDICATE;
    this.sortReverse = false;

    this.setupColumnOptions(TableColumnOptionsType.Subscriber, this.tableColumnOptionsService, this.uiDsModalService);
    if (this.groupId) {
      this.initalizeTable();
    }
  }

  $onChanges(onChangesObj: ng.IOnChangesObject): void {
    if (onChangesObj.groupId && onChangesObj.groupId.currentValue) {
      this.initalizeTable();
    }
  }

  ////////////////////////////////////////////////////////////
  // Implement table controller methods
  ////////////////////////////////////////////////////////////

  selectable() {
    return false;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Table
  //////////////////////////////////////////////////////////////////////////////

  initalizeTable() {
    const filters: SubscriberFilter = {
      status: this.statusFilter,
    };
    this.paginator = this.subscriberEnumeratorsFactory.create(
      SubscriberSearchCategory.groupId,
      this.groupId,
      false,
      false,
      filters,
    );
    this.reloadData();
  }

  setData(data: ExtendedSubscriberInterface[]) {
    return assignGroup(this.alertsService, data)
      .then((dataWithGroup) => {
        super.setData(dataWithGroup);
      })
      .catch((error: LegacyAny) => {
        this.alertsService.showError(error);
      })
      .finally(() => {
        this._isLoading = false;
      });
  }

  statusSortOrderChanged(sortOrder: 'ascend' | 'descend' | null) {
    if (this.sortFilterOptions.statusSortOrder !== sortOrder) {
      // @ts-expect-error (legacy code incremental fix)
      this.sortFilterOptions.statusSortOrder = sortOrder;
      if (this.sortFilterOptions.statusSortOrder !== null) {
        this.sortPredicate = 'status';
        this.sortReverse = this.sortFilterOptions.statusSortOrder === 'descend';
      } else {
        this.sortPredicate = this.DEFAULT_SORT_PREDICATE;
        this.sortReverse = false;
      }
    }
  }

  statusFilterChanged(filteredStatusList: string[]) {
    // Convert list to query. e.g. ["subcribers.status.active". "subscribers.status.inactive"] => "active|inactive"
    // TODO: Move this logic to better place.
    this.sortFilterOptions.selectedStatusList = filteredStatusList;
    const statusFilter = filteredStatusList
      .map((elem) => {
        return elem.substring(elem.lastIndexOf('.') + 1);
      })
      .join('|');
    if (statusFilter !== this.statusFilter) {
      this.debug('status filter has been changed: ', statusFilter);
      this.statusFilter = statusFilter;
      const filters: SubscriberFilter = {
        status: this.statusFilter,
      };
      this.paginator = this.subscriberEnumeratorsFactory.createDefault(filters);
      this.reloadData();
    }
  }

  isStatusFiltered() {
    return false;
  }

  getStatusFilter() {
    return null;
  }

  clearSortAndFilter() {
    this.sortPredicate = this.DEFAULT_SORT_PREDICATE;
    this.sortReverse = false;
  }

  /**
   * This is currently only supported in the main SIM list, not the "SIMs in this Group" table in the Groups UI.
   */
  canSortByName() {
    return false;
  }

  nameSortToggled() {}

  formatExpiredAt(unixMsec: LegacyAny) {
    // @ts-expect-error (legacy code incremental fix)
    const s = formatDateTime(unixMsec, 'datetime_tz', { fallback: null });
    if (s === null) {
      return this.$translate.instant('common.unspecified');
    }
    return s;
  }

  isExpired(t: LegacyAny) {
    if (t === undefined || t === null) {
      return false;
    }

    return t < new Date();
  }

  /** Return a debug duration if it exists for tests, else return the default value specified above */
  getFeedbackDuration() {
    const win = window as any;
    const feedbackDuration = win.soracom_user_console_debug_feedback_duration;
    if (feedbackDuration === undefined || feedbackDuration === null) {
      return this.defaultFeedbackDuration;
    } else {
      return feedbackDuration;
    }
  }
}
