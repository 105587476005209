import { LegacyAny } from '@soracom/shared/core';

// window.app.directive('scUpdateFeedback', scUpdateFeedback);

export function UpdateFeedbackDirective($animateCss: LegacyAny, $log: LegacyAny, $timeout: LegacyAny) {
  const link = (scope: LegacyAny, elem: LegacyAny, attr: LegacyAny) => {
    scope.$watch('feedbackType', (ft: LegacyAny) => {
      if (ft === undefined || ft === null) {
        return;
      }

      const classes = scope.$eval(attr.feedbackClasses);
      if (classes === undefined || classes === null) {
        return;
      }

      const cls = classes[ft];
      if (cls === undefined || cls === null) {
        return;
      }

      const duration = attr.feedbackDuration || 3000;

      const animator = $animateCss(elem, {
        addClass: cls + ' feedback',
      });
      animator.start().done(() => {
        $timeout(() => {
          $animateCss(elem, {
            removeClass: cls,
          }).start();
          scope.feedbackType = null;
          $timeout(() => {
            $animateCss(elem, {
              removeClass: 'feedback',
            }).start();
            scope.feedbackType = null;
          }, 500);
        }, duration);
      });
    });
  };

  return {
    restrict: 'A',
    scope: {
      feedbackType: '=?',
    },
    link,
  };
}

UpdateFeedbackDirective.$inject = ['$animateCss', '$log', '$timeout'];
