import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';

/**This class just exists to provide access methods used previously by $uibService that will now instead use the uiDsModal interface. */
export class LegacyUibModalAdapterModalBase<
  ModalInput = any,
  ModalOutput = any
> extends DsModalContentBase<{ resolve: ModalInput | undefined }, ModalOutput> {
  dismiss(reason?: any) {
    this.close(reason);
  }

  get resolve(): ModalInput | undefined {
    return this.modalData?.resolve;
  }
}
