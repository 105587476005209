export const template = `<app-user-notifications></app-user-notifications>
<div class="x-event_handler-page-root" id="page-wrapper">
  <div class="row block alerts-wrapper">
    <div class="col-sm-12"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
  </div>
  <div class="row block">
    <div class="col-sm-12">
      <h2>{{ 'watch.title' | translate }}</h2><sc-prefixed-event-handlers target="operator" readonly="false"
        alerts-service="$ctrl.alertsService"></sc-prefixed-event-handlers>
    </div>
  </div>
</div>`;
