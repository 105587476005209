export const template = `<form name="changeTerminationProtectionForm" translate-namespace="change_termination_protection"
  novalidate="novalidate">
  <div class="x-form-contents">
    <div class="modal-header">
      <h4 translate=".title"></h4>
    </div>
    <div class="modal-body">
      <div class="form-horizontal">
        <div class="form-group"><label class="col-sm-3 control-label"> {{ 'change_termination_protection.resource_label'
            | translate:$ctrl.resourceTranslations }}</label>
          <div class="col-sm-9">
            <p class="form-control-static" ng-repeat="resource in $ctrl.resources">{{ resource.id }}</p>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-3 control-label"> {{
            'change_termination_protection.termination_protection' | translate:$ctrl.resourceTranslations }}</label>
          <div class="col-sm-9"><label class="ds-switch" id="enable-termination-protection"><input type="checkbox"
                ng-model="$ctrl.enableTerminationProtection" ng-change="$ctrl.terminationProtectionChanged()" />
              <div>{{ $ctrl.enableTerminationProtection ? 'on' : 'off' }}</div>
            </label>
            <div class="ds-text--small"> {{ 'change_termination_protection.switch_help' |
              translate:$ctrl.resourceTranslations }}</div>
          </div>
        </div>
        <div class="form-group"><label class="col-sm-3 control-label"></label>
          <div class="col-sm-9">
            <p>{{ 'change_termination_protection.' + $ctrl.getHelpMessage() | translate:$ctrl.resourceTranslations }}
            </p>
          </div>
        </div>
        <div class="form-group" ng-show="$ctrl.submitting"><label class="col-sm-3 control-label"></label>
          <div class="col-sm-9" style="text-align: left;">
            <div class="progress-indicator" style="vertical-align: bottom"><img
                src="https://assets.soracom.io/images/async-working.gif" alt="working" /><span>&nbsp;
                &nbsp;</span><span> {{ 'change_termination_protection.saving' | translate:$ctrl.resourceTranslations
                }}</span></div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10 col-sm-offset-1"><sc-alerts service="$ctrl.alertsService"></sc-alerts></div>
        </div>
      </div>
    </div>
    <div class="modal-footer"><button class="btn btn-default" id="change-termination-protection-cancel"
        ng-click="$ctrl.cancel()" type="button" ng-disabled="$ctrl.submitting"> {{
        'change_termination_protection.cancel' | translate:$ctrl.resourceTranslations }}</button><button
        class="btn btn-default" id="change-termination-protection-confirm" ng-click="$ctrl.confirm()" type="button"
        ng-disabled="!$ctrl.somethingWillChange() || $ctrl.submitting"> {{ 'change_termination_protection.save' |
        translate:$ctrl.resourceTranslations }}</button></div>
  </div>
</form>`;
